import {
  Box,
  Button,
  Checkbox,
  FormControl,
  HStack,
  Icon,
  Image,
  Input,
  Popover,
  PresenceTransition,
  Pressable,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Dimensions, Platform} from 'react-native';
import {EXTRA_SMALL_SCREEN, FILE_TYPE, SMALL_SCREEN} from '../../constants';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {CommonDataContext} from '../../context/CommonDataContext';
import {Colors} from '../../styles';
import {testID, TestIdentifiers} from '../../testUtils';
import {DisplayText} from '../common/DisplayText/DisplayText';
import FoldhealthLogoSvg from '../common/Svg/FoldhealthLogoSvg';
// import FoldhealthLogoSvg from '../common/Svg/FoldhealthLogoSvg';
import VideoPlayerModal from '../common/VideoPlayerModal/VideoPlayerModal';
import {useKeyboard} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/UseKeyBoard.native';
import {ILoginForm, ILoginProps} from './interface';
import LoginBg from './LoginBg';
import {styles} from './LoginStyles';
import {getTrimmedUserNameAndPassword, validate} from './LoginUtils';
import Feather from 'react-native-vector-icons/Feather';
import {isWeb} from '../../utils/platformCheckUtils';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {style} from '../RightSideContainer/Sales/MembershipAndSubscriptions/MembershipStyles';
import {useIntl} from 'react-intl';
import {TermsAndCondition} from './TermsAndCondition/TermsAndCondition';
import {Auth} from 'aws-amplify';

export const Login = (props: ILoginProps) => {
  const [showBookmarkPrompt, setShowBookmarkPrompt] = useState(true);
  const {height} = Dimensions.get('window');
  const keyboardHeight = useKeyboard();
  const commonData = useContext(CommonDataContext);
  const accountThemeConfig: any = commonData.accountThemeConfig || {};
  const [loginFormData, setLoginFormData]: any = useState<ILoginForm>({
    userName: props.defaultUserName || '',
    password: '',
  });
  const [isTnCAccepted, setIsTnCAccepted] = useState(true);
  const [selectedView, setSelctedView] = useState(false);
  const [show, setShow] = useState(false);
  const [errors, setErrors]: any = useState({});
  const onSubmit = () => {
    const {userName, password} = getTrimmedUserNameAndPassword({
      userName: loginFormData.userName,
      password: loginFormData.password,
    });
    loginFormData.userName = userName;
    loginFormData.password = password;
    setErrors(validate(loginFormData).errors);
    if (validate(loginFormData).validate) {
      props.onLoginAction(loginFormData.userName, loginFormData.password);
    }
  };

  const isSmallScreen = height <= SMALL_SCREEN;
  const extraSmallScreen = height <= EXTRA_SMALL_SCREEN;

  const getLoginTextMarginTop = () => {
    if (extraSmallScreen) {
      return 2;
    } else if (isSmallScreen) {
      return -26;
    }
    return -12;
  };

  const onChangeTAndC = () => {
    setIsTnCAccepted(!isTnCAccepted);
  };

  useEffect(() => {
    if (showBookmarkPrompt) {
      setTimeout(() => {
        setShowBookmarkPrompt(false);
      }, 5000);
    }
  }, [showBookmarkPrompt]);

  const handleClick = () => setShow(!show);

  return (
    <View
      bgColor={'white'}
      height={
        Platform.OS === 'web'
          ? 'auto'
          : keyboardHeight == 0
          ? 'full'
          : height - keyboardHeight
      }
    >
      {isWeb() && showBookmarkPrompt && (
        <AddToBookMarkPrompt showBookmarkPrompt={showBookmarkPrompt} />
      )}
      {Platform.OS === 'web' && (
        <VStack
          height={Platform.OS === 'web' ? '100vh' : 'full'}
          justifyContent={'space-between'}
          style={
            Platform.OS === 'web' && {
              position: 'relative',
              right: 100,
            }
          }
        >
          <View />
          <View {...testID(TestIdentifiers.loginBg)} height={height - 100}>{<LoginBg />}</View>
        </VStack>
      )}
      <View
        flex={1}
        alignItems={'flex-end'}
        mt={Platform.OS === 'web' ? (isSmallScreen ? '2%' : '10%') : '0'}
        mr={Platform.OS === 'web' ? '10%' : '0'}
        style={
          Platform.OS === 'web'
            ? {
                flex: 1,
                position: 'absolute',
                right: 0,
                top: 0,
              }
            : {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 10,
              }
        }
      >
        <View
          style={[
            styles.mainBoxContainer,
            {
              paddingTop: Platform.OS === 'web' ? (isSmallScreen ? 0 : 30) : 0,
            },
            Platform.OS === 'web' && styles.mainBoxContainerShadow,
          ]}
          bg={'white'}
        >
          <VStack w={'100%'}>
            {!extraSmallScreen && (
              <HStack {...testID(TestIdentifiers.foldhealthLogoWithName)} justifyContent="center" alignItems={'center'} flex={1}>
                {Platform.OS === 'web' ? <FoldhealthLogoSvg /> : <></>}
              </HStack>
            )}
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: getLoginTextMarginTop(),
              }}
            >
              <Text
                size={'xxlSemibold'}
                style={{
                  color: Colors.FoldPixel.GRAY400,
                  lineHeight: 45,
                }}
                {...testID('Login')}
              >
                {'Login'}
              </Text>
            </View>
          </VStack>
          <VStack mt="4" w={'100%'}>
            <FormControl {...testID(TestIdentifiers.isRequiredInput)} isRequired isInvalid={errors.userName}>
              <FormControl.Label>
                <Text {...testID(TestIdentifiers.email)} style={styles.labelStyle}>Email</Text>
              </FormControl.Label>
              <Input
                _focus={{
                  borderColor: Colors.Custom.Gray200,
                }}
                height={44}
                autoCapitalize="none"
                placeholder="name@email.com"
                fontSize={16}
                value={loginFormData.userName}
                onKeyPress={(event: any) => event?.keyCode === 13 && onSubmit()}
                onChangeText={(value) => {
                  setLoginFormData({
                    ...loginFormData,
                    userName: value,
                  });
                  setErrors({...errors, password: '', userName: ''});
                }}
                {...testID(TestIdentifiers.loginUserName)}
              />
              {errors.userName && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                  {...testID(TestIdentifiers.errorMessage)}
                >
                  {errors.userName}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            <FormControl {...testID(TestIdentifiers.isRequiredInput)} mt={4} isRequired isInvalid={errors.password}>
              <FormControl.Label>
                <Text {...testID(TestIdentifiers.passwordText)} style={styles.labelStyle}>Password</Text>
              </FormControl.Label>
              <Input
                _focus={{
                  borderColor: Colors.Custom.Gray200,
                }}
                height={44}
                fontSize={16}
                type={show ? 'text' : 'password'}
                placeholder="Password"
                autoCapitalize="none"
                value={loginFormData.password}
                onKeyPress={(event: any) => event?.keyCode === 13 && onSubmit()}
                onChangeText={(value) => {
                  setLoginFormData({
                    ...loginFormData,
                    password: value,
                  });
                  setErrors({...errors, password: '', userName: ''});
                }}
                InputRightElement={
                  <View paddingRight={2}>
                    <MaterialCommunityIcons
                      {...testID(TestIdentifiers.showPasswordBtn)}
                      name={show ? 'eye-off' : 'eye'}
                      size={24}
                      color={Colors.Custom.Gray400}
                      onPress={() => {
                        handleClick();
                      }}
                    />
                  </View>
                }
                {...testID(TestIdentifiers.loginPassword)}
              />
              {errors.password && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                  {...testID(TestIdentifiers.errorMessage)}
                >
                  {errors.password}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
          </VStack>
          <TermsAndCondition
            isTnCAccepted={isTnCAccepted}
            onChangeTAndC={onChangeTAndC}
          />

          <Button
            borderWidth={1}
            borderColor={Colors.danger[200]}
            w={'100%'}
            h={10}
            style={
              isTnCAccepted
                ? styles.submitElement
                : styles.submitElementWithoutTnC
            }
            variant={'solid'}
            onPress={onSubmit}
            borderRadius={20}
            {...testID(TestIdentifiers.loginBtn)}
            disabled={!isTnCAccepted}
            backgroundColor={
              !isTnCAccepted ? 'gray.300' : Colors.secondary[200]
            }
          >
            <DisplayText
              textLocalId="login"
              size={'smMedium'}
              extraStyles={
                {color: 'white', fontWeight: '600', fontSize: 14} || ''
              }
            />
          </Button>
          <HStack space={2} mt={6} width={'100%'} justifyContent={'center'}>
            <Pressable
              // style={}
              onPress={() => {
                props.onForgotPasswordBtnClick(loginFormData.userName);
              }}
              {...testID(TestIdentifiers.forgotPasswordBtn)}
            >
              <HStack space={2}>
                <Icon
                  {...testID('image')}
                  size={5}
                  color={'#825AC7'}
                  as={<AntIcon name="questioncircleo" />}
                />
                <Text
                  style={{
                    color: '#825AC7',
                    fontSize: 14,
                    fontWeight: '600',
                    alignSelf: 'center',
                    lineHeight: 21,
                  }}
                  {...testID(TestIdentifiers.forgotPasswordText)}
                >
                  Forgot Password?
                </Text>
              </HStack>
            </Pressable>
          </HStack>
        </View>
      </View>
      {selectedView && (
        <VideoPlayerModal
          filetype={FILE_TYPE.PDF}
          modalTitle={'tAndC'}
          srcUrl="https://generalfolddocs.s3.us-east-2.amazonaws.com/Fold+Pre-GA+EULA+15Apr22%5B63%5D.pdf#toolbar=0"
          onActionPerformed={() => {
            setSelctedView(false);
          }}
          hideCloseButton
        />
      )}
      <HStack
        justifyContent={'center'}
        style={
          Platform.OS === 'web'
            ? {position: 'absolute', bottom: 10, width: '100%'}
            : {
                width: '100%',
                display: 'flex',
                bottom: 24,
                alignItems: 'center',
              }
        }
      >
        <Text {...testID(TestIdentifiers.copyRightText)} size={'smMedium'} color={Colors.Custom.Gray400}>
          Copyright 2024 Fold Health, Inc. All Rights Reserved.
        </Text>
      </HStack>
    </View>
  );
};

const AddToBookMarkPrompt = (props: {showBookmarkPrompt: boolean}) => {
  return (
    <PresenceTransition
      visible={props.showBookmarkPrompt}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 250,
        },
      }}
    >
      <VStack position={'absolute'} top={2} right={'15%'}>
        <HStack bg="white" rounded={'md'} shadow="3">
          <VStack justifyContent={'center'} p={2}>
            <Image
              source={{uri: require('../../assets/bookmark.gif')}}
              w={10}
              h={10}
              alt='image'
            />
          </VStack>
          <VStack py={2} pr={4}>
            <Text {...testID('Come here Often?')}>Come here Often?</Text>
            <Text {...testID('Bookmark this link')}>Bookmark this link</Text>
          </VStack>
        </HStack>
      </VStack>
    </PresenceTransition>
  );
};
