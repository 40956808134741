import React from 'react';
import { Text, View } from 'react-native';
import { Colors } from '../../../../styles';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import { IDayOptimizerRuleFactor } from './interface';
import { ruleScoreStyles } from './RulesScore.styles';
import {Switch} from 'antd';

const renderColumnData = (value: string | number, color = Colors.Custom.Gray400) => (
  <Text style={[ruleScoreStyles.rowColumnEntry, ruleScoreStyles.columnDataCommon, {color: color}]}>{value}</Text>
);

export const rulesScoreColumns = (onStatusChange: (checked: boolean, id: string) => void): any[] => [
  {
    title: (
      <DisplayText
        size="xsMedium"
        extraStyles={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
        textLocalId="ruleName"
      />
    ),
    key: 'ruleName',
    width: '30%',
    render: (record: IDayOptimizerRuleFactor) => renderColumnData(record.name, Colors.Custom.Gray500),
  },
  {
    title: (
      <DisplayText
        size="xsMedium"
        extraStyles={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
        textLocalId="role"
      />
    ),
    key: 'role',
    width: '20%',
    render: (record: IDayOptimizerRuleFactor) => renderColumnData(record.roleName),
  },
  {
    title: (
      <DisplayText
        size="xsMedium"
        extraStyles={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
        textLocalId="score"
      />
    ),
    key: 'score',
    width: '20%',
    render: (record: IDayOptimizerRuleFactor) => renderColumnData(record.score),
  },
  {
    title: (
      <DisplayText
        size="xsMedium"
        extraStyles={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
        textLocalId="status"
      />
    ),
    key: 'status',
    width: '20%',
    onCell: ()=> {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (record: IDayOptimizerRuleFactor) => {
      return (
        <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
          <Switch
            style={{
              backgroundColor: record.isEnabled ? Colors.primary[300] : Colors.Custom.Gray200,
            }}
            checked={record.isEnabled}
            onChange={(checked) => onStatusChange(checked, record.ruleScoreId)}
          />
        </View>
      )
    },
  },
];
