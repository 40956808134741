import { gql } from '@apollo/client';

export const SEARCH_CONTACTS_WITH_CONTACT_TYPE = gql`
  query GetContacts($searchString: String, $limit: Int, $contactType: uuid) {
    contacts(
      where: {
        name: {_ilike: $searchString}
        contactType: {typeId: {_eq: $contactType}}
        email: {_is_null: false}
        isDeleted: {_eq: false}
      }
      limit: $limit
    ) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      id
      name
      email
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientUuid
      }
      person {
        firstName
        chosenName
        pronounId
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
    }
  }
`;

export const GET_CONTACTS_BY_PATIENT_UUIDS = gql`
  query GetContactsByPatientUUIDs($patientIds: [uuid!]) {
    contacts(
      where: {
        isDeleted: {_eq: false}
        patient: {patientUuid: {_in: $patientIds}}
      }
    ) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      id
      uuid
      name
      email
      phoneNumber
      patient {
        patientUuid
        patientId
      }
      person {
        bloodGroup {
          value
          code
          id
        }
        chosenName
        pronounId
        gender {
          value
          code
        }
        sexAtBirth {
          value
          code
        }
      }
    }
  }
`;
export const GET_CONTACTS_BY_CONTACT_IDS = gql`
  query GetContactsByContactUUIDs($contactIds: [uuid!]) {
    contacts(where: {isDeleted: {_eq: false}, uuid: {_in: $contactIds}}) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      additionalAttributes
      contactType {
        contactType {
          code
        }
      }
      id
      uuid
      name
      email
      personContact {
        isVerified
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          uuid
          name
          practiceCity {
            name
            id
          }
        }
        accountLocation {
          uuid
          locationUuid
          practiceLocation {
            uuid
            name
          }
        }
      }
      phoneNumber
      patient {
        patientUuid
        patientId
        id
      }
      personAddress {
        cityId
        stateId
        zipcodeId
        line1
        line2
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
          abbr
        }
        id
      }
      person {
        birthDate
        bloodGroup {
          value
          code
          id
        }
        chosenName
        pronounId
        gender {
          value
          code
        }
        sexAtBirth {
          value
          code
        }
      }
    }
  }
`;

export const GET_ALL_CONTACTS_BY_CONTACT_IDS = gql`
  query GetContactsByContactUUIDs($offset: Int, $limit: Int, $contactIds: [uuid!]) {
    contacts(offset: $offset, limit: $limit, where: {uuid: {_in: $contactIds}}) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      additionalAttributes
      contactType {
        contactType {
          code
          id
        }
      }
      id
      uuid
      name
      email
      phoneNumber
      patient {
        patientUuid
        patientId
      }
      person {
        birthDate
        bloodGroup {
          value
          code
          id
        }
        chosenName
        pronounId
        gender {
          value
          code
        }
        sexAtBirth {
          value
          code
        }
      }
    }
  }
`;

export const GetPatients = gql`
  query GetPatients($searchString: String, $limit: Int) {
    contactPatients(
      where: {contact: {name: {_ilike: $searchString}}}
      limit: $limit
    ) {
      id
      contact {
        id
        name
        conversations {
          id
        }
      }
    }
  }
`;

export const GET_CONTACT_BY_UUID = gql`
  query GetContactDetailsById($contactId: uuid!) {
    contacts(where: {uuid: {_eq: $contactId}}) {
      id
      name
      email
      accountId
      phoneNumber
      uuid
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          uuid
          name
          practiceCity {
            name
            id
          }
        }
        accountLocation {
          uuid
          locationUuid
          practiceLocation {
            uuid
            name
          }
        }
      }
      isActive
      contactType {
        contactType {
          code
        }
      }
      person {
        chosenName
        pronounId
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
          code
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      personAddress {
        cityId
        stateId
        countryId
        zipcodeId
        zipcodes {
          code
          cityId
          stateId
        }
        states {
          abbr
          id
        }
      }
      contactConsents {
        contactUuid
        consentId
      }
      patient {
        patientId
        patientUuid
      }
      additionalAttributes
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      contactProfilePhotos {
        attachmentId
      }
    }
  }
`;

export const GET_CONTACT_UUID_BY_EMAIL = gql`
  query GetContactUUIDByEmail($email: String!) {
    contacts(where: {email: {_eq: $email}}) {
      uuid
    }
  }
`;

export const GET_PATIENT_ID_BY_CONTACT_UUID = gql`
  query GetPatientIdByContactUuid($contactId: uuid!) {
    contacts(where: {uuid: {_eq: $contactId}}) {
      patient {
        patientId
        patientUuid
      }
      contactPracticeLocations {
        practiceLocationUuid
        uuid
        accountLocation {
          uuid
        }
      }
    }
  }
`;

export const GET_CONTACT_DEMOGRAPHICS_BY_CONTACT_UUID = gql`
  query GetContactDemographicsByContactUuid($contactId: uuid!) {
    contacts(where: {uuid: {_eq: $contactId}}) {
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
    }
  }
`;

export const GET_CONTACT_COMMUNICATION_BY_CONTACT_UUID = gql`
  query GetContactDemographicsByContactUuid($contactId: uuid!) {
    contacts(where: {uuid: {_eq: $contactId}}) {
      email
      phoneNumber
      personAddress {
        id
        line1
        line2
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
          abbr
        }
      }
      personContact {
        value
        personContactType {
          code
        }
        rank
      }
    }
  }
`;

export const GET_EMERGENCY_CONTACT_BY_CONTACT_UUID = gql`
  query GetEmergencyContactByContactUuid($contactId: uuid!) {
    contacts(where: {uuid: {_eq: $contactId}}) {
      relations(where: {isEmergency: {_eq: true}}) {
        id
        realatedContact {
          person {
            chosenName
            pronounId
            firstName
            lastName
          }
          email
          phoneNumber
        }
        relationType {
          value
          code
          id
        }
      }
    }
  }
`;

export const GET_CONTACTS_BY_CONTACTIDS = gql`
  query contacts($contactIdList: [bigint!]!, $customAttributeBooleanExpression: contacts_bool_exp = {}) {
    contacts(where: {
      isDeleted: {_eq: false}, 
      id: {_in: $contactIdList}, 
      _and: [$customAttributeBooleanExpression]
    }
)  {
      id
      uuid
      email
      name
      phoneNumber
      contactPracticeLocations{
        accountLocation{
          uuid
          locationUuid
          practiceLocation{
            uuid
            name
          }
        }
      }
      person {
        chosenName
        pronounId
        lastName
        firstName
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          value
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        middleName
      }
    }
  }
`;

export const GET_CONTACTS_BY_CONTACTUUIDS = gql`
  query contacts($contactUuidList: [uuid!]!, $customAttributeBooleanExpression: contacts_bool_exp = {}) {
    contacts(where: {
      isDeleted: {_eq: false}, 
      uuid: {_in: $contactUuidList}, 
      _and: [$customAttributeBooleanExpression]
    }
)  {
      id
      uuid
      email
      name
      phoneNumber
      contactPracticeLocations{
        accountLocation{
          uuid
          locationUuid
          practiceLocation{
            uuid
            name
          }
        }
      }
      person {
        chosenName
        pronounId
        lastName
        firstName
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          value
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        middleName
      }
    }
  }
`;

export const GET_ACTIVE_CONTACTS_BY_CONTACTIDS = gql`
  query contacts($contactIdList: [bigint!]!) {
    contacts(where: {isDeleted: {_eq: false}, id: {_in: $contactIdList}, isActive: {_eq: true}}) {
      id
      uuid
      isActive
      email
      name
      phoneNumber
      person {
        chosenName
        lastName
        firstName
        gender {
          code
          value
        }
        middleName
      }
      contactPracticeLocations {
        practiceLocationUuid
        accountLocation{
        uuid
        }
      }
      personAddress {
        stateId
      }
      patient {
        patientUuid
      }
    }
  }
`;

export const GET_ACTIVE_CONTACTS_BY_CONTACTUUIDS = gql`
  query contacts($contactIdList: [uuid!]!) {
    contacts(where: {isDeleted: {_eq: false}, uuid: {_in: $contactIdList}, isActive: {_eq: true}}) {
      id
      uuid
      isActive
      email
      name
      phoneNumber
      person {
        chosenName
        lastName
        firstName
        gender {
          code
          value
        }
        middleName
      }
      patient {
        patientUuid
      }
    }
  }
`;

export const GET_CONTACT_PRACTICE_LOCATIONS_BY_UUIDS = gql`
  query GetContactPracticeLocationsById($contactUuids: [uuid!]) {
    contacts(where: {uuid: {_in: $contactUuids}}) {
      id
      uuid
      name
      email
      personAddress {
        cityId
        stateId
        countryId
        zipcodeId
        zipcodes {
          code
          cityId
          stateId
        }
        states {
          abbr
          id
        }
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        practiceLocation {
          uuid
          name
          stateUuid
          practiceCity {
            name
            id
            stateId
          }
        }
        accountLocation {
          uuid
          locationUuid
          practiceLocation {
            uuid
            name
          }
        }
      }
    }
  }
`;

export const GetContact = gql`
  query GetContacts($searchString: String) {
    contacts(
    where: {
      _or: [{name: {_ilike: $searchString}}, {patient: {patientId: {_ilike: $searchString}}}]
      isDeleted: {_eq: false}
    }) {
      id
      uuid
      name
      email
      person {
        chosenName
        pronounId
        contactId
        firstName
        lastName
        middleName
      }
      patient {
        patientId
      }
    }
  }
`;

export const GetContactDetails = gql`
  query GetContacts($searchString: String) {
    contacts(
      where: {
        _or: [{name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}]
        isDeleted: {_eq: false}
      }
    ) {
      id
      uuid
      name
      email
      contactType {
        contactType {
          value
          code
          id
        }
      }
      person {
        chosenName
        pronounId
        contactId
        firstName
        lastName
        middleName
      }
    }
  }
`;

export const GetContactDetailsContainsEmail = gql`
  query GetContacts($searchString: String) {
    contacts(
      where: {
        _or: [
          {
            person: {
              chosenName: {
                _ilike: $searchString
              }
            }
          }
          {
            name: {
              _ilike: $searchString
            }
          },
          {
            email: {
            _ilike: $searchString
            }
          }
        ],
        email: {
          _is_null: false
        },
        isDeleted: {
          _eq: false
        },
      }
    ) {
      id
      uuid
      name
      email
      contactConsents {
        contactUuid
        consentId
      }
      contactType {
        contactType {
          value
          code
          id
        }
      }
      person {
        chosenName
        pronounId
        contactId
        firstName
        lastName
        middleName
      }
    }
  }
`;

export const GetContactWithOffset = gql`
query GetContacts($offset: Int, $limit: Int, $where: contacts_bool_exp) {
  contacts(offset: $offset, limit: $limit, where: $where) {
    contactDeactivationInfo {
      isActive
    }
    isActive
    id
    name
    email
    uuid
    phoneNumber
    contactTagging {
      labels {
        title
        color
        id
        description
      }
    }
    contactType {
      contactType {
        value
        code
        id
      }
    }
    patient {
      patientUuid
    }
    person {
      chosenName
      pronounId
      firstName
      lastName
      birthDate
      bloodGroup {
        value
        code
        id
      }
      gender {
        code
        id
      }
      birthSexId
      sexAtBirth {
        value
        code
        id
      }
    }
    contactPracticeLocations(where: {practiceLocationUuid: {_is_null: false}}) {
      uuid
      practiceLocationUuid
      id
      contactUuid
      practiceLocation {
        uuid
        name
        practiceCity {
          name
          id
        }
      }
      accountLocation {
        uuid
        locationUuid
        practiceLocation {
          uuid
          name
        }
      }
    }
  }
}
`;

export const GET_CREATED_CONTACT_BY_DATE = gql`
  query GetCreatedContactByDate(
    $firstFromDate: timestamp
    $secondFromDate: timestamp
    $thirdFromDate: timestamp
    $fourthFromDate: timestamp
    $fifthFromDate: timestamp
    $sixthFromDate: timestamp
    $firstToDate: timestamp
    $secondToDate: timestamp
    $thirdToDate: timestamp
    $fourthToDate: timestamp
    $fifthToDate: timestamp
    $sixthToDate: timestamp
  ) {
    first: contactAggregate(
      where: {
        createdAt: {_gte: $firstFromDate, _lt: $firstToDate}
        isDeleted: {_eq: false}
        contactType: {contactType: {code: {_eq: "CUSTOMER"}}}
      }
    ) {
      aggregate {
        count
      }
    }
    second: contactAggregate(
      where: {
        createdAt: {_gte: $secondFromDate, _lt: $secondToDate}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
    third: contactAggregate(
      where: {
        createdAt: {_gte: $thirdFromDate, _lt: $thirdToDate}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
    fourth: contactAggregate(
      where: {
        createdAt: {_gte: $fourthFromDate, _lt: $fourthToDate}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
    fifth: contactAggregate(
      where: {
        createdAt: {_gte: $fifthFromDate, _lt: $fifthToDate}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
    sixth: contactAggregate(
      where: {
        createdAt: {_gte: $sixthFromDate, _lt: $sixthToDate}
        isDeleted: {_eq: false}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CREATED_CONVERSATIONS_BY_DATE = gql`
  query GetCreatedConversationsByDate(
    $firstFromDate: timestamp
    $secondFromDate: timestamp
    $thirdFromDate: timestamp
    $fourthFromDate: timestamp
    $fifthFromDate: timestamp
    $sixthFromDate: timestamp
    $firstToDate: timestamp
    $secondToDate: timestamp
    $thirdToDate: timestamp
    $fourthToDate: timestamp
    $fifthToDate: timestamp
    $sixthToDate: timestamp
  ) {
    first: messageAggregate(
      where: {
        createdAt: {_gte: $firstFromDate, _lt: $firstToDate}
        isDeleted: {_eq: false}
        senderType: {_eq: "Contact"}
      }
      distinct_on: senderId
    ) {
      aggregate {
        count
      }
    }
    second: messageAggregate(
      where: {
        createdAt: {_gte: $secondFromDate, _lt: $secondToDate}
        isDeleted: {_eq: false}
        senderType: {_eq: "Contact"}
      }
      distinct_on: senderId
    ) {
      aggregate {
        count
      }
    }
    third: messageAggregate(
      where: {
        createdAt: {_gte: $thirdFromDate, _lt: $thirdToDate}
        isDeleted: {_eq: false}
        senderType: {_eq: "Contact"}
      }
      distinct_on: senderId
    ) {
      aggregate {
        count
      }
    }
    fourth: messageAggregate(
      where: {
        createdAt: {_gte: $fourthFromDate, _lt: $fourthToDate}
        isDeleted: {_eq: false}
        senderType: {_eq: "Contact"}
      }
      distinct_on: senderId
    ) {
      aggregate {
        count
      }
    }
    fifth: messageAggregate(
      where: {
        createdAt: {_gte: $fifthFromDate, _lt: $fifthToDate}
        isDeleted: {_eq: false}
        senderType: {_eq: "Contact"}
      }
      distinct_on: senderId
    ) {
      aggregate {
        count
      }
    }
    sixth: messageAggregate(
      where: {
        createdAt: {_gte: $sixthFromDate, _lt: $sixthToDate}
        isDeleted: {_eq: false}
        senderType: {_eq: "Contact"}
      }
      distinct_on: senderId
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CONTACTS_BY_CONTACT_UUIDS = gql`
  query GetContactsByPatientUUIDs($patientIds: [uuid!]) {
    contacts(
      where: {
        isDeleted: {_eq: false}
        contactUser: {contactUuid: {_in: $patientIds}}
      }
    ) {
      id
      uuid
      name
      email
      phoneNumber
      patient {
        patientUuid
        patientId
      }
      person {
        bloodGroup {
          value
          code
          id
        }
        chosenName
        pronounId
        gender {
          value
          code
        }
        sexAtBirth {
          value
          code
        }
      }
    }
  }
`;

export const SEARCH_CONTACTS_WITH_CUSTOM_QUERY = gql`
  query GetContactsWithCustomQuery($limit: Int, $where: contacts_bool_exp) {
    contacts(limit: $limit, where: $where) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      id
      name
      email
      phoneNumber
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }

      patient {
        patientId
        patientUuid
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          uuid
          name
          practiceCity {
            name
            id
          }
        }
        accountLocation {
          uuid
          locationUuid
          practiceLocation {
            uuid
            name
          }
        }
      }
    }
  }
`;

export const SEARCH_CONTACTS_WITH_GLOBAL_SEARCH = gql`
  query GetContactsWithCustomQuery($limit: Int, $offset: Int = 0 $where: contacts_bool_exp) {
    contacts(limit: $limit, offset: $offset, where: $where) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      id
      name
      email
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }

      patient {
        patientId
        patientUuid
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      phoneNumber
    }
  }
`;

export const SEARCH_CONTACTS_WITH_GLOBAL_SEARCH_WITH_ORDERBY = gql`
  query GetContactsWithCustomQuery($limit: Int, $offset: Int = 0 $where: contacts_bool_exp) {
    contacts(limit: $limit, offset: $offset, where: $where, order_by: { name: asc }) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      id
      name
      email
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }

      patient {
        patientId
        patientUuid
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      phoneNumber
    }
  }
`;

export const SEARCH_CONTACTS_WITH_CUSTOM_QUERY_NATIVE = gql`
  query GetContactsWithCustomQuery(
    $limit: Int
    $offset: Int
    $where: contacts_bool_exp
  ) {
    contacts(
      limit: $limit
      offset: $offset
      where: $where
      order_by: {updatedAt: desc}
    ) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      id
      name
      email
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      patient {
        patientId
        patientUuid
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
    }
  }
`;

export const getContactListByUuids = gql`
  query getContactListByUuids($contactUuidList: [uuid!]!) {
    contacts(where: {uuid: {_in: $contactUuidList}}) {
      id
      name
      email
      uuid
    }
  }
`;


export const GET_CONTACTS_NAME_BY_UUID = gql`
  query getContactListByUuids($contactUuidList: [uuid!]!) {
    contacts(where: {uuid: {_in: $contactUuidList}}) {
      id
      name
      uuid
    }
  }
`;

export const getContactListByEmails = gql`
  query getContactListByEmails($contactEmailList: [String!]!) {
    contacts(where: {email: {_in: $contactEmailList}}) {
      id
      name
      email
      uuid
    }
  }
`;

export const GET_PATIENT_IDS_BY_CONTACT_UUIDS = gql`
  query contacts($contactIdList: [uuid!]!) {
    contacts(where: {isDeleted: {_eq: false}, uuid: {_in: $contactIdList}}) {
      id
      uuid
      patient {
        patientUuid
      }
    }
  }
`;

export const GET_CONTACT_AGGREGATE = gql`
  query GetContactAggregate(
    $total: contacts_bool_exp
    $emailTotal: contacts_bool_exp
    $emailConsent: contacts_bool_exp
    $smsTotal: contacts_bool_exp
    $smsConsent: contacts_bool_exp
    $notificationConsent: contacts_bool_exp
  ) {
    total: contactAggregate(where: $total) {
      aggregate {
        count
      }
    }
    emailTotal: contactAggregate(where: $emailTotal) {
      aggregate {
        count
      }
    }
    emailConsent: contactAggregate(where: $emailConsent) {
      aggregate {
        count
      }
    }
    smsTotal: contactAggregate(where: $smsTotal) {
      aggregate {
        count
      }
    }
    smsConsent: contactAggregate(where: $smsConsent) {
      aggregate {
        count
      }
    }
    notificationConsent: contactAggregate(where: $notificationConsent) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CONTACTS_COUNT = gql`
  query GetContacts($contact_filters: contacts_bool_exp) {
  contactAggregate(where: $contact_filters) {
    aggregate {
      count
    }
  }
}
`;

export const GET_CONTACTS_UUIDS_BY_FILTER = gql`
  query GetContacts($contact_filters: contacts_bool_exp) {
    contacts(where: $contact_filters) {
      uuid
    }
  }
`;

export const GET_CONTACT_BY_ID = gql`
  query GetContactById($id: bigint!) {
    contact(id: $id) {
      name
      phoneNumber
      email
      uuid
    }
  }
`;

export const GetAllCustomContactTypes = gql`
  query GetAllCustomContactTypes($accountUuid: uuid) {
    customContactTypes(where:{
      _or: [
        {isDefault: {_eq: true}},
        {accountUuid: {_eq: $accountUuid}}
      	]
      }) {
      code
      id
      value
      isDeleted
      isActive
    }
  }
`;

export const GET_CONTACT_DETAILS_BY_ID = gql`
  query GetContactDetailsById($contactId: uuid!) {
    contacts(where: {uuid: {_eq: $contactId}}) {
      id
      name
      email
      phoneNumber
      uuid
      isActive
      contactType {
        contactType {
          code
        }
      }
      person {
        chosenName
        pronounId
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
          code
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
    }
  }
`;

export const GET_CONTACT_CONSENT_AND_COMMUNICATION_BY_CONTACT_UUID = gql`
  query GetContactDemographicsByContactUuid($contactId: uuid!) {
    contacts(where: {uuid: {_eq: $contactId}}) {
      email
      phoneNumber
      contactConsents {
        contactUuid
        consentId
      }
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
    }
  }
`;

export const GET_CONTACT_CONSENT_BY_CONTACT_UUID = gql`
  query GetContactConsentByContactUuid($contactUuid: uuid) {
    contactConsents(
      where: {isDeleted: {_eq: false}, contactUuid: {_eq: $contactUuid}}
    ) {
      consentId
      contactUuid
      isDeleted
    }
  }
`;

export const GET_CONTACT_BY_CUSTOM_ATTRIBUTE = gql`
  query getContactByCustomAttribute($params: GetContactByCustomAttributeInput!) {
    getContactByCustomAttribute(params: $params) {
      contactId
    }
  }
`;

export const GET_CONTACT_BY_CONDITION = gql`
  query GET_CONTACT_BY_CONDITION(
    $where: contacts_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
  ) {
    contacts(where: $where, limit: $limit, offset: $offset) {
      id
      uuid
      name
      email
      contactPracticeLocations {
        accountLocation {
          uuid
          locationGroupId
        }
      }
      patient {
        patientId
      }
    }
  }
`;

export const GET_CONTACT_BY_CUSTOM_ATTRIBUTE_ID = gql`
  query GetContactsByCustomAttributeId(
    $customAttributeId: uuid!
    $contactTypeId: uuid!
    $searchString: String!
  ) {
    contacts(
      limit: 10
      order_by: {updatedAt: desc}
      where: {
        _or: [
          {name: {_ilike: $searchString}}
          {customAttributeValues: {value: {_ilike: $searchString}}}
        ]
        customAttributeValues: {customAttributeId: {_eq: $customAttributeId}}
        contactType: {typeId: {_neq: $contactTypeId}}
      }
    ) {
      id
      name
      uuid
      customAttributeValues {
        id
        value
      }
      conversations(
        where: {conversationInbox: {channelType: {_eq: "Channel:EFax"}}}
      ) {
        id
        inboxId
      }
    }
  }
`;

export const GET_CONTACT_BY_CUSTOM_ATTRIBUTE_ID_FOR_REFERRAL = gql`
  query GetContactsByCustomAttributeId(
    $customAttributeBooleanExpression: contacts_bool_exp!
  ) {
    contacts(
      order_by: { updatedAt: desc }
      where: $customAttributeBooleanExpression
    ) {
      id
      name
      uuid
      eFaxNo : customAttributeValues(where: {customAttribute: {label: {_eq: "E Fax Number"}}}) {
        value
      }
      speciality : customAttributeValues(where: {customAttribute: {label: {_eq: "Speciality"}}}) {
        value
      }
      personAddress {
        line1
        cities {
          name
        }
        zipcodes {
          code
        }
      }
      conversations(
        where: { conversationInbox: { channelType: { _eq: "Channel:EFax" } } }
      ) {
        id
        inboxId
      }
    }
  }
`;

export const GET_SPECIALITY_BY_CUSTOM_ATTRIBUTE_ID_FOR_REFERRAL = gql`
  query GetSpecialityByCustomAttributeId(
    $customAttributeBooleanExpression: custom_attribute_values_bool_exp!
  ) {
    customAttributeValues(
      where: $customAttributeBooleanExpression
      distinct_on: [value, textValue]
    ) {
      id
      entityId
      entityType
      value
      textValue
    }
  }
`;


export const GET_ALL_CONTACTS_BY_PATIENT_UUIDS = gql`
query GetContactsByPatientUUIDs($contactUuidList: [uuid!]) {
  contacts(
    where: {
      uuid: {_in: $contactUuidList}
    }
  ) {
    contactDeactivationInfo {
      isActive
    }
    isActive
    id
    uuid
    name
    email
    phoneNumber
    contactPracticeLocations {
      id
      practiceLocationUuid
      accountLocation {
        uuid
        accountUuid
      }
    }
    contactType {
      contactType {
        code
        value
      }
    }
    patient {
      patientUuid
      patientId
    }
    person {
      bloodGroup {
        value
        code
        id
      }
      chosenName
      pronounId
      gender {
        value
        code
      }
      sexAtBirth {
        value
        code
      }
      birthDate
    }
  }
}
`;

export const GET_PATIENT_ID_BY_CONTACT_IDS = gql`
  query GetPatientIdByContactIds($contactIdList: [bigint!]!) {
    contacts(where: {isDeleted: {_eq: false}, id: {_in: $contactIdList}}) {
      id
      uuid
      contactPracticeLocations(order_by: {updatedAt: desc}) {
        accountLocation {
          uuid
        }
        practiceLocation {
          name
          practiceCity {
            name
          }
        }
      }
      isActive
      patient {
        patientUuid
        patientId
      }
      phoneNumber
      name
      email
      person {
        firstName
        birthDate
        gender {
          value
          code
        }
        chosenName
        middleName
        lastName
      }
      personAddress {
          line1
          line2
          cities {
            name
          }
          zipcodes {
            code
          }
          states {
            name
            abbr
          }
      }
    }
  }
`;

export const GET_CONTACT_ACCOUNT_LOCATIONS_BY_CONTACT_UUIDS = gql`
  query getContactLocations($contactId: uuid!) {
      userContactPracticeLocations(where: {contactUuid: {_eq: $contactId}, isDeleted: {_eq: false}, accountLocation: {isDeleted: {_eq: false}, practiceLocation: {isDeleted: {_eq: false}}}}) {
        practiceLocationUuid
    		contactUuid
        accountLocation {
          uuid
          locationGroupId
        }
        practiceLocation {
          name
        }
      }
    }
`;

export default {
  SEARCH_CONTACTS_WITH_CONTACT_TYPE,
  GET_CONTACTS_BY_PATIENT_UUIDS,
  GET_CONTACTS_BY_CONTACT_IDS,
  GetPatients,
  GET_CONTACT_BY_UUID,
  GET_CONTACT_UUID_BY_EMAIL,
  GET_PATIENT_ID_BY_CONTACT_UUID,
  GET_CONTACT_DEMOGRAPHICS_BY_CONTACT_UUID,
  GET_CONTACT_COMMUNICATION_BY_CONTACT_UUID,
  GET_EMERGENCY_CONTACT_BY_CONTACT_UUID,
  GET_CONTACTS_BY_CONTACTIDS,
  GET_CONTACTS_BY_CONTACTUUIDS,
  GET_ACTIVE_CONTACTS_BY_CONTACTIDS,
  GET_ACTIVE_CONTACTS_BY_CONTACTUUIDS,
  GET_CONTACT_PRACTICE_LOCATIONS_BY_UUIDS,
  GetContact,
  GetContactWithOffset,
  GET_CREATED_CONTACT_BY_DATE,
  GET_CREATED_CONVERSATIONS_BY_DATE,
  GET_CONTACTS_BY_CONTACT_UUIDS,
  SEARCH_CONTACTS_WITH_CUSTOM_QUERY,
  SEARCH_CONTACTS_WITH_CUSTOM_QUERY_NATIVE,
  getContactListByUuids,
  GET_PATIENT_IDS_BY_CONTACT_UUIDS,
  getContactListByEmails,
  GET_CONTACT_AGGREGATE,
  GET_CONTACTS_COUNT,
  SEARCH_CONTACTS_WITH_GLOBAL_SEARCH,
  SEARCH_CONTACTS_WITH_GLOBAL_SEARCH_WITH_ORDERBY,
  GetContactDetails,
  GetAllCustomContactTypes,
  GET_CONTACT_DETAILS_BY_ID,
  GET_CONTACT_CONSENT_AND_COMMUNICATION_BY_CONTACT_UUID,
  GetContactDetailsContainsEmail,
  GET_CONTACT_CONSENT_BY_CONTACT_UUID,
  GET_CONTACT_BY_CUSTOM_ATTRIBUTE,
  GET_CONTACTS_NAME_BY_UUID,
  GET_CONTACT_BY_CONDITION,
  GET_CONTACT_BY_CUSTOM_ATTRIBUTE_ID,
  GET_CONTACT_BY_CUSTOM_ATTRIBUTE_ID_FOR_REFERRAL,
  GET_SPECIALITY_BY_CUSTOM_ATTRIBUTE_ID_FOR_REFERRAL,
  GET_ALL_CONTACTS_BY_PATIENT_UUIDS,
  GET_PATIENT_ID_BY_CONTACT_IDS,
  GET_CONTACTS_UUIDS_BY_FILTER,
  GET_CONTACT_ACCOUNT_LOCATIONS_BY_CONTACT_UUIDS
};
