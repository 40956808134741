import {Popover, Tooltip} from 'antd';
import {
  Badge,
  Button,
  HStack,
  Icon,
  Input,
  Text,
  VStack,
  View,
  useMediaQuery,
} from 'native-base';
import {useState} from 'react';
import Fontisto from 'react-native-vector-icons/Fontisto';
import {Colors} from '../../../styles';
import {styles} from './SideCarConversationTabStyles';
import {TestIdentifiers, testID} from '../../../testUtils';
import SideCarFilterIconSvg from '../Svg/SideCarFilterIconSvg';
import ConversationSearchBar from '../SearchBar/ConversationSearchBar';
import AntIcon from 'react-native-vector-icons/AntDesign';
import SideCarSearchIconSvg from '../Svg/SideCarSearchIconSvg';
import {Pressable} from 'react-native';
import SearchIconSvg from '../Svg/TimelineSvg/SearchIconSvg';
import CrossIconSvg from '../Svg/CrossIconSvg';

export interface ITabsAction {
  rightContainerElem?: () => JSX.Element;
}

export interface ISideCarConversationTabList {
  key: string;
  title: string;
  code: string;
}
export interface ICustomTabsView {
  tabsList: ISideCarConversationTabList[];
  unreadCountList: {
    [key: string]: {
      count: number;
      conversationIdList: {uuid: string}[];
      messageCount: number;
    };
  };
  allUnreadCount: number;
  conversationFilterProps: any;
  filterModal?: (isFilterDrawerOpen: boolean) => void;
  showMaxTabCount?: number;
  onTabClick?: (tabItem: any, index?: number) => void;
  setSearchText?: (text: string) => void;
}

const SideCarConversationTabBar = (props: ICustomTabsView) => {
  const {
    tabsList,
    onTabClick,
    unreadCountList,
    allUnreadCount,
    conversationFilterProps,
    filterModal,
    setSearchText,
  } = props;
  const [selectedTab, setSelectedTab] = useState<ISideCarConversationTabList>();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchString, setSearchString] = useState('');
  const activeTab = (): ISideCarConversationTabList => {
    return selectedTab ?? tabsList[0];
  };
  const [isOpen, setIsOpen] = useState(false);

  const maxTab = props.showMaxTabCount ?? 1;
  const isShowMoreOption = tabsList?.length > maxTab;

  const moreOptionsElements = () => {
    const tempList = tabsList.slice(maxTab);
    return (
      <VStack maxH={700} overflow={'scroll'} space={'2'}>
        {tempList.map((tabItem: ISideCarConversationTabList, index: number) => {
          return (
            <HStack key={`${tabItem.title}_${index}`}>
              <Pressable
                key={index}
                onPress={() => {
                  setIsOpen(!isOpen);
                  setSelectedTab({...selectedTab, ...tabItem});
                  if (onTabClick) {
                    onTabClick(tabItem, index);
                  }
                }}
              >
                <HStack
                  style={[styles.tabStyles]}
                  color={Colors.Custom.Gray500}
                  space={2}
                >
                  {getTabItemUI(tabItem)}
                </HStack>
              </Pressable>
            </HStack>
          );
        })}
      </VStack>
    );
  };

  const getTabList = () => {
    if (isShowMoreOption) {
      return tabsList.slice(0, maxTab);
    }
    return tabsList;
  };

  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const getTabItemUI = (tabItem: ISideCarConversationTabList) => {
    const tabUnreadCount =
      tabItem.code === 'ALL_CHAT'
        ? allUnreadCount
        : unreadCountList[tabItem.code]?.count;
    return (
      <>
        <Text
          style={{
            fontWeight: '400',
            fontSize: 14,
            color: activeTab().title === tabItem.title
                ? Colors.Custom.Gray700 : Colors.FoldPixel.GRAY250            
          }}
        >
          {tabItem.title}
        </Text>
        {tabUnreadCount > 0 ? (
          <Tooltip
            title={`${tabUnreadCount} unread`}
          >            
            <Badge
              fontWeight={500}
              backgroundColor={Colors.FoldPixel.GRAY50}
              _text={{
                color: Colors.FoldPixel.GRAY300,
                fontSize: 10,
              }}
              alignSelf="center"
              rounded={''}
              borderRadius={4}
              justifyContent={'center'}
              paddingX={2}
              paddingY={1}
              borderColor={Colors.FoldPixel.GRAY200}
              borderWidth={0.5}
            >
              {tabUnreadCount}
            </Badge>
          </Tooltip>
        ) : null}
      </>
    );
  };

  return (
    <View>
      <View
        style={styles.conversationTabContainerStyles}
      >
        {showSearchBar ? (
          <HStack>
            <View flex={1} style={styles.searchBarContainerStyles}>              
              <Input
                placeholder={'Search here'}
                onChangeText={(value: string) => {
                  if (
                    setSearchText &&
                    (value.length > 2 || value.length === 0)
                  ) {
                    setSearchText(value || '');
                    setSearchString(value);
                  }
                }}
                width={'100%'}
                height={'36px'}
                leftElement={
                  <View marginLeft={2} marginRight={1}>
                    <SearchIconSvg
                      size={18}
                      customStrokeColor={Colors.FoldPixel.GRAY200}
                    />
                  </View>
                }
                placeholderTextColor={Colors.FoldPixel.GRAY300}
                backgroundColor={Colors.Custom.White}
                rightElement={
                  <Pressable
                    style={styles.inputCloseButtonStyles}
                    onPress={()=> {
                      if (setSearchText) {
                        setSearchText('');
                        setSearchString('');
                      }
                      setShowSearchBar(false);
                    }}>
                    <CrossIconSvg customStrokeColor={Colors.FoldPixel.GRAY300} size={20}/>
                  </Pressable>
                }
              />
            </View>
          </HStack>
        ) : (
          <HStack
            alignItems={'center'}
            justifyContent={'space-between'}
            style={[
              styles.tabWrapperStyles,
            ]}
          >
            <View style={styles.tabWrapperViewStyles}>
              <HStack
                style={styles.tabWrapperHstackStyles}
              >
                <>
                  {getTabList()?.map(
                    (tabItem: ISideCarConversationTabList, index: number) => {
                      return (
                        <HStack
                          style={styles.tabItemStyles}
                        >
                          <Pressable
                            key={index}
                            onPress={() => {
                              setSelectedTab(tabItem);
                              if (onTabClick) {
                                onTabClick(tabItem, index);
                              }
                            }}
                          >
                            <VStack
                              style={[
                                styles.tabStyles,
                              ]}
                              color={
                                activeTab().title === tabItem.title
                                  ? Colors.Custom.Gray700 : Colors.FoldPixel.GRAY250
                              }
                              fontWeight={
                                activeTab().title === tabItem.title ? 700 : 600
                              }
                              borderWidth={activeTab().title === tabItem.title ? 0.5 : undefined}
                              borderColor={
                                activeTab().title === tabItem.title ? Colors.Custom.shadeGray : undefined
                              }
                              borderRadius={4}
                              backgroundColor={activeTab().title === tabItem.title ? Colors.Custom.White : undefined}
                            >
                              <HStack alignItems={'center'} space={2}>
                                {getTabItemUI(tabItem)}
                              </HStack>
                            </VStack>
                          </Pressable>
                        </HStack>
                      );
                    }
                  )}
                </>
              </HStack>
              {isShowMoreOption && !showSearchBar && (
                <HStack>
                  <Popover
                    content={moreOptionsElements}
                    trigger="click"
                    overlayInnerStyle={{padding: 0, borderRadius: 16}}
                    overlayStyle={{padding: 0}}
                    overlay={{padding: 0}}
                    style={{padding: 0, borderRadius: 16}}
                    placement={'bottom'}
                    visible={isOpen}
                    onVisibleChange={handleVisibleChange}
                  >
                    <Button
                      style={{
                        height: 24,
                        width: 24,
                        backgroundColor: Colors.FoldPixel.GRAY50,
                      }}
                      onPress={() => {
                        setIsOpen(!isOpen);
                      }}
                    >
                      <Icon
                        size={3}
                        color={Colors.Custom.Gray500}
                        as={<AntIcon name="caretdown" />}
                        {...testID('image')}
                      />
                    </Button>
                  </Popover>
                </HStack>
              )}
            </View>
            <HStack
              justifyContent={'flex-end'}
              justifyItems={'center'}
              alignItems={'center'}
              alignContent={'center'}
              flex={1}
            >
              <Pressable
                onPress={() => {
                  filterModal && filterModal(true);
                }}
                style={[
                  {
                    width: 24,
                    height: 24,
                    marginTop: -2,
                    justifyContent: 'center',
                  },
                ]}
                {...testID(TestIdentifiers.filterButton)}
              >
                <Tooltip
                  title={conversationFilterProps?.count?.join(' \n')}
                  arrowPointAtCenter={false}
                  placement={'left'}
                  overlayStyle={{whiteSpace: 'pre-line'}}
                >
                  {conversationFilterProps?.count?.length ? (
                    <View
                      style={styles.tabItemFilterBadgeViewStyles}
                    >
                      <Text
                        style={styles.tabItemFilterBadgeTextStyles}
                      >
                        {conversationFilterProps?.count?.length}
                      </Text>
                    </View>
                  ) : null}
                  <SideCarFilterIconSvg />
                </Tooltip>
              </Pressable>
              <View
                style={styles.dividerViewStyles}
              ></View>
              <Pressable
                onPress={() => {
                  setShowSearchBar(true);
                }}
                style={[
                  styles.searchButtonStyles,
                ]}
                {...testID(TestIdentifiers.searchBtn)}
              >
                <SideCarSearchIconSvg />
              </Pressable>
            </HStack>
          </HStack>
        )}
      </View>
    </View>
  );
};

export default SideCarConversationTabBar;
