import { HStack, Pressable, Divider, Text as NativeText, Tooltip } from 'native-base';
import { Typography, Popover, Calendar } from 'antd';
import { Colors } from '../../../../../../styles';
import UserAddSvg from '../../../../../../assets/svg/UserAddSvg';
import UserSelectionDropDown from '../../Common/UserSelectionDropDown';
import { cssPropertiesAntD, styles } from '../../style';
import { IProvider, ListingHeaderProps } from '../../interfaces';
import FilterDataSvg from '../../../../../../assets/svg/FilterSvg';
import { disableCalendarDates, getDateLabel } from '../../DayOptimizerHelper';
import useListingHeader from './useListingHeader';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import DownArrowFilledSvg from '../../../../../../assets/Icons/DownArrowFilledSvg';
import AngleRightSvg from '../../../../Svg/DayOptimization/AngleRightSvg';
import AngleLeftSvg from '../../../../Svg/DayOptimization/AngleLeftSvg';
import ColumnFilterDropdown from './ColumnFilterDropdown';
import SunFogSvg from '../../../../../../assets/Icons/SunFogSvg';
import {
  getMomentObj,
  getNextMonth,
  getPreviousMonth,
} from '../../../../../../utils/DateUtils';
import AngleLeftSvgOutlined from '../../../../Svg/DayOptimization/AngleLeftSvgOutlined';
import AngleRightSvgOutlined from '../../../../Svg/DayOptimization/AngleRightSvgOutlined';
import RevertSvg from '../../../../Svg/DayOptimization/RevertSvg';
import { View } from 'react-native';
import ColumnSelectionSvg from '../../../../../../assets/Icons/ColumnSelectionSvg';
import { DoFilterPopover } from './DoFilterPopover/DoFilterPopover';

const { Text } = Typography;

const ListingHeader = ({ onAddToList }: ListingHeaderProps) => {
  const intl = useIntl();
  const {
    currentDate,
    selectedProvider,
    userList,
    handleUserSelect,
    walkDate,
    changeDate,
    fetchUsers,
    loading,
    handleColumnChange,
    handleApplyForColumnFilter,
    columns,
    columnChangeLoading,
    isColumnChanged,
    popoverVisible,
    setPopoverVisible,
    setFilterPopoverVisible,
    filterPopoverVisible,
    onFilterChange,
    filter
  } = useListingHeader();  

  const disablePreviousDateBtn = currentDate <= new Date(new Date().setMonth(new Date().getMonth() - 1));
  const disableNextDateBtn = currentDate > new Date(new Date().setDate(new Date().getDate() + 2));
  const disableNextMonthBtn = currentDate.getMonth() === new Date().getMonth() && currentDate.getFullYear() === new Date().getFullYear();

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const handleFilterPopoverVisibleChange = (visible: boolean) => {
    setFilterPopoverVisible(visible);
  };


  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <HStack
      justifyContent={'space-between'}
      alignItems={'center'}
      paddingX={'12px'}
      paddingY={'12px'}
      paddingBottom={'9px'}
    >
      <HStack alignItems="center" space={2}>
        <SunFogSvg />
        <Popover
          overlayClassName='userSelectionDropDownPopoverStyle'
          content={
            <UserSelectionDropDown
              users={userList as any}
              selectedProvider={selectedProvider as IProvider}
              handleSelect={(provider) => { handleUserSelect(provider) }}
              fetchUsers={fetchUsers}
              loading={loading}
              setShowDropdown={setShowDropdown}
            />
          }
          trigger="click"
          visible={showDropdown}
          onVisibleChange={(visible) => setShowDropdown(visible)}
          placement="bottomLeft"
        >
          <Pressable
            onPress={() => setShowDropdown(!showDropdown)}
          >
            <HStack alignItems="center">
              <NativeText fontSize={'14px'} fontWeight={500} color={Colors.FoldPixel.GRAY400}>
                {/* {selectedProvider && typeof selectedProvider === 'object' && selectedProvider?.name?.split(' ')?.length >= 2
                  ? `${selectedProvider?.name?.split(' ')?.[0]}'s Day Optimizer` */}
                {selectedProvider && typeof selectedProvider === 'object' && selectedProvider?.name
                  ? `${selectedProvider?.name}'s Day Optimizer`
                  : 'Day Optimizer'}
              </NativeText>
              <Divider orientation="vertical" backgroundColor={Colors.FoldPixel.GRAY150} width="0.5px" height="12px" marginX={'4px'} />
              <DownArrowFilledSvg />
            </HStack>
          </Pressable>
        </Popover>
      </HStack>
      <HStack alignItems="center">
        <Pressable>
          <Popover
            content={
              <>
                <Calendar
                  headerRender={({ value, onChange }) => {
                    return (
                      <HStack
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        mb={'8px'}
                      >
                        <Pressable
                          onPress={() => {
                            onChange(getPreviousMonth(value));
                          }}
                          disabled={disablePreviousDateBtn}
                        >
                          <AngleLeftSvgOutlined customStrokeColor={disablePreviousDateBtn ? Colors.FoldPixel.GRAY150 : Colors.FoldPixel.GRAY300} />
                        </Pressable>
                        <Text>
                          {value.toDate().toLocaleDateString('en-US', { month: 'long' })}{' '}
                          {value.toDate().getFullYear()}
                        </Text>
                        <Pressable
                          onPress={() => {
                            onChange(getNextMonth(value));
                          }}
                          disabled={disableNextMonthBtn}
                        >
                          <AngleRightSvgOutlined customStrokeColor={disableNextMonthBtn ? Colors.FoldPixel.GRAY150 : Colors.FoldPixel.GRAY300} />
                        </Pressable>
                      </HStack>
                    );
                  }}
                  className={`custom-calendar day-optimizer-calendar`}
                  value={getMomentObj(currentDate)}
                  fullscreen={false}
                  onSelect={(date) => changeDate(date.toDate())}
                  style={cssPropertiesAntD.w350px}
                  disabledDate={disableCalendarDates()}
                />
                {currentDate?.toDateString() !== new Date().toDateString() ? (
                  <Pressable
                    onPress={() => changeDate(new Date())}
                    style={styles.pressable}
                    _hover={{
                      backgroundColor: Colors.FoldPixel.GRAY50,
                    }}
                  >
                    <View
                      style={styles.dFlexFdRowJcCenterAiCenter}
                    >
                      <RevertSvg />
                      <Text
                        style={cssPropertiesAntD.text}
                      >
                        Today
                      </Text>
                    </View>
                  </Pressable>
                ) : (
                  <></>
                )}
              </>
            }
            placement={'bottom'}
            trigger={'click'}
            overlayInnerStyle={cssPropertiesAntD.br16Bw0p5SolidGray100}
            showArrow={false}
          >
            <Text style={cssPropertiesAntD.dateLabelListingHeader}>
              {getDateLabel(currentDate)}
            </Text>
          </Popover>
        </Pressable>

        <Pressable
          onPress={() => walkDate(-1)}
          padding={'4px'}
          _hover={{
            backgroundColor: Colors.FoldPixel.GRAY50,
            borderRadius: 4,
          }}
          disabled={disablePreviousDateBtn}
        >
          <AngleLeftSvg customStrokeColor={disablePreviousDateBtn ? Colors.FoldPixel.GRAY150 : Colors.FoldPixel.GRAY300} />
        </Pressable>

        <Pressable
          onPress={() => walkDate(1)}
          padding={'4px'}
          _hover={{
            backgroundColor: Colors.FoldPixel.GRAY50,
            borderRadius: 4,
          }}
          disabled={disableNextDateBtn}
        >
          <AngleRightSvg customStrokeColor={disableNextDateBtn ? Colors.FoldPixel.GRAY150 : Colors.FoldPixel.GRAY300} />
        </Pressable>
      </HStack>
      <HStack space={2} alignItems="center">
        <Pressable color={Colors.FoldPixel.GRAY400} onPress={onAddToList}
          style={{
            padding: '4px',
          }}
          _hover={{
            backgroundColor: Colors.FoldPixel.GRAY50,
            borderRadius: 4,
          }}
        >
          <HStack alignItems={'center'}>
            <UserAddSvg />
            <NativeText marginLeft={'4px'} size={'smRegular'} color={Colors.FoldPixel.GRAY300}>
              {intl.formatMessage({ id: 'addToList' })}
            </NativeText>
          </HStack>
        </Pressable>
        <Divider orientation="vertical" backgroundColor={Colors.FoldPixel.GRAY150} width="0.5px" height="16px" />
     
        <Popover
          overlayClassName='filter-popover-style'
          content={
            <DoFilterPopover
              filter={filter}
              onFilterChange={(filter)=> {
                setFilterPopoverVisible(false);
                onFilterChange(filter);
              }}
            />
          }
          trigger="click"
          placement={'bottom'}
          
          visible={filterPopoverVisible}
          onVisibleChange={handleFilterPopoverVisibleChange}
        >
          <Tooltip
            placement="top"
            label={'Filters'}
          >
           <Pressable
            style={styles.p4}
            _hover={{
              backgroundColor: Colors.FoldPixel.GRAY50,
              borderRadius: 4,
            }}
            onPress={() => setFilterPopoverVisible(true)}
          >
          <FilterDataSvg />
          {filter?.statusId && 
            <View style={styles.doFilterBadge}>
              <Text
                style={{
                  color: Colors.Custom.White,
                  fontWeight: '600',                              
                  fontSize: 10,
                  textAlign: 'center',
                }}
              >
                {"1"}
              </Text>
            </View>
          }
        </Pressable>
          </Tooltip>
        </Popover>
        
        <Divider orientation="vertical" height="20px" />
        <Popover
          overlayClassName='threeDotsPopoverStyle'
          content={
            <ColumnFilterDropdown
              setPopoverVisible={setPopoverVisible}
              handleColumnChange={handleColumnChange}
              handleApplyForColumnFilter={handleApplyForColumnFilter}
              columns={columns}
              loading={columnChangeLoading}
              isColumnChanged={isColumnChanged}
            />
          }
          trigger="click"
          placement={'bottom'}
          visible={popoverVisible}
          onVisibleChange={handlePopoverVisibleChange}
        >
          <Tooltip
            placement="top"
            label={'Columns Settings'}
          >
            <Pressable onPress={() => setPopoverVisible(true)}>
              <ColumnSelectionSvg />
            </Pressable>
          </Tooltip>
        </Popover>
      </HStack>
    </HStack>
  )
};

export default ListingHeader;
