import { useMutation } from '@apollo/client';
import { Drawer } from 'antd';
import {
  Button,
  HStack,
  Pressable,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import {useIntl} from 'react-intl';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { CLOUD_TELEPHONY_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import CloudTelephonyQueries from '../../../../services/CloudTelephony/CloudTelephonyQueries';
import BaseService from '../../../../services/CommonService/BaseService';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import { Colors } from '../../../../styles';
import { getUserUUID, getAccountUUID, getUserData, getAccountId, getUserId, canCreateSMSInboxWithPhoneNumber } from '../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { getMlovIdFromCode } from '../../../../utils/mlovUtils';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import { ModalActionTitle } from '../../../common/ModalActionTitle/ModalActionTitle';
import { SearchBar } from '../../../common/SearchBar';
import PhoneRingSvg from '../../../common/Svg/PhoneRingSvg';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import { createInboxUser, createSmsInbox } from '../CloudTelephonyUtils';
import { ISearchNumber } from '../CloudTelephonyView/interface';
import { IUser } from '../interfaces';
import { styles } from './PurchaseNumberStyles';

interface IPurchaseNumberView {
  onFormCompleteAction: (code: string, data?: any) => void;
  userList: IUser[];
  event?: string;
  screenName?: string;
  ivrFlowList?: any;
  inboxes?:any[]
}

const PurchaseNumberView = (props: IPurchaseNumberView) => {
  const axios = BaseService.getSharedInstance().axios;
  const [showModal, setShowModal] = useState(true);
  const loggedInUserData = getUserData();
  const accountUUID = getAccountUUID();
  const commonData = useContext(CommonDataContext);
  const toast = useToast();
  const intl = useIntl();
  const accountId = getAccountId()
  const userId = getUserId()
  const [stateData, setStateData] = useState({
    searchString: '',
    purchasedNumberList: [] as ISearchNumber[],
    loading: false,
    selectedNumberIndex: -1,
    onSavingLoading: false,
  });
  const onCancel = () => {
    setShowModal(false);
    props.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL);
  };

  const getAvailablePhoneNumbers = async (code: string) => {
    return axios.get(
      `/cloud-telephony/phone-numbers/search?areaCode=${code}&limit=1000`
    );
  };
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const assigneesList = mlovData['VirtualNumberAssignee'] || [];
  const userAssigneeType = getMlovIdFromCode(assigneesList, 'USER');

  const [CreateVirtualNumber] = useMutation(
    CloudTelephonyQueries.CREATE_VIRTUAL_PHONE_NUMBER
  );
  const [createInboxMembers] = useMutation(InboxQueries.createInboxMembers);


  const onSearch = async (searchString: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await getAvailablePhoneNumbers(searchString);
    setStateData((prev) => {
      return {
        ...prev,
        purchasedNumberList: response.data,
      };
    });
    setStateData((prev) => {
      return {
        ...prev,
        loading: false,
      };
    });
  };

  const createNumberWithFlow = async (phoneNumber: string) => {
    let createVNObject = {
      accountUuid: accountUUID,
      virtualPhoneNumber: phoneNumber,
    } as any;

    createVNObject = {...createVNObject, assigneeId: loggedInUserData.uuid};
    createVNObject = {...createVNObject, assigneeTypeId: userAssigneeType};
    createVNObject = {...createVNObject, assignee: loggedInUserData.name};
    const responseData: any = await CreateVirtualNumber({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        createVNObject: createVNObject,
      },
    });
    const data = responseData?.data?.createVirtualPhoneNumberAssignee || {};
    showToast(
      toast,
      ` ${phoneNumber} Number Successfully Purchased`,
      ToastType.success
    );
    return data.id ? data.id : ''
  }
  const handleBuyNumber = async (phoneNumberData: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let body = {} as any;
      body = JSON.stringify({
        userUUID: loggedInUserData.uuid,
        name: loggedInUserData.name,
        accountId: accountUUID,
        phoneNumber: phoneNumberData.phoneNumber,
      });
    const bodyStr = body;
    axios
      .post(`/cloud-telephony/phone-numbers/purchase`, bodyStr)
      .then(async (res: any) => {
          if(res?.response?.status == 400){
            const message =
              'Purchase limit reached. Please contact Fold support';
            showToast(toast, message, ToastType.info);
            setStateData((prev) => {
              return {
                ...prev,
                loading: false,
              };
            });
            return;
          }
          const createdId = await createNumberWithFlow(phoneNumberData.phoneNumber);
          const response = await createSmsInbox({
            inboxName: phoneNumberData.phoneNumber,
            accountId: accountId as number,
            phoneNumber: phoneNumberData.phoneNumber,
          });
          if (response.data.id) {
            const inboxId = response.data.id;
            await createInboxUser({userId, inboxId, createInboxMembers});
          }
          const selectedPhoneNumberData = {
            id: createdId,
            phoneNumber: {
              name: loggedInUserData.name,
              phoneNumber: phoneNumberData.phoneNumber
            }
          }
          props?.onFormCompleteAction?.('SETUP_NUMBER', selectedPhoneNumberData);
          setStateData((prev) => {
            return {
              ...prev,
              loading: false,
            };
          });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          let errMsg= '';
          if(err?.response?.data?.message === 'Purchased limit reached'){
            errMsg = err?.response?.data?.message + ', Please contact Fold support';
          }else{
            errMsg = 'Number is sold out. Please search again';
          }
          showToast(
            toast,
            errMsg,
            ToastType.info
          );
          setStateData((prev) => {
            return {
              ...prev,
              loading: false,
            };
          });
        } else {
          showToast(toast, `Error Purchasing ${phoneNumberData.phoneNumber}`, ToastType.error);
        }
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(stateData.searchString);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [stateData.searchString]);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const {width, height} = Dimensions.get('window');
  const finalWidth =
  isIPadScreen || isIPadMiniScreen ? (width * 2) / 4 : (width * 2) / 7;
  return (
    <Drawer
      visible={showModal}
      width={finalWidth}
      closable={false}
      onClose={() => {
        setShowModal(false);
        onCancel();
      }}
      title={
        <ModalActionTitle
        title={'newNumber'}
        titleColor={''}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              onCancel();
            },
          },
        ]}
      />
      }
    >
      {stateData.onSavingLoading ? (
        <Spinner size="lg" style={styles.spinnerStyle} />
      ) : (
        <></>
      )}
      <VStack flex={1}>
        <HStack flex={1}>
          <SearchBar
            placeholderText={'Search number by area code'}
            width={'90%'}
            onChange={(value: string) => {
              const searchString = value;
              setStateData((prev) => {
                return {
                  ...prev,
                  searchString: searchString,
                };
              });
            }}
          />
          <Button
            variant={'transparent'}
            ml={2}
            leftIcon={
              <AntIcon
                size={15}
                color={Colors.Custom.Gray400}
                name={'infocirlceo'}
              />
            }
            onPress={() => {
              window.open('https://www.50states.com/areacodes/', '_blank', 'noopener,noreferrer');
            }}
          />
        </HStack>
        <VStack marginTop={8} flex={1}>
          {stateData.loading ? (
            <Spinner size="lg" style={styles.spinnerStyle} />
          ) : (
            <View maxHeight={height - 200} overflow="scroll">
              {stateData?.purchasedNumberList.length ? (
                stateData?.purchasedNumberList.map(
                  (phoneNumberData: ISearchNumber, index: number) => {
                    if (phoneNumberData.locality && phoneNumberData.isoCountry) {
                      return (
                        <Pressable
                          onPress={() => {
                            setStateData((prev) => {
                              return {
                                ...prev,
                                selectedNumberIndex: index,
                              };
                            });
                          }}
                        >
                          <VStack
                            style={{
                              borderTopWidth: 1,
                              borderColor: Colors.Custom.Gray200,
                              paddingBottom: 0,
                              backgroundColor:
                                index == stateData.selectedNumberIndex
                                  ? Colors.Custom.Gray50
                                  : '',
                              borderRadius: 0,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <HStack
                              key={index}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              style={{
                                paddingBottom: 32,
                                paddingTop:
                                  index == 0 ||
                                  index == stateData.selectedNumberIndex
                                    ? 0
                                    : 32,
                              }}
                            >
                              <View style={{marginTop: 14}}>
                                <HStack alignItems={'center'}>
                                  <View
                                    style={{
                                      paddingVertical: 11,
                                      paddingHorizontal: 13,
                                      marginRight: 11,
                                    }}
                                  >
                                    <PhoneRingSvg />
                                  </View>
                                  <VStack>
                                    <Text
                                      size={
                                        index == stateData.selectedNumberIndex
                                          ? 'lgNormal'
                                          : 'smMedium'
                                      }
                                      fontWeight={
                                        index == stateData.selectedNumberIndex
                                          ? '600'
                                          : '500'
                                      }
                                      color={Colors.FoldPixel.GRAY400}
                                    >
                                      {phoneNumberData.phoneNumber}
                                    </Text>
                                    {phoneNumberData.locality &&
                                    phoneNumberData.isoCountry ? (
                                      <Text
                                        size={'xsNormal'}
                                        fontWeight={
                                          index == stateData.selectedNumberIndex
                                            ? '600'
                                            : '500'
                                        }
                                        color={Colors.FoldPixel.GRAY250}
                                      >
                                        {`${phoneNumberData.locality}, ${phoneNumberData.isoCountry}`}
                                      </Text>
                                    ) : (
                                      <></>
                                    )}
                                  </VStack>
                                </HStack>
                              </View>
                              {index != stateData.selectedNumberIndex && (
                                <View>
                                  <FoldButton
                                    nativeProps={{
                                      variant: BUTTON_TYPE.PRIMARY,
                                      onPress: () => {
                                        handleBuyNumber(phoneNumberData);
                                      },
                                      style: {marginLeft: 10},
                                    }}
                                    customProps={{
                                      btnText: intl.formatMessage({
                                        id: 'buyNow',
                                      }),
                                      withRightBorder: false,
                                    }}
                                  ></FoldButton>
                                </View>
                              )}
                            </HStack>
                          </VStack>
                        </Pressable>
                      );
                    }

                  }
                )
              ) : (
                <Text>
                  'No number found with searched area code'
                </Text>
              )}
            </View>
          )}
        </VStack>
      </VStack>
    </Drawer>
  );
};

export default PurchaseNumberView;
