import {ColumnsType} from 'antd/lib/table';
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  VStack,
  View,
} from 'native-base';
import {IntlShape} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {HolidayDataInterface} from './Interfaces';
import {TestIdentifiers, testID} from '../../../testUtils';
import {HolidayActions} from './HolidayConst';
import CopyIconSvg from '../../common/Svg/CopyIconSvg';
import {Colors} from '../../../styles/Colors';
import { DATE_FORMATS } from '../../../constants';
import { convertDateToStringWithoutTimezone, getDateObjectFromAPIFormatToMyFormat, getDateObjFromDateStrAndFormat, getDateStrFromFormat, getMomentObjFromDateStrAndFormat, isDayAndDateInPast } from '../../../utils/DateUtils';
import {getHolidayLocationValueByKey} from './HolidayUtils';
import { DisplayText } from '../../common/DisplayText/DisplayText';

export const getColumns = (
  intl: IntlShape,
  onActionPerformed: (actionCode: string, actionData: any) => void,
  accountLocations?: any[]
): ColumnsType<any> => {
  return [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='holidayName'
        />
      ),
      key: 'name',
      width: '25%',
      fixed: 'left',
      render: (data: HolidayDataInterface) => {
        return (
          <HStack alignItems="flex-start" flex={1}>
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>{data.name}</Text>
          </HStack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='dateAndTime'
        />
      ),
      key: 'startDate',
      width: '25%',
      fixed: 'left',
      render: (data: HolidayDataInterface) => {
        return (
          <HStack alignItems="flex-start" flex={1}>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
              {`${
                'Start Date: ' +
                convertDateToStringWithoutTimezone(
                  data.startDate,
                  DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
                ) +
                '\nEnd Date: ' +
                convertDateToStringWithoutTimezone(
                  data.endDate,
                  DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
                )
              }`}
            </Text>
          </HStack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='location'
        />
      ),
      key: 'accountLocationUuid',
      fixed: 'left',
      width: '25%',
      render: (data: HolidayDataInterface) => {
        const filterLocationList: any = accountLocations?.filter((item)=> {
          const isExist = data.holidayLocations?.find((location)=> {
            return (location.practiceLocationUuid === item?.practiceLocation?.uuid || location.practiceLocationUuid === item?.uuid)
          })
          return isExist?.holidayId ? true : false
        })
        let locationNames = getHolidayLocationValueByKey('locationName',filterLocationList);
        locationNames = locationNames?.length ? locationNames.join(', ') : ''
        return ( <HStack alignItems="flex-start" flex={1}>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{locationNames}</Text>
        </HStack>
        )
        //   const locationData = getSelectedLocationTypeIds(
        //     data.locationTypeId,
        //     data.isPatientFacingLocationType || false,
        //     locationTypes
        //   );
        //   const locationNameList = locationData.map(id => locationTypes.find(location => location.id === id)?.value)
        //   return locationNameList.length > 1 ? (
        //     <Tooltip
        //       label={locationNameList.slice(1).join(', ')}
        //       placement="top"
        //       w={200}
        //       backgroundColor="white"
        //       _text={{
        //         color: 'gray.500',
        //       }}
        //     >
        //       <Text>
        //         {locationNameList[0]}
        //         <Text color={Colors.Custom.PrimaryColor}>
        //           {` + ${locationNameList.length - 1} more`}
        //         </Text>
        //       </Text>
        //     </Tooltip>
        //   ) : (
        //     <Text>{locationNameList[0]}</Text>
        //   );
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='actions'
        />
      ),
      key: 'action',
      width: '25%',
      fixed: 'right',
      render: (data: HolidayDataInterface) => {
        const isPastHoliDay = isDayAndDateInPast(getDateStrFromFormat(data.endDate));
        return (
          <View flexDirection="row" alignItems="flex-start">
            <Tooltip
              label={`${
                isPastHoliDay ? 'Past holidays cannot be edited' : 'Edit'
              }`}
              placement="top"
            >
              <IconButton
                disabled={isPastHoliDay}
                colorScheme="gray"
                variant="ghost"
                onPress={() =>
                  onActionPerformed(HolidayActions.EDIT_HOLIDAY, data)
                }
                {...testID(TestIdentifiers.editBtn)}
                icon={
                  <Icon
                    as={AntIcon}
                    name="edit"
                    size="4"
                    color={`${isPastHoliDay ? 'gray.400' : 'gray.500'}`}
                  />
                }
              />
            </Tooltip>

            <Tooltip
              label={`Delete`}
              placement="top"
            >
              <IconButton
                colorScheme="gray"
                tintColor="gray.500"
                variant="ghost"
                onPress={() =>
                  onActionPerformed(HolidayActions.DELETE_HOLIDAY, data)
                }
                {...testID(TestIdentifiers.deleteBtn)}
                icon={
                  <Icon
                    as={AntIcon}
                    name="delete"
                    size="4"
                    color={`gray.500`}
                  />
                }
              />
            </Tooltip>

            <Tooltip label={`Copy`} placement="top">
              <IconButton
                colorScheme="gray"
                tintColor="gray.500"
                variant="ghost"
                onPress={() =>
                  onActionPerformed(HolidayActions.COPY_HOLIDAY, data)
                }
                {...testID(TestIdentifiers.clone)}
                icon={
                  <CopyIconSvg
                    customStrokeColor={Colors.Custom.Gray500}
                    width={16}
                    height={16}
                  />
                }
              />
            </Tooltip>
          </View>
        );
      },
    },
  ];
};
