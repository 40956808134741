import {PatientData} from '../../interfaces';

export const getFormattedPatientForAutomation = (selectedPatient: PatientData) => {
  return {
    contactData: {
      selectedPatient,
      id: selectedPatient.patientId,
      name: selectedPatient.name,
    },
    id: selectedPatient.patientId,
    name: selectedPatient.name,
  };
};
