import React from 'react';
import {Pressable, Text} from 'native-base';
import OutboundCallSmsView from '../../../../OutboundCallSmsView/OutboundCallSmsView';
import {Colors} from '../../../../../../styles/Colors';
import { COMMON_ACTION_CODES } from '../../../../../../constants/ActionConst';
import { isCallsAllowed } from '../../../../../RightSideContainer/UserAccess/UserAccessPermission';
import { EventBus } from '../../../../../../utils/EventBus';
import FoldButtonV2, { ButtonType } from '../../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';

const OutboundCallSmsViewWrapper = (props: {
  action: string;
  record: any;
  actionCode: string;
  isLoading: boolean;
}) => {
    const isCallAllowed = isCallsAllowed();
    const [relationListState, setRelationListState] = React.useState<{
        isOutboundViewVisible: boolean
    }>({
        isOutboundViewVisible: false
    });

  const onActionPerformed = (actionCode: string, rowData?: any) => {
    switch(actionCode){
        case COMMON_ACTION_CODES.CLOSE_MODAL:
            setRelationListState((prev) => ({
                isOutboundViewVisible: false,
            }));
        break;
        case COMMON_ACTION_CODES.CALL:
            if (!isCallAllowed) {
            return
            }
            if (rowData) {
                const eventBus = EventBus.getEventBusInstance();
                eventBus.broadcastEvent(COMMON_ACTION_CODES.CLOUD_CALL, {
                    contactData: {
                        ...props?.record,
                        id: parseInt(props?.record?.patientId || ''),
                    },
                    fromNumber: rowData.fromNumber,
                    toNumber: props?.record?.phoneNumber,
                });
                setRelationListState((prev) => ({
                    isOutboundViewVisible: false,
                }));
                } else {
                setRelationListState((prev) => ({
                    isOutboundViewVisible: true,
                }));
            }
        break;
    }
  }
  return (
    <OutboundCallSmsView
      isHideSms={true}
      showOnlyCallNumbers={true}
      visible={relationListState?.isOutboundViewVisible}
      selectedContactData={null}
      content={
        <FoldButtonV2
            label={props?.action}
            onPress={() => {
                setRelationListState((prev) => ({
                    isOutboundViewVisible: true,
                }));            
            }}
            buttonType={ButtonType.tertiary}
            borderColor={Colors.FoldPixel.PRIMARY300}
            height={24}
            width={'82px'}
            hoverBackgroundColor={Colors.FoldPixel.PRIMARY300}
            hoverTextColor={Colors.Custom.White}
            isDisabled={false}
            textSize={12}
            isLoading={props?.isLoading}
        />
      }
      handleAction={(actionCode: string, rawData?: any) => {
        onActionPerformed(actionCode, rawData);
      }}
    />
  );
};

export default OutboundCallSmsViewWrapper;
