import {useLazyQuery} from '@apollo/client';
import {View} from 'native-base';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import QualityMeasuresService, {
  getQualityMeasures,
} from '../../../../../services/Contracts/QualityMeasures.service';
import {
  IQmReportList,
  IQualityMeasures,
  IQualityMeasuresList,
} from '../../HelperFiles/interface';
import {formatExecutionDate, getFormattedQualityMeasuresList} from '../../HelperFiles/utils';
import QualityMeasuresList from './QualityMeasuresList';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {ICustomToast} from '../../../Contacts/CustomField/interface';
import {CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG} from '../../../Contacts/CustomField/CustomFieldConst';
import {useToast} from '../../../../Toast/ToastProvider';
import {ToastType} from '../../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import {SORT_ORDER} from '../../../../../constants';

const QualityMeasures = (props: IQualityMeasures) => {
  const {searchString} = props;
  const {contractId} = useParams();
  const accountUuid = getAccountUUID();
  const [stateData, setStateData] = useState({
    qualityMeasuresList: [] as IQualityMeasuresList[],
    currentPage: 1,
    pageSize: 10,
    total: 10,
    offSet: 0,
    loading: false,
    nameSortOrder: '',
    executionDate: '',
    executionDateLoading: true,
  });
  const toast = useToast();
  const intl = useIntl();

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const [qualityMeasures] = useLazyQuery(
    QualityMeasuresService.QualityMeasuresQuery,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const [qualityMeasureReportByMeasureId] = useLazyQuery(
    QualityMeasuresService.qualityMeasureReportById,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const [getLastExecutionDate] = useLazyQuery(QualityMeasuresService.qualityMeasureReportRunTimestamp,{
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache'
  })
  const handleSortChange = (order: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        nameSortOrder: order
      }
    })
  }
  const handlePageChange = (page: number, currentPageSize: number) => {
    const offSet = currentPageSize * (page - 1);
    setStateData((prev) => {
      return {
        ...prev,
        currentPage: page,
        offSet: offSet,
        pageSize: currentPageSize,
      };
    });
  };
const updateStateData = (newState: any) => {
  setStateData((prev) => ({ ...prev, ...newState }));
};

const fetchQualityMeasuresData = async (offSet?: number) => {
  try {
    const response = await qualityMeasures({
      variables: {
        contractId,
        name: `%${searchString}%`,
        limit: stateData.pageSize,
        offset: offSet === 0 ? offSet : stateData.offSet,
        order_by: {
          ...(stateData?.nameSortOrder) ? {qualityMeasure: { name: stateData?.nameSortOrder}} : {},
          ...(!stateData?.nameSortOrder) ? {updatedOn: SORT_ORDER.DESC} : {},
        }
      },
    });
    return response;
  } catch (error) {
    updateStateData({ qualityMeasuresList: [], loading: false });
    return null;
  }
};

const fetchLastExecutionDate = async (offSet?: number) => {
  setStateData((prev) => {
    return {
      ...prev,
      executionDateLoading: true,
    };
  });
  const contractResp = await getLastExecutionDate({
    variables: {
      filterObjectTotal: {
        isLatest: {
          _eq: true
        },
        contractId: {
          _eq: contractId,
        },
        tenantId: {
          _eq: accountUuid
        }
      }
    }
  }).catch(()=> {
    setStateData((prev) => {
      return {
        ...prev,
        executionDateLoading: false,
      };
    });
  })
  if (contractResp?.data?.qmMeasureReports?.length) {
    const date = contractResp?.data?.qmMeasureReports[0]?.runTimestamp;
    const dateValue = formatExecutionDate(date);
    setStateData((prev) => {
      return {
        ...prev,
        executionDate: dateValue,
        executionDateLoading: false
      };
    });
  } else {
    setStateData((prev) => {
      return {
        ...prev,
        executionDate: '',
        executionDateLoading: false
      };
    });
  }
};

const fetchQualityMeasureReports = async (measureId: string, stratificationId?: string) => {
  const filterObject = {
    isLatest: { _eq: true },
    contractId: { _eq: contractId},
    measureId: { _eq: measureId},
    tenantId: { _eq: accountUuid },
    denominatorFlag: {_eq: true},
    ...(stratificationId && {
      stratification: {_eq: stratificationId},
    }),
  };

  const response = await qualityMeasureReportByMeasureId({
    variables: {
      filterObjectTotal: filterObject,
      filterObjectMeasureNotMeet: {...filterObject, numeratorFlag: {_eq: false}}
    }
  });
  return response.data;
};

const processQualityMeasures = (qualityMeasuresList: IQualityMeasuresList[], qmReportList: IQmReportList[]) => {
  qualityMeasuresList.forEach((measure) => {
    const qmReportsByMeasure = qmReportList.find(qmReport => qmReport.id === measure.measureId);
    if (qmReportsByMeasure?.id) {
      const qmReportsByNumeratorTrue = qmReportsByMeasure?.reports?.filter(qmReport => qmReport.numeratorFlag);
      const performanceRate = (qmReportsByNumeratorTrue?.length / qmReportsByMeasure?.totalMember) * 100;
      measure.performanceScore = `${performanceRate.toFixed(2)}%`; // Ensure consistent decimal formatting
      measure.totalPatients = qmReportsByMeasure?.totalMember;
      measure.careGap = qmReportsByMeasure?.measureNotMeet;
    }
  });

  return qualityMeasuresList;
};

const fetchQualityMeasureReportsByIds = async (measureIds: string[], qualityMeasuresList: IQualityMeasuresList[]) => {
  const allReports = await measureIds.reduce(async (reportAcc: any, measureId) => {
    const prevReport = await reportAcc;
    const measure = qualityMeasuresList.find((measure)=> {
      return measure.measureId === measureId
    })
    const isStratification = measure?.hasSinglePerformance;
    let stratificationId;
    if (measure?.qmMeasureStratification?.length === 1 && isStratification) {
      stratificationId = measure?.qmMeasureStratification[0]?.stratificationId;
    }
    const reports = await fetchQualityMeasureReports(measureId);
    const reportData = {
      id: measureId,
      reports: reports?.qmMeasureReports,
      totalMember: reports?.aggregateQmMeasureReportTotal?.aggregate?.count,
      measureNotMeet: reports?.aggregateQmMeasureReportMeasureNotMeet?.aggregate?.count,
    }
    return [...prevReport, reportData];
  }, []);
  return allReports;
};

const fetchQualityMeasures = async (offSet?: number) => {
  updateStateData({ loading: true });

  const qualityMeasuresResp = await fetchQualityMeasuresData(offSet);
  if (!qualityMeasuresResp || !qualityMeasuresResp.data?.qmContractQualityMeasures?.length) {
    updateStateData({ qualityMeasuresList: [], loading: false });
    props?.updateQualityMeasuresCount(0);
    return;
  }

  const qualityMeasuresList = getFormattedQualityMeasuresList(qualityMeasuresResp.data);
  updateStateData({
    qualityMeasuresList: qualityMeasuresList,
    loading: false,
    countLoading: true,
    total: qualityMeasuresResp.data.qggregateQmContractQualityMeasures?.aggregate?.count,
  });
  props?.updateQualityMeasuresCount(qualityMeasuresResp.data.qggregateQmContractQualityMeasures?.aggregate?.count);
  const measureIds = qualityMeasuresList.map(measure => measure.measureId);
  if (measureIds?.length) {
    try {
      const qmReportList = await fetchQualityMeasureReportsByIds(measureIds, qualityMeasuresList);
      const updatedQualityMeasuresList = processQualityMeasures(qualityMeasuresList, qmReportList);
      updateStateData({
        qualityMeasuresList: updatedQualityMeasuresList,
        loading: false,
        countLoading: false,
      });
    } catch (err: any) {
      updateStateData({
        loading: false,
        countLoading: false,
      });
      showToast({
        toastType: ToastType.error,
        message: err?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  } else {
    updateStateData({
      loading: false,
      countLoading: false,
    });
  }
};

  useEffect(() => {
    fetchQualityMeasures();
  }, [stateData.currentPage, stateData.pageSize, stateData.nameSortOrder]);

  useEffect(() => {
    if (stateData.currentPage !== 1) {
      setStateData((prev) => {
        return {
          ...prev,
          currentPage: 1,
          offSet: 0,
        };
      });
    } else {
      fetchQualityMeasures(0);
    }
  }, [searchString]);

  useEffect(()=> {
    fetchLastExecutionDate();
  },[contractId])

  return (
    <View>
      <QualityMeasuresList
        handlePageChange={handlePageChange}
        stateData={stateData}
        data={stateData.qualityMeasuresList}
        handleSortChange={handleSortChange}
      />
    </View>
  );
};
export default QualityMeasures;
