 import {useLazyQuery} from '@apollo/client';
import {Drawer, Steps, Table} from 'antd';
import {
  Pressable,
  Skeleton,
  Spinner,
  Text,
  View,
  VStack
} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {useParams} from 'react-router-dom';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {
  addOrUpdateCPTByTemplate,
  costSavingCptDiffByAnalyticId,
  costSavingCptExistByExcludingAnalyticId,
  createCostSavingTemplate
} from '../../../../../../services/CostSavingTemplates/CostSavingService';
import CostTemplateQueries from '../../../../../../services/CostSavingTemplates/CostTemplateQueries';
import {Colors} from '../../../../../../styles';
import {getAccountUUID} from '../../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../../utils/commonViewUtils';
import {getMlovListByCategory} from '../../../../../../utils/mlovUtils';
import {ModalActionInput} from '../../../../../common/ModalActionCommonComponent/ModalActionInput';
import {ModalActionSelect} from '../../../../../common/ModalActionCommonComponent/ModalActionSelect';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import CheckIcon from '../../../../../common/Svg/CheckIcon';
import {useToast} from '../../../../../Toast/ToastProvider';
import {COST_TYPE_CODE, SavingTypeConst} from '../helperFiles/CostTemplatesConst';
import {default as DownloadCpt} from '../helperFiles/DownloadCpt';
import {styles} from '../helperFiles/Styles';
import UploadCptCsv from '../helperFiles/UploadCptCsv';
import {convertToInteger, getTemplateCptQuery, removeDuplicatesCpts} from '../helperFiles/utils';
import {addedCptTableColumns} from '../helperFiles/ViewHelper';
import {
  IAccountCptBYAnalytics,
  IAccountCptBYAnalyticsResponse,
  IAddOrUpdateCostTemplateProps,
  IAddOrUpdateTemplateBody,
  ICostSavingTemplateCpt,
  ICostSavingTemplateCptsDataResponse,
  IDownloadCptHeader,
  IDownloadCptList,
  IPreviewCptTableData
} from '../interfaces/savingTemplatesInterface';
const {Step} = Steps;
interface ICreateTemplateResp  {
  data: {
    data: {
      templateId: string;
    }
  }
}
interface IUpdateTemplateResp  {
  data: {
    id: string;
  }
}
const AddOrUpdateCostTemplate = (props: IAddOrUpdateCostTemplateProps) => {
  const {actionCode, selectedData, analyticalResourceId, analyticalResource} = props;
  const {tabName} = useParams();
  const toast = useToast();
  const intl = useIntl()
  const isEditView = actionCode === COMMON_ACTION_CODES.EDIT ? true : false;
  const {width, height} = Dimensions.get('window');
  const showModifier = tabName === SavingTypeConst.VISITS_SAVINGS
  const finalHeight = height - 400;
  const finalWidth = width * 0.5;
  const accountUuid = getAccountUUID();
  const costTypeList = getMlovListByCategory(MLOV_CATEGORY.COST_CATEGORY).map((costType)=> {
    if (costType.code === COST_TYPE_CODE.OUR_COST) {
      return {
        ...costType,
        value: 'Our Service Cost'
      }
    }
    return {...costType}
  });
  const downloadCsv = [
    {label: 'CPT', key: 'cpt'},
    {label: 'NAME', key: 'name'},
    {label: 'COST', key: 'cost'},
  ];
  if (showModifier) {
    downloadCsv.push({label: 'MODIFIER', key: 'modifier'})
  }
  const [GET_CPT_ANALYTICS_RESOURCE] = useLazyQuery(
    CostTemplateQueries.GET_CPT_ANALYTICS_RESOURCE,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [DEFAULT_CPT_BY_ANALYTICS_COST_TYPE] = useLazyQuery(
    CostTemplateQueries.DEFAULT_CPT_BY_ANALYTICS_COST_TYPE,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [GET_ALL_CPT_BY_TEMPLATE] = useLazyQuery(
    CostTemplateQueries.GET_ALL_CPT_BY_TEMPLATE,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [showModal, setShowModal] = useState(true);
  const [stateData, setStateData] = useState({
    loading: false,
    templateData: {
      name: selectedData?.name || '',
      categoryId: selectedData?.costCategoryId || '',
      description: selectedData?.description || '',
      file: {} as any,
      currentCptList: [] as ICostSavingTemplateCpt[],
    },
    downloadableCpt: {
      cptList: [] as IDownloadCptList[],
      cptHeader: [] as IDownloadCptHeader[],
    },
    previewScreen: {
      columnList: [],
      dataList: [] as IPreviewCptTableData[],
      cptsMappedInfo: {
        existingCpts: [] as string[],
        newCpts: [] as string[],
        cptExistOtherAnalytics: [] as string[],
      },
      columnNameArrayList: [] as string[],
    },
    defaultCptList: [],
    downloadCptLoading: false,
    errors: {
      name: '',
      category: '',
      csv: '',
    },
  });
  const [current, setCurrent] = useState(0);
  const onCloseOrCancel = () => {
    props.onActionPerformed(COMMON_ACTION_CODES.CANCEL);
    setShowModal(false);
  };
  const onSaveActionDone = (data: any) => {
    props.onActionPerformed(COMMON_ACTION_CODES.SAVE, data);
    setShowModal(false);
  };
  const validateEntries = () => {
    const errors = {} as any;
    let isValid = true;
    if (!stateData.templateData?.name?.trim()) {
      errors.name = 'Title cannot be empty';
    }

    if (!stateData.templateData?.categoryId.trim()) {
      errors.category = 'Category cannot be empty';
    }

    if (!stateData.previewScreen?.dataList?.length) {
      errors.csv = 'Please upload csv';
    }
    if (errors.name || errors.category || errors.csv) {
      isValid = false;
    }

    return {errors, isValid};
  };
  const onSubmit = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const mappedCpt = stateData.previewScreen?.dataList?.map((cptObj) => {
      return {
        code: cptObj['CPT'],
        name: cptObj['NAME'],
        price: convertToInteger(cptObj['COST'] || ''),
        description: '',
        ...( showModifier && {modifier: cptObj['MODIFIER'] || ""}),
      };
    });
    const filterCpts = removeDuplicatesCpts(mappedCpt)
    const addTemplateBody: IAddOrUpdateTemplateBody = {
      name: stateData.templateData.name,
      description: stateData.templateData.description,
      costCategoryId: stateData.templateData.categoryId,
      analyticalResourceId: analyticalResourceId,
      cpts: [...filterCpts],
    };
    if (selectedData?.id && isEditView) {
      addTemplateBody.id = selectedData.id;
      addTemplateBody?.cpts?.forEach((cptObj: any) => {
        const isCptExist = stateData?.templateData?.currentCptList?.find(
          (currentCpt: any) => {
            if (!currentCpt?.accountCpt?.modifier) {
              currentCpt.accountCpt.modifier = ""
            }
            return (currentCpt?.accountCpt?.code === cptObj?.code && currentCpt?.accountCpt?.modifier  == cptObj?.modifier );
          }
        );
        if (isCptExist?.id) {
          cptObj.id = isCptExist?.id;
        }
      });
      const response: IUpdateTemplateResp = await addOrUpdateCPTByTemplate({...addTemplateBody});
      if (response.data?.id) {
        onSaveActionDone(response.data);
        toast({
          message: intl.formatMessage({id: 'costTemplateUpdatedSuccessfully'}),
          toastType: ToastType.success,
        });
      } else {
        toast({
          message: intl.formatMessage({id: 'costTemplateUpdatedError'}),
          toastType: ToastType.error,
        });
      }
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    } else {
      const response: ICreateTemplateResp = await createCostSavingTemplate({...addTemplateBody});
      if (response.data?.data?.templateId) {
        onSaveActionDone(response.data);
        toast({
          message: intl.formatMessage({id: 'costTemplateCreatedSuccessfully'}),
          toastType: ToastType.success,
        });
      } else {
        toast({
          message: intl.formatMessage({id: 'costTemplateCreatedError'}),
          toastType: ToastType.error,
        });
      } setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const getCptByResource = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        downloadCptLoading: true,
      };
    });
    const response: IAccountCptBYAnalyticsResponse =
      await GET_CPT_ANALYTICS_RESOURCE({
        variables: {
          analyticalResourceId: analyticalResourceId,
          accountUuid: accountUuid,
        },
      });
    if (response.data?.accountCpts?.length) {
      const accountCpts: IAccountCptBYAnalytics[] = response.data?.accountCpts;
      const formattedCpts = accountCpts.map((cpt) => {
        return {
          cpt: cpt?.code || '',
          name: cpt?.name || '',
          ...( showModifier && {modifier: cpt?.modifier || '',})
        };
      });
      setStateData((prev) => {
        return {
          ...prev,
          downloadableCpt: {
            cptList: formattedCpts,
            cptHeader: downloadCsv,
          },
          downloadCptLoading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          downloadableCpt: {
            cptList: [],
            cptHeader: downloadCsv,
          },
          downloadCptLoading: false,
        };
      });
    }
  };
  const getDefaultCptCodeAndPrice = async () => {
    const where = {
      accountUuid: {
        _eq: accountUuid,
      },
      analyticalResourceId: {
        _eq: analyticalResourceId,
      },
      isDefault: {
        _eq: true,
      },
      costCategoryId: {
        _eq: stateData.templateData.categoryId,
      },
      isDeleted: {
        _eq: false,
      },
    };
    const response = await DEFAULT_CPT_BY_ANALYTICS_COST_TYPE({
      variables: {
        where,
      },
    });
    if (
      response?.data?.costSavingTemplates?.length &&
      response?.data?.costSavingTemplates[0]?.costSavingTemplateCpt?.length
    ) {
      setStateData((prev) => {
        return {
          ...prev,
          defaultCptList:
            response?.data?.costSavingTemplates[0]?.costSavingTemplateCpt,
        };
      });
    }
  };
  const prefilledDataInEditMode = async () => {
    const templateId = selectedData?.id;
    const response: ICostSavingTemplateCptsDataResponse =
      await GET_ALL_CPT_BY_TEMPLATE({
        variables: {
          where: getTemplateCptQuery({
            accountUuid,
            templateId,
          }),
        },
      });
    if (response.data?.costSavingTemplateCpts?.length) {
      const formattedResponse = response.data?.costSavingTemplateCpts?.map(
        (cptObj) => {
          return {
            CPT: cptObj?.accountCpt?.code,
            NAME: cptObj?.accountCpt?.name,
            COST: cptObj?.price || 0,
            ...(showModifier && {MODIFIER: cptObj?.accountCpt?.modifier || ''})
          };
        }
      );
      const columnNameArrayList: any = Object.keys(formattedResponse[0]);
      const mutableColumnKeyList = [...columnNameArrayList];
      const columnListArray: string[] = [
        ...mutableColumnKeyList,
        'DEFAULT_COST',
      ];
      const columnList: any = addedCptTableColumns({columnListArray, width, showModifier});
      setStateData((prev) => {
        return {
          ...prev,
          previewScreen: {
            ...prev.previewScreen,
            dataList: formattedResponse,
            columnList: columnList,
            columnNameArrayList: columnNameArrayList,
          },
          templateData: {
            ...prev.templateData,
            file: {
              ...prev.templateData.file,
              name: `${prev.templateData?.name}.csv`,
            },
            currentCptList: response.data?.costSavingTemplateCpts,
          },
        };
      });
    }
  };
  const onActionPerformed = async (code: string, data?: any) => {
    switch (code) {
      case COMMON_ACTION_CODES.UPLOAD:

      setStateData((prev) => {
          return {
            ...prev,
            loading: true,
          };
        });

        data.keyAndValueData = data?.keyAndValueData?.map((keyAndValue: any)=> {
          let keyAndValueObj = {} as any
          data?.columnNameArrayList?.forEach((columnName: string)=> {
            keyAndValueObj = {
              ...keyAndValueObj,
              [columnName.toUpperCase()]: keyAndValue[columnName].replace(/^['"]|['"]$/g, '')
            }
          })
          return keyAndValueObj
        })

        data.columnNameArrayList = data?.columnNameArrayList?.map((columnName: string)=> {
          return columnName = columnName?.toUpperCase()
        })

        const isAllColumnExist = downloadCsv?.filter((item)=> {
            return data?.columnNameArrayList?.find((columnName: string)=> {
              return item.label === "MODIFIER" || item.label.trim() === columnName?.trim()
            })
        })

        if(isAllColumnExist?.length !== downloadCsv?.length) {
          setStateData((prev) => {
            return {
              ...prev,
              loading: false,
              errors: {
                ...prev.errors,
                csv: `You are attempting to upload an incorrect or invalid file.
Please ensure that the file format is correct and valid before proceeding. sequence : CPT | NAME | COST ${showModifier ? '| MODIFIER' : '' }`
              }
            };
          });
          return
        }

        const mutableColumnKeyList = [...data?.columnNameArrayList];
        const columnListArray: string[] = [
          ...mutableColumnKeyList,
          'DEFAULT_COST',
        ];

        const columnList: any = addedCptTableColumns({columnListArray, width, showModifier});

        setStateData((prev) => {
          return {
            ...prev,
            previewScreen: {
              ...prev.previewScreen,
              columnList: columnList,
              dataList: data.keyAndValueData,
              columnNameArrayList: data?.columnNameArrayList,
            },
            templateData: {
              ...prev.templateData,
              file: data.file,
            },
            errors: {
              ...prev.errors,
              csv: '',
            },
            loading: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.NEXT:
        const {isValid, errors} = validateEntries();
        if (!isValid) {
          setStateData((prev) => {
            return {
              ...prev,
              loading: false,
              errors: errors,
            };
          });
          break;
        }
        const cptCodeList = stateData.previewScreen?.dataList?.map((item) => {
          return item['CPT'] || '';
        });
        const response: any = await costSavingCptDiffByAnalyticId(
          analyticalResourceId,
          cptCodeList
        ).catch()
        const cptExistResponse: any = await costSavingCptExistByExcludingAnalyticId(
          analyticalResourceId,
          cptCodeList
        ).catch(()=> {
          setStateData((prev) => {
            return {
              ...prev,
              previewScreen: {
                ...prev.previewScreen,
                cptsMappedInfo: {
                  ...prev.previewScreen.cptsMappedInfo,
                  cptExistOtherAnalytics: cptExistData?.existingCpts || [] as string[]
                },
              },
            };
          });
        })
        const cptsMappedInfo = response?.data?.data;
        const cptExistData = cptExistResponse?.data?.data;

        const defaultCostAddDataList: IPreviewCptTableData[] =
          stateData.previewScreen.dataList?.map((newCpt: any) => {
            let cptExistInDefault: any = {};
            let cptExistInResource: any = {};
            if (stateData.defaultCptList?.length) {
              cptExistInDefault = stateData.defaultCptList?.find(
                (defaultCpt: any) => {
                  return defaultCpt?.accountCpt?.code === newCpt['CPT'];
                }
              );
            }
            if (stateData.downloadableCpt?.cptList?.length) {
              cptExistInResource = stateData.downloadableCpt?.cptList?.find(
                (defaultCpt: any) => {
                  return defaultCpt?.cpt === newCpt['CPT'];
                }
              );
            }
            if (!showModifier) {
              delete newCpt.modifier
            }
            return {
              ...newCpt,
              COST: newCpt.COST || '',
              DEFAULT_COST: cptExistInDefault?.price || 0,
              isNew: cptExistInResource?.cpt ? false : true,
            };
          });
        const filterDuplicateCptDataList = removeDuplicatesCpts(defaultCostAddDataList)
        setStateData((prev) => {
          return {
            ...prev,
            previewScreen: {
              ...prev.previewScreen,
              dataList: filterDuplicateCptDataList,
            },
          };
        });
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            previewScreen: {
              ...prev.previewScreen,
              cptsMappedInfo: {
                existingCpts: cptsMappedInfo?.existingCpts || 0,
                newCpts: cptsMappedInfo?.newCpts || 0,
                cptExistOtherAnalytics: cptExistData?.existingCpts || 0
              },
            },
          };
        });
        setCurrent(current + 1);
        break;
      default:
        break;
    }
  };

  const uploadFileView = (
    <>
      <View style={{width: '100%'}}>
        <ModalActionSelect
          label={'category'}
          isRequired={true}
          isDisabled={isEditView}
          selectedValue={stateData.templateData.categoryId}
          accessibilityLabel={'Select Cost Category'}
          placeholder={`Select Cost Category`}
          onValueChange={(itemValue: any) => {
            const selectedObj = costTypeList.find((item: any) => {
              return item.id === itemValue;
            });
            setStateData((prev) => {
              return {
                ...prev,
                templateData: {
                  ...prev.templateData,
                  categoryId: selectedObj?.id || '',
                },
                errors: {
                  ...prev.errors,
                  category: '',
                },
              };
            });
          }}
          isInvalid={stateData.errors.category}
          errors={stateData.errors.category}
          errorText={stateData.errors.category}
          data={costTypeList}
          selectItemProps={{
            key: 'id',
            label: 'value',
            value: 'id',
          }}
          customStyle={{flex: 1}}
        />
      </View>
      <ModalActionInput
        marginTop={2.4}
        fieldIs={'input'}
        label={'title'}
        leftMargin={'0'}
        placeholder={'Enter Template Name'}
        isRequired={true}
        isInvalid={stateData.errors.name}
        errors={stateData.errors.name}
        errorText={stateData.errors.name}
        value={stateData.templateData?.name}
        onChangeText={(value: any) => {
          setStateData((prev) => {
            return {
              ...prev,
              templateData: {
                ...prev.templateData,
                name: value,
              },
              errors: {
                ...prev.errors,
                name: '',
              },
            };
          });
        }}
        extraStyle={{flex: 1, placeHolderFontsize: 14}}
      />
      <ModalActionInput
        marginTop={2.4}
        label={'description'}
        leftMargin={'0'}
        placeholder={'Enter Template description'}
        value={stateData.templateData?.description}
        onChangeText={(value: any) => {
          setStateData((prev) => {
            return {
              ...prev,
              templateData: {
                ...prev.templateData,
                description: value,
              },
            };
          });
        }}
        extraStyle={{flex: 1, placeHolderFontsize: 14, Height: 150}}
      />
      <UploadCptCsv
        dataList={stateData.previewScreen.dataList}
        file={stateData.templateData.file}
        columnNameArrayList={stateData.previewScreen.columnNameArrayList}
        onActionPerformed={onActionPerformed}
      />
      {stateData.errors?.csv && (
        <Text
          style={{
            fontSize: 12,
            fontWeight: '500',
            color: Colors.error['500'],
            marginVertical: 8,
          }}
        >
          {stateData.errors?.csv}
        </Text>
      )}
      {stateData.downloadCptLoading ? (
        <Skeleton />
      ) : (
        <>
          <VStack
            style={{
              padding: 24,
              marginTop: 16,
              borderRadius: 8,
              flex: 1,
              borderWidth: 1,
              borderColor: Colors.Custom.Gray100,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <VStack
              flex={0.8}
              style={{flexDirection: 'row', alignItems: 'center'}}
            >
              <View
                style={{
                  flexDirection: 'row',
                  padding: 8,
                  alignItems: 'center',
                  borderWidth: 1,
                  borderColor: Colors.Custom.Gray100,
                  marginRight: 16,
                }}
                borderRadius={'50%'}
              >
                <FeatherIcon
                  color={Colors.Custom.Gray300}
                  name="file"
                  size={24}
                />
              </View>
              <View style={{maxWidth: '100%'}}>
                <Text
                  size={'smMedium'}
                  style={{color: Colors.FoldPixel.GRAY400, marginRight: 10}}
                >
                  Table example
                </Text>
                <Text
                  size={'smRegular'}
                  style={{color: Colors.FoldPixel.GRAY250}}
                  width={'80%'}
                >
                  You can download the attached example and use this to fill CPT
                  and cost details
                </Text>
              </View>
            </VStack>
            <View flex={0.15}>
              <DownloadCpt
                title={'Download me'}
                downloadableCpt={stateData.downloadableCpt}
              />
            </View>
          </VStack>
          <Text
            size={'xsBold'}
            style={{
              color: Colors.Custom.Gray400,
              width: '100%',
              marginTop: 8,
              paddingHorizontal: 4,
            }}
          >
            {`Keep your document on this sequence : CPT | NAME | COST ${showModifier ? '| MODIFIER' : '' }. Data might mismatch if the CSV is in a different format and column name or contains more columns. Also, enclose the CPT name in double quotes.`}
          </Text>
        </>
      )}
    </>
  );
  const previewList = () => {
    return (
      <View style={{marginTop: 12}}>
        <View
          style={{
            borderRadius: 8,
            borderWidth: 1,
            borderColor: Colors.Custom.Gray100,
            padding: 16,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderWidth: 1,
              borderColor: Colors.success['500'],
              marginRight: 16,
              backgroundColor: '#E5F4F3',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            borderRadius={'50%'}
          >
            <CheckIcon fill="#E5F4F3" stroke="#12B76A" />
          </View>
          <View>
            <Text size={'smMedium'} style={{color: Colors.Custom.Gray900}}>
              Mapping successfull
            </Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
               <View
                style={[
                  styles.cptCountInfo,
                  {backgroundColor: Colors.FoldPixel.GRAY50},
                ]}
              >
                <Text
                  size={'smMedium'}
                  style={{color: Colors.FoldPixel.GRAY300}}
                >
                  Mapped CPTs:{' '}
                  {stateData.previewScreen?.cptsMappedInfo?.existingCpts
                    ?.length || 0}
                </Text>
              </View>
              <View
                style={[
                  styles.cptCountInfo,
                  {backgroundColor: Colors.Custom.PendingTaskBackgroundColor},
                ]}
              >
                <Text
                  size={'smMedium'}
                  style={{color: Colors.FoldPixel.STATUS_IN_PROGRESS}}
                >
                  Not Mapped CPTs:{' '}
                  {stateData.previewScreen?.cptsMappedInfo?.newCpts?.length ||
                    0}
                </Text>
              </View>
              <View style={[styles.cptCountInfo,
              {backgroundColor: Colors.Custom.PendingTaskBackgroundColor}]}>
                <Text
                  size={'smMedium'}
                  style={{color: Colors.FoldPixel.STATUS_IN_PROGRESS}}
                >
                  CPTs exits for other category:{' '}
                  {stateData.previewScreen?.cptsMappedInfo
                    ?.cptExistOtherAnalytics?.length || 0}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Table
            scroll={{x: width * 0.3, y: finalHeight}}
            columns={stateData.previewScreen.columnList}
            dataSource={stateData.previewScreen.dataList}
            rowKey={(row: any) => `${row['CPT']}_cpt`}
            pagination={{
              position: ['bottomCenter']
            }}
            rowClassName={(record, index) =>
              `${index % 2 == 0 ? 'table-row-light' : ''} ${
                record.isNew ? 'new-cpt-highlight' : ''
              } cpt-drawer-table`
            }
          />
        </View>
      </View>
    );
  };
  const steps = [
    {
      title: (
        <Text
          size={'smMedium'}
          color={Colors.FoldPixel.GRAY400}
          style={{bottom: 3, position: 'relative'}}
        >
          {'Upload CSV'}
        </Text>
      ),
      content: uploadFileView,
    },
    {
      title: (
        <Text
          size={'smMedium'}
          color={Colors.FoldPixel.GRAY400}
          style={{bottom: 3, position: 'relative'}}
        >
          {'Preview'}
        </Text>
      ),
      content: previewList(),
    },
  ];

  useEffect(() => {
    getCptByResource();
    if (isEditView && selectedData?.id) {
      prefilledDataInEditMode();
    }
  }, []);

  useEffect(() => {
    if (stateData.templateData?.categoryId) {
      getDefaultCptCodeAndPrice();
    }
  }, [stateData.templateData.categoryId]);
  return (
    <Drawer
      visible={showModal}
      width={finalWidth}
      onClose={() => {
        onCloseOrCancel();
      }}
      title={
        <ModalActionTitle
          title={isEditView ? 'updateCostTemplate' : 'addCostTemplate'}
          subtitleBlock={
            <Text size='mdMedium' style={{color: Colors.FoldPixel.GRAY400}}>
              {analyticalResource}
            </Text>
          }
          titleColor={''}
          buttonList={[
            current && {
              show: true,
              id: 2,
              btnText: 'back',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isDisabled: stateData.loading ? true : false,
              onClick: () => {
                setCurrent(current - 1);
              },
            },
            {
              show: true,
              id: 2,
              btnText: current ? (isEditView ? 'update' : 'add') : 'next',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isDisabled: stateData.loading ? true : false,
              onClick: () => {
                if (current) {
                  onSubmit();
                } else {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      loading: true,
                    };
                  });
                  onActionPerformed(COMMON_ACTION_CODES.NEXT);
                }
              },
            },
          ]}
          rightButton={
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <View
                style={{
                  width: 1.5,
                  backgroundColor: Colors.Custom.Gray200,
                  marginHorizontal: 8,
                  height: 20,
                }}
              />
              <Pressable
                onPress={() => {
                  onCloseOrCancel();
                }}
              >
                <AntIcon name="close" size={20} color={Colors.Custom.Gray500} />
              </Pressable>
            </View>
          }
        />
      }
    >
      <VStack space={3}>
        {stateData.loading ? (
          <Spinner size="lg" style={styles.spinnerStyle} />
        ) : (
          <></>
        )}
        <View>
          <Steps
            //progressDot={renderProgressDot}
            labelPlacement="horizontal"
            current={current}
            style={{width: '70%'}}
          >
            {steps.map((item, index) => (
              <Step
                icon={
                  <View
                    style={{
                      width: 28,
                      height: 28,
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderWidth: 1,
                      borderColor: '#5020A0',
                      backgroundColor:
                        index === 0 && !current
                          ? '#FCFAFF'
                          : index === 0 && current
                          ? '#8C5AE2'
                          : index === 1 && current
                          ? '#FCFAFF'
                          : 'white',
                    }}
                    borderRadius={'50%'}
                  >
                    <Text
                      size={'exsSemibold'}
                      style={{
                        color:
                          index === 0 && !current
                            ? '#5020A0'
                            : index === 0 && current
                            ? 'white'
                            : index === 1 && current
                            ? '#5020A0'
                            : '#5020A0',
                      }}
                    >
                      {index + 1}
                    </Text>
                  </View>
                }
                key={index + 'step'}
                title={item.title}
              />
            ))}
          </Steps>
          <View style={{marginTop: 20}}> {steps[current]?.content}</View>
        </View>
      </VStack>
    </Drawer>
  );
};

export default AddOrUpdateCostTemplate;
