import {HStack, Text, useToast, View, VStack} from 'native-base';
import ReactPlayer from 'react-player';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {styles} from '../PatientPortalViewStyles';
import {ScriptContainer} from '../ScriptContainer';
import { Colors } from '../../../../styles';

const VideoAndScriptContainer = (props: {
  refVideos: any[];
  inlineScript: string;
  modalScript: string;
}) => {
  const showErrorToast = () => {
    showToast(toast, 'Copied to Clipboard', ToastType.info);
  };

  const copyScript = (scriptText: string) => {
    navigator.clipboard.writeText(scriptText);
    showErrorToast();
  };

  const toast = useToast();

  return (
    <View height={850} backgroundColor={'#fff'}>
      <HStack my={1} mx={4} flex={1}>
        <VStack style={styles.singleScriptContainer}>
          <View mb={4}>
            <Text size={'mdMedium'} color={Colors.FoldPixel.GRAY400}>{props?.refVideos[0].title}</Text>
            <ReactPlayer
              width={350}
              height={200}
              controls={true}
              url={props?.refVideos[0].url}
            />
          </View>
          <ScriptContainer
            onCopyScript={() => {
              copyScript(props?.inlineScript);
            }}
            scriptText={props?.inlineScript}
            wrapperStyle={styles.wrapperStyle}
          />
        </VStack>

        <VStack
          style={{
            margin: 10,
            flex: 0.5,
          }}
        >
          <View mb={4}>
            <Text size={'mdMedium'} color={Colors.FoldPixel.GRAY400}>{props?.refVideos[1].title}</Text>
            <ReactPlayer
              width={350}
              height={200}
              controls={true}
              url={props?.refVideos[1].url}
            />
          </View>
          <ScriptContainer
            onCopyScript={() => {
              copyScript(props?.modalScript);
            }}
            scriptText={props?.modalScript}
            wrapperStyle={styles.wrapperStyle}
          />
        </VStack>
      </HStack>
    </View>
  );
};

export default VideoAndScriptContainer;
