import { Select, Steps, Upload, notification } from 'antd';
import {
  Button, FormControl,
  HStack,
  Input,
  Text,
  useToast,
  View,
  VStack
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Linking, Pressable } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Fontisto from 'react-native-vector-icons/Fontisto';
import { BUTTON_TYPE, MLOV_CATEGORY, PERSON_TYPES } from '../../../../constants';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import CommonService from '../../../../services/CommonService/CommonService';
import { Colors } from '../../../../styles';
import { getAccountUUID, getContactUuid, getUserUUID } from '../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { Consent } from '../../../common/Consent/Consent';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { styles } from './BulkImportEmployeeStyles';
import { capitalizeText } from '../../../common/ContactRelationView/ContactRelationUtils';
import {getActiveContactTypeFormList} from '../../../common/ContactTypeSearch/contactTypeSearchUtils';
import {SelectCustomField} from '../../Contacts/CustomField/SelectCustomField';
import {CUSTOM_ATTRIBUTE_SELECT_TYPE, ENTITY_TYPE} from '../../Contacts/CustomField/CustomFieldConst';
import PlusIcon from '../../../common/Svg/PlusSvg';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {v4 as uuidV4} from 'uuid';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {getFormattedCustomAttributesValues, getSelectedCustomAttributeIds, isErrorInCustomAttributes} from '../../Contacts/CustomField/CustomFieldUtils';
import {IBulkCustomAttribute} from '../../Contacts/CustomField/interface';
import {ModalActionSubTitle} from '../../../common/ModalActionTitle/ModalActionSubTitle';
import LocationAutoComplete, { autoCompleteTypeCodes, ILocationSearch } from '../../../common/CalendarWidget/LocationAutoComplete/LocationAutoComplete';
import UserPracticeLocationQueries from '../../../../services/Location/UserPracticeLocationQueries';
import { useLazyQuery } from '@apollo/client';
import { IAccountPracticeLocationResp } from '../../../../services/Location/interfaces';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import {usePermissions} from '../../../CustomHooks/usePermissions';
import {USER_ACCESS_PERMISSION} from '../../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../SideMenuBar/SideBarConst';
const BulkImportEmployee = (props: any) => {
  const toast = useToast();
  const intl = useIntl();
  const { onModalClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedContactTypeId, setSelectedContactTypeId] = useState();
  const { Step } = Steps;
  const [file, setFile] = useState({} as any);
  const commonData = useContext(CommonDataContext);
  const contactUuid = getContactUuid() || '';
  const userUuid = getUserUUID();
  const accountUuid = getAccountUUID();

  const isValidFilename = (filename:string):boolean =>{
    const regex = /^[a-zA-Z0-9_]+(?=\.[a-zA-Z0-9]+$|$)/;
    if (filename) {
      return regex.test(filename);
    }
    return false;
  }

  const consentType = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.CONSENT
  );
  const customContactTypes = getActiveContactTypeFormList(commonData?.defaultContactTypes?.customContactTypes || [], true)
  const consentIds = consentType.map((consent) => {
    return consent?.id;
  });
  const [mappedValues, setMappedValues] = useState<any>({
    phoneNumber: '',
    email: '',
    firstName: '',
    middleName: '',
    gender: '',
    birthDate: '',
    lastName: '',
  });
  const [importError, setImportError] = useState<any>(null);
  const [importedCsvMapping, setImportedCsvMapping] = useState<any[]>([]);
  const [current, setCurrent] = useState(0);
  const [consent, setConsent] = useState(true);
  const [stateData, setStateData] = useState({
    customAttributes: [] as IBulkCustomAttribute[],
    practiceLocations: [] as any,
    locationUuid: '' as string,
    errors: {
      location: '',
    }
  })

  const { Option } = Select;
  const isEmployeeImport = props?.type == 'employee';
  const isLeadImport = props?.type == 'lead';
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const isLocationEnable = isMsoEnabled && isLeadImport;
  const subTitleHeader =
    props?.type == 'employee'
      ? 'bulkImportSubtitleEmployee'
      : 'Import the Prospect in bulk by uploading a spreadsheet.';
  const defaultMappingEmployee:any = [
    {
      title: 'First Name',
      subTitle: 'Member First Name',
      isRequired: true,
      value: 'firstName',
    },
    {
      title: 'Middle Name',
      subTitle: 'Member Middle Name',
      isRequired: false,
      value: 'middleName',
    },
    {
      title: 'Last Name',
      subTitle: 'Member Last Name',
      isRequired: true,
      value: 'lastName',
    },
    {
      title: 'Email',
      subTitle: 'Member Email',
      isRequired: true,
      value: 'email',
    },
    {
      title: 'Gender',
      subTitle: 'Member Gender',
      isRequired: isEmployeeImport,
      value: 'gender',
    },
    {
      title: 'Phone Number',
      subTitle: 'Member Phone Number',
      isRequired: false,
      value: 'phoneNumber',
    },
    {
      title: 'Birth Date',
      subTitle: 'Member Birth Date',
      isRequired: isEmployeeImport,
      value: 'birthDate',
    },
    ...(isEmployeeImport || !isLocationEnable ? [{
      title: 'Location',
      subTitle: 'Member Location',
      isRequired: false,
      value: 'location',
    }] : []),
    {
      title: 'Address',
      subTitle: 'Member Address',
      isRequired: false,
      value: 'addressLine1',
    },
    {
      title: 'Zipcode',
      subTitle: 'Member Zipcode',
      isRequired: false,
      value: 'zipcode',
    },
    ...(isEmployeeImport ? [] :[{
      title: 'Tags',
      subTitle: 'Column to convert tag',
      isRequired: false,
      mode: 'multiple',
      value: 'tagColumn',
    }]),
  ];
  const memberType = props?.type == 'employee' ? 'member' : 'prospect';

  const [getUserPracticeLocationsByUserId] = useLazyQuery<IAccountPracticeLocationResp>(
    UserPracticeLocationQueries.GetUserPracticeLocationsByUserId(),
    {
      variables: {accountUuid: accountUuid},
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(()=> {
    if (isLocationEnable) {
      getPracticeLocationsData();
    }
  }, []);

  const {check} = usePermissions();
  const permissionWiseLocationData = check(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER,
    ''
  );

  const userLocationData = commonData.accountLocationListWithEHR?.filter(
    (location) => {
      return permissionWiseLocationData?.allowedLocationIds?.includes(
        location?.uuid
      );
    }
  );

  const getPracticeLocationsData = async () => {
    const practiceLocation = userLocationData || []
    const practiceLocationObject = practiceLocation?.map((location: any) => {
      if (location.practiceLocation != null) {
        location.practiceLocation.accountLocationUuid = location.uuid;
        return location.practiceLocation;
      } else {
        return {} as any;
      }
    });
    setStateData((prev) => {
      return {
        ...prev,
        practiceLocations: practiceLocationObject,
      }
    });
  }

  const importCallGetMapping = () => {
    setImportError(null);
    if (isLocationEnable && !stateData?.locationUuid) {
      setStateData((prev) => {
        return {
          ...prev,
          errors: {
            location: 'Please select a location'
          },
        }
      });
      return;
    }
    const commonService = CommonService.getCommonServiceInstance();
    const workflowService = commonService.workflowService;
    const formData = new FormData();
    formData.append('import_file', file);

    workflowService
      .post(`/workflow/extract/column`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp) => {
        if (resp?.data) {
          setImportedCsvMapping(
            Object.keys(resp?.data).map((data) => {
              return {
                id: data,
                value: data,
              };
            })
          );
          setCurrent(current + 1);
        } else {
          setImportError('Invalid File');
        }
      })
      .catch((e) => {

        setImportError('Invalid File');
      });
  };

  const importContactCall = () => {
    if (
      !mappedValues?.firstName ||
      !mappedValues?.lastName ||
      !mappedValues?.email || (isEmployeeImport && (!mappedValues?.gender || !mappedValues.birthDate)) ||
      isErrorInCustomAttributes(stateData?.customAttributes)
    ) {
      setImportError('Please select required mapping');
      return;
    }
    const formattedCustomAttributesValues = getFormattedCustomAttributesValues(stateData?.customAttributes)
    let newMappedValues = mappedValues;
    if (consent) {
      newMappedValues = {
        ...mappedValues,
        customAttributes: formattedCustomAttributesValues,
        consentIds: consentIds || [],
        contactTypeId: selectedContactTypeId,
        practiceLocationUuid: stateData?.locationUuid || '',
      };
    }

    setImportError(null);
    const commonService = CommonService.getCommonServiceInstance();
    const workflowService = commonService.workflowService;
    const formData = new FormData();
    formData.append('import_file', file);
    formData.append('data', JSON.stringify({ keyMap: { ...newMappedValues } }));

    const url =
      props?.type == 'employee'
        ? `/workflow/import/employee/${props?.employerId}`
        : '/workflow/import/lead';
    setIsLoading(true);
    workflowService
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'user-uuid': contactUuid,
        },
      })
      .then((resp) => {
        if (resp?.data) {
        }
      })
      .catch((e) => {

        setIsLoading(false);
        setImportError('Something went wrong');
      });
    showToast(
      toast,
      `Started importing ${props?.type == 'employee' ? 'employee' : 'prospect'
      }. We will notify you once the import is complete.`,
      ToastType.info
    );
    onModalClose(true);
  };

  const onCustomAttributeSelected = (CustomAttribute: IBulkCustomAttribute) => {
    let updatedList: IBulkCustomAttribute[] = [];
    updatedList = stateData?.customAttributes.map((item: IBulkCustomAttribute) =>
        item?.id === CustomAttribute?.id
          ? {
            ...item,
            customAttributeId: CustomAttribute?.customAttributeId ? CustomAttribute?.customAttributeId : item?.customAttributeId,
            mappedValue: CustomAttribute?.mappedValue
          }
          : item
      );
    setStateData((prev)=> {
      return {
        ...prev,
        customAttributes: updatedList
      }
    })
  }

  const onActionPerformed = (actionCode: string, actionData: any, id: string) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ITEM_SELECT:
        const selectedCustomAttribute = stateData?.customAttributes?.find((customAttribute) => customAttribute?.id === id)
        onCustomAttributeSelected(
          {
            customAttributeId: actionData?.key,
            mappedValue: selectedCustomAttribute?.mappedValue,
            id
          },
        )
        break;
      case COMMON_ACTION_CODES.DELETE:
        const updatedCustomAttributeList = stateData?.customAttributes?.filter((customAttribute) => customAttribute?.id !== id);
        setStateData((prev)=> {
          return {
            ...prev,
            customAttributes: updatedCustomAttributeList
          }
        })
        break;
      case COMMON_ACTION_CODES.CUSTOM_FIELD_ADDED:
        const selectedAttribute = stateData?.customAttributes?.find((customAttribute) => customAttribute?.id === id)
        onCustomAttributeSelected(
          {
            customAttributeId: actionData[0]?.id,
            mappedValue: selectedAttribute?.mappedValue,
            id
          }
        )
        break;
      default:
        break;
    }
  }

  const addNewCustomAttribute = () => {
    const customAttributeObj : IBulkCustomAttribute = {
      id: uuidV4(),
      customAttributeId: '',
      mappedValue: ''
    }
    setStateData((prev) => {
      return {
        ...prev,
        customAttributes: [...prev.customAttributes, customAttributeObj]
      }
    })
  }

  const getPracticeLocations = () => {
    return (
      <LocationAutoComplete
        isRequired={isLocationEnable}
        height={40}
        autoCompleteType={autoCompleteTypeCodes.DEFAULT}
        selectedLocationUUID={stateData?.locationUuid
          ? stateData?.locationUuid
          : ''}
        allowClear={false}
        onChange={(location: ILocationSearch) => {
          setStateData((prev: any) => {
            return {
              ...prev,
              locationUuid: location.value,
              errors: {
                location: '',
              },
            };
          });
        }}
        accountLocations={stateData?.practiceLocations}
        labelText={"Select Location"}
        isInvalid={stateData?.errors?.location?.length ? true : false}
        errors={isLocationEnable &&
          stateData?.errors?.location
        }
        errorText={stateData?.errors?.location}
      />
    );
  };

  const renderUploadFile = (): JSX.Element => {
    return (
      <>
        <FormControl isRequired style={[styles.formElement]}>
          <FormControl.Label ml={0} style={styles.formLabel}>
            <DisplayText
              textLocalId={'Contact Type'}
              size={'smRegular'}
              extraStyles={{color: Colors.FoldPixel.GRAY250}}
            />
          </FormControl.Label>
          <Select
            style={{flex: 1}}
            allowClear={true}
            value={selectedContactTypeId}
            onChange={(value) => {
              setSelectedContactTypeId(value);
              setStateData((prev) => {
                return {
                  ...prev,
                  customAttributes: [],
                }
              })
            }}
          >
            {customContactTypes?.map((item: any) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.code === PERSON_TYPES.LEAD
                    ? 'Lead'
                    : item.code === PERSON_TYPES.CUSTOMER
                    ? 'Patient'
                    : capitalizeText(item.value)}
                </Select.Option>
              );
            })}
          </Select>
          {importError && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {importError}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
        {isLocationEnable ? (
          <View flex={1}>
            {getPracticeLocations()}
          </View>
        ) : (
          <></>
        )}
        <FormControl isRequired style={[styles.formElement]} isInvalid={importError}>
          <FormControl.Label ml={0} style={styles.formLabel}>
            <DisplayText
              textLocalId={
                props?.type == 'employee'
                  ? 'Upload multiple members through CSV'
                  : 'Upload multiple contacts through CSV'
              }
              size={'smRegular'}
              extraStyles={{color: Colors.FoldPixel.GRAY250}}
            />
          </FormControl.Label>
          <Upload
            onChange={(e) => {
              setImportError(null)
              if(isValidFilename(e?.file?.name)) {
                setFile(e.file);
              } else {
                setFile({name:""});
                setImportError(intl.formatMessage({id:"fileNameShouldNotContainAnySpecialCharacters"}))
              }
            }}
            style={{width: '100%'}}
            showUploadList={false}
            prefixCls=" "
            accept="text/csv"
            beforeUpload={() => false}
          >
            <Input
              borderRadius={8}
              height={44}
              value={file?.name}
              isReadOnly
              placeholder={intl.formatMessage({id:"clickHereToUploadFile"})}
              type="file"
              InputRightElement={
                <></>
              }
            />
            {importError && (
              <FormControl.ErrorMessage
                style={{
                marginTop:"0",
                }}
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {importError}
              </FormControl.ErrorMessage>
            )}
          </Upload>
          <Text
            marginTop={2}
            alignSelf={'flex-end'}
            flex={1}
            justifySelf={'flex-end'}
            color={'blue.500'}
            onPress={() => {
              Linking.openURL(
                isLocationEnable
                  ? 'https://generalfolddocs.s3.us-east-2.amazonaws.com/importleads.csv'
                  : 'https://generalfolddocs.s3.us-east-2.amazonaws.com/importcontacts.csv'
              ).catch(() => {});
            }}
          >
            Download sample
          </Text>
        </FormControl>
        <Text
          size={'smMedium'}
          style={{color: Colors.primary[700], marginTop: 20, marginLeft: 12}}
        ></Text>
      </>
    );
  };

  const renderFileMapping = (): JSX.Element => {
    return (
      <>
        {importError ? (
          <Text
            size={'smMedium'}
            style={{
              color: Colors.error[500],
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {importError}
          </Text>
        ) : null}
        <View
          style={{
            borderWidth: 1,
            borderRadius: 5,
            borderColor: Colors.Custom.Gray200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              borderBottomWidth: 1,
              borderColor: Colors.Custom.Gray200,
            }}
          >
            <View style={{ flex: 1 }}>
              <View
                style={{
                  backgroundColor: Colors.Custom.Gray50,
                  paddingHorizontal: 24,
                  paddingVertical: 13,
                  flex: 1,
                }}
              >
                <Text size={'smMedium'} style={{ color: Colors.primary[700] }}>
                  Map to Field
                </Text>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View
                style={{
                  backgroundColor: Colors.Custom.Gray50,
                  paddingHorizontal: 24,
                  paddingVertical: 13,
                }}
              >
                <Text size={'smMedium'} style={{ color: Colors.primary[700] }}>
                  Column Name
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'column',
              height: 400,
              overflow: 'scroll',
            }}
          >
            {defaultMappingEmployee.map((data:any, index: number) => {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    borderBottomWidth: defaultMappingEmployee?.length -1 > index ? 1 : 0,
                    borderColor: Colors.Custom.Gray200,
                  }}
                >
                  <View
                    style={{
                      paddingHorizontal: 24,
                      paddingVertical: 16,
                      flex: 1,
                    }}
                  >
                    <Text
                      size={'smMedium'}
                      style={{ color: Colors.primary[700] }}
                    >
                      {data?.title}{' '}
                      {data?.isRequired ? (
                        <Text style={{ color: Colors.error[500] }}>*</Text>
                      ) : (
                        ''
                      )}
                    </Text>
                    <Text
                      size={'smNormal'}
                      style={{ color: Colors.primary[500] }}
                    >
                      {data?.subTitle}
                    </Text>
                  </View>
                  <View
                    style={{
                      paddingHorizontal: 24,
                      paddingVertical: 16,
                      flex: 1,
                    }}
                  >
                    {importedCsvMapping && importedCsvMapping?.length ? (
                      <Select
                        mode={data?.mode}
                        status={data?.isRequired && importError && !mappedValues[data.value] ? 'error' : ''}
                        allowClear={true}
                        onClear={() => {
                          setMappedValues({
                            ...mappedValues,
                            [data.value]: '',
                          });
                        }}
                        value={mappedValues[data.value]}
                        showSearch={true}
                        filterOption={(input, option: any) => {
                          return (
                            option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        onChange={(itemValue) => {
                          setMappedValues({
                            ...mappedValues,
                            [data.value]: itemValue,
                          });

                        }}
                      >
                        {importedCsvMapping.map((data: any) => {
                          return (
                            <Option value={data.value} title={data.value} key={data.id}>{data.value}</Option>
                          );
                        })}

                      </Select>
                      // <Select
                      //   placeholder={'Select Field'}
                      //   _selectedItem={{
                      //     endIcon: <CheckIcon size="5" />,
                      //   }}
                      //   selectedValue={mappedValues[data.value]}
                      //   onValueChange={(itemValue) => {
                      //     setMappedValues({
                      //       ...mappedValues,
                      //       [data.value]: itemValue,
                      //     });
                      //   }}
                      // >
                      //   {importedCsvMapping.map((value: any) => {
                      //     return (
                      //       <Select.Item
                      //         key={value.id}
                      //         label={value.value}
                      //         value={value.id}
                      //       />
                      //     );
                      //   })}
                      // </Select>
                    ) : null}
                  </View>
                </View>
              );
            })}
          </View>
          <View style={{
            borderTopWidth: 1,
            borderColor: Colors.Custom.Gray200,
          }}>
            <ModalActionSubTitle
              marginTop={2}
              marginLeft={4}
              subTitle={'Custom Fields'}
              hideDivider={stateData?.customAttributes?.length ? false : true}
              textColor={Colors.primary[700]}
            />
            <View style={{
              maxHeight: 200,
              overflow: 'scroll',
            }}
            >
              {stateData?.customAttributes?.map((data: any) => {
                return (
                  <View
                    style={{
                      flexDirection: 'row',
                      borderBottomWidth: 1,
                      borderColor: Colors.Custom.Gray200,
                    }}
                  >
                    <View
                      style={{
                        paddingHorizontal: 24,
                        paddingVertical: 16,
                        flex: 1,
                      }}
                    >
                      <SelectCustomField
                        onActionPerformed={(actionCode: string, actionData: any) => {
                          onActionPerformed(actionCode, actionData, data?.id)
                        }}
                        entityType={ENTITY_TYPE.CONTACT}
                        contactTypeId={selectedContactTypeId}
                        selectedValue={data?.value}
                        importError={importError}
                        selectedCustomAttributesId={getSelectedCustomAttributeIds(stateData?.customAttributes)}
                        customAttributeSelectType={CUSTOM_ATTRIBUTE_SELECT_TYPE.SINGLE_SELECT}
                        placeholder={intl.formatMessage({id: 'selectCustomField'})}
                      />
                    </View>
                    <View
                      style={{
                        paddingHorizontal: 24,
                        paddingVertical: 16,
                        flex: 1,
                      }}
                    >
                      <View flexDirection={'row'} justifyContent={'space-between'}>
                        {importedCsvMapping && importedCsvMapping?.length ? (
                          <Select
                            style={{
                              flex: 0.92
                            }}
                            status={importError && !data?.mappedValue ? 'error' : ''}
                            allowClear={true}
                            value={data?.mappedValue}
                            showSearch={true}
                            filterOption={(input,option: any) => {
                              return (
                                option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                            onChange={(itemValue) => {
                              onCustomAttributeSelected(
                                {
                                  mappedValue: itemValue,
                                  id: data?.id
                                }
                              )
                            }}
                          >
                            {importedCsvMapping?.map((importedCsv) => {
                              return (
                                <Option value={importedCsv?.value} title={importedCsv?.value} key={importedCsv?.id}>{importedCsv?.value}</Option>
                              );
                            })}

                          </Select>
                        ) : null}
                        <Pressable
                          onPress={() => onActionPerformed(COMMON_ACTION_CODES.DELETE, {}, data?.id)}
                        >
                          <AntDesign style={{
                            marginTop: 10,
                            color: Colors.Custom.Gray400
                          }}
                            size={18}
                            name="close"
                          />
                        </Pressable>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
          <Pressable style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 6,
            marginLeft: 10,
          }}
            onPress={() => {
              addNewCustomAttribute();
            }}
          >
            <PlusIcon />
            <Text style={{
              color: Colors.Custom.mainPrimaryPurple,
              marginLeft: 4
            }}>
              {intl.formatMessage({id: 'addNew'})}
            </Text>
          </Pressable>
        </View>
      </>
    );
  };

  const steps = [
    {
      title: (
        <Text
          size={'xsMedium'}
          color={current == 0 ? Colors.primary[700] : Colors.Custom.Gray700}
        >
          {'Upload File'}
        </Text>
      ),
      content: renderUploadFile(),
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          color={current == 1 ? Colors.primary[700] : Colors.Custom.Gray700}
        >
          {'Field Mapping'}
        </Text>
      ),
      content: renderFileMapping(),
    },
  ];

  return (
    <View mt={0} mx={0}>
      <HStack flex={1}>
        <VStack justifySelf={'flex-start'} flex={0.5}>
          <TitleSubtitleView
            isTitleSubtitleOldView={true}
            isHideCommonButton={true}
            containerStyle={{ marginBottom: 0, marginLeft: 0, marginTop: 0 }}
            titleLabelId={'bulkImportText'}
            titleStyle={{ fontSize: 24 }}
          />
        </VStack>
        <HStack
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 0.5,
            //marginTop: 20,
          }}
        >
          <Button.Group justifyContent={'flex-end'}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onModalClose(false);
                },
                style: { marginRight: 10 },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            {current ? <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  setCurrent(current - 1);
                },
                style: { marginRight: 10 },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'back',
                }),
                withRightBorder: false,
                hideButton: !current,
              }}
            ></FoldButton> : <></>}
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  // if(!consent){
                  //   notification.error({
                  //     message:'Please select consent first.'
                  //   });
                  //   return
                  // }
                  if (current) {
                    importContactCall();
                  } else {
                    importCallGetMapping();
                  }
                },
                isDisabled: isLoading,
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: current ? 'Import' : 'next',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </HStack>
      </HStack>
      <Text
        size={'smMedium'}
        marginTop={1}
        color={Colors.FoldPixel.GRAY400}
        marginX={0}
      >
        {intl.formatMessage({ id: subTitleHeader })}
      </Text>

      <View style={{ marginHorizontal: 50, marginTop: 30 }}>
        <Steps
          //progressDot={renderProgressDot}
          labelPlacement="vertical"
          current={current}
        >
          {steps.map((item, index) => (
            <Step
              icon={
                <View
                  style={{
                    width: 32,
                    height: 32,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AntIcon
                    size={24}
                    name={index < current ? 'checkcircle' : 'checkcircleo'}
                    color={Colors.primary[700]}
                  />
                </View>
              }
              key={index + 'step'}
              title={item.title}
            />
          ))}
        </Steps>
      </View>

      <View style={{ marginTop: 20 }}> {steps[current]?.content}</View>
      <HStack justifyContent={'center'} alignItems={'center'}>
        <Fontisto name="info" size={20} />
        <DisplayText
          size={'smMedium'}
          extraStyles={{
            color: '#F37232',
            marginLeft: 20,
          }}
          textLocalId={`Login credentials are not sent to a ${memberType} created by bulk import. If automation is configured and active, it will be executed after the ${memberType} is created.`}
        />
      </HStack>
      <HStack style={{ marginTop: 20 }}>
        <Consent
          isChecked={consent}
          setConsent={() => {
            setConsent(!consent);
          }}
          textLocaleId={'consents'}
        />
      </HStack>
    </View>
  );
};

export default BulkImportEmployee;
