import { DISPLAY_DATE_FORMAT, WINDOW_EVENT_CODES } from '../../../../constants';
import {APPOINTMENT_STATUS_CODES} from '../../../../constants/MlovConst';
import { ICommonData } from '../../../../context/CommonDataContext';
import {getDateStrFromFormat, getDayBack, getMomentObj} from '../../../../utils/DateUtils';
import { IFilterTag } from '../../../common/ResponsiveTagRender/ResponsiveTagRender';
import { ADD_UPDATE_EVENTS } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {IMlov} from '../../../RightSideContainer/Journeys/JourneysOfCare/Table/Interfaces';
import {isAllowedToSignEHRNotesByRole} from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import {
  IAvailableEhr,
  ICareTimelineCategory,
  IPatientNoteOperationProps,
  NoteOperation,
} from '../interfaces';
import { checkIsFoldDrivenNotes } from '../PatientNotes/components/AddOrUpdateTemplate/helper';
import { IPatientNotesSelectedFilters } from '../PatientNotes/components/FilterPatientNotesDrawer';
import {EHRName} from '../PatientNotes/interfaces';
import {CARE_TIMELINE_CATEGORY, filterTagCodes} from './CareTimelineConstant';

export const getEhrConfig = (currentEHR: string): IAvailableEhr => {
  return {
    isAthena: currentEHR === EHRName.ATHENA,
    isElation: currentEHR === EHRName.ELATION,
    isFold: currentEHR === EHRName.FOLD,
    isCanvas: currentEHR === EHRName.CANVAS,
  };
};

export const getUserDetailByUserUuid = (userList: any[], userUuId: string) => {
  return (
    (userList || []).find(user => {
      return user?.uuid && user?.uuid === userUuId;
    }) || {}
  );
};

export const getEhrWiseNoteDefaultConfig = (
  ehrConfig: IAvailableEhr,
  additionalFlags: {
    foldVisitNoteWithEncountersEnabled: boolean
    contextData: ICommonData
  },
  externalUserId?: string,
  ): IPatientNoteOperationProps => {
  const {foldVisitNoteWithEncountersEnabled , contextData} = additionalFlags;
  return {
    isAllowToCreate: isAllowedToCreate(ehrConfig, {
      foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
      externalUserId,
    }),
    isAllowToPrint: allowNoteOperation(NoteOperation.PRINT, ehrConfig, {
      foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
      externalUserId,
      contextData
    }),
    isAllowToDelete: allowNoteOperation(NoteOperation.DELETE, ehrConfig, {
      foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
      externalUserId,
      contextData
    }),
    isAllowToUnlock: allowNoteOperation(NoteOperation.UNLOCK, ehrConfig, {
      foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
      externalUserId,
      contextData
    }),
    isAllowToDownload: allowNoteOperation(NoteOperation.DOWNLOAD, ehrConfig, {
      foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
      externalUserId,
      contextData
    }),
    isAllowToEdit: allowNoteOperation(NoteOperation.EDIT, ehrConfig, {
      foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
      externalUserId,
      contextData
    }),
    isAllowToAppointmentLink: allowNoteOperation(
      NoteOperation.APPOINTMENT_LINK,
      ehrConfig,
      {
        foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
        externalUserId,
        contextData
      }
    ),
    isAllowToFilterNote: allowNoteOperation(
      NoteOperation.FILTER_NOTE,
      ehrConfig,
      {
        foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
        externalUserId,
        contextData
      }
    ),
    isAllowToAutoSave: allowNoteOperation(NoteOperation.AUTO_SAVE, ehrConfig, {
      foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
      externalUserId,
      contextData
    }),
    isAllowToSign: allowNoteOperation(NoteOperation.SIGN, ehrConfig, {
      foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
      externalUserId,
      contextData
    }),
    isAllowToSave: allowNoteOperation(NoteOperation.SAVE, ehrConfig, {
      foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
      externalUserId,
      contextData
    }),
  };
};

export const getEhrWiseCareTimeCategory = (
  ehrConfig: IAvailableEhr,
): ICareTimelineCategory[] => {
  const {isElation, isAthena, isFold} = ehrConfig;
  return [
    ...(isAthena
      ? [
          {
            type: CARE_TIMELINE_CATEGORY.ENCOUNTER,
            isTimelineCategoryEnabled: isAthena,
          },
        ]
      : []),
    ...((isFold || isElation)
      ? [
          {
            type: CARE_TIMELINE_CATEGORY.PATIENT_NOTE,
            isTimelineCategoryEnabled: isElation || isFold,
          },
        ]
      : []),
  ];
};

export const getAppointmentFetchParams = (
  appointmentStatusList: IMlov[],
  contactUUID: string,
) => {
  const appointmentStatusIds = appointmentStatusList
    .filter(status => {
      return (
        status.code !== APPOINTMENT_STATUS_CODES.DECLINED &&
        status.code !== APPOINTMENT_STATUS_CODES.CANCELLED &&
        status.code !== APPOINTMENT_STATUS_CODES.CHECKED_OUT &&
        status.code !== APPOINTMENT_STATUS_CODES.PENDING &&
        status.code !== APPOINTMENT_STATUS_CODES.PROPOSED
      );
    })
    .map(status => status.id);
  return {
    startDateTime: getDayBack(7),
    endDateTime: getMomentObj(new Date()).endOf('day').toISOString(),
    statusIds: appointmentStatusIds,
    contactIds: [contactUUID],
  };
};

export const getFilterCount = (data:any) => {
  let filterCount = 0;
  const filters = data;
  if (!filters) {
    return filterCount;
  }

  if (filters.chiefComplaint?.displayName) {
    filterCount += 1;
  }
  if (filters.diagnosis?.text) {
    filterCount += 1;
  }
  if (filters.form?.id) {
    filterCount += 1;
  }
  if (filters.createdByUserIds?.length) {
    filterCount += 1;
  }
  if (filters.signedByUserIds?.length) {
    filterCount += 1;
  }
  if (filters?.lastAmendedAndSignedByUserIds?.length) {
    filterCount += 1;
  }
  if (filters.createdOnStartDate || filters.createdOnEndDate) {
    filterCount += 1;
  }
  if (filters.signedOnStartDate || filters.signedOnEndDate) {
    filterCount += 1;
  }
  if (filters.lastAmendedAndSignedOnStartDate ||  filters.lastAmendedAndSignedOnEndDate) {
    filterCount += 1;
  }
  if (filters.selectedStatusCodeValue?.length) {
    filterCount += 1;
  }

  return filterCount;
}


export const getFilterTags = (data:any) =>  {
  const filterTags: Array<IFilterTag> = [];

  const filters = data;
  if (!filters) {
    return filterTags;
  }
  if (filters.selectedStatusCodeValue?.length) {
    filterTags.push({
      code: filterTagCodes.STATUS,
      displayValue: `Status ${filters.selectedStatusCodeValue
        .map((item: {value: string}) => item?.value)
        ?.join(', ')}`,
    });
  }

  if (filters.createdByUsers?.length) {
    filterTags.push({
      code: filterTagCodes.CREATED_BY_USER_IDS,
      displayValue: `Created by ${filters.createdByUsers?.map((item: any) => item.value).join(', ')}`,
    });
  }
  if (filters.signedByUsers?.length) {
    filterTags.push({
      code: filterTagCodes.SIGNED_BY_USER_IDS,
      displayValue: `Signed by ${filters.signedByUsers?.map((item: any) => item.value).join(', ')}`,
    });
  }
  if (filters.lastAmendedAndSignedByUsers?.length) {
    filterTags.push({
      code: filterTagCodes.LAST_AMENDED_AND_SIGNED_BY_USER_IDS,
      displayValue: `Last amended and signed by ${filters.lastAmendedAndSignedByUsers?.map((item: any) => item.value).join(', ')}`,
    });
  }
  if (filters.createdOnStartDate || filters.createdOnEndDate) {
    let displayValue = 'Created';
    if (filters.createdOnStartDate) {
      displayValue += ` from ${getDateStrFromFormat(filters.createdOnStartDate, DISPLAY_DATE_FORMAT)}`;
    }
    if (filters.createdOnEndDate) {
      displayValue += ` to ${getDateStrFromFormat(filters.createdOnEndDate, DISPLAY_DATE_FORMAT)}`;
    }

    filterTags.push({
      code: filterTagCodes.CREATED_ON_DATE,
      displayValue: displayValue,
    });
  }
  if (filters.signedOnStartDate || filters.signedOnEndDate) {
    let displayValue = 'Signed';
    if (filters.signedOnStartDate) {
      displayValue += ` from ${getDateStrFromFormat(filters.signedOnStartDate, DISPLAY_DATE_FORMAT)}`;
    }
    if (filters.signedOnEndDate) {
      displayValue += ` to ${getDateStrFromFormat(filters.signedOnEndDate, DISPLAY_DATE_FORMAT)}`;
    }

    filterTags.push({
      code: filterTagCodes.SIGNED_ON_DATE,
      displayValue: displayValue,
    });
  }
  if (filters.lastAmendedAndSignedOnStartDate || filters.lastAmendedAndSignedOnEndDate) {
    let displayValue = 'Last amended and signed';
    if (filters.lastAmendedAndSignedOnStartDate) {
      displayValue += ` from ${getDateStrFromFormat(filters.lastAmendedAndSignedOnStartDate, DISPLAY_DATE_FORMAT)}`;
    }
    if (filters.lastAmendedAndSignedOnEndDate) {
      displayValue += ` to ${getDateStrFromFormat(filters.lastAmendedAndSignedOnEndDate, DISPLAY_DATE_FORMAT)}`;
    }

    filterTags.push({
      code: filterTagCodes.LAST_AMENDED_AND_SIGNED_ON_DATE,
      displayValue: displayValue,
    });
  }
  if (filters.diagnosis?.text) {
    filterTags.push({
      code: filterTagCodes.DIAGNOSIS,
      displayValue: `Diagnosis: ${filters.diagnosis.text}`,
    });
  }
  if (filters.chiefComplaint?.displayName) {
    filterTags.push({
      code: filterTagCodes.CHIEF_COMPLAINT,
      displayValue: `Chief complaint: ${filters.chiefComplaint.displayName}`,
    });
  }
  if (filters.form?.name) {
    filterTags.push({
      code: filterTagCodes.FORM,
      displayValue: `Template: ${filters.form.name}`,
    });
  }

  return filterTags;
}


export const allowFoldFormDrivenNotes = (ehrConfig: IAvailableEhr,additionalFlags:{
  foldVisitNoteEnabled:boolean
}) => {
  const {isAthena, isElation, isFold} = ehrConfig;
  const {foldVisitNoteEnabled} = additionalFlags;
  if (isFold) {
    return isFold;
  }
  if (isAthena) {
    return foldVisitNoteEnabled;
  }
  if (isElation) {
    return false;
  }
  return true;
}


const isAllowedToCreate = (
  ehrConfig: IAvailableEhr,
  additionalFlags: {
    foldVisitNoteEnabled?: boolean;
    externalUserId?: string;
  }
):boolean => {
  const {isAthena, isElation, isFold} = ehrConfig;
  const {foldVisitNoteEnabled, externalUserId} = additionalFlags;
  return isFold || (isAthena && !!foldVisitNoteEnabled) || (isElation)
};

export const isDefaultNoteAllowed = (ehrConfig: IAvailableEhr,) => {
  const {isAthena, isElation, isFold} = ehrConfig;
  return isFold || isAthena;
}

export const allowNoteOperation = (
  operationName: NoteOperation,
  ehrConfig: IAvailableEhr,
  additionalFlags: {
    foldVisitNoteEnabled?: boolean;
    externalUserId?: string;
    contextData: ICommonData
  },
  noteData?: any
) => {
  const {isAthena, isElation, isFold} = ehrConfig;
  const { contextData, foldVisitNoteEnabled } = additionalFlags;
  const isFoldDrivenNote = checkIsFoldDrivenNotes(noteData);
  switch (operationName) {
    case NoteOperation.CREATE:
      return isAllowedToCreate(ehrConfig, additionalFlags);
    case NoteOperation.PRINT:
      return isFoldDrivenNote;
    case NoteOperation.DELETE:
      return isFoldDrivenNote;
    case NoteOperation.UNLOCK:
      return (
        (isFoldDrivenNote) && isAllowedToSignEHRNotesByRole(
          contextData.currentUserRoles,
          contextData.userPermissions
        )
      );
    case NoteOperation.DOWNLOAD:
      return isFoldDrivenNote;
    case NoteOperation.EDIT:
      return isFoldDrivenNote;
    case NoteOperation.APPOINTMENT_LINK:
      return foldVisitNoteEnabled;
    case NoteOperation.FILTER_NOTE:
      return isFold || isAthena;
    case NoteOperation.AUTO_SAVE:
      return isFoldDrivenNote;
    case NoteOperation.SIGN:
      return (
        (isFoldDrivenNote) && isAllowedToSignEHRNotesByRole(
          contextData.currentUserRoles,
          contextData.userPermissions
        )
      );
    case NoteOperation.SAVE:
      return (
        isFold || isAthena || isElation
      );
    case NoteOperation.ORDERS:
      return isFold;
    case NoteOperation.READ_ONLY:
      return !isFoldDrivenNote;
    default:
      return false;
  }
};


export const getSearchParamsForNotes = (args: {
  patientId: string,
  foldSource?: boolean,
  filters?: IPatientNotesSelectedFilters,
  encounterId?: string,
  pagination?: {
    limit: number;
    offset: number;
  },
  appendSourceRegardlessOfFilters?: boolean,
}
) => {
  const searchParams = new URLSearchParams();
  const {
    patientId,
    foldSource,
    filters,
    encounterId,
    pagination,
    appendSourceRegardlessOfFilters,
   } = args
  searchParams.append('patient', patientId);
  if (encounterId) {
    searchParams.append('encounterId', encounterId);
  }
  if (pagination) {
    searchParams.append('limit', `${pagination.limit}`);
    searchParams.append('offset', `${pagination.offset}`);
  }
  if (typeof foldSource === 'boolean' && appendSourceRegardlessOfFilters) {
    searchParams.append('foldSource', `${foldSource}`);
  }
  if (filters) {
    if (filters.chiefComplaint?.displayName) {
      searchParams.append(
        'chief-complaint',
        filters.chiefComplaint.displayName
      );
    }
    if (filters.diagnosis?.text) {
      searchParams.append('diagnosis', filters.diagnosis?.text);
    }
    if (filters.form?.id) {
      searchParams.append('form-id', filters.form?.id);
    }
    if (filters.createdByUserIds?.length) {
      filters.createdByUserIds.forEach((createdByUserId) => {
        searchParams.append('author', `Practitioner/${createdByUserId}`);
      });
    }
    if (filters.signedByUserIds?.length) {
      filters.signedByUserIds.forEach((signedByUserId) => {
        searchParams.append('authenticator', `Practitioner/${signedByUserId}`);
      });
    }
    if (filters.createdOnStartDate) {
      searchParams.append('date', `ge${filters.createdOnStartDate}`);
    }
    if (filters.createdOnEndDate) {
      searchParams.append('date', `le${filters.createdOnEndDate}`);
    }
    if (filters.signedOnStartDate) {
      searchParams.append('signed-date', `ge${filters.signedOnStartDate}`);
    }
    if (filters.signedOnEndDate) {
      searchParams.append('signed-date', `le${filters.signedOnEndDate}`);
    }
    if (!!foldSource && !appendSourceRegardlessOfFilters) {
      searchParams.append('foldSource', `${foldSource}`);
    }
    if (filters.selectedStatusCodes?.length) {
      searchParams.append('doc-status', filters.selectedStatusCodes?.join(','));
    }
    if (filters.lastAmendedAndSignedByUserIds?.length) {
      filters.lastAmendedAndSignedByUserIds.forEach(
        (lastAmendedAndSignedByUserId) => {
          searchParams.append(
            'amended-by',
            `Practitioner/${lastAmendedAndSignedByUserId}`
          );
        }
      );
    }
    if (filters.lastAmendedAndSignedOnStartDate) {
      searchParams.append(
        'amended-date',
        `ge${filters.lastAmendedAndSignedOnStartDate}`
      );
    }
    if (filters.lastAmendedAndSignedOnEndDate) {
      searchParams.append(
        'amended-date',
        `le${filters.lastAmendedAndSignedOnEndDate}`
      );
    }
    if(filters?.locationId) {
      searchParams.append('location', `${filters?.locationId}`)
    }
    if (filters._sort) {
      searchParams.append('_sort', `${filters._sort}`);
    }
  }
  return searchParams;
};

enum FormComponents {
  VITALS = 'vitals',
  CHIEF_COMPLAINT = 'chiefComplaint',
  SUBJECTIVE_COMPLAINT = 'subjectiveComplaint',
  OBJECTIVE_FINDINGS = 'objectiveFindings',
  ASSESSMENT = 'assessment',
  PLAN = 'plan',
  TEXTFIELD = 'textField',
  TEXTAREA = 'textArea',
  EMAIL = 'email',
  ALLERGIES = 'allergies',
  SURGICAL_HISTORY = 'surgicalHistory',
  DIAGNOSIS = 'diagnosis',
  FAMILY_HISTORY = 'Family History',
  CONDITIONS = 'conditions',
  MEDICATIONS = 'medications',
  IMMUNIZATION = 'immunizations',
  PATIENT_ADDRESS = 'patientAddress',
  PATIENT_COMMUNICATION = 'patientCommunication',
  PATIENT_DEMOGRAPHICS = 'patientDemographics',
  EMERGENCY_CONTACT = 'emergencyContact',
  PATIENT_CONSENT = 'consentAndPreferredCommunication',
  CHECKBOX = 'checkbox',
  CURRENCY = 'currency',
  DATE = 'date',
  NUMBER = 'number',
  RADIO = 'radio',
  SELECT = 'select',
  PHONE_NUMBER = 'phoneNumber',
  SELECT_BOXES = 'selectBoxes',
  SIGNATURE = 'signature',
  PANEL = 'panel',
  PARAGRAPH = 'paragraph',
  COLUMNS = 'columns',
  IMAGE = 'image',
  FILE = 'file',
  CUSTOM_SURVEY = 'customsurvey',
  SOCIAL_HISTORY = 'socialHistory',
  RATING = 'rating',
  PAST_MEDICAL_HISTORY = 'pastMedicalHistory',
  PATIENT_IDENTIFIER = 'patientIdentifier',
  BARRIERS = 'barriers',
  INTERVENTION = 'intervention',
  GOALS = 'goals',
  SYMPTOM_MONITORING = 'symptomMonitoring',
  MEDICATION_MANAGEMENT = 'medicationManagement',
  DIET = 'diet',
  EXERCISE = 'exercise',
  HABITS = 'habits',
  HOME_MONITORING = 'homeMonitoring',
  LAB_MONITORING = 'labMonitoring',
  HEALTH_MAINTENANCE = 'healthMaintenance',
  NOTE_SUB_STATUS = 'noteSubStatus',
  NOTE_STATUS = 'noteStatus',
  DISCHARGE_DETAILS = 'dischargeDetails',
  CARE_PROGRAM_APPOINTMENTS = 'careProgramAppointments',
}

export const getBroadcastEventCode = (key: FormComponents) => {

   const SYNC_COMPONENTS = {
     [FormComponents.CONDITIONS]: ADD_UPDATE_EVENTS.PROBLEM,
     [FormComponents.ALLERGIES]: ADD_UPDATE_EVENTS.ALLERGY,
     [FormComponents.MEDICATIONS]: ADD_UPDATE_EVENTS.MED,
     [FormComponents.IMMUNIZATION]: ADD_UPDATE_EVENTS.IMMUNIZATION,
     [FormComponents.VITALS]: ADD_UPDATE_EVENTS.VITAL,
     [FormComponents.SURGICAL_HISTORY]: ADD_UPDATE_EVENTS.SURGICAL_HISTORY,
     [FormComponents.FAMILY_HISTORY]: ADD_UPDATE_EVENTS.FAMILY_HISTORY,
     [FormComponents.SOCIAL_HISTORY]: ADD_UPDATE_EVENTS.SOCIAL_HISTORY,
     [FormComponents.PATIENT_ADDRESS]:  WINDOW_EVENT_CODES.PATIENT_UPDATES,
     [FormComponents.PATIENT_COMMUNICATION]:  WINDOW_EVENT_CODES.PATIENT_UPDATES,
     [FormComponents.PATIENT_DEMOGRAPHICS]: WINDOW_EVENT_CODES.PATIENT_UPDATES,
     [FormComponents.PAST_MEDICAL_HISTORY]:
       ADD_UPDATE_EVENTS.PAST_MEDICAL_HISTORY,
     [FormComponents.EMERGENCY_CONTACT]: ADD_UPDATE_EVENTS.EMERGENCY_CONTACT,
     [FormComponents.BARRIERS]: ADD_UPDATE_EVENTS.BARRIER,
     [FormComponents.GOALS]: ADD_UPDATE_EVENTS.GOALS,
   };

  const syncComponents = SYNC_COMPONENTS[key as keyof typeof SYNC_COMPONENTS];
  if (syncComponents) {
    return syncComponents;
  }
}
