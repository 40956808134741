import { useState, useCallback, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LeadQueries } from '../../../../../../services';
import { getUserUUID } from '../../../../../../utils/commonUtils';
import { DayOptimizerContext } from '../../../../../../context-provider/DayOptimizerContext/context';
import { useToast } from 'native-base';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { IContactScore } from '../../interfaces';
import { IContactScoreAudit } from '../../../../MemebersView/interface';

const usePatientAdd = () => {
  const [contactsData, setContactsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSearchText, setCurrentSearchText] = useState('');

  const { state: dayOptimizerState } = useContext(DayOptimizerContext);
  const { selectedUser } = dayOptimizerState?.patientDashboard;
  const loggedInUserUuid = getUserUUID();
  const userUuid = selectedUser?.uuid ? selectedUser?.uuid : loggedInUserUuid;
  const toast = useToast();
  const selectedDate = dayOptimizerState?.patientDashboard?.selectedDate || new Date();
  const [searchContacts] = useLazyQuery(
    LeadQueries.searchContacts,
    {
      onCompleted: (data: any) => {
        let contacts = [];
        if (data?.searchContacts?.contacts?.length) {
          contacts = data?.searchContacts?.contacts
            .filter((contactItem: any) => contactItem?.uuid)
            .map((contact: any) => {
              return {
                label: contact.name,
                value: contact?.uuid,
                key: contact?.uuid,
                contactData: contact,
                rafScore: contact?.contactScore?.hccV28,
                patientId: contact?.id,
                isScheduled: contact?.dayOptimizerUserSchedules?.length > 0,
                contactScore: (contact?.contactScore || {}) as IContactScore,
                contactScoreAudit: (contact?.contactScoreAudit || []) as IContactScoreAudit[],
                patient: contact?.patient,
                practiceLocationAccountId: contact?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
              };
            });
        }
        setContactsData(contacts);
        setLoading(false);
      },
      onError: () => {
        setContactsData([]);
        setLoading(false);
      },
    }
  );

  const [addPatientToDayOptimizer] = useMutation(LeadQueries.addPatientToDayOptimizer, {
    onCompleted: (data) => {
      showToast(
        toast,
        `Patient successfully added for ${selectedDate.toLocaleDateString()} to Day Optimizer!`,
        ToastType.success,
        1500
      );
      searchPatientContacts(currentSearchText);
    },
    onError: (error) => {
      showToast(
        toast,
        'Error adding patient to Day Optimizer!',
        ToastType.error,
        1500
      );
      setLoading(false);
    }
  });

  const fetchInitialContacts = useCallback(() => {
    setLoading(true);
    searchContacts({
      variables: {
        params: {
          limit: 10,
          offset: 0,
          isActive: true,
          searchString: '',
          orderBy: {
            updated_at: 'desc',
          },
          dayOptimizerUserScheduleSelectFilters : {
            date: new Date().toISOString()?.split('T')[0],
            userid: [userUuid]
          }
        },
      },
    });
  }, [searchContacts]);

  const searchPatientContacts = useCallback((searchText: string) => {
    const trimmedSearchText = searchText?.trim?.();
    setCurrentSearchText(trimmedSearchText);
    setLoading(true);
    searchContacts({
      variables: {
        params: {
          limit: 10,
          offset: 0,
          isActive: true,
          searchString: trimmedSearchText,
          dayOptimizerUserScheduleSelectFilters: {
            date: new Date().toISOString()?.split('T')[0],
            userUuids: [userUuid]
          },
          orderBy: {
            updated_at: 'desc',
          },
        },
      },
    });
  }, [searchContacts, userUuid]);

  const addPatient = useCallback((contactUuid: string, date: string) => {
    setLoading(true);
    addPatientToDayOptimizer({
      variables: {
        params: {
          contactUuid,
          date,
          userUuid
        }
      }
    });
  }, [addPatientToDayOptimizer, userUuid]);

  return {
    contactsData,
    loading,
    fetchInitialContacts,
    searchPatientContacts,
    addPatient
  };
};

export default usePatientAdd;