import {
  USER_FETCH_LIST_SUCCESS,
  DATE_CHANGE,
  USER_SELECT,
  COLUMN_SHUFFLE_DROP_DOWN_LIST,
  GRID_CONFIGURATION_DATA,
  FILTER_CHANGED
} from './actionTypes';
import { PatientDashboardState, Action } from './interfaces';

const initialPatientDashboardState: PatientDashboardState = {
  error: null,
  userList: [],
  selectedUser: null,
  columnFilters: {
    rafFilter: null,
    prorityFilter: null,
    nameFilter: null,
    alertsFilter: null,
  },
  selectedDate: new Date(),
  columnShuffleDropDownList: [],
  gridConfigurationData: {},
  filter: {}
};

const patientDashboardReducer = (state: PatientDashboardState = initialPatientDashboardState, action: Action): PatientDashboardState => {
  switch (action.type) {
    case USER_FETCH_LIST_SUCCESS:
      return { ...state, userList: action.payload };
    case DATE_CHANGE:
      return {
        ...state,
        selectedDate: action.payload
      };
    case USER_SELECT:
      return {
        ...state,
        selectedUser: action.payload
      };
    case COLUMN_SHUFFLE_DROP_DOWN_LIST:
      return {
        ...state,
        columnShuffleDropDownList: action.payload
      };
    case GRID_CONFIGURATION_DATA:
      return {
        ...state,
        columnShuffleDropDownList: action.payload?.gridColumns,
        gridConfigurationData: action.payload
      };
    case FILTER_CHANGED:
      return {
        ...state,
        filter: action.payload
      };  
    default:
      return state;
  }
};

export { patientDashboardReducer, initialPatientDashboardState };