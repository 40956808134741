import { cloneDeep } from '@apollo/client/utilities';
import { Drawer, Skeleton, Table } from 'antd';
import {
  HStack,
  ScrollView,
  Spacer,
  Text,
  View,
  VStack
} from 'native-base';
import { useEffect, useState } from 'react';
import {useIntl} from 'react-intl';
import { displayName } from 'react-quill';
import { BUTTON_TYPE, DATE_FORMATS, DISPLAY_DATE_FORMAT } from '../../../../../constants';
import { FlowType } from '../../../../../context/WorkflowContext';
import { Colors } from '../../../../../styles';
import { getDateObjFromDateStrAndFormat, getMomentObj } from '../../../../../utils/DateUtils';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import { CommonCollapse } from '../../../Contacts/TeamMembers/LeftTeamContainer';
import { IProductDetailForm } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import { IUserInputField } from '../../../Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import SideMenu from '../../../Workflow/FlowComponent/StateNodes/SideMenu';
import { WorkflowRender } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowRenderView';
import { getNodeMetadata, INodeDataResponse } from '../JourneyMetadataService';
import {
  getActivitiesFromElements,
  getActivityColumns,
  getCareJourneyEndDate
} from './CareJourneyPreviewHelper';
import { IActivityDisplay, IActivityGroup } from './interface';
import { JOURNEY_START_TYPES } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';

export interface ICareJourneyActivitiesProps {
  nodes: any[];
  edges: any[];
  nodeMasterDataMap?: any;
  nodeMetaData?: any;
  journeyDetails: IProductDetailForm;
  displayConfig: {
    height?: string;
    showAssignee: boolean;
    canEdit: boolean;
    hideHeader?: boolean;
  };
  onNodeChange?: (nodes: any[]) => void;
  onEdgeChange?: (edges: any[]) => void;
  onNodeDataChange?: (nodeId: string, config: any) => void;
}

const CareJourneyActivities = (props: ICareJourneyActivitiesProps) => {
  const [activitiesState, setActivitiesState] = useState<{
    loading: boolean;
    isTreeVisible: boolean;
    activityGroups: IActivityGroup[];
    nodeData: INodeDataResponse;
    editNodeData?: any;
    edges: any[];
    nodes: any[];
  }>({
    loading: false,
    isTreeVisible: false,
    activityGroups: [],
    nodes: props.nodes,
    edges: props.edges,
    nodeData: {
      nodeMasterData: props.nodeMasterDataMap,
      nodeMetaData: props.nodeMetaData,
    },
  });
  const intl = useIntl();
  useEffect(() => {
    if (
      activitiesState.nodeData.nodeMasterData &&
      activitiesState.nodeData.nodeMetaData
    ) {
      setActivityGroups();
    }
    if (props.onNodeChange) {
      props.onNodeChange(activitiesState.nodes);
    }
  }, [activitiesState.nodes]);

  useEffect(() => {
    if (
      activitiesState.nodeData.nodeMasterData &&
      activitiesState.nodeData.nodeMetaData
    ) {
      setActivityGroups();
    } else {
      setActivitiesState((prev) => ({...prev, loading: true}));
      getNodeMetadata(
        FlowType.careJourney,
        (response) => {
          const groups = getActivitiesFromElements(
            [...activitiesState.nodes, ...activitiesState.edges],
            response.nodeMasterData?.nodeMap,
            response.nodeMetaData,
            getStartDate(),
            getEndDate()
          );
          setActivitiesState((prev) => ({
            ...prev,
            activityGroups: groups,
            loading: false,
            nodeData: {
              nodeMasterData: response.nodeMasterData?.nodeMap,
              nodeMetaData: response.nodeMetaData,
            },
          }));
        },
        () => {
          setActivitiesState((prev) => ({...prev, loading: false}));
        }
      );
    }
  }, []);

  const getStartDate = () => {
    if (props.journeyDetails.startDate) {
      if (props.journeyDetails.startType === JOURNEY_START_TYPES.FIXED) {
        // for fixed date journeys the value is already iso string
        return getMomentObj(props.journeyDetails.startDate).toDate();
      };
      return getDateObjFromDateStrAndFormat(
        props.journeyDetails.startDate,
        DISPLAY_DATE_FORMAT
      );
    }
  };

  const getEndDate = () => {
    const startDate = getStartDate();
    if (startDate) {
      return getCareJourneyEndDate(startDate, props.journeyDetails);
    }
    return undefined;
  };

  const setActivityGroups = () => {
    const groups = getActivitiesFromElements(
      [...activitiesState.nodes, ...activitiesState.edges],
      activitiesState.nodeData.nodeMasterData,
      activitiesState.nodeData.nodeMetaData,
      getStartDate(),
      getEndDate()
    );
    setActivitiesState((prev) => ({...prev, activityGroups: groups}));
  };

  const onCloseEditMode = () => {
    setActivitiesState((prev) => ({...prev, editNodeData: undefined}));
  };

  const onRowClicked = (activityData: IActivityDisplay) => {
    if (props.displayConfig.canEdit) {
      setActivitiesState((prev) => ({
        ...prev,
        editNodeData: activityData.dataObject,
      }));
    }
  };

  const onSaveEditMode = (data: any) => {
    const newUserInputFieldList: IUserInputField[] =
      data.userInputFieldMap.out.userInputFieldList || [];

    const updatedElements = activitiesState.nodes;
    updatedElements.forEach((element) => {
      if (element.id === activitiesState.editNodeData.id) {
        element.data.metaData.userInputFieldList = newUserInputFieldList;
      }
    });
    if (props.onNodeDataChange) {
      props.onNodeDataChange(
        activitiesState.editNodeData.id,
        newUserInputFieldList
      );
    }
    setActivitiesState((prev) => ({
      ...prev,
      nodes: [...updatedElements],
      editNodeData: undefined,
    }));
  };

  return (
    <>
      <VStack space={2}>
        {!props.displayConfig.hideHeader && (
          <HStack paddingX={2}>
            <Text fontSize="md" color={Colors.FoldPixel.GRAY400}>Care Journey Activities</Text>
            <Spacer />
             <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    setActivitiesState((prev) => ({...prev, isTreeVisible: true}));
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'journeyFlow',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
          </HStack>
        )}
        <ScrollView height={props.displayConfig?.height || '50vh'}>
          {activitiesState.loading && (
            <View>
              <Skeleton active />
            </View>
          )}
          {!activitiesState.loading && (
            <VStack space={4}>
              {activitiesState.activityGroups.map((activityGroup, index) => {
                return (
                  <CommonCollapse
                    key={index}
                    textLocalId={activityGroup.title}
                    btnList={[]}
                    style={{overflow: 'hidden'}}
                  >
                    <View>
                      <Table
                        rowClassName={(record, index) =>
                          index % 2 == 0 ? 'table-row-light' : ''
                        }
                        rowKey={(row) => row.id}
                        dataSource={activityGroup.list}
                        onRow={(activityData: IActivityDisplay) => {
                          return {
                            onClick: () => {
                              onRowClicked(activityData);
                            },
                          };
                        }}
                        columns={getActivityColumns({
                          showAssignee: props.displayConfig.showAssignee,
                          showActions: props.displayConfig.canEdit,
                        })}
                        pagination={false}
                        size="small"
                      />
                    </View>
                  </CommonCollapse>
                );
              })}
            </VStack>
          )}
        </ScrollView>
      </VStack>
      <Drawer
        destroyOnClose
        placement="right"
        onClose={() => {
          setActivitiesState((prev) => ({...prev, isTreeVisible: false}));
        }}
        visible={activitiesState.isTreeVisible}
        closable={false}
        width="85%"
        title={ <ModalActionTitle
          title={'Journey Flow'}
          marginLeft={4}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                setActivitiesState((prev) => ({...prev, isTreeVisible: false}));
              },
            },
          ]}
        />}
      >
        <View height="full">
          <WorkflowRender
            onNodeDataChange={() => {
              /**/
            }}
            isViewOnly={true}
            isWorkFlowEditable={false}
            flowType={FlowType.careJourney}
            libNodeIdList={[]}
            nodes={activitiesState.nodes || []}
            edges={activitiesState.edges || []}
            setEdges={() => {
              /**/
            }}
            setNodes={() => {
              /**/
            }}
          />
        </View>
      </Drawer>
      <Drawer
        title={
          <Text fontSize={24} fontWeight={400}>
            {(props.nodeMasterDataMap &&
              activitiesState.editNodeData?.nodeType &&
              props.nodeMasterDataMap[activitiesState.editNodeData.nodeType] &&
              props.nodeMasterDataMap[activitiesState.editNodeData.nodeType]
                .displayName) ||
              'Task'}
          </Text>
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          onCloseEditMode();
        }}
        visible={!!activitiesState.editNodeData}
        closable={false}
        width={'40%'}
      >
        {activitiesState.editNodeData && (
          <SideMenu
            userInputFieldMap={{
              out: {
                userInputFieldList: cloneDeep(
                  activitiesState.editNodeData.data.metaData.userInputFieldList
                ),
              },
            }}
            nodeType={activitiesState.editNodeData?.data?.nodeType || ''}
            isOpen={!!activitiesState.editNodeData}
            title={displayName}
            onClose={onCloseEditMode}
            onSave={onSaveEditMode}
          />
        )}
      </Drawer>
    </>
  );
};

export default CareJourneyActivities;
