import { AGE_ATTRIBUTES, DISPLAY_SLASH_DATE_FORMAT } from "../../../../constants";
import { IMlov, IVitalData } from "../../../../Interfaces";
import { Vital, VitalInputType } from "../../../../utils/VitalUtils";
import { CUSTOM_FIELD_DATATYPE, ICustomFieldResponse } from "../../Contacts/CustomField/interface";
import { IEhrFieldList, IEhrFieldType } from "../../Contacts/Leads/LeadView/AddOrUpdateLead/components/CustomEHRAttributes/interface";
import { IMembersDatas } from "../../MembersManagement/RulesInterface";
import { capitalizeText } from "./Helper";

export const getFormattedGroupData = (membersTableGroupData:IMembersDatas[], gendersList: IMlov[])=> {
    const formattedArray:any = [];
    membersTableGroupData.forEach((item:any)=> {
      const ageFormattedData = ''
      const gendersString = ''

      const temp = {
        id: item.id,
        name: item.name,
        description: item.description,
        executionFrequency: item.executionFrequency,
        age : ageFormattedData,
        accountUuid: item.account_uuid,
        rawData: item,
        genderText: gendersString,
        count: item.count || '',
        inActiveCount: item.inActiveCount || '',
        passedCount: item.passedCount || '',
        failedCount: item.failedCount || '',
        executionStatus: item.executionStatus || '',
        practionLocationNameList: item?.practionLocationNameList || [],
        locationFetched: item.locationFetched,
        ...(item?.nextExecutionDateTime ? { nextExecutionDateTime: item?.nextExecutionDateTime } : item?.executionDateTime && { executionDateTime: item.executionDateTime })
      }
      formattedArray.push(temp)
    })
  return formattedArray;
}

export const uniquePossibleValues = (possibleValues: any[], searchTerm?: string) => {
  const values: any = [];
  const seenCodes = new Set();
  possibleValues?.forEach((option) => {
    if (!seenCodes.has(option.code) &&
      (!searchTerm || option.display?.toLowerCase().includes(searchTerm?.toLocaleLowerCase()))
    ) {
      values.push({
        title: option.display,
        value: option.code,
      });
      seenCodes.add(option.code);
    }
  });
  return values;
};

export const mapEHRFieldsToSubfields = (fieldList: IEhrFieldList[]) => {
  const subfields: any = {};
  fieldList.forEach((field: IEhrFieldList, index: number) => {
    let fieldConfig:any = {
      label: field.label,
      type: field.type,
      valueSources: ['value'],
    };
    switch (field.type) {
      case IEhrFieldType.TEXT:
        fieldConfig = {
          ...fieldConfig,
          operators: [
            'equal',
            'not_equal',
            'like',
            'not_like',
          ],
        };
        break;
      case IEhrFieldType.CHOICE:
        fieldConfig = {
          ...fieldConfig,
          type: 'multiselect',
          operators: [
            'multiselect_equals',
            'multiselect_not_equals',
            'multiselect_contains',
            'multiselect_not_contains'
          ],
          fieldSettings: {
            listValues: uniquePossibleValues(field.possibleValues),
          },
        };
        break;
      case IEhrFieldType.DATE:
        fieldConfig = {
          ...fieldConfig,
          operators: [
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
            'between',
          ],
          mainWidgetProps: {
            customProps: {
              format: DISPLAY_SLASH_DATE_FORMAT
            }
          }
        };
        break;
      case IEhrFieldType.NUMBER:
        fieldConfig = {
          ...fieldConfig,
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
            'between',
          ],
        };
        break;
      default:
        break;
    }

    subfields[field.key] = fieldConfig;
  });
  return subfields;
};

export const mapCustomFieldToSubfields = (fieldList: ICustomFieldResponse[]) => {
  const subfields: any = {};
  fieldList.forEach((field: ICustomFieldResponse, index: number) => {
    let fieldConfig: any = {
      label: field.label,
      type: field.datatype.toLowerCase(),
      valueSources: ['value'],
    };

    switch (field.datatype) {
      case CUSTOM_FIELD_DATATYPE.EMAIL:
      case CUSTOM_FIELD_DATATYPE.TEXT:
        fieldConfig = {
          ...fieldConfig,
          type: 'text',
          operators: [
            'equal',
            'not_equal',
            'like',
            'not_like',
          ],
        };
        break;
      case CUSTOM_FIELD_DATATYPE.DATE:
        fieldConfig = {
          ...fieldConfig,
          operators: [
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
            'between',
          ],
          mainWidgetProps: {
            customProps: {
              format: DISPLAY_SLASH_DATE_FORMAT
            }
          }
        };
        break;
      case CUSTOM_FIELD_DATATYPE.NPI:
      case CUSTOM_FIELD_DATATYPE.NUMBER:
        fieldConfig = {
          ...fieldConfig,
          type: 'number',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
            'between',
          ],
        };
        break;
      default:
        break;
    }
    subfields[field.id] = fieldConfig;
  });
  return subfields;
};

export const getFrequencyValue = (rawData: any) => {
  const frequencyMap = new Map<string, string>([
    ['1d', '1 day'],
    ['1w', '1 week'],
    ['1M', '1 month'],
  ]);
  const frequency = rawData.executionFrequency || rawData.execution_frequency || rawData?.inputRuleDetails?.executionFrequency;
  return frequencyMap.get(frequency);
};

export const getAgeFormattedData = (ageData:any)=> {
  let returnString = ''
  if(AGE_ATTRIBUTES.BETWEEN_AGE === ageData.operator){
    returnString = `${ageData?.from} - ${ageData.to}`
  }else if(AGE_ATTRIBUTES.GREATER_THEN === ageData.operator){
    returnString = `> ${ageData.from}`
  }else if(AGE_ATTRIBUTES.LESS_THEN === ageData.operator){
    returnString = `< ${ageData.to}`
  }
  return returnString
}

export const getGenderFormattedString = (genderIds:string[],genderList: IMlov[])  => {
let returnString = ''
genderIds.forEach((id)=> {
  const genderName = genderList.find((item, index)=> {
    return item.id === id
  })
  returnString  = genderName?.code ?  returnString +  capitalizeText(genderName?.code) +  ', ' : ''
})
return returnString
}

export const getVitalListForRuleEngine = (vitalList: IVitalData[]) => {
  const list = vitalList;
  const bpIndex = list.findIndex((item) => item.loinc === Vital.bloodPressure);
  if (bpIndex > -1) {
    const bpVital = list.splice(bpIndex, 1)?.[0];
    if (bpVital) {
      list.push({
        loinc: Vital.systolicBloodPressure,
        unit: bpVital.unit,
        foldDisplay: 'Systolic Blood Pressure',
        inputType: VitalInputType.numeric,
        displayUnit: bpVital.displayUnit,
      });
      list.push({
        loinc: Vital.diastolicBloodPressure,
        unit: bpVital.unit,
        foldDisplay: 'Diastolic Blood Pressure',
        inputType: VitalInputType.numeric,
        displayUnit: bpVital.displayUnit,
      });
    }
  }
  return list;
}
