import {Text, View} from 'native-base';
import React from 'react';
import {ANALYTICS_RESOURCE} from '../../../../../../constants/StringConst';
import {Colors} from '../../../../../../styles';
import {IWidgetCardView} from '../../interface';
import { LG_CONFIG, XS_CONFIG } from '../../../../../../theme/FontConfig/TextFontSizeConfig';

const ListingWidgetWithList = (props: IWidgetCardView) => {
  const {sectionData, resourceData, loading} = props;

  const onDrillDownClick = (item: any) => {
    const filterData = {
      filterCode: 'CONDITION',
      filterValue: item?.code || item?.name
    };
    const name = item?.name
    props.onActionClick?.(sectionData.resourceCode || '', {filterData, name})
  }
  const getSubHeaderString = (item: any) => {
    let subHeaderText = ` ${item.patientCount} Patients`;
    const timesStr = item?.count > 1 ? `${item?.count} times` : 'once';
    switch (sectionData.resourceCode) {
      case ANALYTICS_RESOURCE.TOP_MEDICATIONS:
        if (item?.count) {
          subHeaderText = ` ${item.patientCount} Patients (Prescribed ${timesStr})`;
        }
        break;
      case ANALYTICS_RESOURCE.TOP_CARE_JOURNEYS:
        if (item?.count) {
          subHeaderText = ` ${item.patientCount} Patients (Prescribed ${timesStr})`;
        }
        break;
      case ANALYTICS_RESOURCE.TOP_LABS:
        if (item?.count) {
          subHeaderText = ` ${item.patientCount} Patients (Ordered ${timesStr})`;
        }
        break;
      case ANALYTICS_RESOURCE.TOP_IMAGING:
        if (item?.count) {
          subHeaderText = ` ${item.patientCount} Patients (Ordered ${timesStr})`;
        }
        break;
      case ANALYTICS_RESOURCE.TOP_SYMPTOMS_CHIEF_COMPLAINTS:
        if (item?.count) {
          subHeaderText = ` ${item.patientCount} Patients (Added ${timesStr})`;
        }
        break;
    }
    return subHeaderText;
  }
  return (
    <div className="common-style listing-widget-list-wrapper">
      {resourceData?.map((item: any, index: number) => {
        if (item.name) {
          return (
            <div
              key={index}
              style={{
                paddingLeft: 24,
                paddingRight: 24,
                paddingBottom: 16,
                paddingTop: 16,
                borderColor: "#E4E7EC",
                borderBottomWidth: resourceData.length == index + 1 ? 0 : 1,
              }}
            >
              <div
                style={{
                  color: '#000',
                  ...LG_CONFIG.lgBold
                }}
              >
                {item.name}
              </div>
              <div
                style={{
                  color: Colors.Custom.Gray500,
                  ...XS_CONFIG.xsMedium,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  onDrillDownClick(item)
                }}
              >
                {getSubHeaderString(item)}
              </div>
            </div>
          );
        } else {
          return null
        }
      })}
    </div>
  );
};

export default React.memo(ListingWidgetWithList);
