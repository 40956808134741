import { uuidv4 as uuidV4 } from '@antv/xflow-core';
import { notification } from 'antd';
import { debounce } from 'lodash';
import { HStack, Icon, Spinner, Text, VStack, View, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../../constants';
import { CARE_PROGRAM_STATUS_CODES } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import CareStudioService from '../../../../services/CommonService/CareStudioService';
import { Colors } from '../../../../styles';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import ConfirmOnExitOrReload from '../../../common/ConfirmExitWindow/ConfirmOnExitOrReload';
import CustomStepView from '../../../common/CustomStepView/CustomStepView';
import { IStep } from '../../../common/CustomStepView/interfaces';
import { FHAlertDialog } from '../../../common/FHAlertDialog';
import { JOURNEY_START_TYPES } from '../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import { filterDeletedCareJourneyLocation, filterDeletedRecommendedCareJourney, removeUnwantedKeyCloneJourney, removeUnwantedKeyDocumentObj } from '../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/ProductFormUtils';
import { IProductDetailForm } from '../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import { ICareJourneyStatusIDs } from '../../Sales/ProductsAndServices/Products/ProductTableView/ProductTable/interfaces';
import { getStartTypeIdByCode } from '../JourneysOfCare/PatientCareJourney/PatientCareJourneyHelper';
import { checkIfDetailsFilled, isNextButtonDisabled, isTabDisabled } from './AddOrUpdateJourneyHelper';
import AddOrUpdateJourneyTabs from './AddOrUpdateJourneyTabs/AddOrUpdateJourneyTabs';
import {
  getWorkflowSaveJourneyData,
  saveJourneyToWorkflow
} from './JourneyMetadataService';
import { JOURNEY_TABS } from './JourneyTabs';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { isMultiTenancyEnabled } from '../../../../utils/commonUtils';

export interface IJourneyDetailForm extends IProductDetailForm {
  nodes: any[];
  edges: any[];
  secondaryMasterId?: string;
}

const AddOrUpdateJourney = () => {
  const isEnableAutoSave = false;
  const navigate = useNavigate();
  const toast = useToast();
  const params = useParams();
  const intl = useIntl();
  const [details, setDetails] = useState<IJourneyDetailForm | undefined>();
  const [errorForDetailsForm, setErrorForDetailsForm] = useState({});
  const [errors, setErrors]: any = useState({});
  const [isTabOrBtnClicked, setIsTabOrBtnClicked] = useState(false);
  const [isTabLoading,setIsTabLoading] = useState(false)
  const [isJourneyDetailsLoading,setJourneyDetailsLoading] = useState(false)
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const context = useContext(CommonDataContext);
  const userSettings = context.userSettings;
  const isMultiTenantEnabled = isMultiTenancyEnabled(userSettings);

  const [screenConfig, setScreenConfig] = useState({
    loading: false,
    isSaveButtonDisabled: true,
    isAutoSaveEnabled: true,
    isAutoSaveLoading: false,
    selectedStep: JOURNEY_TABS.DETAIL_AND_MEMBERS,
    cancelConfirmDialog: false,
  });
  const location = useLocation();
  const locationData = location?.state;
  const clone = location?.state?.clone || false;

  const mlovData = useContext(CommonDataContext);
  const careJourneyStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_STATUS
    ) || [];
  const careJourneyStatusIds: ICareJourneyStatusIDs = {
    draft: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.DRAFT
    ),
    published: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.ACTIVE
    ),
  };
  const isDraft = details?.statusId === careJourneyStatusIds.draft;
  const isPublished = details?.statusId === careJourneyStatusIds.published && !clone;
  // const [details, setDetails] = useState<IJourneyDetailForm | undefined>();
  const [workflowMasterIdState, setWorkflowMasterIdState] = useState();

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  const onBackBtnClick = () => {
    navigateToJourneyPage();
  };

  const navigateToJourneyPage = (replace?: boolean) => {
    navigate('/admin/commerce/journeys', {
      replace: replace || false,
    });
  };

  const createOrUpdateCareJourney = async (
    isFromAutoSave: boolean,
    isPublished:boolean,
    abortSignal?: AbortSignal
  ) => {
    if (
      (screenConfig.isSaveButtonDisabled && !screenConfig.isAutoSaveEnabled) ||
      !details ||
      !details.nodes
    ) {
      return;
    }

    if (
      isFromAutoSave &&
      (!details.nodes?.length ||
        screenConfig.selectedStep !== JOURNEY_TABS.JOURNEY_DESIGN)
    ) {
      return;
    }

    if (!details.id) {
      details.id =
      params.id && params.id !== '-1' ? parseInt(params.id) : undefined;
    }
    if (!details.careJourneyWorkflowId) {
      details.careJourneyWorkflowId =
        params.careJourneyId || workflowMasterIdState;
    }

    if (isFromAutoSave) {
      setScreenConfig((prev) => ({...prev, isAutoSaveLoading: true, isSaveButtonDisabled: true}));
    } else {
      setScreenConfig((prev) => ({...prev, loading: true, isSaveButtonDisabled: true}));
    }

    try {
      if (isFromAutoSave && details.careJourneyWorkflowId) {
        saveJourneyToWorkflow(
          details,
          (response) => {
            setScreenConfig((prev) => ({...prev, isAutoSaveLoading: false}));
          },
          () => {
            setScreenConfig((prev) => ({...prev, isAutoSaveLoading: false}));
            throw new Error('error in auto save workflow');
          },
          abortSignal
        );
        return;
      } else {
        const careStudioService =
          CareStudioService.getCareStudioServiceInstance();
        if (!details.careJourneyWorkflowId && !details.secondaryMasterId) {
          details.secondaryMasterId = uuidV4();
        }
        const workflowParams = getWorkflowSaveJourneyData(details, isMsoEnabled)
        const response = await careStudioService.createCareJourneyService({
          data: {
            productId: details.id,
            careJourneyId: details.careJourneyWorkflowId,
            careJourneyParams: getCareJourneyDataForSave(details,isPublished, getStartTypeIdByCode(mlovData, details.startType || JOURNEY_START_TYPES.ROLLING)),
            workflowParams: workflowParams,
            // productParams: getSpreeProductSaveJourneyData(
            //   details,
            //   isFromAutoSave
            // ),
          },
          method: 'POST',
          signal: abortSignal,
        })

        setDetails((prevState) => {
          return (
            prevState && {
              ...prevState,
              careJourneyWorkflowId:
                response?.data?.workFlowResponse?.workflowMasterId,
              id: response?.data?.productResponse?.id,
            }
          );
        });
        setWorkflowMasterIdState(
          response?.data?.workFlowResponse?.workflowMasterId
        );
      }
    } catch (error) {
      showErrorToast();
    }
    if (isFromAutoSave) {
      setScreenConfig((prev) => ({...prev, isAutoSaveLoading: false, isSaveButtonDisabled: false}));
    } else {
      setScreenConfig((prev) => ({...prev, loading: false, isSaveButtonDisabled: false}));
    }
  };

  const onSubmit = async (isPublished:boolean) => {
    try {
      await createOrUpdateCareJourney(false,isPublished);
      navigateToJourneyPage();
      notification.success({
        message: `Journey ${params.careJourneyId ? 'Updated':'Created'} Successfully`,
        duration: 3.0,
      });
    } catch (error) {
      showErrorToast();
    }
    return;
  };

  const debounceOnSubmit = debounce((isPublished: boolean)=> {
    onSubmit(isPublished)
  }, 500);


  const getCareJourneyDataForSave = (data: IProductDetailForm, isPublished: boolean, startTypeId?: string) => {
    const filteredDocuments = data.careJourneyDocuments?.filter(
      (document) => !(clone && document.isDeleted)
    ) || [];
    return {
      id: params.careJourneyId,
      memberOutcome: data.memberOutcome,
      durationUnitId: data.duration?.unit,
      duration: data.duration?.value,
      description: data.description,
      title: data.name,
      businessOutcome: data.businessOutcome,
      statusId: isPublished ? careJourneyStatusIds.published : careJourneyStatusIds.draft,
      careJourneyTeam: {
        currentTeam: data.careTeam,
        prevTeam: data.prevCareTeam,
      },
      careJourneyTrackingResources: data?.careJourneyTrackingResources,
      careJourneyLocations: filterDeletedCareJourneyLocation(
        clone ? [] : data?.careJourneyLocations?.previousLocations || [],
        data?.careJourneyLocations?.locations || []
      ),
      recommendedCareJourneys: filterDeletedRecommendedCareJourney(
        clone ? [] : data?.recommendedCareJourney?.previousRecommendedCareJourney || [],
        data?.recommendedCareJourney?.recommendedCareJourney || []
      ),
      startTypeId: startTypeId,
      startDateTime: data.startDateTime,
      documents: clone ? removeUnwantedKeyCloneJourney(filteredDocuments) : removeUnwantedKeyDocumentObj(data?.careJourneyDocuments || [])
    };
  };

  const journeyDetailSteps = (): IStep[] => {
    const orderDetailTabView: IStep[] = [
      {
        key: JOURNEY_TABS.DETAIL_AND_MEMBERS,
        title: 'Details',
        // isDisabled: isTabDisabled(
        //   JOURNEY_TABS.DETAIL_AND_MEMBERS,
        //   false,
        //   details
        // ),
      },
      {
        key: JOURNEY_TABS.JOURNEY_DESIGN,
        title: 'Journey Design',
        isDisabled: isNextButtonDisabled(JOURNEY_TABS.CARE_TEAM, true, details) || errors.duplicate || errors.isLoading,
      },
      {
        key: JOURNEY_TABS.CARE_TEAM,
        title: 'Care Team',
        isDisabled: isNextButtonDisabled(JOURNEY_TABS.PREVIEW, true, details) || errors.duplicate || errors.isLoading,
      },
      {
        key: JOURNEY_TABS.PREVIEW,
        title: 'Preview',
        isDisabled: isNextButtonDisabled(JOURNEY_TABS.PREVIEW, true, details)|| errors.duplicate || errors.isLoading,
      },
    ];

    return orderDetailTabView;
  };

  const getNextStep = (currentStep: IStep): IStep => {
    const steps = journeyDetailSteps();
    const currentIndex = steps.findIndex(
      (item) => item.key === currentStep.key
    );

    if (steps.length > currentIndex + 1) {
      return steps[currentIndex + 1];
    }

    return steps[currentIndex];
  };

  const getStepByKey = (key: string) => {
    const steps = journeyDetailSteps();
    return steps.find((item) => item.key === key);
  };

  const isNextBtnDisabled = () => {
    const currentStep = getStepByKey(screenConfig.selectedStep);
    const nextStep = getNextStep(currentStep as IStep);
    if (errors.duplicate) {
      return errors.duplicate || isTabLoading;
    } else {
      return isTabDisabled(nextStep.key, false, details) || isTabLoading;
    }
  };

  const handleTabsDataLoading = (data: {[key: string]: boolean}) => {
    const check = Object.values(data).some((val) => val === true);
    setIsTabLoading(check);
  };

  const isOnLastStep = () => {
    const currentStep = getStepByKey(screenConfig.selectedStep) as IStep;
    const lastIndex = journeyDetailSteps().length - 1;
    return journeyDetailSteps()[lastIndex].key === currentStep?.key;
  };

  const getSubmitText = () => {
    return params.careJourneyId ? clone ? 'save' : 'update' : 'save';
  };

  const moveToNextStep = () => {
    const nextStep = getNextStep(
      getStepByKey(screenConfig.selectedStep) as IStep
    );

    setScreenConfig((prev) => {
      return {
        ...prev,
        selectedStep: nextStep.key,
      };
    });
  };

  const checkStepSelectionValidity = (step: any, index: number) => {
    const areDetailsFilled = checkIfDetailsFilled(details, false, isMsoEnabled);

    if (areDetailsFilled) {
      return true;
    }

    if(isJourneyDetailsLoading){
      return false
    }

    setIsTabOrBtnClicked(true);
    showToast(toast, 'Please fill in the mandatory details', ToastType.error);
    // setErrors(
    //   validateProductFormData(details, {
    //     isCareJourney: true,
    //     isAssignWorkflow: false,
    //   }).errors
    // );
    return false;
  };

  useEffect(() => {
    // const abortController = new AbortController();
    const timerId = setTimeout(() => {
      if (!screenConfig.isAutoSaveLoading && isEnableAutoSave) {
        createOrUpdateCareJourney(true,false); // abortController.signal);
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
      // abortController.abort();
    };
  }, [details?.updatedNodes, details?.nodes, details?.edges]);

  return (
  <ConfirmOnExitOrReload>
    <VStack>
      <FHAlertDialog message="Are you sure, you want to cancel?" isOpen={screenConfig.cancelConfirmDialog} buttonActions={[
          {
            textLocalId: 'No',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY
            },
            onPress: () => {
              setScreenConfig((prev) => {
                return {
                  ...prev,
                  cancelConfirmDialog: false,
                };
              });
            },
          },
          {
            textLocalId: 'Yes',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY
            },
            onPress: () => {
              navigateToJourneyPage(true)
            },
          },
        ]} />
      {screenConfig.loading ||
        (screenConfig.isAutoSaveLoading &&
          screenConfig.selectedStep === JOURNEY_TABS.PREVIEW && (
            <View
              zIndex={10}
              position="absolute"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              top="50%"
              left="50%"
            >
              <Spinner size="lg" />
            </View>
          ))}
      <View opacity={screenConfig.loading ? 0.5 : 1.0}>
        {/* <View backgroundColor={'#fff'}>
          <TitleSubtitleView
            showBackButton={true}
            containerStyle={{marginBottom: 0}}
            onBackClick={onBackBtnClick}
            titleLabelId="careJourney"
            subtitle="You can create/update care journey here"
          />
        </View> */}
        <View
        >
          <CustomStepView
            stepList={journeyDetailSteps()}
            details={details}
            onStepClick={(step) => {
              setScreenConfig((prev) => ({
                ...prev,
                selectedStep: step.key,
              }));
            }}
            selectedStep={getStepByKey(screenConfig.selectedStep)}
            canSelectStep={checkStepSelectionValidity}
            actionContainer={
              <View>
                <HStack space={2} alignItems={'center'}>
                  {screenConfig.isAutoSaveLoading && (
                    <View>
                      <Text fontStyle={'italic'} color={Colors.Custom.Gray500}>
                        Auto Saving journey...
                      </Text>
                    </View>
                  )}
                   <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.SECONDARY,
                      onPress: () => {
                        setScreenConfig((prev) => {
                          return {
                            ...prev,
                            cancelConfirmDialog: true,
                          };
                        });
                      }
                    }}
                    customProps={{
                      btnText: intl.formatMessage({
                        id: 'cancel',
                      }),
                      withRightBorder: false,
                    }}
                  ></FoldButton>
                  {isOnLastStep() && (!params.careJourneyId || (params.careJourneyId && isDraft)) && (
                      <FoldButton
                        nativeProps={{
                          variant: BUTTON_TYPE.PRIMARY,
                          onPress: () => {
                            if (isOnLastStep()) {
                              debounceOnSubmit(true);
                            } else if (checkIfDetailsFilled(details, false, isMsoEnabled || isMultiTenantEnabled)) {
                              moveToNextStep();
                            } else {
                              setIsTabOrBtnClicked(true);
                              showToast(
                                toast,
                                'Please fill in the mandatory details',
                                ToastType.error
                              );
                            }
                          },
                          leftIcon: (
                            <Icon
                              as={AntIcon}
                              name={'save'}
                              size="4"
                              color={Colors.Custom.mainPrimaryPurple}
                            />
                          ),
                          isDisabled: isOnLastStep() ? isNextBtnDisabled() || screenConfig.isSaveButtonDisabled: isNextBtnDisabled(),
                        }}
                        customProps={{
                          btnText: intl.formatMessage({
                            id: params.careJourneyId ?  'updateAndPublish' : 'saveAndPublish',
                          }),
                          withRightBorder: false,
                        }}
                      />
                  )}
                  <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.PRIMARY,
                      onPress: () => {
                        if (isOnLastStep()) {
                          debounceOnSubmit(isPublished);
                        } else if (checkIfDetailsFilled(details, false, isMsoEnabled || isMultiTenantEnabled)) {
                          moveToNextStep();
                        } else {
                          setIsTabOrBtnClicked(true);
                          showToast(
                            toast,
                            'Please fill in the mandatory details',
                            ToastType.error
                          );
                        }
                      },
                      leftIcon: isOnLastStep() ? (
                        <Icon
                          as={AntIcon}
                          name={'save'}
                          size="4"
                          color={Colors.Custom.mainPrimaryPurple}
                        />
                      ) : undefined,
                      isDisabled: isOnLastStep() ? isNextBtnDisabled() || screenConfig.isSaveButtonDisabled: isNextBtnDisabled(),
                    }}
                    customProps={{
                      btnText: intl.formatMessage({
                        id: isOnLastStep() ? getSubmitText() : 'next',
                      }),
                      withRightBorder: false,
                    }}
                  ></FoldButton>
                </HStack>
              </View>
            }
          />
        </View>
        <AddOrUpdateJourneyTabs
          setJourneyDetailsLoading={setJourneyDetailsLoading}
          handleTabsDataLoading={handleTabsDataLoading}
          isAssignWorkflow={false}
          selectedStep={screenConfig.selectedStep}
          errors={errors}
          setErrors={setErrors}
          isTabOrBtnClicked={isTabOrBtnClicked}
          onDetailChange={(journeyDetail, nodes, edges, isSaveDisabled) => {
            setDetails((prev) => {
              return {...prev, ...journeyDetail, nodes, edges};
            });
            setScreenConfig((prev) => ({
              ...prev,
              isSaveButtonDisabled: isSaveDisabled,
            }));
          }}
          careJourneyId = {locationData?.careJourneyId}
          isClone = {clone}
        />
      </View>
    </VStack>
  </ConfirmOnExitOrReload>
  );
};

export default AddOrUpdateJourney;
