import {Drawer, Empty, Table} from "antd";
import {useEffect, useState} from "react";
import {View, Text, Pressable, Dimensions} from "react-native";
import {Colors} from "../../../../../../../styles";
import {COMMON_ACTION_CODES} from "../../../../../../../constants/ActionConst";
import './style.css'
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Spinner} from "native-base";
import {getMeasureCriteriaList, measureCriteriaViewDetail} from "../../../../HelperFiles/TablesColumnsView";
import {QUALITY_MEASURES_PATIENT_TYPE} from "../../../../../../../constants";
import {useIntl} from "react-intl";
import {CARESTUDIO_APOLLO_CONTEXT} from "../../../../../../../constants/Configs";
import QualityMeasuresService from "../../../../../../../services/Contracts/QualityMeasures.service";
import {useApolloClient} from "@apollo/client";
import {getFormattedCriteriaListData, getPopulationCriteriaOrTitle} from "../../../../HelperFiles/utils";
import {ICriteriaList, IMeasureCriteriaDetailDrawer} from "../../../../HelperFiles/interface";

export const MeasureCriteriaDetailDrawer = (props: IMeasureCriteriaDetailDrawer) => {
  const { onDrawerClose, criteriaType, selectedMeasureId, isInverse} = props;
  const [stateData, setStateData] = useState({
    loading: false,
    criteriaList: [] as ICriteriaList[],
    expandedCriteriaKeys: [] as string[],
  })
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const intl = useIntl();
  const client = useApolloClient();
  const {height} = Dimensions.get('window');

  const onActionPerformed = (actionCode: string, actionData: any) => {
    switch(actionCode) {
      case COMMON_ACTION_CODES.ITEM_SELECT:
        const prevKeys: string[] = stateData?.expandedCriteriaKeys;
        const expandedCriteriaKeys = actionData?.expanded ? [...prevKeys, actionData?.id] : prevKeys.filter((key) => key !== actionData?.id);
        setStateData((prev)=>{
          return {
            ...prev,
            expandedCriteriaKeys
          }
        });
        break;
      default:
        break;
    }
  }

  const fetchQualityMeasureReports = async (measureId: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true
      }
    })
    try {
      const response = await client.query({
        query: QualityMeasuresService.getEvidenceList,
        variables: {
          evaluationId: measureId,
          populationCriteria: getPopulationCriteriaOrTitle(criteriaType),
        },
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
      });
      if (response.data?.qmMeasureEvidences?.length) {
        const formattedCriteriaListData = getFormattedCriteriaListData(response?.data?.qmMeasureEvidences, criteriaType, isInverse)
        setStateData((prev)=> {
          return {
            ...prev,
            criteriaList: formattedCriteriaListData,
            loading: false
          }
        })
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false
          }
        })
      }
    } catch (err) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false
        }
      })
    }
  };

  useEffect(()=> {
    const expandedRows= stateData?.criteriaList?.filter((criteria: ICriteriaList, index: number)=> {
      return criteria?.showViewDetails
    })?.map(filteredCriteria => filteredCriteria?.id);
    setExpandedRowKeys(expandedRows);
  },[stateData?.criteriaList])

  useEffect(() => {
    setStateData((prev)=> {
      return {
        ...prev,
        criteriaList: props?.qmMeasureEvidence,
      }
    })
  },[selectedMeasureId])

  return (
    <>
      <Drawer
        className='measureCriteriaDetailDrawer'
        width={'565px'}
        visible={true}
        onClose={() => {
          setStateData((prev)=> {
            return {
              ...prev,
              expandedCriteriaKeys: [],
            }
          })
          onDrawerClose?.(COMMON_ACTION_CODES.CLOSE);
        }}
        style={{
          borderRadius: 10
        }}
        title={
          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            height: 53,
            paddingHorizontal: 12,
          }}>
            <View>
              <Text style={{
                fontSize: 26,
                fontWeight: '500',
                lineHeight: 28.8
              }}>
                {intl.formatMessage({id: 'evidence'})}
              </Text>
            </View>
            <View style={{
              flex: 1,
              alignItems: 'flex-end'
            }}>
              <Pressable
                onPress={()=> {
                  onDrawerClose?.(COMMON_ACTION_CODES.CLOSE);
                }}
              >
                <AntIcon size={16} name="close" color={Colors.FoldPixel.GRAY300}/>
              </Pressable>
            </View>
          </View>
        }
      >
        <View style={{
          backgroundColor: Colors.FoldPixel.GRAY50,
          height: '100%'
        }}>
          <View style={{
            paddingHorizontal: 14,
            paddingVertical: 12,
          }}>
            <Text style={{
              fontSize: 14,
              fontWeight: '500',
              lineHeight: 16.8,
              color: Colors.FoldPixel.GRAY400,
              marginLeft: 4,
            }}>
              {intl.formatMessage({id: 'screeningHistory'})}
            </Text>
            <View style={{
              marginTop: 8,
              borderWidth: 0.5,
              borderColor: Colors.FoldPixel.GRAY150,
              borderRadius: 8,
              padding: 2,
              backgroundColor: 'white',
            }}>
              <Table
                scroll={{y: height-140}}
                columns={getMeasureCriteriaList(criteriaType, isInverse)}
                rowClassName={(record, index) => {
                  const isExpanded = expandedRowKeys.includes(record.id);
                  const isLastRow = index === stateData?.criteriaList?.length - 1;
                  return isExpanded ? 'measure-criteria-detail-expanded' : isLastRow ? 'measure-criteria-detail-last-row' : 'measure-criteria-detail';
                }}
                locale={{
                  emptyText: (
                    <Empty
                      description="No data available"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',}}
                    />
                  ),
                }}
                className="measure-criteria-detail-table"
                dataSource={stateData?.criteriaList}
                pagination={false}
                loading={{
                  spinning: stateData.loading,
                  indicator: (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 60
                      }}
                    >
                      <Spinner size={'lg'} />
                    </View>
                  ),
                }}
                rowSelection={undefined}
                rowKey={(record: any) => {
                  return record?.id;
                }}
                expandable={{
                  expandedRowRender: (record) => {
                    return measureCriteriaViewDetail(record, stateData?.expandedCriteriaKeys, onActionPerformed, criteriaType, isInverse);
                  },
                  expandIconColumnIndex: -1, // Hide the default expand icon column
                }}
                expandedRowKeys={expandedRowKeys}
              />
            </View>
          </View>
        </View>
      </Drawer>
    </>
  )
}