import {Select} from 'antd'
import {HStack, View, VStack, Text} from 'native-base'
import {useContext, useEffect, useState} from 'react'
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst'
import {CommonDataContext} from '../../../../context/CommonDataContext'
import {IConfiguration} from '../../../../Interfaces/CommonInterfaces'
import {getAccountId, getAccountUUID} from '../../../../utils/commonUtils'
import {ModalActionAntSelect} from '../../../common/ModalActionCommonComponent/ModalActionAntSelect'
import {SEND_EMAIL_OR_SMS_IF_CONTACT_IS_OFFLINE} from './DefaultGroupConsts'
import {IDefaultCommunicationGroupProps} from './interface'
import { Colors } from '../../../../styles'
const {Option, OptGroup} = Select;

const ContactOfflineMessageSetting = (props: {
  configObj: IConfiguration;
  onConfigSave: (params: {configCode: string; value: string}) => void;
}) => {
  const {configObj, onConfigSave} = props;
  const selectedValue = configObj?.accountConfigurations?.[0]?.value || configObj?.defaultValue || undefined;
  const [contactOfflineMessageSettingState, setContactOfflineMessageSettingState] = useState({
    configValue: selectedValue
  });
  const onConfigurationChange = (value: string) => {
    setContactOfflineMessageSettingState({
      configValue: value
    });
    onConfigSave({configCode: CONFIG_CODES.SEND_EMAIL_OR_SMS_ON_PATIENT_OFFLINE, value: value});
  };

  useEffect(() => {
    const selectedValue = configObj?.accountConfigurations?.[0]?.value || configObj?.defaultValue || '';
    setContactOfflineMessageSettingState({
      configValue: selectedValue
    });
  }, [selectedValue]);

  return (
    <View paddingTop={4}>
      <VStack>
        <VStack justifyContent={'center'}>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Send Email/SMS in case member is offline</Text>
        </VStack>
        <VStack  mt={2}>
          {/* <ModalActionAntSelect
            isRequired={false}
            allowClear={false}
            label={''}
            value={selectedValue}
            placeholder="Select Options"
            defaultValue={'OFF'}
            onChange={(value: any) => {
              onConfigurationChange(value);
            }}
            customStyle={{width: 600}}
            data={SEND_EMAIL_OR_SMS_IF_CONTACT_IS_OFFLINE}
            marginTop={2}
            optionProps={{
              key: 'code',
              value: 'value',
              label: 'label',
            }}
          /> */}
          <Select
            allowClear={false}
            style={reactStyles.selectStyle}
            showSearch={false}
            size={'large'}
            disabled={false}
            value={contactOfflineMessageSettingState?.configValue}
            defaultValue={'OFF'}
            onChange={(value: any) => {
              onConfigurationChange(value);
            }}
            placeholder={'Select Options'}
            maxTagCount={'responsive'}
          >
            {SEND_EMAIL_OR_SMS_IF_CONTACT_IS_OFFLINE.map((group) => (
              <OptGroup key={group.key} label={group.label}>
                {group.options.map((option) => (
                  <Option
                    key={option.code}
                    value={option.value}
                    label={option.label}
                  >
                    {option && option.label}
                  </Option>
                ))}
              </OptGroup>
            ))
            }
          </Select>
        </VStack>
      </VStack>
    </View>
  )
}

const reactStyles: Record<string, React.CSSProperties> = {
  selectStyle: {
    borderRadius: 8, 
    width: 600, 
    height: '40px'
  }
};

export default ContactOfflineMessageSetting;
