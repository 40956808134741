import { ColumnsType } from 'antd/lib/table';
import {Text} from 'native-base';
import {DATE_FORMATS} from '../../../../../constants/StringConst';
import {Colors} from '../../../../../styles';
import {getDateStrFromFormat} from '../../../../../utils/DateUtils';
import {capitalizeText} from '../../../../common/ContactRelationView/ContactRelationUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ResponseTableActions} from '../Helper/ResponseTableActions';
import {IResponseDataProps} from '../interfaces';

export const getcolumns = (
  actionFn: (actionCode: string, recordData: IResponseDataProps) => {}
) => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="shortCode"
        />
      ),
      dataIndex: 'shortCode',
      key: 'shortCode',
      width: '15%',
      fixed: 'left',
      render: (shortCode: string) => (
        <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
          {capitalizeText(shortCode || '')}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="content"
        />
      ),
      dataIndex: 'content',
      key: 'content',
      width: '40%',
      render: (content: string) => (
        <Text noOfLines={4} size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
          {content}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="createdAt"
        />
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      render: (createdAt: string) => (
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
          {getDateStrFromFormat(
            createdAt,
            DATE_FORMATS.CONVERSATION_DATE_FILTER
          )}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="category"
        />
      ),
      width: '10%',
      render: (data: any) => {
        const value = data?.type?.value || '- -';
        return (
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
            {value}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      fixed: 'right',
      render: (id: number, record: IResponseDataProps) => (
        <ResponseTableActions
          id={id}
          onActionPerformed={actionFn}
          record={record}
        />
      ),
    },
  ];

  return columns;
};
