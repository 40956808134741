import { StyleSheet } from 'react-native';
import { Colors } from '../../../../../styles';

export const patientDashboardStyles = StyleSheet.create({
  patientDetailView: {
    flex: 0.5,
    borderLeftColor: Colors.FoldPixel.GRAY150,
    borderLeftWidth: 0.5,
  },
  patientActionView: {
    width: 28,
    height: 6,
    alignItems: 'flex-end',
    justifyContent: 'center',
    flex: 1,
  },
  patientLoadMoreView: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.Custom.White,
    marginTop: 8,
    marginBottom: 6
  },
  patientLoadMoreText: {
    color: Colors.FoldPixel.PRIMARY300,
    marginLeft: 8,
    fontSize: 12
  }
});
