import { Call } from "../../../RNTwilioPhone/RNTwilioPhoneCall";
import { IContact } from "../../RightSideContainer/TeamInbox/Conversations/interfaces";

export interface PhoneCallingProps {
  incomingPhoneNumber?: string;
  outgoingPhoneNumber?: string;
  callSid?: string;
  callUuid?: string;
  endCall?: () => void;
  enableAction: boolean;

  callObject: any;
  direction: string;
  contactData: IContact;
  userList: [];
  onViewHeightChange?: (heightPercentage: number) => void;
  isUserLoggedIn: boolean;
  loggedInUserUuid: string
  anotherActiveCallOnHold?: Call | undefined;
  onCallSwitch?: () => void;
  isCallSwitched: boolean;
  toNumber?: string;
}

export enum UserListModalType {
  transfer = 'TRANSFER',
  conference = 'CONFERENCE',
  addedMembers = 'ADDED_MEMBERS',
}

export interface IConferenceEventData {
  data: IConferenceEvent;
  account_id: number;
}

export interface IConferenceEvent {
  eventCode: string;
  eventUserId: string;
  eventReason: string;
  conferenceSid: string;
}

export enum ConferenceEventCode {
  join = 'participant-join',
  leave = 'participant-leave',
}

export enum CallEventCode {
  callAnswered = 'in-progress',
}

export enum ConferenceEventReasonCode {
  completed = 'completed',
  noAnswer = 'no_answer',
  canceled = 'canceled',
  busy = 'busy',
  // This event is handled only for frontend and above events come from backend
  ringing = 'ringing',
  accepted = 'accepted',
  connected = 'connected'
}

export interface IParticipantHoldResponse {
  accountSid: string;
  callSid: string;
  label: string;
  callSidToCoach?: null;
  coaching?: boolean;
  conferenceSid: string;
  dateCreated?: string;
  dateUpdated?: string;
  endConferenceOnExit: boolean;
  muted: boolean;
  hold: boolean;
  startConferenceOnEnter: boolean;
  status?: string;
  uri?: string;
}

export interface IPariticpant {
  accountSid: string;
  callSid: string;
  label: string;
  callSidToCoach?: null;
  coaching: boolean;
  conferenceSid: string;
  dateCreated: string;
  dateUpdated: string;
  endConferenceOnExit: boolean;
  muted: boolean;
  hold: boolean;
  startConferenceOnEnter: boolean;
  status: string;
  uri: string;
  isHide: boolean;
}
