import React from 'react';
import {Drawer, Progress, Table} from 'antd';
import {Colors} from '../../../../../../styles';
import {MLOV_CATEGORY, SMALL_WINDOW_1500} from '../../../../../../constants';
import {Dimensions, Text, View} from 'react-native';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {SearchBar} from '../../../../../common/SearchBar';
import {
  BulkCareTeamOperationState,
  BulkViewCode,
  getBulkCareManagerTableColumns,
  getConflictCodesCounts,
  getDisplayColorByConflictCode,
  getDisplayTextByConflictCode,
  getUdpatedSeletedContactsByConflictCode,
} from './BulkAssignCareManagerViewHelper';
import './BulkAssignCareManagerView.css';
import {useContext, useEffect, useState} from 'react';
import {CareManagerAssignee} from '../../CareManagerAssignee';
import {useLazyQuery, useMutation} from '@apollo/client';
import {
  CARESTUDIO_APOLLO_CONTEXT,
  CARESTUDIO_PROXY_TO_CRM_CONTEXT,
} from '../../../../../../constants/Configs';
import {
  BULK_CARE_TEAM_OPERATION_STATUS,
  BULK_CARE_TEAM_UPDATE,
  GET_CARE_TEAM_MEMBER_TYPE,
  VALIDATE_BULK_CONTACTS,
} from '../../../../../../services/CareTeam/CareTeamQueries';
import {
  getMlovIdFromCode,
  getMlovListByCategory,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import {
  CARE_TEAM,
  CARE_TEAM_MEMBER_TYPE,
  SQS_MESSAGE_STATUS_CODES,
  USER_ROLE_CODES,
} from '../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {Image, Pressable, Spinner, VStack} from 'native-base';
import FoldButtonV2, {
  ButtonType,
} from '../../../../MiddleContainer/CarePlan/components/FoldButtonV2';
import Close from '../../../../../../assets/svg/Close';
import CareTeamMemberCard from '../CareTeamMemberCard';
import {formatRoleArray} from '../../CareTeamUtils';
import {SummaryCountView} from './SummaryCountView';
import {
  ISummaryCount,
  IContactConflict,
  ISelectedCareManager,
  IBulkCareTeamUpdateContact,
} from './interface';
import {localBroadcastEvent} from '../../../../../../utils/CustomEventHandler';
import {CONVERSATION_LOCAL_EVENT_CODES} from '../../../../../../constants/WebSocketConst';
import {showPopupNotification} from '../../../../../../utils/commonViewUtils';
import {BULK_CARE_TEAM_CONFLICT_CODE} from './BulkAssignCareManagerConstants';
import {IUser} from '../../../../../../Interfaces';
import {styles} from './BulkCareManagerViewStyles';
import { GET_SELECTED_USERS } from '../../../../../../services/Location/UserPracticeLocationQueries';

interface IBulkAssignCareManagerViewProps {
  visible: boolean;
  onClose: () => void;
  selectedContacts: IBulkCareTeamUpdateContact[];
  onSuccess: (selectedCareManager?: ISelectedCareManager) => void;
  fetchContactCareTeams?: boolean;
  ignoreBroadcast?: boolean;
}

interface IBulkAssignCareManagerViewState {
  transactionId?: string;
  bulkCareTeamOperationState: BulkCareTeamOperationState;
  processedCount: number;
  bulkViewCode: BulkViewCode;
  summaryViewCounts: ISummaryCount[];
  validationLoading?: boolean;
  searchText: string;
  allSelectedContacts: IBulkCareTeamUpdateContact[];
  selectedContacts: IBulkCareTeamUpdateContact[];
  doneLoading?: boolean;
}

export const BulkAssignCareManagerView = (
  props: IBulkAssignCareManagerViewProps
) => {
  const [sleectedCareManager, setSelectedCareManager] =
    useState<ISelectedCareManager>();
  const [componentState, setComponentState] =
    useState<IBulkAssignCareManagerViewState>({
      transactionId: '',
      bulkCareTeamOperationState: BulkCareTeamOperationState.none,
      processedCount: 0,
      bulkViewCode: BulkViewCode.configure,
      summaryViewCounts: [],
      searchText: '',
      selectedContacts: props.selectedContacts,
      allSelectedContacts: props.selectedContacts,
    });
  const [contactCareTeamsLoadingState, setContactCareTeamsLoadingState] = useState(false);

  const {width} = Dimensions.get('window');
  const isSmallScreen = width <= SMALL_WINDOW_1500;

  const [bulkCareTeamUpdate] = useMutation(BULK_CARE_TEAM_UPDATE, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [getCareTeamMemberByMemberType] = useLazyQuery(
    GET_CARE_TEAM_MEMBER_TYPE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const [bulkOperationStatus] = useLazyQuery(BULK_CARE_TEAM_OPERATION_STATUS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const commonContextData = useContext(CommonDataContext);

  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const careTeamMemberTypesList = getMlovListByCategory(
    MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
  );

  const careTeamMemberTypeId = getMlovIdFromCode(
    careTeamMemberTypesList,
    CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
  );

  const careTeamList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PRIMARY_CARE_TEAM
    ) || [];

  const sqsStatusList = getMlovListFromCategory(
    commonContextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.SQS_MESSAGE_STATUS,
    false
  );
  const SQS_STATUS_IDS = {
    processed: getMlovIdFromCode(
      sqsStatusList || [],
      SQS_MESSAGE_STATUS_CODES.PROCESSED
    ),
    failed: getMlovIdFromCode(
      sqsStatusList || [],
      SQS_MESSAGE_STATUS_CODES.FAILED
    ),
  };

  const [validateBulkContacts] = useLazyQuery(VALIDATE_BULK_CONTACTS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getSelectedAccountUsers] = useLazyQuery(GET_SELECTED_USERS, {
    fetchPolicy: 'no-cache',
  });
  
  const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);

  const fetchContactCareTeamsData = async () => {
    try {
      const contactIds = props.selectedContacts?.map((contact) => contact.uuid);
      const careTeamMemberTypeResponse = await getCareTeamMemberByMemberType({
        variables: {
          contactIds: contactIds,
          careTeamTypeId: careTeamTypeId,
        },
      });

      const response = careTeamMemberTypeResponse?.data?.careTeams;
      if (response?.length) {
        const careManagerUuids = response?.map((careTeam: any) => {
          const careManager = careTeam?.careTeamMemberType?.find((careTeamMember: any) => {
            return careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
          });
          return careManager?.userId;
        });

        const usersData = await getSelectedAccountUsers({
          variables: {
            userUuids: careManagerUuids,
            roleCode: USER_ROLE_CODES.EMPLOYER
          }
        });

        if (usersData?.data) {
          const careTeamMembers = response?.map((careTeam: any) => {
            const careManager = careTeam?.careTeamMemberType?.map((careTeamMember: any) => {
              if (careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.CARE_MANAGER) {
                const careManagerUser = usersData?.data?.users?.find((user: any) => user?.uuid === careTeamMember?.userId);
                return {
                  ...careTeamMember,
                  userName: careManagerUser?.name
                }
              }
              return careTeamMember;
            });
            return {
              ...careTeam,
              careTeamMemberType: careManager,
            };
          });  

          const updatedContactsData = componentState.selectedContacts?.map((contact) => {
            const careTeam = careTeamMembers?.find((careTeam: any) => careTeam?.contactId === contact?.uuid);
            return {
              ...contact,
              careTeam,
            }
          });
  
          setComponentState((prev) => ({
            ...prev,
            selectedContacts: updatedContactsData,
            allSelectedContacts: updatedContactsData,
          }));
        }
      }
    } catch (error) {
    } finally {
      setContactCareTeamsLoadingState(false);
    }
  }

  useEffect(() => {
    if (props.fetchContactCareTeams) {
      setContactCareTeamsLoadingState(true);
      fetchContactCareTeamsData();
    }
  }, [props?.selectedContacts]);

  useEffect(() => {
    if (componentState.transactionId) {
      setComponentState((prev) => ({...prev, processedCount: 0}));
      getBulkOperationStatus();
    }
  }, [componentState.transactionId]);

  useEffect(() => {
    if (sleectedCareManager) {
      callValidateBulkContacts();
    }
  }, [sleectedCareManager]);

  useEffect(() => {
    setComponentState((prev) => {
      const updatedSummaryCounts = prev.summaryViewCounts?.map(
        (summaryCount) => {
          if (
            summaryCount.conflictCode ===
            BULK_CARE_TEAM_CONFLICT_CODE.SELECTED_COUNT
          ) {
            summaryCount.count = componentState.selectedContacts?.length;
          }
          return summaryCount;
        }
      );
      return {
        ...prev,
        summaryViewCounts: updatedSummaryCounts,
      };
    });
  }, [componentState.selectedContacts?.length]);

  const callValidateBulkContacts = async () => {
    setComponentState((prev) => ({
      ...prev,
      validationLoading: true,
    }));
    const contactIds = props.selectedContacts?.map((contact) => contact.uuid);
    try {
      const response = await validateBulkContacts({
        variables: {
          data: {
            contactIds: contactIds,
            userId: sleectedCareManager?.uuid,
            careTeamMemberTypeId: careTeamMemberTypeId,
            careTeamTypeId: careTeamTypeId,
          },
        },
        fetchPolicy: 'no-cache',
      });
      setComponentState((prev) => ({
        ...prev,
        validationLoading: false,
      }));

      if (response?.data?.validateAddOrUpdateBulkCareTeamOperation?.contacts) {
        const summaryCounts = getConflictCodesCounts(
          response?.data?.validateAddOrUpdateBulkCareTeamOperation?.contacts,
          componentState.allSelectedContacts || []
        );

        const updatedSlectedContacts = componentState.allSelectedContacts?.map(
          (contact) => {
            const contactWithConflictCode =
              response?.data?.validateAddOrUpdateBulkCareTeamOperation?.contacts?.find(
                (contactConflict: any) => contactConflict.id === contact.uuid
              );
            if (contactWithConflictCode) {
              contact.conflictCode = contactWithConflictCode?.conflictCode;
            }
            return contact;
          }
        );

        setComponentState((prev) => ({
          ...prev,
          selectedContacts: updatedSlectedContacts,
          allSelectedContacts: updatedSlectedContacts,
        }));

        setComponentState((prev) => ({
          ...prev,
          summaryViewCounts: summaryCounts,
        }));
      }
    } catch (error) {
      showPopupNotification(
        'Error',
        'Something went wrong while validating patients',
        'error'
      );
      setComponentState((prev) => ({...prev, validationLoading: false}));
    }
  };

  const getBulkOperationStatus = async () => {
    try {
      const total = getValidSelectedContactIds()?.length;
      if (
        componentState.bulkCareTeamOperationState ===
        BulkCareTeamOperationState.inProgress
      ) {
        const response = await bulkOperationStatus({
          variables: {
            transactionId: componentState.transactionId,
            processedStatusIds: [
              SQS_STATUS_IDS.processed,
              SQS_STATUS_IDS.failed,
            ],
          },
        });
        const count = response.data.processed?.aggregate?.count ?? -1;
        if (count > -1 && count <= total) {
          setComponentState((prev) => ({...prev, processedCount: count}));
          if (count < total) {
            setTimeout(getBulkOperationStatus, 1000);
          } else {
            setComponentState((prev) => ({
              ...prev,
              bulkCareTeamOperationState: BulkCareTeamOperationState.success,
            }));
          }
        } else {
          setComponentState((prev) => ({
            ...prev,
            bulkCareTeamOperationState: BulkCareTeamOperationState.success,
          }));
        }
      } else {
        setComponentState((prev) => ({
          ...prev,
          bulkCareTeamOperationState: BulkCareTeamOperationState.success,
        }));
      }
    } catch {
      setComponentState((prev) => ({
        ...prev,
        bulkCareTeamOperationState: BulkCareTeamOperationState.none,
      }));
    }
  };

  const getAssignedCareManagerData = async () => {
    setComponentState((prev) => ({
      ...prev,
      doneLoading: true,
    }));

    const contactIds = getValidSelectedContactIds();
    try {
      const careTeamMemberTypeResponse = await getCareTeamMemberByMemberType({
        variables: {
          contactIds: contactIds,
          careTeamTypeId: careTeamTypeId,
        },
      });

      if (careTeamMemberTypeResponse?.data?.careTeams?.length) {
        if (!props?.ignoreBroadcast)  {
          localBroadcastEvent(
            CONVERSATION_LOCAL_EVENT_CODES.BULK_CONTACTS_CARE_MANAGER_CHANGE,
            {
              careTeams: careTeamMemberTypeResponse?.data?.careTeams,
            }
          );
        }
        setComponentState((prev) => ({
          ...prev,
          doneLoading: false,
        }));
        props.onSuccess(sleectedCareManager);
      }
    } catch (error) {
      showPopupNotification(
        'Error',
        'Something went wrong while validating patients',
        'error'
      );
      setComponentState((prev) => ({
        ...prev,
        doneLoading: false,
      }));
    }
  };

  const onCheckChanged = (code: string, isSelected: boolean) => {
    const updatedSelectedContacts = getUdpatedSeletedContactsByConflictCode(
      componentState.selectedContacts,
      componentState.allSelectedContacts,
      code,
      isSelected
    );
    setComponentState((prev) => {
      const summaryViewCounts = prev.summaryViewCounts?.map(
        (summaryViewCount) => {
          if (summaryViewCount.conflictCode === code) {
            summaryViewCount.isSelected = isSelected;
          }
          return summaryViewCount;
        }
      );

      return {
        ...prev,
        summaryViewCounts: summaryViewCounts,
      };
    });
    setComponentState((prev) => ({
      ...prev,
      selectedContacts: updatedSelectedContacts,
    }));
  };

  const renderSummaryView = () => {
    return (
      <Stack direction="column" style={styles.summaryViewContainer}>
        <Text
          style={styles.titleText}
        >
          {'Summary'}
        </Text>
        <Stack
          direction="row"
          style={styles.summaryCountViewContainer}
        >
          {componentState?.summaryViewCounts?.map((summaryView, index) => {
            return (
              <SummaryCountView
                key={summaryView.conflictCode}
                count={summaryView.count}
                countTextColor={getDisplayColorByConflictCode(
                  summaryView.conflictCode
                )}
                showRightBorder={
                  index !== componentState?.summaryViewCounts?.length - 1
                }
                summaryCode={summaryView.conflictCode}
                onCheckChanged={onCheckChanged}
                titleText={getDisplayTextByConflictCode(
                  summaryView.conflictCode
                )}
                isSelected={summaryView.isSelected}
                showSelection={summaryView.showSelection}
              />
            );
          })}
        </Stack>
      </Stack>
    );
  };

  const renderBulkInProgressView = () => {
    const total = getValidSelectedContactIds()?.length;
    const processed = componentState.processedCount || 0;
    const progress = total ? Math.round((processed * 100) / total) : 0;
    return (
      <Stack direction="column" style={styles.bulkInProgressViewContainer}>
        <View style={{marginVertical: 40}}>
          <Image
            size={160}
            source={
              progress === 100
                ? require('../../../../../../assets/gifs/success.gif')
                : require('../../../../../../assets/gifs/task_bulk.gif')
            }
            alt="image"
            key={Math.random()}
          />
        </View>
        {progress === 100 ? (
          <VStack space={6} width={'100%'} alignItems={'center'} key={'bulk_care_manager_progress'}>
            <Text style={styles.bulkInProgressSubText}>
              {`Care Manager assigned for ${total} patients successfully`}
            </Text>
            <FoldButtonV2
              label="Done"
              isLoading={componentState?.doneLoading}
              buttonType={ButtonType.secondary}
              onPress={() => {
                getAssignedCareManagerData();
              }}
            />
          </VStack>
        ) : (
          <VStack
            space={3}
            width={'100%'}
            borderRadius={8}
            borderColor={Colors.Custom.Gray300}
            borderWidth={0.5}
            padding={4}
            flex={1}
          >
            <VStack space={1}>
              <Text style={styles.bulkInProgressSubText}>
                {'Applying Bulk Changes to the selected patients'}
              </Text>
              <Text
                style={{color: Colors.Custom.Gray400}}
              >{`${total} selected patients`}</Text>
            </VStack>
            <Progress percent={progress} />
          </VStack>
        )}
      </Stack>
    );
  };

  const renderPreviewView = () => {
    return (
      <View>
        <Stack direction="column" style={styles.previewViewContainer}>
          <Text style={styles.titleText}>
            {'Care Manager to be Bulk Assigned'}
          </Text>
          <View style={styles.careTeamMemberCardContainer}>
            <CareTeamMemberCard
              key={sleectedCareManager?.id}
              name={sleectedCareManager?.name || ''}
              id={sleectedCareManager?.id || ''}
              uuid={sleectedCareManager?.uuid || ''}
              paddingHorizontal={2}
              roles={formatRoleArray(sleectedCareManager?.userRoles || [])}
              isLocal={false}
              isSearchComponent={true}
              profileUrl={
                sleectedCareManager?.agent?.thumbnail.includes('d=404')
                  ? ''
                  : sleectedCareManager?.agent?.thumbnail || ''
              }
            />
          </View>
        </Stack>
        {renderSummaryView()}
        <Stack direction="row" style={styles.titleContainer}>
          <Text style={styles.titleText}>{'Patients to be updated'}</Text>

          <SearchBar
            placeholderText="Search patients from list"
            borderColor={Colors.Custom.Gray300}
            placeholderTextColor={Colors.FoldPixel.GRAY250}
            searchBarInputStyles={{
              backgroundColor: Colors.Custom.White,
              borderColor: Colors.Custom.Gray300,
            }}
            borderRadius={6}
            width={300}
            onChange={(text) => {
              setComponentState((prev) => ({
                ...prev,
                searchText: text,
              }));
            }}
          />
        </Stack>
        <View style={styles.tableContainer}>
          <Table
            columns={getBulkCareManagerTableColumns(onDeleteAction, contactCareTeamsLoadingState)}
            rowKey={(row) => row.id}
            dataSource={getFiltertedSelectedContacts()}
            pagination={false}
            size="small"
          />
        </View>
      </View>
    );
  };

  const getFiltertedSelectedContacts = () => {
    const filteredContacts = componentState.selectedContacts?.filter((contact: any) =>
      contact.name
    ?.toLowerCase()
    .includes(componentState?.searchText.toLowerCase())
    );
    return filteredContacts;
  };

  const onDeleteAction = (deletedContact: any) => {
    const updatedSelectedContacts = componentState.selectedContacts?.filter(
      (contact) => contact.uuid !== deletedContact.uuid
    );

    const updatedSelectedAllContacts =
      componentState.allSelectedContacts?.filter(
        (contact) => contact.uuid !== deletedContact.uuid
      );

    const updatedSummaryCounts = componentState.summaryViewCounts?.map(
      (summaryCount) => {
        if (summaryCount.conflictCode === deletedContact.conflictCode) {
          summaryCount.count = summaryCount.count - 1;
        }
        return summaryCount;
      }
    );

    setComponentState((prev) => ({
      ...prev,
      selectedContacts: updatedSelectedContacts,
      allSelectedContacts: updatedSelectedAllContacts,
      summaryViewCounts: updatedSummaryCounts,
    }));
  };

  const getValidSelectedContactIds = () => {
    return componentState.selectedContacts
      ?.filter(
        (contact) =>
          contact.conflictCode !== BULK_CARE_TEAM_CONFLICT_CODE.NO_CHANGE
      )
      ?.map((contact) => contact.uuid);
  };

  const onConfirmClick = async () => {
    try {
      const contactIds = getValidSelectedContactIds();

      if (contactIds?.length === 0) {
        showPopupNotification(
          'Error',
          'Action can not be performed as no patients are selected.',
          'error'
        );
        return;
      }

      setComponentState((prev) => ({
        ...prev,
        bulkCareTeamOperationState: BulkCareTeamOperationState.inProgress,
        bulkViewCode: BulkViewCode.bulkInProgress,
        transactionId: undefined,
      }));
      const bulkUpdateResponse = await bulkCareTeamUpdate({
        variables: {
          params: {
            userId: sleectedCareManager?.uuid,
            careTeamMemberTypeId: careTeamMemberTypeId,
            careTeamTypeId: careTeamTypeId,
            contactIds: contactIds,
          },
        },
      });

      const transactionId =
        bulkUpdateResponse.data?.addOrUpdateBulkCareTeamUser?.processId;
      if (transactionId) {
        setComponentState((prev) => ({
          ...prev,
          transactionId,
        }));
      } else {
        setComponentState((prev) => ({
          ...prev,
          bulkCareTeamOperationState: BulkCareTeamOperationState.error,
        }));
      }
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        bulkCareTeamOperationState: BulkCareTeamOperationState.error,
      }));

      showPopupNotification(
        'Error',
        'Something went wrong while validating patients',
        'error'
      );
    }
  };

  const renderTitle = () => {
    return (
      <Stack
        direction="row"
        style={styles.headerViewContainer}
      >
        <Text style={styles.headerText}>{'Bulk Assign Care Manager'}</Text>
        <Stack direction="row" style={{alignItems: 'center'}}>
          {componentState.bulkViewCode === BulkViewCode.preview && (
            <>
              <FoldButtonV2
                buttonType={ButtonType.primary}
                label="Confirm"
                onPress={onConfirmClick}
              />
              <View
                style={{
                  height: 16,
                  width: 1,
                  backgroundColor: Colors.Custom.Gray300,
                  marginLeft: 8,
                  marginRight: 16,
                }}
              ></View>
            </>
          )}
          <Pressable
            onPress={() => {
              if (
                componentState?.bulkCareTeamOperationState ===
                BulkCareTeamOperationState.success
              ) {
                getAssignedCareManagerData();
              } else {
                props.onClose();
              }
            }}
          >
            <Close fill={Colors.Custom.Gray500} size={24} />
          </Pressable>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack direction="column">
      {isDrawerVisible && (
        <Drawer
          destroyOnClose
          placement="right"
          open={props.visible}
          closable
          width={isSmallScreen ? '50%' : '45%'}
          mask={true}
          title={renderTitle()}
        >
          {componentState.validationLoading && <Spinner />}
          {componentState.bulkViewCode === BulkViewCode.preview &&
            !componentState.validationLoading &&
            sleectedCareManager &&
            renderPreviewView()}
          {componentState.bulkViewCode === BulkViewCode.bulkInProgress &&
            renderBulkInProgressView()}
        </Drawer>
      )}
      {!isDrawerVisible && (
        <CareManagerAssignee
          onClose={props.onClose}
          showDialog={true}
          onSelectCareManager={(careManager) => {
            setSelectedCareManager(careManager);
            setDrawerVisible(true);
            setComponentState((prev) => ({
              ...prev,
              bulkViewCode: BulkViewCode.preview,
            }));
          }}
        />
      )}
    </Stack>
  );
};
