import React from 'react';
import {Circle, Svg} from 'react-native-svg';

function DotIconSvg() {
  return (
    <Svg width="8" height="9" viewBox="0 0 8 9" fill="none">
      <Circle cx="4" cy="4.5" r="3.5" fill="#D92D20" stroke="white" />
    </Svg>
  );
}

export default React.memo(DotIconSvg);
