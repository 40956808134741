import {useLazyQuery, useMutation} from '@apollo/client'
import {Button, HStack, Image, Modal, Pressable, Skeleton, Text, useToast, View, VStack} from 'native-base'
import {useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Dimensions, StyleSheet} from 'react-native'
import {MLOV_CATEGORY} from '../../../../constants'
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst'
import {USER_ROLE_CODES} from '../../../../constants/MlovConst'
import {BUTTON_TYPE, DEFAULT_COMMUNICATION_GROUP_NAME, GROUP_TYPES} from '../../../../constants/StringConst'
import {CommonDataContext} from '../../../../context/CommonDataContext'
import {IConfiguration} from '../../../../Interfaces'
import ConversationsQueries from '../../../../services/Conversations/ConversationsQueries'
import GroupQueries from '../../../../services/Conversations/GroupQueries'
import {ITeam, ITeamMemberData, ITeamResp} from '../../../../services/Team/interfaces'
import TeamQueries from '../../../../services/Team/TeamQueries'
import UserQueries from '../../../../services/User/UserQueries'
import {Colors} from '../../../../styles/Colors'
import {getAccountId, getAccountUUID, getAllowedUserPracticeLocationUuidsAndName, getConfigDataFromCode, getUserUUID} from '../../../../utils/commonUtils'
import {showToast, ToastType} from '../../../../utils/commonViewUtils'
import {getMlovId, getMlovIdFromCode, getMlovListFromCategory} from '../../../../utils/mlovUtils'
import {ModalActionAntSelect} from '../../../common/ModalActionCommonComponent/ModalActionAntSelect'
import {IUser, IUsersResponse} from '../../Contacts/TeamMembers/interfaces'
import ContactOfflineMessageSetting from './ContactOfflineMessageSetting'
import {addUserToDefaultCommunicationGroup, createOrUpdateAccountConfiguration, removeUserFromDefaultCommunicationGroup} from './DefaultCommunicationGroupService'
import {getGroupCountForAdd, getGroupCountToRemove, getMembersToAdd, getMembersToDelete} from './DefaultGroupUtils'
import GroupWelcomeMessageSetting from './GroupWelcomeMessageSetting'
import {ICreateOrUpdateAccountConfig, ICreateWelcomeMessage, IDefaultCommunicationGroupProps, IGroupConversationData, IUpdateWelcomeMessage} from './interface'
import { IAccountPracticeLocationResp } from '../../../../services/Location/interfaces'
import { IAccountLocation } from '../../Contacts/TeamMembers/interfaces'
import { PatientChatAutoResponseView } from '../../AutoReplyView'
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton'
import { DEFAULT_ADD_OR_REMOVE_ACTION_TYPE } from './DefaultGroupConsts'
import { isAccountConfigEnabled } from '../../../../utils/configUtils'
import { USER_ACCESS_PERMISSION } from '../../UserAccess/UserAccessPermission'
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst'

const DefaultCommunicationGroup = (props: IDefaultCommunicationGroupProps) => {
  const DIALOG_CODE = {
    ADD: 'ADD',
    REMOVE: 'REMOVE'
  };
  const commonData: any = useContext(CommonDataContext);
  const contactOfflineConfig = getConfigDataFromCode(CONFIG_CODES.SEND_EMAIL_OR_SMS_ON_PATIENT_OFFLINE);
  const userData = commonData?.userData;
  const defaultGroupPreferenceId = getMlovId(commonData.MLOV, MLOV_CATEGORY.TEAM_PREFERENCE, 'DEFAULT_GENERAL_GROUP');
  const intl = useIntl();
  const createGroupToast = useToast();
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const allowedUserPracticeLocationUuidsAndName = getAllowedUserPracticeLocationUuidsAndName(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.COMMUNICATION,
  );
  const [stateData, setStateData] = useState({
    loading: true,
    locationsDefaultLoading: false,
    selectedUserId: undefined,
    userList: [] as IUser[],
    allUserList: [] as IUser[],
    defaultTeamData: {} as ITeam,
    dialogConfig: {
      openConfirmDialog: false,
      dialogCode: '',
      title: '',
      bodyText: '',
      primaryBtnText: '',
      secBtnText: '',
      userUuid: ''
    },
    contactOfflineConfig: contactOfflineConfig as IConfiguration,
    defaultWelcomeGroupMessageData: {} as any,
    locationData: [] as any[],
    submitting: false,
    locationsLoading: true,
    userToAddGroupCount: 0,
    userGroupCount: 0,
    noAllActionLoading: false,
    yesAllActionLoading: false
  });
  const [selectedLocationId, setSelectedLocationId] = useState('')
  const accountUUID = getAccountUUID();
  const accountId = getAccountId();
  const currentUserUuid = getUserUUID();

  const welcomeGroupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GROUP_WELCOME_MESSAGES_TYPE
  );
  const defaultGroupTypeId = getMlovIdFromCode(
    welcomeGroupTypeList,
    'DEFAULT_COMMUNICATION_GROUP'
  );

  const [CreateTeam] = useMutation<ITeam>(TeamQueries.CreateTeam);
  const [CreatePracticeTeam] = useMutation<ITeam>(TeamQueries.CreatePracticeTeam)
  const [CreateTeamPreference] = useMutation(TeamQueries.CreateTeamPreference);
  const [CreateTeamMembers] = useMutation(TeamQueries.CreateTeamMembersWithActionType);
  const [DeleteTeamMbr] = useMutation(TeamQueries.DeleteTeamMembersWithActionType);
  const [getGroupWelcomeMessage] = useLazyQuery(GroupQueries.GetGroupWelcomeMessage, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      groupTypeCode: 'DEFAULT_COMMUNICATION_GROUP',
    },
  });
  const [createGroupWelcomeMessage] = useMutation<any, ICreateWelcomeMessage>(GroupQueries.CreateGroupWelcomeMessage);
  const [updateGroupWelcomeMessage] = useMutation<any, IUpdateWelcomeMessage>(GroupQueries.UpdateGroupWelcomeMessage);
  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS_WITHOUT_EMPLOYER, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      searchString: '%%',
      accountId: getAccountId(),
      roleCode: USER_ROLE_CODES.EMPLOYER,
    },
  });
  const [getUsersDataByLocation] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS_BY_LOCATION_ID_WITHOUT_EMPLOYER, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      accountId: getAccountId(),
      roleCode: USER_ROLE_CODES.EMPLOYER,
    },
  });


  const [getDefaultCommunicationGroup] = useLazyQuery<ITeamResp>(TeamQueries.GetCommunicationGroupTeams, {
    fetchPolicy: 'no-cache',
  });

  const [getDefaultConversations] = useLazyQuery<IGroupConversationData>(ConversationsQueries.getDefaultConversations, {
    fetchPolicy: 'no-cache',
    variables: {},
  });

  const [getAccountPracticeLocations] = useLazyQuery<IAccountPracticeLocationResp>(
    TeamQueries.GetLocations,
    {
      variables: {accountUuid: accountUUID},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [getTeamMemberDataByUserId] = useLazyQuery<ITeamMemberData>(TeamQueries.GetTeamMemberDataByUserId, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const getPracticeLocationsData = async () => {
    let locationData: any[] = [];
    if (isCommunicationLocationHandlingEnabled) {
      locationData = allowedUserPracticeLocationUuidsAndName;
      setSelectedLocationId(locationData?.[0]?.practiceLocationId)
    } else {
      const practiceLocationResp = await getAccountPracticeLocations();
      const locationList = practiceLocationResp?.data?.accountLocations || [];
      locationData = [{
        practiceLocationId: 'Default',
        practiceLocationName: 'Default'
      }];
      locationData.push(...locationList.map((location: IAccountLocation) => {
        return {
          "practiceLocationId": location?.practiceLocation?.uuid || '',
          "practiceLocationName": location?.practiceLocation?.name || '',
          "uuid": location?.uuid,
        }
      }))
      setSelectedLocationId(locationData?.[0]?.practiceLocationId || 'Default')
    }
    setStateData((prev) => {
      return {
        ...prev,
        locationData: locationData,
        locationsLoading: false,
      }
    });
  }

  const getTeamsWhereCondition = () => {
    const whereCondition: any = {
      accountUuid: {_eq: accountUUID},
      _or: [
        {
          name: {
            _eq: DEFAULT_COMMUNICATION_GROUP_NAME
          }
        },
        {
           teamPreferencesRel: {
              preferenceMlov: {code: {_eq: "DEFAULT_GENERAL_GROUP"}}
            }
        }
      ]
    }
    if (selectedLocationId && selectedLocationId !== 'Default') {
      whereCondition.practiceLocationUuid = {_eq: selectedLocationId}
    } else {
      whereCondition.practiceLocationUuid = {_is_null: true}
    }
    return whereCondition;
  }

  const getAllDefaultCommunicationGroup = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
        locationsDefaultLoading: true
      }
    });
    let userListResp = {} as any;
    if (isCommunicationLocationHandlingEnabled && selectedLocationId && selectedLocationId !== 'Default') {
      userListResp = await getUsersDataByLocation({
        variables: {
          locationUuid: selectedLocationId,
        },
      });
    } else {
      userListResp = await getUsersData();
    }
    const teamsData = await getDefaultCommunicationGroup({
      variables: {
        accountUuid: accountUUID,
        name: DEFAULT_COMMUNICATION_GROUP_NAME,
        where: getTeamsWhereCondition()
      }
    });
    const groupWelComeMsgData = await getGroupWelcomeMessage();
    let defaultTeamData: any = teamsData?.data?.teams?.[0] || {};
    if (!defaultTeamData?.id) {
      if (selectedLocationId.length > 0 && selectedLocationId !== 'Default') {
        defaultTeamData = await CreatePracticeTeam({
          variables: {
            accountId: accountId,
            accountUuid: accountUUID,
            name: DEFAULT_COMMUNICATION_GROUP_NAME,
            practiceLocationUuid: selectedLocationId
          }
        }).catch(() => {
          showToast(
            createGroupToast,
            intl.formatMessage({id: 'errorCreatingTeam'}),
            ToastType.error
          );
        });
      } else {
        defaultTeamData = await CreateTeam({
          variables: {
            accountId: accountId,
            accountUuid: accountUUID,
            name: DEFAULT_COMMUNICATION_GROUP_NAME,
          },
        }).catch(() => {
          showToast(
            createGroupToast,
            intl.formatMessage({id: 'errorCreatingTeam'}),
            ToastType.error
          );
        });
      }
      if (defaultTeamData?.data?.createTeam?.uuid) {
        defaultTeamData = defaultTeamData?.data?.createTeam;
        await CreateTeamPreference({
          variables: {
            teamPreferenceData: {
              teamUuid: defaultTeamData.uuid,
              preferenceId: defaultGroupPreferenceId,
              accountUuid: accountUUID,
            }
          },
        });
      } else {
        showToast(
          createGroupToast,
          intl.formatMessage({id: 'errorCreatingTeam'}),
          ToastType.error
        );
      }
      if (defaultTeamData) {
        defaultTeamData.teamMembers = [];
      }
    } else if (defaultTeamData?.uuid && defaultTeamData?.teamPreferencesRel?.length === 0) {
      if (defaultTeamData?.uuid) {
        await CreateTeamPreference({
          variables: {
            teamPreferenceData: {
              teamUuid: defaultTeamData.uuid,
              preferenceId: defaultGroupPreferenceId,
              accountUuid: accountUUID,
            }
          },
        });
      } else {
        showToast(
          createGroupToast,
          intl.formatMessage({id: 'errorCreatingTeam'}),
          ToastType.error
        );
      }
      if (defaultTeamData) {
        defaultTeamData.teamMembers = [];
      }
    }
    const teamMemberUserIds = defaultTeamData?.teamMembers?.map((singleUserData: any) => singleUserData?.userUuid);

    const userList = userListResp?.data?.users?.filter((singleUser: any) => {
      let roles = '';
      singleUser.value = singleUser.name;
      singleUser.userRoles?.forEach((element: any) => {
        roles += element?.userRole?.userRole?.code;
      });

      if (!roles.includes('WORKFLOW') && !roles.includes('CUSTOMER_SUCCESS')  && !teamMemberUserIds?.includes(singleUser.uuid)) {
        return singleUser;
      }
    });
    setStateData((prev) => {
      return {
        ...prev,
        loading: false,
        locationsDefaultLoading: false,
        userList: userList as IUser[],
        allUserList: userListResp?.data?.users as IUser[],
        defaultTeamData: defaultTeamData as any,
        defaultWelcomeGroupMessageData: groupWelComeMsgData?.data?.groupWelcomeMessages[0] || {} as any,
        yesAllActionLoading: false,
        noAllActionLoading: false
      }
    });
  }

  useEffect(() => {
    getPracticeLocationsData();
  }, [])

  useEffect(() => {
    if (selectedLocationId?.length) {
      getAllDefaultCommunicationGroup();
    }
  }, [selectedLocationId])

  const onUserRemove = (teamMemberId: string) => {
    getConfirmModalData(DIALOG_CODE.REMOVE, teamMemberId);
  }

  const getConfirmModalData = async (openConfirmDialogCode: string, userUuid: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      }
    });
    let dialogConfig = {
      openConfirmDialog: false,
      dialogCode: '',
      title: '',
      bodyText: '',
      primaryBtnText: '',
      secBtnText: '',
      userUuid: ''
    };
    const groups = await getDefaultConversations({
      variables: {
        groupWhereCondition: {
          isDefaultGroup: {_eq: true},
          groupType: {code: {_eq: GROUP_TYPES.PRIVATE}},
          accountUuid: {_eq: accountUUID},
        },
      }
    });
    if (openConfirmDialogCode === 'ADD') {
      const userToAddGroupCount = getGroupCountForAdd(groups?.data?.groupConversations || [], userUuid);
      dialogConfig = {
        openConfirmDialog: true,
        dialogCode: 'ADD',
        title: 'Add user to default communication group',
        bodyText: `Do you want to add this new user to all the previously created ${userToAddGroupCount} default groups?`,
        primaryBtnText: 'Yes, add to all previously created groups',
        secBtnText: 'No, add to only new groups going forward',
        userUuid,
      };
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          dialogConfig: {
            ...dialogConfig
          },
          userGroupCount: userToAddGroupCount,
          yesAllActionLoading: false,
          noAllActionLoading: false
        }
      });
    } else if (openConfirmDialogCode === 'REMOVE') {
      const userToRemoveGroupCount = getGroupCountToRemove(groups?.data?.groupConversations || [], userUuid);
      dialogConfig = {
        openConfirmDialog: true,
        dialogCode: 'REMOVE',
        title: 'Remove user to default communication group',
        bodyText: `Do you want to remove this user from previously created ${userToRemoveGroupCount} default groups?`,
        primaryBtnText: 'Yes, remove from all previously created groups',
        secBtnText: 'No, don\'t add into any new group going forward',
        userUuid,
      };
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          dialogConfig: {
            ...dialogConfig
          },
          userGroupCount: userToRemoveGroupCount,
          yesAllActionLoading: false,
          noAllActionLoading: false
        }
      });
    }
  }


  const addTeamMembersToTeam = async (
    teamId: number,
    teamUuid: string,
    userToAdd: string[],
    addActionType: string,
  ) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      }
    });

    const teamMemberData = await getTeamMemberDataByUserId({
      variables: {
        teamUserUuid: userToAdd,
        teamUuid: teamUuid
      }
    })

    const teamMembers = getMembersToAdd(teamId, teamUuid, userToAdd, addActionType, currentUserUuid, teamMemberData?.data?.teamMembers);
    const responseData = await CreateTeamMembers({
      variables: {teamMembers: teamMembers},
    });
    if (responseData) {
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'teamUpdated'}),
        ToastType.success
      );
      const teamsData = await getDefaultCommunicationGroup();
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          defaultTeamData: teamsData?.data?.teams[0] as any,
          dialogConfig: {
            ...prev.dialogConfig,
            openConfirmDialog: false,
            dialogCode: '',
          },
          yesAllActionLoading: false,
          noAllActionLoading: false
        }
      });
    } else {
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'errorCreatingTeam'}),
        ToastType.error
      );
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          dialogConfig: {
            ...prev.dialogConfig,
            openConfirmDialog: false,
            dialogCode: '',
          },
          yesAllActionLoading: false,
          noAllActionLoading: false
        }
      });
    }
  };

  const deleteTeamMembersFromTeam = async (
    teamId: number,
    teamUuid: string,
    userToAdd: string[],
    removeActionType: string,
  ) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      }
    });
    const teamMemberData = await getTeamMemberDataByUserId({
      variables: {
        teamUserUuid: userToAdd,
        teamUuid: teamUuid,
      },
    });
    let additionalAttributes = {};
    const currentActionData = {
      updatedBy: currentUserUuid,
      actionType: removeActionType,
    };
    if (teamMemberData?.data?.teamMembers?.length) {
      const previousTeamMemberData = teamMemberData?.data?.teamMembers?.[0];

      if (previousTeamMemberData?.additionalAttributes?.removeActionData) {
        additionalAttributes = {
          ...previousTeamMemberData?.additionalAttributes,
          removeActionData: [
            ...previousTeamMemberData?.additionalAttributes?.removeActionData,
            currentActionData,
          ],
        };
      } else {
        additionalAttributes = {
          ...previousTeamMemberData?.additionalAttributes,
          removeActionData: [currentActionData],
        };
      }
    } else {
      additionalAttributes = {
        removeActionData: [currentActionData],
      };
    }

    const teamMembers = getMembersToDelete(teamId, teamUuid, userToAdd);
    const responseData = await DeleteTeamMbr({
      variables: {teamMembers: teamMembers, removeActionType: removeActionType, additionalAttributes: additionalAttributes},
    });
    if (responseData) {
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'teamUpdated'}),
        ToastType.success
      );
      const teamsData = await getDefaultCommunicationGroup();
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          defaultTeamData: teamsData?.data?.teams[0] as any,
          dialogConfig: {
            ...prev.dialogConfig,
            openConfirmDialog: false,
            dialogCode: '',
          },
          yesAllActionLoading: false,
          noAllActionLoading: false
        }
      });
    } else {
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'errorCreatingTeam'}),
        ToastType.error
      );
      setStateData((prev) => {
        return {
          ...prev,
          loading: true,
          dialogConfig: {
            ...prev.dialogConfig,
            openConfirmDialog: false,
            dialogCode: '',
          },
          yesAllActionLoading: false,
          noAllActionLoading: false
        }
      });
    }
  };

  const onContinueWithAddingToGeneralGroup = () => {
    continueWithoutAddingToGeneralGroup(DEFAULT_ADD_OR_REMOVE_ACTION_TYPE.YES_ALL)
    const locationId = selectedLocationId === 'Default' ? '' : selectedLocationId;
    if (stateData?.dialogConfig?.dialogCode === DIALOG_CODE.ADD) {
      addUserToDefaultCommunicationGroup(accountId as any, stateData?.dialogConfig?.userUuid, locationId);
    } else if (stateData?.dialogConfig?.dialogCode === DIALOG_CODE.REMOVE) {
      removeUserFromDefaultCommunicationGroup(accountId as any, stateData?.dialogConfig?.userUuid, locationId);
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          dialogConfig: {
            ...prev.dialogConfig,
            openConfirmDialog: false,
            dialogCode: '',
          },
          yesAllActionLoading: false,
          noAllActionLoading: false
        }
      });
    }
  }

  const continueWithoutAddingToGeneralGroup = (addOrRemoveActionType: string) => {
    const teamData = stateData?.defaultTeamData;
    const dialogData = stateData?.dialogConfig;
    if (stateData?.dialogConfig?.dialogCode === DIALOG_CODE.ADD) {
      addTeamMembersToTeam(teamData?.id, teamData?.uuid, [dialogData?.userUuid], addOrRemoveActionType);
    } else if (stateData?.dialogConfig?.dialogCode === DIALOG_CODE.REMOVE) {
      deleteTeamMembersFromTeam(teamData?.id, teamData?.uuid, [dialogData?.userUuid], addOrRemoveActionType);
    }
  }

  const getConfirmModal = () => {
    const dialogConfig = stateData.dialogConfig;
    const IsDefaultGroupPresent = stateData?.userGroupCount > 0 ? true : false
    if (dialogConfig.openConfirmDialog) {
      return (
        <Modal
          isOpen={dialogConfig.openConfirmDialog}
          onClose={() => {
            setStateData((prev) => {
              return {
                ...prev,
                loading: false,
                dialogConfig: {
                  ...prev.dialogConfig,
                  openConfirmDialog: false,
                  dialogCode: '',
                },
                yesAllActionLoading: false,
                noAllActionLoading: false
              }
            });
          }}>
          <Modal.Content
            maxW={400}
            height={250}
          >
            <Modal.CloseButton />
            <Modal.Header>{dialogConfig.title}</Modal.Header>
            <Modal.Body >
              {dialogConfig.bodyText}
            </Modal.Body>
            <Modal.Footer justifyContent={'center'}>
              <VStack justifyContent={'center'}>
                <HStack>
                  <Button
                   _loading={{
                     bg: Colors.Custom.ButtonDefaultColor
                   }}
                   isLoading={stateData.noAllActionLoading}
                   onPress={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        noAllActionLoading: true,
                      }
                    })
                    continueWithoutAddingToGeneralGroup(DEFAULT_ADD_OR_REMOVE_ACTION_TYPE.NO_ALL)
                  }}>{dialogConfig.secBtnText}
                  </Button>
                </HStack>
                <HStack paddingTop={3}>
                <Button
                  _loading={{
                    bg: Colors.Custom.ButtonDefaultColor
                  }}
                    onPress={() => {
                      setStateData((prev) => {
                        return {
                          ...prev,
                          yesAllActionLoading: true,
                        }
                      })
                      onContinueWithAddingToGeneralGroup();
                    }}
                    isDisabled={!IsDefaultGroupPresent}
                    isLoading={stateData.yesAllActionLoading}
                  >
                    <Text
                      opacity={IsDefaultGroupPresent ? 1 : 0.5}
                      color={IsDefaultGroupPresent ? Colors.Custom.White : Colors.Custom.Gray500}
                    >
                      {dialogConfig.primaryBtnText}
                    </Text>
                  </Button>
                </HStack>
              </VStack>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      );
    }
    return <></>;
  }

  const setGroupWelcomeMessageText = (messageText: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        defaultWelcomeGroupMessageData: {
          ...prev.defaultWelcomeGroupMessageData,
          messageText: messageText,
        },
      };
    });
  };

  const onGroupWelcomeMsgSave = async () => {
    const messageText =
      stateData?.defaultWelcomeGroupMessageData?.messageText || '';
    setStateData((prev) => {
      return {
        ...prev,
        submitting: true
      }
    });
    if (stateData.defaultWelcomeGroupMessageData?.id) {
      const data = await updateGroupWelcomeMessage({
        variables: {
          welcomeMsgData: {
            messageText: messageText
          },
          id: stateData.defaultWelcomeGroupMessageData?.id
        }
      });
      setStateData((prev) => {
        return {
          ...prev,
          submitting: false,
          defaultWelcomeGroupMessageData: {
            ...prev.defaultWelcomeGroupMessageData,
            messageText: messageText
          }
        }
      });
    } else {
      const createDataResp = await createGroupWelcomeMessage({
        variables: {
          welcomeMsgData: {
            accountUuid: accountUUID,
            groupType: defaultGroupTypeId,
            messageText: messageText
          }
        }
      });
      setStateData((prev) => {
        return {
          ...prev,
          submitting: false,
          defaultWelcomeGroupMessageData: {
            ...prev.defaultWelcomeGroupMessageData,
            id: createDataResp?.data?.createGroupWelcomeMessage?.id,
            messageText: messageText
          }
        }
      });
    }
  }
  const onConfigSave = async (params: {configCode: string; value: string}) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true
      }
    });
    const bodyData: ICreateOrUpdateAccountConfig = {...params, accountUuid: accountUUID};
    const accountConfigData = await createOrUpdateAccountConfiguration(bodyData);
    if (accountConfigData?.data && params?.configCode) {
      const configObj = commonData.accountConfigData[params?.configCode]
      if (configObj?.accountConfigurations?.length) {
        commonData.accountConfigData[params?.configCode].accountConfigurations = [accountConfigData?.data];
      } else {
        commonData.accountConfigData[params?.configCode] = accountConfigData?.data;
      }
    }
    setStateData((prev) => {
      return {
        ...prev,
        loading: false,
        yesAllActionLoading: false,
        noAllActionLoading: false
      }
    });
  }
  const screenHeight = Dimensions.get('window').height;

  useEffect(() => {
    const teamMemberUserIds = stateData?.defaultTeamData?.teamMembers?.map((singleUserData: any) => singleUserData?.userUuid);
    const userList = stateData?.allUserList?.filter((singleUser: any) => {
      let roles = '';
      singleUser.value = singleUser.name;
      singleUser.userRoles?.forEach((element: any) => {
        roles += element?.userRole?.userRole?.code;
      });

      if (!roles.includes('WORKFLOW') && !roles.includes('CUSTOMER_SUCCESS')  && !teamMemberUserIds?.includes(singleUser.uuid)) {
        return singleUser;
      }
    });
    setStateData(prev => ({
      ...prev,
      userList: userList
    }))
  }, [stateData?.defaultTeamData?.teamMembers])

  const getFinalLocationData = () => {
    return stateData.locationData?.filter(
      (item) => item?.practiceLocationId !== 'Default'
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View>
          <VStack mt={3}>
            <Text style={styles.headerText}>Patient messages default group settings</Text>
          </VStack>
          <VStack style={styles.descriptionContainer}>
            <Text style={styles.descriptionText}>
              Use this setting to assign default internal users to patient chats. Configure different sets of internal users for various locations. The system will automatically add the appropriate internal users to patient chats based on the patient's location when the chat is created.
            </Text>
          </VStack>
          <VStack pt={2}>
            <VStack flex={0.3} mt={3}>
              <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Location</Text>
            </VStack>
            <VStack flex={0.7}>
              <ModalActionAntSelect
                isRequired={false}
                allowClear={false}
                label={''}
                showSearch={true}
                placeholder="Select location"
                value={selectedLocationId || undefined}
                defaultValue={selectedLocationId || undefined}
                filterOption={(input: string, option: any) =>
                  (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value: any) => {
                  setSelectedLocationId(value)
                }}
                customStyle={{width: 600}}
                data={stateData.locationData}
                optionProps={{key: 'practiceLocationId', value: 'practiceLocationId', label: 'practiceLocationName'}}
                marginTop={2}
              />
              {!stateData?.locationsLoading  && !stateData.locationData?.length ?
                <Text style={styles.noLocationText}>
                  {intl.formatMessage({id: 'noLocationMapped'})}
                </Text>
                : <></>}
            </VStack>
          </VStack>
          {stateData.locationsDefaultLoading ? (
            <Skeleton.Text
              mx={1}
              mt={7}
              lines={4}
              width={'40%'}
            ></Skeleton.Text>
          ) : (
            <VStack pt={4}>
              <VStack
              // flex={0.3}
              mt={3}>
                <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Add users for patient default messaging chat groups</Text>
              </VStack>
              <VStack
              // flex={0.7}
              >
                <ModalActionAntSelect
                  isRequired={false}
                  allowClear={false}
                  label={''}
                  showSearch={true}
                  placeholder="Select users"
                  value={stateData.selectedUserId}
                  filterOption={(input: string, option: any) =>
                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value: any) => {
                    setStateData((prev) => ({...prev, selectedUserId: value
                  }));
                  setTimeout(() => {
                    setStateData((prev) => ({
                      ...prev,
                      selectedUserId: undefined,
                    }));
                  }, 500);
                    if (value && stateData?.userList?.length) {
                      const userList = stateData?.userList;
                      const singleTeamMemberData = userList.find((singleUser) => {
                        return singleUser.id === value;
                      });
                      if (singleTeamMemberData?.uuid) {
                        getConfirmModalData('ADD', singleTeamMemberData?.uuid);
                      }
                    }
                  }}
                  customStyle={{width: 600}}
                  data={stateData?.userList}
                  optionProps={{key: 'id', value: 'id', label: 'value'}}
                  marginTop={2}
                />
                <HStack width={600} mt={2} flexWrap={'wrap'} overflow={'auto'} space={2}>
                  {stateData?.defaultTeamData?.teamMembers &&
                    stateData?.defaultTeamData?.teamMembers.map((teamMemberObj) => {
                      return (
                        <View
                          key={teamMemberObj.id}
                          mt={2}
                          px={2}
                          py={1}
                          borderRadius={16}
                          bgColor={'gray.100'}
                        >
                          <HStack space={1} alignItems={'center'}>
                            <Text>{teamMemberObj?.userData?.name}</Text>
                            <Pressable
                              onPress={() => {
                                if (teamMemberObj?.userData?.uuid) {
                                  onUserRemove(teamMemberObj?.userData?.uuid);
                                }
                              }}
                            >
                              <Image
                                style={styles.imageSize}
                                source={require('../../../../assets/images/cancel.png')}
                              />
                            </Pressable>
                          </HStack>
                        </View>
                      );
                    })}
                </HStack>
              </VStack>
            </VStack>
          )}
          <View>
            <GroupWelcomeMessageSetting
              groupWebComeTextData={stateData.defaultWelcomeGroupMessageData}
              onGroupWelcomeMsgSave={setGroupWelcomeMessageText} />
              <View
                style={styles.saveButtonContainer}
              >
                <FoldButton
                  customProps={{
                    btnText: 'Save',
                  }}
                  nativeProps={{
                    variant: BUTTON_TYPE.PRIMARY,
                    style: {
                      width: 'fit-content',
                    },
                    isLoading: stateData.submitting,
                    onPress() {
                      onGroupWelcomeMsgSave();
                    },
                  }}
                />
              </View>
          </View>
        </View>
        <HStack mt={4}>
            <View height={0.4} width={600} mt={4} background={Colors.Custom.Gray400}></View>
        </HStack>
        <View>
          <VStack mt={3}>
            <Text fontSize={18}>Patient/Prospect offline settings</Text>
          </VStack>
          <VStack>
            <Text style={styles.descriptionText}>
            When a patient is offline on the patient app, this feature enables you to select your preferred mode of communication - either sending the same message via SMS or Email. The system will automatically deliver the same message through the chosen method to ensure effective reach and communication.
            </Text>

          </VStack>
          <ContactOfflineMessageSetting configObj={stateData.contactOfflineConfig} onConfigSave={onConfigSave} />
        </View>
        <HStack mt={4}>
            <View height={0.4} width={600} mt={4} background={Colors.Custom.Gray400}></View>
        </HStack>
        <View mt={3}>
          <PatientChatAutoResponseView selectedLocationId={selectedLocationId} 
            locationData={getFinalLocationData()}/>
        </View>
        {getConfirmModal()}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: Dimensions.get('window').height - 180,
  },
  innerContainer: {
    backgroundColor: '#fff',
    height: '90%',
    padding: 24,
  },
  headerText: {
    fontSize: 18,
  },
  descriptionContainer: {
    width: 600,
  },
  descriptionText: {
    width: 600,
    color: Colors.Custom.Gray500,
  },
  noLocationText: {
    fontSize: 12,
    color: 'warning.600',
    marginTop: 3,
  },
  saveButtonContainer: {
    marginTop: 10,
  },
  imageSize: {
    height: 8,
    width: 8
  },
})

export default DefaultCommunicationGroup;
