import {ColumnsType} from 'antd/lib/table';
import {HStack, Pressable, Text, VStack, View} from 'native-base';
import {DisplayText} from '../../../DisplayText/DisplayText';
import {Colors} from '../../../../../styles';
import MemberInfoListItem from '../../../MemberInfoListItem/MemberInfoListItem';
import {useContext} from 'react';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {ContactListItem, ScheduleRuleMemberListActionTypes} from './interface';
import {getFormDataFromLeadData} from '../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {numericStringMask} from '../../../../../utils/commonUtils';
import {PhoneNumber} from '../../../EmailAndPhone/PhoneNumber';
import {Email} from '../../../EmailAndPhone/Email';
import AppointmentActionSvg from '../../../Svg/PersonActionSvgIcons/AppointmentActionSvg';
import {Tooltip} from 'antd';

export const getColumns = (args: {
  showActionColumn: boolean;
  onActionPerformed: (
    type: ScheduleRuleMemberListActionTypes,
    data: any
  ) => void;
}): ColumnsType<any> => {
  const colums: ColumnsType<any> = [];
  colums.push({
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
        textLocalId="patientName"
      />
    ),
    dataIndex: 'patientName',
    key: 'patientName',
    width: '60%',
    render: (data: any, selectedRecord: any): JSX.Element => {
      return (
        <MemberInfoListItem
          contactData={selectedRecord?.contactData}
          showEmail={false}
          showDateOfBirth={true}
        />
      );
    },
    responsive: ['md'],
  });

  colums.push({
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
        textLocalId="contactInfo"
      />
    ),
    dataIndex: 'contactInfo',
    key: 'contactInfo',
    width: '30%',
    render: (data: any, singleLeadData: any): JSX.Element => {
      const mask = `(###) ###-####`;
      const maskedPhoneNumber = numericStringMask(
        singleLeadData.phoneNumber,
        mask
      );

      const email = singleLeadData?.email || '';
      return (
        <VStack>
          <View>
            {singleLeadData.phoneNumber === '' ||
            singleLeadData.phoneNumber === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Pressable>
                <PhoneNumber
                  phoneNumber={maskedPhoneNumber}
                  contactData={singleLeadData}
                  onActionPerformed={(actionCode) => {
                    return;
                  }}
                />
              </Pressable>
            )}
          </View>
          <View>
            {email === '' || email === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Pressable>
                <Email
                  contactData={singleLeadData}
                  onActionPerformed={(actionCode) => {
                    return;
                  }}
                />
              </Pressable>
            )}
          </View>
        </VStack>
      );
    },
    responsive: ['md'],
  });

  colums.push({
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
        textLocalId="actions"
      />
    ),
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    width: '10%',
    render: (data: any, singleLeadData: any): JSX.Element => {
      return (
        <HStack justifyContent={'center'}>
          <Pressable
            disabled={!args.showActionColumn}
            onPress={() =>
              args.onActionPerformed(
                ScheduleRuleMemberListActionTypes.BOOK_APPOINMENT,
                singleLeadData
              )
            }
          >
            <Tooltip title="Schedule Appointment">
              <View style={{width: 20, height: 20}}>
                <AppointmentActionSvg isActive={args.showActionColumn} />
              </View>
            </Tooltip>
          </Pressable>
        </HStack>
      );
    },
  });

  return colums;
};

export const formattedContactDataList = (data: ContactListItem[]) => {
  const mlovData = useContext(CommonDataContext);
  const formmattedDataList: any[] = [];
  data.map((item) =>
    formmattedDataList.push(getFormDataFromLeadData(item, mlovData))
  );
  return formmattedDataList;
};
