import { useContext, useEffect, useState } from 'react';
import { IAddOrUpdateDayOptimizerRuleFactorsParams, IDayOptimizerRuleFactor } from '../common/interface';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { IUserRoleCategory } from '../../../../Interfaces';
import {useLazyQuery, useMutation} from '@apollo/client';
import {LeadQueries} from '../../../../services';
import {notification} from 'antd';


const useRuleScore = () => {
  const { userRoleCategories: accountRoles = [] } = useContext(CommonDataContext);

  const [ruleScoreData, setRuleScoreData] = useState<IDayOptimizerRuleFactor[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [SearchDayOptimizerRuleFactors] = useLazyQuery(LeadQueries.SearchDayOptimizerRuleFactors, {
    fetchPolicy: 'no-cache'
  })
  const [updateRuleFactorStatus] = useMutation(LeadQueries.UpdateDayOptimizerRuleFactorStatus);

  const fetchAndAddRoleNameFromRoleId = (roles: IUserRoleCategory[], scores: IDayOptimizerRuleFactor[]) => {
    const roleMap = new Map(roles.map(role => [role.id, role.roleName]));
    return scores.map((rule) => ({
      ...rule,
      roleName: roleMap.get(rule.accountRoleId) || ''
    }));
  }

  const fetchData = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const { data } = await SearchDayOptimizerRuleFactors();
      const ruleFactors = data?.searchDayOptimizerRuleFactors?.ruleFactors?.map(
        ({
          id,
          name,
          ruleCode,
          accountRoleId,
          score,
          isEnabled,
          ruleScoreId
        }: IDayOptimizerRuleFactor) => ({
          id,
          name,
          ruleCode,
          accountRoleId,
          score,
          isEnabled,
          ruleScoreId
        })
      ) || [];

      setRuleScoreData(fetchAndAddRoleNameFromRoleId(accountRoles, ruleFactors));
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const updateRuleFactor = async (params: IAddOrUpdateDayOptimizerRuleFactorsParams) => {
    try {
      setLoading(true);
      const response = await updateRuleFactorStatus({
        variables: {
          params
        }
      });

      if (response?.data) {
        setRuleScoreData(prevData => 
          prevData.map(rule => 
            rule.ruleScoreId === params.ruleScoreId 
              ? {...rule, score: params.score || rule.score} // Ensure score is never undefined
              : rule
          )
        );
      setLoading(false);
        return true;
      }
      setLoading(false);
      return false;
    } catch (error) {
      setLoading(false);
      return false;
    }
  }

  const onStatusChange = async (checked: boolean, ruleScoreId: string) => {
    setLoading(true);
    try {
      const success = await updateRuleFactor({
        ruleScoreId,
        isEnabled: checked
      });

      if (success) {
        setRuleScoreData(prevData => 
          prevData.map(rule => 
            rule.ruleScoreId === ruleScoreId 
              ? {...rule, isEnabled: checked}
              : rule
          )
        );
      } else {
        const message = 'Something went wrong. Please try again later.';
        notification.destroy();
        notification.error({
          message: message,
        });
        setRuleScoreData(prevData => 
          prevData.map(rule => 
            rule.ruleScoreId === ruleScoreId 
              ? {...rule, isEnabled: !checked}
              : rule
          )
        );
      }
    } catch (error) {
      const message = 'Something went wrong. Please try again later.';
      notification.destroy(); 
      notification.error({
        message: message,
      });
    } finally {
      setLoading(false);
    }
  }
  const onPaginationChange = (current: number, pageSize = 10) => {
    setPagination((prev) => ({ ...prev, current, pageSize }));
    fetchData(current, pageSize);
  };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, []);

  return {
    ruleScoreData,
    loading,
    pagination,
    onPaginationChange,
    onStatusChange,
    updateRuleFactor
  };
};

export default useRuleScore;
