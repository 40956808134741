import {Button, Popover, Upload} from 'antd';
import moment from 'moment';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {EMPLOYER_CONST} from '../EmployerUtils/EmployerConst';
import {IPrintPreview} from '../interface';
import ColorPlates from './ColorPlates';
import { LG_CONFIG, MD_CONFIG } from '../../../../../theme/FontConfig/TextFontSizeConfig';
const CoverPageView = (props: IPrintPreview) => {
  const {isDownload} = props;
  const intl = useIntl()
  const [stateData, setStateData] = useState({
    employerLogoSrc: '',
    clinicLogoSrc: '',
    coverPageBgColor: '',
    coverPageBgImage: `url("/ReportingCoverPage/ReportingCoverPage.jpg")`,
    openBgEditView: false,
    name: '',
    introduction: '',
  });
  const handleCoverPageBackGround = (code: string, data: any) => {
    switch (code) {
      case EMPLOYER_CONST.COLOR:
        setStateData((prev) => {
          return {
            ...prev,
            coverPageBgColor: data,
            coverPageBgImage: '',
          };
        });
        break;
      case EMPLOYER_CONST.BG_IMAGE:
        setStateData((prev) => {
          return {
            ...prev,
            coverPageBgImage: `url("${data}")`,
            coverPageBgColor: '',
          };
        });
        break;
      case 'POP_OVER_VISIBLE':
        setStateData((prev) => {
          return {
            ...prev,
            openBgEditView: data,
          };
        });
        break;
      default:
        break;
    }
  };
  return (
    <div
      className="cover-page-view"
      style={
        stateData.coverPageBgColor
          ? {backgroundColor: stateData.coverPageBgColor}
          : {backgroundImage: stateData.coverPageBgImage}
      }
    >
      <div className="overlay"></div>
      <div
        style={{
          position: 'absolute',
          bottom: 32,
          left: 32,
        }}
      >
        <div
          style={{
            margin: 0,
            color: '#fff',
            ...MD_CONFIG.mdSemibold
          }}
        >
          Report
        </div>
        <div
          style={{
            margin: 0,
            color: '#fff',
            ...LG_CONFIG.lgSemibold
          }}
        >
          {`${moment(props.dateRange?.uiFromDate).format('MMM yyy')} - ${moment(
            props.dateRange?.to
          ).format('MMM yyy')}`}
        </div>
      </div>
      {!isDownload && (
        <Popover
          content={
            <ColorPlates
              handleCoverPageBackGround={handleCoverPageBackGround}
            />
          }
          overlayClassName="cover-page-edit"
          trigger="click"
          open={stateData.openBgEditView}
          onOpenChange={(visible: boolean) => {
            handleCoverPageBackGround('POP_OVER_VISIBLE', visible);
          }}
        >
          <Button
            style={{
              position: 'absolute',
              bottom: 32,
              right: 50,
              padding: 0,
              backgroundColor: 'transparent',
              borderWidth: 0,
            }}
          >
            <div
              style={{
                padding: 8,
                backgroundColor: '#F5F0FF',
                borderRadius: 8,
                borderWidth: 1,
                borderColor: '#D7C0FF',
                borderStyle: 'solid',
                color: '#8C5AE2',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Feather name="edit-2" size={16} color={'#8C5AE2'} />
              <div style={{fontSize: 14, fontWeight: 500, marginLeft: 4}}>
                Edit Cover
              </div>
            </div>
          </Button>
        </Popover>
      )}

      <div
        style={{
          width: '94%',
          position: 'absolute',
          zIndex: 11,
          top: 32,
          left: 32,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          {stateData.employerLogoSrc ? (
            <div
              style={{
                width: 125,
                height: 125,
                backgroundColor: '#fff',
                borderRadius: 16,
                position: 'relative',
              }}
              className="uploaded-print-logo"
            >
              <img
                style={{objectFit: 'cover', borderRadius: 16}}
                src={stateData.employerLogoSrc}
                width="100%"
                height="100%"
              />
              <div
                style={{
                  position: 'absolute',
                  top: -10,
                  right: -10,
                  width: 25,
                  height: 25,
                  borderRadius: 4,
                  backgroundColor: '#fff',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className="uploaded-print-edit-logo"
              >
                <Upload
                  listType="picture"
                  multiple={true}
                  beforeUpload={() => false}
                  onChange={(info: any) => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        employerLogoSrc: URL.createObjectURL(info?.file),
                      };
                    });
                  }}
                  fileList={[]}
                  className="msg-attachment"
                >
                  <Feather
                    name="edit-2"
                    size={15}
                    color={Colors.Custom.Gray600}
                  />
                </Upload>
              </div>
            </div>
          ) : isDownload ? (
            <></>
          ) : (
            <Upload
              listType="picture"
              multiple={true}
              beforeUpload={() => false}
              onChange={(info: any) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    employerLogoSrc: URL.createObjectURL(info?.file),
                  };
                });
              }}
              fileList={[]}
              className="msg-attachment"
            >
              <div
                style={{
                  width: 125,
                  height: 125,
                  backgroundColor: '#fff',
                  borderRadius: 16,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Feather
                  name="upload"
                  size={20}
                  color={Colors.Custom.Gray400}
                />
                <div
                  style={{
                    fontSize: 14,
                    color: Colors.Custom.Gray400,
                    fontWeight: 400,
                  }}
                >
                  Employer Logo
                </div>
              </div>
            </Upload>
          )}
        </div>
        <div>
          {stateData.clinicLogoSrc ? (
            <div
              style={{
                width: 125,
                height: 125,
                backgroundColor: '#fff',
                borderRadius: 16,
                position: 'relative',
              }}
              className="uploaded-print-logo"
            >
              <img
                style={{objectFit: 'cover', borderRadius: 16}}
                src={stateData.clinicLogoSrc}
                width="100%"
                height="100%"
              />
              <div
                style={{
                  position: 'absolute',
                  top: -10,
                  right: -10,
                  width: 25,
                  height: 25,
                  borderRadius: 4,
                  backgroundColor: '#fff',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className="uploaded-print-edit-logo"
              >
                <Upload
                  listType="picture"
                  multiple={true}
                  beforeUpload={() => false}
                  onChange={(info: any) => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        employerLogoSrc: URL.createObjectURL(info?.file),
                      };
                    });
                  }}
                  fileList={[]}
                  className="msg-attachment"
                >
                  <Feather
                    name="edit-2"
                    size={15}
                    color={Colors.Custom.Gray600}
                  />
                </Upload>
              </div>
            </div>
          ) : isDownload ? (
            <></>
          ) : (
            <Upload
              listType="picture"
              multiple={true}
              beforeUpload={() => false}
              onChange={(info: any) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    clinicLogoSrc: URL.createObjectURL(info?.file),
                  };
                });
              }}
              fileList={[]}
              className="msg-attachment"
            >
              <div
                style={{
                  width: 125,
                  height: 125,
                  backgroundColor: '#fff',
                  borderRadius: 16,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Feather
                  name="upload"
                  size={20}
                  color={Colors.Custom.Gray400}
                />
                <div
                  style={{
                    fontSize: 14,
                    color: Colors.Custom.Gray400,
                    fontWeight: 400,
                  }}
                >
                  Clinic Logo
                </div>
              </div>
            </Upload>
          )}
        </div>
      </div>
      <div
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 24,
          position: 'absolute',
          top: '20%',
          width: '100%',
          translate: '(-20%, -20%)',
        }}
      >
        {isDownload ? (
          stateData.name &&
          stateData.introduction && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 40,
              }}
            >
              <div
                style={{
                  fontSize: 40,
                  fontWeight: 'bold',
                  color: '#fff',
                  lineHeight: '48px',
                }}
              >
                {stateData.name}
              </div>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: '#fff',
                  lineHeight: '24px',
                  marginTop: 30,
                }}
              >
                {stateData.introduction}
              </div>
            </div>
          )
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 40,
            }}
          >
            <textarea
              className="cover-page-title"
              maxLength={50}
              onChange={(value) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    name: value.target?.value,
                  };
                });
              }}
              style={{
                width: '100%',
                borderWidth: 0,
                backgroundColor: 'transparent',
                color: '#fff',
              }}
              defaultValue={stateData.name}
              placeholder={intl.formatMessage({
                id: 'addReportTitle',
              })}
            />
            <textarea
              className="cover-page-description"
              maxLength={900}
              onChange={(value) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    introduction: value.target?.value,
                  };
                });
              }}
              style={{
                width: '100%',
                fontSize: 18,
                fontWeight: 600,
                borderWidth: 0,
                backgroundColor: 'transparent',
                color: '#fff',
              }}
              defaultValue={stateData.introduction}
              placeholder={intl.formatMessage({
                id: 'addReportIntroductionHere',
              })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoverPageView;
