import React, { useState } from 'react';
import {Modal, FormControl, Input, Button, VStack, Text, View, Icon, Box, Toast, WarningOutlineIcon, HStack, Spinner, useToast} from 'native-base';
import {ColorSelector} from './ColorSelector';
import {tagColorList} from '.';
import {IEditLabelState, IEditTagModalProps} from '../interfaces';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../styles';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {BUTTON_TYPE} from '../../../../../constants';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useLazyQuery } from '@apollo/client';
import TagQueries from '../../../../../services/Tags/TagQueries';
import { debounce } from 'lodash';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';

const {Body, Content, Footer, Header, CloseButton} = Modal;
const {Label} = FormControl;

const EditTagModal = (props: IEditTagModalProps) => {
  const {
    record,
    onCancel,
    isEditModalOpen,
    onEditModalClose,
    isLabel
  } = props;
  const intl = useIntl();
  const toast = useToast();

  const [editLabelState, setEditLabelState] = useState<IEditLabelState>({
    tagText: record.title,
    tagColor: record.color,
    tagDescription: record.description,
    disableSave: false,
    labelLoading: false,
    error: false
  });

  const [getLabelByTitle] = useLazyQuery(TagQueries.GetLabelByTitle, {
    fetchPolicy: 'no-cache',
  });

  const handleChange = async (tagText: string) => {
    try {
      if (tagText.trim().length === 0) {
        setEditLabelState((prev) => ({
          ...prev,
          error: false,
          disableSave: true,
          tagText: '',
        }));
        return;
      }
      setEditLabelState((prev) => ({
        ...prev,
        labelLoading: true,
        disableSave: true,
        error: false,
        tagText: tagText,
      }));
      
      const response = await getLabelByTitle({
        variables: {
          title: tagText,
        },
      });

      const isDuplicate = response.data?.labels?.length > 0;
      setEditLabelState((prev) => ({
        ...prev,
        error: isDuplicate,
        disableSave: isDuplicate,
        labelLoading: false,
      }));
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({id: 'labelIdFetchingErrorMessage'}),
        ToastType.error
      );
      setEditLabelState((prev) => ({
        ...prev,
        disableSave: true,
        labelLoading: false,
      }));
    }
  };

  return (
    <Modal isOpen={isEditModalOpen} onClose={() => onCancel()}>
      <Content minWidth={600}>
        <CloseButton />
        <Header>
          <DisplayText textLocalId={isLabel ? 'editLabel' : 'editTag'} size="lgNormal" />
        </Header>
        <Body padding={5}>
          <FormControl isInvalid={editLabelState.error}>
            <Label>
              <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={isLabel ? 'labelName' : 'tagName'} />
            </Label>
            <Input
            _focus={{
              borderColor: Colors.Custom.Gray200
            }}
              size={'mdNormal'}
              defaultValue={editLabelState.tagText}
              onChangeText={debounce((tagText: string) => handleChange(tagText.trim()),400)}
            />
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }} />}
            >
              {`Label with name '${editLabelState.tagText}' already exists.`}
            </FormControl.ErrorMessage>
              <HStack marginTop={2} height={3}>
                {editLabelState.labelLoading ? (
                  <>
                    <Spinner
                      size="sm"
                      accessibilityLabel={`Checking label name availability`}
                    />
                    <Text marginLeft={1} fontSize="xs">
                      {intl.formatMessage({
                        id: 'checkingLabelNameAvailability',
                      })}
                    </Text>
                  </>
                ) : (
                  <Text> </Text>
                )}
              </HStack>
 
          </FormControl>
          <FormControl marginTop={5}>
            <Label>
              <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={isLabel ? 'labelDescription' : 'tagDescription'} />
            </Label>
            <Input
            _focus={{
              borderColor: Colors.Custom.Gray200
            }}
              size={'mdNormal'}
              defaultValue={editLabelState.tagDescription}
              onChangeText={(tagDescription: string) =>
                setEditLabelState((prev)=>{
                  return {
                    ...prev,
                    tagDescription: tagDescription
                  }
                })
              }
            />
          </FormControl>
          <VStack width={'20%'} marginBottom={3} marginTop={3}>
            <ColorSelector
              color={editLabelState.tagColor}
              title={isLabel ? 'labelColor' : 'tagColor'}
              colorList={tagColorList}
              onBtnPress={(color: string) => setEditLabelState((prev)=>{
                return {
                  ...prev,
                  tagColor: color
                }
              })}
              onChangeHex={(text) =>
                setEditLabelState((prev)=>{
                  return {
                    ...prev,
                    tagColor: '#' + text
                  }
                })
              }
            />
          </VStack>
          <Box
            borderRadius={8}
            borderColor={Colors.Custom.Gray200}
            borderWidth={1}
            p={2}
          >
            <VStack flexDir={'row'}>
              <Icon as={AntIcon} name="infocirlceo" size="smMedium" color={Colors.danger[500]} mt={0.8}/>
              <Text fontSize="xs" color={Colors.danger[500]} ml={1}>
                {intl.formatMessage({ id: isLabel ? 'labelSyncMessage' : 'tagSyncMessage'})}
              </Text>
            </VStack>
          </Box>
        </Body>
        <Footer marginTop={3}>
          <Button.Group space={2}>
             <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onCancel();
                  },
                  style: {marginLeft: 20},
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'cancel',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress:  () => {
                    onEditModalClose(editLabelState);
                  },
                  isDisabled: editLabelState.disableSave,
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'save',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default EditTagModal;
