import {Collapse, Grid, Space} from '@arco-design/web-react';
import '@arco-themes/react-easy-email-theme-purple/css/arco.css';
import {
  AdvancedType,
  BasicType,
  BlockManager,
  JsonToMjml,
} from '@foldhealth/easy-email-core';
import {
  BlockAvatarWrapper,
  EmailEditor,
  EmailEditorProvider,
  IEmailTemplate,
  Stack,
  useFocusIdx,
} from '@foldhealth/easy-email-editor';
import {
  AttributesPanelWrapper,
  BlockAttributeConfigurationManager,
  ColorPickerField,
  ExtensionProps,
  FontFamily,
  InputWithUnitField,
  StandardLayout,
  TextAreaField,
} from '@foldhealth/easy-email-extensions';
import {Liquid} from 'liquidjs';
import {
  Checkbox,
  FormControl,
  HStack,
  Input,
  Select,
  Skeleton,
  Stack as NBStack,
  useMediaQuery,
  useToast,
  View,
} from 'native-base';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useNavigate, useParams} from 'react-router-dom';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {commonBodyContentContainer} from '../../../../../styles/CommonBodyContentStyle';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {fontList} from '../../ContentManagementConsts';
import {
  getAccountMergeTagData,
  getTemplateCategoryList,
} from '../../ContentManagementUtils';
import {CustomBlocksType} from '../../EmailTemplates/CreateOrEditTemplate/CustomBlocks/constants';
import {
  mergeTagGenerator,
  uploadImage,
} from '../../EmailTemplates/EmailTemplatesUtils';
import {ITemplateCategory} from '../../EmailTemplates/interfaces';
import {OverrideModal} from '../../OverrideModal';
import {
  createEmailComponent,
  getComponentId,
  getComponentTypes,
  getEmailComponentById,
  getFormattedSingleEmailComponent,
  isEmptyComponent,
  updateEmailComponent,
  validateComponent,
} from '../EmailComponentsUtils';
import {SingleEmailComponent} from '../interfaces';
import {styles} from './AddOrUpdateEmailComponentStyles';
const {Item} = Select;
const {Label} = FormControl;

const pageBlock = BlockManager.getBlockByType(BasicType.PAGE)!;
const templateJson: IEmailTemplate['content'] = pageBlock.create({
  data: {
    value: {},
  },
});

const initialValues = {
  content: templateJson,
  subject: '',
  subTitle: '',
};

const AddOrUpdateEmailComponent = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const toast = useToast();
  const {focusIdx} = useFocusIdx();
  BlockAttributeConfigurationManager.add({
    [BasicType.PAGE]: () => (
      <AttributesPanelWrapper style={{padding: 0}}>
        <Stack.Item fill>
          <Collapse defaultActiveKey={['0', '1']}>
            <Collapse.Item name="0" header="Component Setting">
              <Space direction="vertical">
                <InputWithUnitField
                  label="Width"
                  name={`${focusIdx}.attributes.width`}
                  inline
                />
                <InputWithUnitField
                  label="Breakpoint"
                  helpText="Allows you to control on which breakpoint the layout should go desktop/mobile."
                  name={`${focusIdx}.data.value.breakpoint`}
                  inline
                />
              </Space>
            </Collapse.Item>
            <Collapse.Item name="1" header="Theme Setting">
              <Stack vertical spacing="tight">
                <Grid.Row>
                  <Grid.Col span={11}>
                    <FontFamily name={`${focusIdx}.data.value.font-family`} />
                  </Grid.Col>
                  <Grid.Col offset={1} span={11}>
                    <InputWithUnitField
                      label="Font size"
                      name={`${focusIdx}.data.value.font-size`}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col span={11}>
                    <InputWithUnitField
                      label="Line height"
                      unitOptions="percent"
                      name={`${focusIdx}.data.value.line-height`}
                    />
                  </Grid.Col>
                  <Grid.Col offset={1} span={11}>
                    <InputWithUnitField
                      label="Font weight"
                      unitOptions="percent"
                      name={`${focusIdx}.data.value.font-weight`}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col span={11}>
                    <ColorPickerField
                      label="Text color"
                      name={`${focusIdx}.data.value.text-color`}
                    />
                  </Grid.Col>
                  <Grid.Col offset={1} span={11}>
                    <ColorPickerField
                      label="Background"
                      name={`${focusIdx}.attributes.background-color`}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <ColorPickerField
                    label="Content background"
                    name={`${focusIdx}.data.value.content-background-color`}
                  />
                </Grid.Row>

                <TextAreaField
                  autoSize
                  label="User style"
                  name={`${focusIdx}.data.value.user-style.content`}
                />
                <Stack.Item />
                <Stack.Item />
                {/* <AddFont /> */}
                <Stack.Item />
                <Stack.Item />
              </Stack>
            </Collapse.Item>
          </Collapse>
        </Stack.Item>
      </AttributesPanelWrapper>
    ),
  });

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const [state, setState] = React.useState({
    loading: false,
    initialValues: {
      content: templateJson,
      subject: '',
      subTitle: '',
    } as IEmailTemplate,
    componentTypes: [] as ITemplateCategory[],
    emailComponent: {} as SingleEmailComponent,
    overrideModal: false,
    errors: {} as any,
  });
  const [templateHtml, setTemplateHtml] = React.useState('');
  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const intl = useIntl();
  const onHeaderBackClick = () => {
    navigate(-1);
  };

  const setLoading = (value: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        loading: value,
      };
    });
  };

  const accountMergeTags = getAccountMergeTagData();

  const getMergeTags = (category: string) => {
    const mergeTagsByCategory = state.componentTypes.find(
      (item) => item.name == category
    )?.mergeTags;
    return {global: accountMergeTags, ...mergeTagsByCategory};
  };

  const onBeforePreview = (html: string, mergeTags: any) => {
    setTemplateHtml(html);
    const engine = new Liquid();
    const tpl = engine.parse(html);
    return engine.renderSync(tpl, mergeTags);
  };

  const defaultCategories: ExtensionProps['categories'] = [
    {
      label: 'Content',
      active: true,
      blocks: [
        {
          type: AdvancedType.TEXT,
        },
        {
          type: AdvancedType.IMAGE,
          payload: {attributes: {padding: '0px 0px 0px 0px'}},
        },
        {
          type: AdvancedType.BUTTON,
        },
        {
          type: AdvancedType.SOCIAL,
        },
        {
          type: AdvancedType.DIVIDER,
        },
        {
          type: AdvancedType.SPACER,
        },
        {
          type: AdvancedType.HERO,
        },
        {
          type: AdvancedType.WRAPPER,
        },
        {
          type: AdvancedType.ACCORDION,
        },
        // {
        //   type: AdvancedType.CAROUSEL,
        // },
        {
          type: AdvancedType.NAVBAR,
        },
        {
          type: AdvancedType.GROUP,
        },
        {
          type: AdvancedType.COLUMN,
        },
        {
          type: AdvancedType.SECTION,
        },
      ],
    },
    {
      label: 'Layout',
      active: true,
      displayType: 'column',
      blocks: [
        {
          title: '2 columns',
          payload: [
            ['50%', '50%'],
            ['33%', '67%'],
            ['67%', '33%'],
            ['25%', '75%'],
            ['75%', '25%'],
          ],
        },
        {
          title: '3 columns',
          payload: [
            ['33.33%', '33.33%', '33.33%'],
            ['25%', '25%', '50%'],
            ['50%', '25%', '25%'],
          ],
        },
        {
          title: '4 columns',
          payload: [[['25%', '25%', '25%', '25%']]],
        },
      ],
    },
    {
      label: 'Educational Articles',
      active: true,
      displayType: 'custom',
      blocks: [
        <BlockAvatarWrapper type={CustomBlocksType.MEDLINE_SUGGESTIONS}>
          <div
            style={{
              position: 'relative',
              marginBottom: 20,
              width: '80%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <b> Educational Article 1 </b>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 2,
              }}
            />
          </div>
          <div
            style={{
              position: 'relative',
              marginBottom: 20,
              width: '80%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            Educational Article 1 Content
          </div>
        </BlockAvatarWrapper>,
      ],
    },
  ];

  const handleOnTemplateSave = (values: IEmailTemplate) => {
    const mjml = JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content,
      dataSource: {},
    });
    setState((prev) => {
      return {
        ...prev,
        initialValues: values,
      };
    });
    const singleEmailComponent: SingleEmailComponent = {
      ...state.emailComponent,
      bodyJson: values.content,
      bodyHtml: templateHtml,
      bodyMjml: mjml,
    };
    const {errors, valid} = validateComponent(singleEmailComponent);
    setState((prev) => {
      return {
        ...prev,
        errors: errors,
      };
    });
    if (!valid) {
      return;
    }
    if (id) {
      showToast(toast, 'Updating...', ToastType.success);
      updateEmailComponent({id, singleEmailComponent: singleEmailComponent})
        .then((response) => {
          setLoading(true);
          return response;
        })
        .then((response) => {
          setLoading(false);
          navigate('/admin/contentManagement/emailComponent');
        })
        .catch((error) => {
          errorHandler(error);
        });
    } else {
      showToast(toast, 'Saving...', ToastType.success);
      if (!isEmptyComponent(singleEmailComponent)) {
        singleEmailComponent.bodyJson.children[0].componentId = getComponentId(
          singleEmailComponent.name
        );
      }
      createEmailComponent({singleEmailComponent})
        .then((response) => {
          setLoading(true);
          return response;
        })
        .then((response) => {
          setLoading(false);
          navigate('/admin/contentManagement/emailComponent');
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };

  const handleOverride = () => {
    const mjml = JsonToMjml({
      data: state.initialValues.content,
      mode: 'production',
      context: state.initialValues.content,
      dataSource: {},
    });
    const singleEmailComponent: SingleEmailComponent = {
      ...state.emailComponent,
      bodyJson: initialValues.content,
      bodyHtml: templateHtml,
      bodyMjml: mjml,
    };
    if (id) {
      updateEmailComponent({
        id: id,
        singleEmailComponent,
        override: true,
      })
        .then((response) => {
          setLoading(true);
          return response;
        })
        .then((response) => {
          setLoading(false);
          navigate('/admin/contentManagement/emailComponent');
        })
        .catch((error) => {
          errorHandler(error);
        });
    } else {
      createEmailComponent({
        singleEmailComponent,
        override: true,
      })
        .then((response) => {
          setLoading(true);
          return response;
        })
        .then((response) => {
          setLoading(false);
          navigate('/admin/contentManagement/emailComponent');
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };

  const errorHandler = (error: any) => {

    if (
      error.response?.status === 400 &&
      error.response.data?.error === 'Default Template Already Present'
    ) {
      setState((prev) => {
        return {
          ...prev,
          overrideModal: true,
        };
      });
    } else {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getEmailComponentById({id})
        .then((response) => {
          const comp = getFormattedSingleEmailComponent({response});
          setState((prev) => {
            return {
              ...prev,
              loading: false,
              emailComponent: comp,
              initialValues: {
                content: comp.bodyJson,
                subject: '',
                subTitle: '',
              },
            };
          });
        })
        .catch((error) => {

        });
    }
    getComponentTypes()
      .then((response) => {
        const list = getTemplateCategoryList(response);
        setState((prev) => {
          return {
            ...prev,
            componentTypes: list,
          };
        });
      })
      .catch((error) => {

      });
  }, []);

  return (
    <View flex={1}>
      <View>
        <TitleSubtitleView
          title={'createComponent'}
          showBackButton={true}
          onBackClick={onHeaderBackClick}
        />
      </View>
      <View style={[commonBodyContentContainer.pageContentContainer]}>
        {state.loading ? (
          <Skeleton.Text lines={4} />
        ) : (
          <EmailEditorProvider
            fontList={fontList}
            mergeTags={getMergeTags(state?.emailComponent?.componentType?.name)}
            mergeTagGenerate={mergeTagGenerator}
            data={id ? state.initialValues : initialValues}
            height={'85vh'}
            enabledLogic
            autoComplete
            dashed={false}
            onSubmit={handleOnTemplateSave}
            onBeforePreview={onBeforePreview}
            onUploadImage={(blob: Blob) => {
              return uploadImage(blob);
            }}
          >
            {({}, {submit}) => {
              return (
                <View overflow="scroll">
                  <View style={{padding: 2}}>
                    <View
                      style={{
                        backgroundColor: '#fff',
                        borderColor: Colors.Custom.BorderColor,
                        borderWidth: 1,
                        borderRadius: 8,
                      }}
                    >
                      <HStack style={[styles.topbarContainer]}>
                        <HStack
                          style={{
                            justifyContent: isSmallScreen
                              ? 'space-between'
                              : 'flex-end',
                            flex: 1,
                          }}
                        >
                          <NBStack
                            direction={isSmallScreen ? 'column' : 'row'}
                            space={'2'}
                            style={{
                              justifyContent: 'space-between',
                            }}
                          >
                            <View style={styles.inputStyle}>
                              <FormControl
                                isRequired
                                isInvalid={!!state.errors.name}
                              >
                                <HStack
                                  style={{
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Label marginTop={2}>
                                    <DisplayText extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId="templateName" />
                                  </Label>
                                  <Input
                                    _focus={{borderColor: '#D0D5DD'}}
                                    defaultValue={
                                      state.emailComponent.name || ''
                                    }
                                    onChangeText={(text: string) => {
                                      setState((prev) => {
                                        return {
                                          ...prev,
                                          emailComponent: {
                                            ...prev.emailComponent,
                                            name: text,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </HStack>
                              </FormControl>
                            </View>
                            <View style={styles.dropdownStyle}>
                              <FormControl
                                isRequired
                                isInvalid={!!state.errors.type}
                              >
                                <HStack
                                  style={{
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Label marginTop={2}>
                                    <DisplayText extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId="type" />
                                  </Label>
                                  <Select
                                    selectedValue={
                                      state?.emailComponent?.componentType
                                        ?.code || ''
                                    }
                                    onValueChange={(value) => {
                                      const selectedType =
                                        state.componentTypes.find(
                                          (item) => item.code == value
                                        );
                                      setState((prev) => {
                                        return {
                                          ...prev,
                                          emailComponent: {
                                            ...prev.emailComponent,
                                            componentType:
                                              selectedType ||
                                              ({} as ITemplateCategory),
                                          },
                                        };
                                      });
                                    }}
                                  >
                                    {state.componentTypes.map(
                                      (singleCategory) => {
                                        return (
                                          <Item
                                            key={singleCategory.id}
                                            label={singleCategory.name}
                                            value={singleCategory.code}
                                          />
                                        );
                                      }
                                    )}
                                  </Select>
                                </HStack>
                              </FormControl>
                            </View>
                            <View style={styles.switchView}>
                              <FormControl>
                                <HStack
                                  style={{
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Label marginTop={2}>Default</Label>
                                  <Checkbox
                                    marginTop={2}
                                    value="true"
                                    isChecked={state.emailComponent.isDefault}
                                    onChange={(isSelected) => {
                                      setState((prev) => {
                                        return {
                                          ...prev,
                                          emailComponent: {
                                            ...prev.emailComponent,
                                            isDefault: isSelected,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </HStack>
                              </FormControl>
                            </View>
                          </NBStack>

                          <NBStack
                            direction={isSmallScreen ? 'column' : 'row'}
                            space="2"
                          >
                            <FoldButton
                              nativeProps={{
                                variant: BUTTON_TYPE.SECONDARY,
                                onPress: () => {
                                  navigate(
                                    '/admin/contentManagement/emailComponent'
                                  );
                                },
                                leftIcon: (
                                  <AntIcon
                                    name="back"
                                    size={15}
                                    color={Colors.Custom.mainSecondaryBrown}
                                  />
                                ),
                              }}
                              customProps={{
                                btnText: intl.formatMessage({
                                  id: 'cancel',
                                }),
                                withRightBorder: false,
                              }}
                            ></FoldButton>
                            <FoldButton
                              nativeProps={{
                                variant: BUTTON_TYPE.PRIMARY,
                                onPress: () => {
                                  submit();
                                },
                                leftIcon: (
                                  <AntIcon
                                    name="save"
                                    color={Colors.Custom.mainSecondaryBrown}
                                    size={16}
                                  />
                                ),
                              }}
                              customProps={{
                                btnText: intl.formatMessage({
                                  id: id ? 'update' : 'save',
                                }),
                                withRightBorder: false,
                              }}
                            ></FoldButton>
                          </NBStack>
                        </HStack>
                      </HStack>
                      <View>
                        <StandardLayout
                          compact={!isSmallScreen}
                          categories={defaultCategories}
                        >
                          <EmailEditor />
                        </StandardLayout>
                      </View>
                    </View>
                  </View>
                </View>
              );
            }}
          </EmailEditorProvider>
        )}
      </View>

      {state.overrideModal && (
        <OverrideModal
          isOpen={state.overrideModal}
          onClose={() => {
            setState((prev) => {
              return {
                ...prev,
                overrideModal: false,
              };
            });
          }}
          textContent={{
            headerText: 'Override Default Component',
            message:
              'Default component for the same type is already present. Do you want to override and make this component as default?',
          }}
          onConfirm={() => {
            handleOverride();
          }}
        />
      )}
    </View>
  );
};

export default AddOrUpdateEmailComponent;
