import {Skeleton} from 'antd';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {ANALYTICS_RESOURCE} from '../../../../../../constants';
import {getSectionDataByResourceCode} from '../../../../../../services/Employer/EmployerServices/EmployerServices';
import EyeHideSvg from '../../../../../common/Svg/WidgetsSvg/EyeHideSvg';
import IncreaseArrow from '../../../../../common/Svg/WidgetsSvg/IncreaseArrow';
import RightArrow from '../../../../../common/Svg/WidgetsSvg/RightArrow';
import InfoIconView from '../../CommonView/InfoIconView';
import {DashboardDataContext} from '../../EmployerContext/DashboardContext';
import {WIDGET_ACTION_CODE} from '../../EmployerUtils/EmployerConst';
import {getIconForWidgetByCode} from '../../EmployerUtils/WidgetSvgHelper';
import {
  getCountSumFromResource,
  getPercentageChange,
  isWidgetDataEmpty,
} from '../../EmployerUtils/WidgetUtils';
import {IAppliedFilter, IResource, IWidgetCardView} from '../../interface';
import {
  APPOINTMENT_BOOKED,
  CHAT_MESSAGING,
  getTenantForDemo,
  NO_SHOWS,
  VIDEO_MESSAGING,
  VOICE_MESSAGING,
  VIDEO_CALLING,
} from '../../tempDataFile';
import LineGraphWidget from '../LineGraphWidget';
import NodataViewTypeOne from '../NoDataView/NodataViewTypeOne';
import WidgetActionView from '../WidgetActionView';
import { getBackgroundColorByPositiveStatus, getColorByPositiveStatus } from '../utils';
import {getFormattedDataForLineGraph} from '../../EmployerUtils/FormateDataUtils';

const WidgetVerticalCardView = (props: IWidgetCardView) => {
  const {
    sectionData,
    reusableData,
    ref,
    onImageDownloadClick,
    onActionClick,
    dateRangeType,
    dateRange,
    appliedFilter,
    employerId,
      isAccountDashboard,
      memberType,
  } = props;
  const [stateData, setStateData] = useState({
    resourceData: [] as IResource[] | any,
    loading: false,
    employeeTotalCount: 0,
    selectedView: '',
    selectedResourceData: {} as any,
    masterData: {} as any,
    appliedFilter: props.appliedFilter
  });
  const isChatMessaging = sectionData.resourceCode === ANALYTICS_RESOURCE.CHAT_MESSAGING
  const isVideoCalling = sectionData.resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING
  const isVoiceCalling = sectionData.resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING
  const isAppointment = sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BOOKED
  const isNoShows = sectionData.resourceCode === ANALYTICS_RESOURCE.NO_SHOWS
  let unit = [
    ANALYTICS_RESOURCE.VOICE_CALLING,
    // ANALYTICS_RESOURCE.VIDEO_CALLING,
  ].includes(sectionData.resourceCode || '')
    ? 'min'
    : '';
  const getCountValue = () => {
    let count = reusableData.employeeCount;
    count = getCountSumFromResource(
      stateData.resourceData,
      dateRangeType || '',
      sectionData.resourceCode || '',
      dateRange
    );
    if (
      sectionData.resourceCode == ANALYTICS_RESOURCE.VOICE_CALLING
      // sectionData.resourceCode == ANALYTICS_RESOURCE.VIDEO_CALLING
    ) {
      count = count;
      unit = 'sec';
      if (3600 >= count && count > 60) {
        count = moment.duration(count, 'seconds').asMinutes().toFixed();
        unit = 'min';
        return (
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
              display: 'flex',
            }}
          >
            <p
              style={{
                margin: 0,
                marginLeft: 6,
                fontFamily: 'Manrope-Medium',
              }}
            >
              {count}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: 16,
                marginLeft: 6,
                fontFamily: 'Manrope-Bold',
                marginBottom: 4,
              }}
            >
              {unit}
            </p>
          </div>
        );
      } else if (count > 3600) {
        const mins = moment.duration(count, 'seconds').asMinutes();
        // count = count / 60
        const hours = Math.floor(mins / 60);
        const minutes = mins % 60;
        return (
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
              display: 'flex',
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 24,
                marginLeft: 6,
                fontFamily: 'Manrope-Medium',
              }}
            >
              {hours}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: 16,
                marginLeft: 6,
                fontFamily: 'Manrope-Bold',
                marginBottom: 4,
              }}
            >
              {'hr :'}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: 24,
                marginLeft: 6,
                fontFamily: 'Manrope-Medium',
              }}
            >
              {Math.floor(minutes)}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: 16,
                marginLeft: 6,
                fontFamily: 'Manrope-Bold',
                marginBottom: 4,
              }}
            >
              {'min'}
            </p>
          </div>
        );
        return;
      }
      // count =  Math.floor(count / 60);
    }
    return (
      <p
        style={{
          margin: 0,
          fontSize: 24,
          marginLeft: 6,
          fontFamily: 'Manrope-Medium',
        }}
      >
        {count}
      </p>
    );
  };
  const percentageChange = getPercentageChange(
    stateData.resourceData,
    sectionData.resourceCode || '',
    dateRangeType || '',
    dateRange
  );
  const formattedData = getFormattedDataForLineGraph(sectionData, stateData.resourceData);
  let positive = true;
  const isInCreased =  formattedData[0]?.scales  < formattedData?.[formattedData?.length - 1]?.scales;
    if (isInCreased) {
      positive = true;
    } else {

      positive = false;
    }
  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let formId = '';
    if (
      sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION &&
      sectionData?.additionalAttributes?.formId
    ) {
      formId = sectionData?.additionalAttributes?.formId;
    }
    const filterList = appliedFilter?.length ?  appliedFilter : stateData.appliedFilter
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      formId,
      isAccountDashboard,
      memberType,
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && isWidgetDataEmpty(response?.data)) {
      const resourceData = response.data?.totalCount
        ? response.data
        : response.data?.result || response.data;
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true
        }
      })
      const resourceData: any =  getTenantForDemo() ?( isChatMessaging
        ? CHAT_MESSAGING
        : isVideoCalling
        ? VIDEO_MESSAGING
        : isVoiceCalling
        ? VOICE_MESSAGING
        :isAppointment
        ? APPOINTMENT_BOOKED
        : isNoShows
        ? NO_SHOWS
        : {})
        : {};

        setTimeout(()=> {
          setStateData((prev) => {
            return {
              ...prev,
              resourceData: resourceData,
              loading: false
            }
          })
        },3000)
      return
    }
    getSectionByResourceCode();
    return () => controller?.abort();
  }, [sectionData.id])
   useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue:  props.filterData?.filterValue
      }
      appliedFilter.push(selectedFilter);
      getSectionByResourceCode(appliedFilter);
    }
    return () => controller?.abort();
  },[props.filterData?.filterValue])

  const statusColor = getColorByPositiveStatus(
    positive || getTenantForDemo(),
    sectionData.resourceCode === ANALYTICS_RESOURCE.NO_SHOWS
  );

  return (
    <div className="widgetBoxShadow" style={{position: 'relative'}}>
      {stateData.loading ? (
        <Skeleton active={true} />
      ) : (
        <div style={{flex: 1}}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              zIndex: 12121212,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: 4,
              marginRight: 4,
            }}
          >
            {props.isPrintPreview ? (
              !props.isPrintDownload ? (
                <div
                  style={{
                    padding: 7.5,
                    paddingRight: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    props?.onActionClick?.(WIDGET_ACTION_CODE.HIDE_SECTION);
                  }}
                >
                  <EyeHideSvg isPrimary={!sectionData.isVisible} />
                </div>
              ) : null
            ) : (
              <>
                <DashboardDataContext.Consumer>
                  {(context) => {
                    return (
                      <InfoIconView
                      sectionCode={sectionData.sectionCategory || ''}
                      employerName={context.employerName || ''}
                      date={{
                        fromDate: context.uiFromDate,
                        toDate: context.uiToDate,
                      }}
                      resourceCode={sectionData.resourceCode || ''}
                      name={sectionData.name}
                      subTitle={sectionData.subHeading}
                      />
                    );
                  }}
                </DashboardDataContext.Consumer>
                {stateData.resourceData?.length ? (
                  <WidgetActionView
                    resourceCode={sectionData.resourceCode}
                    sectionCode={sectionData.name}
                    onActionClick={(code: string, data: any) => {
                      onActionClick?.(code, data);
                    }}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 0.8,
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div style={{marginBottom: 4}}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      color: 'rgb(16, 24, 40)',
                      fontSize: 18,
                      fontFamily: 'Manrope-Medium',
                    }}
                  >
                    {sectionData.name}
                  </div>
                  {(stateData.resourceData?.length > 0 ||
                    stateData.resourceData?.result?.length > 0) &&
                  !props.isPrintPreview ? (
                    <div
                      style={{
                        borderColor: '#C2A6F3',
                        borderWidth: 1.5,
                        borderStyle: 'solid',
                        borderRadius: 7.5,
                        marginLeft: 7.5,
                        padding: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        props?.onActionClick?.(
                          sectionData.resourceCode || '',
                          stateData.resourceData
                        );
                      }}
                    >
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          display: 'flex',
                        }}
                      >
                        <RightArrow />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {sectionData.subHeading && (
                  <div
                    style={{
                      color: 'rgb(152, 162, 179)',
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: 'Manrope-Medium',
                    }}
                  >
                    {sectionData.subHeading}
                  </div>
                )}
              </div>
              <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div
                    style={{
                      display: 'flex',
                      flex: 0.1,
                      width: 34,
                      height: 45,
                      marginRight: 3,
                    }}
                  >
                    {getIconForWidgetByCode(sectionData.resourceCode || '')}
                  </div>
                  {stateData.resourceData.length ? (
                    <div style={{flex: 1}}>
                      <div>
                        <div
                          style={{
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            display: 'flex',
                          }}
                        >
                          {getCountValue()}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NodataViewTypeOne viewCode={'ONLY_TEXT'} />
                  )}
                </div>
                {(percentageChange.percentageChange && !percentageChange?.noView && false)  ? (
                  <div style={{marginLeft: 12}}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      // "display: flex; flex-direction: row; align-items: center;"
                    >
                      <div
                        style={{
                          display: 'flex',
                          minHeight: 22,
                          height: 22,
                          paddingLeft: 4,
                          paddingRight: 4,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 16,
                          backgroundColor: getBackgroundColorByPositiveStatus(
                            positive || getTenantForDemo(),
                            sectionData.resourceCode === ANALYTICS_RESOURCE.NO_SHOWS
                          ),
                        }}
                      >
                        <div
                          style={{
                            width: 9,
                            height: 9,
                            rotate:
                            positive || getTenantForDemo()
                                ? '0deg'
                                : '180deg',
                            position: 'relative',
                            top:
                            positive || getTenantForDemo()
                                ? -7
                                : 7,
                            marginRight: 3,
                          }}
                        >
                          <IncreaseArrow color={statusColor} />
                        </div>
                        <div>
                          <div
                            style={{
                              margin: 0,
                              color: statusColor,
                              fontSize: 10,
                              fontFamily: 'Manrope-Regular',
                            }}
                          >
                            {percentageChange.percentageChange}%
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            margin: 0,
                            color: statusColor,
                            fontSize: 10,
                            fontFamily: 'Manrope-Regular',
                            marginLeft: 14,
                          }}
                        >
                          {' '}
                          {`vs ${props.dateRangeType?.toLocaleLowerCase()}`}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              style={{
                justifyContent: 'center',
                flex: 0.2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
                {stateData.resourceData?.length ||
                stateData.resourceData?.result?.length ? (
                <LineGraphWidget
                appliedFilter={appliedFilter}
                employerId={props.employerId}
                reusableData={{
                  isPositive: percentageChange.positive,
                }}
                loading={stateData.loading}
                sectionData={sectionData}
                resourceData={stateData.resourceData}
              />
              ) : (
                <NodataViewTypeOne viewCode={'ONLY_ICON'} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(WidgetVerticalCardView);

// {isLineGraphView() ? (
//   <div
//     style={{justifyContent: 'center', flex: .3, height: 110, display: 'flex'}}
//   >
// <LineGraphWidget
//   reusableData={{
//     isPostive: percentageChange.positive
//   }}
//   loading={loading}
//   sectionData={sectionData}
//   resourceData={resourceData}
// />
//   </div>
// ) : null}
// getIconForWidget(0.1)}
