import { Table } from 'antd';
import React from 'react';
import { rulesScoreColumns } from './common/RulesScoreColumns';
import { TableWrapper } from '../../common/TableWrapper';
import { Dimensions, View } from 'react-native';
import { Spinner } from 'native-base';
import { IDayOptimizerRuleFactorTable } from './common/interface';

const RuleScoreTable: React.FC<IDayOptimizerRuleFactorTable> = ({
  loading,
  ruleScoreData,
  pagination,
  onPageChange,
  onHandleTableRowClick,
  onStatusChange
}) => {
  const screenHeight = Dimensions.get('window').height;

  return (
    <TableWrapper
      wrapperStyle={{ alignItems: 'center' }}
      minHeight={230}
      pagination={
        loading
          ? {}
          : {
            ...pagination,
            onChange: onPageChange,
          }
      }
      hideOnSinglePage={false}
      hidePagination={true}
    >
      <Table
        rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-light' : '')}
        dataSource={ruleScoreData}
        scroll={{ x: 700, y: screenHeight - 260 }}
        loading={{
          spinning: loading,
          indicator: (
            <View style={{ justifyContent: 'center', height: '100%' }}>
              <Spinner size="lg" />
            </View>
          ),
        }}
        rowKey={(row: any) => `rule_score${row.ruleScoreId}_`}
        onRow={(data) => {
          return {
            onClick: () => {
              onHandleTableRowClick(data)
            }
          };
        }}
        columns={rulesScoreColumns(onStatusChange)}
        pagination={false}
      />
    </TableWrapper>
  );
};

export default RuleScoreTable;
