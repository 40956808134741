import React, {useMemo} from 'react';
import {Circle, Path, Svg} from 'react-native-svg';
import {ICommonSvgProps} from '../../components/common/Svg/interfaces';

const CheckedIconSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <Path
        d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
        fill="#8C5AE2"
      />
      <Circle cx="8" cy="8" r="4" fill="white" />
    </Svg>
  );
};

export default React.memo(CheckedIconSvg);
