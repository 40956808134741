import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { APPOINTMENT_STATUS_CODES, CARE_PLAN_STATUS_CODES, CARE_PROGRAM_TYPES, CARE_TEAM_MEMBER_TYPE, MLOV_CATEGORY } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { GET_CONTACT_ID_BY_MATCH_CARE_MEMBER_TYPE_USER, GET_UNDER_MY_CARE_CONTACT_IDS, GET_UNDER_MY_CARE_CONTACT_WITH_MATCH_CARE_MEMBER_TYPE_USER } from '../../../../services/CareTeam/CareTeamQueries';
import { getAccountId, getUserUUID } from '../../../../utils/commonUtils';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { AWV_POP_GROUP_CODE, AWV_SCHEDULED_SUB_FILTER_CODE, DASHBOARD_TAB_CODE, FUTURE_AWV_API_CODE, PROGRAM_FILTER_CODE, PROGRAM_STEPS_FILTER_CODE, PROGRAM_SUB_FILTER_CODE, STEP_STATUS } from '../constant';
import { GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID, GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_FOR_TCM, GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_TCM, GET_CONTACT_IDS_BY_PROGRAM_IDS, GET_CONTACT_IDS_BY_PROGRAM_STEPS_ID, GET_CONTACT_IDS_BY_PROGRAM_STEPS_IDS, GET_CONTACT_IDS_BY_PROGRAM_STEPS_IDS_FILTER_CONTACT_IDS } from '../../../../services/ContactCareProgram/ContactCareProgram';
import {
  IContactFilterParams,
  IContactProgramCountResponse,
  IFilterCategoryApiResponseMap,
  IGetContactFilterParams,
  IPatientCategoryCount,
  IPatientCategoryCountParams,
  IPatientCategoryWiseCountParams,
  IPatientDashboardFilter,
  IPayerProgramWiseProgramResponse,
  IPopGroupResponse,
  IPopGroupResult,
  IStepsLogResponse,
  StepFilter,
} from '../interface';
import useDebounceEvent from './useDebounceEvent';
import { getAwvFilteredContactIds, getContactFilter } from './utils';
import { getProgramContactDataFromPopGroup } from '../../../RightSideContainer/Contacts/CodeGroups/CodeGroupApi';
import { IMlov } from '../../../../Interfaces/CommonInterfaces';
import { GET_FUTURE_BOOKED_APPOINTMENTS_BY_CONTACT_IDS } from '../../../../services/Appointment/AppointmentQueries';
import { getCurrentDateTimeMoment } from '../../../../utils/DateUtils';
import { cloneDeep, isEqual } from 'lodash';
import { LeadQueries } from '../../../../services';


interface IStepWiseContactIdResponse {
  contactIds: string[];
  categoryCode: string,
  count: number,
};

export const usePatientCountManager = (args: IPatientCategoryCountParams) => {
  const { params, isProgramBasedDashboard } = args;
  const [tabCountLoading, setLoading] = useState<boolean>(false);
  const [dashboardTabCount, setDashboardTabCount] = useState<
    IPatientCategoryCount[]
  >([]);

  const commonContextData = useContext(CommonDataContext);
  const careProgramStepList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_STEP_TYPE) || [];
  const careProgramStepStatusList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_STEP_STATUS) || [];
  const carePlanStatusList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PLAN_STATUS) || [];
  const currentUserId = getUserUUID();
  const accountId = getAccountId();
  const [filterParams, setFilterParams] = useState({} as IPatientDashboardFilter);
  const [firstFetch, setFirstFetch] = useState(false);
  const [loadingCategoryCodes, setLoadingCategoryCodes] = useState<string[]>([]);

  const careProgramTypeList = getMlovListFromCategory(
    commonContextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_TYPES
  ) || [];

  const getFilterCategoryMlov = (categoryCode: string): IMlov | undefined => {
    return careProgramStepList.find(careProgramStep => {
      return careProgramStep?.code === categoryCode;
    });
  }
  const ccmCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.CCM);
  const ecmCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.ECM);
  const tcmCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.TCM);
  const accpCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.ACCP);
  const mlovData = useContext(CommonDataContext);
  const assessmentCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.ASSESSMENT);
  const consentCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.CONSENT);
  const planCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN);
  const scheduleF2FCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F);
  const ccmEnrolledCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.CCM_ENROLLED);
  const tcmOutreachCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.TCM_OUTREACH_2DAY);
  const tcmAppointmentCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.TCM_OUTREACH_14DAY);
  const ecmOutreachCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.ECM_OUTREACH);

  const [getContactCount] = useLazyQuery(LeadQueries.contactAggregateOnLocation, {
    fetchPolicy: 'no-cache',
  });

  const [getContactIdsByFilter] = useLazyQuery(LeadQueries.searchContacts, {
    fetchPolicy: 'no-cache',
  });

  const [getFutureBookedAppointments, { refetch: refetchAppointments }] = useLazyQuery(
    GET_FUTURE_BOOKED_APPOINTMENTS_BY_CONTACT_IDS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getUnderMyCareContactIds] = useLazyQuery(
    GET_UNDER_MY_CARE_CONTACT_IDS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getUnderMyCareContactIdsWithCareTeamMemberTypeUserId] = useLazyQuery(
    GET_UNDER_MY_CARE_CONTACT_WITH_MATCH_CARE_MEMBER_TYPE_USER,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const [getContactByMatchCareTeamMemberType] = useLazyQuery(
    GET_CONTACT_ID_BY_MATCH_CARE_MEMBER_TYPE_USER,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    },
  );

  const getPatientTabCount = async (param: IPatientCategoryWiseCountParams) => {
    let responseMap: IPatientCategoryCount[] = [];
    let contactIds = [];
    if (params?.filter?.careManagerId) {
      const underMyCareContactResponse = await getContactByMatchCareTeamMemberType({
        variables: {
          memberTypeCode: CARE_TEAM_MEMBER_TYPE.CARE_MANAGER,
          memberTypeUserId: param?.filter?.formData?.careManagerId
        }
      });
      if (underMyCareContactResponse.data?.careTeams?.length) {
        contactIds = underMyCareContactResponse.data.careTeams.map(
          (result: { contactId: string }) => {
            return result.contactId;
          },
        );
      }
      if (!contactIds?.length) {
        const categoryWiseCount: { categoryCode: string; count: number; }[] = [];
        if (param?.categoryCodes?.length) {
          (param.categoryCodes || []).forEach(categoryCode => {
            categoryWiseCount.push({
              categoryCode: categoryCode,
              count: 0,
            });
          });
        }
        return responseMap;
      }
    }
    if (isProgramBasedDashboard) {
      responseMap = await getCareProgramBasedDashboardCount(param, contactIds);
    } else {
      const countResponse = await getContactIdWiseResponse(contactIds, param);
      responseMap.push({
        tabCode: param.tabCode,
        count: countResponse?.data?.searchContacts?.contactAggregate?.aggregate?.count,
      });
    }
    return responseMap;
  };

  const getAppointmentListByContactIds = async (popGroupAwvDueContactIds: string[], popGroupAwvDueThreeMonthsContactIds: string[]) => {
    const appointmentStatusList =
      getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.APPOINTMENT_STATUS
      ) || [];

    const filteredPopGroupAwvDueContactIds = getAwvFilteredContactIds(
      popGroupAwvDueContactIds,
      popGroupAwvDueThreeMonthsContactIds
    );

    const scheduledStatusId = appointmentStatusList?.find(
      (status) => status?.code === APPOINTMENT_STATUS_CODES.BOOKED
    )?.id;

    const appointmentResponse = await getFutureBookedAppointments({
      variables: {
        contactIds: filteredPopGroupAwvDueContactIds,
        startDateTime: getCurrentDateTimeMoment().toISOString(),
        statusId: scheduledStatusId,
      },
    });

    return appointmentResponse?.data?.appointments;
  }

  const getUniqueContactIdsFromResponse = async (filterType: string, popGroupAwvDueContactIds: string[], appointments: any[]) => {
    const appointmentUniqueContactIds = getUniqueContactIds(appointments)
    if (filterType === PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT) {
      return appointmentUniqueContactIds.filter((item:any) => popGroupAwvDueContactIds?.includes(item));
    } else if (filterType === PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED) {
      const filteredPopGroupAwvDueResponseIds = getAwvFilteredContactIds(popGroupAwvDueContactIds, appointmentUniqueContactIds)
      return filteredPopGroupAwvDueResponseIds;
    }
  }

  const getCategoryWiseFilterEnum = (categoryCodes: string[]) => {
    const categoryWiseEnum: StepFilter[] = [];
    for (const categoryCode of categoryCodes) {
      switch (categoryCode) {
        case PROGRAM_STEPS_FILTER_CODE.ALL:
          categoryWiseEnum.push(StepFilter.ALL);
          break;
        case PROGRAM_STEPS_FILTER_CODE.ASSESSMENT:
          categoryWiseEnum.push(StepFilter.ASSESSMENT);
          break;
        case PROGRAM_STEPS_FILTER_CODE.CONSENT:
          categoryWiseEnum.push(StepFilter.CONSENT);
          break;
        case PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN:
          categoryWiseEnum.push(StepFilter.PLAN);
          break;
        case PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F:
          categoryWiseEnum.push(StepFilter.SCHEDULE);
          break;
        case PROGRAM_FILTER_CODE.CCM:
          categoryWiseEnum.push(StepFilter.CCM);
          if (params?.filter.categoryCode && params?.filter.categoryCode === PROGRAM_FILTER_CODE.CCM) {
            categoryWiseEnum.push(StepFilter.CCM_VISIT_PENDING);
            categoryWiseEnum.push(StepFilter.CCM_CONSENT_PENDING);
            categoryWiseEnum.push(StepFilter.CCM_ASSESSMENT_PENDING);
            categoryWiseEnum.push(StepFilter.CCM_ENROLLMENT_PENDING);
            categoryWiseEnum.push(StepFilter.CCM_CARE_PLAN_DUE);
            categoryWiseEnum.push(StepFilter.CCM_CARE_PLAN_ENROLL);
          }
          break;
        case PROGRAM_FILTER_CODE.ACCP:
          categoryWiseEnum.push(StepFilter.ACCP);
          break;
        case PROGRAM_FILTER_CODE.TCM:
          categoryWiseEnum.push(StepFilter.TCM);
          if (params?.filter.categoryCode && params?.filter.categoryCode === PROGRAM_FILTER_CODE.TCM) {
            categoryWiseEnum.push(StepFilter.TCM_OUTREACH_PENDING);
            categoryWiseEnum.push(StepFilter.TCM_APPOINTMENT_PENDING);
          }
          break;
        case PROGRAM_FILTER_CODE.ECM:
          categoryWiseEnum.push(StepFilter.ECM);
          if (params?.filter.categoryCode && params?.filter.categoryCode === PROGRAM_FILTER_CODE.ECM) {
            categoryWiseEnum.push(StepFilter.ECM_OUTREACH_PENDING);
            categoryWiseEnum.push(StepFilter.ECM_CONSENT_PENDING);
            categoryWiseEnum.push(StepFilter.ECM_ASSESSMENT_PENDING);
            categoryWiseEnum.push(StepFilter.ECM_CARE_PLAN_DUE);
            categoryWiseEnum.push(StepFilter.ECM_CARE_PLAN_ENROLL);
          }
          break;
        case PROGRAM_FILTER_CODE.AWV_DUE:
          categoryWiseEnum.push(StepFilter.AWV);
          if (params?.filter.categoryCode && params?.filter.categoryCode === PROGRAM_FILTER_CODE.AWV_DUE) {
            categoryWiseEnum.push(StepFilter.AWV_SCHEDULED);
            categoryWiseEnum.push(StepFilter.NOT_SCHEDULED);
            categoryWiseEnum.push(StepFilter.OTHER_APPOINTMENT);
          }
          break;
        case PROGRAM_FILTER_CODE.CCM_ENROLLED:
          categoryWiseEnum.push(StepFilter.CCM_ENROLLED);
          break;
        case PROGRAM_FILTER_CODE.ALERTS:
          categoryWiseEnum.push(StepFilter.ALERTS);
          break;
      }
    }
    return categoryWiseEnum;
  }


  const getStepFilterCategoryIds = (categoryCodes: string[]) => {
    const categoryIds: string[] = [];
    for (const categoryCode of categoryCodes) {
      switch (categoryCode) {
        case PROGRAM_STEPS_FILTER_CODE.ASSESSMENT:
          if (assessmentCategory?.id) {
            categoryIds.push(assessmentCategory.id);
          }
          break;
        case PROGRAM_STEPS_FILTER_CODE.CONSENT:
          if (consentCategory?.id) {
            categoryIds.push(consentCategory.id);
          }
          break;
        case PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN:
          if (planCategory?.id) {
            categoryIds.push(planCategory.id);
          }
          break;
        case PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F:
          if (scheduleF2FCategory?.id) {
            categoryIds.push(scheduleF2FCategory.id);
          }
          break;
      }
    }
    return categoryIds;
  }

  const getProgramCodesByCategory = (categoryCodes: string[]) => {

    const programCodes: string[] = [];
    for (const categoryCode of categoryCodes) {
      switch (categoryCode) {
        case PROGRAM_FILTER_CODE.AWV_DUE:
          programCodes.push(AWV_POP_GROUP_CODE);
          if (args.params.filter.categoryCode ===  PROGRAM_FILTER_CODE.AWV_DUE) {
              programCodes.push(FUTURE_AWV_API_CODE);
          }

      }
    }
    return programCodes;
  }

  const getProgramIdsByCategory = (categoryCodes: string[]) => {
    const programIds: string[] = [];
    for (const categoryCode of categoryCodes) {
      switch (categoryCode) {
        case PROGRAM_FILTER_CODE.CCM:
          programIds.push(ccmCareProgramTypeId);
          break;
        case PROGRAM_FILTER_CODE.TCM:
          programIds.push(tcmCareProgramTypeId);
          break;
        case PROGRAM_FILTER_CODE.ECM:
          programIds.push(ecmCareProgramTypeId);
          break;
        case PROGRAM_FILTER_CODE.ACCP:
          programIds.push(accpCareProgramTypeId);
          break;
      }
    }
    return programIds;
  }

  const getCareProgramContactIdsByStepCategoryId = (categoryId: string, contactCareProgramStepsLogs: IContactProgramCountResponse[], myContactIds: string[]): string[] => {
    const filterContactCarePrograms: IContactProgramCountResponse[] = (contactCareProgramStepsLogs || []).filter(contactCareProgramStepsLog => {
      return contactCareProgramStepsLog?.careProgramStep?.careProgramStepTypeId === categoryId;
    }) || [];
    if (!filterContactCarePrograms?.length) {
      return [];
    }
    let contactIds: string[] = [];
    (filterContactCarePrograms || []).forEach(filterContactCareProgram => {
      if (filterContactCareProgram?.contactCareProgram?.contactId) {
        if (!contactIds.includes(filterContactCareProgram.contactCareProgram.contactId)) {
          contactIds.push(filterContactCareProgram.contactCareProgram.contactId);
        }
      }
    });
    if (params.tabCode == DASHBOARD_TAB_CODE.MY_PATIENT) {
      if (myContactIds?.length) {
        contactIds = (contactIds || []).filter(contactId => {
          return myContactIds.includes(contactId);
        });
      } else {
        contactIds = [];
      }
    }
    return contactIds;
  }

  const getCandidatesOfProgramByRuleCode = (ruleCode: string, programWiseResponse: IPopGroupResult[], contactIds: string[]): string[] => {
    let contactUuids: string[] = [];
    (programWiseResponse || []).some(programResponse => {
      if (programResponse?.ruleCode === ruleCode) {
        contactUuids = programResponse?.ruleResult?.contactUuids || [];
        return true;
      }
    });
    if (params?.tabCode === DASHBOARD_TAB_CODE.MY_PATIENT && !contactIds?.length) {
      return [];
    }
    if (contactIds?.length) {
      contactUuids = (contactUuids || []).filter(contactUuid => {
        return contactIds.includes(contactUuid);
      });
    }
    return contactUuids;
  }

  const getPayerProgramIdWiseContactIds = (payerProgramId: string, programWiseResponse: IPayerProgramWiseProgramResponse[], contactIds: string[]): string[] => {
    let contactUuids: string[] = [];
    (programWiseResponse || []).forEach(programResponse => {
      if (programResponse?.contactCareProgram?.payerCareProgram?.careProgramTypeId === payerProgramId) {
        contactUuids.push(programResponse?.contactCareProgram?.contactId);
      }
    });
    if (params?.tabCode === DASHBOARD_TAB_CODE.MY_PATIENT && !contactIds?.length) {
      return [];
    }
    if (contactIds?.length) {
      contactUuids = (contactUuids || []).filter(contactUuid => {
        return contactIds.includes(contactUuid);
      });
    }
    return contactUuids;
  }

  const addEmptyPromise = () => {
    return new Promise((resolve)=> resolve(undefined))
  }

  const getResponseByRequestObject = async (
    categoryWiseEnum: StepFilter[],
    categoryCodes: string[],
    contactIds: string[]
  ) => {
    const responseList: any[] = [];
    try {
      const filterStatusIds: string[] = [];
      (careProgramStepStatusList || []).map(careProgramStepStatus => {
        if (careProgramStepStatus.code == STEP_STATUS.FAIL || careProgramStepStatus.code == STEP_STATUS.TODO) {
          filterStatusIds.push(careProgramStepStatus.id);
        }
      });
      const promiseList: any[] = [];

      if (promiseList?.length) {
        for (const enumCode of categoryWiseEnum) {
          switch (enumCode) {
            case StepFilter.ALL:
              const AllCategoryResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_STEPS_FILTER_CODE.ALL,
                count: 0
              };
              responseList.push(AllCategoryResponse);
              break;
            case StepFilter.ASSESSMENT:
              const assessmentCategoryResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_STEPS_FILTER_CODE.ASSESSMENT,
                count: 0
              };
              responseList.push(assessmentCategoryResponse);
              break;
            case StepFilter.CONSENT:
              const consentCategoryResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_STEPS_FILTER_CODE.CONSENT,
                count: 0
              };
              responseList.push(consentCategoryResponse);
              break;
            case StepFilter.PLAN:
              const planCategoryResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN,
                count: 0
              };
              responseList.push(planCategoryResponse);
              break;
            case StepFilter.SCHEDULE:
              const scheduleF2FCategoryResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F,
                count: 0
              };
              responseList.push(scheduleF2FCategoryResponse);
              break;
            case StepFilter.CCM:
              const ccmProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.CCM,
                count: 0
              };
              responseList.push(ccmProgramResponse);
              break;
            case StepFilter.ACCP:
              const accpProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.ACCP,
                count: 0
              };
              responseList.push(accpProgramResponse);
              break;
            case StepFilter.AWV:
              const awvProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.AWV_DUE,
                count: 0
              };
              responseList.push(awvProgramResponse);
              break;
            case StepFilter.TCM:
              const tcmProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.TCM,
                count: 0
              };
              responseList.push(tcmProgramResponse);
              break;
            case StepFilter.ECM:
              const ecmProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.ECM,
                count: 0
              };
              responseList.push(ecmProgramResponse);
              break;
            case StepFilter.CCM_ENROLLED:
              const ccmeEnrolledResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.CCM_ENROLLED,
                count: 0
              };
              responseList.push(ccmeEnrolledResponse);
              break;
            case StepFilter.AWV_SCHEDULED:
              const awvScheduledProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_SUB_FILTER_CODE.AWV_SCHEDULED,
                count: 0
              };
              break;
            case StepFilter.NOT_SCHEDULED:
              const awvNotScheduledProgramResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED,
                count: 0
              };
              responseList.push(awvNotScheduledProgramResponse);
              break;
            case StepFilter.OTHER_APPOINTMENT:
              const awvOtherAppointementsResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT,
                count: 0
              };
              responseList.push(awvOtherAppointementsResponse);
              break;
            case StepFilter.ALERTS:
              const alertResponse: IStepWiseContactIdResponse = {
                contactIds: [],
                categoryCode: PROGRAM_FILTER_CODE.ALERTS,
                count: 0
              };
              responseList.push(alertResponse);
              break;
          }
        }
      }
    }
      catch (error) {
        console.log('error in fetching response', error)
       }
    return responseList;
  };

  const getUniqueContactIds = (meetings: any) => {
    const uniqueContactIds = new Set();
    meetings.forEach((meeting: any) => {
      meeting.participants.forEach((participant: any) => {
        uniqueContactIds.add(participant.contactId);
      });
    });
    return Array.from(uniqueContactIds);
  }

  const getFormattedResponseFilter = (response: IStepsLogResponse, categoryCode: string) => {
    const formattedResponse = {
      categoryCode: categoryCode,
      contactIds: [],
      count: 0,
    } as IFilterCategoryApiResponseMap;
    const contactIds: string[] = [];
    if (response?.contactCareProgramStepsLogs?.length) {
      for (const stepResponse of response.contactCareProgramStepsLogs) {
        if (stepResponse?.contactCareProgram?.contactId) {
          contactIds.push(stepResponse.contactCareProgram.contactId);
        }
      }
    }
    formattedResponse.contactIds = contactIds;
    formattedResponse.count = response?.contactCareProgramStepsLogs?.length || 0;
    return formattedResponse;
  }

  const getFormattedResponsePopGroupFilter = (response: IPopGroupResponse, categoryCode: string) => {
    return {
      categoryCode: categoryCode,
      contactIds: response?.result?.[0]?.ruleResult?.contactUuids,
      count: response?.result?.[0]?.ruleResult?.count || 0,
    } as IFilterCategoryApiResponseMap;
  }

  const getProgramStepWiseTypeId = (subFilterCode: StepFilter) => {
    const filterStatusIds: string[] = [];
    (careProgramStepStatusList || []).map(careProgramStepStatus => {
      if (careProgramStepStatus.code == STEP_STATUS.FAIL || careProgramStepStatus.code == STEP_STATUS.TODO) {
        filterStatusIds.push(careProgramStepStatus.id);
      }
    });
    const stepTypeIds = [];
    const filterCarePlanStatusIds: string[] = [];
    let isCarePlanNotAssigned: boolean | undefined = undefined;
    switch (subFilterCode) {
      case StepFilter.CCM_VISIT_PENDING:
        stepTypeIds.push(scheduleF2FCategory?.id);
        break;
      case StepFilter.CCM_CONSENT_PENDING:
      case StepFilter.ECM_CONSENT_PENDING:
        stepTypeIds.push(consentCategory?.id);
        break
      case StepFilter.CCM_ASSESSMENT_PENDING:
      case StepFilter.ECM_ASSESSMENT_PENDING:
        stepTypeIds.push(assessmentCategory?.id);
        break;
      case StepFilter.CCM_ENROLLMENT_PENDING:
        stepTypeIds.push(ccmEnrolledCategory?.id);
        break;
      case StepFilter.CCM_CARE_PLAN_DUE:
      case StepFilter.ECM_CARE_PLAN_DUE:
        isCarePlanNotAssigned = true;
        break;
      case StepFilter.CCM_CARE_PLAN_ENROLL:
      case StepFilter.ECM_CARE_PLAN_ENROLL:
        isCarePlanNotAssigned = false;
        const allowCarePlanStatusCodes = [CARE_PLAN_STATUS_CODES.ACTIVE, CARE_PLAN_STATUS_CODES.DRAFT, CARE_PLAN_STATUS_CODES.IN_REVIEW, CARE_PLAN_STATUS_CODES.COMPLETED];
        (carePlanStatusList || []).map(carePlanStatus => {
          if (allowCarePlanStatusCodes.includes(carePlanStatus.code)) {
            filterCarePlanStatusIds.push(carePlanStatus.id);
          }
        });
        break;
      case StepFilter.TCM_OUTREACH_PENDING:
        stepTypeIds.push(tcmOutreachCategory?.id);
        break;
      case StepFilter.TCM_APPOINTMENT_PENDING:
        stepTypeIds.push(tcmAppointmentCategory?.id);
        break;
      case StepFilter.ECM_OUTREACH_PENDING:
        stepTypeIds.push(ecmOutreachCategory?.id);
        break;
    }
    return { careProgramStepTypeIds: stepTypeIds, careProgramStepStatusIds: filterStatusIds, carePlanStatusIds: filterCarePlanStatusIds, isCarePlanNotAssigned: isCarePlanNotAssigned };
  }

  const getProgramSubFilterCode = (subFilterCode: StepFilter): string => {
      let filterCode = '';
      switch (subFilterCode) {
        case StepFilter.CCM_VISIT_PENDING:
          filterCode = PROGRAM_SUB_FILTER_CODE.CCM_VISIT_PENDING;
          break;
        case StepFilter.CCM_CONSENT_PENDING:
          filterCode = PROGRAM_SUB_FILTER_CODE.CCM_CONSENT_PENDING;
          break;
        case StepFilter.CCM_ASSESSMENT_PENDING:
          filterCode = PROGRAM_SUB_FILTER_CODE.CCM_ASSESSMENT_PENDING;
          break;
        case StepFilter.CCM_ENROLLMENT_PENDING:
          filterCode = PROGRAM_SUB_FILTER_CODE.CCM_ENROLLMENT_PENDING;
          break;
        case StepFilter.CCM_CARE_PLAN_DUE:
          filterCode = PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_DUE;
          break;
        case StepFilter.CCM_CARE_PLAN_ENROLL:
          filterCode = PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_ENROLL;
          break;
        case StepFilter.TCM_OUTREACH_PENDING:
          filterCode = PROGRAM_SUB_FILTER_CODE.TCM_OUTREACH_PENDING;
          break;
        case StepFilter.TCM_APPOINTMENT_PENDING:
          filterCode = PROGRAM_SUB_FILTER_CODE.TCM_APPOINTMENT_PENDING;
          break;
        case StepFilter.ECM_OUTREACH_PENDING:
          filterCode = PROGRAM_SUB_FILTER_CODE.ECM_OUTREACH_PENDING;
          break;
        case StepFilter.ECM_CONSENT_PENDING:
          filterCode = PROGRAM_SUB_FILTER_CODE.ECM_CONSENT_PENDING;
          break;
        case StepFilter.ECM_ASSESSMENT_PENDING:
          filterCode = PROGRAM_SUB_FILTER_CODE.ECM_ASSESSMENT_PENDING;
          break;
        case StepFilter.ECM_CARE_PLAN_DUE:
          filterCode = PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_DUE;
          break;
        case StepFilter.ECM_CARE_PLAN_ENROLL:
          filterCode = PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_ENROLL;
          break;
      }
      return filterCode;
  }

  const configureCategoryWiseCountData = async (param: IPatientCategoryWiseCountParams, requestObjects: any[], responseObjects: any[],categoryIds: string[],programIds: string[], filterStatusIds: string[], DoneStatusId: string[]): Promise<IFilterCategoryApiResponseMap[]> => {
    const categoryWiseCountResponse: IFilterCategoryApiResponseMap[] = [];
    const allCategoryContactResponse: string[] = [];
    let filterParams: IGetContactFilterParams;
    // const promiseList = [];
    let categoryIndex = -1;
    let filters: any;
    const filterIndices: Partial<Record<StepFilter, number>> = {};
    for (const requestObject of requestObjects) {
      categoryIndex += 1;
      const categoryResponse = responseObjects?.[categoryIndex];
      const filterKey = requestObject as StepFilter;
      const promiseList:any = [];
      switch (filterKey) {
        case StepFilter.ALL:
          filterParams = getContactFilterParams({ param: param });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.ASSESSMENT:
          filterParams = getContactFilterParams({
            param: param,
            careProgramStepTypeIds: [assessmentCategory?.id] || [],
            careProgramStepStatusIds: filterStatusIds
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.CONSENT:
          filterParams = getContactFilterParams({
            param: param,
            careProgramStepTypeIds: [consentCategory?.id] || [],
            careProgramStepStatusIds: filterStatusIds
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.PLAN:
          filterParams = getContactFilterParams({
            param: param,
            careProgramStepTypeIds: [planCategory?.id] || [],
            careProgramStepStatusIds: filterStatusIds
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.SCHEDULE:
          filterParams = getContactFilterParams({
            param: param,
            careProgramStepTypeIds: [scheduleF2FCategory?.id] || [],
            careProgramStepStatusIds: filterStatusIds
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.CCM_ENROLLED:
          filterParams = getContactFilterParams({
            param: param,
            careProgramStepTypeIds: [ccmEnrolledCategory?.id] || [],
            careProgramStepStatusIds: DoneStatusId,
            careProgramTypeIds: [ccmCareProgramTypeId]
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.AWV:
          filterParams = getContactFilterParams({
            param: param,
            ruleCodes: [AWV_POP_GROUP_CODE]
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.AWV_SCHEDULED:
          filterParams = getContactFilterParams({
            param: param,
            ruleCodes: [FUTURE_AWV_API_CODE]
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.NOT_SCHEDULED:
          filterParams = getContactFilterParams({
            param: param,
            customFilterCode: AWV_SCHEDULED_SUB_FILTER_CODE.NOT_SCHEDULED
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.OTHER_APPOINTMENT:
          filterParams = getContactFilterParams({
            param: param,
            customFilterCode: AWV_SCHEDULED_SUB_FILTER_CODE.OTHER_APPOINTMENT
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.CCM:
          filterParams = getContactFilterParams({
            param: param,
            careProgramTypeIds: [ccmCareProgramTypeId]
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.TCM:
          filterParams = getContactFilterParams({
            param: param,
            careProgramTypeIds: [tcmCareProgramTypeId]
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.ECM:
          filterParams = getContactFilterParams({
            param: param,
            careProgramTypeIds: [ecmCareProgramTypeId]
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.ALERTS:
          filterParams = getContactFilterParams({
            param: param,
            showContactsWithPendingAlerts: true
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        case StepFilter.CCM_VISIT_PENDING:
        case StepFilter.CCM_CONSENT_PENDING:
        case StepFilter.CCM_ASSESSMENT_PENDING:
        case StepFilter.CCM_ENROLLMENT_PENDING:
        case StepFilter.CCM_CARE_PLAN_DUE:
        case StepFilter.CCM_CARE_PLAN_ENROLL:
          const ccmStepFilters = getProgramStepWiseTypeId(filterKey);
          filterParams = getContactFilterParams({param: param, careProgramTypeIds: [ccmCareProgramTypeId],  ...ccmStepFilters});
          filters = getContactFilter(filterParams);
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: filters } }) });
          break;
        case StepFilter.TCM_OUTREACH_PENDING:
        case StepFilter.TCM_APPOINTMENT_PENDING:
          const tcmStepFilters = getProgramStepWiseTypeId(filterKey);
          filterParams = getContactFilterParams({param: param, careProgramTypeIds: [tcmCareProgramTypeId], ...tcmStepFilters});
          filters = getContactFilter(filterParams);
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: filters } }) });
          break;
        case StepFilter.ECM_OUTREACH_PENDING:
        case StepFilter.ECM_CONSENT_PENDING:
        case StepFilter.ECM_ASSESSMENT_PENDING:
        case StepFilter.ECM_CARE_PLAN_DUE:
        case StepFilter.ECM_CARE_PLAN_ENROLL:
          const ecmStepFilters = getProgramStepWiseTypeId(filterKey);
          filterParams = getContactFilterParams({param: param, careProgramTypeIds: [ecmCareProgramTypeId], ...ecmStepFilters});
          filters = getContactFilter(filterParams);
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: filters } }) });
          break;
        case StepFilter.ACCP:
          filterParams = getContactFilterParams({
            param: param,
            careProgramTypeIds: [accpCareProgramTypeId]
          });
          promiseList.push({ filterKey, promise: getContactCount({ variables: { params: getContactFilter(filterParams) } }) });
          break;

        default:
          break;
      }
      const results = await Promise.all(promiseList.map((p:any) => p.promise));
      results.forEach((result, index) => {
          const filterKey = promiseList[index].filterKey;
          const count = result?.data?.searchContacts?.contactAggregate?.aggregate?.count || 0;

          switch (filterKey) {
            case StepFilter.ALL:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_STEPS_FILTER_CODE.ALL,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.ASSESSMENT:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_STEPS_FILTER_CODE.ASSESSMENT,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.CONSENT:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_STEPS_FILTER_CODE.CONSENT,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.PLAN:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.SCHEDULE:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.CCM_ENROLLED:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_FILTER_CODE.CCM_ENROLLED,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.AWV:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_FILTER_CODE.AWV_DUE,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.AWV_SCHEDULED:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_SUB_FILTER_CODE.AWV_SCHEDULED,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.NOT_SCHEDULED:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.OTHER_APPOINTMENT:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.CCM:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_FILTER_CODE.CCM,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.TCM:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_FILTER_CODE.TCM,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.ECM:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_FILTER_CODE.ECM,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            case StepFilter.ALERTS:
              categoryWiseCountResponse.push({
                categoryCode: PROGRAM_FILTER_CODE.ALERTS,
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;
            case StepFilter.CCM_VISIT_PENDING:
            case StepFilter.CCM_CONSENT_PENDING:
            case StepFilter.CCM_ASSESSMENT_PENDING:
            case StepFilter.CCM_ENROLLMENT_PENDING:
            case StepFilter.CCM_CARE_PLAN_DUE:
            case StepFilter.CCM_CARE_PLAN_ENROLL:
            case StepFilter.ECM_ASSESSMENT_PENDING:
            case StepFilter.ECM_CARE_PLAN_DUE:
            case StepFilter.ECM_CARE_PLAN_ENROLL:
            case StepFilter.ECM_CONSENT_PENDING:
            case StepFilter.ECM_OUTREACH_PENDING:
            case StepFilter.TCM_APPOINTMENT_PENDING:
            case StepFilter.TCM_OUTREACH_PENDING:
              categoryWiseCountResponse.push({
                categoryCode: getProgramSubFilterCode(filterKey),
                contactIds: [],
                count: count,
              } as IFilterCategoryApiResponseMap);
              break;

            default:
              break;
          }
      });
    }
    return categoryWiseCountResponse;
  }

  const filterCategoryCodes = () => {
    const categoryCodes = filterParams?.isCategoryChanged ? [filterParams?.categoryCode || ''] : params?.categoryCodes || [];
    return categoryCodes;
  }

  const getCareProgramBasedDashboardCount = async (
    param: IPatientCategoryWiseCountParams,
    contactIds: string[],
  ) => {
    const responseMap: IPatientCategoryCount[] = [];
    const categoryCodes = filterCategoryCodes();
    const categoryWiseEnum: StepFilter[] = getCategoryWiseFilterEnum(categoryCodes);
    const responseObjects = await getResponseByRequestObject(categoryWiseEnum,categoryCodes, contactIds);
    const filterStatusIds: string[] = [];
    (careProgramStepStatusList || []).map(careProgramStepStatus => {
      if (careProgramStepStatus.code == STEP_STATUS.FAIL || careProgramStepStatus.code == STEP_STATUS.TODO) {
        filterStatusIds.push(careProgramStepStatus.id);
      }
    });
    const DoneStatusId: string[] = [];
    (careProgramStepStatusList || []).map(careProgramStepStatus => {
      if (careProgramStepStatus.code == STEP_STATUS.DONE) {
        DoneStatusId.push(careProgramStepStatus?.id);
      }
    });
    const categoryIds = getStepFilterCategoryIds(categoryCodes);
    const programIds = getProgramIdsByCategory(categoryCodes);
    const configureCategoryWiseResponse: IFilterCategoryApiResponseMap[] = await configureCategoryWiseCountData(param, categoryWiseEnum, responseObjects, categoryIds, programIds, filterStatusIds, DoneStatusId);
    const tabWiseResponseMap: IPatientCategoryCount = {
      categoryWiseCount: [],
      tabCode: param.tabCode,
      count: 0
    }
    let allCategoryCount = filterParams?.isCategoryChanged ? dashboardTabCount?.[0]?.count || 0 : 0;
    for (const categoryResponse of configureCategoryWiseResponse) {
      if (categoryResponse.categoryCode === PROGRAM_STEPS_FILTER_CODE.ALL) {
        allCategoryCount = categoryResponse.count;
      }
      tabWiseResponseMap.categoryWiseCount?.push({
        categoryCode: categoryResponse.categoryCode,
        count: categoryResponse.count,
      });
    }
    tabWiseResponseMap.count = allCategoryCount;
    responseMap.push(tabWiseResponseMap);
    return responseMap;
  };

  const getContactFilterParams = ({
    contactIds,
    param,
    careProgramStepTypeIds,
    careProgramTypeIds,
    careProgramStepStatusIds,
    ruleCodes,
    showContactsWithPendingAlerts,
    customFilterCode,
    carePlanStatusIds,
    isCarePlanNotAssigned
  }: IContactFilterParams) :IGetContactFilterParams => {
    return {
      filter: param?.filter,
      tabCode: param?.tabCode,
      accountId: accountId,
      commonContextData: commonContextData,
      patientList: contactIds,
      careProgramStepTypeIds: careProgramStepTypeIds || [],
      careProgramTypeIds: careProgramTypeIds || [],
      careProgramStepStatusIds: careProgramStepStatusIds || [],
      ruleCodes: ruleCodes || [],
      showContactsWithPendingAlerts: showContactsWithPendingAlerts || false,
      customFilterCode: customFilterCode || '',
      ...((carePlanStatusIds || [])?.length && {
        carePlanStatusIds: carePlanStatusIds,
      }),
      ...((isCarePlanNotAssigned != undefined) && {
        isCarePlanNotAssigned: isCarePlanNotAssigned,
      }),
    };
  };

  const getContactIdWiseResponse = async (
    contactIds: string[],
    param: IPatientCategoryWiseCountParams,
  ) => {
    const filterParams: IGetContactFilterParams = getContactFilterParams({contactIds: contactIds, param: param});
    const filter = getContactFilter(filterParams);
    const countResponse = await getContactCount({
      variables: {
        params: filter,
      },
    })
    return countResponse;
  };


  // const getMyPatientTabCount = async (
  //   param: IPatientCategoryWiseCountParams,
  // ) => {
  //   let contactIds: string[] = [];
  //   let responseMap: IPatientCategoryCount[] = [];
  //   let underMyCareContactResponse: any = undefined;
  //   if (params?.filter?.careManagerId) {
  //     underMyCareContactResponse = await getUnderMyCareContactIdsWithCareTeamMemberTypeUserId({
  //       variables: {
  //         userId: currentUserId,
  //         memberTypeCode: CARE_TEAM_MEMBER_TYPE.CARE_MANAGER,
  //         memberTypeUserId: param?.filter?.formData?.careManagerId
  //       }
  //     });
  //   } else {
  //     underMyCareContactResponse = await getUnderMyCareContactIds({
  //       variables: {
  //         userId: currentUserId,
  //       },
  //     });
  //   }
  //   if (underMyCareContactResponse.data?.careTeams?.length) {
  //     contactIds = underMyCareContactResponse.data.careTeams.map(
  //       (result: { contactId: string }) => {
  //         return result.contactId;
  //       },
  //     );
  //   }
  //   if (!contactIds?.length) {
  //     const categoryWiseCount: { categoryCode: string; count: number; }[] = [];
  //     if (param?.categoryCodes?.length) {
  //       (param.categoryCodes || []).forEach(categoryCode => {
  //         categoryWiseCount.push({
  //           categoryCode: categoryCode,
  //           count: 0,
  //         });
  //       });
  //     }
  //     responseMap.push({
  //       categoryWiseCount: categoryWiseCount,
  //       tabCode: param.tabCode,
  //       count: 0,
  //     });
  //     return responseMap;
  //   }
  //   if (isProgramBasedDashboard) {
  //     responseMap = await getCareProgramBasedDashboardCount(param, contactIds);
  //   } else {
  //     const countResponse = await getContactIdWiseResponse(contactIds, param);
  //     responseMap.push({
  //       tabCode: param.tabCode,
  //       count: countResponse?.data?.searchContacts?.contactAggregate?.aggregate?.count,
  //     });
  //   }
  //   return responseMap;
  // };

  const getLoadingCategoryTabs = () => {
    const categoryCodes = filterCategoryCodes() || []

    if (
      categoryCodes?.[0] === PROGRAM_FILTER_CODE.AWV_DUE
    ) {
      categoryCodes.push(PROGRAM_SUB_FILTER_CODE.AWV_SCHEDULED);
      categoryCodes.push(PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED);
      categoryCodes.push(PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT);
    }
    return categoryCodes;
  }


  const getPatientCountByFilter = async () => {
    setLoading(true);
    setLoadingCategoryCodes(getLoadingCategoryTabs())
    let responseMap: IPatientCategoryCount[] = [];
    switch (params.tabCode) {
      case DASHBOARD_TAB_CODE.MY_PATIENT:
        const countMyPatientResponse = await getPatientTabCount(params);
        responseMap = responseMap.concat(countMyPatientResponse);
        break;
      case DASHBOARD_TAB_CODE.PATIENT:
        const countResponseOfPatient = await getPatientTabCount(params);
        responseMap = responseMap.concat(countResponseOfPatient);
        break;
    }
    setFirstFetch(true);
    setDashboardTabCount(responseMap);
    setLoading(false);
    setLoadingCategoryCodes([]);
  };

  // Public methods
  const refreshCount = () => {
    getPatientCountByFilter();
  };

  const { pushEvent } = useDebounceEvent({
    onDebounce: () => {
      refreshCount();
    }
  });

  const updateFilterParamsState = (isCategoryChanged?: boolean) => {
    delete filterParams.isCategoryChanged;
    if (!firstFetch || !isEqual(filterParams, params?.filter)) {
      setFilterParams({
        ...params?.filter,
        isCategoryChanged: isCategoryChanged,
      });
    }
  };

  // Lifecycle methods
  useEffect(() => {
    if (isProgramBasedDashboard) {
      updateFilterParamsState();
    } else {
      setDashboardTabCount([]);
      setLoading(false);
    }
  }, [
    params?.filter?.contactUpdated,
    params?.filter?.formData,
    params?.filter?.inboxItem,
    params?.filter?.loggedInStatus,
    params?.filter?.personType,
    params?.filter?.personTypeUuid,
    params?.filter?.searchText,
    params?.filter?.selectedUserPracticeLocation,
    params?.filter?.tab,
    params?.filter?.tagItem,
    params?.filter?.status,
    params?.filter?.careManagerId,
  ]);

  useEffect(() => {
    if (isProgramBasedDashboard) {
      updateFilterParamsState(!firstFetch ? false : true);
    } else {
      setDashboardTabCount([]);
      setLoading(false);
    }
  }, [params?.filter?.categoryCode]);

  useEffect(()=> {
    if (Object.keys(filterParams)?.length) {
      refreshCount();
    }
  }, [filterParams]);

  return { dashboardTabCount, tabCountLoading, refreshCount, loadingCategoryCodes };
};

export default usePatientCountManager;


