import {Bar, BarConfig, BidirectionalBarConfig, Column, ColumnConfig, BidirectionalBar} from '@ant-design/charts';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  ANALYTICS_RESOURCE,
  APPOINTMENT_MODE_CODE,
  DATE_FORMATS,
  DISPLAY_TYPE,
  EMPLOYER_REPORT_FILTER_CODES,
  HIDE_TOTAL_COUNT_IN_LEGEND,
  MOMENT_DATE_CONST,
} from '../../../../../constants';
import {
  getFormattedDataForBarChart,
  getToolTipList,
  getTopDataViewForBarChart,
  wordWrapFromChart,
} from '../EmployerUtils/FormateDataUtils';
import {IAppliedFilter, IResource, IWidgetCardView} from '../interface';
// import ReactToPrint, {useReactToPrint} from 'react-to-print';
import {Skeleton} from 'antd';
import moment from 'moment';
import {Colors, GraphColors} from '../../../../../styles/Colors';
import EyeHideSvg from '../../../../common/Svg/WidgetsSvg/EyeHideSvg';
import InfoIconView from '../CommonView/InfoIconView';
import NodataViewTypeOne from './NoDataView/NodataViewTypeOne';
import WidgetActionView from './WidgetActionView';
import {DashboardDataContext} from '../EmployerContext/DashboardContext';
import {OFFICE_HOURS_CONST, WIDGET_ACTION_CODE} from '../EmployerUtils/EmployerConst';
import {
  APPOINTMENT_BOOKED,
  APPOINTMENT_SELF_SCHEDULED,
  AUTOMATION_TIME_SAVED,
  AVOIDED_ED,
  AVOIDED_SPECIALIST_VISIT,
  AVOIDED_WC,
  AVOIDED_WORKDAY_ABSENCE,
  ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT_DATA,
  EMPLOYEE_VS_DEPENDANT_VISITS,
  ENCOUNTER_TYPES,
  getDemoDataForEmployerReportCurrentSection,
  getTenantForDemo,
  LOGGED_IN_COUNT,
  MEMBERSHIP_INACTIVATED,
  MEMBERSHIP_NET_GROWTH,
  OUT_OFFICE_HOUR_USER_ENGAGEMENT_DATA,
  PATIENT_APP_MESSAGING_DATA,
  SMS_MESSAGING_DATA,
  TEMP_CLINICAL_INTERVENTION_MESSAGE_NOTES,
  VIDEO_CALLING,
  VOICE_CALLING_DIRECTION_WISE,
} from '../tempDataFile';
import {getSectionDataByResourceCode} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {aggregateGroupEntry, getDateRangeFilterFromAppliedFilter, getXAxisTitleByResourceCode, getYAxisTitleByResourceCode, isWidgetDataEmpty} from '../EmployerUtils/WidgetUtils';
import {Pressable} from 'react-native';
import RestSvgIcons from '../../../../common/Svg/RestSvgIcons';
import {Tooltip} from 'native-base';
import {getFormattedMemberType, getYAxisMaxValueWidgetObject} from './utils';
import {getEmployerReportFilterList} from '../../../../../utils/commonUtils';
import WidgetHeader from '../WidgetHeader/WidgetHeader';
import {graphColorList, graphMetaDataColor} from '../graphColors';
import {capitalizeText} from '../../../ContentManagement/CampaignManagement/Helper';
import CustomTooltip from './MixMultipleCharts/CustomTooltip';
import {getDateStrFromFormat, getFormattedDateStringInTimeZone} from '../../../../../utils/DateUtils';
import {ModalActionSelect} from '../../../../common/ModalActionCommonComponent/ModalActionSelect';
import {MEMBER_SATIFCATION_FORMS} from '../TempAppointmentDummyData';
import ArrowRightSvg from '../../../../common/Svg/ArrowRightSvg';
import {useMutation} from '@apollo/client';
import EmployerDashboardQueries from '../../../../../services/Employer/EmployerServices/EmployerDashboardQueries';
import {COMMON_ACTION_CODES, MEMBER_SATISFACTION_ACTION_CODE} from '../../../../../constants/ActionConst';
import {FORM_STATUS_CODE} from '../../../../../constants/MlovConst';
import LocationFilterDropdown from '../LocationFilterDropDown';
import CommunicationStatDetails from './CommunicationStatDetails';
import { LG_CONFIG, SM_CONFIG, XS_CONFIG, XSL_CONFIG } from '../../../../../theme/FontConfig/TextFontSizeConfig';
const WidgetBarChartView = (props: IWidgetCardView) => {
  const {
    sectionData,
    dateRange,
    appliedFilter,
    employerId,
    isAccountDashboard,
    memberType,
    memberSatisfactionFormList,
    onWidgetLoaded,
    hintMembershipDisabled,
  } = props;
  const containerRef: any = useRef()
  const titleRef: any = useRef();
  const [stateData, setStateData] = useState({
    resourceData: [] as IResource[] | any,
    loading: false,
    employeeTotalCount: 0,
    selectedView: '',
    selectedResourceData: {} as any,
    masterData: {} as any,
    appliedFilter: props.appliedFilter,
    filterValueName: [] as string[],
    filterValueString: '',
    selectedAppliedFilter: [] as any[],
    graphWrapperHeight: 350,
    selectedFormId: '',
    additionalData: {} as any,
    selectedLocation: props.accountPracticeLocations[0]?.uuid || ''
  });
  const isEmployeeDependents =
    sectionData.resourceCode ===
    ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS;
  const isEncounterTypes =
    sectionData.resourceCode === ANALYTICS_RESOURCE.ENCOUNTER_TYPES;
  const isSelfScheduled =
    sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED
  const isPatientAppChats =
    sectionData.resourceCode === ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING;
  const isVoiceCallByType =
    sectionData.resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE;
  const isMemberSatisfactionSurvey =
    sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION;
  const isSmsChats =
    sectionData.resourceCode === ANALYTICS_RESOURCE.SMS_MESSAGING;
  const isAppointmentBooked =
    sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BOOKED;
  const isVideoCalling =
    sectionData.resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING;
  const isEmployeeVsDependentUtilization =
    sectionData.resourceCode === ANALYTICS_RESOURCE.ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT;
  const isTimeSaved =
    sectionData.resourceCode === ANALYTICS_RESOURCE.AUTOMATION_TIME_SAVED
  const isLoggedIn = ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN === sectionData.resourceCode
  const isEncounterNotes = sectionData.resourceCode === ANALYTICS_RESOURCE.CLINICAL_INTERVENTION_MESSAGE_NOTES;
  const isMembershipGrowth = sectionData.resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH;
  const isMembershipInactivated = sectionData.resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED;
  const isOutOfficeHourUserEngagement = sectionData.resourceCode === ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT;
  let finalResourceData = stateData.resourceData || [];
  const [UPDATE_SECTION_ADDITIONAL] = useMutation(
    EmployerDashboardQueries.UPDATE_SECTION_ADDITIONAL
  );
  if (getTenantForDemo() && sectionData?.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION) {
    finalResourceData = getDemoDataForEmployerReportCurrentSection(sectionData);
  } else if (sectionData?.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION) {
    finalResourceData = stateData.resourceData?.result;
  }
  const dateFilter = getDateRangeFilterFromAppliedFilter(appliedFilter)
  const formattedResourceData = getFormattedDataForBarChart(
    finalResourceData,
    dateFilter,
    sectionData.currentDisplayType || '',
    sectionData.resourceCode || '',
    isAccountDashboard
  );
  const topViewData = getTopDataViewForBarChart(
    formattedResourceData,
    sectionData.resourceCode || ''
  );
  const onActionClick = (code: string, data?: any) => {
    switch (code) {
      case WIDGET_ACTION_CODE.RESET_LOCATION_FILTER:
        const filteredWidgetFilter = stateData.selectedAppliedFilter.filter(item => {
          return item.filterCode != 'LOCATION'
        })
        setStateData(prev => {
          return {
            ...prev,
            selectedAppliedFilter: filteredWidgetFilter,
            filterValueString: '',
            filterValueName: []
          }
        })
        getSectionByResourceCode();
        break;
      default:
        props.onActionClick?.(code, data)
        break;
    }
  };

  const updateMemberSatisFactionForm = async (selectedFormId: string) => {
    const additionalAttributes = {
      ...sectionData.additionalAttributes,
      formId: selectedFormId,
    };
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await UPDATE_SECTION_ADDITIONAL({
      variables: {
        id: sectionData?.id,
        additionalAttributes: additionalAttributes,
      },
    }).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (response?.data?.updatePageSections?.returning?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          selectedFormId: selectedFormId,
          loading: false,
        }
      });
    }
  }

  const graphYAxisTitle = getYAxisTitleByResourceCode(sectionData.resourceCode || '', sectionData)
  const graphXAxisTitle = getXAxisTitleByResourceCode(sectionData.resourceCode || '')
  const getColumnChartWithStack = (height?: number) => {

    const dateFilter = getDateRangeFilterFromAppliedFilter(appliedFilter);
    let tempFormattedResourceData = [...formattedResourceData];
    if (getTenantForDemo() && sectionData?.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION) {
      const resourceData: any = getDemoDataForEmployerReportCurrentSection(sectionData);
      const tempResourceData = getFormattedDataForBarChart(
        [...resourceData?.result],
        dateFilter,
        sectionData.currentDisplayType || '',
        sectionData.resourceCode || '',
        isAccountDashboard
      );
      if (tempResourceData && Object.keys(tempResourceData)?.length) {
        tempFormattedResourceData = [...tempResourceData];
      }

    }
    const yAxisMaxValue = getYAxisMaxValueWidgetObject(tempFormattedResourceData, sectionData.resourceCode)
    const configStack: ColumnConfig = {
      data: tempFormattedResourceData,
      color: graphColorList,
      isStack: true,
      xField: 'month',
      yField: 'value',
      seriesField: 'type',
      yAxis: {
        tickLine: {
          style: {
            fill: '#fff',
            stroke: '#fff',
          },
        },
        label: {
          style: {
            fontSize: 12,
            fontWeight: 400,
            fill: graphMetaDataColor.axisTextColor
          }
        },
        title: {
          text: graphYAxisTitle.title,
          description: graphYAxisTitle.description,
          style: {
            fill: graphMetaDataColor.axisTextColor
          }
        },
        line: {
          style: {
            stroke: '#F2F4F7',
          },
        },
        grid: {
          alignTick: true,
          line: {
            type: 'line',
            style: {
              stroke: '#F2F4F7'
            }
          },
          closed: true
        },
        ...(yAxisMaxValue)
      },
      renderer: 'svg',
      xAxis: {
        tickLine: {
          style: {
            fill: '#fff',
            stroke: '#fff',
          },
        },
        title: {
          text: graphXAxisTitle.title,
          description: graphXAxisTitle.description,
          style: {
            fill: graphMetaDataColor.axisTextColor
          },
          offset: 60
        },
        label: {
          offset: 20,
          offsetY: -4,
          rotate: -1.1,
          style: {
            fontSize: 9,
            fontWeight: 400,
            fill: graphMetaDataColor.axisTextColor
          },
        },
        line: {
          style: {
            stroke: '#F2F4F7',
          },
        },
      },
      legend: {
        position: 'top-right',
        itemSpacing: 3,
        itemName: {
          formatter(text: any) {
            return text?.toLocaleUpperCase()
          },
        },
        layout: 'horizontal',
        marker: {
          symbol: 'circle',
          style(style: any) {
            return {
              ...style,
              r: 4
            }
          },
        }
      },
      label: {
        position: 'middle',
        style: {
          fill: 'transparent'
        },
        formatter: () => {
          return ''
        }
      },
      height: height ? height : 240,
      columnStyle(datum: any) {
        return {
          radius: [2, 2, 0, 0],
          fill: null
        };
      },
      tooltip: {
        shared: true,
        customContent : (title: string, data: any[]) => {
          let videoCallingFlag = false;
          const allMembersColumns: any[] = [];
          data.forEach((singleItem) => {
            if(sectionData.resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING) {
              singleItem.value = singleItem.value + ' (minutes)';
              videoCallingFlag = true;
            }
            allMembersColumns.push(singleItem?.data?.type?.toLowerCase())
          })
          const totalMetaData: any[] = [
            {
              title: 'Total',
              columnList: allMembersColumns,
            }
          ];
          if (title && data?.length) {
            return <CustomTooltip title={title} data={data} totalMetaData={totalMetaData} videoCallingFlag={videoCallingFlag} />
          }
        }
      },
      annotations: aggregateGroupEntry(sectionData.resourceCode || '', tempFormattedResourceData),
      onReady: (plot: any) => {
        plot.on('element:click', (args: any) => {
          if (args?.data?.data?.type) {
            onReady(args);
          }
        });
      },
    };
    return <Column {...configStack} />;
  }
  const getBarChartViewByType = () => {
    let ele = <></>;
    switch (sectionData.currentDisplayType) {
      case DISPLAY_TYPE.HORIZONTAL_BAR_CHART:
        const config1: BarConfig = {
          data: formattedResourceData,
          xField: 'xField',
          yField: 'yField',
          autoFit: true,
          color: graphColorList,
          renderer: 'svg',
          xAxis: {
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60
            },
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              rotate: -1
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          yAxis: {
            tickLine: {
              style: {
                fill: '#fff',
                stroke: '#fff',
              },
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              formatter: (text: string) => {
                return wordWrapFromChart(text, 50);
                // return text;
              },
            },
            title: {
              text: graphYAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          label: {
            position: 'middle',
            style: {
              fill: null
            },
            formatter: () => {
              return ''
            }
          },
          legend: {
            maxItemWidth: 100,
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style) {
                return {
                  ...style,
                  fill: style.stroke,
                  stroke: null
                }
              },
            }
          },
          height: 240,
          width: 470,
          barBackground: {
            style: {
              fill: 'transparent',
              points: [{}],
              lineDash: [0]
            },
          },
          barStyle(datum) {
            return {
              radius: [8, 8, 8, 8],
              // fill: '#B692F6',
              lineDash: [0],
              points: [{}]
            };
          },
          interactions: [
            {
              type: 'active-region',
              enable: false,
            },
          ],
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data?.type || args?.data?.data?.xField || args?.data?.data?.yField) {
                onReady(args);
              }
            });
          },
        };
        ele = <Bar {...config1} />;
        break;
      case DISPLAY_TYPE.BAR_CHART:
        const config: ColumnConfig = {
          data: formattedResourceData,
          color: graphColorList,
          xField: 'action',
          yField: 'pv',
          conversionTag: {},
          renderer: 'svg',
          xAxis: {
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60
            },
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              rotate: -1
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          yAxis: {
            title: {
              text: graphYAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style) {
                return {
                  ...style,
                  fill: style.stroke,
                  stroke: null
                }
              },
            }
          },
          label: {
            position: 'middle',
            formatter: () => {
              return ''
            }
          },
          height: 240,
          columnStyle(datum) {
            return {
              radius: [8, 8, 0, 0],
              opacity: 0.3,
              fill: null
            };
          },
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data?.type) {
                onReady(args);
              }
            });
          },
        };
        ele = <Column {...config} />;
        break;
      case DISPLAY_TYPE.BAR_CHART_WITH_STACK_AND_GROUP_COLUMN:
        const configStackGroup: ColumnConfig = {
          data: formattedResourceData,
          color: graphColorList,
          isStack: true,
          isGroup: true,
          xField: 'month',
          yField: 'value',
          seriesField: 'type',
          groupField: 'name',
          renderer: 'svg',
          xAxis: {
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60
            },
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              rotate: -1
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
          },
          yAxis: {
            range: [1, 0, 1],
            title: {
              text: graphYAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style: any) {
                return {
                  ...style,
                  r: 4
                }
              },
            }
          },
          label: {
            position: 'middle',
            style: {
              fill: null
            },
            formatter: () => {
              return ''
            }
          },
          height: 240,
          autoFit: true,
          columnStyle(datum: any) {
            return {
              radius: [2, 2, 0, 0],
              fill: null
            };
          },
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data?.code) {
                onReady(args);
              }
            });
          },
        };
        ele = <Column {...configStackGroup} />;
        break;
      case DISPLAY_TYPE.BAR_CHART_WITH_STACK_COLUMN:
        ele = getColumnChartWithStack();
        break;
      case DISPLAY_TYPE.BAR_CHART_WITH_GROUP_COLUMN:
        const configGroup: any = {
          data: formattedResourceData,
          isGroup: true,
          xField: 'month',
          color: graphColorList,
          yField: 'value',
          seriesField: 'type',
          autoFit: true,
          renderer: 'svg',
          xAxis: {
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60
            },
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              rotate: -1
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          yAxis: {
            title: {
              text: graphYAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style: any) {
                return {
                  ...style,
                  r: 4
                }
              },
            }
          },
          label: {
            position: 'middle',
            style: {
              fill: null
            },
            formatter: () => {
              return ''
            }
          },
          height: 240,
          columnStyle(datum: any) {
            return {
              radius: [8, 8, 0, 0],
              fill: null
            };
          },
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data?.type) {
                onReady(args);
              }
            });
          },
        };
        ele = <Column {...configGroup} />;
        break;
      case DISPLAY_TYPE.BIDIRECTIONAL_BAR_GRAPH:
        const yFiled: [string, string] = ['OFFICE_HOURS', 'OUT_OF_OFFICE_HOURS'];
        const yAxisMaxValue = getYAxisMaxValueWidgetObject(formattedResourceData, sectionData.resourceCode)
        const bidirectionalBar: BidirectionalBarConfig = {
          data: formattedResourceData,
          layout: 'vertical',
          xField: 'monthDate',
          yField: yFiled,
          height: 240,
          color: graphColorList,
          renderer: 'svg',
          yAxis: {
            [yFiled[0]]: {
              nice: true,
              line: {
                style: {
                  stroke: '#F2F4F7',
                },
              },
              title: {
                text: graphYAxisTitle.title,
                description: graphYAxisTitle.description,
                style: {
                  fill: graphMetaDataColor.axisTextColor,
                }
              },
              grid: {
                alignTick: true,
                line: {
                  type: 'line',
                  style: {
                    stroke: '#F2F4F7'
                  }
                },
                closed: true
              },
            ...(yAxisMaxValue)
            },
            [yFiled[1]]: {
              ...(yAxisMaxValue),
              nice: true,
              line: {
                style: {
                  stroke: '#F2F4F7',
                },
              },
              grid: {
                alignTick: true,
                line: {
                  type: 'line',
                  style: {
                    stroke: '#F2F4F7'
                  }
                },
                closed: true
              }
            },
          },
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: string) {
                const labelName =
                text === OFFICE_HOURS_CONST.OFFICE_HOURS
                ? 'During Office Hours'
                : text === OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS
                ? 'After Hours'
                :'' ;
                return labelName.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style: any) {
                return {
                  ...style,
                  r: 4
                }
              },
            }
          },
          xAxis: {
            position: 'bottom',
            tickCount: 12,
            label: {
              offsetX: -20,
              rotate: -1,
              style: {
                fontSize: 10,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              formatter(text, item, index) {
                return getDateStrFromFormat(text, 'MMM YY')
              },
            },
          },
          barStyle(datum: any) {
            const radius = datum['series-field-key'] === OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS
              ? [0, 0, 4, 4]
              : [4, 4, 0, 0];
            return {
              radius: radius,
              fill: null
            };
          },
          tooltip: {
            shared: false,
            showMarkers: false,
            customContent: (title: string, data: any[]) => {
              const toolTipList = getToolTipList({
                resourceCode: sectionData.resourceCode,
                data: data,
                graphData: formattedResourceData,
                fromAction: 'TOOLTIP'
              })
              const titleText =
                sectionData.resourceCode ===
                ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT
                  ? getDateStrFromFormat(title, 'MMM YY')
                  : title;
              if (titleText && data?.length) {
                return <CustomTooltip title={titleText} data={toolTipList} />
              }
            },
          },
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data?.['series-field-key']) {
                onReady(args);
              }
            });
          },
        };
        ele = <BidirectionalBar {...bidirectionalBar} />;
        break;
      case DISPLAY_TYPE.DETAIL_WITH_BAR_CHART_WITH_STACK_COLUMN:
        const columnChart = getChartForDetailChartView()
        const mainEleContainer = (
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            {getDetailsViewForDetailChartView()}
            {columnChart}
          </div>);
        ele = mainEleContainer
        break;
    }
    return ele;
  };

  const getChartForDetailChartView = () => {
    const resourceCode = sectionData.resourceCode;
    let ele = <></>;
    switch (resourceCode) {
      case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
        ele = (
          <div style={{width: '58%', marginBottom: 20}}>
            {getColumnChartWithStack(280)}
          </div>
        );
        break;
      case ANALYTICS_RESOURCE.COMMUNICATION_STATS:
        const bidirectionalBar = getBidirectionalBarGraphConfig();
        ele = (
          <div
            style={{
              width: '60%',
              marginBottom: 33,
            }}
          >
            <BidirectionalBar {...bidirectionalBar} />
          </div>
        );
        break;
      default:
        ele = <></>;
    }
    return ele;
  }

  const getBidirectionalBarGraphConfig = (): BidirectionalBarConfig => {
    const yAxisMaxValue = getYAxisMaxValueWidgetObject(formattedResourceData, sectionData.resourceCode)
    return {
      data: formattedResourceData,
      layout: 'vertical',
      xField: 'monthDate',
      yField: ['OFFICE_HOURS', 'OUT_OF_OFFICE_HOURS'],
      height: 240,
      color: graphColorList,
      renderer: 'svg',
      yAxis: {
        'OFFICE_HOURS': {
          ...(yAxisMaxValue),
          nice: true,
          line: {
            style: {
              stroke: '#F2F4F7',
            },
          },
          grid: {
            alignTick: true,
            line: {
              type: 'line',
              style: {
                stroke: '#F2F4F7'
              }
            },
            closed: true
          }
        },
        'OUT_OF_OFFICE_HOURS': {
          ...(yAxisMaxValue),
          nice: true,
          line: {
            style: {
              stroke: '#F2F4F7',
            },
          },
          grid: {
            alignTick: true,
            line: {
              type: 'line',
              style: {
                stroke: '#F2F4F7'
              }
            },
            closed: true
          }
        },
      },
      legend: {
        itemSpacing: 3,
        position: 'top-right',
        layout: 'horizontal',
        itemName: {
          formatter(text: string) {
            const textString = text.replace(/_/g, ' ').charAt(0).toUpperCase() + text.replace(/_/g, ' ').slice(1).toLowerCase()
            return textString.toLocaleUpperCase();
          },
        },
        marker: {
          symbol: 'circle',
          style(style: any) {
            return {
              ...style,
              r: 4
            }
          },
        }
      },
      xAxis: {
        position: 'bottom',
        label: {
          style: {
            fontSize: 12,
            fontWeight: 400,
            fill: graphMetaDataColor.axisTextColor
          },
          formatter(text, item, index) {
            return getDateStrFromFormat(text, 'MMM YY')
          },
        },
      },
      barStyle(datum: any) {
        const radius = datum['series-field-key'] === OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS
          ? [0, 0, 4, 4]
          : [4, 4, 0, 0];
        return {
          radius: radius,
          fill: null
        };
      },
      tooltip: {
        shared: false,
        showMarkers: false,
        customContent: (title: string, data: any[]) => {
          const toolTipList = getToolTipList({
            resourceCode: sectionData.resourceCode,
            data: data,
            graphData: formattedResourceData,
            fromAction: 'TOOLTIP'
          })
          const titleText = sectionData.resourceCode === ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT ? getDateStrFromFormat(title, 'MMM YY') : title
          if (titleText && data?.length) {
            return <CustomTooltip title={titleText} data={toolTipList} />
          }
        },
      },
      onReady: (plot: any) => {
        plot.on('element:click', (args: any) => {
          if (args?.data?.data?.['series-field-key']) {
            onReady(args);
          }
        });
      },
    }

  }

  const getDetailsViewForDetailChartView = () => {
    const resourceCode = sectionData.resourceCode;
    let additionalData = stateData?.resourceData?.additionalData || {};
    if (getTenantForDemo() && sectionData?.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION) {
      const resourceData: any = getDemoDataForEmployerReportCurrentSection(sectionData);
      additionalData = resourceData?.additionalData;
    }
    let ele = <></>;
    switch (resourceCode) {
      case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
        ele = (
          <div style={{width: '38%'}}>
            <div style={{width: '100%'}}>
              <ModalActionSelect
                selectedValue={
                  stateData?.selectedFormId ||
                  sectionData?.additionalAttributes?.formId
                }
                placeholder="Select Form"
                onValueChange={(value: string) => {
                  if (value) {
                    updateMemberSatisFactionForm(value);
                  }
                }}
                data={memberSatisfactionFormList}
                selectItemProps={{
                  key: 'id',
                  label: 'name',
                  value: 'id',
                }}
                customStyle={{flex: 1, width: '100%'}}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                marginTop: 8,
              }}
            >
              <div
                style={{
                  padding: 16,
                  borderWidth: 1,
                  borderColor: Colors.Custom.Gray200,
                  borderStyle: 'solid',
                  borderRadius: 8,
                  marginBottom: 8,
                  width: '100%',
                }}
              >
                <div
                  style={{
                    color: Colors.FoldPixel.GRAY300,
                    ...SM_CONFIG.smRegular,
                    fontWeight: 600,
                  }}
                >
                  Average Score
                </div>
                {additionalData?.formResponseDetails?.totalFormSendCount &&
                  additionalData?.formResponseDetails?.submittedFormLogCount ? (
                  <div
                    style={{
                      color: Colors.FoldPixel.GRAY400,
                      ...LG_CONFIG.lgNormal,
                      fontWeight: 600,
                    }}
                  >
                    {additionalData?.averageFormScore?.avgScore} from{' '}
                    {additionalData?.formResponseDetails?.submittedFormLogCount}{' '}
                    responses
                  </div>
                ) : (
                  <div
                    style={{
                      color: Colors.FoldPixel.GRAY250,
                      ...XS_CONFIG.xsNormal
                    }}
                  >
                    No Data
                  </div>
                )}
              </div>
              <div
                style={{
                  padding: 16,
                  borderWidth: 1,
                  borderColor: Colors.Custom.Gray200,
                  borderStyle: 'solid',
                  borderRadius: 8,
                  width: '100%',
                  marginBottom: 8,
                }}
              >
                <div
                  style={{
                    marginRight: 8,
                    marginBottom: 8,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      color: Colors.FoldPixel.GRAY300,
                      ...SM_CONFIG.smRegular,
                      fontWeight: 600,
                    }}
                  >
                    Total form sent
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {additionalData?.formResponseDetails?.totalFormSendCount >
                      0 ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          onMemberSatisfactionCustomActionClick(
                            MEMBER_SATISFACTION_ACTION_CODE.TOTAL_FORM_SENT,
                            {count: additionalData?.formResponseDetails
                              ?.totalFormSendCount,
                            type: 'totalformsent'}
                          );
                        }}
                      >
                        <div
                          style={{
                            color: Colors.FoldPixel.GRAY400,
                            ...LG_CONFIG.lgNormal,
                            fontWeight: 600,
                          }}
                        >
                          {
                            additionalData?.formResponseDetails
                              ?.totalFormSendCount
                          }
                        </div>
                        <div style={{width: 16, height: 16, display: 'flex'}}>
                          <ArrowRightSvg customStrokeColor="black"></ArrowRightSvg>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          color: Colors.FoldPixel.GRAY250,
                          ...XS_CONFIG.xsNormal
                        }}
                      >
                        No Data
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: Colors.Custom.Gray200,
                  }}
                ></div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: 8,
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      marginRight: 8,
                    }}
                    className="score-detail-item"
                  >
                    <div
                      style={{
                        color: Colors.FoldPixel.GRAY300,
                        ...SM_CONFIG.smRegular,
                        fontWeight: 600,
                      }}
                    >
                      Responded
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {additionalData?.formResponseDetails
                        ?.submittedFormLogCount > 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            onMemberSatisfactionCustomActionClick(
                              MEMBER_SATISFACTION_ACTION_CODE.RESPONDED,
                              {count: additionalData?.formResponseDetails
                                ?.submittedFormLogCount,
                                type: 'responded'}
                            );
                          }}
                        >
                          <div
                            style={{
                              color: Colors.FoldPixel.GRAY400,
                              ...LG_CONFIG.lgNormal,
                              fontWeight: 600,
                            }}
                          >
                            {
                              additionalData?.formResponseDetails
                                ?.submittedFormLogCount
                            }
                          </div>
                          <div style={{width: 16, height: 16, display: 'flex'}}>
                            <ArrowRightSvg customStrokeColor="black"></ArrowRightSvg>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            color: Colors.FoldPixel.GRAY250,
                            ...XS_CONFIG.xsNormal
                          }}
                        >
                          No Data
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      marginRight: 8,
                    }}
                    className="score-detail-item"
                  >
                    <div
                      style={{
                        color: Colors.FoldPixel.GRAY300,
                        ...SM_CONFIG.smRegular,
                        fontWeight: 600,
                      }}
                    >
                      Not Responded
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {additionalData?.formResponseDetails?.notResponded > 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            onMemberSatisfactionCustomActionClick(
                              MEMBER_SATISFACTION_ACTION_CODE.NOT_RESPONDED,
                              {count: additionalData?.formResponseDetails
                                ?.notResponded,
                                type: 'notresponded'}
                            );
                          }}
                        >
                          <div
                            style={{
                              color: '#000',
                              fontFamily: 'Manrope',
                              fontSize: 15,
                              fontWeight: 600,
                            }}
                          >
                            {additionalData?.formResponseDetails?.notResponded}
                          </div>
                          <div style={{width: 16, height: 16, display: 'flex'}}>
                            <ArrowRightSvg customStrokeColor="black"></ArrowRightSvg>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            color: Colors.FoldPixel.GRAY250,
                            ...XS_CONFIG.xsNormal
                          }}
                        >
                          No Data
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case ANALYTICS_RESOURCE.COMMUNICATION_STATS:
        ele = (
          <CommunicationStatDetails
            memberCount={stateData.additionalData?.memberCount}
            recordCount={stateData.additionalData?.recordCount}
            totalSavings={stateData.additionalData?.totalSavings}
          />
        );
    }
    return ele;
  }


  const onMemberSatisfactionCustomActionClick = (actionCode: string, data?: any) => {
    let filterData = [] as any[];
    const dashBoardDateRange = {
      fromDate: moment(props.dateRange?.uiFromDate).format(
        `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
      ),
      toDate: moment(props.dateRange?.uiToDate).format(
        `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
      ),
    };
    switch (actionCode) {
      case MEMBER_SATISFACTION_ACTION_CODE.TOTAL_FORM_SENT: {
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: dashBoardDateRange,
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_STATUS_CODE,
            filterValue: {
              value: []
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_ID,
            filterValue: {
              value: stateData?.selectedFormId
            }
          }
        ];
        break;
      }
      case MEMBER_SATISFACTION_ACTION_CODE.RESPONDED:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: dashBoardDateRange,
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_STATUS_CODE,
            filterValue: {
              value: [FORM_STATUS_CODE.SUBMITTED]
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_ID,
            filterValue: {
              value: stateData?.selectedFormId
            }
          }
        ];
        break;
      case MEMBER_SATISFACTION_ACTION_CODE.NOT_RESPONDED:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: dashBoardDateRange,
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_STATUS_CODE,
            filterValue: {
              value: [FORM_STATUS_CODE.DRAFT, FORM_STATUS_CODE.PENDING]
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_ID,
            filterValue: {
              value: stateData?.selectedFormId
            }
          }
        ];
        break;
    }
    props?.onActionClick?.(sectionData.resourceCode || '', {filterData, ...data});
  }

  const onReady = (data: any) => {
    let filterData = {} as any;
    let memberType = '';
    const dateString = data?.data?.data?.date || data?.data?.data?.monthDate
    const fromDate = getFormattedDateStringInTimeZone(
      dateString,
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.START_OF
    );
    const toDate = getFormattedDateStringInTimeZone(
      dateString,
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.END_OF
    );
    const type: string = data?.data?.data?.type;
    const dataType = capitalizeText(type);
    const dateOrMonth = data?.data?.data?.date || data?.data?.data?.month || data?.data?.data?.monthDate
    switch (sectionData.resourceCode) {
      case ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT:
        const count = data?.data?.data[data?.data?.data['series-field-key']]
        const hoursType = data?.data?.data['series-field-key']
        const selectedGraphData = getToolTipList({
          resourceCode: sectionData.resourceCode,
          data: data?.data?.data,
          graphData: formattedResourceData,
          fromAction: 'DRILLDOWN'
        })
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.OUT_OF_OFFICE_TYPE_CODE,
            filterValue: selectedGraphData?.[0]?.code,
          },
        ];
        // TO_DO. drill down here
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: count, type: hoursType, date: dateOrMonth, selectedGraphData: selectedGraphData});
        break
      case ANALYTICS_RESOURCE.CLINICAL_INTERVENTION_MESSAGE_NOTES:
      case ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS:
      case ANALYTICS_RESOURCE.BILLING_CPT_CODES:
      case ANALYTICS_RESOURCE.BILLING_CPT_CODES_AVOIDED_ED:
        let typeList: string[] = [];
        memberType = getFormattedMemberType(type?.toLocaleLowerCase());
        if (memberType === 'employee') {
          typeList = ['employee'];
        } else if (memberType === 'dependent') {
          typeList = ['father', 'spouse', 'child'];
        } else if (memberType === 'selfsponsored') {
          typeList = ['self-sponsored'];
        } else if (memberType === 'oneoff') {
          typeList = ['one-off'];
        } else {
          typeList = [type]
        }
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: 'EMPLOYEE_TYPE',
            filterValue: typeList,
            count: data?.data?.data?.value
          },
          {
            filterCode: 'DASHBOARD_DATE_RANGE',
            filterValue: dateRange
          },
          ((stateData.selectedLocation && stateData.selectedLocation !== 'ALL') ? {
            filterCode: "LOCATION",
            filterValue: [stateData.selectedLocation]
          } : {} as any),
        ];
        if (sectionData.resourceCode === ANALYTICS_RESOURCE.BILLING_CPT_CODES
          || sectionData.resourceCode === ANALYTICS_RESOURCE.BILLING_CPT_CODES_AVOIDED_ED) {
          let appliedFilterList = [];
          if (sectionData?.appliedFilter) {
            appliedFilterList = getEmployerReportFilterList(sectionData?.appliedFilter);
            if (appliedFilterList?.length && filterData?.length) {
              filterData = [...filterData, ...appliedFilterList];
            } else if (appliedFilterList?.length) {
              filterData = [...appliedFilterList];
            }
          }
        }
        filterData = [...filterData, ...stateData.selectedAppliedFilter]
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.ENCOUNTER_TYPES:
        let modeType: string[] = [];
        if (type?.toLocaleLowerCase() === 'video') {
          modeType = [APPOINTMENT_MODE_CODE.VIDEO]
        } else {
          modeType = [APPOINTMENT_MODE_CODE.IN_PERSON]
        }
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.APPOINTMENT_MODE,
            filterValue: modeType,
            count: data?.data?.data?.value
          },
          {
            filterCode: 'DASHBOARD_DATE_RANGE',
            filterValue: dateRange
          },
          ((stateData.selectedLocation && stateData.selectedLocation !== 'ALL') ? {
            filterCode: "LOCATION",
            filterValue: [stateData.selectedLocation]
          } : {} as any)
        ];
        filterData = [...filterData, ...stateData.selectedAppliedFilter]
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING:
      case ANALYTICS_RESOURCE.SMS_MESSAGING:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.MSG_SENDER_TYPE,
            filterValue: data?.data?.data?.type,
            count: data?.data?.data?.value
          },
        ];
        // TO_DO. drill down here
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.APPOINTMENT_BOOKED:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.APPOINTMENT_STATUS,
            filterValue: data?.data?.data?.type,
            count: data?.data?.data?.value
          },
        ];
        // TO_DO. drill down here
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.VIDEO_CALLING:
        let videoCallingTypeList: string[] = [];
        memberType = getFormattedMemberType(type?.toLocaleLowerCase());
        if (memberType === 'employee') {
          videoCallingTypeList = ['employee'];
        } else if (memberType === 'dependent') {
          videoCallingTypeList = ['father', 'spouse', 'child'];
        } else if (memberType === 'selfsponsored') {
          videoCallingTypeList = ['self-sponsored'];
        } else if (memberType === 'oneoff') {
          videoCallingTypeList = ['one-off'];
        } else {
          videoCallingTypeList = [type];
        }
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.EMPLOYEE_TYPE,
            filterValue: videoCallingTypeList,
            count: data?.data?.data?.value
          },
          {
            filterCode: 'DASHBOARD_DATE_RANGE',
            filterValue: dateRange
          },
        ];
        // TO_DO. drill down here
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.CALL_TYPE,
            filterValue: {
              value: data?.data?.data?.filterCode,
            },
            count: data?.data?.data?.value
          },
        ];
        // TO_DO. drill down here
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.OUT_OF_OFFICE_TYPE_CODE,
            filterValue: data?.data?.data?.code,
          },
        ];
        // TO_DO. drill down here
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED:
        const selfType = data?.data?.data?.type;
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              type: selfType,
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value
          },
          {
            filterCode: 'DASHBOARD_DATE_RANGE',
            filterValue: dateRange
          },
          ((stateData.selectedLocation && stateData.selectedLocation !== 'ALL') ? {
            filterCode: "LOCATION",
            filterValue: [stateData.selectedLocation]
          } : {} as any)
        ];
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN:
      case ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED:
      case ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH:
      case ANALYTICS_RESOURCE.AUTOMATION_TIME_SAVED:
        let loggedInList: string[] = [];
        if (type?.toLocaleLowerCase() === 'employee') {
          loggedInList = ['employee']
        } else if (type?.toLocaleLowerCase() === 'dependent') {
          loggedInList = ['father', 'spouse', 'child']
        } else if (type?.toLocaleLowerCase() === 'one off') {
          loggedInList = ['one off']
        } else if (type?.toLocaleLowerCase() === 'self-sponsored') {
          loggedInList = ['self-sponsored']
        }

        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: 'DASHBOARD_DATE_RANGE',
            filterValue: dateRange
          },
          {
            filterCode: 'EMPLOYEE_TYPE',
            filterValue: loggedInList,
            count: data?.data?.data?.value
          },
        ];
        filterData = [...filterData, ...stateData.selectedAppliedFilter]
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_STATUS_CODE,
            filterValue: {
              value: [data?.data?.data?.filterCode]
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_ID,
            filterValue: {
              value: stateData?.selectedFormId
            }
          }
        ];
        filterData = [...filterData, ...stateData.selectedAppliedFilter]
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.COMMUNICATION_STATS:
        const statsCount =
          data?.data?.data[data?.data?.data['series-field-key']];
        const statsHoursType = data?.data?.data['series-field-key'];
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value || data?.data?.data?.xField,
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.OUT_OF_OFFICE_TYPE_CODE,
            filterValue: statsHoursType,
          },
        ];
        filterData = [...filterData, ...stateData.selectedAppliedFilter];
        props?.onActionClick?.(sectionData.resourceCode || '', {
          filterData,
          count: statsCount,
          type: dataType,
          date: dateOrMonth,
        });
        break
      default:
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value || data?.data?.data?.xField
          },
        ];
        filterData = [...filterData, ...stateData.selectedAppliedFilter]
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value || data?.data?.data?.xField, type: dataType, date: dateOrMonth});
        break;
    }
  };
  const getTopViewWithData = () => {
    if (topViewData.type1?.count || topViewData.type2?.count) {
      return (
        <div
          style={{
            borderWidth: 1,
            borderBottomColor: '#EDEDED',
            borderBottomStyle: 'solid',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '20px 26px',
          }}
        >
          <div style={{marginRight: 20}}>
            <div
              style={{
                color: '#000',
                fontSize: 20,
                fontWeight: 600,
                fontFamily: 'Manrope-Regular',
                marginBottom: 2,
              }}
            >
              {topViewData.type1?.name}
            </div>
            <div
              style={{
                color: '#000',
                fontSize: 20,
                fontWeight: 400,
                fontFamily: 'Manrope-Regular',
              }}
            >
              {topViewData.type1?.count}
            </div>
          </div>
          <div>
            <div
              style={{
                color: '#000',
                fontSize: 20,
                fontWeight: 600,
                fontFamily: 'Manrope-Regular',
                marginBottom: 2,
              }}
            >
              {topViewData.type2?.name}
            </div>
            <div
              style={{
                color: '#000',
                fontSize: 20,
                fontWeight: 400,
                fontFamily: 'Manrope-Regular',
              }}
            >
              {topViewData.type2?.count}
            </div>
          </div>
        </div>
      );
    }
    return (
      <></>
    );
  }
  const barChartView = useMemo(() => {
    return getBarChartViewByType()
  }, [stateData.loading])
  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let formId = '';

    let filterList: any = appliedFilter?.length ? appliedFilter : stateData.appliedFilter;
    let appliedFilterList = [
    ];
    if (!filterList?.length) {
      filterList = [];
    }
    if (sectionData?.appliedFilter) {
      appliedFilterList = getEmployerReportFilterList(sectionData?.appliedFilter);
      if (appliedFilterList?.length && filterList?.length) {
        filterList = [
          ...filterList,
          ...appliedFilterList
        ];
      } else if (appliedFilterList?.length) {
        filterList = [...appliedFilterList];
      }
    }
    if (sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION && sectionData?.additionalAttributes?.formId) {
      formId = stateData?.selectedFormId || sectionData?.additionalAttributes?.formId;
      filterList = [
        {
          filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
          filterValue: stateData?.appliedFilter?.[0]?.filterValue,
        },
        {
          filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_ID,
          filterValue: {
            value: formId
          }
        }
      ];

    }
    if (hintMembershipDisabled) {
      filterList.push({
        filterCode: EMPLOYER_REPORT_FILTER_CODES.MEMBERSHIP,
        filterValue: hintMembershipDisabled
      })
    }
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      formId,
      isAccountDashboard,
      memberType,
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && isWidgetDataEmpty(response?.data)) {
      const containerHight = containerRef?.current?.offsetHeight;
      const titleWrapperHight = titleRef?.current?.offsetHeight;
      const heightVariable = (props.sectionData?.subHeading?.length || 0) > 100 ? 20 : 10
      const graphWrapper = containerHight - titleWrapperHight - heightVariable - 50
      let resourceData = response.data?.totalCount ? response.data : response.data?.result || response.data;
      if (sectionData?.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION) {
        resourceData = response.data;
      }
      const additionalData = response?.data?.additionalData
      onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
          graphWrapperHeight: graphWrapper,
          additionalData,
        };
      });
    } else {
      onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true,
          selectedFormId: sectionData?.additionalAttributes?.formId
        }
      })
      let additionalData = {}
      let resourceData = getTenantForDemo()
        ? isEmployeeDependents
          ? EMPLOYEE_VS_DEPENDANT_VISITS
          : isEncounterTypes
            ? ENCOUNTER_TYPES
            : isPatientAppChats
              ? PATIENT_APP_MESSAGING_DATA
              : isVoiceCallByType
                ? VOICE_CALLING_DIRECTION_WISE
                : isSmsChats
                  ? SMS_MESSAGING_DATA
                  : isEmployeeVsDependentUtilization
                    ? ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT_DATA.result
                    : isSelfScheduled
                      ? APPOINTMENT_SELF_SCHEDULED
                      : isTimeSaved
                        ? AUTOMATION_TIME_SAVED
                        : isLoggedIn
                          ? LOGGED_IN_COUNT
                          : isEncounterNotes
                            ? TEMP_CLINICAL_INTERVENTION_MESSAGE_NOTES
                            : isMembershipGrowth
                              ? MEMBERSHIP_NET_GROWTH
                              : isMembershipInactivated
                                ? MEMBERSHIP_INACTIVATED
                                : isOutOfficeHourUserEngagement
                                  ? OUT_OFFICE_HOUR_USER_ENGAGEMENT_DATA.result
                                  : isMemberSatisfactionSurvey
                                    ? VOICE_CALLING_DIRECTION_WISE
                                    : isAppointmentBooked
                                      ? APPOINTMENT_BOOKED
                                      : isVideoCalling
                                        ? VIDEO_CALLING
                                        : []
        : [];
      if (sectionData?.additionalAttributes && Object.keys(sectionData?.additionalAttributes || {})?.length) {
        const sectionAdditionalAttributes = sectionData?.additionalAttributes;
        const sectionDataCode = sectionAdditionalAttributes?.code;
        switch (sectionDataCode) {
          case 'AVOIDED_SPECIALIST_VISIT':
            resourceData = AVOIDED_SPECIALIST_VISIT;
            break;
          case 'AVOIDED_WORKDAY_ABSENCE':
            resourceData = AVOIDED_WORKDAY_ABSENCE;
            break;
          case 'AVOIDED_WC':
            resourceData = AVOIDED_WC;
            break;
          case 'AVOIDED_ED':
            resourceData = AVOIDED_ED;
            break;
        }
      } else if (sectionData?.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION) {
        resourceData = getDemoDataForEmployerReportCurrentSection(sectionData) as any;
      }
      if (sectionData.resourceCode === ANALYTICS_RESOURCE.COMMUNICATION_STATS) {
        const data = getDemoDataForEmployerReportCurrentSection(
          sectionData
        ) as any;
        resourceData = data?.result;
        additionalData = data.additionalData;
      }
      setTimeout(() => {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: resourceData,
            loading: false,
            additionalData: additionalData
          }
        })
      }, 3000)
      return
    }
    if (stateData.selectedLocation && stateData.selectedLocation !== 'ALL') {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedLocationFilter: any = {
        filterCode: 'LOCATION' ,
        filterValue: (stateData.selectedLocation && stateData.selectedLocation !== 'ALL') ?  [stateData.selectedLocation] : []
      }
      appliedFilter.push(selectedLocationFilter);
      getSectionByResourceCode(appliedFilter);
    } else {
      getSectionByResourceCode();
    }
    return () => controller?.abort();
  }, [sectionData.id, stateData.selectedLocation]);


  useEffect(() => {
    if (!getTenantForDemo() && stateData?.selectedFormId) {
      getSectionByResourceCode();
    }
    return () => controller?.abort();
  }, [stateData?.selectedFormId])
  useEffect(() => {
    if (sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION && memberSatisfactionFormList?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          selectedFormId: sectionData?.additionalAttributes?.formId || memberSatisfactionFormList[0]?.id
        };
      });
    }
  }, []);

  const getRefreshButtonOnLocalEnv = () => {
    if (window.location.host.includes('localhost')) {
      return (
        <Pressable
          style={{marginHorizontal: 3}}
          onPress={() => {
            setStateData(prev => {
              return {
                ...prev,
                filterValueName: [],
                filterValueString: ''
              }
            })
            getSectionByResourceCode();
          }}
        >
          <Tooltip label="Refresh">
            <RestSvgIcons />
          </Tooltip>
        </Pressable>
      )
    }
    return <></>;
  }
  return (
    <div ref={containerRef} className="widgetBoxShadow">
      <div ref={titleRef}>
        <WidgetHeader isPrintPreview={props.isPrintPreview} isPrintDownload={props.isPrintDownload} refreshButtonOnLocalEnv={getRefreshButtonOnLocalEnv()} sectionData={sectionData} onActionClick={onActionClick} />
      </div>
      {stateData.loading ? (
        <Skeleton style={{padding: 40}} active={true} />
      ) : (
        <>
         {stateData.resourceData && stateData.resourceData?.length
            || (sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: '75%',
                position: 'relative'
              }}
            >
              <LocationFilterDropdown
                  accountPracticeLocations={props.accountPracticeLocations}
                  selectedLocation={stateData.selectedLocation}
                  resourceCode={sectionData.resourceCode}
                  onSelect={(value: any) => {
                        setStateData(prev=> {
                          return {
                            ...prev,
                            selectedLocation: value
                          }
                        })
                    }}
                />
              <main style={{alignSelf: 'center', width: '100%', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'flex-start'}}>
                <main
                  style={{padding: '10px 0px', width: '88%'}}
                  className={stateData.resourceData.length && props.isPrintPreview ? 'canvas-wrapper' : ''}
                >
                  {barChartView}
                </main>
              </main>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '60%',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <LocationFilterDropdown
                  accountPracticeLocations={props.accountPracticeLocations}
                  selectedLocation={stateData.selectedLocation}
                  resourceCode={sectionData.resourceCode}
                  top={-10}
                  onSelect={(value: any) => {
                        setStateData(prev=> {
                          return {
                            ...prev,
                            selectedLocation: value
                          }
                        })
                    }}
                />
              <NodataViewTypeOne viewCode={'TEXT_ICON'} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(WidgetBarChartView);
