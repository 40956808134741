import React from "react";
import "./ProgressBar.css";
import ProgressArrowSvg from "../../../common/Svg/MeasurePerformanceSvg/ProgressArrowSvg";
import {getProgressBarColor} from "./TablesColumnsView";
import {getLeadingOffTrackValue, isCriteriaExceed, isCriteriaMet} from "./utils";
import {useIntl} from "react-intl";
import ProgressArrowInverseSvg from "../../../common/Svg/MeasurePerformanceSvg/ProgressArrowInverseSvg";
import {Colors} from "../../../../styles";
import {IProgressBar} from "./interface";

const ProgressBar = (props: IProgressBar) => {
  const { percentage, goal, inInverse, showProgressInfo } = props;
  const intl = useIntl();
  return (
    <div style={{
      width: '100%'
    }} className="progress-container">
      <div className="progress-bar">
        <div
          className="progress-fill"
          style={{
            width: `${percentage}%`,
            backgroundColor: getProgressBarColor(
              percentage || 0, goal || 0, inInverse
            )
          }}
        ></div>

        <div
          className="progress-arrow-wrapper"
          style={{
            left: inInverse ? `calc(${goal}%)` : `calc(${goal}% - 12px)`
          }}
        >
          <div className="progress-arrow">
            {inInverse ? <ProgressArrowInverseSvg met={isCriteriaExceed(percentage || 0, goal || 0, inInverse)}/> : <ProgressArrowSvg met={isCriteriaExceed(percentage || 0, goal || 0, inInverse)}/>}
          </div>
        </div>
      </div>
      {showProgressInfo && <div className="progress-info">
        <span className="leading-text">
          {intl.formatMessage({id: isCriteriaMet(percentage || 0, goal || 0, inInverse) ? (inInverse ? 'underControlBy' : 'leadingBy') : (inInverse ? 'outOfControlBy' : 'offTrackBy')})}
        </span>
        <span style={{
          color: isCriteriaMet(percentage || 0, goal || 0, inInverse) ? Colors.FoldPixel.STATUS_DARK_SUCCESS : Colors.FoldPixel.STATUS_ERROR,
        }} className="leading-percentage">{`${getLeadingOffTrackValue(percentage || 0, goal || 0, inInverse)}`}%</span>
      </div>}
    </div>
  );
};

export default ProgressBar;