import React from 'react';
import {IPowerBiAccountReports} from '../../../../../../services/PowerBI/interface';
import styles from './WorkspaceList.module.css';

interface IProps {
  powerBiAccountReports: IPowerBiAccountReports[];
  selectedPowerBiAccountReportId: string;
  onSelectWorkspace: (id: string) => void;
}

const WorkspaceList = ({
  onSelectWorkspace,
  powerBiAccountReports,
  selectedPowerBiAccountReportId,
}: IProps) => {
  return (
    <div className={styles.container}>
      {powerBiAccountReports?.map((item) => {
        const isSelected = selectedPowerBiAccountReportId === item.id;
        return (
          <div
            className={`${styles.textContainer} ${
              isSelected ? styles.selectedTextContainer : ''
            } pressable`}
            onClick={(e) => {
              e.stopPropagation();
              onSelectWorkspace(item.id);
            }}
          >
            <div
              className={`${styles.chip} ${
                isSelected ? styles.chipSelected : ''
              }`}
            />

            <h1
              className={`${styles.text} ${
                isSelected ? styles.selectedText : ''
              }`}
            >
              {item.name || ''}
            </h1>
          </div>
        );
      })}
    </div>
  );
};

export default WorkspaceList;
