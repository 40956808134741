import React from 'react';
import { Line } from '@ant-design/charts';
import { View } from 'native-base';
import { getDateStrFromFormat } from '../../../utils/DateUtils';
import { DATE_FORMATS } from '../../../constants';

interface LogData {
  logDate: string;
  failedCount: number;
  successCount: number;
}

const CommunicationLogLineChart = (props: { data: LogData[] }) => {
  const { data } = props;
  const processedData = data ? data.flatMap(({ logDate, failedCount, successCount }) => [
    { logDate, value: failedCount, type: 'Failed Count' },
    { logDate, value: successCount, type: 'Success Count' }
  ]) : [];

  const config = {
    data: processedData,
    xField: 'logDate',
    yField: 'value',
    seriesField: 'type', // Differentiate the lines by 'type'
    smooth: true, // Make the line smooth
    height: 320, // Adjust the overall height of the chart
    padding: [40, 40, 70, 70],
    xAxis: {
      title: {
        text: 'Months',
        style: {
          fontSize: 14,
        },
      },
      label: {
        formatter: (text: any) => getDateStrFromFormat(
          text,
          DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
        ),
        style: {
          fontSize: 12,
          fill: '#aaa',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Error vs. Success',
        style: {
          fontSize: 14,
        },
      },
      label: {
        formatter: (v: any) => `${v}`, // Format the y-axis labels
        style: {
          fontSize: 12,
          fill: '#aaa',
        },
      },
    },
    point: {
      size: 5,
      shape: 'diamond',
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
        //   fill: 'red',
        },
      },
    },
    interactions: [{ type: 'marker-active' }],
  };

  return (
    <View flex={1} padding={4}>
      <Line {...config} />
    </View>
  );
};

export default CommunicationLogLineChart;
