import React from 'react';
import {
  HStack,
  Pressable,
  Skeleton,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {Colors} from '../../../../../styles/Colors';
import {getColumnValueByDataType} from '../EmployerUtils/DrillDownMemberListUtils';
import CommonColumnRenderer from './DrillDownColumnRenderer/CommonColumnRenderer';
import {IContactDrillDownDataList} from '../interface';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import SingleContactColumnView from './DrillDownColumnRenderer/CommonColumnRenderer/SingleContactColumnView';
import {IContactSavingsMap} from './interfaces';
import { ANALYTICS_RESOURCE, APPOINTMENT_WIDGET_RESOURCES } from '../../../../../constants';
export const getDrillDownColumnForFirstView = (data: {
  contactDrillDownData: IContactDrillDownDataList;
  onViewDetailsClick: (selectedContact: any, isViewDetails: boolean) => void;
  onColumnActionPerformed: (actionCode: string, actionData: any) => void;
  filterData?: any;
}, resourceCode?: string) => {
  const {
    contactDrillDownData,
    onColumnActionPerformed,
    onViewDetailsClick,
    filterData,
  } = data;
  const {columnList, isOnFirstView, contactDrillDownRowDataList} =
    contactDrillDownData;
  const isAppointmentWidget: boolean =
    resourceCode && APPOINTMENT_WIDGET_RESOURCES.includes(resourceCode)
      ? true
      : false;
  if (isOnFirstView) {
    const finalColumnList: any = columnList?.map((item: any) => {
      const columnItem = {
        title: (
          <Text size={'smMedium'} color={Colors.FoldPixel.GRAY300}>
            {item.columnName}
          </Text>
        ),
        dataIndex: item.columnKey,
        key: item.columnKey,
        ...(item.columnKey === 'contact'
          ? {
              width: '30%',
            }
          : {}),
        render(value: any, record: any, index: number) {
          const cellClassNames = `employer-drill-down-cell ${item?.columnKey}`;
          return (
            <div className={cellClassNames}>
              {
                <CommonColumnRenderer
                  columnData={value}
                  rowRecordData={record}
                  columnConfig={{
                    columnKey: item?.columnKey,
                    columnName: item?.columnName,
                  }}
                  contactDrillDownData={contactDrillDownRowDataList}
                  isOnFirstView={isOnFirstView || false}
                  filterData={filterData}
                  onDetailsActionPerformed={onViewDetailsClick}
                  onColumnActionPerformed={onColumnActionPerformed}
                />
              }
            </div>
          );
        },
      };
      return columnItem;
    });
    return finalColumnList;
  } else {
    const drillDownColumnList = contactDrillDownData?.columnList?.length
      ? contactDrillDownData?.columnList
      : [];
    const previousColumnList = contactDrillDownData?.previousColumnList?.length
      ? contactDrillDownData?.previousColumnList
      : [];
    const showViewDetailsBtn: boolean = !contactDrillDownData?.isOnFirstView &&
    drillDownColumnList?.length > 0 &&
    previousColumnList?.length ? true : false
    const drillColumnArr = [
      {
        title: (
          <DisplayText
            size={'smMedium'}
            extraStyles={{color: Colors.FoldPixel.GRAY300}}
            textLocalId="members"
          />
        ),
        dataIndex: 'contact',
        key: 'contact',
        width: isAppointmentWidget
          ? '30%'
          : showViewDetailsBtn
          ? '80%'
          : '100%',
        render: (value: any, record: any, index: any) => {
          return (
            <SingleContactColumnView
              contactData={value}
              contactDrillDownData={contactDrillDownData}
              onViewDetailsClick={onViewDetailsClick}
            />
          );
        },
      },
    ];
    let totalCountObj = {} as any;
    let viewDetailsObj = {} as any;
    if (isAppointmentWidget) {
      totalCountObj = {
        title: (
          <DisplayText
            size={'smMedium'}
            extraStyles={{
              color: Colors.FoldPixel.GRAY300,
            }}
            textLocalId="totalAppointmentCount"
          />
        ),
        dataIndex: 'contact',
        key: 'contact',
        width: '20%',
        align: 'center',
        render: (value: any, record: any, index: any) => {
          const count = contactDrillDownData?.contactDrillDownDataList?.find(
            (item) => {
              return item?.contactUuid === value?.uuid;
            }
          );
          return (
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{count?.contactRowData?.length}</Text>
          );
        },
      };
    }
    if (showViewDetailsBtn) {
      viewDetailsObj = {
        title: <></>,
        dataIndex: 'contact',
        key: 'contact',
        align: 'right',
        width: isAppointmentWidget ? '50%' : showViewDetailsBtn ? '20%' : '0%',
        render: (value: any, record: any, index: any) => {
          return showViewDetailsBtn ? (
            <Pressable
              style={{paddingHorizontal: 3, paddingVertical: 4}}
              onPress={() => {
                if (onViewDetailsClick) {
                  onViewDetailsClick(value, true);
                }
              }}
            >
              <Text size="smBold" color={Colors.Custom.mainPrimaryPurple}>
                View details
              </Text>
            </Pressable>
          ) : (
            <></>
          );
        },
      };
    }
    if (Object.keys(totalCountObj).length !== 0) {
      drillColumnArr.push(totalCountObj);
    }
    if (Object.keys(viewDetailsObj).length !== 0) {
      drillColumnArr.push(viewDetailsObj);
    }
    return drillColumnArr;
  }
};
export const getDrillDownDataForFirstView = (contactRowData: any[]) => {
  const finalContactRowData = contactRowData?.map((singleContactData: any) => {
    const obj: any = {};
    singleContactData.forEach((item: any) => {
      obj[item.columnKey] = item.value;
    });
    return obj;
  });
  return finalContactRowData;
};
