import { IModalActionDialog } from './interface';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';
import { Colors } from '../../../styles/Colors';
import {HStack, Text, View, VStack, Input, Button} from 'native-base';
import Stack from '../LayoutComponents/Stack';
import InputTextWithCharacterLimit from '../InputTextWithCharacterLimit/InputTextWithCharacterLimit';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../constants';
import { useState } from 'react';
import { modalActionDialogStyles } from './styles';

export const ModalActionDialog = (props: IModalActionDialog) => {
  const [text, setText] = useState<string>('');
  const rightButtonColor = !props?.isAlert ? Colors.Custom.Primary300 : Colors.Custom.Red100;
  const rightHoverColor = !props?.isAlert ? Colors.Custom.onHoverPrimaryButtonColor : Colors.FoldPixel.ALERT_HOVER_COLOR;
  const rightTextColor = !props?.isAlert ? Colors.Custom.ContainerBGColor : Colors.FoldPixel.STATUS_ERROR
  return (
    <Modal
      afterClose={() => {props?.onClose?.();}}
      destroyOnClose={true}
      open={props?.open || false}
      closable={!!props?.includeCloseIcon}
      width={400}
      style={modalActionDialogStyles.modalStyle}
      centered
      onCancel={() => {props?.onClose?.();}}
      footer={null}
    >
      <Stack
        direction='column'
        space={16}
        style={{
          borderRadius: 12,
          alignItems: 'center',
        }}
      >
        <Stack
          direction='column'
          space={8}
          style={modalActionDialogStyles.stackStyle}
        >
          {props?.icon}
          <Stack
            direction='column'
            style={modalActionDialogStyles.stackStyle}
            space={2}
          >
            <Text
              size={'mdMedium'}
              color={Colors.FoldPixel.GRAY400}
              lineHeight={19.2}
              textAlign={'center'}
            >{props?.title}</Text>

            <Text
              size={'smRegular'}
              color={Colors.FoldPixel.GRAY250}
              lineHeight={16.8}
              textAlign={'center'}
            >{props?.subTitle}</Text>
          </Stack>
        </Stack>
        <View width={360}>

        <InputTextWithCharacterLimit
          placeHolder={props?.textPlaceHolder}
          onChangeText={(text: string) => {setText(text);}}
          characterLimit={500}
          numberOfLines={4}
          lineHeight={2}
          value={''}
        />
        </View>

        <Stack
          direction='row'
          space={8}
          style={{
            width: '320px',
            alignItems: 'center'
          }}
        >
          <Button
            borderColor={Colors.Custom.AlertsDescriptionColor}
            onPress={() => {
              props?.onLeftButtonClick?.(text);
            }}
            backgroundColor={Colors.Custom.ContainerBGColor}
            gap={8}
            style={modalActionDialogStyles.buttonStyle}
            _hover={{
              backgroundColor: Colors.FoldPixel.GRAY50
            }}
          >
            <Text fontSize={14} color={Colors.FoldPixel.GRAY300}>{props.leftButtonText}</Text>
          </Button>
          <Button
            borderColor={Colors.Custom.AlertsDescriptionColor}
            onPress={() => {
              props?.onRightButtonClick?.(text);
            }}
            backgroundColor={rightButtonColor}
            gap={8}
            _hover={{
              backgroundColor: rightHoverColor
            }}
            style={modalActionDialogStyles.buttonStyle}
          >
            <Text fontSize={14} color={rightTextColor}>{props.rightButtonText}</Text>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}
