import {
  LazyQueryResult,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';
import {Checkbox, Dropdown, Menu} from 'antd';
import {Button, Pressable, View, useMediaQuery} from 'native-base';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useIntl} from 'react-intl';
import {Platform} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {ILoginUserData} from '../../../../../../Interfaces';
import {MLOV_CATEGORY} from '../../../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
} from '../../../../../../constants/ActionConst';
import {
  BUTTON_TYPE,
  GROUP_MEMBER_TYPE,
  MODULE_PAGINATION_COUNT,
  ONLINE_STATUS,
  SMALL_WINDOW,
} from '../../../../../../constants/StringConst';
import {
  CUSTOM_MESSAGE_EVENT_CODES,
} from '../../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
import {Colors} from '../../../../../../styles';
import {EventBus} from '../../../../../../utils/EventBus';
import {
  getAccountId,
  getUserId,
  getUserUUID,
  isAllCallsChannelInboxType,
  isEFaxChannelInbox,
  isEmailChannelInboxType,
  isEmployerRole,
  isSMSEmailMissedCallChannelInboxType,
} from '../../../../../../utils/commonUtils';
import {
  getMlovCodeIdObj,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {NoDataFound} from '../../../../../common/NoDataFound';
import {
  CHANNEL_TYPE_CODE,
  CONVERSATION_SUBTAB_CODES,
} from '../../ConversationConst';
import {
  IConversationData,
  IConversationListProps,
  IConversationResponse,
  tabTypeString,
} from '../../interfaces';
import {renderConversationFooter} from './ConversationListComponents';
import {ConversationListItem} from './ConversationListItem';
import {ConversationListLoader} from './ConversationListLoader';
import {
  formatConversationMentionData,
  formatConversationsCallActivityData,
  formatConversationsEFaxData,
  formatConversationsEmailData,
  getBgColorClassNameForConversationItem,
  getBgColorForConversationItem,
  getConversationFinalVariables,
  getConversationListAfterReadMessages,
  getConversationListSearchCondition,
  getConversationUuidsFromConversationDataList,
  getPaginationCount,
  getStyleSelectedConversationItem,
  getUniqueConversationList,
} from './ConversationListUtils';

import {isEqual} from 'lodash';
import {deleteAllConversationsDraftDataFromMessageInMessageStorage, getConversationMentionTabData, saveConversationListDraftMessagesInMessageInMessageStorage} from '../../MessagingWindow/MessagingUtils';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import {useConversationMessageReceivedEffect} from './ConversationListEventHook/ConversationListEventHook';
import React from 'react';
import {useToast} from '../../../../../Toast/ToastProvider';
import {TestIdentifiers, testID} from '../../../../../../testUtils';
import ConversationsQueriesV2 from '../../../../../../services/Conversations/V2/ConversationsQueriesV2';
import {INBOX_CHANNEL_LIST} from '../ConversationConst';
import CallActivityConversationsQueries from '../../../../../../services/Conversations/V2/CallActivityConversationsQueries';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {ISipNumberList, IUserList} from '../../../CallLogs/interface';
import {getDraftForConversationList} from '../../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Api';
import {getCSSProperties, getStyles} from './ConversationListV2Styles';
import './ConversationListV2.css';
import debounce from 'lodash/debounce';
import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../../../../../utils/CaptureTransaction';
import {useMessageDBService} from '../../../../../CustomHooks/useMessageDBService';
// import {FormattedMessage} from 'react-intl';


const RenderConversationBlock = ({conversationListMarginBottom, getFinalConversationLists, conversationListState, selectedInboxTypeCode, isSidecarContext, enableConversationSelection, isCheckBoxChecked, handleCheckBoxChange, userId, onConversationListItemClick, conversationListWidth, searchString, allInboxList, selectedSubtabCode, loadMoreConversations}:{loadMoreConversations:any, selectedSubtabCode:any, allInboxList:any, searchString:any, conversationListWidth:any, onConversationListItemClick:any, userId:any, handleCheckBoxChange:any, isCheckBoxChecked:any, enableConversationSelection:any, isSidecarContext:any, selectedInboxTypeCode:any, conversationListState:any, conversationListMarginBottom:any, getFinalConversationLists:any}) => {
  const captureTransactionInst = CaptureTransaction.getInstance();
  const memorizeFooter = React.useMemo(() => renderConversationFooter(
    conversationListState.showFetchMore,
    conversationListState.isFetchMore,
    conversationListState.allConversationListLength,
    loadMoreConversations,
    conversationListState.alwaysShowFetchMore
  ), [
    conversationListState.showFetchMore,
    conversationListState.isFetchMore,
    conversationListState.allConversationListLength,
    conversationListState.alwaysShowFetchMore
  ]);

 

  const styles = React.useMemo(() => getStyles({}), []);
  return (
    <View

      {...testID('ConversationList')}
      marginBottom={conversationListMarginBottom()}
    >
      {getFinalConversationLists().map((item:any) => {
        const backgroundColor= getBgColorForConversationItem(
          item,
          conversationListState.newAddedConversation,
          conversationListState.selectedConversation,
          selectedInboxTypeCode,
          isSidecarContext
        )
        const bgClassName = getBgColorClassNameForConversationItem(
          item,
          conversationListState.newAddedConversation,
          conversationListState.selectedConversation,
          selectedInboxTypeCode,
          isSidecarContext
        )
        return (
          <View
            style={isSidecarContext ? styles.conversationListItemSidecar : styles.conversationListItem}
            key={
              selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
                ? item?.conversationMentionId
                : item?.uuid || item?.id
            }
            {...testID('ConversationListItem')}
          >
            <Stack direction="column" style={styles.flex1}>
              <Stack direction="row" style={styles.flex1}>
                {enableConversationSelection && (
                  <View
                    flex={0.15}
                    justifyContent={'center'}
                    justifyItems={'center'}
                    alignItems={'center'}
                    alignContent={'center'}
                    backgroundColor={backgroundColor}
                    {...testID(TestIdentifiers.checkbox)}
                  >
                    <Checkbox
                      className="copy-checkbox"
                      checked={isCheckBoxChecked(
                        item?.uuid,
                        item?.conversationMentionId
                      )}
                      value={
                        selectedInboxTypeCode ===
                        CHANNEL_TYPE_CODE.CHANNEL_MENTION
                          ? item?.conversationMentionId
                          : item?.uuid
                      }
                      onChange={(value) => {
                        handleCheckBoxChange(
                          value?.target?.checked,
                          item?.uuid,
                          item?.conversationMentionId
                        );
                      }}
                    />
                  </View>
                )}
                <div
                  className={`pressable w-0 ${bgClassName} ${
                    enableConversationSelection
                      ? 'conversation-list-item-container-f09'
                      : 'conversation-list-item-container-f1'
                  }`}
                  style={getStyleSelectedConversationItem(
                    item,
                    conversationListState.selectedConversation,
                    selectedInboxTypeCode,
                    userId
                  )}
                  onClick={onConversationListItemClick.bind(null, item)}
                  {...testID('ConversationListItemClick')}
                >
                  <View style={styles.pv4}>
                    <ConversationListItem
                      conversationListWidth={conversationListWidth}
                      selectedInboxTypeCode={selectedInboxTypeCode}
                      singleConversationData={item}
                      searchString={searchString}
                      isSelected={selectedInboxTypeCode ===
                          tabTypeString.CONVERSATION_MENTIONS
                          ? getConversationMentionTabData(
                            selectedInboxTypeCode || '',
                            item
                          )?.conversationMentionId ==
                          item?.conversationMentionId
                          : conversationListState.selectedConversation?.id ==
                          item?.id}
                      // selectedConversationId={conversationListState.selectedConversation?.id}
                      // selectedConversationMentionId={conversationListState.selectedConversation?.conversationMentionId}
                      allInboxList={allInboxList}
                      selectedSubtabCode={selectedSubtabCode}
                      isUnreadMsgPresent={item?.unreadMsgCount ? true : false}
                    />
                  </View>
                </div>
              </Stack>
            </Stack>
          </View>
        );
      })}
      {memorizeFooter}
    </View>
  );
};

const ConversationList = (props: IConversationListProps) => {
  const abortControllerRef = React.useRef<AbortController>(
    new AbortController()
  );
  const isRefreshConversationAfterOfflineOnlineEnabled = isAccountConfigEnabled(
    CONFIG_CODES.REFRESH_CONVERSATION_AFTER_OFFLINE_ONLINE
  );
  const intl = useIntl();
  const toast = useToast();
  const {
    searchString,
    selectedTabCode,
    conversationFilter,
    readConversationDataId,
    groupCreatedCount,
    selectedInboxTypeCode,
    onActionPerformed,
    allInboxList,
    enableConversationSelection,
    checkedSelectAll,
    checkedSelectList,
    isDeselectedSomeConversations,
    conversationUserFilterData,
  } = props;
  const conversationListRef: any = React.useRef();
  const conversationListWidth =
    conversationListRef?.current?.clientWidth < 200 ? '35%' : null;
  const commonData = useContext(CommonDataContext);
  const userId = getUserId();
  const userUuid = getUserUUID();
  const userData = commonData.userData || ({} as ILoginUserData);
  const [isSmallWindow] = useMediaQuery([{maxWidth: SMALL_WINDOW}]);
  const isSmallScreen = isSmallWindow;
  const groupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const groupMemberTypeList =
    getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.GroupMemberType) ||
    [];
  const userTypeId = getMlovIdFromCode(
    groupMemberTypeList,
    GROUP_MEMBER_TYPE.USER
  );
  const navigate = useNavigate();
  const captureTransactionInst = CaptureTransaction.getInstance();
  const {syncConversationsInBulk} = useMessageDBService();

  const groupTypeCodeIdObj = getMlovCodeIdObj(groupTypeList);
  const [conversationListState, setConversationListState] = useState({
    isFetchMore: false,
    showFetchMore: true,
    alwaysShowFetchMore: false,
    isLoadingConversations: true,
    pageCount: 1,
    newAddedConversation: {} as IConversationData,
    selectedConversation: {} as IConversationData,
    conversationList: [] as IConversationData[],
    uniqueConversationList: [] as IConversationData[],
    allConversationListLength: 0,
    uniqueId: new Date().toISOString(),
    selectedConversationList: [] as string[],
    deselectedConversationUuidList: [] as string[],
    selectedMentionConversationList: [] as string[],
    deselectedMentionConversationUuidList: [] as string[],
    userList: [] as IUserList[],
    sipNumberList: [] as ISipNumberList[],
    userAndSipNumberLoading: false,
  });

  const isEFaxMessagingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION
  );
  const isSidecarContext = commonData.sidecarContext?.isSidecar;

  const [conversationListLength, setConversationListLength] = useState(0);

  const hideStartNewConversationInboxTypeList: string[] = [
    CHANNEL_TYPE_CODE.CHANNEL_MENTION,
    CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED,
    CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS,
    CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME,
    CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER,
    CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED,
    CHANNEL_TYPE_CODE.ALL_CALLS,
  ];

  const paginationCount = getPaginationCount(isSidecarContext);

  useEffect(() => {
    return () => {
      abortControllerRef?.current?.abort();
      conversationListRef?.current?.abort();
      deleteAllConversationsDraftDataFromMessageInMessageStorage();
      captureTransactionInst.clearTransaction(
        TRANSACTION_NAMES.CONVERSATION_LOAD_MORE_CLICKED,
        props.selectedInboxTypeCode
      );
    }
  }, []);

  useEffect(() => {
    setConversationListState((prev) => ({
      ...prev,
      showFetchMore: true,
    }));
  }, [selectedInboxTypeCode]);

  useEffect(() => {
    if (
      !conversationListState?.isFetchMore &&
      !conversationListState?.isLoadingConversations &&
      conversationListState?.conversationList.length <
      paginationCount
    ) {
      loadMoreConversations();
    }
  }, [conversationListState?.conversationList.length]);

  useEffect(() => {
    if (!enableConversationSelection) {
      setConversationListState((prev) => {
        return {
          ...prev,
          selectedConversationList: [],
          deselectedConversationUuidList: [],
          selectedMentionConversationList: [],
          deselectedMentionConversationUuidList: [],
        };
      });
    }
    if (checkedSelectList) {
      const selectedConversationUuids = getFinalConversationLists();
      if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        const filteredList = selectedConversationUuids?.map(
          (item) => item?.conversationMentionId
        );
        setConversationListState((prev: any) => {
          return {
            ...prev,
            selectedMentionConversationList: filteredList,
            deselectedMentionConversationUuidList: [],
          };
        });
        handleConversationAction(
          CONVERSATION_ACTION_CODES.CONVERSATION_SELECTION,
          filteredList
        );
      } else {
        const filteredList = selectedConversationUuids?.map(
          (item) => item?.uuid
        );
        setConversationListState((prev) => {
          return {
            ...prev,
            selectedConversationList: filteredList,
            deselectedConversationUuidList: [],
          };
        });
        handleConversationAction(
          CONVERSATION_ACTION_CODES.CONVERSATION_SELECTION,
          filteredList
        );
      }
    }
    if (checkedSelectAll && isDeselectedSomeConversations) {
      setConversationListState((prev) => {
        return {
          ...prev,
          deselectedConversationUuidList: [],
          deselectedMentionConversationUuidList: [],
        };
      });
      handleConversationAction(
        CONVERSATION_ACTION_CODES.CONVERSATION_DESELECTION,
        []
      );
    }
  }, [
    enableConversationSelection,
    checkedSelectList,
    isDeselectedSomeConversations,
  ]);

  const [getConversationList, {fetchMore}] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );

  const [getMentionConversations, {fetchMore: fetchMoreMentions}] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetMentionsConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );

  const [getEmailChatConversations, {fetchMore: fetchMoreEmail}] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetEmailConversations,
      {
        fetchPolicy: 'no-cache',
      }
    );

  const [getEFaxChatConversations, {fetchMore: fetchMoreEFax}] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetEFaxConversations,
      {
        fetchPolicy: 'no-cache',
      }
    );

  const [getMentionsSearchConversations, {fetchMore: fetchMoreMentionsSearch}] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueries.GetMentionsSearchConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );

  const [getInboxConversations, {fetchMore: fetchMoreInboxes}] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetInboxConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );
  const [GetLastCallActivityConversations, {fetchMore: fetchMoreCallActivityConversations }] =
    useLazyQuery<IConversationResponse>(
      CallActivityConversationsQueries.GetLastCallActivityConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );


  const [searchConversationList, searchConversation] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );

    const [searchInternalChatConversationList, searchInternalChatConversation] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetIntenalChatConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );

  const [searchEmailConversationList, searchEmailConversation] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueries.GetEmailConversations,
      {
        fetchPolicy: 'no-cache',
      }
    );

    const [searchEFaxConversationList, searchEFaxConversation] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetEFaxConversations,
      {
        fetchPolicy: 'no-cache',
      }
    );

    const [searchAllCallsConversationList, searchAllCallsConversation] =
    useLazyQuery<IConversationResponse>(
      CallActivityConversationsQueries.SearchLastCallActivityConversations,
      {
        fetchPolicy: 'no-cache',
      }
    );

  const [searchInboxConversationList, searchInboxConversation] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetInboxConversations,
      {
        fetchPolicy: 'no-cache',
      }
    );

    const [
      searchAssignToOtherConversationList,
      searchAssignToOtherConversation,
    ] = useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetAssignToOtherConversations,
      {
        fetchPolicy: 'no-cache',
      }
    );

  const [GetIntenalChatConversations, {fetchMore: fetchMoreInternalChat}] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetIntenalChatConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );

  const [getChatConversations, {fetchMore: fetchMoreChats}] =
    useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetChatConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );

    const [
      getAssignToOtherConversations,
      {fetchMore: fetchMoreAssignedToOther},
    ] = useLazyQuery<IConversationResponse>(
      ConversationsQueriesV2.GetAssignToOtherConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );


  const onUserOnlineStatusChanged = useCallback(
    (eventData: any) => {
      if (eventData?.onlineStatus === ONLINE_STATUS.ONLINE) {
        setConversationListState((prev) => {
          return {
            ...prev,
            isLoadingConversations: true,
            pageCount: 1,
            conversationList: [],
          };
        });
        debouncedFetchConversationList();  
      }
      return () => {
        debouncedFetchConversationList.cancel();
      };
    },
    [conversationListState]
  );

  if (isRefreshConversationAfterOfflineOnlineEnabled) {
    useEffect(() => {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.addEventListener(
        CUSTOM_MESSAGE_EVENT_CODES.REFRESH_CONVERSATION_ON_USER_ONLINE,
        onUserOnlineStatusChanged
      );
      return () => {
        eventBus.removeEventListener(onUserOnlineStatusChanged);
      };
    }, [conversationListState]);
  }

  if (isSidecarContext) {
    useEffect(() => {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.addEventListener(
        CUSTOM_MESSAGE_EVENT_CODES.SIDECAR_CONVERSATION_UNMOUNT,
        onSidecarConversationBack
      );
      return () => {
        eventBus.removeEventListener(onSidecarConversationBack);
      };
    }, [conversationListState]);
  }

  const onSidecarConversationBack = useCallback(() => {
    setConversationListState((prev) => {
      return {
        ...prev,
        selectedConversation: {} as IConversationData,
      };
    });
  }, [conversationListState]);

  useConversationMessageReceivedEffect(
    conversationListState as any,
    setConversationListState as any,
    {
      searchString: props?.searchString || '',
      conversationFilter: {
        dateRange: {
          fromDate: props?.conversationUserFilterData?.dateRange?.startDate || '',
          toDate: props?.conversationUserFilterData?.dateRange?.endDate || ''
        },
        assignedUsersIds: props?.conversationUserFilterData?.assigneeIds,
        readFilter: props?.conversationUserFilterData?.readFilter,
        locationUuids: props?.conversationUserFilterData?.locationUuids,
        inboxId: props?.conversationUserFilterData?.inboxId,
        statusId: props?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED ? 1 : 0,
      },
      selectedInboxTypeCode: props?.selectedInboxTypeCode,
      onConversationActionPerformed: props?.onConversationActionPerformed,
      selectedSubtabCode: props?.selectedSubtabCode,
    },
    commonData,
    toast
  );

  const getConversationPromise = (): Promise<
    LazyQueryResult<IConversationResponse, OperationVariables>
  > => {
    if (searchString) {
      const finalSearchWhereConditions: any =
        getConversationListSearchCondition(
          searchString,
          selectedTabCode,
          groupTypeCodeIdObj,
          userUuid,
          selectedInboxTypeCode,
          userData,
          userTypeId,
          isEFaxMessagingEnabled,
          props?.selectedSubtabCode,
          props?.emailInboxUserPreference,
          props?.otherContactTypeId,
          props?.isEmailUserPreferenceEnabled,
          conversationUserFilterData
        );
      const conversationMessageWhereCondition =
        finalSearchWhereConditions?._or?.[0]?.conversationMessages;
      if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        return getMentionsSearchConversations({
          variables: {
            limit: paginationCount,
            offset: 0,
            whereCondition: finalSearchWhereConditions,
            orderByObject: {lastActivityAt: `desc`},
          },
        });
      } else if (isEmailChannelInboxType(selectedInboxTypeCode)) {
        let orderByObject;
        if (
          props?.selectedSubtabCode ===
          CONVERSATION_SUBTAB_CODES.SENT
        ) {
          orderByObject = {
            lastOutgoingEmailAt: `desc_nulls_last`,
          };
        } else {
          orderByObject = {lastIncomingEmailAt: `desc_nulls_last`};
        }

        setConversationListState((prev) => {
          return {
            ...prev,
            isLoadingConversations: true,
          };
        });
        return searchEmailConversationList({
          variables: {
            limit: paginationCount,
            offset: 0,
            whereCondition: finalSearchWhereConditions,
            orderByObject: orderByObject,
            ...(conversationMessageWhereCondition && {
              conversationMessagesWhere: conversationMessageWhereCondition,
            }),
            unreadMessagesWhere: {
              senderType: {
                _is_null: false,
              },
              senderId: {
                _neq: userId,
              },
              messageType: {
                _neq: -1,
              },
            },
            unreadMessagesWhereCount: {
              useruuid: userUuid,
              userid: userId,
              messagetype: '{-1}',
              messageconditionin: false,
            },
          },
        });
      } else if (isEFaxChannelInbox(selectedInboxTypeCode)) {
        let orderByObject;
        if (
          props?.selectedSubtabCode ===
          CONVERSATION_SUBTAB_CODES.SENT
        ) {
          orderByObject = {
            lastOutgoingEFaxAt: `desc_nulls_last`,
          };
        } else {
          orderByObject = {lastIncomingEFaxAt: `desc_nulls_last`};
        }

        setConversationListState((prev) => {
          return {
            ...prev,
            isLoadingConversations: true,
          };
        });
        return searchEFaxConversationList({
          variables: {
            limit: paginationCount,
            offset: 0,
            whereCondition: finalSearchWhereConditions,
            orderByObject: orderByObject,
            ...(conversationMessageWhereCondition && {
              conversationMessagesWhere: conversationMessageWhereCondition,
            }),
            unreadMessagesWhere: {
              senderType: {
                _is_null: false,
              },
              senderId: {
                _neq: userId,
              },
              messageType: {
                _neq: -1,
              },
            },
            unreadMessagesWhereCount: {
              useruuid: userUuid,
              userid: userId,
              messagetype: '{-1}',
              messageconditionin: false,
            },
          },
        });
      } else if (INBOX_CHANNEL_LIST?.includes(selectedInboxTypeCode)) {
        setConversationListState((prev) => {
          return {
            ...prev,
            isLoadingConversations: true,
          };
        });
        return searchInboxConversationList({
          variables: {
            limit: paginationCount,
            offset: 0,
            whereCondition: finalSearchWhereConditions,
            orderByObject: {lastActivityAt: `desc_nulls_last`},
            ...(conversationMessageWhereCondition && {
              conversationMessagesWhere: conversationMessageWhereCondition,
            }),
            unreadMessagesWhere: {
              senderType: {
                _is_null: false,
              },
              senderId: {
                _neq: userId,
              },
              messageType: {
                _neq: -1,
              },
            },
            unreadMessagesWhereCount: {
              useruuid: userUuid,
              userid: userId,
              messagetype: '{-1}',
              messageconditionin: false,
            },
          },
        });
      } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL){
        setConversationListState((prev) => {
          return {
            ...prev,
            isLoadingConversations: true,
          };
        });
        return searchInternalChatConversationList({
          variables: {
            limit: paginationCount,
            offset: 0,
            whereCondition: finalSearchWhereConditions,
            orderByObject: {lastActivityAt: `desc_nulls_last`},
            unreadMessagesWhere: {
              senderType: {
                _is_null: false,
              },
              senderId: {
                _neq: userId,
              },
              messageType: {
                _neq: -1,
              },
            },
            unreadMessagesWhereCount: {
              useruuid: userUuid,
              userid: userId,
              messagetype: '{-1}',
              messageconditionin: false,
            },
          },
        });
      } else if (isAllCallsChannelInboxType(selectedInboxTypeCode)) {
        return searchAllCallsConversationList({
          variables: {
            limit: paginationCount,
            offset: 0,
            whereCondition: finalSearchWhereConditions,
            orderByObject: {lastCallAt: `desc`},
          },
        });
      } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
        setConversationListState((prev) => {
          return {
            ...prev,
            isLoadingConversations: true,
          };
        });
        return searchAssignToOtherConversationList({
          variables: {
            limit: MODULE_PAGINATION_COUNT.CONVERSATION_PAGE_SIZE,
            offset: 0,
            whereCondition: finalSearchWhereConditions,
            orderByObject: {lastActivityAt: `desc_nulls_last`},
            unreadMessagesWhere: {
              senderType: {
                _is_null: false,
              },
              senderId: {
                _neq: userId,
              },
              messageType: {
                _neq: -1,
              },
            },
            loginUserUuid: userUuid,
            unreadMessagesWhereCount: {
              useruuid: userUuid,
              userid: userId,
              messagetype: '{-1}',
              messageconditionin: false,
            },
          },
        });
      }else {
        setConversationListState((prev) => {
          return {
            ...prev,
            isLoadingConversations: true,
          };
        });
        return searchConversationList({
          variables: {
            limit: paginationCount,
            offset: 0,
            whereCondition: finalSearchWhereConditions,
            orderByObject: {lastActivityAt: `desc_nulls_last`},
            unreadMessagesWhere: {
              senderType: {
                _is_null: false,
              },
              senderId: {
                _neq: userId,
              },
              messageType: {
                _neq: -1,
              },
            },
            unreadMessagesWhereCount: {
              useruuid: userUuid,
              userid: userId,
              messagetype: '{-1}',
              messageconditionin: false,
            },
          },
        });
      }
    } else {
      const finalVariables: any = getConversationFinalVariables(
        selectedInboxTypeCode,
        userTypeId,
        userUuid,
        userId,
        conversationFilter,
        props?.selectedSubtabCode,
        props?.selectedInboxUserIds,
        isSidecarContext
      );
      if (INBOX_CHANNEL_LIST.includes(selectedInboxTypeCode)) {
        return getInboxConversations({
          variables: finalVariables,
        });
      } else if(isAllCallsChannelInboxType(selectedInboxTypeCode)) {
        return GetLastCallActivityConversations({
          variables: finalVariables,
        });
      } else if (
        selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET
      ) {
        return getChatConversations({
          variables: finalVariables,
        });
      } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        return getMentionConversations({
          variables: finalVariables,
        });
      } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL) {
        return GetIntenalChatConversations({
          variables: finalVariables,
        });
      } else if (isEmailChannelInboxType(selectedInboxTypeCode)) {
        return getEmailChatConversations({
          variables: finalVariables,
        });
      } else if (isEFaxChannelInbox(selectedInboxTypeCode)) {
        return getEFaxChatConversations({
          variables: finalVariables,
        })
      } else if(selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
        return getAssignToOtherConversations({
          variables: finalVariables,
        })
      } else {
        return getConversationList({
          variables: finalVariables,
        });
      }
    }
  };

  const getDraftConversationDataFromConversationUuids = async (conversationUuids : string[]) => {
    const conversationListDraftData = await getDraftForConversationList({
      conversationUuids: conversationUuids,
      abortSignal: abortControllerRef?.current?.signal
    });

    if (conversationListDraftData?.conversationDraft && Object.keys(conversationListDraftData?.conversationDraft || {})?.length) {
      saveConversationListDraftMessagesInMessageInMessageStorage(conversationListDraftData?.conversationDraft);
    }
  }

  const debouncedFetchConversationList = debounce(() => {
      getConversationPromise()
        .then(async (conversationRespData) => {
          props?.setUserPreferenceChangeLoading?.();
          let showFetchMore = conversationListState.showFetchMore;
          if (
            conversationRespData.variables &&
            conversationRespData.variables.offset === 0
          ) {
            showFetchMore = true;
          }
          let allConversationListData = [] as IConversationData[];
          if (conversationRespData?.data?.conversations) {
            allConversationListData =
              conversationRespData?.data?.conversations || [];
          } else if (conversationRespData?.data?.conversationMentions) {
            allConversationListData = formatConversationMentionData(
              conversationRespData?.data?.conversationMentions || []
            );
          } else if (conversationRespData?.data?.emailConversationActivities) {
            allConversationListData = formatConversationsEmailData(
              conversationRespData?.data?.emailConversationActivities || []
            );
          } else if (conversationRespData?.data?.efaxConversationActivities) {
            allConversationListData = formatConversationsEFaxData(
              conversationRespData?.data?.efaxConversationActivities || []
            );
          } else if (conversationRespData?.data?.callConversationActivities) {
            allConversationListData = formatConversationsCallActivityData(
              conversationRespData?.data?.callConversationActivities || []
            );
          }

          const allConversationList = allConversationListData;
          const conversationIds = allConversationList.map((item) => item.id);
          syncConversationsInBulk(conversationIds);
          const conversationUuidList = getConversationUuidsFromConversationDataList(allConversationList);
          await getDraftConversationDataFromConversationUuids(conversationUuidList);
          allConversationList.forEach((item) => {
            if (
              item.conversationMessages &&
              item.conversationMessages.length > 0
            ) {
              item.conversationMessages[0].messageMentions = [];
            }
            item.unreadMessages = [];
          });
        let selectedConversation = {} as IConversationData;
          if (allConversationList && allConversationList.length) {
            const uniqueConversationList = getUniqueConversationList(
              allConversationList,
              selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
            );
          selectedConversation = uniqueConversationList[0];
            if (
              (conversationListState.pageCount === 1 || conversationFilter) &&
              Platform.OS === 'web'
            ) {
            const tempSelectedConversation = selectedConversation
              setConversationListState((prev) => {
                return {
                  ...prev,
                  isLoadingConversations: false,
                  pageCount: prev.pageCount + 1,
                  showFetchMore,
                selectedConversation: isSidecarContext
                  ? ({} as IConversationData)
                  : selectedConversation,
                  conversationList: [
                    ...uniqueConversationList,
                    // ...prev.conversationList,
                  ],
                  allConversationListLength: allConversationList?.length,
                };
              });
            if (!isSidecarContext) {
              captureTransactionInst.initiateTransaction({
                name: TRANSACTION_NAMES.CONVERSATION_SELECTED,
                identifier: tempSelectedConversation?.id || 0,
              });
              captureTransactionInst.finishTransaction(
                TRANSACTION_NAMES.CONVERSATION_SELECTED,
                tempSelectedConversation?.id || 0
              );
              props?.onConversationActionPerformed?.(
                COMMON_ACTION_CODES.ITEM_CLICKED,
                {
                  selectedConversation: tempSelectedConversation,
                }
              );
            }
            }
          } else if (Platform.OS === 'web') {
            setConversationListState((prev) => {
              return {
                ...prev,
                isLoadingConversations: false,
                pageCount: prev.pageCount + 1,
                conversationList: [
                  ...allConversationList,
                  // ...prev.conversationList,
                ],
              };
            });
            props?.onConversationActionPerformed?.(
              COMMON_ACTION_CODES.NO_DATA_FOUND,
              {
                selectedConversation: {},
              }
            );
          }
        })
        .catch(() => {
          props?.setUserPreferenceChangeLoading?.();
          setConversationListState((prev) => {
            return {
              ...prev,
              isLoadingConversations: false,
              conversationList: [...prev.conversationList],
            };
          });
        });
    }, 200);

  useEffect(() => {
    const conversationListAfterReadMsg = getConversationListAfterReadMessages(
      conversationListState.conversationList,
      readConversationDataId
    );
    setConversationListState((prev) => {
      return {
        ...prev,
        conversationList: [...conversationListAfterReadMsg],
      };
    });
  }, [readConversationDataId]);

  useDeepEffect(() => {
    if (conversationFilter && Object.keys(conversationFilter)?.length) {
      if (isEmailChannelInboxType(selectedInboxTypeCode) || isEFaxChannelInbox(selectedInboxTypeCode)) {
        setConversationListState((prev) => {
          return {
            ...prev,
            isLoadingConversations: true,
            pageCount: 1,
            conversationList: [],
          };
        });
      }
      debouncedFetchConversationList();
    }
    return () => {
      debouncedFetchConversationList.cancel();
    };
  }, [
    searchString,
    conversationFilter,
    selectedTabCode,
    groupCreatedCount,
    selectedInboxTypeCode,
    props?.selectedSubtabCode,
  ]);

  const updateFetchMore = (fetchMoreResult: IConversationResponse) => {
    setConversationListState((prev) => {
      return {
        ...prev,
        alwaysShowFetchMore: false,
      };
    });
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      if (
        fetchMoreResult &&
        fetchMoreResult?.conversationMentions?.length === 0
      ) {
        setConversationListState((prev) => {
          return {
            ...prev,
            isFetchMore: false,
            showFetchMore: false,
          };
        });
      } else {
        setConversationListState((prev) => {
          return {
            ...prev,
            isFetchMore: false,
            showFetchMore:
              fetchMoreResult &&
              (fetchMoreResult?.conversationMentions?.length || 0) <
                paginationCount
                ? false
                : true,
          };
        });
      }
    } else if (isEmailChannelInboxType(selectedInboxTypeCode)) {
      if (
        fetchMoreResult &&
        fetchMoreResult?.emailConversationActivities?.length === 0
      ) {
        setConversationListState((prev) => {
          return {
            ...prev,
            isFetchMore: false,
            showFetchMore: false,
          };
        });
      } else {
        setConversationListState((prev) => {
          return {
            ...prev,
            isFetchMore: false,
            showFetchMore:
              fetchMoreResult &&
              (fetchMoreResult?.emailConversationActivities?.length || 0) <
                paginationCount
                ? false
                : true,
          };
        });
      }
    } else if (isEFaxChannelInbox(selectedInboxTypeCode)) {
      if (
        fetchMoreResult &&
        fetchMoreResult?.efaxConversationActivities?.length === 0
      ) {
        setConversationListState((prev) => {
          return {
            ...prev,
            isFetchMore: false,
            showFetchMore: false,
          };
        });
      } else {
        setConversationListState((prev) => {
          return {
            ...prev,
            isFetchMore: false,
            showFetchMore:
              fetchMoreResult &&
              (fetchMoreResult?.efaxConversationActivities?.length || 0) <
                paginationCount
                ? false
                : true,
          };
        });
      }
    }  else if (isAllCallsChannelInboxType(selectedInboxTypeCode)) {
      if (
        fetchMoreResult &&
        fetchMoreResult?.callConversationActivities?.length === 0
      ) {
        setConversationListState((prev) => {
          return {
            ...prev,
            isFetchMore: false,
            showFetchMore: false,
          };
        });
      } else {
        setConversationListState((prev) => {
          return {
            ...prev,
            isFetchMore: false,
            showFetchMore:
              fetchMoreResult &&
              (fetchMoreResult?.callConversationActivities?.length || 0) <
                paginationCount
                ? false
                : true,
          };
        });
      }
    } else {
      if (fetchMoreResult && fetchMoreResult?.conversations.length === 0) {
        setConversationListState((prev) => {
          return {
            ...prev,
            isFetchMore: false,
            showFetchMore: false,
          };
        });
      } else {
        setConversationListState((prev) => {
          return {
            ...prev,
            isFetchMore: false,
            showFetchMore:
              (fetchMoreResult && fetchMoreResult?.conversations?.length) <
              paginationCount
                ? false
                : true,
          };
        });
      }
    }
  }

  const loadMoreConversations = React.useCallback((): void => {
    captureTransactionInst.initiateTransaction({
      name: TRANSACTION_NAMES.CONVERSATION_LOAD_MORE_CLICKED,
      identifier: props.selectedInboxTypeCode,
    });
    setConversationListState((prev) => {
      return {
        ...prev,
        alwaysShowFetchMore: false,
        isFetchMore: true,
      };
    });
    let fetchMoreConversation = fetchMore;
    if (INBOX_CHANNEL_LIST.includes(selectedInboxTypeCode)) {
      fetchMoreConversation = fetchMoreInboxes;
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET) {
      fetchMoreConversation = fetchMoreChats;
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      fetchMoreConversation = fetchMoreMentions;
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL) {
      fetchMoreConversation = fetchMoreInternalChat;
    } else if (isEmailChannelInboxType(selectedInboxTypeCode)) {
      fetchMoreConversation = fetchMoreEmail;
    } else if (isAllCallsChannelInboxType(selectedInboxTypeCode)) {
      fetchMoreConversation = fetchMoreCallActivityConversations;
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
      fetchMoreConversation = fetchMoreAssignedToOther
    } else if (isEFaxChannelInbox(selectedInboxTypeCode)) {
      fetchMoreConversation = fetchMoreEFax
    }

    if (searchString) {
      if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        fetchMoreConversation = fetchMoreMentionsSearch;
      } else if (isEmailChannelInboxType(selectedInboxTypeCode)) {
        fetchMoreConversation = searchEmailConversation.fetchMore;
      } else if (INBOX_CHANNEL_LIST.includes(selectedInboxTypeCode)) {
        fetchMoreConversation = searchInboxConversation.fetchMore;
      } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL){
        fetchMoreConversation = searchInternalChatConversation.fetchMore
      } else if (isAllCallsChannelInboxType(selectedInboxTypeCode)) {
        fetchMoreConversation = searchAllCallsConversation.fetchMore;
      } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
        fetchMoreConversation = searchAssignToOtherConversation.fetchMore
      } else if (isEFaxChannelInbox(selectedInboxTypeCode)) {
        fetchMoreConversation = searchEFaxConversation.fetchMore
      } else {
        fetchMoreConversation = searchConversation.fetchMore;
      }
    }
    fetchMoreConversation({
      variables: {
        offset: conversationListState?.conversationList.length,
      },
      updateQuery: (prevResult, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prevResult;
        let allConversations = {} as any;
        setConversationListState((prev) => {
          let allConversationListData = [] as IConversationData[];
          if (fetchMoreResult?.conversations) {
            allConversationListData =
              fetchMoreResult?.conversations || [];
          } else if (fetchMoreResult?.conversationMentions) {
            allConversationListData = formatConversationMentionData(
              fetchMoreResult?.conversationMentions || []
            );
          } else if (fetchMoreResult?.emailConversationActivities) {
            allConversationListData = formatConversationsEmailData(
              fetchMoreResult?.emailConversationActivities || []
            );
          } else if (fetchMoreResult?.callConversationActivities) {
            allConversationListData = formatConversationsCallActivityData(
              fetchMoreResult?.callConversationActivities || []
            );
          } else if(fetchMoreResult?.efaxConversationActivities) {
            allConversationListData = formatConversationsEFaxData(
              fetchMoreResult?.efaxConversationActivities || []
            );
          }
          allConversations = {
            ...prevResult,
            conversations: [
              ...prev.conversationList,
              ...allConversationListData
            ],
          };
          const allConversationList = allConversationListData;
          const conversationIds = allConversationList.map((item) => item.id);
          syncConversationsInBulk(conversationIds);
          const conversationUuidList = getConversationUuidsFromConversationDataList(allConversationList);
          getDraftConversationDataFromConversationUuids(conversationUuidList);
          const uniqueConversationList = getUniqueConversationList(
            allConversations.conversations,
            selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
          );
          captureTransactionInst.finishTransaction(
            TRANSACTION_NAMES.CONVERSATION_LOAD_MORE_CLICKED,
            props.selectedInboxTypeCode,
            {
              conversationListLength: uniqueConversationList.length,
            }
          );
          return {
            ...prev,
            conversationList: [...uniqueConversationList],
            allConversationListLength: allConversations.conversations?.length,
          };
        });
        updateFetchMore(fetchMoreResult);
        return allConversations;
      },
    });
  }, [setConversationListState, updateFetchMore]);

  const finalListHeight = isSidecarContext
    ? '82vh'
    : isSMSEmailMissedCallChannelInboxType(selectedInboxTypeCode) ||
      isAllCallsChannelInboxType(selectedInboxTypeCode)||
      isEFaxChannelInbox(selectedInboxTypeCode)
    ? isSmallScreen
      ? '80vh'
      : '82vh'
    : isSmallScreen
    ? '86vh'
    : '88vh';

  const handleNoConversation = (selectedInboxTypeCode: string) => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL) {
      if (
        allInboxList &&
        allInboxList[CHANNEL_TYPE_CODE.CHANNEL_EMAIL]?.length
      ) {
        onActionPerformed(CHANNEL_TYPE_CODE.CHANNEL_EMAIL);
      } else {
        navigate('/admin/communication/inbox/create');
      }
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS) {
      if (
        allInboxList &&
        allInboxList[CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS]?.length
      ) {
        onActionPerformed(CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS);
      } else {
        navigate('/admin/communication/inbox/create');
      }
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL) {
      props?.onConversationActionPerformed?.(
        GROUP_ACTION_CODES.GROUP_CREATE,
        CHANNEL_TYPE_CODE.CHANNEL_INTERNAL
      );
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_E_FAX) {
      onActionPerformed(CHANNEL_TYPE_CODE.CHANNEL_E_FAX);
    } else {
      props?.onConversationActionPerformed?.(GROUP_ACTION_CODES.GROUP_CREATE);
    }
  };

  const startConversationBtnText = (action?: string) => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL) {
      if (
        allInboxList &&
        allInboxList[CHANNEL_TYPE_CODE.CHANNEL_EMAIL]?.length
      ) {
        return 'newEmail';
      } else {
        return 'setupEmailInbox';
      }
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS) {
      if (
        allInboxList &&
        allInboxList[CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS]?.length
      ) {
        return 'newSMS';
      } else {
        return 'setupSmsInbox';
      }
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_E_FAX) {
      return 'newFax';
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL) {
      return isEmployerRole() ? 'startNewChat' : 'startInternalChat';
    } else if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ALL) {
      if (action && action?.length) {
        if (action === CHANNEL_TYPE_CODE.CHANNEL_EMAIL) {
          if (
            allInboxList &&
            allInboxList[CHANNEL_TYPE_CODE.CHANNEL_EMAIL]?.length
          ) {
            return 'Start Email';
          } else {
            return 'Setup Email Inbox';
          }
        } else if (action === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS) {
          if (
            allInboxList &&
            allInboxList[CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS]?.length
          ) {
            return 'Start SMS';
          } else {
            return 'Setup SMS Inbox';
          }
        }
      } else {
        return 'newConversations';
      }
    }
    return 'startChat';
  };

  const getGroupConversationDataForSelectedConversationNew = async (
    selectedConversation: IConversationData,
    conversationList: IConversationData[]
  ) => {
    const finalSelectedConversation = {...selectedConversation};
    props?.onConversationActionPerformed?.(COMMON_ACTION_CODES.ITEM_CLICKED, {
      selectedConversation: finalSelectedConversation,
      isGroupConversationLoading: false,
    });
    selectedConversation = finalSelectedConversation;
    if (conversationListState.conversationList?.length) {
      const tempConList = [...conversationListState.conversationList];
      const conIndex = tempConList.findIndex((singleCon) => {
        if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
          return (
            singleCon.conversationMentionId ===
            finalSelectedConversation.conversationMentionId
          );
        }
        return singleCon.uuid === finalSelectedConversation?.uuid;
      });
      if (conIndex !== -1) {
        if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
          tempConList[conIndex] = {
            ...finalSelectedConversation,
          };
        } else {
          tempConList[conIndex] = {
            ...finalSelectedConversation,
          };
        }
      }
      setConversationListState((prev) => {
        return {
          ...prev,
          conversationList: tempConList,
          selectedConversation: finalSelectedConversation,
        };
      });
    } else {
      setConversationListState((prev) => {
        return {
          ...prev,
          selectedConversation: finalSelectedConversation,
        };
      });
    }
  };


  const onConversationListItemClick = async (
    item: IConversationData,
    conversationList?: IConversationData[]
  ) => {
    captureTransactionInst.initiateTransaction({
      name: TRANSACTION_NAMES.CONVERSATION_SELECTED,
      identifier: item?.id || 0,
    });
    captureTransactionInst.finishTransaction(
      TRANSACTION_NAMES.CONVERSATION_SELECTED,
      item?.id || 0
    );
    props?.onConversationActionPerformed?.(
      CONVERSATION_ACTION_CODES.GROUP_CONVERSATION_DATA_LOADING,
      {
        selectedConversation: item,
        isGroupConversationLoading: true,
      }
    );
    const conversationListData = conversationList
      ? conversationList
      : conversationListState.conversationList;
    await getGroupConversationDataForSelectedConversationNew(
      item,
      conversationListData
    );
  };

  const getNodataMessage = () => {
    let noDataMessage = '';
    if (props.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME) {
      noDataMessage = 'noAssigned';
    } else if (
      props.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER
    ) {
      noDataMessage = 'noAssigned';
    } else if (
      props.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED
    ) {
      noDataMessage = 'noUnassigned';
    } else if (
      props.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
    ) {
      noDataMessage = 'noMentions';
    } else if (
      props.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS
    ) {
      noDataMessage = 'noMissedCall';
    } else if (
      props.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED
    ) {
      noDataMessage = 'noArchived';
    } else if (
      props.selectedInboxTypeCode === CHANNEL_TYPE_CODE.ALL_CALLS
    ) {
      noDataMessage = 'noCalls';
    } else {
      noDataMessage = 'noConversations';
    }
    return noDataMessage;
  };

  const hideCreateNewConversationByInboxType = () => {
    return (
      hideStartNewConversationInboxTypeList.includes(selectedInboxTypeCode) ||
      isEmployerRole()
    );
  };



  const getFinalConversationLists = useCallback(() => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      const conversationList = conversationListState.conversationList;
      if (conversationListLength !== conversationList?.length) {
        handleConversationAction(
          CONVERSATION_ACTION_CODES.CONVERSATION_LIST_LENGTH,
          conversationList?.length
        );
        setConversationListLength(conversationList?.length);
      }
      return conversationList;
    } else {
      const conversationList = getUniqueConversationList(
        conversationListState.conversationList
      );
      if (conversationListLength !== conversationList?.length) {
        handleConversationAction(
          CONVERSATION_ACTION_CODES.CONVERSATION_LIST_LENGTH,
          conversationList?.length
        );
        setConversationListLength(conversationList?.length);
      }
      return conversationList;
    }
  }, [
    selectedInboxTypeCode,
    conversationListState.conversationList,
    conversationListLength
  ]);

  const handleConversationAction = (actionCode: string, actionData: any) => {
    if (props?.onConversationActionPerformed) {
      props?.onConversationActionPerformed(actionCode, actionData);
    }
  };

  const handleSelectedAllConversation = (
    checked: boolean,
    conversationUuid: string,
    conversationMentionId?: string
  ) => {
    if (
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION &&
      conversationMentionId
    ) {
      setConversationListState((prev) => {
        let deselectedMentionConversationUuidList = [
          ...prev.deselectedMentionConversationUuidList,
        ];
        if (!checked) {
          deselectedMentionConversationUuidList.push(conversationMentionId);
        } else {
          deselectedMentionConversationUuidList =
            deselectedMentionConversationUuidList?.filter?.(
              (item) => item !== conversationMentionId
            );
        }
        handleConversationAction(
          CONVERSATION_ACTION_CODES.CONVERSATION_DESELECTION,
          deselectedMentionConversationUuidList
        );
        return {
          ...prev,
          selectedMentionConversationList: [],
          deselectedMentionConversationUuidList:
            deselectedMentionConversationUuidList,
        };
      });
    } else {
      setConversationListState((prev) => {
        let deselectedConversationList = [
          ...prev.deselectedConversationUuidList,
        ];
        if (!checked) {
          deselectedConversationList.push(conversationUuid);
        } else {
          deselectedConversationList = deselectedConversationList?.filter?.(
            (item) => item !== conversationUuid
          );
        }
        handleConversationAction(
          CONVERSATION_ACTION_CODES.CONVERSATION_DESELECTION,
          deselectedConversationList
        );
        return {
          ...prev,
          selectedConversationList: [],
          deselectedConversationUuidList: deselectedConversationList,
        };
      });
    }
  };

  const handleSelectedConversationList = (
    checked: boolean,
    conversationUuid: string,
    conversationMentionId?: string
  ) => {
    if (
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION &&
      conversationMentionId
    ) {
      setConversationListState((prev) => {
        let updatedList = [...prev.selectedMentionConversationList];
        if (checked) {
          updatedList.push(conversationMentionId);
        } else {
          updatedList = updatedList?.filter?.(
            (item) => item !== conversationMentionId
          );
        }
        handleConversationAction(
          CONVERSATION_ACTION_CODES.CONVERSATION_SELECTION,
          updatedList
        );
        return {
          ...prev,
          selectedMentionConversationList: updatedList,
        };
      });
    } else {
      setConversationListState((prev) => {
        let updatedList = [...prev.selectedConversationList];
        if (checked) {
          updatedList.push(conversationUuid);
        } else {
          updatedList = updatedList?.filter?.(
            (item) => item !== conversationUuid
          );
        }
        handleConversationAction(
          CONVERSATION_ACTION_CODES.CONVERSATION_SELECTION,
          updatedList
        );
        return {
          ...prev,
          selectedConversationList: updatedList,
        };
      });
    }
  };

  const handleCheckBoxChange = useCallback(
    (checked: boolean, conversationUuid: string, conversationMentionId?: string) => {
      if (checkedSelectAll) {
        handleSelectedAllConversation(
          checked,
          conversationUuid,
          conversationMentionId
        );
      } else {
        handleSelectedConversationList(
          checked,
          conversationUuid,
          conversationMentionId
        );
      }
    },
    [checkedSelectAll, handleSelectedAllConversation, handleSelectedConversationList]
  );


  const isCheckBoxChecked = useCallback(
    (conversationUuid: string, conversationMentionId?: string) => {
      if (checkedSelectAll) {
        return selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION &&
          conversationMentionId
          ? !conversationListState.deselectedMentionConversationUuidList.includes(
              conversationMentionId
            )
          : !conversationListState.deselectedConversationUuidList.includes(
              conversationUuid
            );
      } else {
        return selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION &&
          conversationMentionId
          ? conversationListState.selectedMentionConversationList.includes(
              conversationMentionId
            )
          : conversationListState.selectedConversationList.includes(
              conversationUuid
            );
      }
    },
    [
      checkedSelectAll,
      selectedInboxTypeCode,
      conversationListState.deselectedMentionConversationUuidList,
      conversationListState.deselectedConversationUuidList,
      conversationListState.selectedMentionConversationList,
      conversationListState.selectedConversationList,
    ]
  );


  const conversationListMarginBottom = useCallback(() => {
    return isEmailChannelInboxType(selectedInboxTypeCode) || isEFaxChannelInbox(selectedInboxTypeCode)
      ? enableConversationSelection
        ? 20
        : 10
      : enableConversationSelection
      ? 10
      : 0;
  }, [selectedInboxTypeCode, enableConversationSelection]);

  const styles = React.useMemo(() => getStyles({}), []);
  const cssProperties = React.useMemo(() => getCSSProperties({finalListHeight: finalListHeight}), [finalListHeight]);


  return (
    <View ref={conversationListRef} style={isSidecarContext? styles.conversationListContainerSidecarStyles : styles.conversationListContainerStyles}>
      {conversationListState.isLoadingConversations ? (
        <ConversationListLoader
          listCount={10}
          selectedInboxTypeCode={selectedInboxTypeCode}
        />
      ) : (
        <View>
          {conversationListState.conversationList &&
          conversationListState.conversationList.length ? (
            <div style={cssProperties.renderConversationBlock}>
              <RenderConversationBlock conversationListMarginBottom={conversationListMarginBottom} getFinalConversationLists={getFinalConversationLists} conversationListState={conversationListState} selectedInboxTypeCode={selectedInboxTypeCode} isSidecarContext={isSidecarContext} enableConversationSelection={enableConversationSelection} isCheckBoxChecked={isCheckBoxChecked} handleCheckBoxChange={handleCheckBoxChange} userId={userId} onConversationListItemClick={onConversationListItemClick} conversationListWidth={conversationListWidth} searchString={searchString} allInboxList={allInboxList} selectedSubtabCode={props.selectedSubtabCode} loadMoreConversations={loadMoreConversations} ></RenderConversationBlock>
            </div>
          ) : (
            <View
              flex={1}
              style={styles.noDataFoundMainContainer}
            >
              <View style={styles.noDataFoundContainer}>
                <NoDataFound
                  textStyle={styles.noDataFoundText}
                  displayString={getNodataMessage()}
                />
                {!hideCreateNewConversationByInboxType() && (
                  <View mt={5}>
                    {selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_ALL ? (
                      <>
                        <FoldButton
                          nativeProps={{
                            variant: BUTTON_TYPE.PRIMARY,
                            onPress: handleNoConversation.bind(null, selectedInboxTypeCode),
                          }}
                          customProps={{
                            btnText: intl.formatMessage({
                              id: startConversationBtnText() || '',
                            }),
                            withRightBorder: false,
                          }}
                        ></FoldButton>
                      </>
                    ) : (
                      <Dropdown
                        overlay={
                          <Menu
                            items={[
                              {key: '1', label: <a>Start Chat</a>},
                              {
                                key: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
                                label: (
                                  <a>
                                    {startConversationBtnText(
                                      CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS
                                    )}
                                  </a>
                                ),
                              },
                              {
                                key: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
                                label: (
                                  <a>
                                    {startConversationBtnText(
                                      CHANNEL_TYPE_CODE.CHANNEL_EMAIL
                                    )}
                                  </a>
                                ),
                              },
                              {
                                key: CHANNEL_TYPE_CODE.CHANNEL_INTERNAL,
                                label: <a>Start Internal Chat</a>,
                              },
                            ]}
                            onClick={(data: any) => {
                              handleNoConversation(data.key);
                            }}
                          />
                        }
                        placement="bottomRight"
                      >
                        <Button variant={BUTTON_TYPE.PRIMARY}>
                          <DisplayText
                            textLocalId={'newConversations'}
                            testID="newConversations"
                            size={'smMedium'}
                            extraStyles={{
                              color: Colors.Custom.mainPrimaryPurple || '',
                            }}
                          />
                        </Button>
                      </Dropdown>
                    )}
                  </View>
                )}
              </View>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

function useDeepEffect(fn: any, deps: any) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isFirstEffect = isFirst.current;
    const isSame = prevDeps.current.every((obj: any, index: any) =>
      isEqual(obj, deps[index])
    );

    isFirst.current = false;
    prevDeps.current = deps;

    if (isFirstEffect || !isSame) {
      return fn();
    }
  }, deps);
}

export default memo(ConversationList);
