import {ANALYTICS_RESOURCE, SECTION_LIST} from "../../../../../constants";



export const TEMP_HIDE_SECTIONS = [
  SECTION_LIST.EVENTS_CONDUCTED,
  SECTION_LIST.WINS,
  SECTION_LIST.OPPORTUNITIES,
  ANALYTICS_RESOURCE.PROJECTED_ROI,
  ANALYTICS_RESOURCE.CLAIMS_AVOIDED,
  ANALYTICS_RESOURCE.EMPLOYEE_ENROLLMENT_RETENTION,
  ANALYTICS_RESOURCE.CARE_JOURNEY_ADHERENCE,
  ANALYTICS_RESOURCE.MEDICATION_ADHERENCE,
  ANALYTICS_RESOURCE.CHRONIC_RISKS,
];


export const TEMP_TESTING_EMPLOYEE = 100

export const EMPLOYER_CONST = {
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
  COLOR: 'COLOR',
  BG_IMAGE: 'BG_IMAGE'
}

export const MEMBER_CONST = {
  ALL_MEMBERS: 'ALL_MEMBERS',
  ALL_EMPLOYEES: 'ALL_EMPLOYEES',
}

export const TEMP_DATE_RANGE_FOR_DASHBOARD = [
  {
    id: 1,
    name: 'Month',
    code: EMPLOYER_CONST.MONTH
  },
  {
    id: 2,
    name: 'Quarter',
    code: EMPLOYER_CONST.QUARTER
  },
  {
    id: 3,
    name: 'Year',
    code: EMPLOYER_CONST.YEAR
  }
]

export const TEMP_MEMBER_TYPE_FOR_DASHBOARD = [
  {
    id: 1,
    name: 'All Members',
    code: MEMBER_CONST.ALL_MEMBERS
  },
  {
    id: 2,
    name: 'All Employees',
    code: MEMBER_CONST.ALL_EMPLOYEES
  },
]

export const WIDGET_ACTION_CODE = {
  DOWNLOAD_WIDGET: 'DOWNLOAD_WIDGET',
  OPEN_CUSTOMER_SATISFACTION_MODAL: 'OPEN_CUSTOMER_SATISFACTION_MODAL',
  OPEN_SAVING_MODAL: 'OPEN_SAVING_MODAL',
  HIDE_SECTION: 'HIDE_SECTION',
  UPDATE_FORM: 'UPDATE_FORM',
  SELECT_LOCATION: 'SELECT_LOCATION',
  RESET_LOCATION_FILTER: 'RESET_LOCATION_FILTER',
  FORM_LIST_VIEW: 'FORM_LIST_VIEW',
  OPEN_SAVING_DRILL_DOWN: 'OPEN_SAVING_DRILL_DOWN',
  WIDGET_LOADED: 'WIDGET_LOADED'
}

export const WIDGET_ACTIONS = {
  id: 1,
  name: 'Save as image',
  code: WIDGET_ACTION_CODE.DOWNLOAD_WIDGET
}
export const LOCATION_FILTER = {
  id: 3,
  name: 'Filter by location',
  code: WIDGET_ACTION_CODE.SELECT_LOCATION
}
export const RESET_LOCATION_FILTER = {
  id: 3,
  name: 'Reset Location Filter',
  code: WIDGET_ACTION_CODE.RESET_LOCATION_FILTER
}

export const WIDGET_ACTIONS_FORM_UPDATE = {
  id: 2,
  name: 'Change Form',
  code: WIDGET_ACTION_CODE.UPDATE_FORM
}

export const SECTION_UPDATE_TYPE = {
  HIDE_SHOW: 'HIDE_SHOW',
  SEQUENCE_CHANGE: 'SEQUENCE_CHANGE'
}


export const VIEW_CODE = {
  UPDATE_SECTION_VIEW: 'UPDATE_SECTION_VIEW',
  GROUP_MEMBER_VIEW: 'GROUP_MEMBER_VIEW',
  SAVING_DRILL_DOWN: 'SAVING_DRILL_DOWN',
  CUSTOMER_SATISFACTION_DRAWER: 'CUSTOMER_SATISFACTION_DRAWER',
  TIME_SAVED_AUTOMATION_DRAWER: 'TIME_SAVED_AUTOMATION_DRAWER',
  ADD_WIDGET: 'ADD_WIDGET',
  CREATE_POWER_BI_REPORT:'CREATE_POWER_BI_REPORT'
}

export const VERSES_WIDGETS = [
  ANALYTICS_RESOURCE.CHAT_MESSAGING,
  ANALYTICS_RESOURCE.VOICE_CALLING,
  ANALYTICS_RESOURCE.EMPLOYEE_WELLNESS_INDEX,
  ANALYTICS_RESOURCE.SYMPTOMS_REPORTED,
  ANALYTICS_RESOURCE.NO_SHOWS,
];


export const INFO_TEXT = {
  EMPLOYEE_COUNT: 'Total lives at organization count includes active /n membership Employees and their dependence.',
  ENROLLED_FOR_CARE: 'Engagement for care shows a member count who has booked appointment at least once during the selected date range.',
  CUSTOMER_SATISFACTION: 'Customer satisfaction percentage is basically a average response of satisfaction survey form. ',
  CHRONIC_RISKS_TREND: 'Chronic risks shows the patient having at least one or two chronic conditions along with the top 10 chronic conditions. Chronic condition are identified based on diagnosis code(ICD10).',
  TOTAL_SAVINGS: 'Total saving is the savings done by DPC across the Labs, Imaging, Procedure, and Visits for the selected duration.',
  ERX_SAVINGS: `Savings done by DPC by dispensing a medicines through in-house pharmacy/doctors to employee. This savings are calculated based on the cost entered by the DPC vs Traditional/Market cost. In case market cost is not entered that record will not consider for the calculation.

Medications are referred from the EHR med orders, and based on NDC code grouping of medication is done to show quantity. `,
  LAB_SAVINGS: `Saving are calculated based on Lab orders done for the selected employer employees during the selected duration and DPC price vs Market cost.

Labs are referred from the EHR Lab orders for selected duration and it is grouped on the basis of CPT code associated with lab for identifying the quantity.`
}



export const APPLIED_PREVIOUS_RANGE = [
  ANALYTICS_RESOURCE.ERX_SAVINGS,
  ANALYTICS_RESOURCE.IMAGING_SAVINGS,
  ANALYTICS_RESOURCE.LAB_SAVINGS,
  ANALYTICS_RESOURCE.PROCEDURES_SAVINGS,
  ANALYTICS_RESOURCE.VISITS_SAVINGS,
  ANALYTICS_RESOURCE.TOTAL_SAVINGS,
];

export const EMPLOYEE_CODE = 'employee';

export const DATA_TYPE = {
  STRING: 'STRING',
  DATE: 'DATE',
  DURATION: 'DURATION'
}
export const CURRENT_FORMAT = {
  MIN: 'MIN',
  SEC: 'SEC'
}

export const COST_TYPE_WORDS = [
  'cost',
  'amount',
  'revenue',
  'member spend',
  'non member spend'
]

export const INPATIENT_CLAIMS_CODE_PDF_LINK = `https://foldhealth-my.sharepoint.com/:b:/g/personal/rajt_fold_health/EeLxOfLlUUZLi-bQNWd1XuUBcLCT7J9buCxjduvJLvXmLQ?e=WKC0kA`

export const OUTPATIENT_CLAIMS_CODE_PDF_LINK = `https://foldhealth-my.sharepoint.com/:b:/g/personal/rajt_fold_health/EZjrK_xV7XxKl9sWMeQ-rbUBTdOLNWsXLNjrx-T6BFd7uw?e=XGSe2E`

export const EMERGENCY_DEPARTMENT_CLAIMS_CODE_PDF_LINK = `https://foldhealth-my.sharepoint.com/:b:/g/personal/rajt_fold_health/EVQBH1RiKKxMv20RL9wEz-4B3I0Yr7ntGF-EWQvp9bhnBA?e=paXp7A`

export const EMPLOYER_DASHBOARD_TABS = [
  {
    key: 'OVERVIEW',
    title: 'Member Overview',
    childSection: ['ALL'],
  },
  {
    key: 'ENGAGEMENT',
    title: 'Engagement',
    childSection: [
      SECTION_LIST.OVERVIEW,
      SECTION_LIST.APP_ANALYTICS_ENGAGEMENT,
      SECTION_LIST.PATIENT_ENCOUNTERS,
    ],
  },
  {
    key: 'FINANCIAL',
    title: 'Financial',
    childSection: [
      SECTION_LIST.SAVINGS,
      SECTION_LIST.CLAIMS_COST_ANALYSIS,
      SECTION_LIST.AVOIDABLE_EMERGENCY_VISITS,
    ],
  },
  {
    key: 'CLINICAL',
    title: 'Clinical',
    childSection: [
      SECTION_LIST.PATIENT_STATISTICS,
      SECTION_LIST.UTILIZATION
    ],
  },
  {
    key: 'OPERATIONS',
    title: 'Operations',
    childSection: [
      SECTION_LIST.OPERATIONS,
    ],
  }
];


export const showTabsStructure = true

export const DRILL_DOWN_RESOURCE_CODE = {
  CONTACT: 'contact',
  APPOINTMENT: 'APPOINTMENT',
  COMMON_COMMUNICATION: 'COMMON_COMMUNICATION',
  APP_CHAT: 'APP_CHAT',
  SMS_CHAT: 'SMS_CHAT',
  VOICE_CALL: 'VOICE_CALL',
  VIDEO_CALL: 'VIDEO_CALL',
  CHRONIC_CONDITIONS: 'CHRONIC_CONDITIONS',
  LABS: 'LABS',
  IMAGING: 'IMAGING',
  MEDICATION: 'MEDICATIONS',
  PROCEDURE: 'PROCEDURE',
  CLAIMS: 'CLAIMS',
  OUT_OF_OFFICE_HOURS_SAVINGS: 'OUT_OF_OFFICE_HOURS_SAVINGS',
  OFFICE_HOURS_SAVINGS: 'OFFICE_HOURS_SAVINGS',
  FORM_COUNT: 'FORM_COUNT',
  TASK_COUNT: 'TASK_COUNT',
  APPOINTMENT_COUNT: 'APPOINTMENT_COUNT',
  DOCUMENT_REFERENCE_COUNT: 'DOCUMENT_REFERENCE_COUNT',
  VIDEO_CALL_DURATION: 'VIDEO_CALL_DURATION',
  CALLS_COUNTS: 'CALLS_COUNTS',
  COUNT: 'COUNT'
};

export const SAVING_WIDGET_LIST = [
  ANALYTICS_RESOURCE.LAB_SAVINGS,
  ANALYTICS_RESOURCE.TOTAL_SAVINGS,
  ANALYTICS_RESOURCE.VISITS_SAVINGS,
  ANALYTICS_RESOURCE.PROCEDURES_SAVINGS,
  ANALYTICS_RESOURCE.IMAGING_SAVINGS,
  ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING,
  ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
]

export const OFFICE_HOURS_CODE_DISPLAY_TEXT = [
  {
    colorType: 'APPOINTMENTS_OFFICE_HOURS',
    code: 'APPOINTMENTS_OFFICE_HOURS',
    displayText: 'Number of Appointments During Office Hours ',
  },
  {
    colorType: 'VIDEO_CALLS_OFFICE_HOURS',
    code: 'VIDEO_CALLS_OFFICE_HOURS',
    displayText: 'Number of Video Calls During Office Hours ',
  },
  {
    colorType: 'VOICE_CALLS_OFFICE_HOURS',
    code: 'VOICE_CALLS_OFFICE_HOURS',
    displayText: 'Number of Voice Calls During Office Hours ',
  },
  {
    colorType: 'MESSAGES_OFFICE_HOURS',
    code: 'MESSAGES_OFFICE_HOURS',
    displayText: 'Number of Messages During Office Hours',
  },
  {
    colorType: 'VISIT_NOTES_OFFICE_HOURS',
    code: 'VISIT_NOTES_OFFICE_HOURS',
    displayText: 'Visit Notes Office Hours',
  },
];
export const OUT_OF_OFFICE_HOURS_CODE_DISPLAY_TEXT = [
  {
    colorType: 'APPOINTMENTS_OUT_OF_OFFICE_HOURS',
    code: 'APPOINTMENTS_OUT_OF_OFFICE_HOURS',
    displayText: 'Number of Appointments During After Hours'
  },
  {
    colorType: 'VIDEO_CALLS_OUT_OF_OFFICE_HOURS',
    code: 'VIDEO_CALLS_OUT_OF_OFFICE_HOURS',
    displayText: 'Number of Video Calls During After Hours',
  },
  {
    colorType: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
    code: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
    displayText: 'Number of Voice Calls During After Hours'
  },
  {
    colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    displayText: 'Number of Messages During After Hours'
  },
  {
    colorType: 'VISIT_NOTES_OUT_OF_OFFICE_HOURS',
    code: 'VISIT_NOTES_OUT_OF_OFFICE_HOURS',
    displayText: 'Number of Visit Notes During After Hours'
  }
]

export const OFFICE_HOURS_CONST = {
  OFFICE_HOURS: 'OFFICE_HOURS',
  OUT_OF_OFFICE_HOURS: 'OUT_OF_OFFICE_HOURS'
}

export const AddRendererToCanvass = [
  ANALYTICS_RESOURCE.EMPLOYEE_COUNT
]

export const WidgetLegendMap: {[index: string]: {[innerIndex: string]: string}} = {
  [ANALYTICS_RESOURCE.CRM_ACTIVITY]: {
    'message': 'Messages',
    'task': 'Tasks',
    'form_response': 'Completed Forms',
    'video_call': 'Video Calls',
    'call': 'Voice Calls'
  },
  [ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT]: {
    'APPOINTMENTS_OFFICE_HOURS': 'Number of Appointments During Office Hours ',
    'VIDEO_CALLS_OFFICE_HOURS': 'Number of Video Calls During Office Hours',
    'VOICE_CALLS_OFFICE_HOURS': 'Number of Voice Calls During Office Hours',
    'MESSAGES_OFFICE_HOURS': 'Number of Messages During Office Hours',
    'VISIT_NOTES_OFFICE_HOURS': 'Number of Visit Notes During Office Hours',
    'APPOINTMENTS_OUT_OF_OFFICE_HOURS': 'Number of Appointments During After Hours',
    'VIDEO_CALLS_OUT_OF_OFFICE_HOURS': 'Number of Video Calls During After Hours',
    'VOICE_CALLS_OUT_OF_OFFICE_HOURS': 'Number of Voice Calls During After Hours',
    'MESSAGES_OUT_OF_OFFICE_HOURS': 'Number of Messages During After Hours',
    'VISIT_NOTES_OUT_OF_OFFICE_HOURS': 'Number of Visit Notes During After Hours',
  },
  [ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE]: {
    'Outgoing Calls': 'Outbound Calls',
    'Incoming Calls': 'Inbound Calls'
  },
  [ANALYTICS_RESOURCE.TELEPHONE_BY_DAY]: {
    'Outgoing Calls': 'Outbound Calls',
    'Incoming Calls': 'Inbound Calls'
  },
  [ANALYTICS_RESOURCE.TELEPHONE_BY_TIME]: {
    'Outgoing Calls': 'Outbound Calls',
    'Incoming Calls': 'Inbound Calls'
  },
};

export const costSavingKeyNameByResourceCode = (resourceCode: string) => {
  return  resourceCode == 'ERX_SAVINGS'
    ? 'erxCostDatalist'
    : resourceCode == 'IMAGING_SAVINGS'
      ? 'imagingCostDatalist'
      : resourceCode == 'LAB_SAVINGS'
        ? 'labCostDatalist'
        : resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
          ? 'visitCostDatalist'
          : 'procedureCostDatalist';
}

export const ALL_MEMBERS = 'All Members';
export const ALL_MEMBERS_OBJ = {
  id: ALL_MEMBERS,
  name: ALL_MEMBERS,
};

export const EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL: Record<string, string> = {
  employee: 'Employees',
  selfsponsored: 'Self-Sponsored Members',
  oneoff: 'One-Off',
  dependent: 'Dependents',
  appointment: 'Appointments',
  patient: 'Patients',
  child: 'Children',
  spouse: 'Spouse',
  sms: 'SMS\'s',
  email: 'Email\'s',
  promoters: 'Promoters',
  passives: 'Passives',
  detractors: 'Detractors',
  sentbypractice: 'Messages sent by practice',
  sentbymembers: 'Messages sent by members',
  outboundcalls: 'Outbound Calls',
  inboundcalls: 'Inbound Calls',
  notresponded: 'Not Responded',
  responded: 'Responded',
  patientsnotseen: 'Not Seen',
  patientsnotengagedforcare: 'Not Engaged',
  totalformsent: 'Total form sent',
  member: 'Members'
};

export const EMPLOYER_DRILL_DOWN_SUBTITLE_SINGULAR: Record<string, string> = {
  employee: 'Employee',
  selfsponsored: 'Self-Sponsored Member',
  oneoff: 'One-Off',
  dependent: 'Dependent',
  appointment: 'Appointment',
  patient: 'Patient',
  child: 'Child',
  spouse: 'Spouse',
  sms: 'SMS\'s',
  email: 'Email',
  promoters: 'Promoter',
  passives: 'Passive',
  detractors: 'Detractor',
  sentbypractice: 'Message sent by practice',
  sentbymembers: 'Message sent by member',
  outboundcalls: 'Outbound Call',
  inboundcalls: 'Inbound Call',
  notresponded: 'Not Responded',
  responded: 'Responded',
  patientsnotseen: 'Not Seen',
  patientsnotengagedforcare: 'Not Engaged',
  totalFormSent: 'Total form sent',
  member: 'Member'
};
