import {View, Text} from "react-native"
import RefreshSvg from "../../common/Svg/RefreshSvg"
import {Colors} from "../../../styles"
import {Skeleton} from "native-base"
import {useIntl} from "react-intl"

export const ExecutionDate = (props: {date: string, loading: boolean})=> {
  const intl = useIntl();
  return (
    <View style={{
      marginLeft: 23.5,
      marginTop: 14,
      alignItems: 'center',
      flexDirection: 'row'
    }}>
      <Text style={{
        color: Colors.FoldPixel.GRAY200,
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 14.4,
      }}>
        {intl.formatMessage({id: 'dataRefreshed'})}
      </Text>
      {props?.loading ? (
        <Skeleton.Text width={150} lines={1}/>
      ) : <>
      <Text style={{
        color: Colors.FoldPixel.GRAY200,
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 14.4,
      }}>
        {props?.date || "-"}
      </Text>
      <View style={{
        marginLeft: 6,
      }}>
        <RefreshSvg />
      </View>
      </>}
    </View>
  )
}