import React, { useContext, useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { IAvailabilityCalendarData, IAvailabilityCalendarTableData, IAvailabilityCalendarTablePrimaryData, IPracticeAvailability, IUserAvailability } from './PracticeAvailabilityInterfaces';
import { AVAILABILITY_CALENDAR_WEEK_COLUMN_SEQUENCE, AVAILABILITY_CALENDAR_WEEK_COLUMN_VALUES, convertAvailabilityCalendarDataIntoTableData } from './PracticeAvailabilityHelper';
import { Box, Center, Divider, HStack, Icon, Pressable, ScrollView, Spacer, Text, View, VStack } from 'native-base';
import { Dimensions } from 'react-native';
import { convertDate } from '../../../../utils/DateUtils';
import { useIntl } from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../styles';
import { DATE_FORMATS } from '../../../../constants';
import { Pagination } from 'antd';
import FHActionPopover from '../../../common/FHActionPopover/FHActionPopover';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import { PracticeAvailabilityOverrideCard } from './PracticeAvailabilityOverrideCard';
import { TeamMemberProfile } from '../../Contacts/TeamMembers/TeamMembersTable/TeamMemberProfile';
import { getBooleanFeatureFlag, getCurrentUserRole, getUserUUID, isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner } from '../../../../utils/commonUtils';
import { USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';

interface IProps {
  searchUserText?: string;
  isReadOnly?: boolean;
  selectedAccountLocationId?: string;
  availabilityCalendarData: IAvailabilityCalendarData[];
  isUserSchedule: boolean;
  isInboxHours: boolean;
  removeHeight?: boolean
  onEditAvailabilities: (data: IAvailabilityCalendarTableData) => void;
  onDeleteAvailabilities: (data: IAvailabilityCalendarTableData) => void;
  onDeleteOverrideAvailabilities: (data: IAvailabilityCalendarTableData) => void;
  setPaginationPage: (page: number) => void;
  paginationPage: number;
}

export function PracticeAvailabilityCalendarView(props: IProps) {
  const intl = useIntl();
  const context = useContext(CommonDataContext);
  const currentUserRoles = getCurrentUserRole();
  const isBusinessOwner = isLoginUserBusinessOwner() || isLoggedInUserGlobalAdmin() || isLoggedInUserWorkFlowOrCustomerSuccess()
  const isMultiTenancyEnabled = getBooleanFeatureFlag(context?.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const loggedInUserUUID = getUserUUID();
  const {height} = Dimensions.get('window');
  const columns: ColumnsType<IAvailabilityCalendarTableData> = [
    {
      title: (props.isUserSchedule ? 'Users' : 'Locations'),
      dataIndex: 'primaryData',
      render: renderPrimaryData,
    },
    ...(AVAILABILITY_CALENDAR_WEEK_COLUMN_SEQUENCE.map((weekCode) => {
      return {
        title: [AVAILABILITY_CALENDAR_WEEK_COLUMN_VALUES[weekCode]],
        dataIndex: weekCode,
        render: renderAvailabilityCellData,
      };
    }))
  ];

  const tableData = convertAvailabilityCalendarDataIntoTableData(props.availabilityCalendarData);

  const defaultpageSizeOptions = {
    page: 1,
    pageSize: 10,
    total: tableData.length
  };
  const [paginationData, setPaginationData] = useState(defaultpageSizeOptions);
  const [offsetTabledata, setOffsetTabledata] = useState(tableData.slice(0, 10));

  function noDataAvailableElement() {
    return (
      <Center
        p={1}
        height={'full'}
      >
        <Feather
          name="clock"
          color={Colors.Custom.IconColorGray}
          size={22}
        />
        <Text color={Colors.FoldPixel.GRAY250} textAlign={'center'}>
          {'No schedule'}
        </Text>
      </Center>
    );
  }

  function renderAvailabilityCellData(data: IUserAvailability[], record: IAvailabilityCalendarTableData) {
    return (
      <View height="full">
        {
          !data?.length &&
          noDataAvailableElement()
        }
        {
          data.length > 0 &&
          <VStack height="full">
          {
            data.map((availability, index) => (
              <VStack key={availability.id} space={2}>
                <VStack space={1}>
                  <Text color={Colors.FoldPixel.GRAY400} fontSize={'xs'}>
                    {`${convertDate(
                      availability.startTime || '',
                      DATE_FORMATS.API_TIME_FORMAT,
                      DATE_FORMATS.DISPLAY_TIME_FORMAT
                    )} - ${convertDate(
                      availability.endTime || '',
                      DATE_FORMATS.API_TIME_FORMAT,
                      DATE_FORMATS.DISPLAY_TIME_FORMAT
                    )}`}
                  </Text>
                  {
                    availability.timezone &&
                    <Text
                      fontSize={'xs'}
                      color={Colors.FoldPixel.GRAY250}
                    >
                      {availability.timezone.displayValue}
                    </Text>
                  }
                  {
                    record.primaryData.isUserSchedule &&
                    (availability.isVirtualLocation || availability?.locationData?.practiceLocation?.name) &&
                    <HStack alignItems={'center'} space={1}>
                      <Icon
                        as={MaterialIcon}
                        name="location-on"
                        size={'xs'}
                        color={Colors.Custom.IconColorGray}
                        ml={0}
                      />
                      <Text
                        fontSize={'xs'}
                        color={Colors.Custom.Gray500}
                      >
                        {availability.isVirtualLocation ? 'Virtual' : availability?.locationData?.practiceLocation.name}
                      </Text>
                    </HStack>
                  }
                </VStack>
                {index !== (data.length - 1) && (
                  <Divider marginBottom={2}/>
                )}
              </VStack>
            ))
          }
          </VStack>
        }
      </View>
    );
  }


  function renderPrimaryData(data: IAvailabilityCalendarTablePrimaryData, record: IAvailabilityCalendarTableData) {
    const actions = [];
    let canHideAllActions = false;
    const isLoggedInUserAvailability = loggedInUserUUID === data?.userId;

    if (isMultiTenancyEnabled && !isLoggedInUserAvailability) {
      canHideAllActions = !isBusinessOwner;
    }

    if (!props.isReadOnly && !canHideAllActions) {
      actions.push({
        code: 'EDIT',
        name: 'Edit',
      });
    }

    if (!props.isReadOnly && isBusinessOwner && !canHideAllActions && tableData?.length > 1 && (!props.isUserSchedule || !data.isPracticeScheduleApplied)) {
      actions.push({
        code: 'DELETE',
        name: 'Delete',

      });
    }
    return (
      <VStack space={1}>
        <HStack>
          <View flex={9} height="full">
            {
              data.isUserSchedule &&
              <TeamMemberProfile singleUserData={data.userData as any} fullName={data.userData?.name || ''} showActiveStatus={false} />
            }
            {
              !data.isUserSchedule &&
              <Text color={Colors.FoldPixel.GRAY400} textTransform={'capitalize'} fontSize={'md'}>{data.locationData?.practiceLocation?.name}</Text>
            }
          </View>
          {actions.length > 0 && (
          <HStack flex={1}>
            <Spacer />
            <Pressable>
              <FHActionPopover
                isPrimary={true}
                actions={actions}
                onActionClick={(code, event) => {
                  if (code === 'EDIT') {
                    props.onEditAvailabilities(record);
                  } else if (code === 'DELETE') {
                    props.onDeleteAvailabilities(record);
                  }
                }}
              />
            </Pressable>
          </HStack>
          )}
        </HStack>

        <View>
          {
            props.isUserSchedule &&
            data.isPracticeScheduleApplied &&
            <Text color={Colors.Custom.Gray400} fontSize={'xs'}>
              {'Same as practice schedule'}
            </Text>
          }
        </View>

        {
          data.overriddenAvailabilitiesByDateRangeKey?.isAvailabilitiesPresent &&
          <PracticeAvailabilityOverrideCard
            availabilitiesByDateRangeKey={data.overriddenAvailabilitiesByDateRangeKey}
            onEditAvailabilities={() => {
              props.onEditAvailabilities(record);
            }}
            onDeleteAvailabilities={() => {
              props.onDeleteOverrideAvailabilities(record);
            }}
          />
        }


      </VStack>
    );
  }

  const setOffsetData = (page: number, pageSize: number) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setOffsetTabledata(tableData.slice(startIndex, endIndex))
  }

  useEffect(() => {
    setOffsetData(paginationData.page, paginationData.pageSize);
    setPaginationData(prev => ({
      ...prev,
      total: tableData.length,
    }))
  }, [paginationData.page, tableData.length])

  useEffect(() => {
    setPaginationData(prev => ({
      ...prev,
      page: props?.paginationPage || 1
    }))
    setOffsetData(props?.paginationPage, paginationData.pageSize);
  }, [props?.paginationPage])

  return (
    <VStack space={3}>
      <HStack
        borderWidth={1}
        borderColor={Colors.Custom.BorderColor}
        shadow="3"
        height={12}
        alignItems={'center'}
        borderRadius={'lg'}
      >
        {
          columns.map((column, index) => {
            const isFirstColumn = index === 0 ;
            return <View
              key={column.title as string || index}
              flex={isFirstColumn ?  1.5 : 1}
              alignItems={'left'}
              backgroundColor={isFirstColumn ? Colors.Custom.DisplayCardBGColor : '#fff'}
              borderRightWidth={1}
              borderColor={Colors.Custom.BorderColor}
              height={'full'}
              justifyContent={'center'}
              padding={2}
              borderLeftRadius={isFirstColumn ? 'lg' : 0}
            >
              <Text color={Colors.FoldPixel.GRAY400}>{column.title}</Text>
            </View>
          })
        }
      </HStack>
      <ScrollView height={props.removeHeight ? 200 :  height - 256}>
        <VStack space={3}>
          {
            (props?.isUserSchedule ? offsetTabledata : tableData)?.length > 0 &&
            (props?.isUserSchedule ? offsetTabledata : tableData)?.map((data, dataIndex) => {
              let showRow = true;
              if (props.isInboxHours && props.selectedAccountLocationId) {
                showRow = (data.primaryData?.locationData?.uuid === props.selectedAccountLocationId);
              } else if (!props.isUserSchedule && !props.isInboxHours && props.searchUserText) {
                const locationName = data.primaryData.locationData?.practiceLocation?.name;
                if (locationName) {
                  showRow = locationName.toLocaleLowerCase().includes(props.searchUserText);
                }
              }

              if (!showRow) {
                return <></>;
              }

              return (
                <HStack
                  key={data.key}
                  borderWidth={1}
                  borderColor={Colors.Custom.BorderColor}
                  shadow="3"
                  borderRadius={'lg'}
                  minHeight={'100px'}
                >
                  {
                    columns.map((column: any, columnIndex) => {
                      const isFirstColumn = columnIndex === 0 ;
                      return (
                        <View
                          key={column.title as string || columnIndex}
                          flex={isFirstColumn ?  1.5 : 1}
                          backgroundColor={isFirstColumn ? Colors.Custom.DisplayCardBGColor : '#fff'}
                          borderRightWidth={1}
                          borderColor={Colors.Custom.BorderColor}
                          padding={2}
                          alignItems={'left'}
                          borderLeftRadius={isFirstColumn ? 'lg' : 0}
                        >
                          {column.render(data[(column as any).dataIndex], data, dataIndex)}
                        </View>
                      )
                    })
                  }
                </HStack>
              )
            })
          }
          {
            !tableData?.length &&
            <View
              alignItems={'center'}
              justifyContent={'center'}
              height={'130px'}
              borderWidth={1}
              borderColor={Colors.Custom.BorderColor}
              borderRightWidth={1}
              width='full'
            >
              {noDataAvailableElement()}
            </View>
          }
        </VStack>
      </ScrollView>
      {props?.isUserSchedule && <Pagination
        total={paginationData.total}
        current={paginationData.page}
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}
        showSizeChanger={true}
        pageSize={paginationData.pageSize}
        onChange={(page, pageSize) => {
          setPaginationData(prev => ({
            ...prev,
            page: page,
            pageSize: pageSize,
          }));
          props?.setPaginationPage(page);
        }}
      />}
    </VStack>
  );
}
