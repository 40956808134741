import {Drawer} from 'antd';
import {
  Button,
  HStack,
  Image,
  Pressable,
  Radio,
  Spinner,
  Text,
  Tooltip,
  useMediaQuery,
  View,
  VStack,
} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MODULE_PAGINATION_COUNT,
} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {getProducts} from '../../../../../services/Stripe/StripeServices';
import {Colors} from '../../../../../styles/Colors';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {formateStripeProductListData} from '../../ProductsAndServices/Products/ProductTableView/ProductUtils/ProductTableUtils';

const MemberListDrawer = (props: any) => {
  const [showModal, setShowModal] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [plan, setPlan] = useState({
    price: '',
    plan: ''
  });

  const [productTableState, setProductTableState] = useState({
    isLoading: true,
    isNoDataFound: false,
    selectedProduct: {} as any,
    searchString: props.searchText,
    productList: [] as any[],
    productDisplayList: [] as any[],
    page: 1,
    pageSize: MODULE_PAGINATION_COUNT.PRODUCTS_LIST_PAGE_SIZE,
  });

  const closeModal = () => {
    setShowModal(false);
    props.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL);
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const {width} = Dimensions.get('window');

  const finalWidth = isIPadMiniScreen || isIPadScreen ? width / 2 : width / 3;

  useEffect(() => {
    fetchProducts();
  }, []);

  const enablePackageConfig = isAccountConfigEnabled(CONFIG_CODES.ENABLE_JOURNEY_PACKAGE)

  const fetchProducts = async () => {
    setProductTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const productResponse = await getProducts(props.searchText, enablePackageConfig).catch(
      (error) => {
        setProductTableState((prev) => {
          return {
            ...prev,
            isLoading: false,
            isNoDataFound: true,
          };
        });
      }
    );
    if (
      productResponse?.data?.length > 0 ||
      productResponse?.data?.data?.length > 0
    ) {
      const productList =
        productResponse?.data?.length > 0
          ? productResponse
          : productResponse?.data?.data?.length > 0
          ? productResponse?.data
          : [];
      const tempProductDisplayList = formateStripeProductListData(productList);
      setProductTableState((prev) => {
        return {
          ...prev,
          productDisplayList: [...tempProductDisplayList],
          isLoading: false,
        };
      });
    } else {
      setProductTableState((prev) => {
        return {
          ...prev,
          productDisplayList: [],
          isLoading: false,
        };
      });
    }
  };

  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        onClose={() => closeModal()}
        closable={false}
        title={
          <ModalActionTitle
          title={'Browse Membership'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                closeModal();
              },
            },
          ]}
        />
        }
      >
        {productTableState?.isLoading ? (
          <Spinner
            size="lg"
            color="black"
            style={{
              margin: '0 auto',
              position: 'absolute',
              zIndex: 100011111,
              backgroundColor: '#0000000f',
              height: '100%',
              width: '100%',
              padding: 0,
            }}
          />
        ) : (
          <></>
        )}
        <View ml={0}>
          <VStack flex={1} marginTop={1}>
            {productTableState?.productDisplayList?.map((product: any) => {
              return !product.active ? <></> :  (
                <VStack
                  style={{
                    marginBottom: 20,
                    borderWidth: selectedProduct == product ? 1 : 0,
                    borderColor: Colors.primary[500],
                    borderRadius: 5,
                    padding: 5,
                  }}
                >
                  <Pressable
                    style={{
                      alignItems: 'center',
                      flexDirection: 'row',
                      display: 'flex',
                      width: '100%',
                    }}
                    onPress={() => {
                      setSelectedProduct(product);
                    }}
                  >
                    <HStack
                      style={{
                        flex: 1,
                      }}
                    >
                      <Image
                        style={{
                          width: 40,
                          height: 40,
                          marginRight: 10,
                        }}
                        source={require('../../../../../assets/images/GroupAvatar.png')}
                      />
                      <VStack
                        style={{
                          flex: 1,
                        }}
                      >
                        <Text
                          size={'smMedium'}
                          style={{
                            color: Colors.FoldPixel.GRAY400
                          }}
                        >
                          {product.name}
                        </Text>
                        <Text
                          size={'xsNormal'}
                          style={{
                            fontWeight: '300',
                            fontSize: 12,
                            color: Colors.FoldPixel.GRAY250
                          }}
                          numberOfLines={1}
                        >
                          {product.description}
                        </Text>
                      </VStack>
                    </HStack>
                  </Pressable>
                  {selectedProduct == product && (
                    <>
                      <Radio.Group
                        style={{
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          marginVertical: 5,
                        }}
                        // colorScheme={'secondary'}
                        name="StatusType"
                        flexDirection={'row'}
                        //value={stateData.formData.selectedStatusUuid}
                        onChange={(nextValue: any) => {
                          setPlan((prev)=>{
                            return {
                              ...prev,
                              price: product?.prices[nextValue]?.priceId ,
                              plan: nextValue
                            }
                          })
                        }}
                      >
                        {product.prices &&
                          Object.keys(product.prices).map(
                            (priceType: any, index: number) => {
                              return product.prices[priceType].price / 100 > 0 &&
                              (
                                <Radio
                                  colorScheme=""
                                  isHovered={false}
                                  isFocusVisible={false}
                                  isFocused={false}
                                  key={index}
                                  value={priceType}
                                  style={{marginVertical: 10, marginLeft: 5}}
                                >
                                  <Text
                                    size={'smMedium'}
                                    color={Colors.Custom.Gray700}
                                    style={{marginLeft: 8}}
                                  >
                                    {`$${
                                      product.prices[priceType].price / 100
                                    } ${priceType}`}
                                  </Text>
                                </Radio>
                              );
                            }
                          )}
                      </Radio.Group>
                      {plan.plan && (
                        <HStack justifyContent={'space-around'}>
                          {props.rowData?.contactData?.email ? (
                            <Tooltip label={'Send Email'} placement="top">
                              <Button
                                _hover={{bg: Colors.Custom.HoverBgColor}}
                                onPress={() => {
                                  props.onFormCompleteAction(
                                    COMMON_ACTION_CODES.MAIL,
                                    props.rowData,
                                    product,
                                    plan
                                  );
                                }}
                                // disabled={isEmailBtnDisabled()}
                                leftIcon={
                                  <AntIcon
                                    name="mail"
                                    style={[
                                      {
                                        marginLeft: 2,
                                        marginRight: 2,
                                        fontSize: 14.29,
                                        color: '#ffffff',
                                      },
                                      // emailStyleIcon()
                                    ]}
                                  />
                                }
                                style={{
                                  backgroundColor: Colors.primary[500],
                                }}
                              >
                                <Text color="#ffffff">Send Email</Text>
                              </Button>
                            </Tooltip>
                          ) : null}
                          {/* {props.rowData?.contactData?.phoneNumber ? (
                            <Tooltip label={'Send SMS'} placement="top">
                              <Button
                                onPress={() => {
                                  props.onFormCompleteAction(
                                    COMMON_ACTION_CODES.SMS,
                                    props.rowData,
                                    product,
                                    plan
                                  );
                                }}
                                // disabled={isNumberBtnDisabled()}
                                leftIcon={
                                  <Feather
                                    name="message-circle"
                                    size={17}
                                    style={{
                                      marginLeft: 2,
                                      marginRight: 2,
                                      fontSize: 14.29,
                                      color: '#ffffff',
                                    }}
                                  />
                                }
                                style={{
                                  backgroundColor: Colors.primary[500],
                                }}
                              >
                                <Text color="#ffffff">Send SMS</Text>
                              </Button>
                            </Tooltip>
                          ) : null} */}
                        </HStack>
                      )}
                    </>
                  )}
                </VStack>
              );
            })}
          </VStack>
        </View>
      </Drawer>
    </>
  );
};

export default MemberListDrawer;
