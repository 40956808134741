
import GaugeChartView from '../../GaugeCharts/GaugeCharts';
import {Colors} from '../../../../../../../styles';
import {Icon} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {IDpcPercentage} from '../interface';
import ArrowRightSvg from '../../../../../../common/Svg/ArrowRightSvg';
import PreVsPostDpcChart from './PreVsPostDpcChart';
import React from 'react';
import {IPreVSPostDPCWidgetProps} from './interface';
import ArrowDownSvg from '../../../../../../common/Svg/ArrowDownSvg';
import {View} from 'native-base';
import {useIntl} from 'react-intl';
import { LG_CONFIG, XL_CONFIG, XS_CONFIG } from '../../../../../../../theme/FontConfig/TextFontSizeConfig';
export const PreVSPostDPCWidget = (props: IPreVSPostDPCWidgetProps) => {
  const intl = useIntl();
  const finalResourceData: any = props?.stateData?.resourceData;
  const additionalData = finalResourceData?.additionalData;
  const dpcPercentageDataList: IDpcPercentage[] = additionalData?.dpcPercentageDataList;
  const getCountAndPercentageElem = (singleItem: IDpcPercentage) => {
    if (singleItem?.countNum) {
      const costStr = intl.formatNumber(singleItem?.countNum, {
        style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
      return (
        <div style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <div style={{
              color: singleItem?.isClaimCostDown ? Colors.Custom.SuccessColor : '#000',
              ...XL_CONFIG.xlBold
            }}>
              {costStr}
            </div>
            {
              singleItem?.percentage && (
                <div style={{
                  color: singleItem?.isClaimCostDown ? Colors.Custom.SuccessColor : '#000',
                  ...LG_CONFIG.lgBold,
                  marginLeft: 12,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <View size={18}>
                    {singleItem?.isClaimCostDown ? (
                      <Icon
                        color={Colors.Custom.SuccessColor}
                        as={AntIcon}
                        name={'arrowdown'}
                        size="5"
                      />
                    ) : (
                      <Icon
                        color={Colors.Custom.Danger700}
                        as={AntIcon}
                        name={'arrowup'}
                        size="5"
                      />
                    )}
                  </View>
                  <View style={{
                    marginLeft: '2px'
                  }}>
                    {singleItem?.percentage}%
                  </View>
                </div>
              )
            }
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{
            color: '#000',
            ...LG_CONFIG.lgBold,
          }}>No Data</div>
        </div>

      )
    }
  }
  const mainEleContainer = (
    <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <div style={{width: '15%'}}>
        <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: 12, justifyContent: 'space-between'}}>
          {dpcPercentageDataList?.map((item: any) => {
            return (
              <div style={{
                padding: 16,
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderStyle: 'solid',
                borderRadius: 8,
                marginBottom: 8,
                minWidth: '210px',
              }} className='score-detail-item'>
                <div style={{
                  color: Colors.Custom.Gray500,
                  ...XS_CONFIG.xsBold,
                }}>{item?.displayText}</div>
                <div style={{
                  color: '#000',
                  ...LG_CONFIG.lgBold,
                }}>{getCountAndPercentageElem(item)}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{width: '84%'}}>
        <div>
          <PreVsPostDpcChart
            resourceData={finalResourceData}
            onActionClick={props?.onActionClick}
            sectionData={props?.sectionData}
          />
        </div>
        <div style={{textAlign: 'center'}}>
          Week
        </div>
      </div>
    </div>);
  return mainEleContainer;
}

export default React.memo(PreVSPostDPCWidget);
