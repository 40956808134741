import {ANALYTICS_RESOURCE} from "../../../../constants";
import {COMMUNICATION_STATS_DUMMY_DATA} from "../../../../dummyData/EmployerReporting/communicationStatsDummyData";
import {PRE_DPC_VS_POST_DPC_DEMO_DATA} from "./SectionAndWidget/MixMultipleCharts/PreVsPostDpc/PreVsPostDpcDemoData";
import {ISectionListItem} from "./interface";

export const TEMP_CLINICAL_INTERVENTION_MESSAGE_NOTES = [
  {
    monthDt: {value: '2023-05-01T00:00:00'},
    displayText: 'employee',
    countNum: 10,
  },
  {
    monthDt: {value: '2023-04-01T00:00:00'},
    displayText: 'employee',
    countNum: 7,
  },
  {
    monthDt: {value: '2023-03-01T00:00:00'},
    displayText: 'employee',
    countNum: 9,
  },
  {
    monthDt: {value: '2023-02-01T00:00:00'},
    displayText: 'employee',
    countNum: 1,
  },
  {
    monthDt: {value: '2023-01-01T00:00:00'},
    displayText: 'employee',
    countNum: 4,
  },
  {
    monthDt: {value: '2023-12-01T00:00:00'},
    displayText: 'employee',
    countNum: 2,
  },
  {
    monthDt: {value: '2023-11-01T00:00:00'},
    displayText: 'employee',
    countNum: 7,
  },
  {
    monthDt: {value: '2023-10-01T00:00:00'},
    displayText: 'employee',
    countNum: 3,
  },
  {
    monthDt: {value: '2023-09-01T00:00:00'},
    displayText: 'employee',
    countNum: 2,
  },
  {
    monthDt: {value: '2023-06-01T00:00:00'},
    displayText: 'employee',
    countNum: 1,
  },
];

export const WIDGET_DISPLAY_TYPE: any = {
  EMPLOYEE_COUNT: {
    currentDisplayType: 'MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
    allowedDisplayType: [
      'MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
      'COUNT',
      'COUNT_WITH_LINE_GRAPH',
      'PIE_CHART',
      'MULTIPLE_LINE_CHARTS',
    ],
  },
  MEMBERSHIP_REVENUE_GROWTH: {
    currentDisplayType: 'MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['MULTIPLE_LINE_CHARTS'],
  },
  EMPLOYEE_LOGGED_IN: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'COUNT_WITH_LINE_GRAPH', 'PIE_CHART'],
  },
  MEMBERSHIP_INACTIVATED: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'COUNT_WITH_LINE_GRAPH', 'PIE_CHART'],
  },
  MEMBERSHIP_NET_GROWTH: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'COUNT_WITH_LINE_GRAPH', 'PIE_CHART'],
  },
  EMPLOYEE_AGE_GROUP: {
    currentDisplayType: 'PIE_CHART',
    allowedDisplayType: ['PIE_CHART'],
  },
  EMPLOYEE_GENDER_GROUP: {
    currentDisplayType: 'PIE_CHART',
    allowedDisplayType: ['PIE_CHART'],
  },
  ERX_SAVINGS: {
    currentDisplayType: 'SAVING_WIDGET',
    allowedDisplayType: ['COUNT', 'SAVING_WIDGET'],
  },
  LAB_SAVINGS: {
    currentDisplayType: 'SAVING_WIDGET',
    allowedDisplayType: ['COUNT', 'SAVING_WIDGET'],
  },
  IMAGING_SAVINGS: {
    currentDisplayType: 'SAVING_WIDGET',
    allowedDisplayType: ['COUNT', 'SAVING_WIDGET'],
  },
  AVOIDABLE_VISIT_SAVING: {
    currentDisplayType: 'SAVING_WIDGET',
    allowedDisplayType: ['COUNT', 'SAVING_WIDGET'],
  },
  AVOIDED_WORKDAY_ABSENCE_SAVING: {
    currentDisplayType: 'SAVING_WIDGET',
    allowedDisplayType: ['COUNT', 'SAVING_WIDGET'],
  },
  VISITS_SAVINGS: {
    currentDisplayType: 'SAVING_WIDGET',
    allowedDisplayType: ['COUNT', 'SAVING_WIDGET'],
  },
  CLAIMS_AVOIDED: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  WEIGHT_REDUCED: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  AVERAGE_BMI_REDUCED: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  CHRONIC_REVERSAL: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  SMOKING_DISCONTINUED: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  WORK_COMPS_AVOIDED: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  ER_ADMISSIONS_AVOIDED: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  SYMPTOMS_REPORTED: {
    currentDisplayType: 'COUNT_VERTICAL_WITH_LINE_GRAPH',
    allowedDisplayType: ['COUNT', 'COUNT_VERTICAL_WITH_LINE_GRAPH'],
  },
  CHAT_MESSAGING: {
    currentDisplayType: 'COUNT_VERTICAL_WITH_LINE_GRAPH',
    allowedDisplayType: [
      'COUNT',
      'COUNT_WITH_LINE_GRAPH',
      'COUNT_VERTICAL_WITH_LINE_GRAPH',
    ],
  },
  VOICE_CALLING: {
    currentDisplayType: 'COUNT_VERTICAL_WITH_LINE_GRAPH',
    allowedDisplayType: [
      'COUNT',
      'COUNT_WITH_LINE_GRAPH',
      'COUNT_VERTICAL_WITH_LINE_GRAPH',
    ],
  },
  APPOINTMENT_BOOKED: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'BAR_CHART_WITH_STACK_COLUMN'],
  },
  VIDEO_CALLING: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'BAR_CHART_WITH_STACK_COLUMN'],
  },
  APPOINTMENT_SELF_SCHEDULED: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'BAR_CHART_WITH_STACK_COLUMN'],
  },
  AUTOMATION_TIME_SAVED: {
    currentDisplayType: 'H_BAR_CHART_WITH_TOP_ITEM',
    allowedDisplayType: [
      'COUNT',
      'BAR_CHART_WITH_GROUP_COLUMN',
      'H_BAR_CHART_WITH_TOP_ITEM',
    ],
  },
  MEDICATION_ADHERENCE: {
    currentDisplayType: 'COUNT_VERTICAL_WITH_LINE_GRAPH',
    allowedDisplayType: ['COUNT', 'COUNT_VERTICAL_WITH_LINE_GRAPH'],
  },
  CARE_JOURNEY_ADHERENCE: {
    currentDisplayType: 'COUNT_VERTICAL_WITH_LINE_GRAPH',
    allowedDisplayType: ['COUNT', 'COUNT_VERTICAL_WITH_LINE_GRAPH'],
  },
  NO_SHOWS: {
    currentDisplayType: 'COUNT_VERTICAL_WITH_LINE_GRAPH',
    allowedDisplayType: ['COUNT', 'COUNT_VERTICAL_WITH_LINE_GRAPH'],
  },
  TOP_DIAGNOSIS: {
    currentDisplayType: 'HORIZONTAL_STACK_BAR_VIEW',
    allowedDisplayType: [
      'COUNT,PIE_CHART',
      'HORIZONTAL_STACK_BAR_VIEW',
      'H_BAR_CHART_WITH_TOP_ITEM',
    ],
  },
  APPOINTMENT_BY_DAY: {
    currentDisplayType: 'APPOINTMENT_WITH_STACK_BAR_VIEW',
    allowedDisplayType: ['COUNT', 'APPOINTMENT_WITH_GROUP_BAR_VIEW'],
  },
  APPOINTMENT_BY_TIME: {
    currentDisplayType: 'APPOINTMENT_WITH_STACK_BAR_VIEW',
    allowedDisplayType: ['COUNT', 'APPOINTMENT_WITH_GROUP_BAR_VIEW'],
  },
  APPOINTMENT_AGGREGATE: {
    currentDisplayType: 'APPOINTMENT_WITH_HORIZONTAL_GROUP_VIEW',
    allowedDisplayType: ['COUNT', 'APPOINTMENT_WITH_HORIZONTAL_GROUP_VIEW'],
  },
  APPOINTMENT_TYPE: {
    currentDisplayType: 'APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW',
    allowedDisplayType: ['COUNT', 'APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW'],
  },
  EMPLOYEE_VS_DEPENDANT_VISITS: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'BAR_CHART_WITH_GROUP_COLUMN'],
  },
  ENCOUNTER_TYPES: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'BAR_CHART_WITH_STACK_COLUMN'],
  },
  SMS_MESSAGING: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'BAR_CHART_WITH_STACK_COLUMN'],
  },
  PATIENT_APP_MESSAGING: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'BAR_CHART_WITH_STACK_COLUMN'],
  },
  USER_MESSAGING: {
    currentDisplayType: 'APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW',
    allowedDisplayType: ['COUNT', 'APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW'],
  },
  USER_VIDEO_USAGE: {
    currentDisplayType: 'APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW',
    allowedDisplayType: ['COUNT', 'APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW'],
  },
  USER_APPOINTMENT_TIME_USAGE: {
    currentDisplayType: 'APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW',
    allowedDisplayType: ['COUNT', 'APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW'],
  },
  OUT_OFFICE_HOUR_USER_ENGAGEMENT: {
    currentDisplayType: 'BIDIRECTIONAL_BAR_GRAPH',
    allowedDisplayType: ['COUNT', 'BAR_CHART_WITH_STACK_AND_GROUP_COLUMN'],
  },
  ENGAGEMENT_ON_APPLIED_RULES: {
    currentDisplayType: 'HORIZONTAL_GROUP_BAR_WITH_LINE_VIEW',
    allowedDisplayType: ['COUNT', 'HORIZONTAL_GROUP_BAR_WITH_LINE_VIEW'],
  },
  VOICE_CALLING_DIRECTION_WISE: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'BAR_CHART_WITH_STACK_COLUMN'],
  },
  TELEPHONE_BY_DAY: {
    currentDisplayType: 'APPOINTMENT_WITH_STACK_BAR_VIEW',
    allowedDisplayType: ['COUNT', 'APPOINTMENT_WITH_STACK_BAR_VIEW'],
  },
  TELEPHONE_BY_TIME: {
    currentDisplayType: 'APPOINTMENT_WITH_STACK_BAR_VIEW',
    allowedDisplayType: ['COUNT', 'APPOINTMENT_WITH_STACK_BAR_VIEW'],
  },
  CLINICAL_INTERVENTION_MESSAGE_NOTES: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'BAR_CHART_WITH_STACK_COLUMN'],
  },
  EMPLOYEE_ENROLLMENT_RETENTION: {
    currentDisplayType: 'PIE_CHART',
    allowedDisplayType: ['COUNT', 'PIE_CHART'],
  },
  TOP_MEDICATIONS: {
    currentDisplayType: 'H_BAR_CHART_WITH_TOP_ITEM',
    allowedDisplayType: [
      'COUNT',
      'LISTING_WIDGET_WITH_LIST',
      'LISTING_WIDGET_WITH_PROGRESS',
    ],
  },
  NEEDS_ATTENTION: {
    currentDisplayType: 'NEEDS_ATTENTION',
  },
  TOP_CARE_JOURNEYS: {
    currentDisplayType: 'H_BAR_CHART_WITH_TOP_ITEM',
    allowedDisplayType: ['COUNT', 'LISTING_WIDGET_WITH_LIST'],
  },
  TOP_LABS: {
    currentDisplayType: 'H_BAR_CHART_WITH_TOP_ITEM',
    allowedDisplayType: ['COUNT', 'LISTING_WIDGET_WITH_LIST'],
  },
  TOP_IMAGING: {
    currentDisplayType: 'H_BAR_CHART_WITH_TOP_ITEM',
    allowedDisplayType: ['COUNT', 'LISTING_WIDGET_WITH_LIST'],
  },
  TOP_SYMPTOMS_CHIEF_COMPLAINTS: {
    currentDisplayType: 'H_BAR_CHART_WITH_TOP_ITEM',
    allowedDisplayType: ['COUNT', 'LISTING_WIDGET_WITH_LIST'],
  },
  CLAIMS: {
    currentDisplayType: 'DUAL_MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['COUNT', 'MULTIPLE_LINE_CHARTS'],
  },
  ENROLLED_FOR_CARE: {
    currentDisplayType: 'DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
    allowedDisplayType: [
      'MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
      'COUNT',
      'COUNT_WITH_LINE_GRAPH',
      'PIE_CHART',
      'MULTIPLE_LINE_CHARTS',
    ],
  },
  NOT_ENROLLED_FOR_CARE: {
    currentDisplayType: 'DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
    allowedDisplayType: [
      'MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
      'COUNT',
      'COUNT_WITH_LINE_GRAPH',
      'PIE_CHART',
      'MULTIPLE_LINE_CHARTS',
    ],
  },
  PATIENT_ABSENCE_ANALYSIS: {
    currentDisplayType: 'DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
    allowedDisplayType: [
      'MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
      'COUNT',
      'COUNT_WITH_LINE_GRAPH',
      'PIE_CHART',
      'MULTIPLE_LINE_CHARTS',
    ],
  },
  ENROLLED_FOR_CHRONIC_CARE: {
    currentDisplayType: 'MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['COUNT', 'MULTIPLE_LINE_CHARTS'],
  },
  EMPLOYEE_WELLNESS_INDEX: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  CUSTOMER_SATISFACTION: {
    currentDisplayType: 'DETAIL_WITH_BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'DETAIL_WITH_BAR_CHART_WITH_STACK_COLUMN'],
  },
  COMMUNICATION_STATS: {
    currentDisplayType: 'DETAIL_WITH_BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'DETAIL_WITH_BAR_CHART_WITH_STACK_COLUMN'],
  },
  APPOINTMENT_RATING: {
    currentDisplayType: 'STAR_RATING_CHART',
    allowedDisplayType: ['COUNT', 'STAR_RATING_CHART'],
  },
  USER_SATISFACTION: {
    currentDisplayType: 'MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['COUNT', 'MULTIPLE_LINE_CHARTS'],
  },
  EMPLOYER_SATISFACTION: {
    currentDisplayType: 'MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['COUNT', 'MULTIPLE_LINE_CHARTS'],
  },
  CRM_ACTIVITY: {
    currentDisplayType: 'MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['COUNT', 'MULTIPLE_LINE_CHARTS'],
  },
  FOLD_CONVERSIONS: {
    currentDisplayType: 'MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['COUNT', 'MULTIPLE_LINE_CHARTS'],
  },
  FOLD_INACTIVE_ENGAGEMENT: {
    currentDisplayType: 'MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['COUNT', 'MULTIPLE_LINE_CHARTS'],
  },
  // CUSTOMER_SATISFACTION: {
  //   currentDisplayType: 'MULTIPLE_LINE_CHARTS',
  //   allowedDisplayType: ['COUNT','BAR_CHART_WITH_STACK_COLUMN', 'MULTIPLE_LINE_CHARTS'],
  // },
  CHRONIC_RISKS: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  PROJECTED_ROI: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  EXPENSE_PARETO_CURVES: {
    currentDisplayType: 'COUNT',
    allowedDisplayType: ['COUNT'],
  },
  TOTAL_SAVINGS: {
    currentDisplayType: 'SAVING_WIDGET_LINE_PIE',
    allowedDisplayType: ['COUNT', 'SAVING_WIDGET_LINE_PIE'],
  },
  PROCEDURES_SAVINGS: {
    currentDisplayType: 'SAVING_WIDGET',
    allowedDisplayType: ['COUNT', 'SAVING_WIDGET'],
  },
  EMPLOYEE_WELLNESS_INDEX_TREND: {
    currentDisplayType: 'STATUS_NUMBER_LINE',
    allowedDisplayType: ['COUNT', 'STATUS_NUMBER_LINE'],
  },
  CHRONIC_RISKS_TREND: {
    currentDisplayType: 'HORIZONTAL_STACK_BAR_VIEW',
    allowedDisplayType: [
      'COUNT',
      'H_BAR_CHART_WITH_TOP_ITEM, HORIZONTAL_STACK_BAR_VIEW',
    ],
  },
  BILLING_CPT_CODES: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'COUNT_WITH_LINE_GRAPH', 'PIE_CHART'],
  },
  BILLING_CPT_CODES_AVOIDED_ED: {
    currentDisplayType: 'BAR_CHART_WITH_STACK_COLUMN',
    allowedDisplayType: ['COUNT', 'COUNT_WITH_LINE_GRAPH', 'PIE_CHART'],
  },
  EXPENSE_PARETO_CURVES_TREND: {
    currentDisplayType: 'STATUS_NUMBER_LINE',
    allowedDisplayType: ['STATUS_NUMBER_LINE', 'STATUS_NUMBER_LINE'],
  },
  NEW_ENROLLED: {
    currentDisplayType: 'COUNT_WITH_LINE_GRAPH',
    allowedDisplayType: ['COUNT_WITH_LINE_GRAPH', 'COUNT_WITH_LINE_GRAPH'],
  },
  ED_UTILIZATION_AMOUNT: {
    currentDisplayType: 'MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['MULTIPLE_LINE_CHARTS'],
  },
  DPC_STRATIFICATION: {
    currentDisplayType: 'STATUS_NUMBER_LINE',
    allowedDisplayType: ['STATUS_NUMBER_LINE'],
  },
  ED_UTILIZATION_PERCENT: {
    currentDisplayType: 'PIE_CHART',
    allowedDisplayType: ['PIE_CHART'],
  },
  ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT: {
    currentDisplayType: 'PIE_CHART',
    allowedDisplayType: ['HORIZONTAL_BAR_CHART', 'PIE_CHART'],
  },
  ED_UTILIZATION_AMORTIZATION: {
    currentDisplayType: 'MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['MULTIPLE_LINE_CHARTS'],
  },
  TOTAL_CLAIM_COST: {
    currentDisplayType: 'DUAL_MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['MULTIPLE_LINE_CHARTS'],
  },
  IP_CLAIM_COST: {
    currentDisplayType: 'DUAL_MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['MULTIPLE_LINE_CHARTS'],
  },
  URGENT_CARE_CLAIM_COST: {
    currentDisplayType: 'DUAL_MULTIPLE_LINE_CHARTS',
    allowedDisplayType: ['MULTIPLE_LINE_CHARTS'],
  },
  ALL_CLAIM_DATA: {
    currentDisplayType: 'MULTIPLE_LINE_CHARTS_WITH_SLIDER',
    allowedDisplayType: ['COUNT', 'PIE_CHART', 'MULTIPLE_LINE_CHARTS'],
  },
  NET_PROMOTER_SCORE: {
    currentDisplayType: 'DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
    allowedDisplayType: ['COUNT', 'PIE_CHART', 'DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE'],
  },
  PRE_DPC_VS_POST_DPC: {
    currentDisplayType: 'MULTI_PLOT_VIEW_CHART',
    allowedDisplayType: ['MULTI_PLOT_VIEW_CHART'],
  }
};

export const getTenantForDemo = () => {
  if (
    (window.location.host.split('.')?.[0]?.includes('demo') && window.location.host.split('.')?.[1] === 'sandbox') ||
    window.location.host.includes('bowtie.sandbox') || window.location.host.includes('athena.qa')
  ) {
    return true;
  }
  return false;
};

export const getDemoDataForEmployerReportCurrentSection = (sectionData: ISectionListItem) => {
  switch (sectionData.resourceCode) {
    case ANALYTICS_RESOURCE.EMPLOYEE_COUNT: {
      return EMPLOYEE_COUNT.result;
    }
    case ANALYTICS_RESOURCE.NET_PROMOTER_SCORE: {
      return NET_PROMOTER_SCORE;
    }
    case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION: {
      return CUSTOMER_SATISFACTION;
    }
    case ANALYTICS_RESOURCE.APPOINTMENT_RATING: {
      return APPOINTMENT_RATING_DUMMY_DATA;
    }
    case ANALYTICS_RESOURCE.ALL_CLAIM_DATA: {
      return ALL_CLAIM_DATA;
    }
    case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE: {
      return TEMP_ENGAGE_FOR_CARE.result;
    }
    case ANALYTICS_RESOURCE.COMMUNICATION_STATS: {
      return COMMUNICATION_STATS_DUMMY_DATA;
    }
    case ANALYTICS_RESOURCE.PRE_DPC_VS_POST_DPC: {
      return PRE_DPC_VS_POST_DPC_DEMO_DATA;
    }
  }
};




export const FOLD_CONVERSIONS = {
  result: [
    {
      countNum: 121,
      displayText: 'email',
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
    },
    {
      countNum: 61,
      displayText: 'sms',
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
    },
    {
      countNum: 72,
      displayText: 'sms',
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
    },
    {
      countNum: 57,
      displayText: 'sms',
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
    },
    {
      countNum: 46,
      displayText: 'sms',
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
    },
    {
      countNum: 35,
      displayText: 'sms',
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
    },
    {
      countNum: 21,
      displayText: 'sms',
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
    },
    {
      countNum: 135,
      displayText: 'email',
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
    },
    {
      countNum: 100,
      displayText: 'email',
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
    },
    {
      countNum: 146,
      displayText: 'email',
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
    },
    {
      countNum: 113,
      displayText: 'email',
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
    },
    {
      countNum: 160,
      displayText: 'email',
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
    },
  ],
};

export const FOLD_INACTIVE_ENGAGEMENT = {
  result: [
    {
      countNum: 156,
      displayText: 'email',
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
    },
    {
      countNum: 91,
      displayText: 'sms',
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
    },
    {
      countNum: 100,
      displayText: 'sms',
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
    },
    {
      countNum: 87,
      displayText: 'sms',
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
    },
    {
      countNum: 64,
      displayText: 'sms',
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
    },
    {
      countNum: 41,
      displayText: 'sms',
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
    },
    {
      countNum: 34,
      displayText: 'sms',
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
    },
    {
      countNum: 167,
      displayText: 'email',
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
    },
    {
      countNum: 121,
      displayText: 'email',
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
    },
    {
      countNum: 175,
      displayText: 'email',
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
    },
    {
      countNum: 134,
      displayText: 'email',
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
    },
    {
      countNum: 190,
      displayText: 'email',
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
    },
  ],
};

export const CRM_ACTIVITY = {
  result: [
    {
      countNum: 130,
      displayText: 'message',
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
    },
    {
      countNum: 114,
      displayText: 'formResponse',
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
    },
    {
      countNum: 126,
      displayText: 'formResponse',
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
    },
    {
      countNum: 71,
      displayText: 'formResponse',
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
    },
    {
      countNum: 86,
      displayText: 'formResponse',
      monthDt: {
        value: '2023-02-01',
      },
      month: 'January',
    },
    {
      countNum: 91,
      displayText: 'formResponse',
      monthDt: {
        value: '2023-03-01',
      },
      month: 'January',
    },
    {
      countNum: 103,
      displayText: 'formResponse',
      monthDt: {
        value: '2023-04-01',
      },
      month: 'January',
    },
    {
      countNum: 100,
      displayText: 'message',
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
    },
    {
      countNum: 141,
      displayText: 'message',
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
    },
    {
      countNum: 136,
      displayText: 'message',
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
    },
    {
      countNum: 124,
      displayText: 'message',
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
    },
    {
      countNum: 152,
      displayText: 'message',
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
    },
    {
      countNum: 81,
      displayText: 'call',
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
    },
    {
      countNum: 92,
      displayText: 'call',
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
    },
    {
      countNum: 103,
      displayText: 'call',
      monthDt: {
        value: '2023-03-01',
      },
      month: 'January',
    },
    {
      countNum: 121,
      displayText: 'call',
      monthDt: {
        value: '2023-04-01',
      },
      month: 'January',
    },
    {
      countNum: 134,
      displayText: 'call',
      monthDt: {
        value: '2023-05-01',
      },
      month: 'January',
    },
    {
      countNum: 141,
      displayText: 'call',
      monthDt: {
        value: '2023-06-01',
      },
      month: 'January',
    },
  ],
};

export const EXPENSE_PARETO_CURVES = {
  contactExpData: {
    topOnePercentCount: {
      count: 14,
      totalClaimCost: 26014.25,
      totalCount: 5670,
    },
    topFivePercentCount: {
      count: 13,
      totalClaimCost: 59601.35,
      totalCount: 8759,
    },
    topTenPercentCount: {
      count: 24,
      totalClaimCost: 86943.18,
      totalCount: 46980,
    },
    other: {
      count: 45,
      totalClaimCost: 142178.42,
      totalCount: 789015,
    },
  },
};

export const DPC_UTILIZATION = {
  contactExpData: {
    topOnePercentCount: {
      count: 142,
      totalClaimCost: 26014.25,
      totalCount: 5670,
    },
    topFivePercentCount: {
      count: 351,
      totalClaimCost: 59601.35,
      totalCount: 8759,
    },
    topTenPercentCount: {
      count: 797,
      totalClaimCost: 86943.18,
      totalCount: 46980,
    },
    other: {
      count: 1615,
      totalClaimCost: 142178.42,
      totalCount: 789015,
    },
  },
};

export const TEMP_TOTAL_CLAIMS_COST = {
  result: {
    contactData: [
      {
        totalClaimCost: 1200,
        contactCount: 2,
        monthDate: {
          value: '2023-11-01',
        },
        amortizationCost: 6,
        totalActiveContacts: 185,
      },
      {
        totalClaimCost: 1500,
        contactCount: 1,
        monthDate: {
          value: '2023-10-01',
        },
        amortizationCost: 8,
        totalActiveContacts: 170,
      },
      {
        totalClaimCost: 1900,
        contactCount: 4,
        monthDate: {
          value: '2023-09-01',
        },
        amortizationCost: 11,
        totalActiveContacts: 150,
      },
      {
        totalClaimCost: 2100,
        contactCount: 50,
        monthDate: {
          value: '2023-08-01',
        },
        amortizationCost: 16,
        totalActiveContacts: 130,
      },
      {
        totalClaimCost: 2300,
        contactCount: 12,
        monthDate: {
          value: '2023-07-01',
        },
        amortizationCost: 20,
        totalActiveContacts: 110,
      },
      {
        totalClaimCost: 2800,
        contactCount: 14,
        monthDate: {
          value: '2023-06-01',
        },
        amortizationCost: 30,
        totalActiveContacts: 91,
      },
      {
        totalClaimCost: 3200,
        contactCount: 16,
        monthDate: {
          value: '2023-05-01',
        },
        amortizationCost: 45,
        totalActiveContacts: 70,
      },
      {
        totalClaimCost: 4100,
        contactCount: 19,
        monthDate: {
          value: '2023-04-01',
        },
        amortizationCost: 82,
        totalActiveContacts: 50,
      },
      {
        totalClaimCost: 5500,
        contactCount: 24,
        monthDate: {
          value: '2023-03-01',
        },
        amortizationCost: 100,
        totalActiveContacts: 55,
      },
      {
        totalClaimCost: 6500,
        contactCount: 11,
        monthDate: {
          value: '2023-02-01',
        },
        amortizationCost: 162,
        totalActiveContacts: 40,
      },
      {
        totalClaimCost: 7000,
        contactCount: 12,
        monthDate: {
          value: '2023-01-01',
        },
        amortizationCost: 233,
        totalActiveContacts: 30,
      },
    ],
  },
};

export const TEMP_CHROMIC_DATA = {
  result: {
    topChronicConditions: [
      {
        displayText: 'Diabetes (Type 1 and Type 2)',
        code: 'E55.9',
        employeeType: 'employee',
        countNum: 80,
      },
      {
        displayText: 'Hypertension (High blood pressure)',
        code: 'I10',
        employeeType: 'employee',
        countNum: 120,
      },
      {
        displayText: 'Asthma',
        code: 'E78.5',
        employeeType: 'employee',
        countNum: 60,
      },
      {
        displayText: 'Cardiovascular disease',
        code: 'E66.9',
        employeeType: 'employee',
        countNum: 40,
      },
      {
        displayText: 'Chronic kidney disease',
        code: 'E11.9',
        employeeType: 'employee',
        countNum: 50,
      },
    ],
    topChronicConditionsPatientCount: [
      {
        totalChronicCondition: 5,
        countNum: 15,
      },
      {
        totalChronicCondition: 4,
        countNum: 10,
      },
      {
        totalChronicCondition: 3,
        countNum: 20,
      },
      {
        totalChronicCondition: 2,
        countNum: 60,
      },
      {
        totalChronicCondition: 1,
        countNum: 100,
      },
    ],
  },
};

export const TEMP_ENGAGE_FOR_CARE = {
  result: {
    columnStackDataList: [
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1413
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1456
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 47
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 8
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 4
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 25
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1427
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 49
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 6
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 3
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 34
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1388
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 66
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 5
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 2
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 27
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1359
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 53
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 4
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 1
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 29
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1354
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 68
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 4
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 2
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 31
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1349
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 57
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 8
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 2
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 22
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1323
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 85
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 5
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 4
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 50
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1188
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 84
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 8
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 2
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 31
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1149
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 133
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 15
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 7
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 52
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1131
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 145
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 20
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 12
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 65
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "displayText": "Not Engaged",
        "filterCode": "NOT_ENGAGED",
        "countNum": 1132
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 139
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 24
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 14
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 66
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "displayText": "employee",
        "filterCode": "employee",
        "countNum": 191
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "displayText": "Spouse",
        "filterCode": "Spouse",
        "countNum": 29
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "displayText": "Child",
        "filterCode": "Child",
        "countNum": 15
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "displayText": "self-sponsored",
        "filterCode": "self-sponsored",
        "countNum": 64
      }
    ],
    lineDataList: [
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "displayText": "Engaged Members",
        "filterCode": "self-sponsored",
        "countNum": 84
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "displayText": "Engaged Members",
        "filterCode": "employee",
        "countNum": 92
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "displayText": "Engaged Members",
        "filterCode": "employee",
        "countNum": 100
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "displayText": "Engaged Members",
        "filterCode": "self-sponsored",
        "countNum": 87
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "displayText": "Engaged Members",
        "filterCode": "employee",
        "countNum": 105
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "displayText": "Engaged Members",
        "filterCode": "self-sponsored",
        "countNum": 89
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "displayText": "Engaged Members",
        "filterCode": "Spouse",
        "countNum": 144
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "displayText": "Engaged Members",
        "filterCode": "employee",
        "countNum": 125
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "displayText": "Engaged Members",
        "filterCode": "Spouse",
        "countNum": 207
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "displayText": "Engaged Members",
        "filterCode": "Child",
        "countNum": 242
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "displayText": "Engaged Members",
        "filterCode": "Spouse",
        "countNum": 243
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "displayText": "Engaged Members",
        "filterCode": "Child",
        "countNum": 299
      }
    ],
    additionalData: {
      notEngagedFromMonthData: [
        {
          filterCode: "MONTH_3",
          countNum: 1000,
          totalActiveMemberCount: 2000,
          currentEngagedPercentage: 50,
          displayText: "Engaged in last 90 Days",
          month: 3
        },
        {
          filterCode: "MONTH_6",
          countNum: 1200,
          totalActiveMemberCount: 2000,
          currentEngagedPercentage: 60,
          displayText: "Engaged in last 180 Days",
          month: 6
        },
        {
          filterCode: "MONTH_12",
          countNum: 1800,
          totalActiveMemberCount: 2000,
          currentEngagedPercentage: 90,
          displayText: "Engaged in last 360 Days",
          month: 12
        }
      ],
      activeMemberCountData: {
        result: {
          totalCount: 2000
        }
      }
    }
  }
};
export const PATIENT_SATISFACTION = {
  result: [
    {
      countNum: 98,
      month: 'May',
      monthDt: {
        value: '2023-05-01',
      },
      averageRating: 61,
    },
    {
      countNum: 98,
      month: 'June',
      monthDt: {
        value: '2023-06-01',
      },
      averageRating: 98,
    },
    {
      countNum: 96,
      month: 'April',
      monthDt: {
        value: '2023-04-01',
      },
      averageRating: 41,
    },
  ],
};

export const MEMBERSHIP_REVENUE_GROWTH = {
  result: [
    {
      membershipRevenue: 25580,
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
    },
    {
      membershipRevenue: 22000,
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
    },
    {
      membershipRevenue: 15100,
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
    },
    {
      membershipRevenue: 12500,
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
    },
    {
      membershipRevenue: 10400,
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
    },
    {
      membershipRevenue: 9120,
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
    },
    {
      membershipRevenue: 6850,
      monthDt: {
        value: '2023-12-01',
      },
      month: 'December',
    },
    {
      membershipRevenue: 5550,
      monthDt: {
        value: '2023-11-01',
      },
      month: 'November',
    },
    {
      membershipRevenue: 2000,
      monthDt: {
        value: '2023-10-01',
      },
      month: 'October',
    },
  ],
};

export const MEMBERSHIP_INACTIVATED = [
  {
    displayText: 'Self-Sponsored',
    countNum: 2,
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
  },
  {
    displayText: 'Employee',
    countNum: 3,
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
  },
  {
    displayText: 'Dependent',
    countNum: 1,
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 1,
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
  },
  {
    displayText: 'Employee',
    countNum: 4,
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
  },
  {
    displayText: 'Dependent',
    countNum: 2,
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 1,
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
  },
  {
    displayText: 'Employee',
    countNum: 1,
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
  },
  {
    displayText: 'Dependent',
    countNum: 1,
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 3,
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
  },
  {
    displayText: 'Employee',
    countNum: 8,
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
  },
  {
    displayText: 'Dependent',
    countNum: 4,
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 2,
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
  },
  {
    displayText: 'Employee',
    countNum: 2,
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
  },
  {
    displayText: 'Dependent',
    countNum: 1,
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 4,
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
  },
  {
    displayText: 'Employee',
    countNum: 7,
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
  },
  {
    displayText: 'Dependent',
    countNum: 4,
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 2,
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
  },
  {
    displayText: 'Employee',
    countNum: 5,
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
  },
  {
    displayText: 'Dependent',
    countNum: 1,
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 1,
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
  },
  {
    displayText: 'Employee',
    countNum: 3,
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
  },
  {
    displayText: 'Dependent',
    countNum: 2,
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 1,
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
  },
  {
    displayText: 'Employee',
    countNum: 4,
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
  },
  {
    displayText: 'Dependent',
    countNum: 2,
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 1,
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
  },
  {
    displayText: 'Dependent',
    countNum: 2,
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
  },
  {
    displayText: 'Employee',
    countNum: 5,
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
  },
];

export const MEMBERSHIP_NET_GROWTH = [
  {
    displayText: 'Employee',
    countNum: 25,
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
  },
  {
    displayText: 'Dependent',
    countNum: 11,
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 14,
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
  },
  {
    displayText: 'Employee',
    countNum: 31,
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
  },
  {
    displayText: 'Dependent',
    countNum: 18,
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 20,
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
  },
  {
    displayText: 'Employee',
    countNum: 51,
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
  },
  {
    displayText: 'Dependent',
    countNum: 27,
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 22,
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
  },
  {
    displayText: 'Dependent',
    countNum: 70,
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
  },
  {
    displayText: 'Dependent',
    countNum: 81,
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
  },
  {
    displayText: 'Employee',
    countNum: 170,
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 41,
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
  },
  {
    displayText: 'Employee',
    countNum: 150,
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
  },
  {
    displayText: 'Dependent',
    countNum: 51,
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 36,
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
  },
  {
    displayText: 'Employee',
    countNum: 175,
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 44,
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
  },
  {
    displayText: 'Employee',
    countNum: 95,
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
  },
  {
    displayText: 'Dependent',
    countNum: 39,
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 31,
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
  },
  {
    displayText: 'Employee',
    countNum: 120,
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
  },
  {
    displayText: 'Dependent',
    countNum: 43,
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 34,
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
  },
  {
    displayText: 'Employee',
    countNum: 75,
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
  },
  {
    displayText: 'Dependent',
    countNum: 31,
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 24,
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
  },
  {
    displayText: 'Employee',
    countNum: 10,
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
  },
  {
    displayText: 'Dependent',
    countNum: 9,
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 11,
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
  },
  {
    displayText: 'Employee',
    countNum: 162,
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
  },
  {
    displayText: 'Dependent',
    countNum: 62,
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
  },
  {
    displayText: 'Self-Sponsored',
    countNum: 39,
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
  },
];

export const USER_SATISFACTION = {
  result: [
    {
      countNum: 40,
      month: 'May',
      monthDt: {
        value: '2023-05-01',
      },
      averageRating: 40,
    },
    {
      countNum: 91,
      month: 'June',
      monthDt: {
        value: '2023-06-01',
      },
      averageRating: 95,
    },
    {
      countNum: 100,
      month: 'April',
      monthDt: {
        value: '2023-04-01',
      },
      averageRating: 20,
    },
  ],
};

export const EMPLOYER_SATISFACTION = {
  result: [
    {
      countNum: 50,
      month: 'May',
      monthDt: {
        value: '2023-05-01',
      },
      averageRating: 95,
    },
    {
      countNum: 56,
      month: 'June',
      monthDt: {
        value: '2023-06-01',
      },
      averageRating: 85,
    },
    {
      countNum: 34,
      month: 'April',
      monthDt: {
        value: '2023-04-01',
      },
      averageRating: 45,
    },
  ],
};

export const AUTOMATION_TIME_SAVED_DRILL_DOWN = {
  data: {
    selectedDrillDownRowData: [
      {
        workflowMasterId: 'e654a662-08df-11ed-9698-6b0ab3025bea',
        flowType: 'SCHEDULING',
        wflRowData: [
          [
            {
              columnKey: 'workflowMasterId',
              value: 'e654a662-08df-11ed-9698-6b0ab3025bea',
            },
            {
              columnKey: 'name',
              value:
                'Send pending forms automatically once schedule is created or updated based on status',
            },
            {
              columnKey: 'automationTimeSaved',
              value: 18,
            },
          ],
        ],
      },
      {
        workflowMasterId: 'd3b4c1b2-d000-11ed-9aae-196dcd9b07f5',
        flowType: 'COMMUNICATION',
        wflRowData: [
          [
            {
              columnKey: 'workflowMasterId',
              value: 'd3b4c1b2-d000-11ed-9aae-196dcd9b07f5',
            },
            {
              columnKey: 'name',
              value: 'VIP member tagged',
            },
            {
              columnKey: 'automationTimeSaved',
              value: 3,
            },
          ],
        ],
      },
      {
        workflowMasterId: 'a95cb213-be97-11ed-bb9d-cdf2cb2d8c71',
        flowType: 'PATIENTS',
        wflRowData: [
          [
            {
              columnKey: 'workflowMasterId',
              value: 'a95cb213-be97-11ed-bb9d-cdf2cb2d8c71',
            },
            {
              columnKey: 'name',
              value: 'High-Risk High-Cost Patient Flow',
            },
            {
              columnKey: 'automationTimeSaved',
              value: 2,
            },
          ],
        ],
      },
    ],
    columnList: [
      {
        columnKey: 'workflowMasterId',
        columnName: 'Id',
        DATA_TYPE: 'STRING',
        sortable: 'DESC',
      },
      {
        columnKey: 'name',
        columnName: 'Name',
        sortable: 'DESC',
        DATA_TYPE: 'STRING',
      },
      {
        columnKey: 'automationTimeSaved',
        columnName: 'Time Saved (minutes)',
        DATA_TYPE: 'DURATION',
        CURRENT_FORMAT: 'MIN',
      },
    ],
  },
};

export const PATIENT_SATISFACTION_DRILL_DOWN = {
  result: {
    avgRating: 90,
    queAnsList: {
      '31809c65-98ea-4500-a1d5-b0f2020a910a': {
        queDisplayString: 'Feeling nervous, anxious, or on edge',
        ansOptList: [
          {
            label: 'Not at all',
            value: 'notAtAll',
            riskScore: 0,
            contactAnsCount: 12,
          },
          {
            label: 'Several days',
            value: 'severalDays',
            riskScore: 1,
            contactAnsCount: 65,
          },
          {
            label: 'More than half the days',
            value: 'moreThanHalfTheDays',
            riskScore: 2,
            contactAnsCount: 21,
          },
          {
            label: 'Nearly every day',
            value: 'nearlyEveryDay',
            riskScore: 3,
            contactAnsCount: 44,
          },
        ],
      },
      'dc7223f7-6f0e-4506-a0ab-dcd268c54331': {
        queDisplayString: 'Not being able to stop or control worrying',
        ansOptList: [
          {
            label: 'Not at all',
            value: 'notAtAll',
            riskScore: 0,
            contactAnsCount: 91,
          },
          {
            label: 'Several days',
            value: 'severalDays',
            riskScore: 1,
            contactAnsCount: 32,
          },
          {
            label: 'More than half the days',
            value: 'moreThanHalfTheDays',
            riskScore: 2,
            contactAnsCount: 72,
          },
          {
            label: 'Nearly every day',
            value: 'nearlyEveryDay',
            riskScore: 3,
            contactAnsCount: 61,
          },
        ],
      },
      '74e919b9-71df-4cf1-9a0b-1f4b72ab66f1': {
        queDisplayString: 'Worrying too much about different things',
        ansOptList: [
          {
            label: 'Not at all',
            value: 'notAtAll',
            riskScore: 10,
            contactAnsCount: 19,
          },
          {
            label: 'Several days',
            value: 'severalDays',
            riskScore: 1,
            contactAnsCount: 89,
          },
          {
            label: 'More than half the days',
            value: 'moreThanHalfTheDays',
            riskScore: 2,
            contactAnsCount: 54,
          },
          {
            label: 'Nearly every day',
            value: 'nearlyEveryDay',
            riskScore: 3,
            contactAnsCount: 35,
          },
        ],
      },
      '66fcbf28-8fb5-42f8-a2ba-eec5bad196c4': {
        queDisplayString: 'Trouble relaxing',
        ansOptList: [
          {
            label: 'Not at all',
            value: 'notAtAll',
            riskScore: 0,
            contactAnsCount: 35,
          },
          {
            label: 'Several days',
            value: 'severalDays',
            riskScore: 1,
            contactAnsCount: 31,
          },
          {
            label: 'More than half the days',
            value: 'moreThanHalfTheDays',
            riskScore: 2,
            contactAnsCount: 50,
          },
          {
            label: 'Nearly every day',
            value: 'nearlyEveryDay',
            riskScore: 3,
            contactAnsCount: 95,
          },
        ],
      },
      '6e96cff9-8e57-4b82-9b32-57a934d3d043': {
        queDisplayString: 'Being so restless that its hard to sit still',
        ansOptList: [
          {
            label: 'Not at all',
            value: 'notAtAll',
            riskScore: 0,
            contactAnsCount: 95,
          },
          {
            label: 'Several days',
            value: 'severalDays',
            riskScore: 1,
            contactAnsCount: 59,
          },
          {
            label: 'More than half the days',
            value: 'moreThanHalfTheDays',
            riskScore: 2,
            contactAnsCount: 85,
          },
          {
            label: 'Nearly every day',
            value: 'nearlyEveryDay',
            riskScore: 3,
            contactAnsCount: 15,
          },
        ],
      },
      '3cccb32a-fef7-418e-a872-cb6cf8750531': {
        queDisplayString: 'Becoming easily annoyed or irritable',
        ansOptList: [
          {
            label: 'Not at all',
            value: 'notAtAll',
            riskScore: 0,
            contactAnsCount: 15,
          },
          {
            label: 'Several days',
            value: 'severalDays',
            riskScore: 1,
            contactAnsCount: 11,
          },
          {
            label: 'More than half the days',
            value: 'moreThanHalfTheDays',
            riskScore: 2,
            contactAnsCount: 45,
          },
          {
            label: 'Nearly every day',
            value: 'nearlyEveryDay',
            riskScore: 3,
            contactAnsCount: 55,
          },
        ],
      },
      '9fd00de7-d1f3-417b-bde2-de0af9b8e97b': {
        queDisplayString: 'Feeling afraid as if something awful might happen',
        ansOptList: [
          {
            label: 'Not at all',
            value: 'notAtAll',
            riskScore: 0,
            contactAnsCount: 85,
          },
          {
            label: 'Several days',
            value: 'severalDays',
            riskScore: 1,
            contactAnsCount: 95,
          },
          {
            label: 'More than half the days',
            value: 'moreThanHalfTheDays',
            riskScore: 2,
            contactAnsCount: 75,
          },
          {
            label: 'Nearly every day',
            value: 'nearlyEveryDay',
            riskScore: 3,
            contactAnsCount: 65,
          },
        ],
      },
      '0cb5063e-3f7a-400b-b1d5-c00a3808c38c': {
        queDisplayString:
          'How difficult have these problems made it to do work, take care of things at home, or get along with other people?',
        ansOptList: [
          {
            label: 'Not at all',
            value: 'notAtAll',
            riskScore: 0,
            contactAnsCount: 15,
          },
          {
            label: 'Several days',
            value: 'severalDays',
            riskScore: 2,
            contactAnsCount: 35,
          },
          {
            label: 'More than half the days',
            value: 'moreThanHalfTheDays',
            riskScore: 1,
            contactAnsCount: 58,
          },
          {
            label: 'Nearly every day',
            value: 'nearlyEveryDay',
            riskScore: 3,
            contactAnsCount: 71,
          },
        ],
      },
    },
    scoreData: {
      allContactScore: 90,
      allContactMaxScore: 100,
    },
    totalFormSubmitted: 200,
    totalFormSend: 324,
  },
};

export const CHAT_MESSAGING = [
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    countNum: 7000,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    countNum: 7500,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    countNum: 6000,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    countNum: 5000,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    countNum: 2000,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    countNum: 4000,
  },
  {
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
    countNum: 1000,
  },
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    countNum: 2310,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    countNum: 1300,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    countNum: 1000,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    countNum: 3100,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    countNum: 50,
  },
];

export const VIDEO_MESSAGING = [
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    countNum: 2700,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    countNum: 2550,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    countNum: 2300,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    countNum: 2010,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    countNum: 1700,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    countNum: 1550,
  },
  {
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
    countNum: 1400,
  },
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    countNum: 1200,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    countNum: 1400,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    countNum: 780,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'September',
    countNum: 680,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'August',
    countNum: 550,
  },
];

export const VOICE_MESSAGING = [
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    countNum: 9700,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    countNum: 10550,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    countNum: 9220,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    countNum: 6050,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    countNum: 9810,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    countNum: 11660,
  },
  {
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
    countNum: 9570,
  },
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    countNum: 6890,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    countNum: 2310,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    countNum: 4190,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    countNum: 5220,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    countNum: 880,
  },
];

export const APPOINTMENT_BOOKED = [
  {
    "monthDt": {
      "value": "2023-08-01"
    },
    "month": "August",
    "countNum": 732,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-08-01"
    },
    "month": "August",
    "countNum": 89,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-07-01"
    },
    "month": "July",
    "countNum": 541,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-07-01"
    },
    "month": "July",
    "countNum": 101,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-06-01"
    },
    "month": "June",
    "countNum": 501,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-06-01"
    },
    "month": "June",
    "countNum": 99,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-05-01"
    },
    "month": "May",
    "countNum": 500,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-05-01"
    },
    "month": "May",
    "countNum": 104,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-04-01"
    },
    "month": "April",
    "countNum": 447,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-04-01"
    },
    "month": "April",
    "countNum": 100,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-03-01"
    },
    "month": "March",
    "countNum": 455,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-03-01"
    },
    "month": "March",
    "countNum": 108,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-02-01"
    },
    "month": "February",
    "countNum": 513,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-02-01"
    },
    "month": "February",
    "countNum": 107,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-01-01"
    },
    "month": "January",
    "countNum": 501,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-01-01"
    },
    "month": "January",
    "countNum": 137,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-12-01"
    },
    "month": "December",
    "countNum": 57,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-12-01"
    },
    "month": "December",
    "countNum": 371,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-11-01"
    },
    "month": "November",
    "countNum": 447,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-11-01"
    },
    "month": "November",
    "countNum": 76,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-10-01"
    },
    "month": "October",
    "countNum": 445,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-10-01"
    },
    "month": "October",
    "countNum": 93,
    "code": "no show"
  },
  {
    "monthDt": {
      "value": "2023-09-01"
    },
    "month": "September",
    "countNum": 504,
    "code": "booked"
  },
  {
    "monthDt": {
      "value": "2023-09-01"
    },
    "month": "September",
    "countNum": 78,
    "code": "no show"
  }
];

export const VIDEO_CALLING = [
  {
    "monthDt": {
      "value": "2023-12-01T00:00:00"
    },
    "month": "December",
    "countNum": null,
    "employeeType": "employee"
  },
  {
    "monthDt": {
      "value": "2023-01-01T00:00:00"
    },
    "month": "January",
    "countNum": 41931,
    "employeeType": "employee"
  },
  {
    "monthDt": {
      "value": "2023-02-01T00:00:00"
    },
    "month": "February",
    "countNum": 2738,
    "employeeType": "employee"
  },
  {
    "monthDt": {
      "value": "2023-03-01T00:00:00"
    },
    "month": "March",
    "countNum": null,
    "employeeType": "employee"
  },
  {
    "monthDt": {
      "value": "2023-04-01T00:00:00"
    },
    "month": "April",
    "countNum": 386,
    "employeeType": "dependent"
  },
  {
    "monthDt": {
      "value": "2023-05-01T00:00:00"
    },
    "month": "May",
    "countNum": 18325,
    "employeeType": "employee"
  },
  {
    "monthDt": {
      "value": "2023-05-01T00:00:00"
    },
    "month": "May",
    "countNum": null,
    "employeeType": "dependent"
  },
  {
    "monthDt": {
      "value": "2023-05-01T00:00:00"
    },
    "month": "May",
    "countNum": null,
    "employeeType": "dependent"
  },
  {
    "monthDt": {
      "value": "2023-06-01T00:00:00"
    },
    "month": "June",
    "countNum": 48,
    "employeeType": "dependent"
  },
  {
    "monthDt": {
      "value": "2023-06-01T00:00:00"
    },
    "month": "June",
    "countNum": 115,
    "employeeType": "dependent"
  },
  {
    "monthDt": {
      "value": "2023-06-01T00:00:00"
    },
    "month": "June",
    "countNum": 91,
    "employeeType": "employee"
  },
  {
    "monthDt": {
      "value": "2023-07-01T00:00:00"
    },
    "month": "July",
    "countNum": 1320,
    "employeeType": "employee"
  },
  {
    "monthDt": {
      "value": "2023-07-01T00:00:00"
    },
    "month": "July",
    "countNum": 583,
    "employeeType": "dependent"
  },
  {
    "monthDt": {
      "value": "2023-08-01T00:00:00"
    },
    "month": "August",
    "countNum": 551,
    "employeeType": "employee"
  }
];

export const NO_SHOWS = [
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    countNum: 2,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    countNum: 4,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    countNum: 9,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    countNum: 11,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    countNum: 15,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    countNum: 19,
  },
  {
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
    countNum: 20,
  },
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    countNum: 32,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    countNum: 33,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    countNum: 35,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    countNum: 40,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    countNum: 50,
  },
];

export const EMPLOYEE_VS_DEPENDANT_VISITS = [
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    employeeType: 'Employee',
    countNum: 547,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    employeeType: 'Dependent',
    countNum: 141,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    employeeType: 'Employee',
    countNum: 600,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    employeeType: 'Dependent',
    countNum: 202,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    employeeType: 'Employee',
    countNum: 192,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    employeeType: 'Dependent',
    countNum: 358,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    employeeType: 'Dependent',
    countNum: 271,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    employeeType: 'Employee',
    countNum: 336,
  },
  {
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
    employeeType: 'Dependent',
    countNum: 152,
  },
  {
    monthDt: {
      value: '2023-01-01',
    },
    month: 'January',
    employeeType: 'Employee',
    countNum: 311,
  },
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    employeeType: 'Employee',
    countNum: 77,
  },
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    employeeType: 'Dependent',
    countNum: 135,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    employeeType: 'Dependent',
    countNum: 56,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    employeeType: 'Employee',
    countNum: 178,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    employeeType: 'Employee',
    countNum: 403,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    employeeType: 'Dependent',
    countNum: 105,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    employeeType: 'Employee',
    countNum: 302,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    employeeType: 'Dependent',
    countNum: 382,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    employeeType: 'Employee',
    countNum: 165,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    employeeType: 'Dependent',
    countNum: 162,
  },
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    employeeType: 'Employee',
    countNum: 348,
  },
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    employeeType: 'Dependent',
    countNum: 212,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    employeeType: 'Employee',
    countNum: 151,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    employeeType: 'Dependent',
    countNum: 314,
  },
];

export const ENCOUNTER_TYPES = [
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    apptType: 'VIDEO',
    countNum: 23,
  },
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    apptType: 'IN PERSON',
    countNum: 31,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    apptType: 'IN PERSON',
    countNum: 89,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    apptType: 'VIDEO',
    countNum: 12,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    apptType: 'VIDEO',
    countNum: 56,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    apptType: 'IN PERSON',
    countNum: 51,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    apptType: 'IN PERSON',
    countNum: 22,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    apptType: 'VIDEO',
    countNum: 45,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    apptType: 'VIDEO',
    countNum: 71,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    apptType: 'IN PERSON',
    countNum: 54,
  },
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    apptType: 'VIDEO',
    countNum: 77,
  },
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    apptType: 'IN PERSON',
    countNum: 39,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    apptType: 'IN PERSON',
    countNum: 63,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    apptType: 'VIDEO',
    countNum: 77,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    apptType: 'IN PERSON',
    countNum: 91,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    apptType: 'VIDEO',
    countNum: 19,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    apptType: 'IN PERSON',
    countNum: 71,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    apptType: 'VIDEO',
    countNum: 69,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    apptType: 'VIDEO',
    countNum: 51,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    apptType: 'IN PERSON',
    countNum: 62,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    apptType: 'IN PERSON',
    countNum: 41,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    apptType: 'VIDEO',
    countNum: 65,
  },
];

export const AUTOMATION_TIME_SAVED = [
  {
    countNum: 349,
    displayText: 'When a scheduled is added or updated',
    workflowMasterId: '2139afa6-3e3d-11ed-9453-81281661da78',
    flowType: 'SCHEDULING',
  },
  {
    countNum: 330,
    displayText: '24may sk  When a new employee is registered in account',
    workflowMasterId: 'ddb152f7-fa31-11ed-aa96-857b2272f122',
    flowType: 'PATIENTS',
  },
  {
    countNum: 370,
    displayText: 'SK When a Contact Membership Status Changed',
    workflowMasterId: '01b4e589-08e0-11ee-9b6f-b72661f535f1',
    flowType: 'PATIENTS',
  },
  {
    countNum: 172,
    displayText: 'Pine Park - Elation Appointment Integration Flow',
    workflowMasterId: '1244b0d6-b447-11ed-80ea-2356023f001f',
    flowType: 'SCHEDULING',
  },
  {
    countNum: 350,
    displayText: 'When a new employee is registered in account',
    workflowMasterId: 'c0bc33a7-8bfe-11ed-8847-d5bfdf6ea32e',
    flowType: 'PATIENTS',
  },
  {
    countNum: 182,
    displayText: 'on employee membership status change',
    workflowMasterId: '16fa4b25-bcf3-11ed-bff5-ff730f5cef5c',
    flowType: 'PATIENTS',
  },
  {
    countNum: 410,
    displayText: 'When members are matching selected conditions',
    workflowMasterId: '9725a5ac-a6a9-11ed-8f5f-f948dbbc2a46',
    flowType: 'PATIENTS',
  },
  {
    countNum: 200,
    displayText: 'Test_When a Employee Membership Status Changed',
    workflowMasterId: '52f0ed66-a094-11ed-873e-35ced0440fb0',
    flowType: 'PATIENTS',
  },
  {
    countNum: 301,
    displayText: 'EHR Appointment sync',
    workflowMasterId: '83946be1-cdfb-11ed-b975-835ad26d428a',
    flowType: 'SCHEDULING',
  },
  {
    countNum: 191,
    displayText:
      'Healthy Diabetes Journey (No comorbidities) for non-smoking males',
    workflowMasterId: '60fe8ae2-9317-11ed-b674-4d83a74ecfc3',
    flowType: 'CARE_JOURNEY',
  },
  {
    countNum: 140,
    displayText: 'SK 26 may',
    workflowMasterId: '5b0bfdce-fb92-11ed-9b7b-c112c330160e',
    flowType: 'CARE_JOURNEY',
  },
  {
    countNum: 120,
    displayText:
      'Healthy Diabetes Journey (No comorbidities) for non-smoking males',
    workflowMasterId: '5e6f1d8f-97fe-11ed-9ad2-e14607466b3b',
    flowType: 'CARE_JOURNEY',
  },
];

export const APPOINTMENT_SELF_SCHEDULED = [
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    apptType: 'VIDEO',
    countNum: 31,
  },
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    apptType: 'IN PERSON',
    countNum: 41,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    apptType: 'IN PERSON',
    countNum: 25,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    apptType: 'VIDEO',
    countNum: 31,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    apptType: 'VIDEO',
    countNum: 35,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    apptType: 'IN PERSON',
    countNum: 40,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    apptType: 'IN PERSON',
    countNum: 23,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    apptType: 'VIDEO',
    countNum: 34,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    apptType: 'VIDEO',
    countNum: 22,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    apptType: 'IN PERSON',
    countNum: 36,
  },
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    apptType: 'VIDEO',
    countNum: 23,
  },
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    apptType: 'IN PERSON',
    countNum: 34,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    apptType: 'IN PERSON',
    countNum: 32,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    apptType: 'VIDEO',
    countNum: 35,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    apptType: 'IN PERSON',
    countNum: 54,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    apptType: 'VIDEO',
    countNum: 11,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    apptType: 'IN PERSON',
    countNum: 45,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    apptType: 'VIDEO',
    countNum: 35,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    apptType: 'VIDEO',
    countNum: 21,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    apptType: 'IN PERSON',
    countNum: 47,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    apptType: 'IN PERSON',
    countNum: 34,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    apptType: 'VIDEO',
    countNum: 50,
  },
];

export const TOP_IMAGING = [
  {
    displayText: 'Computed Tomography (CT)',
    code: 'I48.91',
    countNum: 68,
  },
  {
    displayText: 'Magnetic Resonance Imaging (MRI)',
    code: 'H66.92',
    countNum: 90,
  },
  {
    displayText: 'Ultrasound',
    code: 'E66.9',
    countNum: 72,
  },
  {
    displayText: 'Mammography',
    code: 'F41.9',
    countNum: 39,
  },
  {
    displayText: 'Positron Emission Tomography (PET)',
    code: 'L50.9',
    countNum: 44,
  },
  {
    displayText: 'Electroencephalography (EEG)',
    code: 'M19.90',
    countNum: 88,
  },
  {
    displayText: 'Optical Coherence Tomography (OCT)',
    code: 'I10',
    countNum: 51,
  },
  {
    displayText: 'Functional Magnetic Resonance Imaging (fMRI)',
    code: 'R73.03',
    countNum: 61,
  },
  {
    displayText: 'Radiofrequency ablation',
    code: 'G47.00',
    countNum: 77,
  },
];
export const TOP_LABS = [
  {
    displayText: 'Hemoglobin A1c',
    code: 'I48.91',
    countNum: 68,
  },
  {
    displayText: 'Lipid Panel',
    code: 'H66.92',
    countNum: 90,
  },
  {
    displayText: 'CBC With Differential/Platelet',
    code: 'E66.9',
    countNum: 72,
  },
  {
    displayText: 'Vitamin D, 25-Hydroxy',
    code: 'F41.9',
    countNum: 39,
  },
  {
    displayText: 'Glucose',
    code: 'L50.9',
    countNum: 44,
  },
  {
    displayText: 'TSH',
    code: 'M19.90',
    countNum: 88,
  },
  {
    displayText: 'Comp. Metabolic Panel (14)',
    code: 'I10',
    countNum: 51,
  },
  {
    displayText: 'Prostate-Specific Ag',
    code: 'R73.03',
    countNum: 61,
  },
  {
    displayText: "Cyanocobalamin vitamin b-12",
    code: "82607",
    countNum: 5,
  },
  {
    displayText: "UA; urinalysis",
    code: "81003",
    countNum: 2,
  }
];
export const TOP_CARE_JOURNEYS = [
  {
    displayText: 'Emergency care',
    code: 'I48.91',
    countNum: 68,
  },
  {
    displayText: 'Mental health care',
    code: 'H66.92',
    countNum: 90,
  },
  {
    displayText: 'Chronic kidney disease management:',
    code: 'E66.9',
    countNum: 72,
  },
  {
    displayText: 'Chronic disease management',
    code: 'F41.9',
    countNum: 39,
  },
  {
    displayText: 'Rehabilitation after accidents',
    code: 'L50.9',
    countNum: 44,
  },
  {
    displayText: 'Pre diabetic power plan',
    code: 'M19.90',
    countNum: 88,
  },
];

export const TOP_SYMPTOMS_CHIEF_COMPLAINTS = [
  {
    displayText: 'Obesity',
    code: 'E66.9',
    countNum: 22,
  },
  {
    displayText: 'Hypertension',
    code: 'I10',
    countNum: 18,
  },
  {
    displayText: 'HLD (hyperlipidemia)',
    code: 'E78.5',
    countNum: 16,
  },
  {
    displayText: 'Vitamin D deficiency',
    code: 'E55.9',
    countNum: 16,
  },
  {
    displayText: 'Prediabetes',
    code: 'R73.03',
    countNum: 14,
  },
  {
    displayText: 'Diabetes',
    code: 'E11.9',
    countNum: 12,
  },
  {
    displayText: "Midsternal chest pain",
    code: "786.51",
    countNum: 9,
  },
  {
    displayText: 'Seasonal allergies',
    code: 'J30.2',
    countNum: 7,
  },
  {
    displayText: "Gastro-esophageal reflux disease without esophagitis",
    code: "K21.9",
    countNum: 5,
  },
  {
    displayText: 'Anxiety',
    code: 'F41.9',
    countNum: 5,
  },
];

export const TOP_MEDICATIONS = [
  {
    displayText: 'Medrol Tab Therapy Pack 4 mg',
    code: '200224',
    countNum: 110,
  },
  {
    displayText: 'Zithromax Z-Pak Tab 250 mg',
    code: '308460',
    countNum: 210,
  },
  {
    displayText: 'metFORMIN Tab 500 mg',
    code: '861007',
    countNum: 120,
  },
  {
    displayText: 'traMADol Tab 50 mg',
    code: '197397',
    countNum: 220,
  },
  {
    displayText: 'Lisinopril Tab 10 mg',
    code: '240029',
    countNum: 160,
  },
  {
    displayText: 'hydroCHLOROthiazide Tab 25 mg',
    code: null,
    countNum: 101,
  },
  {
    displayText: '108 (90 Base) MCG/ACT',
    code: '314076',
    countNum: 281,
  },
  {
    displayText: 'Vitamin D3 Cap 1.25 mg (50000 UT)',
    code: '308047',
    countNum: 310,
  },
  {
    displayText: 'Albuterol Sulfate HFA Inhalation Aerosol Solution',
    code: '896321',
    countNum: 130,
  },
];

export const LAB_SAVINGS = {
  currentDateResp: {
    totalDpcCost: 3537,
    totalMarketCost: 282550,
  },
  previousDateResp: {
    totalDpcCost: 2100,
    totalMarketCost: 3481,
  },
};
export const TOTAL_SAVINGS = {
  currentDateRangeTotalCostSavingData: {
    totalDpcCost: 28205,
    totalMarketCost: 1002500,
    totalMembershipCost: 15000,
    totalServiceCost: 13205,
  },
  totalCostSavingHistoryData: {
    '2023_January': {
      totalDpcCost: 121,
      totalMarketCost: 141,
    },
    '2022_December': {
      totalDpcCost: 3267,
      totalMarketCost: 3807,
    },
    '2022_November': {
      totalDpcCost: 3388,
      totalMarketCost: 3948,
    },
    '2022_October': {
      totalDpcCost: 7260,
      totalMarketCost: 8460,
    },
    '2022_September': {
      totalDpcCost: 7381,
      totalMarketCost: 8601,
    },
    '2022_August': {
      totalDpcCost: 9680,
      totalMarketCost: 11280,
    },
    '2022_July': {
      totalDpcCost: 6655,
      totalMarketCost: 7755,
    },
    '2022_June': {
      totalDpcCost: 6292,
      totalMarketCost: 7332,
    },
    '2022_May': {
      totalDpcCost: 1089,
      totalMarketCost: 1269,
    },
    '2022_April': {
      totalDpcCost: 363,
      totalMarketCost: 423,
    },
    '2022_March': {
      totalDpcCost: 1035,
      totalMarketCost: 1215,
    },
    '2022_February': {
      totalDpcCost: 242,
      totalMarketCost: 282,
    },
    '2022_January': {
      totalDpcCost: 242,
      totalMarketCost: 282,
    },
    '2021_December': {
      totalDpcCost: 1694,
      totalMarketCost: 1974,
    },
    '2021_November': {
      totalDpcCost: 484,
      totalMarketCost: 564,
    },
    '2021_October': {
      totalDpcCost: 726,
      totalMarketCost: 846,
    },
    '2021_September': {
      totalDpcCost: 968,
      totalMarketCost: 1128,
    },
    '2021_August': {
      totalDpcCost: 847,
      totalMarketCost: 987,
    },
    '2021_July': {
      totalDpcCost: 242,
      totalMarketCost: 282,
    },
    '2021_June': {
      totalDpcCost: 484,
      totalMarketCost: 564,
    },
    '2021_May': {
      totalDpcCost: 726,
      totalMarketCost: 846,
    },
    '2021_April': {
      totalDpcCost: 484,
      totalMarketCost: 564,
    },
    '2021_March': {
      totalDpcCost: 242,
      totalMarketCost: 282,
    },
    '2021_February': {
      totalDpcCost: 605,
      totalMarketCost: 705,
    },
    '2021_January': {
      totalDpcCost: 242,
      totalMarketCost: 282,
    },
    '2020_December': {
      totalDpcCost: 363,
      totalMarketCost: 423,
    },
    '2020_November': {
      totalDpcCost: 121,
      totalMarketCost: 141,
    },
    '2020_October': {
      totalDpcCost: 242,
      totalMarketCost: 282,
    },
    '2020_September': {
      totalDpcCost: 1331,
      totalMarketCost: 1551,
    },
    '2020_August': {
      totalDpcCost: 1331,
      totalMarketCost: 1551,
    },
    '2020_July': {
      totalDpcCost: 1331,
      totalMarketCost: 1551,
    },
    '2020_June': {
      totalDpcCost: 1349,
      totalMarketCost: 1569,
    },
    '2020_May': {
      totalDpcCost: 726,
      totalMarketCost: 846,
    },
    '2020_April': {
      totalDpcCost: 1089,
      totalMarketCost: 1269,
    },
    '2020_March': {
      totalDpcCost: 847,
      totalMarketCost: 987,
    },
    '2020_February': {
      totalDpcCost: 726,
      totalMarketCost: 846,
    },
    '2020_January': {
      totalDpcCost: 1936,
      totalMarketCost: 2256,
    },
    '2019_December': {
      totalDpcCost: 1089,
      totalMarketCost: 1269,
    },
    '2019_November': {
      totalDpcCost: 242,
      totalMarketCost: 282,
    },
    '2019_October': {
      totalDpcCost: 726,
      totalMarketCost: 846,
    },
    '2019_September': {
      totalDpcCost: 242,
      totalMarketCost: 282,
    },
    '2019_August': {
      totalDpcCost: 484,
      totalMarketCost: 564,
    },
    '2019_July': {
      totalDpcCost: 0,
      totalMarketCost: 0,
    },
    '2019_June': {
      totalDpcCost: 121,
      totalMarketCost: 141,
    },
  },
};
export const IMAGING_SAVINGS = {
  currentDateResp: {
    totalDpcCost: 6758,
    totalMarketCost: 449450,
  },
  previousDateResp: {
    totalDpcCost: 4200,
    totalMarketCost: 5620,
  },
};
export const PROCEDURES_SAVINGS = {
  currentDateResp: {
    totalDpcCost: 1655,
    totalMarketCost: 137750,
  },
  previousDateResp: {
    totalDpcCost: 900,
    totalMarketCost: 1100,
  },
};
export const VISITS_SAVINGS = {
  currentDateResp: {
    totalDpcCost: 1255,
    totalMarketCost: 132750,
  },
  previousDateResp: {
    totalDpcCost: 530,
    totalMarketCost: 941,
  },
};

export const TOP_DIAGNOSIS = [
  {
    displayText: 'Other specified counseling',
    code: 'Z71.89',
    employeeType: 'employee',
    countNum: 7,
    dependentCount: 1,
    totalCount: 8,
    employeeCount: 7,
  },
  {
    displayText: 'COVID-19',
    code: 'U07.1',
    employeeType: 'employee',
    countNum: 6,
    dependentCount: 1,
    totalCount: 7,
    employeeCount: 6,
  },
  {
    displayText:
      'Contact with and (suspected) exposure to other viral communicableeases',
    code: 'Z20.828',
    employeeType: 'employee',
    countNum: 5,
    dependentCount: 1,
    totalCount: 6,
    employeeCount: 5,
  },
  {
    displayText:
      'Encounter for general adult medical examination without abnormal ings',
    code: 'Z00.00',
    employeeType: 'employee',
    countNum: 4,
    dependentCount: 2,
    totalCount: 6,
    employeeCount: 4,
  },
  {
    displayText: 'Hyperlipidemia, unspecified',
    code: 'E78.5',
    employeeType: 'employee',
    countNum: 4,
    dependentCount: 1,
    totalCount: 5,
    employeeCount: 4,
  },
];

export const EMPLOYEE_AGE_GROUP = [
  {
    displayText: '51 & over',
    countNum: 10000,
  },
  {
    displayText: '31 - 50',
    countNum: 15000,
  },
  {
    displayText: '10 - 20',
    countNum: 8000,
  },
  {
    displayText: '5 - 10',
    countNum: 5000,
  },
  {
    displayText: 'under 5',
    countNum: 2200,
  },
];

export const EMPLOYEE_GENDER_GROUP = [
  {
    birth_sex_id: 'a28d4009-2274-4d61-864c-ffc1a1223ccf',
    code: 'M',
    displayText: 'Male',
    countNum: 20000,
  },
  {
    birth_sex_id: '1b39175f-f786-4ab3-9dd8-f87fb582e121',
    code: 'F',
    displayText: 'Female',
    countNum: 15000,
  },
  {
    birth_sex_id: '2fbee6b6-6d7b-4bf7-b030-f0cda979e728',
    code: 'UNK',
    displayText: 'Unknown',
    countNum: 4296,
  },
];

export const EMPLOYEE_COUNT = {
  "result": {
    "columnStackDataList": [
      {
        "displayText": "Inactive Members",
        "month": "January",
        "monthDt": {
          "value": "2023-01-01"
        },
        "countNum": 81
      },
      {
        "displayText": "Inactive Members",
        "month": "January",
        "monthDt": {
          "value": "2023-01-01"
        },
        "countNum": 5
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "countNum": 1221
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "countNum": 297
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "countNum": 332
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "countNum": 225
      },
      {
        "displayText": "Inactive Members",
        "month": "February",
        "monthDt": {
          "value": "2023-02-01"
        },
        "countNum": 89
      },
      {
        "displayText": "Inactive Members",
        "month": "February",
        "monthDt": {
          "value": "2023-02-01"
        },
        "countNum": 14
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "countNum": 1197
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "countNum": 289
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "countNum": 334
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "countNum": 227
      },
      {
        "displayText": "one off",
        "empCode": "one-off",
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "countNum": 8
      },
      {
        "displayText": "Inactive Members",
        "month": "March",
        "monthDt": {
          "value": "2023-03-01"
        },
        "countNum": 42
      },
      {
        "displayText": "Inactive Members",
        "month": "March",
        "monthDt": {
          "value": "2023-03-01"
        },
        "countNum": 5
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "countNum": 1166
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "countNum": 287
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "countNum": 322
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "countNum": 224
      },
      {
        "displayText": "one off",
        "empCode": "one-off",
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "countNum": 33
      },
      {
        "displayText": "Inactive Members",
        "month": "April",
        "monthDt": {
          "value": "2023-04-01"
        },
        "countNum": 56
      },
      {
        "displayText": "Inactive Members",
        "month": "April",
        "monthDt": {
          "value": "2023-04-01"
        },
        "countNum": 8
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "countNum": 1163
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "countNum": 283
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "countNum": 324
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "countNum": 225
      },
      {
        "displayText": "one off",
        "empCode": "one-off",
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "countNum": 21
      },
      {
        "displayText": "Inactive Members",
        "month": "May",
        "monthDt": {
          "value": "2023-05-01"
        },
        "countNum": 27
      },
      {
        "displayText": "Inactive Members",
        "month": "May",
        "monthDt": {
          "value": "2023-05-01"
        },
        "countNum": 7
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "countNum": 1142
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "countNum": 280
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "countNum": 320
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "countNum": 222
      },
      {
        "displayText": "one off",
        "empCode": "one-off",
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "countNum": 41
      },
      {
        "displayText": "Inactive Members",
        "month": "June",
        "monthDt": {
          "value": "2023-06-01"
        },
        "countNum": 88
      },
      {
        "displayText": "Inactive Members",
        "month": "June",
        "monthDt": {
          "value": "2023-06-01"
        },
        "countNum": 3
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "countNum": 1146
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "countNum": 279
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "countNum": 319
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "countNum": 219
      },
      {
        "displayText": "one off",
        "empCode": "one-off",
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "countNum": 631
      },
      {
        "displayText": "Inactive Members",
        "month": "July",
        "monthDt": {
          "value": "2023-07-01"
        },
        "countNum": 116
      },
      {
        "displayText": "Inactive Members",
        "month": "July",
        "monthDt": {
          "value": "2023-07-01"
        },
        "countNum": 4
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "countNum": 1190
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "countNum": 278
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "countNum": 319
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "countNum": 224
      },
      {
        "displayText": "one off",
        "empCode": "one-off",
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "countNum": 33
      },
      {
        "displayText": "Inactive Members",
        "month": "August",
        "monthDt": {
          "value": "2023-08-01"
        },
        "countNum": 50
      },
      {
        "displayText": "Inactive Members",
        "month": "August",
        "monthDt": {
          "value": "2023-08-01"
        },
        "countNum": 5
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "countNum": 1219
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "countNum": 278
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "countNum": 329
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "countNum": 221
      },
      {
        "displayText": "one off",
        "empCode": "one-off",
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "countNum": 93
      },
      {
        "displayText": "Inactive Members",
        "month": "September",
        "monthDt": {
          "value": "2023-09-01"
        },
        "countNum": 56
      },
      {
        "displayText": "Inactive Members",
        "month": "September",
        "monthDt": {
          "value": "2023-09-01"
        },
        "countNum": 6
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "countNum": 1052
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "countNum": 282
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "countNum": 299
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "countNum": 236
      },
      {
        "displayText": "Inactive Members",
        "month": "October",
        "monthDt": {
          "value": "2023-10-01"
        },
        "countNum": 23
      },
      {
        "displayText": "Inactive Members",
        "month": "October",
        "monthDt": {
          "value": "2023-10-01"
        },
        "countNum": 13
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "countNum": 1028
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "countNum": 276
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "countNum": 292
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "countNum": 233
      },
      {
        "displayText": "one off",
        "empCode": "one-off",
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "countNum": 2
      },
      {
        "displayText": "Inactive Members",
        "month": "November",
        "monthDt": {
          "value": "2023-11-01"
        },
        "countNum": 12
      },
      {
        "displayText": "Inactive Members",
        "month": "November",
        "monthDt": {
          "value": "2023-11-01"
        },
        "countNum": 11
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "countNum": 1018
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "countNum": 276
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "countNum": 292
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "countNum": 226
      },
      {
        "displayText": "Inactive Members",
        "month": "December",
        "monthDt": {
          "value": "2023-12-01"
        },
        "countNum": 90
      },
      {
        "displayText": "Inactive Members",
        "month": "December",
        "monthDt": {
          "value": "2023-12-01"
        },
        "countNum": 5
      },
      {
        "displayText": "Employee",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "countNum": 1058
      },
      {
        "displayText": "Spouse",
        "empCode": "spouse",
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "countNum": 285
      },
      {
        "displayText": "Child",
        "empCode": "child",
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "countNum": 295
      },
      {
        "displayText": "Self-Sponsored",
        "empCode": "self-sponsored",
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "countNum": 223
      }
    ],
    "lineDataList": [
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-09-01"
        },
        "month": "September",
        "countNum": 1869
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-10-01"
        },
        "month": "October",
        "countNum": 1831
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-11-01"
        },
        "month": "November",
        "countNum": 1812
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-12-01"
        },
        "month": "December",
        "countNum": 1861
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January",
        "countNum": 2075
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February",
        "countNum": 2055
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March",
        "countNum": 2032
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April",
        "countNum": 2016
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May",
        "countNum": 2005
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June",
        "countNum": 2594
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July",
        "countNum": 2044
      },
      {
        "displayText": "Active Members",
        "empCode": "employee",
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August",
        "countNum": 2140
      }
    ]
  }
};


export const NET_PROMOTER_SCORE = {
  "result": {
    "columnStackDataList": [
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 70,
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January"
      },
      {
        "displayText": "Passives",
        "filterCode": "PASSIVES",
        "countNum": 60,
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January"
      },
      {
        "displayText": "Detractors",
        "filterCode": "DETRACTORS",
        "countNum": 14,
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 60,
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February"
      },
      {
        "displayText": "Passives",
        "filterCode": "PASSIVES",
        "countNum": 50,
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February"
      },
      {
        "displayText": "Detractors",
        "filterCode": "DETRACTORS",
        "countNum": 12,
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 50,
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March"
      },
      {
        "displayText": "Passives",
        "filterCode": "PASSIVES",
        "countNum": 40,
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March"
      },
      {
        "displayText": "Detractors",
        "filterCode": "DETRACTORS",
        "countNum": 10,
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 40,
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April"
      },
      {
        "displayText": "Passives",
        "filterCode": "PASSIVES",
        "countNum": 30,
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April"
      },
      {
        "displayText": "Detractors",
        "filterCode": "DETRACTORS",
        "countNum": 8,
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 30,
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May"
      },
      {
        "displayText": "Passives",
        "filterCode": "PASSIVES",
        "countNum": 20,
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May"
      },
      {
        "displayText": "Detractors",
        "filterCode": "DETRACTORS",
        "countNum": 6,
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 20,
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June"
      },
      {
        "displayText": "Passives",
        "filterCode": "PASSIVES",
        "countNum": 10,
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June"
      },
      {
        "displayText": "Detractors",
        "filterCode": "DETRACTORS",
        "countNum": 4,
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 10,
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July"
      },
      {
        "displayText": "Passives",
        "filterCode": "PASSIVES",
        "countNum": 5,
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July"
      },
      {
        "displayText": "Detractors",
        "filterCode": "DETRACTORS",
        "countNum": 2,
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 4,
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August"
      },
      {
        "displayText": "Passives",
        "filterCode": "PASSIVES",
        "countNum": 1,
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August"
      },
      {
        "displayText": "Detractors",
        "filterCode": "DETRACTORS",
        "countNum": 1,
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August"
      },
    ],
    "lineDataList": [
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 4,
        "monthDt": {
          "value": "2023-08-01"
        },
        "month": "August"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 10,
        "monthDt": {
          "value": "2023-07-01"
        },
        "month": "July"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 20,
        "monthDt": {
          "value": "2023-06-01"
        },
        "month": "June"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 30,
        "monthDt": {
          "value": "2023-05-01"
        },
        "month": "May"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 40,
        "monthDt": {
          "value": "2023-04-01"
        },
        "month": "April"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 50,
        "monthDt": {
          "value": "2023-03-01"
        },
        "month": "March"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 60,
        "monthDt": {
          "value": "2023-02-01"
        },
        "month": "February"
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 70,
        "monthDt": {
          "value": "2023-01-01"
        },
        "month": "January"
      },
    ]
  },
  "additionalData": {
    "formResponseDetails": {
      "completionRate": 90,
      "pendingFormLogCount": 9,
      "draftFormLogCount": 1,
      "submittedFormLogCount": 90,
      "totalFormSendCount": 100,
      "notResponded": 9
    },
    "averageFormScore": {
      "avgScorePercentage": null
    },
    "averageRatingScoreByType": [
      {
        "displayText": "NPS Score",
        "filterCode": "NPS_SCORE",
        "avgScorePercentage": 85
      },
      {
        "displayText": "Detractors",
        "filterCode": "DETRACTORS",
        "countNum": 8,
        "avgScorePercentage": 8
      },
      {
        "displayText": "Passives",
        "filterCode": "PASSIVES",
        "countNum": 7,
        "avgScorePercentage": 7
      },
      {
        "displayText": "Promoters",
        "filterCode": "PROMOTERS",
        "countNum": 93,
        "avgScorePercentage": 93
      }
    ],
    "npsCatRatingList": [
      0,
      0.16,
      0.16,
      0.5,
      0.66,
      1
    ]
  }
};

export const CUSTOMER_SATISFACTION = {
  "result": [
    {
      "displayText": "Not Responded",
      "filterCode": "NOT_SUBMITTED",
      "countNum": 15,
      "monthDt": {
        "value": "2023-08-01"
      },
      "month": "August"
    },
    {
      "displayText": "Responded",
      "filterCode": "SUBMITTED",
      "countNum": 200,
      "monthDt": {
        "value": "2023-08-01"
      },
      "month": "August"
    },
    {
      "displayText": "Not Responded",
      "filterCode": "NOT_SUBMITTED",
      "countNum": 5,
      "monthDt": {
        "value": "2023-07-01"
      },
      "month": "July"
    },
    {
      "displayText": "Responded",
      "filterCode": "SUBMITTED",
      "countNum": 10,
      "monthDt": {
        "value": "2023-07-01"
      },
      "month": "July"
    },
    {
      "displayText": "Not Responded",
      "filterCode": "NOT_SUBMITTED",
      "countNum": 10,
      "monthDt": {
        "value": "2023-06-01"
      },
      "month": "June"
    },
    {
      "displayText": "Responded",
      "filterCode": "SUBMITTED",
      "countNum": 20,
      "monthDt": {
        "value": "2023-06-01"
      },
      "month": "June"
    },
    {
      "displayText": "Not Responded",
      "filterCode": "NOT_SUBMITTED",
      "countNum": 15,
      "monthDt": {
        "value": "2023-05-01"
      },
      "month": "May"
    },
    {
      "displayText": "Responded",
      "filterCode": "SUBMITTED",
      "countNum": 30,
      "monthDt": {
        "value": "2023-05-01"
      },
      "month": "May"
    },
    {
      "displayText": "Not Responded",
      "filterCode": "NOT_SUBMITTED",
      "countNum": 20,
      "monthDt": {
        "value": "2023-04-01"
      },
      "month": "April"
    },
    {
      "displayText": "Responded",
      "filterCode": "SUBMITTED",
      "countNum": 40,
      "monthDt": {
        "value": "2023-04-01"
      },
      "month": "April"
    },
    {
      "displayText": "Not Responded",
      "filterCode": "NOT_SUBMITTED",
      "countNum": 25,
      "monthDt": {
        "value": "2023-03-01"
      },
      "month": "March"
    },
    {
      "displayText": "Responded",
      "filterCode": "SUBMITTED",
      "countNum": 50,
      "monthDt": {
        "value": "2023-03-01"
      },
      "month": "March"
    },
    {
      "displayText": "Not Responded",
      "filterCode": "NOT_SUBMITTED",
      "countNum": 30,
      "monthDt": {
        "value": "2023-02-01"
      },
      "month": "February"
    },
    {
      "displayText": "Responded",
      "filterCode": "SUBMITTED",
      "countNum": 60,
      "monthDt": {
        "value": "2023-02-01"
      },
      "month": "February"
    },
    {
      "displayText": "Not Responded",
      "filterCode": "NOT_SUBMITTED",
      "countNum": 35,
      "monthDt": {
        "value": "2023-01-01"
      },
      "month": "January"
    },
    {
      "displayText": "Responded",
      "filterCode": "SUBMITTED",
      "countNum": 70,
      "monthDt": {
        "value": "2023-01-01"
      },
      "month": "January"
    }
  ],
  "additionalData": {
    "formResponseDetails": {
      "completionRate": 0.5256241787122208,
      "pendingFormLogCount": 15,
      "draftFormLogCount": 0,
      "submittedFormLogCount": 4,
      "totalFormSendCount": 100,
      "notResponded": 15
    },
    "averageFormScore": {
      "avgScore": 20
    }
  }
};
export const LOGGED_IN_COUNT = [
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    displayText: 'Employee',
    countNum: 31,
  },
  {
    monthDt: {
      value: '2023-12-01',
    },
    month: 'December',
    displayText: 'Dependent',
    countNum: 41,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    displayText: 'Dependent',
    countNum: 25,
  },
  {
    monthDt: {
      value: '2023-11-01',
    },
    month: 'November',
    displayText: 'Employee',
    countNum: 31,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    displayText: 'Employee',
    countNum: 35,
  },
  {
    monthDt: {
      value: '2023-10-01',
    },
    month: 'October',
    displayText: 'Dependent',
    countNum: 40,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    displayText: 'Dependent',
    countNum: 23,
  },
  {
    monthDt: {
      value: '2023-09-01',
    },
    month: 'September',
    displayText: 'Employee',
    countNum: 34,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    displayText: 'Employee',
    countNum: 22,
  },
  {
    monthDt: {
      value: '2023-08-01',
    },
    month: 'August',
    displayText: 'Dependent',
    countNum: 36,
  },
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    displayText: 'Employee',
    countNum: 23,
  },
  {
    monthDt: {
      value: '2023-07-01',
    },
    month: 'July',
    displayText: 'Dependent',
    countNum: 34,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    displayText: 'Dependent',
    countNum: 32,
  },
  {
    monthDt: {
      value: '2023-06-01',
    },
    month: 'June',
    displayText: 'Employee',
    countNum: 35,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    displayText: 'Dependent',
    countNum: 54,
  },
  {
    monthDt: {
      value: '2023-05-01',
    },
    month: 'May',
    displayText: 'Employee',
    countNum: 11,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    displayText: 'Dependent',
    countNum: 45,
  },
  {
    monthDt: {
      value: '2023-04-01',
    },
    month: 'April',
    displayText: 'Employee',
    countNum: 35,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    displayText: 'Employee',
    countNum: 21,
  },
  {
    monthDt: {
      value: '2023-03-01',
    },
    month: 'March',
    displayText: 'Dependent',
    countNum: 47,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    displayText: 'Dependent',
    countNum: 34,
  },
  {
    monthDt: {
      value: '2023-02-01',
    },
    month: 'February',
    displayText: 'Employee',
    countNum: 50,
  },
];

export const TEMP_EMP_ONE: any = {
  name: 'ABc Llp',

  id: '90bd02d3-16df-4991-8b12-a39461b70711',
};
export const TEMP_EMP_TWO: any = {
  name: 'Brix Inc',

  id: '61a3427e-47ef-44c4-8d57-2fe4cad50d7d',
};

export const costSavingDetail = [
  {
    id: '5a4648d5-b04b-42e4-ae1d-83aea6ad8cdb',
    accountVisitTypeId: '64ec02cc-6081-46db-96d8-fd501a19a6cf',
    accountUuid: '02a088ac-9a36-4886-b3d8-41ba2c74ba5b',
    accountPracticeLocationId: null,
    zipcodeId: null,
    price: 83,
    priceData: {},
    currentMarketPrice: 93,
    marketPriceData: {},
    isDeleted: false,
    createdBy: null,
    updatedBy: null,
    createdAt: '2023-12-29T08:00:23.230Z',
    updatedAt: '2023-12-29T08:00:23.230Z',
    accountVisitTypes: {
      id: '64ec02cc-6081-46db-96d8-fd501a19a6cf',
      visitTypeId: 'd925fdf9-6717-47a5-847e-1786b3a97f73',
      accountUuid: '02a088ac-9a36-4886-b3d8-41ba2c74ba5b',
      employerUuid: '797025ec-2c40-4643-9189-926ad6862d61',
      totalQuantity: 59,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: '2023-12-29T08:00:23.230Z',
      updatedAt: '2023-12-29T08:00:23.230Z',
      visitTypes: {
        id: 'd925fdf9-6717-47a5-847e-1786b3a97f73',
        name: 'Office Visit',
        code: null,
        codeType: '',
        description: null,
        additionalAttribute: {
          coding: [
            {
              code: 'Office Visit',
              system: 'https://hl7.org/fhir/v2/ValueSet/appointment-type',
              display: 'Office Visit',
              version: null,
              userSelected: null,
            },
          ],
        },
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: '2023-12-29T08:00:19.887Z',
        updatedAt: '2023-12-29T08:00:19.887Z',
      },
    },
  },
  {
    id: 'aa5e3d1a-1920-475d-bd86-137e11e809a6',
    accountVisitTypeId: 'f9e22285-bdd0-46f6-886e-ed157a1983bb',
    accountUuid: 'c4d1b592-5cd7-4ffc-8d8d-976349a092bf',
    accountPracticeLocationId: null,
    zipcodeId: null,
    price: 38,
    priceData: {},
    currentMarketPrice: 48,
    marketPriceData: {},
    isDeleted: false,
    createdBy: null,
    updatedBy: null,
    createdAt: '2023-12-29T08:00:19.888Z',
    updatedAt: '2023-12-29T08:00:19.888Z',
    accountVisitTypes: {
      id: 'f9e22285-bdd0-46f6-886e-ed157a1983bb',
      visitTypeId: 'd925fdf9-6717-47a5-847e-1786b3a97f73',
      accountUuid: 'c4d1b592-5cd7-4ffc-8d8d-976349a092bf',
      employerUuid: 'bd9b3ba8-32b4-4d8f-a559-b84aa331b872',
      totalQuantity: 59,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: '2023-12-29T08:00:19.888Z',
      updatedAt: '2023-12-29T08:00:19.887Z',
      visitTypes: {
        id: 'd925fdf9-6717-47a5-847e-1786b3a97f73',
        name: 'Office Visit',
        code: null,
        codeType: '',
        description: null,
        additionalAttribute: {
          coding: [
            {
              code: 'Office Visit',
              system: 'https://hl7.org/fhir/v2/ValueSet/appointment-type',
              display: 'Office Visit',
              version: null,
              userSelected: null,
            },
          ],
        },
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: '2023-12-29T08:00:19.887Z',
        updatedAt: '2023-12-29T08:00:19.887Z',
      },
    },
  },
];

export const VISIT_SAVING_DRAWER_DATA = [
  {
    name: 'Office visit est, straightforward (99212)',
    marketCost: '189',
    dpcCost: '120',
    quantity: '134',
    spendings: '32999',
    discount: '40',
    saving: '2999',
  },
  {
    name: 'Office Visit est, Low complexity (99213)',
    marketCost: '178',
    dpcCost: '130',
    quantity: '68',
    spendings: '32999',
    discount: '40',
    saving: '2999',
  },
  {
    name: 'Office Visit est, moderate complexity (99214)',
    marketCost: '150',
    dpcCost: '100',
    quantity: '148',
    spendings: '19229',
    discount: '40',
    saving: '2323',
  },
  {
    name: 'New patient, low complexity office visit (99203)',
    marketCost: '190',
    dpcCost: '100',
    quantity: '300',
    spendings: '19229',
    discount: '40',
    saving: '2323',
  },
];


export const AVOIDED_WORKDAY_ABSENCE_SAVING_DRAWER_DATA = [
  {
    name: 'Avoided Workday Absence',
    marketCost: '189',
    dpcCost: '120',
    quantity: '134',
    spendings: '32999',
    discount: '40',
    saving: '2999',
  },
];

export const AVOIDABLE_VISIT_SAVING_DRAWER_DATA = [
  {
    name: 'Avoided Urgent Care Visit',
    marketCost: '189',
    dpcCost: '120',
    quantity: '134',
    spendings: '32999',
    discount: '40',
    saving: '2999',
  },
];

export const IMG_SAVING_DRAWER_DATA = [
  {
    name: 'Mammogram',
    marketCost: '151',
    dpcCost: '122',
    quantity: '10',
    spendings: '32999',
    discount: '40',
    saving: '2999',
  },
  {
    name: 'X-Ray   5 toes of the left foot  ',
    marketCost: '50',
    dpcCost: '40',
    quantity: '7',
    spendings: '32999',
    discount: '40',
    saving: '2999',
  },
  {
    name: 'Chest Xray',
    marketCost: '90',
    dpcCost: '70',
    quantity: '39',
    spendings: '19229',
    discount: '40',
    saving: '2323',
  },
  {
    name: 'Bone Density: DEXA Scan',
    marketCost: '110',
    dpcCost: '90',
    quantity: '28',
    spendings: '19229',
    discount: '40',
    saving: '2323',
  },
];

export const LAB_SAVING_DRAWER_DATA = [
  {
    name: 'Lipid Panel',
    marketCost: '189',
    dpcCost: '133',
    quantity: '182',
    spendings: '32999',
    discount: '40',
    saving: '2999',
  },
  {
    name: 'Comp. Metabolic Panel (14)',
    marketCost: '165',
    dpcCost: '135',
    quantity: '89',
    spendings: '32999',
    discount: '40',
    saving: '2999',
  },
  {
    name: 'Thyroxine (T4) Free, Direct, S',
    marketCost: '180',
    dpcCost: '90',
    quantity: '210',
    spendings: '19229',
    discount: '40',
    saving: '2323',
  },
  {
    name: 'CBC With Differential/Platelet',
    marketCost: '200',
    dpcCost: '170',
    quantity: '400',
    spendings: '19229',
    discount: '40',
    saving: '2323',
  },
];

export const PROC_SAVING_DRAWER_DATA = [
  {
    name: 'Infection removal on Spinal Cord',
    marketCost: '3000',
    dpcCost: '2300',
    quantity: '2',
    spendings: '32999',
    discount: '40',
    saving: '2999',
  },
  {
    name: 'Gallbladder removal',
    marketCost: '2000',
    dpcCost: '1400',
    quantity: '12',
    spendings: '32999',
    discount: '40',
    saving: '2999',
  },
  {
    name: 'Dural arteriovenous fistula of spinal cord',
    marketCost: '300',
    dpcCost: '200',
    quantity: '21',
    spendings: '19229',
    discount: '40',
    saving: '2323',
  },
  {
    name: 'Removal impacted cerumen instrumentation unilat',
    marketCost: '1800',
    dpcCost: '1500',
    quantity: '13',
    spendings: '19229',
    discount: '40',
    saving: '2323',
  },
];

export const PRIMARY_VALUE = [
  {
    id: 1,
    code: 'CPT_CODE',
    value: 'CPT',
  },
  {
    id: 2,
    code: 'REVENUE_CODE',
    value: 'Revenue',
  },
];

export const TEMP_CPT_CODE = [
  {
    cptCode: '84270',
    id: '28189bb9-0b30-467b-8629-559ff0efb938',
  },
  {
    cptCode: '87427',
    id: '83c04b08-980a-4bd8-a1fd-57fc2172e8a6',
  },
  {
    cptCode: 'G8427',
    id: '1a34bdd6-952b-4519-bef8-07809b9975dc',
  },
];

export const TEMP_REVENUE_CODE = [
  {
    revenueCode: null,
    id: '18ac742c-cc11-473c-a32f-fad2bce41fba',
  },
  {
    revenueCode: '490',
    id: 'f198d237-00d3-4783-b1e1-902426de8015',
  },
  {
    revenueCode: '450',
    id: 'f37df550-246f-4dc7-8024-c5ba00c02ab3',
  },
  {
    revenueCode: '306',
    id: 'afdf1023-b102-4cb1-a3a2-29f5acba73c9',
  },
  {
    revenueCode: '750',
    id: 'a7770b1c-9d0d-4754-9d26-42979a25650c',
  },
  {
    revenueCode: '483',
    id: '5431032d-9f06-4dfa-b0fa-09666f77e427',
  },
  {
    revenueCode: '341',
    id: '19f12409-cd0d-4b1a-ab0e-2de6e4b5723e',
  },
  {
    revenueCode: '301',
    id: 'ea1ff576-41cf-4acc-addb-e08e5ab723ad',
  },
  {
    revenueCode: '305',
    id: 'e46d9f07-0c0a-4436-b2c6-aa6fd1ab0562',
  },
  {
    revenueCode: '307',
    id: 'ec4be450-6789-46f8-b4c2-aa6de183dd1d',
  },
  {
    revenueCode: '121',
    id: '80ea6c65-a18f-4251-9c3b-439b2fce0c3f',
  },
  {
    revenueCode: '240',
    id: '7a879518-a007-40b3-9ab0-c66712b8bf6c',
  },
  {
    revenueCode: '771',
    id: 'a21c03ee-8476-4b64-b2ea-ce862a692a6b',
  },
  {
    revenueCode: '636',
    id: '1c211b17-579b-47e5-9fef-7c15a8492c8d',
  },
  {
    revenueCode: '610',
    id: 'ea342463-8268-4b39-a817-71fa3bf6729f',
  },
  {
    revenueCode: 'NULL',
    id: 'bbc4e5d9-f1bc-4bbe-bb16-8438f1bb4f9c',
  },
];

export const X_AXIS_METRICS = [
  {
    id: 1,
    code: 'DURATION',
    value: 'Duration',
  },
];
export const Y_AXIS_METRICS = [
  {
    id: 1,
    code: 'COST',
    value: 'Cost',
  },
];
export const X_AXIS_DIMENSIONS = [
  {
    id: '1',
    value: 'Month',
    code: 'MONTH',
  },
  {
    id: '2',
    value: 'Quarter',
    code: 'QUARTER',
  },
  {
    id: '3',
    value: 'Year',
    code: 'YEAR',
  },
];

export const TEMP_APPOINTMENT_BY_DAY = {
  result: [
    {
      employeeType: 'Employee',
      day: 'Sunday',
      countNum: 527,
    },
    {
      employeeType: 'Dependent',
      day: 'Sunday',
      countNum: 347,
    },
    {
      employeeType: 'Employee',
      day: 'Monday',
      countNum: 384,
    },
    {
      employeeType: 'Dependent',
      day: 'Monday',
      countNum: 412,
    },
    {
      employeeType: 'Employee',
      day: 'Tuesday',
      countNum: 699,
    },
    {
      employeeType: 'Dependent',
      day: 'Tuesday',
      countNum: 359,
    },
    {
      employeeType: 'Employee',
      day: 'Wednesday',
      countNum: 459,
    },
    {
      employeeType: 'Dependent',
      day: 'Wednesday',
      countNum: 273,
    },
    {
      employeeType: 'Employee',
      day: 'Thursday',
      countNum: 835,
    },
    {
      employeeType: 'Dependent',
      day: 'Thursday',
      countNum: 232,
    },
    {
      employeeType: 'Employee',
      day: 'Friday',
      countNum: 238,
    },
    {
      employeeType: 'Dependent',
      day: 'Friday',
      countNum: 492,
    },
    {
      employeeType: 'Employee',
      day: 'Saturday',
      countNum: 468,
    },
    {
      employeeType: 'Dependent',
      day: 'Saturday',
      countNum: 375,
    },
  ],
};

export const TEMP_APPOINTMENT_BY_TIME = {
  result: [
    {
      employeeType: 'Employee',
      day: '00-01',
      timeGroup: '00-01',
      countNum: 12,
    },
    {
      employeeType: 'Dependent',
      day: '00-01',
      timeGroup: '00-01',
      countNum: 32,
    },
    {
      employeeType: 'Employee',
      timeGroup: '01-02',
      countNum: 45,
    },
    {
      employeeType: 'Dependent',
      day: '01-02',
      timeGroup: '01-02',
      countNum: 27,
    },
    {
      employeeType: 'Employee',
      day: '02-03',
      timeGroup: '02-03',
      countNum: 49,
    },
    {
      employeeType: 'Dependent',
      day: '02-03',
      timeGroup: '02-03',
      countNum: 30,
    },
    {
      employeeType: 'Employee',
      day: '03-04',
      timeGroup: '03-04',
      countNum: 36,
    },
    {
      employeeType: 'Dependent',
      day: '03-04',
      timeGroup: '03-04',
      countNum: 41,
    },
    {
      employeeType: 'Employee',
      day: '04-05',
      timeGroup: '04-05',
      countNum: 45,
    },
    {
      employeeType: 'Dependent',
      day: '04-05',
      timeGroup: '04-05',
      countNum: 90,
    },
    {
      employeeType: 'Employee',
      timeGroup: '05-06',
      countNum: 100,
    },
    {
      employeeType: 'Dependent',
      day: '05-06',
      timeGroup: '05-06',
      countNum: 21,
    },
    {
      employeeType: 'Employee',
      day: '06-07',
      timeGroup: '06-07',
      countNum: 44,
    },
    {
      employeeType: 'Dependent',
      day: '06-07',
      timeGroup: '06-07',
      countNum: 55,
    },
    {
      employeeType: 'Employee',
      day: '07-08',
      timeGroup: '07-08',
      countNum: 12,
    },
    {
      employeeType: 'Dependent',
      day: '07-08',
      timeGroup: '07-08',
      countNum: 120,
    },
    {
      employeeType: 'Employee',
      timeGroup: '08-09',
      countNum: 111,
    },
    {
      employeeType: 'Dependent',
      day: '08-09',
      timeGroup: '08-09',
      countNum: 60,
    },
    {
      employeeType: 'Dependent',
      timeGroup: '09-10',
      countNum: 112,
      day: '09-10',
    },
    {
      employeeType: 'Employee',
      day: '10-11',
      timeGroup: '10-11',
      countNum: 90,
    },
    {
      employeeType: 'Dependent',
      day: '10-11',
      timeGroup: '10-11',
      countNum: 77,
    },
    {
      employeeType: 'Employee',
      day: '11-12',
      timeGroup: '11-12',
      countNum: 140,
    },
    {
      employeeType: 'Dependent',
      timeGroup: '11-12',
      countNum: 133,
    },
    {
      employeeType: 'Employee',
      timeGroup: '12-13',
      countNum: 120,
    },
    {
      employeeType: 'Dependent',
      timeGroup: '12-13',
      countNum: 115,
    },
    {
      employeeType: 'Employee',
      timeGroup: '13-14',
      countNum: 88,
    },
    {
      employeeType: 'Dependent',
      day: '13-14',
      timeGroup: '13-14',
      countNum: 77,
    },
    {
      employeeType: 'Employee',
      timeGroup: '14-15',
      countNum: 66,
    },
    {
      employeeType: 'Dependent',
      day: '14-15',
      timeGroup: '14-15',
      countNum: 95,
    },
    {
      employeeType: 'Employee',
      day: '15-16',
      timeGroup: '15-16',
      countNum: 99,
    },
    {
      employeeType: 'Dependent',
      day: '15-16',
      timeGroup: '15-16',
      countNum: 44,
    },
    {
      employeeType: 'Employee',
      timeGroup: '16-17',
      countNum: 55,
    },
    {
      employeeType: 'Dependent',
      day: '16-17',
      timeGroup: '16-17',
      countNum: 110,
    },
    {
      employeeType: 'Employee',
      day: '17-18',
      timeGroup: '17-18',
      countNum: 115,
    },
    {
      employeeType: 'Dependent',
      day: '17-18',
      timeGroup: '17-18',
      countNum: 49,
    },
    {
      employeeType: 'Employee',
      timeGroup: '18-19',
      countNum: 110,
    },
    {
      employeeType: 'Dependent',
      timeGroup: '18-19',
      countNum: 122,
    },
    {
      employeeType: 'Employee',
      timeGroup: '19-20',
      countNum: 46,
    },
    {
      employeeType: 'Dependent',
      timeGroup: '19-20',
      countNum: 60,
    },
    {
      employeeType: 'Employee',
      timeGroup: '20-21',
      countNum: 45,
    },
    {
      employeeType: 'Dependent',
      day: '20-21',
      timeGroup: '20-21',
      countNum: 95,
    },
    {
      employeeType: 'Employee',
      timeGroup: '21-22',
      countNum: 89,
    },
    {
      employeeType: 'Dependent',
      timeGroup: '21-22',
      countNum: 67,
    },
    {
      employeeType: 'Employee',
      timeGroup: '22-23',
      countNum: 99,
    },
    {
      employeeType: 'Dependent',
      day: '22-23',
      timeGroup: '22-23',
      countNum: 110,
    },
    {
      employeeType: 'Employee',
      timeGroup: '23-24',
      countNum: 121,
    },
    {
      employeeType: 'Dependent',
      timeGroup: '23-24',
      countNum: 111,
    },
  ],
};
export const APPOINTMENT_TYPE_DATA = {
  result: [
    {
      employeeType: 'Employee',
      apptType: 'Follow-Up Continuity Visit',
      countNum: 900,
    },
    {
      employeeType: 'Dependent',
      apptType: 'Acute Problem Visit',
      countNum: 440,
    },
    {
      employeeType: 'Employee',
      apptType: 'New Patient - Est Care',
      countNum: 1010,
    },
    {
      employeeType: 'Dependent',
      apptType: 'New Patient - Est Care',
      countNum: 700,
    },
    {
      employeeType: 'Dependent',
      apptType: 'Lab/RN Only',
      countNum: 400,
    },
    {
      employeeType: 'Dependent',
      apptType: 'Follow-Up Continuity Visit',
      countNum: 400,
    },
    {
      employeeType: 'Employee',
      apptType: 'Lab/RN Only',
      countNum: 400,
    },
    {
      employeeType: 'Employee',
      apptType: 'Acute Problem Visit',
      countNum: 600,
    },
    {
      employeeType: 'Employee',
      apptType: 'Phone Visit',
      countNum: 700,
    },
    {
      employeeType: 'Dependent',
      apptType: 'Phone Visit',
      countNum: 550,
    },
  ],
};
export const PATIENT_APP_MESSAGING_DATA = [
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-06-01T00:00:00',
    },
    month: 'June',
    countNum: 40,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-06-01T00:00:00',
    },
    month: 'June',
    countNum: 35,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-05-01T00:00:00',
    },
    month: 'May',
    countNum: 35,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-05-01T00:00:00',
    },
    month: 'May',
    countNum: 55,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-04-01T00:00:00',
    },
    month: 'April',
    countNum: 90,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-04-01T00:00:00',
    },
    month: 'April',
    countNum: 41,
    code: 'User',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-03-01T00:00:00',
    },
    month: 'March',
    countNum: 120,
    code: 'User',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-03-01T00:00:00',
    },
    month: 'March',
    countNum: 162,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-02-01T00:00:00',
    },
    month: 'February',
    countNum: 91,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-02-01T00:00:00',
    },
    month: 'February',
    countNum: 88,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-01-01T00:00:00',
    },
    month: 'January',
    countNum: 120,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-01-01T00:00:00',
    },
    month: 'January',
    countNum: 77,
    code: 'User',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-11-01T00:00:00',
    },
    month: 'November',
    countNum: 122,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-11-01T00:00:00',
    },
    month: 'November',
    countNum: 144,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-10-01T00:00:00',
    },
    month: 'October',
    countNum: 98,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-10-01T00:00:00',
    },
    month: 'October',
    countNum: 79,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-09-01T00:00:00',
    },
    month: 'September',
    countNum: 33,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-09-01T00:00:00',
    },
    month: 'September',
    countNum: 99,
    code: 'User',
  },
];

export const VOICE_CALLING_DIRECTION_WISE = [
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-05-01T00:00:00',
    },
    month: 'May',
    countNum: 59,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-05-01T00:00:00',
    },
    month: 'May',
    countNum: 90,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-04-01T00:00:00',
    },
    month: 'April',
    countNum: 35,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-04-01T00:00:00',
    },
    month: 'April',
    countNum: 75,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-03-01T00:00:00',
    },
    month: 'March',
    countNum: 91,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-03-01T00:00:00',
    },
    month: 'March',
    countNum: 110,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-02-01T00:00:00',
    },
    month: 'February',
    countNum: 46,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-02-01T00:00:00',
    },
    month: 'February',
    countNum: 43,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-01-01T00:00:00',
    },
    month: 'January',
    countNum: 42,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-01-01T00:00:00',
    },
    month: 'January',
    countNum: 51,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-12-01T00:00:00',
    },
    month: 'December',
    countNum: 71,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-12-01T00:00:00',
    },
    month: 'December',
    countNum: 71,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-11-01T00:00:00',
    },
    month: 'November',
    countNum: 59,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-11-01T00:00:00',
    },
    month: 'November',
    countNum: 49,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-10-01T00:00:00',
    },
    month: 'October',
    countNum: 105,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-10-01T00:00:00',
    },
    month: 'October',
    countNum: 131,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-09-01T00:00:00',
    },
    month: 'September',
    countNum: 101,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-09-01T00:00:00',
    },
    month: 'September',
    countNum: 120,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-08-01T00:00:00',
    },
    month: 'August',
    countNum: 129,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-08-01T00:00:00',
    },
    month: 'August',
    countNum: 149,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-07-01T00:00:00',
    },
    month: 'July',
    countNum: 64,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-07-01T00:00:00',
    },
    month: 'July',
    countNum: 82,
  },
  {
    senderType: 'User',
    monthDt: {
      value: '2023-06-01T00:00:00',
    },
    month: 'June',
    countNum: 57,
  },
  {
    senderType: 'Contact',
    monthDt: {
      value: '2023-06-01T00:00:00',
    },
    month: 'June',
    countNum: 65,
  },
];

export const APPOINTMENT_AGGREGATE_DATA = {
  result: [
    {
      apptType: 'In Person',
      averageDuration: 27.142857142857142,
      maxDuration: 30,
      minDuration: 5,
    },
    {
      apptType: 'Video',
      averageDuration: 31.142857142857142,
      maxDuration: 21,
      minDuration: 12,
    },
  ],
};

export const SMS_MESSAGING_DATA = [
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-06-01T00:00:00',
    },
    month: 'June',
    countNum: 90,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-06-01T00:00:00',
    },
    month: 'June',
    countNum: 31,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-05-01T00:00:00',
    },
    month: 'May',
    countNum: 110,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-05-01T00:00:00',
    },
    month: 'May',
    countNum: 120,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-04-01T00:00:00',
    },
    month: 'April',
    countNum: 110,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-04-01T00:00:00',
    },
    month: 'April',
    countNum: 90,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-03-01T00:00:00',
    },
    month: 'March',
    countNum: 81,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-03-01T00:00:00',
    },
    month: 'March',
    countNum: 55,
    code: 'User',
  },
  {
    senderType: 'Sent By Members',
    monthDt: {
      value: '2023-02-01T00:00:00',
    },
    month: 'February',
    countNum: 130,
    code: 'Contact',
  },
  {
    senderType: 'Sent By Practice',
    monthDt: {
      value: '2023-02-01T00:00:00',
    },
    month: 'February',
    countNum: 100,
    code: 'User',
  },
];

export const ED_UTILIZATION_AMOUNT_DATA = {
  result: [
    {
      countNum: 1,
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
      totalAmount: 570.16,
      edCareNeededNotPreventableAmount: 0,
      unclassifiedAmount: 570.16,
      edCareNeededPreventableAvoidableAmount: 90,
      emergentPcTreatableAmount: 11,
      nonEmergentAmount: 76,
      alcoholAmount: 49,
      drugAmount: 198,
      psychAmount: 391,
      injuryAmount: 200,
    },
    {
      countNum: 1,
      monthDt: {
        value: '2023-07-01',
      },
      month: 'July',
      totalAmount: 257.55,
      edCareNeededNotPreventableAmount: 100,
      unclassifiedAmount: 257.55,
      edCareNeededPreventableAvoidableAmount: 66,
      emergentPcTreatableAmount: 21,
      nonEmergentAmount: 190,
      alcoholAmount: 140,
      drugAmount: 121,
      psychAmount: 81,
      injuryAmount: 41,
    },
    {
      countNum: 1,
      monthDt: {
        value: '2023-11-01',
      },
      month: 'November',
      totalAmount: 308.16,
      edCareNeededNotPreventableAmount: 301,
      unclassifiedAmount: 308.16,
      edCareNeededPreventableAvoidableAmount: 200,
      emergentPcTreatableAmount: 251,
      nonEmergentAmount: 291,
      alcoholAmount: 293,
      drugAmount: 141,
      psychAmount: 121,
      injuryAmount: 198,
    },
  ],
};

export const ED_UTILIZATION_PERCENT_DATA = {
  result: [
    {
      countNum: 1,

      totalAmount: 308.16,

      edCareNeededNotPreventable: 21,

      unclassified: 100,

      edCareNeededPreventableAvoidable: 91,

      emergentPcTreatable: 121,

      nonEmergent: 191,

      alcohol: 212,

      drug: 70,

      psych: 300,

      injury: 0,
    },
  ],
};

export const ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT_DATA = {
  result: [
    {
      countNum: 22,

      employeeType: 'Employee',
    },

    {
      countNum: 31,

      employeeType: 'Dependent',
    },
  ],
};

export const ED_UTILIZATION_AMORTIZATION_DATA = {
  result: [
    {
      monthDt: {
        value: '2023-04-01',
      },

      month: 'April',

      countNum: 1,

      totalCost: 1140.32,

      totalMembers: 252,

      utilizationAmount: 4.525079365079365,
    },

    {
      monthDt: {
        value: '2023-07-01',
      },

      month: 'July',

      countNum: 1,

      totalCost: 515.1,

      totalMembers: 252,

      utilizationAmount: 2.0440476190476193,
    },

    {
      monthDt: {
        value: '2023-11-01',
      },

      month: 'November',

      countNum: 1,

      totalCost: 616.32,

      totalMembers: 252,

      utilizationAmount: 2.4457142857142857,
    },
  ],
};

export const CLAIMS_DATA = {
  result: {
    contactData: [
      {
        totalClaimCost: 800,
        contactCount: 3,
        monthDate: {
          value: '2023-11-01',
        },
        amortizationCost: 4,
        totalActiveContacts: 185,
      },
      {
        totalClaimCost: 1200,
        contactCount: 8,
        monthDate: {
          value: '2023-10-01',
        },
        amortizationCost: 7,
        totalActiveContacts: 170,
      },
      {
        totalClaimCost: 1500,
        contactCount: 10,
        monthDate: {
          value: '2023-09-01',
        },
        amortizationCost: 10,
        totalActiveContacts: 150,
      },
      {
        totalClaimCost: 1800,
        contactCount: 30,
        monthDate: {
          value: '2023-08-01',
        },
        amortizationCost: 13,
        totalActiveContacts: 130,
      },
      {
        totalClaimCost: 2300,
        contactCount: 12,
        monthDate: {
          value: '2023-07-01',
        },
        amortizationCost: 20,
        totalActiveContacts: 110,
      },
      {
        totalClaimCost: 2800,
        contactCount: 14,
        monthDate: {
          value: '2023-06-01',
        },
        amortizationCost: 30,
        totalActiveContacts: 91,
      },
      {
        totalClaimCost: 3200,
        contactCount: 16,
        monthDate: {
          value: '2023-05-01',
        },
        amortizationCost: 45,
        totalActiveContacts: 70,
      },
      {
        totalClaimCost: 3700,
        contactCount: 19,
        monthDate: {
          value: '2023-04-01',
        },
        amortizationCost: 82,
        totalActiveContacts: 50,
      },
      {
        totalClaimCost: 4100,
        contactCount: 18,
        monthDate: {
          value: '2023-03-01',
        },
        amortizationCost: 74,
        totalActiveContacts: 55,
      },
      {
        totalClaimCost: 4700,
        contactCount: 14,
        monthDate: {
          value: '2023-02-01',
        },
        amortizationCost: 167,
        totalActiveContacts: 28,
      },
      {
        totalClaimCost: 5000,
        contactCount: 3,
        monthDate: {
          value: '2023-01-01',
        },
        amortizationCost: 416,
        totalActiveContacts: 12,
      },
    ],
  },
};
export const EMPLOYER_ENROLLMENT_DATA = {
  contactMemberships: [
    {
      employeeData: {
        employer: {
          name: 'Cox Manufacturing',
        },
      },
      startDate: '2023-01-01',
      endDate: '2023-02-01',
    },
  ],
};

export const USER_MESSAGING_DATA = {
  result: [
    {
      name: 'Isabella Santiago, LVN',
      messageType: 'SMS',
      countNum: 50,
    },
    {
      name: "Benjamin Hayes, CMA, EMT-B",
      messageType: 'SMS',
      countNum: 1,
    },
    {
      name: 'Olivia Harper CCMA',
      messageType: 'SMS',
      countNum: 9,
    },
    {
      name: 'Gabriel Ramirez',
      messageType: 'CHAT',
      countNum: 3,
    },
    {
      name: 'Olivia Harper CCMA',
      messageType: 'CHAT',
      countNum: 9,
    },
    {
      name: 'Ethan Thompson Admin',
      messageType: 'CHAT',
      countNum: 12,
    },
    {
      name: 'Ethan Thompson Admin',
      messageType: 'SMS',
      countNum: 13,
    },
    {
      name: 'Ava Foster, DNP, AGPCNP, BC',
      messageType: 'CHAT',
      countNum: 4,
    },
    {
      name: 'Mia Patel, NRCMA',
      messageType: 'SMS',
      countNum: 3,
    },
    {
      name: "Benjamin Hayes, CMA, EMT-B",
      messageType: 'CHAT',
      countNum: 5,
    },
    {
      name: 'Sebastian Morgan, MD',
      messageType: 'CHAT',
      countNum: 1,
    },
    {
      name: 'Mia Patel, NRCMA',
      messageType: 'CHAT',
      countNum: 1,
    },
    {
      name: 'Ava Foster, DNP, AGPCNP, BC',
      messageType: 'SMS',
      countNum: 2,
    },
    {
      name: 'Isabella Santiago, LVN',
      messageType: 'CHAT',
      countNum: 1,
    },
    {
      name: 'Sebastian Morgan, MD',
      messageType: 'SMS',
      countNum: 1,
    },
  ],
};

export const TOTAL_CLAIM_COST_DATA = {
  result: {
    contactData: [
      {
        totalClaimCost: 2050,
        contactCount: 4,
        monthDate: {
          value: '2023-06-01',
        },
        amortizationCost: 512,
      },
      {
        totalClaimCost: 1430,
        contactCount: 5,
        monthDate: {
          value: '2023-05-01',
        },
        amortizationCost: 286,
      },
      {
        totalClaimCost: 5198,
        contactCount: 6,
        monthDate: {
          value: '2023-04-01',
        },
        amortizationCost: 866,
      },
      {
        totalClaimCost: 824,
        contactCount: 5,
        monthDate: {
          value: '2023-03-01',
        },
        amortizationCost: 164,
      },
      {
        totalClaimCost: 2600,
        contactCount: 4,
        monthDate: {
          value: '2023-02-01',
        },
        amortizationCost: 650,
      },
      {
        totalClaimCost: 2482,
        contactCount: 3,
        monthDate: {
          value: '2023-01-01',
        },
        amortizationCost: 827,
      },
    ],
  },
};

export const IP_CLAIM_COST_DATA = {
  result: [
    {
      totalClaimCost: 204012.1,
      monthDate: {
        value: '2023-06-01',
      },
      contactCount: 1571,
      totalClaims: 2400,
      averageCost: 129.1,
    },
    {
      totalClaimCost: 254012.1,
      monthDate: {
        value: '2023-07-01',
      },
      contactCount: 1824,
      totalClaims: 2400,
      averageCost: 139.1,
    },
  ],
};


export const OUT_OFFICE_HOUR_USER_ENGAGEMENT_DATA = {
  result: [
    {
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 120,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 192,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 240,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 310,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-06-01',
      },
      month: 'June',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 210,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-07-01',
      },
      month: 'July',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 210,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-08-01',
      },
      month: 'August',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 110,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-09-01',
      },
      month: 'September',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 340,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-10-01',
      },
      month: 'October',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 180,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-11-01',
      },
      month: 'November',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 250,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-12-01',
      },
      month: 'December',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 220,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
      displayText: 'Voice Calls Out Of Office Hours',
      type: 'Voice Calls',
      countNum: 401,
      colorType: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
      code: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 164,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 190,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 212,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-07-01',
      },
      month: 'July',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 190,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-08-01',
      },
      month: 'August',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 412,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-09-01',
      },
      month: 'September',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 389,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-10-01',
      },
      month: 'October',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 412,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-11-01',
      },
      month: 'November',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 266,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-05-01',
      },
      month: 'December',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 212,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 399,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 240,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 278,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-05-01',
      },
      month: 'May',
      displayText: 'Voice Calls Out Of Office Hours',
      type: 'Voice Calls',
      countNum: 149,
      colorType: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
      code: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 304,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 423,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 237,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 222,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
      displayText: 'Voice Calls Out Of Office Hours',
      type: 'Voice Calls',
      countNum: 146,
      colorType: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
      code: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-04-01',
      },
      month: 'April',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 346,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 374,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 125,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 327,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 214,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
      displayText: 'Voice Calls Out Of Office Hours',
      type: 'Voice Calls',
      countNum: 339,
      colorType: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
      code: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-03-01',
      },
      month: 'March',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 243,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 412,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 318,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
      displayText: 'Video Calls Office Hours',
      type: 'Video Calls',
      countNum: 117,
      colorType: 'VIDEO_CALLS_OFFICE_HOURS',
      code: 'VIDEO_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 264,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
      displayText: 'Voice Calls Office Hours',
      type: 'Voice Calls',
      countNum: 259,
      colorType: 'VOICE_CALLS_OFFICE_HOURS',
      code: 'VOICE_CALLS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-02-01',
      },
      month: 'February',
      displayText: 'Voice Calls Out Of Office Hours',
      type: 'Voice Calls',
      countNum: 247,
      colorType: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
      code: 'VOICE_CALLS_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 170,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 349,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-01-01',
      },
      month: 'January',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 255,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-12-01',
      },
      month: 'December',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 230,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-12-01',
      },
      month: 'December',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 231,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-12-01',
      },
      month: 'December',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 212,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-11-01',
      },
      month: 'November',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 288,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-11-01',
      },
      month: 'November',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 236,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-11-01',
      },
      month: 'November',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 210,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-10-01',
      },
      month: 'October',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 430,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-10-01',
      },
      month: 'October',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 140,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-10-01',
      },
      month: 'October',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 334,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-09-01',
      },
      month: 'September',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 345,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-09-01',
      },
      month: 'September',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 335,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-09-01',
      },
      month: 'September',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 333,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-08-01',
      },
      month: 'August',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 361,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-08-01',
      },
      month: 'August',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 348,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-08-01',
      },
      month: 'August',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 551,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-07-01',
      },
      month: 'July',
      displayText: 'Messages Office Hours',
      type: 'Messages',
      countNum: 199,
      colorType: 'MESSAGES_OFFICE_HOURS',
      code: 'MESSAGES_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-07-01',
      },
      month: 'July',
      displayText: 'Messages Out Of Office Hours',
      type: 'Messages',
      countNum: 133,
      colorType: 'MESSAGES_OUT_OF_OFFICE_HOURS',
      code: 'MESSAGES_OUT_OF_OFFICE_HOURS',
    },
    {
      monthDt: {
        value: '2023-07-01',
      },
      month: 'July',
      displayText: 'Appointments Office Hours',
      type: 'Appointments',
      countNum: 222,
      colorType: 'APPOINTMENTS_OFFICE_HOURS',
      code: 'APPOINTMENTS_OFFICE_HOURS',
    },
  ],
};


export const ENGAGEMENT_ON_APPLIED_RULES_DATA_HTN = {
  "result": [
    {
      "code": "8d12a37d-5c1a-488b-839e-33eb7f865de8",
      "displayText": "Appointment In The Last 3 Months",
      "countNum": 12,
      "type": "Members Count",
      "colorType": "MEMBERS_COUNT"
    },
    {
      "code": [
        "c6fca532-afbe-4941-9e32-61d18917cb80"
      ],
      "displayText": "Appointment In The Last 3 Months",
      "countNum": 225,
      "type": "Hypertension Members Count",
      "colorType": "HYPERTENSION_MEMBERS_COUNT"
    },
    {
      "code": "a36b46ea-b17a-45b7-a4e5-91c4ef17c7ae",
      "displayText": "Appointment In The Last 6 Months",
      "countNum": 20,
      "type": "Members Count",
      "colorType": "MEMBERS_COUNT"
    },
    {
      "code": [
        "c6fca532-afbe-4941-9e32-61d18917cb80"
      ],
      "displayText": "Appointment In The Last 6 Months",
      "countNum": 225,
      "type": "Hypertension Members Count",
      "colorType": "HYPERTENSION_MEMBERS_COUNT"
    },
    {
      "code": "dee5c2c6-f4e3-418a-8662-7c8d076995de",
      "displayText": "Appointment In The Last 12 Months",
      "countNum": 32,
      "type": "Members Count",
      "colorType": "MEMBERS_COUNT"
    },
    {
      "code": [
        "c6fca532-afbe-4941-9e32-61d18917cb80"
      ],
      "displayText": "Appointment In The Last 12 Months",
      "countNum": 225,
      "type": "Hypertension Members Count",
      "colorType": "HYPERTENSION_MEMBERS_COUNT"
    },
    {
      "code": "94346d11-68c7-4cd4-98ae-3883a5441a54",
      "displayText": "BMI > 25 At The Last Appointment",
      "countNum": 29,
      "type": "Members Count",
      "colorType": "MEMBERS_COUNT"
    },
    {
      "code": [
        "c6fca532-afbe-4941-9e32-61d18917cb80"
      ],
      "displayText": "BMI > 25 At The Last Appointment",
      "countNum": 225,
      "type": "Hypertension Members Count",
      "colorType": "HYPERTENSION_MEMBERS_COUNT"
    },
    {
      "code": "9a062e31-a1cf-422f-baa9-6f54da398eb7",
      "displayText": "Currently Prescribed Hypertension Medications",
      "countNum": 7,
      "type": "Members Count",
      "colorType": "MEMBERS_COUNT"
    },
    {
      "code": [
        "c6fca532-afbe-4941-9e32-61d18917cb80"
      ],
      "displayText": "Currently Prescribed Hypertension Medications",
      "countNum": 225,
      "type": "Hypertension Members Count",
      "colorType": "HYPERTENSION_MEMBERS_COUNT"
    },
    {
      "code": "0a646a50-1d50-4e37-8462-3fa76b9628b5",
      "displayText": "Elevated BP (140/90) At Last Appointment/BP Check",
      "countNum": 3,
      "type": "Members Count",
      "colorType": "MEMBERS_COUNT"
    },
    {
      "code": [
        "c6fca532-afbe-4941-9e32-61d18917cb80"
      ],
      "displayText": "Elevated BP (140/90) At Last Appointment/BP Check",
      "countNum": 225,
      "type": "Hypertension Members Count",
      "colorType": "HYPERTENSION_MEMBERS_COUNT"
    }
  ],
  "additionalData": {
    "finalBPAndBMIList": [
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "type": "BMI",
        "countNum": 30.46
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "type": "BP Sys",
        "countNum": 124
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "type": "BMI",
        "countNum": 30.325
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "type": "BMI",
        "countNum": 29.56
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "type": "BMI",
        "countNum": 29.88
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "type": "BP Dia",
        "countNum": 78
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "type": "BMI",
        "countNum": 29.765
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "type": "BMI",
        "countNum": 29.7
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "type": "BP Dia",
        "countNum": 78
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "type": "BP Sys",
        "countNum": 126
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "type": "BMI",
        "countNum": 30.36
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "type": "BP Dia",
        "countNum": 78
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "type": "BMI",
        "countNum": 30
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "type": "BP Dia",
        "countNum": 78
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "type": "BP Sys",
        "countNum": 126
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "type": "BMI",
        "countNum": 30.33
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "type": "BP Dia",
        "countNum": 76
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "type": "BP Sys",
        "countNum": 126
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "type": "BMI",
        "countNum": 29.82
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "type": "BP Dia",
        "countNum": 78
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "type": "BP Sys",
        "countNum": 126
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "type": "BMI",
        "countNum": 30.15
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "type": "BP Dia",
        "countNum": 78.5
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "type": "BP Sys",
        "countNum": 126
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "type": "BMI",
        "countNum": 30.08
      }
    ]
  }
};



export const ENGAGEMENT_ON_APPLIED_RULES_DATA_DIABETIC = {
  "result": [
    {
      "code": "abbaa201-f8f9-49c5-9dd5-77a462b1f0a4",
      "displayText": "Diabetic patients with HBA1C Above 9 and are not on Statin",
      "countNum": 1,
      "type": "Members Count",
      "colorType": "MEMBERS_COUNT"
    },
    {
      "code": "08f17899-fe7c-4a02-9a7f-70b7dc463184",
      "displayText": "Patients with HBA1C Above 7",
      "countNum": 1,
      "type": "Members Count",
      "colorType": "MEMBERS_COUNT"
    },
    {
      "code": "bac73993-10dd-4897-a02f-0b2783628780",
      "displayText": "Pre-diabetic patients (ICD10-R73)",
      "countNum": 7,
      "type": "Members Count",
      "colorType": "MEMBERS_COUNT"
    }
  ],
  "additionalData": {
    "finalBPAndBMIList": [
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "type": "BP Dia",
        "countNum": 78
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "type": "BP Sys",
        "countNum": 122
      },
      {
        "monthDt": {
          "value": "2023-07-01"
        },
        "type": "BMI",
        "countNum": 28.49
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "type": "BP Sys",
        "countNum": 126
      },
      {
        "monthDt": {
          "value": "2023-06-01"
        },
        "type": "BMI",
        "countNum": 29.12
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "type": "BP Sys",
        "countNum": 126
      },
      {
        "monthDt": {
          "value": "2023-05-01"
        },
        "type": "BMI",
        "countNum": 30.68
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "type": "BP Sys",
        "countNum": 125
      },
      {
        "monthDt": {
          "value": "2023-04-01"
        },
        "type": "BMI",
        "countNum": 30.1
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-03-01"
        },
        "type": "BMI",
        "countNum": 30.46
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "type": "BP Sys",
        "countNum": 124
      },
      {
        "monthDt": {
          "value": "2023-02-01"
        },
        "type": "BMI",
        "countNum": 30.325
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-01-01"
        },
        "type": "BMI",
        "countNum": 29.56
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-12-01"
        },
        "type": "BMI",
        "countNum": 29.88
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "type": "BP Dia",
        "countNum": 78
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-11-01"
        },
        "type": "BMI",
        "countNum": 29.765
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "type": "BP Dia",
        "countNum": 80
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-10-01"
        },
        "type": "BMI",
        "countNum": 29.7
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "type": "BP Dia",
        "countNum": 78
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "type": "BP Sys",
        "countNum": 126
      },
      {
        "monthDt": {
          "value": "2023-09-01"
        },
        "type": "BMI",
        "countNum": 30.36
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "type": "BP Dia",
        "countNum": 78
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "type": "BP Sys",
        "countNum": 128
      },
      {
        "monthDt": {
          "value": "2023-08-01"
        },
        "type": "BMI",
        "countNum": 30
      }
    ]
  }
};


export const AVOIDED_SPECIALIST_VISIT = [
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-09-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-09-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-09-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-10-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-10-01"
    },
    "countNum": 3
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-11-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-11-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-12-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-12-01"
    },
    "countNum": 2
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-01-01"
    },
    "countNum": 7
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-01-01"
    },
    "countNum": 2
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-01-01"
    },
    "countNum": 4
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-02-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-02-01"
    },
    "countNum": 2
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-02-01"
    },
    "countNum": 3
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-03-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-03-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-03-01"
    },
    "countNum": 4
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-04-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-04-01"
    },
    "countNum": 4
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-04-01"
    },
    "countNum": 8
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-05-01"
    },
    "countNum": 3
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-05-01"
    },
    "countNum": 2
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-05-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-06-01"
    },
    "countNum": 5
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-06-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-06-01"
    },
    "countNum": 2
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-07-01"
    },
    "countNum": 26
  }
];

export const AVOIDED_WORKDAY_ABSENCE = [
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-09-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-09-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-09-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-10-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-10-01"
    },
    "countNum": 3
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-11-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-11-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-12-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-12-01"
    },
    "countNum": 2
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-01-01"
    },
    "countNum": 7
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-01-01"
    },
    "countNum": 2
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-01-01"
    },
    "countNum": 4
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-02-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-02-01"
    },
    "countNum": 2
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-02-01"
    },
    "countNum": 3
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-03-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-03-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-03-01"
    },
    "countNum": 4
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-04-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-04-01"
    },
    "countNum": 4
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-04-01"
    },
    "countNum": 8
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-05-01"
    },
    "countNum": 3
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-05-01"
    },
    "countNum": 2
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-05-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-06-01"
    },
    "countNum": 5
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-06-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-06-01"
    },
    "countNum": 2
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-07-01"
    },
    "countNum": 26
  }
];

export const AVOIDED_WC = [
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-08-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-09-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-10-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-11-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-12-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-02-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-03-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-04-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-05-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-06-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00005",
    "monthDt": {
      "value": "2023-07-01"
    },
    "countNum": 3
  }
];



export const AVOIDED_ED = [
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-09-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-09-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-09-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-10-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-10-01"
    },
    "countNum": 3
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-11-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-11-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-12-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-12-01"
    },
    "countNum": 2
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-01-01"
    },
    "countNum": 7
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-01-01"
    },
    "countNum": 2
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-01-01"
    },
    "countNum": 4
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-02-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-02-01"
    },
    "countNum": 2
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-02-01"
    },
    "countNum": 3
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-03-01"
    },
    "countNum": 1
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-03-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-03-01"
    },
    "countNum": 4
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-04-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-04-01"
    },
    "countNum": 4
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-04-01"
    },
    "countNum": 8
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-05-01"
    },
    "countNum": 3
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-05-01"
    },
    "countNum": 2
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-05-01"
    },
    "countNum": 3
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-06-01"
    },
    "countNum": 5
  },
  {
    "displayText": "dependent",
    "type": "dependent",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-06-01"
    },
    "countNum": 1
  },
  {
    "displayText": "self-sponsored",
    "type": "self-sponsored",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-06-01"
    },
    "countNum": 2
  },
  {
    "displayText": "employee",
    "type": "employee",
    "cpt_code": "00912",
    "monthDt": {
      "value": "2023-07-01"
    },
    "countNum": 26
  }
]


export const ALL_CLAIM_DATA = {
  "result": [
    {
      "monthDt": {
        "value": "2023-12-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 26223
    },
    {
      "monthDt": {
        "value": "2023-11-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 1827
    },
    {
      "monthDt": {
        "value": "2023-11-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 112043
    },
    {
      "monthDt": {
        "value": "2023-10-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 1832
    },
    {
      "monthDt": {
        "value": "2023-10-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 87511
    },
    {
      "monthDt": {
        "value": "2023-09-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 2329
    },
    {
      "monthDt": {
        "value": "2023-09-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 147486
    },
    {
      "monthDt": {
        "value": "2023-08-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 1293
    },
    {
      "monthDt": {
        "value": "2023-08-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 78508
    },
    {
      "monthDt": {
        "value": "2023-07-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 5172
    },
    {
      "monthDt": {
        "value": "2023-07-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 106645
    },
    {
      "monthDt": {
        "value": "2023-06-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 200
    },
    {
      "monthDt": {
        "value": "2023-06-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 289943
    },
    {
      "monthDt": {
        "value": "2023-05-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 757
    },
    {
      "monthDt": {
        "value": "2023-05-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 213009
    },
    {
      "monthDt": {
        "value": "2023-04-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 3712
    },
    {
      "monthDt": {
        "value": "2023-04-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 76649
    },
    {
      "monthDt": {
        "value": "2023-03-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 29890
    },
    {
      "monthDt": {
        "value": "2023-03-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 1025773
    },
    {
      "monthDt": {
        "value": "2023-02-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 1300
    },
    {
      "monthDt": {
        "value": "2023-02-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 68342
    },
    {
      "monthDt": {
        "value": "2023-01-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 650
    },
    {
      "monthDt": {
        "value": "2023-01-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 25218
    },
    {
      "monthDt": {
        "value": "2021-12-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 4827
    },
    {
      "monthDt": {
        "value": "2021-12-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 57402
    },
    {
      "monthDt": {
        "value": "2021-11-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 1768
    },
    {
      "monthDt": {
        "value": "2021-11-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 23518
    },
    {
      "monthDt": {
        "value": "2021-10-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 3171
    },
    {
      "monthDt": {
        "value": "2021-10-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 50106
    },
    {
      "monthDt": {
        "value": "2021-09-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 226
    },
    {
      "monthDt": {
        "value": "2021-09-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 47905
    },
    {
      "monthDt": {
        "value": "2021-08-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 1708
    },
    {
      "monthDt": {
        "value": "2021-08-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 48820
    },
    {
      "monthDt": {
        "value": "2021-07-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 1060
    },
    {
      "monthDt": {
        "value": "2021-07-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 25951
    },
    {
      "monthDt": {
        "value": "2021-06-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 6539
    },
    {
      "monthDt": {
        "value": "2021-06-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 42288
    },
    {
      "monthDt": {
        "value": "2021-05-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 2776
    },
    {
      "monthDt": {
        "value": "2021-05-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 19073
    },
    {
      "monthDt": {
        "value": "2021-04-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 1591
    },
    {
      "monthDt": {
        "value": "2021-04-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 36710
    },
    {
      "monthDt": {
        "value": "2021-03-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 74
    },
    {
      "monthDt": {
        "value": "2021-03-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 8755
    },
    {
      "monthDt": {
        "value": "2021-02-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 227
    },
    {
      "monthDt": {
        "value": "2021-02-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 19988
    },
    {
      "monthDt": {
        "value": "2021-01-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 1579
    },
    {
      "monthDt": {
        "value": "2021-01-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 11591
    },
    {
      "monthDt": {
        "value": "2020-12-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 766
    },
    {
      "monthDt": {
        "value": "2020-12-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 84134
    },
    {
      "monthDt": {
        "value": "2020-11-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 108
    },
    {
      "monthDt": {
        "value": "2020-11-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 145018
    },
    {
      "monthDt": {
        "value": "2020-10-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 533
    },
    {
      "monthDt": {
        "value": "2020-10-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 86533
    },
    {
      "monthDt": {
        "value": "2020-09-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 1421
    },
    {
      "monthDt": {
        "value": "2020-09-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 21517
    },
    {
      "monthDt": {
        "value": "2020-08-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 455
    },
    {
      "monthDt": {
        "value": "2020-08-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 22195
    },
    {
      "monthDt": {
        "value": "2020-07-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 10160
    },
    {
      "monthDt": {
        "value": "2020-07-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 17334
    },
    {
      "monthDt": {
        "value": "2020-06-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 240
    },
    {
      "monthDt": {
        "value": "2020-06-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 20187
    },
    {
      "monthDt": {
        "value": "2020-05-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 31724
    },
    {
      "monthDt": {
        "value": "2020-04-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 751
    },
    {
      "monthDt": {
        "value": "2020-04-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 141469
    },
    {
      "monthDt": {
        "value": "2020-03-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 359
    },
    {
      "monthDt": {
        "value": "2020-03-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 7507
    },
    {
      "monthDt": {
        "value": "2020-02-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 225
    },
    {
      "monthDt": {
        "value": "2020-02-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 43637
    },
    {
      "monthDt": {
        "value": "2020-01-01"
      },
      "type": "Member Spend",
      "filterCode": "MEMBER",
      "totalClaimCost": 519
    },
    {
      "monthDt": {
        "value": "2020-01-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 20954
    },
    {
      "monthDt": {
        "value": "2019-12-01"
      },
      "type": "Non Member Spend",
      "filterCode": "NON_MEMBER",
      "totalClaimCost": 4
    }
  ]
};

export const APPOINTMENT_RATING_DUMMY_DATA = [
  {
    displayText: "LVN or MA -Customer Service",
    countNum: 4.5
  },
  {
    displayText: "Responsiveness On Arrival",
    countNum: 4.5
  },
  {
    displayText: "Practitioner -Customer Service",
    countNum: 4.5
  },
  {
    displayText: "Clinic Cleanliness",
    countNum: 4.5
  },
  {
    displayText: "Recommend Clinic",
    countNum: 4.5
  },

]

export const WIDGET_DUMMY_DATA: any = {
  MEMBERSHIP_INACTIVATED: {
    displayText: "",
    month: "",
    monthDt: {
        value: ""
    },
    countNum: 0
  },
  BILLING_CPT_CODES: {
    displayText: "",
    type: "",
    cpt_code: "",
    monthDt: {
        value: ""
    },
    countNum: 2
  }
}
