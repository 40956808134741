import { useLazyQuery, useMutation } from "@apollo/client";
import { VStack, HStack, Text, Divider, ScrollView, Pressable, View, Spacer, Input, FormControl, useToast, Spinner, Skeleton, Tooltip } from "native-base"
import { useEffect, useRef, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { MLOV_CATEGORY } from "../../../../constants";
import { showToast, ToastType } from "../../../../utils/commonViewUtils";
import { getCareStudioMlovListByCategoryCode } from "../../../../utils/mlovUtils";
import AddCategory from "../../../common/Svg/AddCategory";
import DocumentCategoryIcon from "../../../common/Svg/DocumentCategoryIcon";
import { useContainerDimensions } from "../../../CustomHooks/ContainerDimensionHook";
import { DocumentsList } from "./DocumentsList";
import * as DocumentQueries from '../../../../services/Document/DocumentQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../constants/Configs";
import FHActionPopover from "../../../common/FHActionPopover/FHActionPopover";
import { Dimensions, StyleSheet } from "react-native";
import { getSortedCategories } from "./DocumentUtils";
import { DOCUMENT_CATEGORY_CODES } from "./DocumentCategoryConstants";
import { IPersonData } from "../interfaces";
import { Colors } from "../../../../styles";

export type IDocumentCategory = {
    id: string;
    isDefault: boolean;
    code: string;
    value: string;
    description?: string;
}

type IDocumentComponentState = {
    selectedCategory: IDocumentCategory;
    creatingNewCategory: boolean;
    loaderForCreatingcategory: boolean;
    loaderForGetCategories: boolean;
    editingCategory?: IDocumentCategory;
}

type IProps = {
    contactUuid: string;
    contactName: string;
    contactId: number;
    personData?: IPersonData;
    unFormattedContactData?: any;
}

export const Documents = (props: IProps) => {
    const mlovCategoryId = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.DOCUMENT_TYPE)[0]?.categoryId;
    const [documentsCategories, setDocumentsCategories] = useState<IDocumentCategory[]>([])
    const [documentComponentState, setDocumentComponentState] = useState<IDocumentComponentState>({
        selectedCategory: {
            code: '',
            id: '',
            isDefault: false,
            value: ''
        },
        creatingNewCategory: false,
        loaderForCreatingcategory: false,
        loaderForGetCategories: false
    });
    const intl = useIntl();
    const categoryListRef: any = useRef();
    const inputRef: any = useRef();
    const inputEditCategoryRef: any = useRef();
    const textRef: any = useRef();
    const { width, resetDimension } = useContainerDimensions(textRef)
    const toast = useToast();

    useEffect(() => {
        if (documentComponentState?.creatingNewCategory && document.activeElement !== inputRef.current) {
            if (categoryListRef?.current) {
                categoryListRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [documentComponentState?.creatingNewCategory])

    useEffect(() => {
        if (documentComponentState?.editingCategory && document.activeElement !== inputEditCategoryRef.current) {
            if (inputEditCategoryRef.current) {
                inputEditCategoryRef.current.focus();
            }
        }
    }, [documentComponentState?.editingCategory])

    useEffect(() => {
        getDocumentCategories()
    }, [])

    const getDocumentCategories = async () => {
        setDocumentComponentState((prev) => {
            return {
                ...prev,
                loaderForGetCategories: true,
            }
        })
        await getDocumentCategoriesQuery()
    }

    const onCategoryClick = (category: IDocumentCategory) => {
      setDocumentComponentState((prev) => {
        return {
          ...prev,
          selectedCategory: category,
          creatingNewCategory: false,
          editingCategory: undefined,
        };
      });
    };

    const [getDocumentCategoriesQuery] = useLazyQuery(
        DocumentQueries.GET_DOCUMENT_CATEGORIES,
        {
            fetchPolicy: 'no-cache',
            context: { service: CARESTUDIO_APOLLO_CONTEXT },
            variables: {
                params: {
                    categoryId: mlovCategoryId
                }
            },
            onCompleted: (response) => {
                if (response?.getDocumentCategories) {
                    const sortedDocumentCategories = getSortedCategories(response?.getDocumentCategories)
                    sortedDocumentCategories.unshift({
                        id: DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS,
                        isDefault: true,
                        code: '',
                        description: undefined,
                        value: "All Documents"
                    })
                    setDocumentsCategories(sortedDocumentCategories);
                    setDocumentComponentState((prev) => {
                        return {
                            ...prev,
                            selectedCategory: sortedDocumentCategories?.[0]
                        }
                    })
                }
                setDocumentComponentState((prev) => {
                    return {
                        ...prev,
                        loaderForGetCategories: false,
                    }
                })
            },
            onError: (error) => {
                setDocumentComponentState((prev) => {
                    return {
                        ...prev,
                        loaderForGetCategories: false,
                    }
                })
                showToast(
                    toast,
                    'Error in fetching document categories',
                    ToastType.error
                );
            },
        }
    );

    const [addNewCategory] = useMutation(
        DocumentQueries.CREATE_NEW_CATEGORY,
        {
            fetchPolicy: 'no-cache',
            context: {
                service: CARESTUDIO_APOLLO_CONTEXT,
            },
            onError: () => {
                setDocumentComponentState((prev) => {
                    return {
                        ...prev,
                        creatingNewCategory: false,
                    }
                })
                showToast(
                    toast,
                    'Could Not Add Category',
                    ToastType.error
                );
            },
        }
    );

    const handleCreateNewCategory = async (data: any) => {
        const editingCategory = documentComponentState?.editingCategory?.id;
        const allowCreateOrUpdate = data?.nativeEvent?.text?.trim()?.length > 0 && data?.nativeEvent?.text?.trim()?.toLocaleLowerCase() !== documentComponentState?.editingCategory?.value?.toLocaleLowerCase()
        if (allowCreateOrUpdate) {
            if (documentsCategories?.find(category => category.value?.trim()?.toLocaleLowerCase() === data?.nativeEvent?.text.trim()?.toLocaleLowerCase())) {
                showToast(
                    toast,
                    'Category name already exists',
                    ToastType.error
                );
                return;
            }
            setDocumentComponentState((prev) => {
                return {
                    ...prev,
                    loaderForCreatingcategory: true,
                }
            })
            await addNewCategory({
                variables: {
                    params: {
                        categoryId: mlovCategoryId,
                        value: data?.nativeEvent?.text?.trim(),
                        ...(documentComponentState?.editingCategory?.id && { id: documentComponentState?.editingCategory?.id })
                    }
                },
                onCompleted: (response: any) => {
                    if (editingCategory) {
                        const prevDocumentCategories = documentsCategories;
                        const indexToUpdate = prevDocumentCategories.findIndex(category => category.id === response?.createAccountCategory?.id);
                        const updatedArray = prevDocumentCategories?.slice(1, indexToUpdate)?.concat(prevDocumentCategories?.slice(indexToUpdate + 1));
                        const sortedDocumentCategories = getSortedCategories([...updatedArray, response?.createAccountCategory]);
                        sortedDocumentCategories.unshift({
                            id: DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS,
                            isDefault: true,
                            code: '',
                            description: undefined,
                            value: "All Documents"
                        })
                        setDocumentsCategories(sortedDocumentCategories);
                        setDocumentComponentState((prev) => {
                            return {
                                ...prev,
                                creatingNewCategory: false,
                                loaderForCreatingcategory: false,
                                editingCategory: undefined,
                                selectedCategory: response?.createAccountCategory
                            }
                        })
                    }
                    else {
                        const category = {
                            code: response?.createAccountCategory?.code,
                            id: response?.createAccountCategory?.id,
                            value: response?.createAccountCategory?.value,
                            isDefault: false,
                        }
                        // All Documents category should be at the top
                        const sortedDocumentCategories = getSortedCategories([...documentsCategories.slice(1), category]);
                        sortedDocumentCategories.unshift({
                            id: DOCUMENT_CATEGORY_CODES.ALL_DOCUMENTS,
                            isDefault: true,
                            code: '',
                            description: undefined,
                            value: "All Documents"
                        })
                        setDocumentsCategories(sortedDocumentCategories);
                        setDocumentComponentState((prev) => {
                            return {
                                ...prev,
                                creatingNewCategory: false,
                                loaderForCreatingcategory: false,
                                selectedCategory: category,
                                editingCategory: undefined,
                            }
                        })
                    }
                    showToast(
                        toast,
                        editingCategory ? 'Category Name Updated' :
                            'Category Added Successfully',
                        ToastType.success
                    );
                },
                onError: () => {
                    showToast(
                        toast,
                        editingCategory ? 'Renaming Category Failed' :
                            'Adding Category Failed',
                        ToastType.error
                    );
                }
            })
        }
        else {
            setDocumentComponentState((prev) => {
                return {
                    ...prev,
                    creatingNewCategory: false,
                    editingCategory: undefined
                }
            })
        }
    }

    return (
        <HStack flex={1} backgroundColor={'white'}>
            <VStack flex={0.3}>
                    <HStack style={styles.categoryHeader}>
                        <Text style={styles.categoryHeaderText}>
                            {intl.formatMessage({ id: 'categories' })}
                        </Text>
                        <Spacer />
                        <Pressable style={styles.addCategoryButton} onPress={() => {
                            if (!documentComponentState?.creatingNewCategory) {
                                setDocumentComponentState((prev) => {
                                    return {
                                        ...prev,
                                        creatingNewCategory: true,
                                    }
                                })
                            }
                        }}>
                            <AddCategory />
                        </Pressable>
                    </HStack>
                    <ScrollView>
                        {documentComponentState?.loaderForGetCategories &&
                            <Skeleton.Text lines={2} />
                        }
                        {!documentComponentState?.loaderForGetCategories && documentsCategories.map((category, index) => {
                            return (
                              <Pressable
                                key={category.id}
                                onPress={() => onCategoryClick(category)}
                                style={{
                                  borderLeftWidth:
                                    documentComponentState?.selectedCategory
                                      ?.id === category?.id
                                      ? 5
                                      : 0,
                                  borderLeftColor: '#7F56D9',
                                  borderStyle: 'solid',
                                  borderTopWidth: !index ? 1 : 0,
                                  borderBottomWidth: 1,
                                  borderTopColor: '#EEEEEE',
                                  borderBottomColor: '#EEEEEE',
                                  paddingLeft: 12,
                                  paddingVertical: 10,
                                  backgroundColor:
                                    documentComponentState?.selectedCategory
                                      ?.id === category?.id
                                      ? '#f2f4f7'
                                      : '',
                                }}
                                _hover={{
                                  bg: '#7F56D920',
                                }}
                              >
                                <HStack space={2.5}>
                                  <DocumentCategoryIcon
                                    categoryCode={category?.code}
                                  />
                                  {documentComponentState?.editingCategory
                                    ?.id === category?.id ? (
                                    <Input
                                      ref={inputEditCategoryRef}
                                      onBlur={handleCreateNewCategory}
                                      placeholder={'Enter Category Name'}
                                      maxWidth={110}
                                      defaultValue={
                                        documentComponentState?.editingCategory
                                          ?.value || ''
                                      }
                                    />
                                  ) : (
                                    <>
                                      <Tooltip label={category?.value}>
                                        <Text
                                          onPress={() =>
                                            onCategoryClick(category)
                                          }
                                          ref={textRef}
                                          style={{
                                            color:
                                              documentComponentState
                                                ?.selectedCategory?.id ===
                                              category?.id
                                                ? '#7F56D9'
                                                : Colors.FoldPixel.GRAY400,
                                          }}
                                          size={'smMedium'}
                                          maxWidth={'80%'}
                                          isTruncated={true}
                                        >
                                          {category?.value}
                                        </Text>
                                      </Tooltip>
                                      <Spacer />
                                      {!category?.isDefault && (
                                        <View width={1} alignItems={'center'}>
                                          <FHActionPopover
                                            actions={[
                                              {
                                                code: 'RENAME',
                                                name: 'Rename',
                                              },
                                            ]}
                                            onActionClick={(code, event) => {
                                              if (code === 'RENAME') {
                                                setDocumentComponentState(
                                                  (prev) => ({
                                                    ...prev,
                                                    editingCategory: category,
                                                  })
                                                );
                                              }
                                            }}
                                          />
                                        </View>
                                      )}
                                    </>
                                  )}
                                </HStack>
                              </Pressable>
                            );
                        })
                        }
                        {
                            documentComponentState?.creatingNewCategory && (
                                <View
                                    style={styles.newCategoryItem}
                                    ref={categoryListRef}
                                >
                                    <HStack space={2.5}>
                                        <DocumentCategoryIcon categoryCode={''} />
                                        <Input
                                            ref={inputRef}
                                            onBlur={handleCreateNewCategory}
                                            placeholder={'Enter Category Name'}
                                            maxWidth={110}
                                            defaultValue={documentComponentState?.editingCategory?.value || ''}
                                        />
                                        {documentComponentState?.loaderForCreatingcategory ? <Spinner /> : <></>}
                                    </HStack>
                                </View>
                            )
                        }
                    </ScrollView>
            </VStack>
            <Divider orientation={'vertical'} />
            <VStack flex={0.7}>
                {documentComponentState?.loaderForGetCategories && <View><Skeleton size={10} rounded="full" />
                    <Skeleton.Text lines={2} /> </View>
                }
                {!documentComponentState?.loaderForGetCategories &&
                    <DocumentsList
                        key={documentComponentState?.selectedCategory?.code}
                        contactUuid={props?.contactUuid}
                        category={documentComponentState?.selectedCategory}
                        documentsCategories={documentsCategories}
                        contactName={props?.contactName}
                        contactId={props?.contactId}
                        personData={props?.personData}
                        unFormattedContactData={props?.unFormattedContactData}
                    />
                }
            </VStack>

        </HStack>
    )
}

const styles = StyleSheet.create({
    categoryHeader: {
        borderLeftColor: '#7F56D9',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderTopColor: '#EEEEEE',
        borderBottomColor: '#EEEEEE',
        paddingHorizontal: 16,
        paddingVertical: 12,
    },
    categoryHeaderText: {
        color: 'black',
        size: 'mdBoldNew'
    },
    addCategoryButton: {
        justifyContent: 'center',
    },
    categoryItem: {
        borderLeftColor: '#7F56D9',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderTopColor: '#EEEEEE',
        borderBottomColor: '#EEEEEE',
        paddingLeft: 12,
        paddingVertical: 10,
    },
    categoryText: {
        fontSize: 14,
        fontFamily: 'Manrope',
        fontWeight: '600',
    },
    newCategoryItem: {
        borderLeftWidth: 0,
        borderLeftColor: '#7F56D9',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderTopColor: '#EEEEEE',
        borderBottomColor: '#EEEEEE',
        paddingLeft: 12,
        paddingVertical: 10,
    },
});

export default Documents