import React from 'react';
import {Tooltip} from 'antd';
import {
  View,
  Text,
  HStack
} from 'native-base';
import {IChronicConditionDrillDownResp} from '../interface';
import {IContact} from '../../../../../TeamInbox/Conversations/interfaces';
import TagListView from '../../../../../../common/TagViews/TagListView';
import {ILabelDataView} from '../../../../../../common/FilterView/interfaces';
import {Colors} from '../../../../../../../styles/Colors';
const ChronicConditions = (props: {
  columnData: IChronicConditionDrillDownResp;
  contactData: IContact;
  columnConfig: any;
  contactDrillDownData: any;
  isOnFirstView: boolean;
  filterData?: any;
}) => {

  const {columnData} = props;
  const getChronicConditionString = () => {
    let chronicConditionStr = '';
    const chronicConditionList: string[] = [];
    if (columnData?.data?.length) {
      columnData?.data?.forEach((singleChronicCondition) => {
        if (singleChronicCondition?.code || singleChronicCondition?.displayText) {
          let tempDisplayString = '';
          if (singleChronicCondition?.code) {
            tempDisplayString = singleChronicCondition?.code;
            if (singleChronicCondition?.displayText) {
              tempDisplayString += ' - ' + singleChronicCondition?.displayText;
            }
          }
          chronicConditionList.push(tempDisplayString);
        }
      });
    }
    if (chronicConditionList?.length) {
      chronicConditionStr = chronicConditionList.join(',');
    }
    return chronicConditionStr;
  }

  const getChronicConditionTagList = (): ILabelDataView[] => {
    const chronicConditionTagList = [] as ILabelDataView[];

    if (columnData?.data?.length) {
      for (let index = 0; index < columnData?.data?.length; index++) {
        const singleChronicCondition = columnData?.data?.[index];
        if (singleChronicCondition?.code || singleChronicCondition?.displayText) {
          let tempDisplayString = '';
          if (singleChronicCondition?.code) {
            tempDisplayString = singleChronicCondition?.code;
            if (singleChronicCondition?.displayText) {
              tempDisplayString += ' - ' + singleChronicCondition?.displayText;
            }
          }
          chronicConditionTagList.push({
            id: index + 1,
            color: '#E3E3E3',
            description: '',
            title: tempDisplayString,
          });
        }
      }

    }

    return chronicConditionTagList;
  }
  const renderChronicConditionsDetails = () => {
    if (columnData?.contactUuid && columnData?.data?.length) {
      const allChronicConditionStr = getChronicConditionString();
      const allChronicConditionTagList = getChronicConditionTagList();

      return (
        <HStack>
          <Tooltip title={allChronicConditionStr}>
            <TagListView
              tagList={allChronicConditionTagList}
              popOverConfig={{
                placement: 'rightTop',
                trigger: 'click',
              }} noDataString={'No Chronic Condition'} />
          </Tooltip>
        </HStack>
      )
    } else {
      return (
        <View>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} >
            No Chronic Condition
          </Text>
        </View>
      );
    }
  };

  return (
    <View style={{
      maxWidth: 180
    }}>
      {renderChronicConditionsDetails()}
    </View>
  );
}
export default React.memo(ChronicConditions);
