import {Popover} from 'antd';
import {Button, HStack, Pressable, Text, useToast, VStack} from 'native-base';
import {useRef, useState} from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {CSVData, IEmployerTableActionsArgs} from '../interfaces';
import {styles} from './EmployerTableActionStyles';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {DeleteEmployerModal} from '../DeleteEmployerModal';
import {CSVLink} from 'react-csv';
import {csvHeaders, getFormattedMemberDataForCSV} from '../Helper';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {useLazyQuery} from '@apollo/client';
import EmployeeQueries from '../../../../services/Employee/EmployeeQueries';
import {getAccountUUID, getOnFocusButtonStyles} from '../../../../utils/commonUtils';

const EmployerTableActions = (props: IEmployerTableActionsArgs) => {
  const {onActionPerformed, record} = props;
  const [isOpen, setIsOpen] = useState(false);
  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const accountUuid = getAccountUUID();

  const [state, setState] = useState({
    csvData: [] as CSVData[],
  });

  const csvRef = useRef<any>(null);

  const [getEmployeesForExport] = useLazyQuery(
    EmployeeQueries.getEmployeesForExport,
    {
      fetchPolicy: 'no-cache',
      variables: {
        employerId: record.id,
        accountUuid: accountUuid,
      },
    }
  );

  const toast = useToast();

  const handleCSVDownload = async () => {
    try {
      showToast(toast, 'Preparing the file', ToastType.success);
      const response = await getEmployeesForExport();
      const csvData = getFormattedMemberDataForCSV(response.data);
      setState((prev) => {
        return {
          ...prev,
          csvData: csvData,
        };
      });

      csvRef.current.link.click();
    } catch (error) {
      showToast(
        toast,
        'Something went wrong! Please try again later',
        ToastType.error
      );
    }
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteEmployer = () => {
    onActionPerformed(COMMON_ACTION_CODES.DELETE, record);
  };
  const quickActionList = [
    // {
    //   displayString: 'editEmployer',
    //   actionCode: COMMON_ACTION_CODES.EDIT_EMPLOYER,
    // },
    {
      displayString: 'manageDeal',
      actionCode: COMMON_ACTION_CODES.MANAGE_DEAL,
    },
    {
      displayString: 'delete',
      actionCode: COMMON_ACTION_CODES.DELETE,
    },
    {
      displayString: 'downloadCSV',
      actionCode: COMMON_ACTION_CODES.DOWNLOAD_CLICK,
    },
  ];

  const onEmployerTableActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        setOpenDeleteModal(true);
        break;
      case COMMON_ACTION_CODES.DOWNLOAD_CLICK:
        handleCSVDownload();
        break;
      default:
        onActionPerformed(actionCode, record);
        break;
    }
  };

  const content = (
    <VStack style={{padding: 0, borderRadius: 16}}>
      {quickActionList.map((action: any, index: any) => {
        return (
          <Pressable
            key={index}
            onPress={() => {
              onEmployerTableActionPerformed(action.actionCode);
              setIsOpen(!isOpen);
            }}
          >
            <HStack style={[styles.mainMenuContent, styles.firstMenu]}>
              <Text style={styles.mainMenuTxt} size={'smRegular'}>
                <DisplayText
                  size={'smRegular'}
                  textLocalId={action.displayString}
                />
              </Text>
            </HStack>
          </Pressable>
        );
      })}
    </VStack>
  );

  return (
    <HStack>
      <Button
        onPress={() => {
          onActionPerformed(COMMON_ACTION_CODES.EDIT_EMPLOYER, record);
        }}
        _focus={{
          ...getOnFocusButtonStyles(),
        }}
        leftIcon={<Feather name="edit-2" size={17} style={[styles.icons]} />}
        style={styles.btn}
      />
      <Popover
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        style={{padding: 0, borderRadius: 16}}
        placement={'left'}
        trigger="click"
        visible={isOpen}
        onVisibleChange={handleVisibleChange}
        content={content}
      >
        <Button
          onPress={() => {
            setIsOpen(true);
          }}
          style={styles.btn}
          _focus={{
            ...getOnFocusButtonStyles(),
          }}
        >
          <Feather
            name="more-vertical"
            style={[
              styles.icons,
              // {color: Colors.Custom.PrimaryBtnColor}
            ]}
          />
        </Button>
      </Popover>
      <DeleteEmployerModal
        isMembers={false}
        isOpen={openDeleteModal}
        onConfirm={() => {
          handleDeleteEmployer();
          setOpenDeleteModal(false);
        }}
        onClose={() => setOpenDeleteModal(false)}
      />

      <CSVLink
        data={state.csvData}
        headers={csvHeaders}
        filename={`${record.name || 'employees'}.csv`}
        ref={csvRef}
      />
    </HStack>
  );
};

export default EmployerTableActions;
