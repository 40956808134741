import { Table } from 'antd';
import { Text, VStack } from 'native-base';
import React from 'react';
import { getPatientSearchListingColumns } from '../../DayOptimizerHelper';
import { cssPropertiesAntD } from '../../style';
import SearchRecordsSvg from '../../../../../../assets/Icons/SearchRecordsSvg';
import { useIntl } from 'react-intl';
import { Colors } from '../../../../../../styles';

const PatientSearchListing = (props: {
  dataSource: any;
  onActionPerformed: (action: string, record: any) => void;
  loading: boolean;
  hasSearched: boolean;
}) => {
  const intl = useIntl();
  return (
    <div style={cssPropertiesAntD.fullHeight}>
      {!props.hasSearched ? (
         <VStack space={2} justifyContent={'center'} alignItems="center" height={'80%'}>
          <SearchRecordsSvg />
          <Text color={Colors.FoldPixel.GRAY300}>{intl.formatMessage({ id: 'SeachForPatients' })}</Text>
        </VStack>
      ) : (
        <Table
          className="day-op patient-search-listing"
          rowClassName="table-row-dayOptimizer"
          dataSource={props.dataSource || []}
          columns={getPatientSearchListingColumns(props.onActionPerformed)}
          pagination={false}
          loading={props.loading}
          scroll={{ x: true }}
          style={{ padding: 0 }}
        />
      )}
    </div>
  );
};

export default PatientSearchListing;
