import { useLazyQuery, useMutation } from '@apollo/client';
import { Drawer, Modal, Space } from 'antd';
import parse from 'html-react-parser';
import { Liquid } from 'liquidjs';
import {
  Button,
  Divider,
  HStack,
  Select,
  Skeleton,
  Spacer,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BUTTON_TYPE, GROUP_MEMBER_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH, MLOV_CATEGORY } from '../../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { FORM_SOURCE } from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { FormsQueries, TaskQueries } from '../../../../../services';
import ContactsQueries from '../../../../../services/Contacts/ContactsQueries';
import { getAccountUUID, getFormURL, splitArrayIntoChunks } from '../../../../../utils/commonUtils';
import { getDateToMomentISOString } from '../../../../../utils/DateUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../../../../utils/mlovUtils';
import { ContentTypes } from '../../../../RightSideContainer/ContentManagement/ContentManagementConsts';
import { getAccountMergeTagData, getTemplateCategories, getTemplateCategoryList, getTemplates } from '../../../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { getFormattedEmailTemplateData } from '../../../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import { ITemplateCategory } from '../../../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import { getDefaultTaskStatusId } from '../../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { sendEmail } from '../../../../common/EmailPopupView/EmailPopupViewUtils';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import MultipleFormSearch from '../../../../common/MultipleFormSearch/MultipleFormSearch';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { DisplayCardAvatar } from '../../../../common/DisplayCard/DisplayCardAvatar';
import { Colors } from '../../../../../styles/Colors';
import { IGenderCodeType } from '../../../../common/DisplayCard/interface';
import { IMembersGroupData } from '../../../MembersManagement/interfaces';
import { getContactsWithinGroup } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { SEND_BULK_FORMS } from './PopulationGroupEmailQueries';
import { FHAlertDialog } from '../../../../common/FHAlertDialog';
import { useIntl } from 'react-intl';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import './styles.css';
import FormSearch from '../../../../common/FormSearch/FormSearch';

interface IContactSendFormProps {
  isVisible: boolean;
  assignmentData: {
    assignedById: string;
  };
  onActionComplete: () => void;
  onClose: () => void;
  contactIds?: any[];
  groupAndActiveContactIds?: { group?: IMembersGroupData, contactIds?: string[] }[]
  sendingFormBySelectingPopulationGroupFromList?: boolean;
  selectedGroupId?: string;
  useAbsoluteLocation?: boolean;
  formLocations?: string[];
}

interface IForm {
  id: string;
  name: string;
  taskId?: string;
}
interface IContactSendFormState {
  showError: boolean;
  loading: boolean;
  selectedForms: IForm[];
  emailTemplateId?: string;
  templateData?: any;
}

interface ISendingFormsPopulationGroup {
  patientId: string;
  contactId: string;
  assignedById: string;
  patientEmail: string;
  patientFirstName: string;
  patientContactUUID: string;
  profileImgSrc: string;
  patientLastName: string;
  patientName: string;
  patientContactTypeCode: string;
  patientGenderCode: IGenderCodeType;
  isActive: boolean
}


const ContactSendForm = (props: IContactSendFormProps) => {
  const { isVisible, onClose } = props;
  const accountUUID = getAccountUUID();
  const toast = useToast();
  const mlovData = useContext(CommonDataContext);
  const [stateDataSendingFormsInPopulationGroup, setStateDataSendingFormsInPopulationGroup] = useState<ISendingFormsPopulationGroup[]>([])
  const [formattedTemplates, setFormattedTemplates] = useState<any>([])
  const [notificationConfirmationModal, setNotificationConfirmationModal] = useState(false)
  const intl = useIntl();

  const formSourceList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.FORM_SOURCE
    ) || [];

  const formSourceId = getMlovIdFromCode(
    formSourceList,
    FORM_SOURCE.POPULATIONGROUP
  );

  const defaultValue: IContactSendFormState = {
    showError: false,
    loading: false,
    selectedForms: [],
  };
  const [categoryList, setCategoryList] = useState<ITemplateCategory[]>([]);
  const [sendFormState, setSendFormState] =
    useState<IContactSendFormState>(defaultValue);

  const [sendBulkForms] = useMutation(SEND_BULK_FORMS, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    }
  });

  useEffect(() => {
    getTemplateCategories()
      .then((data) => {
        return getTemplateCategoryList(data);
      })
      .then((list) => {
        setCategoryList(list);
      })
      .catch((error) => {});
  }, []);

  const getTemplatesByCategoryCode = () => {
    const path = `${ContentTypes.emails.path}?category=MULTIPLE_PATIENT_FORMS`;
    getTemplates(path)
      .then((response) => {
        return getFormattedEmailTemplateData(response);
      })
      .then((formattedTemplates) => {
        setFormattedTemplates(formattedTemplates)
        if (formattedTemplates.length > 0) {
          const template = formattedTemplates[0];
          setSendFormState((prev) => ({
            ...prev,
            emailTemplateId: template.id || '',
            templateData: template || {},
          }));
        }
      })
      .catch((error) => {
      });
  };

  const resetData = () => {
    setSendFormState((prev) => ({ ...prev, ...defaultValue }));
  };

  const onSubmitClick = async (args: { allowToSendNotification: boolean }) => {
    const paramsForSendingBulkForms = {
      groups: props?.sendingFormBySelectingPopulationGroupFromList ? props?.groupAndActiveContactIds?.map(item => ({ groupId: item?.group?.id, contactIds: item?.contactIds })) :
        [{ groupId: props?.selectedGroupId, contactIds: stateDataSendingFormsInPopulationGroup?.map(contact => contact?.patientContactUUID) }],
      forms: sendFormState.selectedForms.map(form => ({ id: form.id, name: form.name })),
      sourceId: formSourceId,
      templateId: sendFormState.emailTemplateId,
      allowToSendNotification: args.allowToSendNotification
    }
    setSendFormState((prev) => ({ ...prev, loading: true }))
    sendBulkForms({
      variables: {
        params: paramsForSendingBulkForms
      }
    }).then(
      (response) => {
        if (response?.data?.sendBulkForms?.status === 'success') {
          resetData();
          props?.onActionComplete();
          showToast(
            toast,
            'Form sending in progress',
            ToastType.success
          )
        }
        else {
          showToast(
            toast,
            'Something went wrong',
            ToastType.success
          )
        }
      }).catch(() => {
        showToast(
          toast,
          'Error in sending forms',
          ToastType.error
        );
      }).finally(() => {
        setSendFormState((prev) => ({ ...prev, loading: false }))
      });
    // };
  }

  const accountMergeTags = getAccountMergeTagData();

  const getMergeTags = (category: string, categories: ITemplateCategory[]) => {
    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return { ...mergeTagsByCategory, global: accountMergeTags };
  };

  const getPreviewHtml = (templateData: any) => {
    const engine = new Liquid();
    const finalMergeTag = { ...getMergeTags(templateData?.templateCategory, categoryList), ...getFormMergedTags(sendFormState.selectedForms) };
    const tpl = engine.parse(templateData?.templateHtml || '');
    return engine.renderSync(tpl, finalMergeTag);
  };

  const getFormMergedTags = (forms: IForm[], contactChunk?: any[]) => {
    const formMergedTags: any[] = []
    if (contactChunk) {
      contactChunk.forEach(contact => {
        if (contact.patientEmail) {
          const newObjectforTag = {
            patient: { firstName: contact.patientFirstName },
            formList: forms.map((form) => {
              return {
                name: form.name,
                link: getFormURL(
                  form.id,
                  contact.patientContactUUID,
                  accountUUID,
                  form.taskId,
                  formSourceId
                ),
              }
            }),
          }
          formMergedTags.push(newObjectforTag)
        }
      })
      return formMergedTags
    }
    else {
      return {
        patient: {
          firstName: 'patient'
        },
        formList: forms?.map((form) => {
          return {
            name: form.name,
            link: getFormURL(
              form.id,
              '',
              accountUUID,
              form.taskId,
              formSourceId
            ),
          }
        }),
      }
    }
  };

  const [getContactsByIds, contactDetailsQuery] = useLazyQuery(
    props?.sendingFormBySelectingPopulationGroupFromList ? ContactsQueries.GET_ACTIVE_CONTACTS_BY_CONTACTUUIDS : ContactsQueries.GET_ACTIVE_CONTACTS_BY_CONTACTIDS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => {

      },
    }
  );

  const getContactsByChunk = async (chunkForGetContacts: any) => {
    const newStateData: any[] = []
    for (const chunk of chunkForGetContacts) {
      const contactsData = await getContactsByIds({
        variables: {
          contactIdList: chunk,
        },
      })
      if (contactsData?.data?.contacts?.length) {
        contactsData?.data?.contacts.forEach((contact: any) => {
          newStateData.push({
            patientId: contact?.uuid,
            contactId: contact?.uuid,
            assignedById: props?.assignmentData?.assignedById,
            patientEmail: contact?.email,
            patientFirstName: contact?.person?.firstName || contact?.name || '',
            patientContactUUID: contact?.uuid, profileImgSrc: contact?.profileImgSrc || '',
            patientLastName: contact?.lastName || '', patientName: contact?.name || '',
            patientContactTypeCode: contact?.contactType?.contactType?.code || '',
            patientGenderCode: contact?.person?.gender?.code || '',
            isActive: contact?.isActive
          })
        })
      }
      if (chunkForGetContacts[chunkForGetContacts.length - 1] === chunk) {
        setStateDataSendingFormsInPopulationGroup(newStateData)
      }
    }
  }

  useEffect(() => {
    if (!props?.sendingFormBySelectingPopulationGroupFromList && props?.contactIds && props?.contactIds?.length > 0) {
      const chunkForGetContacts = splitArrayIntoChunks(props.contactIds, 100)
      getContactsByChunk(chunkForGetContacts)
    }
  }, [props?.contactIds])

  useEffect(() => {
    getTemplatesByCategoryCode();
  }, []);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const drawerWidth = isSmallScreen ? '70%' : '35%';

  return (
    <Drawer
      open={isVisible}
      onClose={() => {
        if (!sendFormState.loading) {
          resetData();
          onClose();
        }
      }}
      destroyOnClose
      title={<ModalActionTitle title={'Select Form'} titleColor={''} buttonList={[]} />}
      width={drawerWidth}
      closable={false}
      placement="right"
    >
      <VStack space={4}>
        <DisplayText
          size={'smRegular'}
          extraStyles={{colors: Colors.FoldPixel.GRAY250}}
          textLocalId="template"
        ></DisplayText>
        <Select
          onValueChange={(value: string) => {
            const template = formattedTemplates.find(
              (item: any) => item.id == value
            );
            setSendFormState((prev) => ({
              ...prev,
              emailTemplateId: template.id || '',
              templateData: template || {},
            }));
          }}
          selectedValue={
            sendFormState.emailTemplateId
          }
        >
          {formattedTemplates.map((item: any) => {
            return (
              <Select.Item
                label={item.templateName}
                value={item.id as string}
              >
                {item.templateName}
              </Select.Item>
            );
          })}
        </Select>
        <DisplayText
          size={'smRegular'}
          extraStyles={{colors: Colors.FoldPixel.GRAY250}}
          textLocalId="Form"></DisplayText>
        <FormSearch
          formLocations={props?.formLocations}
          useAbsoluteLocations={props?.useAbsoluteLocation}
          isShowError={sendFormState.showError}
          value={sendFormState.selectedForms}
          onChange={(value) => {
            if (value) {
              setSendFormState((prev) => ({ ...prev, selectedForms: value ? [value] : [], showError: false }));
            }
          }}
        />
        <HStack>
          <Spacer />
          <Button onPress={() => {
            const forms = sendFormState.selectedForms;
            const isError = !forms.length || forms?.length === 0;
            setSendFormState((prev) => ({ ...prev, showError: isError }));
            if (!isError) {
              setNotificationConfirmationModal(true)
            }
          }}
            rounded="3xl" leftIcon={sendFormState.loading ? <Spinner /> : undefined} isDisabled={sendFormState.loading}>
            <DisplayText
              textLocalId="sendForm"
              extraStyles={{ color: 'white' }}
            />
          </Button>
        </HStack>
        {sendFormState.templateData?.templateHtml && (
          <View>{parse(getPreviewHtml(sendFormState.templateData))}</View>
        )}
        {contactDetailsQuery.loading ?
          <Skeleton.Text flex={1} lines={1}></Skeleton.Text> :
          <VStack>
            {notificationConfirmationModal && (
              <Modal
                open={notificationConfirmationModal}
                onCancel={() => setNotificationConfirmationModal(false)}
                footer={null}
                width="35%"
                bodyStyle={{
                  backgroundColor: Colors.Custom.BackgroundColor,
                  padding: 0
                }}
                title={'Notification'}
                closable={false}
                className="popGroupFormNotificationConfirmation"
              >
                <Divider></Divider>
                <div className="modal-body">
                  <Text>{intl.formatMessage({ id: 'formSentNotification' })}</Text>
                </div>
                <div className="modal-footer" style={{ border: 'none' }}>
                  <Button.Group>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.SECONDARY,
                        onPress: () => {
                          setNotificationConfirmationModal(false);
                          onSubmitClick({ allowToSendNotification: false });
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({ id: 'No' }),
                        withRightBorder: false,
                      }}
                    />
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          setNotificationConfirmationModal(false);
                          onSubmitClick({ allowToSendNotification: true });
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({ id: 'Yes' }),
                        withRightBorder: false,
                      }}
                    />
                  </Button.Group>
                </div>
              </Modal>
            )}
          </VStack>}
      </VStack>
    </Drawer>
  );
};

export default ContactSendForm;
