import { useLazyQuery } from '@apollo/client';
import { Spin, notification } from 'antd';
import { ScrollView,Text,View } from 'native-base';
import React, { useCallback, useContext,useEffect,useRef,useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation,useNavigate } from 'react-router';
import { ILoginUserData } from '../../../../../Interfaces';
import { MLOV_CATEGORY, PERSON_TYPES, RIGHT_SIDE_CONTAINER_CODE,WINDOW_EVENT_CODES,getInActiveContactError } from '../../../../../constants';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
} from '../../../../../constants/ActionConst';
import { CUSTOM_COMMON_EVENT_CODES, SUPPORTED_EVENT_CODE } from '../../../../../constants/WebSocketConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { FlowType } from '../../../../../context/WorkflowContext';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import { IInbox } from '../../../../../services/Inbox/interfaces';
import { Colors } from '../../../../../styles/Colors';
import { EventBus } from '../../../../../utils/EventBus';
import LocalStorage from '../../../../../utils/LocalStorage';
import { getAccountId, getSecondaryValue,getUserId,getUserUUID,isActiveContact,isEmployerRole } from '../../../../../utils/commonUtils';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { AutomationSettingBtn } from '../../../../common/AutomationSettingBtn';
import AppointmentBooking from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import { ParticipantType } from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import CreateChannelView from '../../../../common/CreateChannelView/CreateChannelView';
import EmailDrawerCommon from '../../../../common/EmailPopupView/EmailDrawerCommon';
import MeetingView from '../../../../common/MeetingView/MeetingView';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import { AddOrUpdateLead } from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import { getFormDataFromLeadData } from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { getConditionalStyles, styles } from '../ConversationChannelTabs/ConversationChannelTabsStyle';
import { isCallsAllowed, isMessageSettingAllowed } from '../../../UserAccess/UserAccessPermission';
import { getAllUserInboxes } from '../ConversationChannelTabs/ConversationChannelTabsUtils';
import CreateSmsConversationDrawer from '../ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import { IConversationChannelTabState,IInboxChannelType,IUserInboxList } from '../ConversationChannelTabs/interfaces';
import { CHANNEL_TYPE_CODE,CONVERSATION_TAB_CODES, USER_CONVERSATION_FILTER_CODES_LIST } from '../ConversationConst';
import Conversations from '../Conversations';
import { createUserConversationFilter,getUserConversationFilter,resetUserConversationFilter,updateUserConversationFilter } from './ConversationAPIService';
import { CONVERSATION_TYPES,DISPLAY_CONVERSATION_SIDEBAR_LIST,INITIAL_UNREAD_MAP_V2,SIDECAR_CONVERSATION_TAB_BAR_LIST,getAllConversationUnreadCountForAllTabsV2,getCodeAndUserConversationFilterData,getConversationFilterPropsAndQueryData,getFilterValueData } from './ConversationSidebarUtils';
import { getSearchParams,isUpdateAllCount } from './ConversationSidebarUtils';
import { ConversationSidebarV2Event } from './ConversationSidebarV2Event';
import { IConversationChannelList, IUserConversationFilterResp, IUserPreferenceContactType } from './Interfaces';
import CreateEFaxConversationDrawer from '../ConversationChannelTabs/CreateEFaxConversationDrawer/CreateEFaxConversationDrawer';
import UserQueries from '../../../../../services/User/UserQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { getMlovCodeIdObj, getMlovIdCodeObj, getMlovIdFromCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { USER_PREFERENCE_CODE } from '../../../../../constants/MlovConst';
import { testID } from '../../../../../testUtils';
import { SidecarTitle } from '../../../../SideCar/SidecarTitle';
import {EMAIL_INBOX_USER_CODE} from '../../../../common/PreferencesSetting/PreserenceConst';
import {ToastType} from '../../../../../utils/commonViewUtils';
import {CUSTOM_FIELD_TOAST_ERROR_DURATION} from '../../../Contacts/CustomField/CustomFieldConst';
import {ICustomToast} from '../../../Contacts/CustomField/interface';
import {useToast} from '../../../../Toast/ToastProvider';
import {INBOX_TYPE_TAB_CODE} from '../../../../../constants/ConstantValues';
import SideCarConversationTabBar, { ISideCarConversationTabList } from '../../../../common/SideCar/SideCarConversationTabBar';
import { GetPracticeLocationByUuid } from '../../../../../services/Location/UserPracticeLocationQueries';
import {deleteAllConversationsDraftDataFromMessageInMessageStorage} from '../MessagingWindow/MessagingUtils';
import {CaptureTransaction} from '../../../../../utils/CaptureTransaction';
import ConversationSidebarTab from './ConversationSidebarTab';
import ConversationFilterV2 from '../ConversationContainer/ConversationFilters/ConversationFilterV2';

const ConversationSidebarV2 = (props: {
  navigateOrOpenContactIdDrawer?: (actionId: any, actionData: any) => void;
  onConversationSelected?: (isSelected: boolean) => void;
  onClose?: () => void;
}) => {
  const [getSingleMessageDataById] = useLazyQuery(ConversationsQueries.GetSingleMessageDataById, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  })
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const userPreferenceSettings = commonData?.userPreferenceSetting;
  let emailInboxUserPreference = '';
  if (userPreferenceSettings?.userPreferences?.length > 0 && userPreferenceSettings?.userPreferences?.[0]?.preferencesJson) {
    const preferenceData = JSON.parse(userPreferenceSettings?.userPreferences?.[0]?.preferencesJson);
    emailInboxUserPreference = preferenceData?.userPreference?.emailInboxUserView;
  }
  const userUUID = getUserUUID();
  const userId = getUserId();
  const accountId = getAccountId();
  const basePath = '/inbox';
  const navigate = useNavigate();
  const intl = useIntl();
  const location = useLocation();
  const toast = useToast();
  const pathList = location?.pathname?.split('/') || [];
  const lastPathStr = pathList[pathList?.length - 1];
  const isEmployer = isEmployerRole();
  const isCallAllowed = isCallsAllowed();
  let conversationSideBarList = DISPLAY_CONVERSATION_SIDEBAR_LIST;
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR);
  const isEmailUserPreferenceEnabled = isAccountConfigEnabled(
    CONFIG_CODES.EMAIL_VIEW_USER_PREFERENCE
  );
  const userUuid = getUserUUID();
  const userPreferenceList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.USER_CONVERSATION_PREFERENCE
  );
  const groupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const groupTypeIdCodeObj = getMlovIdCodeObj(groupTypeList);
  const groupTypeCodeIdObj = getMlovCodeIdObj(groupTypeList)
  const [getUsersDataById] = useLazyQuery(InboxQueries.GetInternalUsersByIDs, {
    fetchPolicy: 'no-cache',
  });

  const [GetInboxDataByInboxId] = useLazyQuery(
    InboxQueries.GetInboxDataByInboxId,
    {
      fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      notifyOnNetworkStatusChange: true,
    }
  );

  const [GetAccountLocationData] = useLazyQuery(
    GetPracticeLocationByUuid,
    {
      fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      notifyOnNetworkStatusChange: true,
    }
  );

  const showPreferencesSetting = isAccountConfigEnabled(CONFIG_CODES.EMAIL_VIEW_USER_PREFERENCE);
  const captureTransactionInst = CaptureTransaction.getInstance();
  const conditionalStyles = React.useMemo(
    () => getConditionalStyles({isSidecarContext}),
    [isSidecarContext]
  );

  if (isEmployer) {
    conversationSideBarList = DISPLAY_CONVERSATION_SIDEBAR_LIST.filter((item) => {
      return item.header == 'Channels'
    })
    const filterMenuList = conversationSideBarList[0].list?.filter((channelObj) => {
      return channelObj.code == 'INTERNAL_CHAT'
    })
    conversationSideBarList[0].list = [...filterMenuList]
  }
  if (!isPatientMessagingEnabled) {
    const filterOutChatSTab = conversationSideBarList[1].list?.filter((channelObj) => {
      return channelObj.code !== CONVERSATION_TYPES.GROUP_CHAT
    })
    conversationSideBarList[1].list = [...filterOutChatSTab]
  }
  if (!isEFaxMessagingEnabled) {
    const filterOutChatSTab = conversationSideBarList[1].list?.filter((channelObj) => {
      return channelObj.code !== CONVERSATION_TYPES.FAX_CHAT
    })
    conversationSideBarList[1].list = [...filterOutChatSTab]
  }

  if (!isCallAllowed) {
    const filterOutMissedTab = conversationSideBarList?.[0]?.list?.filter((channelObj) => {
      return channelObj.code !== CONVERSATION_TYPES.MISSED_CALL_CHAT
    })
    conversationSideBarList[0].list = [...filterOutMissedTab]

    const filterOutChatSTab = conversationSideBarList[1].list?.filter((channelObj) => {
      return channelObj.code !== CONVERSATION_TYPES.ALL_CALLS
    })
    conversationSideBarList[1].list = [...filterOutChatSTab]
  }

  let selectedPathItem = conversationSideBarList[0].list[0];
  conversationSideBarList.map((menuItem) => {
    menuItem?.list.find((item) => {
      if (item.path === lastPathStr) {
        selectedPathItem = item;
      }
    })
  })

  // this needs to invoke only on flag based if side car flag is enable
  const sideCarInboxTypes = SIDECAR_CONVERSATION_TAB_BAR_LIST;
  const internalChatTabIndex = sideCarInboxTypes.findIndex((conversationTab) => {
    return conversationTab.key === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL
  })
  if (showOnlyInternalChatInSidecar) {
    const [internalChatTab] = sideCarInboxTypes.splice(internalChatTabIndex, 1);
    if (internalChatTab) {
      sideCarInboxTypes.splice(0, sideCarInboxTypes.length, internalChatTab);
    }
  }

  const [sideCarTabList, setSideCarTabList] =
    useState<ISideCarConversationTabList[]>(sideCarInboxTypes);
  const [isConversationSelected, setIsConversationSelected] = useState(false);

  useEffect(() => {
    if (props.onConversationSelected) {
      props.onConversationSelected(isConversationSelected);
    }
  }, [isConversationSelected]);

  const [conversationChannelTabState, setConversationChannelTabState] =
    useState<IConversationChannelTabState>({
      isLoading: false,
      inboxList: [] as IInbox[],
      selectedInboxList: [] as any,
      userInboxList: {} as IUserInboxList,
      displayCreateChatPopup: false,
      openCount: 0,
      isCreateChannelViewDisplay: false,
      selectedTabCode: isSidecarContext ? sideCarInboxTypes[0].key : selectedPathItem.key,
      selectedInbox: {} as IInbox,
      visibleDrawerCode: '',
      filterModal: false as boolean,
      conversationFilterProps: {} as any,
      conversationFilterQuery: {} as any,
      conversationUserFilterData: {} as any,
      selectedData: {} as any,
      formDataFromLeadData: {} as any,
      userFilterData: {} as any,
      emailInboxUserPreference: emailInboxUserPreference,
      userPreferenceChangeLoading: false,
      sideCarSearchString: '',
      filterCodeValueDataObj: {} as any
    });

  const [unreadCountMap, setUnreadCountMap] = useState(INITIAL_UNREAD_MAP_V2);
  const [allUnreadCount, setAllUnreadCount] = useState(0);
  const currentTabRef = useRef(conversationChannelTabState.selectedTabCode)


  const [getInboxes] = useLazyQuery(InboxQueries.GetInboxes, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    notifyOnNetworkStatusChange: true,
    variables: {
      searchString: `%%`,
      accountId: userData.account_id,
    },
  });


  const getInboxesData = async () => {
    const inboxResp = await getInboxes().catch(() => {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          inboxList: [],
        };
      });
    });
    if (inboxResp?.data?.inboxes?.length) {
      const userInboxList: any = getAllUserInboxes(
        inboxResp?.data?.inboxes,
        userData
      );

      const selectedChannelType = conversationChannelTabState.selectedTabCode;
      const curSelChannelTypeInboxList: IInbox[] =
        userInboxList[selectedChannelType] || [];
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          inboxList: inboxResp?.data?.inboxes,
          userInboxList: userInboxList,
          selectedInbox: curSelChannelTypeInboxList[0] || {},
        };
      });
    }
  };

  const [getUserPreference] = useLazyQuery(UserQueries.GET_USER_PREFERENCE, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: userUUID,
      userPreferencesTypeId: userPreferenceId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted:(data) => {
      if(data?.userPreferences?.length > 0 && data?.userPreferences?.[0]?.preferencesJson){
       const preferenceData = data?.userPreferences?.[0];
       LocalStorage.setItem(
        `messaging_user_perference_${userUUID}_${accountId}`,
        JSON.stringify(preferenceData)
      );
      }
    }
  });

  useEffect(() => {
    getUserPreference();
    getInboxesData();
    getConversationCountData();
    getUserConversationFilterDataFromAPI();

  }, [conversationChannelTabState?.emailInboxUserPreference]);

useEffect(() => {
  return () => {
    deleteAllConversationsDraftDataFromMessageInMessageStorage();
    setConversationChannelTabState(() => {
      return {
        isLoading: false,
        inboxList: [] as IInbox[],
        selectedInboxList: [] as any,
        userInboxList: {} as IUserInboxList,
        displayCreateChatPopup: false,
        openCount: 0,
        isCreateChannelViewDisplay: false,
        selectedTabCode: isSidecarContext
          ? sideCarInboxTypes[0].key
          : selectedPathItem.key,
        selectedInbox: {} as IInbox,
        visibleDrawerCode: '',
        filterModal: false as boolean,
        conversationFilterProps: {} as any,
        conversationFilterQuery: {} as any,
        selectedData: {} as any,
        formDataFromLeadData: {} as any,
        userFilterData: {} as any,
        conversationUserFilterData: {} as any,
      };
    });
  }
}, [])

  useEffect(() => {
    const conversationUuidMasterList: Array<string> = [];
    Object.keys(unreadCountMap).forEach((singleKey: any) => {
      if (isUpdateAllCount({key: singleKey})) {
        const conversationIdList =
          unreadCountMap?.[singleKey]?.conversationIdList || [];
        conversationIdList.forEach((conversationData:any) => {
          if(conversationData?.uuid && conversationUuidMasterList.indexOf(conversationData?.uuid) == -1){
            conversationUuidMasterList.push(conversationData?.uuid)
          }
        });
      }
    });
    setAllUnreadCount(conversationUuidMasterList?.length);
  }, [unreadCountMap]);

  const getConversationCountData = async () => {
    const userPreferenceContactType = {} as IUserPreferenceContactType;
    const emailInboxUserPreference = conversationChannelTabState?.emailInboxUserPreference;
    if ((emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER || emailInboxUserPreference === EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD) && showPreferencesSetting) {
      userPreferenceContactType.contactType = PERSON_TYPES.OTHER;
      userPreferenceContactType.include = emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER ? true :  false;
    }
    try {
      const response = await getAllConversationUnreadCountForAllTabsV2(userPreferenceContactType);
      setUnreadCountMap((prev) => {
        return {
          ...prev,
          ...response,
        };
      });
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          userPreferenceChangeLoading: false,
        }
      })
    } catch (err) {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          userPreferenceChangeLoading: false,
        }
      })
    }
  }

  const getUserConversationFilterDataFromAPI = async () => {
    const selectedChannelType = conversationChannelTabState.selectedTabCode;
    const filterResp = await getUserConversationFilter({
      filterCodes: USER_CONVERSATION_FILTER_CODES_LIST
    });
    if (filterResp?.data?.filterList?.length) {
      const filterCodeValueDataObj = getCodeAndUserConversationFilterData(filterResp?.data?.filterList);
      await handleConversationFilterData(filterCodeValueDataObj, selectedChannelType);
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          filterCodeValueDataObj: filterCodeValueDataObj
        }
      })
    }
  };

  const handleConversationFilterData = async(filterData: {[index: string]: IUserConversationFilterResp}, selectedChannelType: string) => {
    const conversationFilterValueData = filterData?.[selectedChannelType];
    if (
      conversationFilterValueData?.id &&
      !conversationFilterValueData?.isDefault &&
      Object.keys(conversationFilterValueData?.filterValue)?.length
    ) {
      const filterValue = conversationFilterValueData?.filterValue;
      let selectedUserData;
      let selectedInboxData;
      let selectedUserLocationData;
      if (filterValue?.assigneeIds) {
        selectedUserData = await getUsersDataById({
          variables: {
            ids: filterValue?.assigneeIds,
          },
        });
      }
      if (filterValue?.inboxId) {
        selectedInboxData = await GetInboxDataByInboxId({
          variables: {
            inboxId: filterValue?.inboxId,
          },
        });
      }
      if (filterValue?.locationUuids) {
        selectedUserLocationData = await GetAccountLocationData({
          variables: {
            locationUuids: filterValue?.locationUuids,
          },
        });
      }
      const conversationFilterData = getConversationFilterPropsAndQueryData({
        accountId: Number(accountId),
        userId: Number(userId),
        userUuid,
        selectedTabCode: selectedChannelType,
        isEFaxMessagingEnabled,
        isEmailUserPreferenceEnabled,
        isPatientMessagingEnabled,
        emailInboxUserPreference:
          conversationChannelTabState?.emailInboxUserPreference || '',
        groupTypeCodeIdObj,
        filterValue,
        selectedUserData: selectedUserData?.data?.accountUsers,
        userLocationData: selectedUserLocationData?.data?.practiceLocations,
        selectedInbox: selectedInboxData?.data?.inboxes?.[0],
      });

      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          conversationFilterProps: {
            ...conversationFilterData?.conversationFilterProps,
          },
          conversationUserFilterData: filterValue,
          conversationFilterQuery: {
            ...conversationFilterData?.conversationFilterQuery,
          },
          userFilterData: {
            ...conversationFilterValueData,
            additionalData: {
              conversationFilterProps: {
                ...conversationFilterData?.conversationFilterProps,
              },
              conversationFilterQuery: {
                ...conversationFilterData?.conversationFilterQuery,
              },
            },
          },
        };
      });
    } else {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          conversationFilterProps: {},
          conversationFilterQuery: {},
          userFilterData: {
            ...conversationFilterValueData,
          },
        };
      });
    }
  };

  const navigateToTabPath = (tabItem: IConversationChannelList) => {
    let finalPath = tabItem?.path || '';
    if (basePath) {
      finalPath = basePath + '/' + (tabItem?.path || '');
    }
    navigate(finalPath);
  };

  const onTabPress = (tabItem: IConversationChannelList) => {
    if (tabItem?.path) {
      navigateToTabPath(tabItem);
    }
    onTabClick(tabItem);
    saveLastTabSelected(tabItem.key);
  }


  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const onCreateGroupPopupActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    const contactActiveStatus = isActiveContact(actionData);
    const phoneNumber = actionData?.phoneNumber ||
      getSecondaryValue(actionData?.personContact, 'phone') ||
      ''
    const email = actionData?.email ||
      getSecondaryValue(actionData?.personContact, 'email') ||
      ''
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_POPUP: {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.NEW_GROUP_CREATED, {data: ''});
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
          };
        });

        break;
      }
      case CONVERSATION_ACTION_CODES.START_GROUP_CHAT:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            isCreateChannelViewDisplay: true,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
          };
        });
        break;
      case COMMON_ACTION_CODES.EDIT:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            visibleDrawerCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW,
            selectedData: actionData
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.START_SMS:
        if (contactActiveStatus) {
          if (phoneNumber) {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
                selectedData: actionData,
              };
            });
          } else {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW,
                selectedData: actionData,
              };
            });
          }
        } else {
          const message = getInActiveContactError(actionData);
          notification.error({
            message,
          });
        }
        break;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        if (contactActiveStatus) {
          if (email) {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
                selectedData: actionData
              };
            });
          } else {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW,
                selectedData: actionData,
              };
            });
          }
        } else {
          const message = getInActiveContactError(actionData);
          notification.error({
            message,
          });
        }
        break;
      case CONVERSATION_ACTION_CODES.CHAT_WITH_INTERNAL_USERS:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT,
            selectedData: actionData,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.START_VIDEO_MEET:
        const formattedContactData = getFormDataFromLeadData(
          actionData || {},
          commonData
        );
        if (contactActiveStatus) {
          if (phoneNumber || email) {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW,
                selectedData: formattedContactData
              };
            });
          } else {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                visibleDrawerCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW,
                selectedData: actionData,
              };
            });
          }
        } else {
          const message = getInActiveContactError(actionData);
          notification.error({
            message,
          });
        }
        break;

      case CONVERSATION_TAB_CODES.CHART:
        if (
          actionData?.contactType?.contactType?.code == 'CUSTOMER' ||
          actionData?.contactType?.contactType?.code == 'PATIENT'
        ) {
          props?.navigateOrOpenContactIdDrawer?.(actionData?.id, {
            state: {actionType: 'CREATE_CHART'},
          });
        }
        break;
      case GROUP_ACTION_CODES.GROUP_HEADER_CLICKED:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
            selectedData: actionData
          };
        });
        break;
      // case CHANNEL_TYPE_CODE.CALL_LOGS:
      //   //
      //   break;
    }
  };


  const showLoadingOnPreferenceChange = useCallback(() => {
    const currentTab = currentTabRef?.current;
    return conversationChannelTabState?.userPreferenceChangeLoading &&
      (currentTab === CHANNEL_TYPE_CODE.CHANNEL_EMAIL || currentTab === CHANNEL_TYPE_CODE.CHANNEL_ALL)
  }, [conversationChannelTabState?.userPreferenceChangeLoading, currentTabRef]);


  useEffect(() => {
    const conversationSidebarV2Event = new ConversationSidebarV2Event({
      userUUID: userUUID,
      getSingleMessageDataById: getSingleMessageDataById,
      userId: userId,
      getSearchParams: getSearchParams,
      getConversationCountData: getConversationCountData,
      setUnreadCountMap: setUnreadCountMap,
      onCreateGroupPopupActionPerformed: onCreateGroupPopupActionPerformed,
      selectedConversation: undefined,
      groupTypeIdCodeObj: groupTypeIdCodeObj
    });
    return conversationSidebarV2Event.addEventListener()
  }, [])

  const getUserPreferenceData = async () => {
    try {
      const userPreference = await getUserPreference();
      if (userPreference?.data?.userPreferences?.length > 0 && userPreference?.data?.userPreferences?.[0]?.preferencesJson) {
        const preferenceData = JSON.parse(userPreference?.data?.userPreferences?.[0]?.preferencesJson);
        const emailInboxUserPreference = preferenceData?.userPreference?.emailInboxUserView;
        if(userPreference?.data?.userPreferences?.length > 0 && userPreference?.data?.userPreferences?.[0]?.preferencesJson){
          const localPreferenceData = userPreference?.data?.userPreferences?.[0];
          LocalStorage.setItem(
           `messaging_user_perference_${userUUID}_${accountId}`,
           JSON.stringify(localPreferenceData)
          );
        }

        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            emailInboxUserPreference: emailInboxUserPreference,
          }
        })
      }
    } catch (error: any) {
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
        closeAllPrevToast: true,
      })
    }
  }

  const onUserPreferenceDataChange = useCallback((data: any) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        userPreferenceChangeLoading: true,
        emailInboxUserPreference: data?.selectedEmailInboxUserView,
      };
    });
  }, [conversationChannelTabState.selectedTabCode]);

  const setUserPreferenceChangeLoading = () => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        userPreferenceChangeLoading: false,
      }
    })
  }

  useEffect(() => {
    if (!emailInboxUserPreference?.length) {
      getUserPreferenceData();
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CUSTOM_COMMON_EVENT_CODES.USER_PREFERENCE_UPDATED,
      onUserPreferenceDataChange
    );

    return () => {
      eventBus.removeEventListener(onUserPreferenceDataChange);
    };
  },[])

  useEffect(() => {
    currentTabRef.current = conversationChannelTabState?.selectedTabCode;
    captureTransactionInst.initiateTransaction({
      name: 'TAB_CHANGE',
      identifier: conversationChannelTabState?.selectedTabCode,
    });

    return () => {
      captureTransactionInst.clearTransaction(
        'TAB_CHANGE',
        conversationChannelTabState?.selectedTabCode
      );
    };

  }, [conversationChannelTabState?.selectedTabCode]);

  const onCreateChannelActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.NEW_GROUP_CREATED, {
          conversations: actionData,
        });
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        break;
    }
  };

  const onCreateSmsConversationActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        if (actionData?.selectedInboxConversationData?.conversations?.[0]?.id) {
        }
        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              isCreateChannelViewDisplay: false,
              visibleDrawerCode: '',
            };
          });
        }
        break;
      case COMMON_ACTION_CODES.EMAIL_SENT:
        {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              isCreateChannelViewDisplay: false,
              visibleDrawerCode: '',
            };
          });
        }
        break;
      case COMMON_ACTION_CODES.RESET:
        {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              isCreateChannelViewDisplay: false,
              visibleDrawerCode: '',
            };
          });
        }
        break;
    }
    if (actionData?.inboxData?.id) {
      onInboxChange(actionData?.inboxData?.id);
    }
  };

  const onInboxChange = (inboxOptionData: any) => {
    const userInboxList: any = conversationChannelTabState.userInboxList;
    const selectedChannelType = conversationChannelTabState.selectedTabCode;
    const curSelChannelTypeInboxList: IInbox[] =
      userInboxList[selectedChannelType];
    const curSelectedInbox =
      curSelChannelTypeInboxList.find((inboxData) => {
        return inboxData.id === inboxOptionData.value;
      }) || ({} as any);
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        selectedInbox: curSelectedInbox,
      };
    });
  };

  const onActionPerformed = (action: any) => {
    switch (action) {
      case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: true,
            visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
          };
        });

        break;
      case CHANNEL_TYPE_CODE.CHANNEL_EMAIL:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: true,
            visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
          };
        });
        break;
      case CHANNEL_TYPE_CODE.CHANNEL_E_FAX:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            displayCreateChatPopup: true,
            visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_E_FAX,
          };
        });

        break;
      default:
        break;
    }
  };

  const filterModal = (isFilterDrawerOpen: boolean) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        filterModal: isFilterDrawerOpen,
      };
    });
  }

  const isCreateConversationDrawerVisible = (channelCode: string) => {
    return channelCode === conversationChannelTabState.visibleDrawerCode;
  };

  const getCreateChannelTabCode = () => {
    if (
      conversationChannelTabState.selectedTabCode ===
      CHANNEL_TYPE_CODE.CHANNEL_INTERNAL
    ) {
      return CONVERSATION_TAB_CODES.INTERNAL;
    } else {
      return CONVERSATION_TAB_CODES.PRIVATE;
    }
  };

  const onAppointmentBookingAction = useCallback(() => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        visibleDrawerCode: '',
      };
    });
  }, []);

  const onMeetingViewAction = useCallback((actionCode: any) => {
    if (actionCode == COMMON_ACTION_CODES.CANCEL) {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          visibleDrawerCode: '',
          selectedData: {},
        };
      });
    }
  }, []);

  const conversationActionDrawer = (): JSX.Element => {
    const userInboxList: any = conversationChannelTabState.userInboxList;
    return (
      <View style={styles.conversationDrawerContainer}>
        <CreateSmsConversationDrawer
          isDrawerVisible={isCreateConversationDrawerVisible(
            CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS
          )}
          selectedInbox={{} as any}
          selectedPatient={conversationChannelTabState.selectedData}
          onCreateSmsConversationActionPerformed={
            onCreateSmsConversationActionPerformed
          }
        />
        {isCreateConversationDrawerVisible(CHANNEL_TYPE_CODE.CHANNEL_EMAIL) && (
          <EmailDrawerCommon
            contactData={conversationChannelTabState.selectedData}
            selectedInbox={conversationChannelTabState.selectedInbox}
            onMailCompleteAction={onCreateSmsConversationActionPerformed}
          />
        )}

        {isCreateConversationDrawerVisible(
          CONVERSATION_ACTION_CODES.START_GROUP_CHAT
        ) ? (
          <CreateChannelView
            isDrawerVisible={isCreateConversationDrawerVisible(
              CONVERSATION_ACTION_CODES.START_GROUP_CHAT
            )}
            onCreateChannelActionPerformed={onCreateChannelActionPerformed}
            selectedTabCode={getCreateChannelTabCode()}
          />
        ) : null}
        {conversationChannelTabState.filterModal ? (
          <ConversationFilterV2
            showModal={conversationChannelTabState.filterModal}
            onModalAction={onModalAction}
            selectedTab={conversationChannelTabState.selectedTabCode}
            inboxList={userInboxList}
            selectedData={
              conversationChannelTabState?.conversationFilterProps
            }
            userFilterData={conversationChannelTabState?.userFilterData}
            emailInboxUserPreference={
              conversationChannelTabState?.emailInboxUserPreference
            }
          />
          ) : (
            <></>
          )
        }
        {conversationChannelTabState.visibleDrawerCode ===
          CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT && (
          <AppointmentBooking
            defaultParticipants={[
              {
                label: conversationChannelTabState.selectedData?.name || '',
                key: conversationChannelTabState.selectedData?.uuid || '',
                value: conversationChannelTabState.selectedData?.uuid || '',
                type: ParticipantType.patient,
              },
            ]}
            isVisible={true}
            onComplete={onAppointmentBookingAction}
            onCancel={onAppointmentBookingAction}
          />
        )}
        {isCreateConversationDrawerVisible(
          RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW
        ) && (
          <MeetingView
            personData={conversationChannelTabState.selectedData}
            onPersonActionPerformed={onMeetingViewAction}
          />
        )}
        {isCreateConversationDrawerVisible(CHANNEL_TYPE_CODE.CHANNEL_E_FAX) && (
          <CreateEFaxConversationDrawer
            visible={isCreateConversationDrawerVisible(
              CHANNEL_TYPE_CODE.CHANNEL_E_FAX
            )}
            onActionPerformed={onCreateChannelActionPerformed}
          />
        )}
      </View>
    );
  };

  const onTabClick = async (tabItem: IConversationChannelList) => {
    const userInboxList: any = conversationChannelTabState.userInboxList;
    const selectedChannelType = tabItem.key;
    const curSelChannelTypeInboxList: IInbox[] = userInboxList[selectedChannelType];
    await handleConversationFilterData(conversationChannelTabState?.filterCodeValueDataObj || {}, selectedChannelType)
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        selectedTabCode: tabItem.key,
        selectedInbox: curSelChannelTypeInboxList?.[0] || {},
        conversationChannelTabState: {},
        selectedInboxList: curSelChannelTypeInboxList || [],
      };
    });
  };

  const createUserConversationFilterIfNotExistFromAPI = async (actionData: any) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        isLoading: true,
        filterModal: false,
      };
    });
    if (conversationChannelTabState?.userFilterData?.id) {
      await updateUserConversationFilterFromAPI(
        conversationChannelTabState?.userFilterData?.id,
        actionData
      )
    } else {
      await createUserConversationFilterFromAPI(actionData);
    }

  }

  const updateUserConversationFilterFromAPI = async (id: string, actionData: any) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        isLoading: true,
        filterModal: false,
      };
    });
    const filterValueData = getFilterValueData(actionData.conversationFilterProps);
    let respData = {} as any;
    respData = await updateUserConversationFilter({
      id: id,
      filterCode: conversationChannelTabState.selectedTabCode,
      filterValue: filterValueData
    });
    if (respData?.data?.count) {
      setConversationChannelTabState((prev) => {
        const filterCodeValueDataObj = prev?.filterCodeValueDataObj;
        if (filterCodeValueDataObj?.[conversationChannelTabState.selectedTabCode]?.id) {
          const selectedChannelFilterCodeObj = filterCodeValueDataObj?.[conversationChannelTabState.selectedTabCode]
          selectedChannelFilterCodeObj.filterValue = filterValueData;
          selectedChannelFilterCodeObj.isDefault = false;
          filterCodeValueDataObj[conversationChannelTabState.selectedTabCode] = selectedChannelFilterCodeObj
        }
        return {
          ...prev,
          filterModal: false,
          conversationFilterProps: {
            ...actionData.conversationFilterProps
          },
          conversationUserFilterData: filterValueData,
          conversationFilterQuery: {
            ...actionData.conversationFilterQuery
          },
          userFilterData: {
            ...conversationChannelTabState?.userFilterData,
            additionalData: {
              conversationFilterProps: {
                ...actionData.conversationFilterProps
              },
              conversationFilterQuery: {
                ...actionData.conversationFilterQuery
              },
            },
            filterCode: conversationChannelTabState.selectedTabCode,
            filterValue: actionData?.filterValue || {},
          },
          filterCodeValueDataObj: filterCodeValueDataObj
        };
      });
    } else {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          conversationFilterProps: {
            ...actionData.conversationFilterProps
          },
          conversationFilterQuery: {
            ...actionData.conversationFilterQuery
          },
          filterModal: false,
        };
      });
    }
  }

  const resetUserConversationFilterFromAPI = async (id: string, actionData: any) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        isLoading: true,
        filterModal: false,
      };
    });
    const respData = await resetUserConversationFilter({
      id: id,
      filterCode: conversationChannelTabState.selectedTabCode,
    });
    if (respData?.data) {
      setConversationChannelTabState((prev) => {
        const filterCodeValueDataObj = prev?.filterCodeValueDataObj;
        if (filterCodeValueDataObj?.[conversationChannelTabState.selectedTabCode]?.id) {
          const selectedChannelFilterCodeObj = filterCodeValueDataObj?.[conversationChannelTabState.selectedTabCode]
          selectedChannelFilterCodeObj.filterValue = {};
          selectedChannelFilterCodeObj.isDefault = true;
          filterCodeValueDataObj[conversationChannelTabState.selectedTabCode] = selectedChannelFilterCodeObj
        }
        return {
          ...prev,
          filterModal: false,
          conversationFilterProps: {
            ...actionData.conversationFilterProps
          },
          conversationFilterQuery: {
            ...actionData.conversationFilterQuery
          },
          userFilterData: {
            ...conversationChannelTabState?.userFilterData,
            additionalData: {
              conversationFilterProps: {
                ...actionData.conversationFilterProps
              },
              conversationFilterQuery: {
                ...actionData.conversationFilterQuery
              },
            },
            filterCode: conversationChannelTabState.selectedTabCode,
            filterValue: {},
          },
          conversationUserFilterData: {},
          filterCodeValueDataObj: filterCodeValueDataObj
        };
      });
    } else {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          conversationFilterProps: {
            ...actionData.conversationFilterProps
          },
          conversationFilterQuery: {
            ...actionData.conversationFilterQuery
          },
          filterModal: false,
        };
      });
    }
  }

  const createUserConversationFilterFromAPI = async (actionData: any) => {
    const selectedChannelType = conversationChannelTabState.selectedTabCode;
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const filterValueData = getFilterValueData(actionData.conversationFilterProps);
    let respData = {} as any;
    respData = await createUserConversationFilter({
      filterCode: conversationChannelTabState.selectedTabCode,
      filterValue: filterValueData
    });
    if (respData?.data?.id) {
      setConversationChannelTabState((prev) => {
        const filterCodeValueDataObj = prev.filterCodeValueDataObj;
        let singleInboxFilterFinalData = {...respData?.data};
        if (filterCodeValueDataObj && filterCodeValueDataObj[selectedChannelType] && Object.keys(filterCodeValueDataObj[selectedChannelType]).length) {
          singleInboxFilterFinalData = {...singleInboxFilterFinalData, ...filterCodeValueDataObj[selectedChannelType]};
        }
        const newFilterCodeValueDataObj = {
          [selectedChannelType]: singleInboxFilterFinalData
        }
        const updatedFilterCodeValueDataObj = {...filterCodeValueDataObj, ...newFilterCodeValueDataObj}
        return {
          ...prev,
          filterModal: false,
          conversationUserFilterData: filterValueData,
          conversationFilterProps: {
            ...actionData.conversationFilterProps
          },
          conversationFilterQuery: {
            ...actionData.conversationFilterQuery
          },
          userFilterData: {
            id: respData?.data?.id,
            additionalData: {
              conversationFilterProps: {
                ...actionData.conversationFilterProps
              },
              conversationFilterQuery: {
                ...actionData.conversationFilterQuery
              },
            },
            filterCode: conversationChannelTabState.selectedTabCode,
            filterValue: actionData?.filterValue || {},
            defaultFilterValue: {},
          } as any,
          filterCodeValueDataObj: updatedFilterCodeValueDataObj
        };
      });
    } else {
      setConversationChannelTabState((prev) => {
        return {
          ...prev,
          conversationFilterProps: {
            ...actionData.conversationFilterProps
          },
          conversationFilterQuery: {
            ...actionData.conversationFilterQuery
          },
          filterModal: false,
        };
      });
    }
  }

  const onModalAction = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.FILTER_CHANGED:
        if (
          actionData.conversationFilterState ||
          actionData.conversationFilterQuery ||
          actionData.conversationFilterProps
        ) {
          createUserConversationFilterIfNotExistFromAPI(actionData);
        } else {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              filterModal: false,
            };
          });
        }

        break;
      case COMMON_ACTION_CODES.RESET_FILTER_TO_DEFAULT:
        if (
          actionData.conversationFilterState ||
          actionData.conversationFilterQuery ||
          actionData.conversationFilterProps
        ) {
          if (conversationChannelTabState?.userFilterData?.id) {
            resetUserConversationFilterFromAPI(
              conversationChannelTabState?.userFilterData?.id,
              actionData
            );
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                conversationFilterProps: {},
                conversationFilterQuery: {},
                filterModal: false,
              };
            });
          } else {
            setConversationChannelTabState((prev) => {
              return {
                ...prev,
                conversationFilterProps: actionData.conversationFilterProps,
                conversationFilterQuery: actionData.conversationFilterQuery,
                filterModal: false,
              };
            });
          }
        } else {
          setConversationChannelTabState((prev) => {
            return {
              ...prev,
              filterModal: false,
            };
          });
        }

        break;
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setConversationChannelTabState((prev) => {
          return {
            ...prev,
            filterModal: false,
          };
        });
        break;
    }
  };

  const saveLastTabSelected = (tabKey: string) => {
    const selectedChannelType = conversationChannelTabState.selectedTabCode;
    const storageValue: any = {};
    storageValue[userUUID] = tabKey;
    LocalStorage.setItem('communicationLastSelectedTab', JSON.stringify(storageValue));
    LocalStorage.getItem('conversationLastFilterSelected')
      .then((response) => {
        if (response) {
          const responseData = JSON.parse(response);
          if (responseData?.[userUUID] && responseData?.[userUUID]?.selectedTab) {
            if (responseData?.[userUUID]?.selectedTab !== tabKey) {
              LocalStorage.removeItem('conversationLastFilterSelected');
            }
          }
        }
      })
  }

  const updateSideCarTabList = (selectedTab: string) => {
    const currentList = sideCarTabList;
    const index = currentList.findIndex(item => item.key === selectedTab);
    const selectedItem = currentList.find(item => item.key === selectedTab);
    if (index && index > 3 && selectedItem) {
      currentList.splice(index, 1);
      // Add the item to the 4th position
      currentList.splice(3, 0, selectedItem);
    }
    setSideCarTabList(currentList);
  }

  const onLeadFormCompleteAction = useCallback(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(WINDOW_EVENT_CODES.PATIENT_UPDATES, {
      fromSection: 'LEFT_PROFILE_VIEW',
    });

    eventBus.broadcastEvent(
      SUPPORTED_EVENT_CODE.MESSAGE_UPDATED,
      COMMON_ACTION_CODES.COMPLETED
    );
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        visibleDrawerCode: '',
        selectedData: {},
      };
    });
  }, []);

  const onSideCarActionButtonPress = useCallback(() => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        displayCreateChatPopup: false,
        visibleDrawerCode:
          CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
      };
    });
  }, []);

  const setSearchText = useCallback((text: string) => {
    setConversationChannelTabState((prev) => {
      return {
        ...prev,
        sideCarSearchString: text,
      };
    });
  }, []);

  const onSideCarTabClick = useCallback((tabItem: any) => {
    if (tabItem?.path) {
      navigateToTabPath(tabItem);
    }
    onTabClick(tabItem);
    saveLastTabSelected(tabItem.key);
    updateSideCarTabList(tabItem.key);
  }, []);

  return (
    <>
      {showLoadingOnPreferenceChange() && (
        <View
          zIndex={10}
          position="absolute"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          height={'100%'}
          width={'100%'}
          backgroundColor={Colors?.Custom?.Gray200}
          opacity={0.5}
        >
          <Spin size="large" />
        </View>
      )}
      <View flex={1}>
        {isSidecarContext ? (
          <View height={isConversationSelected ? 0 : 'auto'}>
            {!showOnlyInternalChatInSidecar && (
              <>
                <View>
                  <SideCarConversationTabBar
                    tabsList={sideCarTabList}
                    showMaxTabCount={4}
                    unreadCountList={unreadCountMap}
                    allUnreadCount={allUnreadCount}
                    conversationFilterProps={
                      conversationChannelTabState.conversationFilterProps
                    }
                    filterModal={filterModal}
                    setSearchText={setSearchText}
                    onTabClick={onSideCarTabClick}
                  />
                </View>
              </>
            )}
          </View>
        ) : (
          <View style={styles.titleWrapper}>
            <TitleSubtitleView
              titleLabelId="messagingTitle"
              subtitleLabelId="messagingSubtitle"
            />
          </View>
        )}
        <View style={conditionalStyles.sideBarContainer} >
          {isSidecarContext ? (
            <></>
          ) : (
            <View
              style={styles.sidebarListContainer}
            >
              <ScrollView flex={1} showsVerticalScrollIndicator={false}>
                <View>
                  {conversationSideBarList.map((menuItem, index) => {
                    return (
                      <View key={menuItem.intlId+"_ConvSidebar_"+index}>
                        <View
                          style={styles.sidebarListContainerItem}
                        >
                          <Text
                            style={styles.sidebarListContainerItemText}
                            fontWeight={500}
                            size={'smMedium'}
                            {...testID(menuItem.intlId || '')}
                          >
                            {intl.formatMessage({id: menuItem.intlId})}
                          </Text>
                          <View style={styles.automationSettingBtn}>
                            {!isEmployerRole() &&
                              menuItem.header.toLocaleUpperCase() ==
                                MAIN_MENU_CODES.INBOX && (
                                <AutomationSettingBtn
                                  hideDivider={true}
                                  flowType={FlowType.communication}
                                  settingsPath={
                                    isMessageSettingAllowed()
                                      ? '/admin/communication/inbox'
                                      : ''
                                  }
                                />
                              )}
                          </View>
                        </View>
                        {menuItem.list.map(
                          (tabItem: IConversationChannelList) => {
                            return (
                              <ConversationSidebarTab
                                key={tabItem.key + '_ConvSidebar_'}
                                tabItem={tabItem}
                                allUnreadCount={allUnreadCount}
                                selectedTabCode={
                                  conversationChannelTabState.selectedTabCode
                                }
                                count={
                                  unreadCountMap[tabItem.code || '']?.count || 0
                                }
                                isShowCountLoading={
                                  conversationChannelTabState?.userPreferenceChangeLoading &&
                                  (tabItem.tabCode ===
                                    INBOX_TYPE_TAB_CODE.ALL_CHAT ||
                                    tabItem.tabCode ===
                                      INBOX_TYPE_TAB_CODE.EMAIL_CHAT)
                                }
                                onTabPress={onTabPress}
                              />
                            );
                          }
                        )}
                      </View>
                    );
                  })}
                </View>
              </ScrollView>
            </View>
          )}

          <View
            style={conditionalStyles.conversationContainer}
            flex={1}
          >
            <Conversations
              key={conversationChannelTabState.selectedTabCode}
              conversationUserFilterData={conversationChannelTabState.conversationUserFilterData}
              selectedInboxTypeCode={
                conversationChannelTabState.selectedTabCode as IInboxChannelType
              }
              selectedInbox={conversationChannelTabState.selectedInbox}
              conversationFilterQuery={
                conversationChannelTabState.conversationFilterQuery
              }
              conversationFilterProps={
                conversationChannelTabState.conversationFilterProps
              }
              onActionPerformed={onActionPerformed}
              allInboxList={conversationChannelTabState.userInboxList}
              filterModal={filterModal}
              emailInboxUserPreference={
                conversationChannelTabState?.emailInboxUserPreference
              }
              setUserPreferenceChangeLoading={setUserPreferenceChangeLoading}
              sideCarSearchString={
                isSidecarContext
                  ? conversationChannelTabState.sideCarSearchString
                  : undefined
              }
              isConversationSelectedInSideCar={setIsConversationSelected}
            />
          </View>
        </View>
        {conversationActionDrawer()}
        {conversationChannelTabState.visibleDrawerCode ===
          RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW && (
          <AddOrUpdateLead
            singleLeadData={conversationChannelTabState.selectedData}
            onFormCompleteAction={onLeadFormCompleteAction}
            personType={
              conversationChannelTabState.selectedData.contactType?.code
            }
            personTypeUuid={
              conversationChannelTabState.selectedData.contactType?.id
            }
          />
        )}
      </View>
    </>
  );
};

export default ConversationSidebarV2
