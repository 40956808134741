import moment from 'moment';
import {IS_DEACTIVATE_CONTACT_ENABLED, PATIENT_SEARCH_MODULE_CODE, PERSON_TYPES } from '../../../constants';
import { ICommonData } from '../../../context/CommonDataContext';
import {
  IContactPracticeLocations,
  IUserPracticeLocation
} from '../../../services/Location/interfaces';
import { EHR_CAPABILITY_CONST, getEhrConfig } from '../../../utils/capabilityUtils';
import {
  isEmptyString,
  isNumeric,
  IS_FEATURE_FLAG_ENABLED,
  unmaskPhoneNumber,
  normalizeNumber,
  normalizeBoolean,
  normalizeDate,
} from '../../../utils/commonUtils';
import { getCurrentTimeZone, getEndOfDay, getStartAndEndOfDayForSearchByBirthDate, getStartOfDay, getTodaysTimeSlot } from '../../../utils/DateUtils';
import { getContactTypeId, getContactTypeIdInDefaultContactTypes, getMlovId } from '../../../utils/mlovUtils';
import { Vital } from '../../../utils/VitalUtils';
import { isSearchStringADate } from '../../common/PatientSearch/PatientSearchUtils';
import {
  CONTACT_ACTION_CODES,
  DISPLAY_LIST_BY_PERSON_TYPE,
  QUICK_ACTION_LIST,
} from './ContactsConst';
import { checkIfAgeIsLessThan18 } from './Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { IAllowedOperation } from '../../../Interfaces';
import {CustomAttributeValues, ICustomAttributeQueryObj, ICustomAttributesFilterRange, ICustomAttributesFilters} from './CustomField/interface';
import {CUSTOM_ATTRIBUTE_FILTERS_CONDITION, CUSTOM_ATTRIBUTE_VALUE_COLUMN, replaceFilterCondition} from './CustomField/CustomFieldConst';
import {USER_ACCESS_PERMISSION} from '../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import {PERSON_ACTION_CODES} from '../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {CONVERSATION_ACTION_CODES} from '../../../constants/ActionConst';
import {MIDDLE_CONTAINER_TABS} from '../../PersonOmniView/MiddleContainer/MiddleContainerConst';
import {ISearchContactFilter} from './Leads/LeadView/LeadTableView/LeadListView/interface';
import { isWeb } from '../../../utils/platformCheckUtils';

export const getQuickActionObjListByPersonType = (
  personType: string,
  canCreatePatient?: boolean,
  canUpdatePatient?: boolean
): IQuickAction[] => {
  let personQuickActionCodeList = DISPLAY_LIST_BY_PERSON_TYPE[personType] ? [
    ...DISPLAY_LIST_BY_PERSON_TYPE[personType],
  ]: [
    ...DISPLAY_LIST_BY_PERSON_TYPE[PERSON_TYPES.LEAD],
  ];
  if(!!canUpdatePatient === false){
    personQuickActionCodeList = personQuickActionCodeList.filter(code => code !==  CONTACT_ACTION_CODES.EDIT);
  }
  if(personType === PERSON_TYPES.LEAD &&!! canCreatePatient === false){
    personQuickActionCodeList = personQuickActionCodeList.filter(
      (code) => code !== CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER
    );
  }
  if (
    !IS_DEACTIVATE_CONTACT_ENABLED &&
    personType === PERSON_TYPES.CUSTOMER &&
    personQuickActionCodeList.indexOf(CONTACT_ACTION_CODES.DEACTIVATE) !== -1
  ) {
    personQuickActionCodeList.splice(
      personQuickActionCodeList.indexOf(CONTACT_ACTION_CODES.DEACTIVATE),
      1
    );
  }

  if (!IS_FEATURE_FLAG_ENABLED('ENABLE_MEMBERSHIP')) {
    personQuickActionCodeList.splice(
      personQuickActionCodeList.indexOf(
        CONTACT_ACTION_CODES.SEND_MEMBERSHIP_LINK
      ),
      1
    );
  }

  const quickActionObjList = QUICK_ACTION_LIST.filter(
    (actionObj: IQuickAction) => {
      return personQuickActionCodeList.includes(actionObj.actionCode);
    }
  );
  return quickActionObjList || [];
};

export const getConvertStrings = (personType: any, isUpdate: boolean) => {
  let convertString: any = {};
  if (PERSON_TYPES.LEAD === personType) {
    if (!isUpdate) {
      convertString = {
        heading: 'convertToLead',
        title: 'confirmTextForLead',
      };
    } else {
      convertString = {
        heading: 'convertToCustomer',
        title: 'confirmTextForCustomer',
      };
    }
  }
  if (PERSON_TYPES.CUSTOMER === personType) {
    if (!isUpdate) {
      convertString = {
        heading: 'convertToCustomer',
        title: 'confirmTextForCustomer',
      };
    } else {
      convertString = {
        heading: 'convertToLead',
        title: 'confirmTextForLead',
      };
    }
  }
  return convertString;
};

export const getChosenNameCondition = (searchStrings: string[]) => {
  const conditionArray = [] as any[];

  const generateCombinations = (arr: string[]) => {
    const combinations = [] as any;
    const permute = (array: string[], current: string[] = []) => {
      if (array.length === 0) {
        combinations.push([...current]);
        return;
      }
      for (let itemIndex = 0; itemIndex < array.length; itemIndex++) {
        const remaining = array.slice(0, itemIndex).concat(array.slice(itemIndex + 1));
        permute(remaining, current.concat(array[itemIndex]));
      }
    };
    permute(arr);
    return combinations;
  };

  const partialMatch = (searchString: string) => `%${searchString}%`;

  if (searchStrings.length === 1) {
    const searchString = searchStrings[0] || '';
    conditionArray.push({
      person: {
        chosenName: {
          _ilike: partialMatch(searchString),
        },
      },
    });
  } else {
    for (const combination of generateCombinations(searchStrings)) {
      conditionArray.push({
        person: {
          chosenName: {
            _ilike: partialMatch(combination.join(" ")),
          },
        },
      });
    }
  }
  return conditionArray;
};

export const getChosenNameConditionV2 = (searchStrings: string[]) => {
  const conditionArray = [] as any[];

  const generateCombinations = (arr: string[]) => {
    const combinations = [] as any;
    const permute = (array: string[], current: string[] = []) => {
      if (array.length === 0) {
        combinations.push([...current]);
        return;
      }
      for (let itemIndex = 0; itemIndex < array.length; itemIndex++) {
        const remaining = array.slice(0, itemIndex).concat(array.slice(itemIndex + 1));
        permute(remaining, current.concat(array[itemIndex]));
      }
    };
    permute(arr);
    return combinations;
  };

  const partialMatch = (searchString: string) => `%${searchString}%`;

  if (searchStrings.length === 1) {
    const searchString = searchStrings[0] || '';
    conditionArray.push({
      person: {
        chosenName: {
          _ilike: partialMatch(searchString),
        },
      }
    });
  } else if((searchStrings.length > 1)) {
    conditionArray.push({
      person: {
        _or: []
      }
    });
    for (const combination of generateCombinations(searchStrings)) {
      if (conditionArray?.[0]?.person?._or) {
        conditionArray?.[0]?.person?._or.push({
            chosenName: {
              _ilike: partialMatch(combination.join(" ")),
            },
        });
      }
    }
  }
  return conditionArray;
};

export const getPatientSearchORCondition = (searchString: string, searchStringArray: string[], searchModuleCode?: string, isAthena?: boolean) => {
  const conditionArray = [] as any[];
  let searchString1 = '';
  let searchString2 = '';
  let searchString3 = '';
  if (searchStringArray?.length > 3) {
    searchString1 = searchStringArray[0] || '';
    searchString2 = searchStringArray
      .slice(1, searchStringArray.length - 1)
      .join(' ');
    searchString3 = searchStringArray[searchStringArray.length - 1] || '';
  } else if (searchStringArray.length) {
    searchString1 = searchStringArray[0] || '';
    searchString2 = searchStringArray[1] || '';
    searchString3 = searchStringArray[2] || '';
  }

  if (searchString.indexOf('@') != -1) {
    conditionArray.push({
      email: {
        _ilike: '%' + searchString + '%',
      },
    });
  } else if (isNumeric(searchString)) {
    conditionArray.push({
      phoneNumber: {
        _ilike: '%' + searchString + '%',
      },
    });
    // NOTE: We need this in case when > lead is created with number as name
    conditionArray.push({
      name: {
        _ilike: '%' + searchString + '%',
      }
    });
    if (isAthena) {  
      conditionArray.push({
        patient: {
          patientId: {
            _ilike: '%' + searchString + '%',
          }
        }
      });
    }
  } else if (searchStringArray.length === 1) {
    searchString = searchString1;
    conditionArray.push({
      person: {
        firstName: {
          _ilike: '%' + searchString + '%',
        },
      },
    });
    conditionArray.push({
      person: {
        lastName: {
          _ilike: '%' + searchString + '%',
        },
      },
    });
    conditionArray.push({
      person: {
        middleName: {
          _ilike: '%' + searchString + '%',
        },
      },
    });
     // NOTE: We need this in case when > lead is created with number as name
    if (searchString.includes("+")) {
      conditionArray.push({
        name: {
          _ilike: '%' + searchString + '%',
        }
      });
    };
    if (searchModuleCode === PATIENT_SEARCH_MODULE_CODE.CONVERSATION_LIST) {
      conditionArray.push(...getChosenNameConditionV2([searchString]));
    } else {
      conditionArray.push(...getChosenNameCondition([searchString]));
    }
  } else if (searchStringArray.length === 2) {
    conditionArray.push({
      person: {
        _and: {
          firstName: {
            _ilike: '%' + searchString1 + '%',
          },
          lastName: {
            _ilike: '%' + searchString2 + '%',
          },
        },
      },
    });
    conditionArray.push({
      person: {
        _and: {
          firstName: {
            _ilike: '%' + searchString2 + '%',
          },
          lastName: {
            _ilike: '%' + searchString1 + '%',
          },
        },
      },
    });
    conditionArray.push({
      person: {
        _and: {
          middleName: {
            _ilike: '%' + searchString1 + '%',
          },
          lastName: {
            _ilike: '%' + searchString2 + '%',
          },
        },
      },
    });
    conditionArray.push({
      person: {
        _and: {
          firstName: {
            _ilike: '%' + searchString1 + '%',
          },
          middleName: {
            _ilike: '%' + searchString2 + '%',
          },
        },
      },
    });
     // NOTE: We need this in case when > lead is created with number as name
    if (searchString.includes("+")) {
      conditionArray.push({
        name: {
          _ilike: '%' + searchString + '%',
        }
      });
    };
    if (searchModuleCode === PATIENT_SEARCH_MODULE_CODE.CONVERSATION_LIST) {
      conditionArray.push(...getChosenNameConditionV2([searchString1, searchString2]));
    } else {
      conditionArray.push(...getChosenNameCondition([searchString1, searchString2]));
    }
  } else if (searchStringArray.length === 3) {
    conditionArray.push({
      person: {
        _and: {
          firstName: {
            _ilike: '%' + searchString1 + '%',
          },
          lastName: {
            _ilike: '%' + searchString3 + '%',
          },
        },
      },
    });
    conditionArray.push({
      person: {
        _and: {
          firstName: {
            _ilike: '%' + searchString2 + '%',
          },
          lastName: {
            _ilike: '%' + searchString1 + '%',
          },
        },
      },
    });
    conditionArray.push({
      person: {
        _and: {
          middleName: {
            _ilike: '%' + searchString2 + '%',
          },
          lastName: {
            _ilike: '%' + searchString3 + '%',
          },
        },
      },
    });
    conditionArray.push({
      person: {
        _and: {
          firstName: {
            _ilike: '%' + searchString2 + '%',
          },
          middleName: {
            _ilike: '%' + searchString3 + '%',
          },
        },
      },
    });
     // NOTE: We need this in case when > lead is created with number as name
    if (searchString.includes("+")) {
      conditionArray.push({
        name: {
          _ilike: '%' + searchString + '%',
        }
      });
    };
    if (searchModuleCode === PATIENT_SEARCH_MODULE_CODE.CONVERSATION_LIST) {
      conditionArray.push(...getChosenNameConditionV2([searchString1, searchString2, searchString3]));
    } else {
      conditionArray.push(...getChosenNameCondition([searchString1, searchString2, searchString3]));
    }
  } else if (searchStringArray.length > 3) {
    conditionArray.push({
      person: {
        _and: {
          firstName: {
            _ilike: '%' + searchString1 + '%',
          },
          middleName: {
            _ilike: '%' + searchString2 + '%',
          },
          lastName: {
            _ilike: '%' + searchString3 + '%',
          },
        },
      },
    });
     // NOTE: We need this in case when > lead is created with number as name
    if (searchString.includes("+")) {
      conditionArray.push({
        name: {
          _ilike: '%' + searchString + '%',
        }
      });
    };
    if (searchModuleCode === PATIENT_SEARCH_MODULE_CODE.CONVERSATION_LIST) {
      conditionArray.push(...getChosenNameConditionV2([searchString1, searchString2, searchString3]));
    } else {
      conditionArray.push(...getChosenNameCondition([searchString1, searchString2, searchString3]));
    }

  }
  return conditionArray;
}

export const getContactFiltersObject = (
  filter: any,
  searchString: string,
  account_id: number | string,
  tagItem: any,
  inboxItem: any,
  contactTypeId: any,
  selectedUserPracticeLocations?: IUserPracticeLocation[],
  status?: any,
  formData?: any,
  employerId?: string,
  mlovData?: ICommonData,
  tab?: string,
  myPatientList?: string[],
  loggedInStatus?: any,
  onlyShowLead?: boolean,
  userLocationUuids?: string[],
) => {
  const selectedUserPracticeLocationsUuid = selectedUserPracticeLocations?.map(
    (location: any) => {
      return location?.practiceLocation?.uuid;
    }
  );
  delete filter._and;
  const filter_and: any = [];

  filter = {
    _or: {},
    contactPracticeLocations: {
      _or: [
        {
          _and: []
        }
      ],
    },
  };
  if (
    searchString &&
    (searchString.includes('+') ||
      searchString.includes('(') ||
      searchString.includes(')')) &&
    searchString?.length > 10 &&
    !searchString.includes('@')
  ) {
    searchString = unmaskPhoneNumber(searchString);
  }
  if (searchString && isSearchStringADate(searchString?.trim())) {
    const searchStringIsADate = isSearchStringADate(searchString?.trim())
    if (searchStringIsADate) {
      const startAndEndOfDay = getStartAndEndOfDayForSearchByBirthDate(searchStringIsADate)
      if (startAndEndOfDay) {
        filter = {
          ...filter,
          person:
          {
            birthDate: {
              _gte: startAndEndOfDay[0],
              _lte: startAndEndOfDay[1]
            }
          }
        }
      }
      else return undefined // if searchString is a date(birth date), but not a valid date or if it is a future date, then no need to run query
    }
  }
  else {
    let searchStringArray = searchString.split(' ');
    searchStringArray = searchStringArray.filter((char) => {
      return char.length >= 1
    })
    if (searchString) {
      filter = {
        ...filter,
        _or: getPatientSearchORCondition(searchString, searchStringArray),
      };
    }
  }

  if (loggedInStatus) {
    if (loggedInStatus == 'NOT_LOGGED_IN') {
      filter = {
        ...filter,
        _not: {
          additionalAttributes: {
            _has_key: 'loggedInTime',
          },
        },
      };
    } else if (loggedInStatus == 'LOGGED_IN') {
      filter = {
        ...filter,
        additionalAttributes: {_has_key: 'loggedInTime'},
      };
    }
  }

  if (status) {
    if (status == 'INACTIVE') {
      filter = {
        ...filter,
        isActive: {
          _eq: false,
        },
      };
    } else if (status == 'ACTIVE') {
      filter = {
        ...filter,
        isActive: {
          _eq: true,
        },
      };
    }
  } else {
    filter = {
      ...filter,
      isActive: {
        _eq: true,
      },
    };
  }
  // filter.email = {_is_null: false};
  // filter.phoneNumber = {_is_null: false};
  filter.accountId = { _eq: account_id };

  // filter._and = [
  //   {
  //     id: {_neq: 773},
  //   },
  //   {
  //     id: {_neq: 846},
  //   },
  //   {
  //     id: {_neq: 1077},
  //   },x
  // ];
  if (formData?.toDate && formData?.fromDate) {
    const toDate = {
      createdAt: {
        _lt: moment(formData.toDate).format('YYYY-MM-DD') + 'T23:59:59',
      },
    };
    const fromDate = {
      createdAt: {
        _gt: moment(formData.fromDate).format('YYYY-MM-DD') + 'T00:00:00',
      },
    };
    filter_and.push(toDate);
    filter_and.push(fromDate);
  }

  if (formData?.selectedEmployeeType?.length) {
    const employeeType = {
      employees: {
        isDeleted: {
          _eq: false,
        },
        type: {
          _in: formData?.selectedEmployeeType,
        },
      },
    };
    filter_and.push(employeeType);
  }

  if (formData?.customAttributes?.length) {
    formData?.customAttributes?.forEach((customAttribute: ICustomAttributesFilters) => {
      const customAttributeFilterObject = getCustomAttributeFilterObject(customAttribute);
      if (customAttributeFilterObject?.customAttributeValues?.customAttributeId) {
        filter_and.push(customAttributeFilterObject);
      }
    })
  }

  if (formData?.employerIds?.length > 0) {
    const employerFilter = {
      employees: {
        isDeleted: {
          _eq: false,
        },
        employerId: {
          _in: formData?.employerIds,
        },
      },
    };
    filter_and.push(employerFilter);
  }
  if (tab && tab === 'myPatient' || (myPatientList && myPatientList?.length > 0)) {
    const teamFilter = {
      uuid: {
        _in: myPatientList
      }
    }
    filter_and.push(teamFilter);
  }
  filter._and = filter_and;
  let leadMlovId = ''
  let supportingMember = ''
  let other = '';
  let patientTypeId = '';
  if (mlovData) {
    leadMlovId = getContactTypeIdInDefaultContactTypes(mlovData.defaultContactTypes?.customContactTypes, PERSON_TYPES.LEAD);
    supportingMember = getContactTypeIdInDefaultContactTypes(mlovData.defaultContactTypes?.customContactTypes, PERSON_TYPES.SUPPORTING_MEMBER);
    other = getContactTypeIdInDefaultContactTypes(mlovData.defaultContactTypes?.customContactTypes, PERSON_TYPES.OTHER);
    patientTypeId = getContactTypeIdInDefaultContactTypes(mlovData.defaultContactTypes?.customContactTypes, PERSON_TYPES.CUSTOMER);
  }
  if (formData?.selectedContactTypeIds?.length) {
    filter.contactType = { typeId: { _in: formData.selectedContactTypeIds}}
  } else if (formData?.selectedContactTypeId && !isEmptyString(formData.selectedContactTypeId)) {
    filter.contactType = { typeId: { _eq: formData.selectedContactTypeId } };
  } else if (!isEmptyString(contactTypeId)) {
    if (onlyShowLead) {
      filter.contactType = { typeId: { _eq: contactTypeId } };
    } else if (leadMlovId == contactTypeId) {
      filter.contactType = {
        _or: getContactTypeFilterForLead({ leadMlovId, supportingMember, other }),
      };
    } else {
      filter.contactType = { typeId: { _eq: contactTypeId } };
    }
  } else if (mlovData?.defaultContactTypes?.customContactTypes && mlovData.defaultContactTypes.customContactTypes.length > 0) {
    const customContactTypeIds = mlovData.defaultContactTypes.customContactTypes.filter((obj: any) => {
      if (obj.code) {
        return obj.code !== PERSON_TYPES.CUSTOMER;
      }
      return true
    }).map((obj: any) => {
      return obj.id
    })
    filter.contactType = { typeId: { _in: customContactTypeIds}}
  }
  if (
    selectedUserPracticeLocationsUuid?.length &&
    selectedUserPracticeLocationsUuid?.length > 0
  ) {
    const contactPracticeLocations: IContactPracticeLocations[] = [];
    selectedUserPracticeLocationsUuid.forEach((item: any) => {
      const locationUuidObj = {
        practiceLocationUuid: {
          _eq: item,
        },
      };
      contactPracticeLocations.push(locationUuidObj);
    });

    filter.contactPracticeLocations._or = contactPracticeLocations;
  } else {
    delete filter.contactPracticeLocations;
  }

  if (userLocationUuids && userLocationUuids?.length) {
    filter.contactPracticeLocations = {
      practiceLocationUuid: {
        _in: userLocationUuids,
      },
    };
  }

  // if (selectedUserPracticeLocations?.practiceLocation?.uuid) {
  //   if (selectedUserPracticeLocations?.practiceLocation?.uuid != 'All') {
  //     filter.contactPracticeLocations = {
  //       practiceLocationUuid: {
  //         _eq: selectedUserPracticeLocations?.practiceLocation?.uuid,
  //       },
  //     };
  //   } else {
  //     delete filter.contactPracticeLocations;
  //   }
  // }
  if (tagItem && tagItem.length > 0) {
    const tempFilterarray: any = [];
    tagItem?.forEach((item: any) => {
      const obj = {
        labelId: { _eq: item?.id },
        taggableType: { id: { _eq: item?.labelTypes[0]?.labelTypeId } },
      };
      tempFilterarray.push(obj);
    });
    filter.contactTagging = {
      _or: tempFilterarray,
    };
  } else {
    delete filter.contactTagging;
  }
  if (inboxItem && inboxItem.length > 0) {
    const tempFilterarray: any = [];
    inboxItem?.forEach((item: any) => {
      const obj = {
        inboxId: { _eq: item.channelId },
      };
      tempFilterarray.push(obj);
    });
    filter.contactInboxes = {
      _or: tempFilterarray,
    };
  } else {
    delete filter.contactInboxes;
  }
  // if (!(Object.keys(inboxItem).length === 0)) {
  //   filter.contactInboxes = {
  //     inboxId: {_eq: inboxItem.channelId},
  //   };
  // } else {
  // delete filter.contactInboxes;
  // }

  if (employerId && employerId.length > 0) {
    filter.employees = {
      employerId: {
        _eq: employerId,
      },
    };
  }
  if ( contactTypeId !== patientTypeId ) {
    delete filter.isActive;
  }
  return filter;
};
  export const getSearchContactsFilterObject = ({
    filter,
    searchString,
    tagItem,
    inboxItem,
    contactTypeId,
    selectedUserPracticeLocations,
    status,
    formData,
    employerId,
    mlovData,
    tab,
    loggedInStatus,
    onlyShowLead,
    sortFilter,
    careProgramStepTypeIds,
    careProgramTypeIds,
    careProgramStepStatusIds,
    ruleCodes,
    showContactsWithPendingAlerts,
    customFilterCode,
    carePlanStatusIds,
    isCarePlanNotAssigned,
    contactCreatedToDateTime,
    contactCreatedFromDateTime,
  }: {
    filter?: any;
    searchString?: string;
    tagItem?: any;
    inboxItem?: any;
    contactTypeId: any;
    selectedUserPracticeLocations?: IUserPracticeLocation[];
    status?: any;
    formData?: any;
    employerId?: string;
    mlovData?: ICommonData;
    tab?: string;
    loggedInStatus?: any;
    onlyShowLead?: boolean;
    sortFilter?: any;
    careProgramStepTypeIds?: (string | undefined)[];
    careProgramTypeIds?: (string | undefined)[];
    careProgramStepStatusIds?: (string | undefined)[];
    ruleCodes?: string[];
    showContactsWithPendingAlerts?: boolean;
    customFilterCode?: string;
    carePlanStatusIds?: string[];
    isCarePlanNotAssigned?: boolean;
    contactCreatedToDateTime?: string;
    contactCreatedFromDateTime?: string;
  }) => {
    const searchContactActionFilter: ISearchContactFilter =
      {} as ISearchContactFilter;
    const selectedUserPracticeLocationsUuid =
      selectedUserPracticeLocations?.map((location: any) => {
        return location?.uuid;
      });

    if (sortFilter?.contactData?.length) {
      if (sortFilter?.contactData[0]?.includes('name')) {
        searchContactActionFilter.orderBy = {
          name: sortFilter?.contactData[0]?.includes('asc')
            ? 'asc'
            : sortFilter?.contactData[0]?.includes('desc')
            ? 'desc'
            : '',
        };
      } else if (sortFilter?.contactData[0]?.includes('created')) {
        searchContactActionFilter.orderBy = {
          created_at: sortFilter?.contactData[0]?.includes('asc')
            ? 'asc'
            : sortFilter?.contactData[0]?.includes('desc')
            ? 'desc'
            : '',
        };
      } else if (sortFilter?.contactData[0]?.includes('updated')) {
        searchContactActionFilter.orderBy = {
          updated_at: sortFilter?.contactData[0]?.includes('asc')
            ? 'asc'
            : sortFilter?.contactData[0]?.includes('desc')
            ? 'desc'
            : '',
        };
      } else {
        searchContactActionFilter.orderBy = {
          updated_at: 'desc',
        };
      }
    } else {
      searchContactActionFilter.orderBy = {
        updated_at: 'desc',
      };
    }

    searchContactActionFilter.searchString = searchString;
    if (loggedInStatus) {
      if (loggedInStatus == 'NOT_LOGGED_IN') {
        searchContactActionFilter.isLoggedIn = false;
      } else if (loggedInStatus == 'LOGGED_IN') {
        searchContactActionFilter.isLoggedIn = true;
      } else {
        delete searchContactActionFilter.isLoggedIn;
      }
    } else {
      delete searchContactActionFilter.isLoggedIn;
    }

    if (status) {
      if (status == 'INACTIVE') {
        searchContactActionFilter.isActive = false;
      } else if (status == 'ACTIVE') {
        searchContactActionFilter.isActive = true;
      }
    } else {
      searchContactActionFilter.isActive = true;
    }
    if (formData?.toDate && formData?.fromDate) {
      searchContactActionFilter.contactCreatedFromDateTime = getStartOfDay(formData?.fromDate).toISOString();

      searchContactActionFilter.contactCreatedToDateTime = getEndOfDay(formData?.toDate).toISOString();
    } else {
      delete searchContactActionFilter.contactCreatedFromDateTime;
      delete searchContactActionFilter.contactCreatedToDateTime;
    }

    if (formData?.selectedEmployeeType?.length) {
      searchContactActionFilter.employeeTypes = formData?.selectedEmployeeType;
    } else {
      delete searchContactActionFilter.employeeTypes;
    }

    if (formData?.customAttributes?.length) {
      const customAttributesFilters: ICustomAttributeQueryObj[] = [];
      formData?.customAttributes?.forEach(
        (customAttribute: ICustomAttributesFilters) => {
          const customAttributeFilterObject =
            getCustomAttributeSearchContactFilterObject(customAttribute);
          if (customAttributeFilterObject?.customAttributeId) {
            const updtaedCustomAttributeFilterObject = formatCustomAttributeValueObject(customAttributeFilterObject)
            customAttributesFilters.push(updtaedCustomAttributeFilterObject);
          }
        }
      );
      searchContactActionFilter.customAttributesFilters =
        customAttributesFilters;
    } else {
      delete searchContactActionFilter.customAttributesFilters;
    }

    if (formData?.employerIds?.length > 0) {
      searchContactActionFilter.employerIds = formData?.employerIds;
    } else {
      delete searchContactActionFilter.employerIds;
    }

    if (
      (tab && tab === 'myPatient')
    ) {
      searchContactActionFilter.showOnlyMyPatient = true;
    } else {
      delete searchContactActionFilter.showOnlyMyPatient;
    }
    let leadMlovId = '';
    let supportingMember = '';
    let other = '';
    let patientTypeId = '';
    if (mlovData) {
      leadMlovId = getContactTypeIdInDefaultContactTypes(
        mlovData.defaultContactTypes?.customContactTypes,
        PERSON_TYPES.LEAD
      );
      supportingMember = getContactTypeIdInDefaultContactTypes(
        mlovData.defaultContactTypes?.customContactTypes,
        PERSON_TYPES.SUPPORTING_MEMBER
      );
      other = getContactTypeIdInDefaultContactTypes(
        mlovData.defaultContactTypes?.customContactTypes,
        PERSON_TYPES.OTHER
      );
      patientTypeId = getContactTypeIdInDefaultContactTypes(
        mlovData.defaultContactTypes?.customContactTypes,
        PERSON_TYPES.CUSTOMER
      );
    }
    if (formData?.selectedContactTypeIds?.length) {
      searchContactActionFilter.contactTypeIds = formData.selectedContactTypeIds;
    } else if (
      formData?.selectedContactTypeId &&
      !isEmptyString(formData.selectedContactTypeId)
    ) {
      searchContactActionFilter.contactTypeIds = [
        formData.selectedContactTypeId,
      ];
    } else if (!isEmptyString(contactTypeId)) {
      if (onlyShowLead) {
        searchContactActionFilter.contactTypeIds = [contactTypeId];
      } else if (leadMlovId == contactTypeId) {
        searchContactActionFilter.contactTypeIds = [
          leadMlovId,
          supportingMember,
          other,
        ];
      } else {
        searchContactActionFilter.contactTypeIds = [contactTypeId];
      }
    } else if (
      mlovData?.defaultContactTypes?.customContactTypes &&
      mlovData.defaultContactTypes.customContactTypes.length > 0
    ) {
      if (isWeb()) {
        const customContactTypeIds =
          mlovData.defaultContactTypes.customContactTypes
            .filter((obj: any) => {
              if (obj.code) {
                return obj.code !== PERSON_TYPES.CUSTOMER;
              }
              return true;
            })
            .map((obj: any) => {
              return obj.id;
            });
        searchContactActionFilter.contactTypeIds = [customContactTypeIds];
      } else {
        const customContactTypeIds =
          mlovData.defaultContactTypes.customContactTypes
            .find((obj: any) => {
              if (obj.code) {
                return obj.code == PERSON_TYPES.CUSTOMER;
              }
              return true;
            }).id;
        searchContactActionFilter.contactTypeIdsNotIn = [customContactTypeIds];
      }
    }
    if (
      selectedUserPracticeLocationsUuid?.length &&
      selectedUserPracticeLocationsUuid?.length > 0
    ) {
      const accountLocationIds: string[] = [];
      selectedUserPracticeLocationsUuid.forEach((item: any) => {
        accountLocationIds?.push(item);
      });

      searchContactActionFilter.accountLocationIds = accountLocationIds;
    } else {
      delete searchContactActionFilter.accountLocationIds;
    }

    if (tagItem && tagItem.length > 0) {
      const tags: {
        labelTypeId: string;
        id: string;
      }[] = [];
      tagItem?.forEach((item: any) => {
        tags.push({
          id: item?.id,
          labelTypeId: item?.labelTypes[0]?.labelTypeId,
        });
      });
      searchContactActionFilter.tags = tags;
    } else {
      delete searchContactActionFilter.tags;
    }
    if (inboxItem && inboxItem.length > 0) {
      const inboxIds: string[] = [];
      inboxItem?.forEach((item: any) => {
        inboxIds.push(item.channelId);
      });
      searchContactActionFilter.inboxIds = inboxIds;
    } else {
      delete searchContactActionFilter.inboxIds;
    }

    if (employerId && employerId.length > 0) {
      searchContactActionFilter.employerIds = [employerId];
    }
    if (contactTypeId !== patientTypeId) {
      delete searchContactActionFilter.isActive;
    }
    if (careProgramStepTypeIds?.length) {
      searchContactActionFilter.careProgramStepTypeIds = careProgramStepTypeIds;
    }
    if(careProgramTypeIds?.length) {
      searchContactActionFilter.careProgramTypeIds = careProgramTypeIds;
    }
    if(careProgramStepStatusIds?.length) {
      searchContactActionFilter.careProgramStepStatusIds = careProgramStepStatusIds;
    }
    if(ruleCodes?.length) {
      searchContactActionFilter.ruleCodes = ruleCodes;
    }
    if(showContactsWithPendingAlerts) {
      searchContactActionFilter.showContactsWithPendingAlerts = true;
    }
    if(customFilterCode?.length) {
      searchContactActionFilter.customFilterCode = customFilterCode;
    }
    if (carePlanStatusIds?.length) {
      searchContactActionFilter.carePlanStatusIds = carePlanStatusIds;
    }
    if (isCarePlanNotAssigned) {
      searchContactActionFilter.isCarePlanNotAssigned = true;
    }
    if(contactCreatedToDateTime?.length) {
      searchContactActionFilter.contactCreatedToDateTime = contactCreatedToDateTime;
    }
    if(contactCreatedFromDateTime?.length) {
      searchContactActionFilter.contactCreatedFromDateTime = contactCreatedFromDateTime;
    }
    return searchContactActionFilter;
  };



export const getSupportingMemberFiltersObject = (
  filter: any,
  searchString: string,
  account_id: number | string,
  tagItem: any,
  inboxItem: any
  // contactTypeId: any
) => {
  // delete filter.realatedContact.contactTagging;
  delete filter._or;
  filter._or = {} as any;
  if (searchString) {
    filter._or = [
      { realatedContact: { name: { _ilike: '%' + searchString + '%' } } },
      { realatedContact: { email: { _ilike: '%' + searchString + '%' } } },
      { realatedContact: { phoneNumber: { _ilike: '%' + searchString + '%' } } },
    ];
  }

  filter.realatedContact = { email: { _is_null: false } };
  // filter.phoneNumber = {_is_null: false};
  filter.realatedContact = { accountId: { _eq: account_id } };

  // const leadMlovId = getContactTypeId('LEAD');
  // if (!isEmptyString(contactTypeId)) {
  //   filter.contactType = {typeId: {_eq: contactTypeId}};
  // }
  if (tagItem && tagItem.length > 0) {
    const tempFilterarray: any = [];
    tagItem?.forEach((item: any) => {
      const obj = {
        labelId: { _eq: item?.id },
        taggableType: { id: { _eq: item?.labelTypes[0]?.labelTypeId } },
      };
      tempFilterarray.push(obj);
    });
    filter.realatedContact.contactTagging = {
      _or: tempFilterarray,
    };
  } else {
    delete filter.realatedContact.contactTagging;
  }
  if (false) {
    if (inboxItem && inboxItem.length > 0) {
      const tempFilterarray: any = [];
      inboxItem?.forEach((item: any) => {
        const obj = {
          inboxId: { _eq: item.channelId },
        };
        tempFilterarray.push(obj);
      });
      filter.contactInboxes = {
        _or: tempFilterarray,
      };
    } else {
      delete filter.contactInboxes;
    }
  }

  filter.realatedContact.id = { _is_null: false };
  return filter;
};
export interface IQuickAction {
  displayString: string;
  actionCode: string;
}

export const checkMandatoryFields = (
  formattedData: any,
  contactType: string,
  otherChecks?: {
    isGenderAtBirthRequired: boolean;
    isPractitionerRequired: boolean;
    isAddressLine1Required: boolean;
    isMobileNumberRequired: boolean;
    checkVitalsForLessThan18Age: boolean;
    isPhoneOrEmailRequired: any;
    isLocationRequired?: boolean;
  }
): boolean => {
  let result;
  switch (contactType) {
    case PERSON_TYPES.LEAD:
      result =
        formattedData.firstName &&
        formattedData.lastName &&
        (formattedData.email || formattedData.phone);
      break;
    case PERSON_TYPES.CUSTOMER:
      result =
        formattedData.firstName &&
        formattedData.lastName &&
        (!otherChecks?.isPhoneOrEmailRequired || formattedData.email || formattedData.phone) &&
        formattedData.birthDate &&
        formattedData.zipCodeId;
      if (otherChecks?.isAddressLine1Required) {
        result = result && !!formattedData.line1;
      }
      if (otherChecks?.isMobileNumberRequired) {
        result = result && !!formattedData.phone;
      }
      if (otherChecks?.checkVitalsForLessThan18Age && checkIfAgeIsLessThan18(formattedData?.birthDate)) {
        result = result && (!formattedData?.vitalObservations || formattedData?.vitalObservations?.length < 2);
      }
      // formattedData.line1 &&
      // formattedData.genderId; Commenting genderId as it's not mandatory anymore.
      break;
  }

  let additionalCondition = otherChecks?.isGenderAtBirthRequired
    ? formattedData.sexAtBirth && result
    : result;

  additionalCondition = otherChecks?.isPractitionerRequired
    ? formattedData.practitionerId && additionalCondition
    : additionalCondition;

  additionalCondition = otherChecks?.isLocationRequired
    ? formattedData.locationUuid && additionalCondition
    : additionalCondition;

  return additionalCondition;
};

export const getMissingFields = (
  formattedData: any,
  otherChecks?: {
    isGenderAtBirthRequired: boolean;
    isPractitionerRequired: boolean;
    isAddressLine1Required: boolean;
    isMobileNumberRequired: boolean;
    checkVitalsForLessThan18Age: boolean;
    genderAtBirthLabel: string;
    genderIdentityLabel: string;
    isPhoneOrEmailRequired: any;
    isLocationRequired?: boolean;
  }
) => {
  const missingFields: string[] = [];
  const missingOptions: string[] = [];
  const messageAdd = 'Please add ';
  const messageSelect = 'Please select '
  // if (!formattedData.firstName) {
  //   missingFields.push('First Name');
  // }
  if (!formattedData.firstName) {
    missingFields.push('First Name');
  }
  if (!formattedData.lastName) {
    missingFields.push('Last Name');
  }
  if (otherChecks?.isPhoneOrEmailRequired && !formattedData.email && !formattedData.phone) {
    missingFields.push('Email or Mobile number');
  }
  // if (!formattedData.phone) {
  //   missingFields.push('Phone Number');
  // }
  if (!formattedData.birthDate) {
    missingOptions.push('Date of Birth');
  }
  if (!formattedData.zipCode) {
    missingFields.push('Zip Code');
  }
  if (otherChecks?.checkVitalsForLessThan18Age && checkIfAgeIsLessThan18(formattedData?.birthDate) &&
    (!formattedData?.vitalObservations || formattedData?.vitalObservations?.length < 2)) {
    const heightAlreadyAdded = formattedData?.vitalObservations?.find((item: any) => item.code?.coding?.[0]?.code == Vital.height || item.loinc == Vital.height);
    const weightAlreadyAdded = formattedData?.vitalObservations?.find((item: any) => item.code?.coding?.[0]?.code == Vital.weight || item.loinc == Vital.weight);
    if (heightAlreadyAdded && !weightAlreadyAdded) {
      missingFields.push('Weight');
    } else if (!heightAlreadyAdded && weightAlreadyAdded) {
      missingFields.push('Height');
    } else if (!heightAlreadyAdded && !weightAlreadyAdded) {
      missingFields.push('Height, Weight');
    }
  }
  // Commenting this as gender is not mandatory
  // if (!formattedData.genderName) {
  //   missingFields.push(otherChecks?.genderIdentityLabel || EHR_CAPABILITY_CONST.DEFAULT_GENDER_IDENTITY_LABEL);
  // }

  // if (!formattedData.line1) {
  //   missingFields.push('Address');
  // }
  if (otherChecks?.isAddressLine1Required && !formattedData.line1) {
    missingFields.push('Address Line 1');
  }

  if (otherChecks?.isMobileNumberRequired && !formattedData.phone) {
    missingFields.push('Phone Number');
  }

  if (otherChecks?.isGenderAtBirthRequired && !formattedData?.sexAtBirth) {
    missingFields.push(
      otherChecks?.genderAtBirthLabel ||
      EHR_CAPABILITY_CONST.DEFAULT_BIRTH_SEX_LABEL
    );
  }

  if (otherChecks?.isPractitionerRequired && !formattedData?.practitionerId) {
    missingOptions.push('Practitioner');
  }
  if (otherChecks?.isPractitionerRequired && !formattedData?.locationUuid) {
    missingOptions.push('Location');
  }

  let finalMessage = "";
  if (missingFields.length) {
    finalMessage += messageAdd + missingFields.join(', ');
  }
  if (missingOptions.length) {
    if (missingFields.length) {
      finalMessage += ", ";
    }
    finalMessage += messageSelect + missingOptions.join(', ');
  }

  return {
    missingFields,
    message: finalMessage,
  };
};

export const getConvertContactFormattedData = (listData: any): any[] => {
  const tempFormatedList: any[] = [];
  listData?.map((item: any) => {
    let prefix = '';
    if (item.name[0].prefix) {
      prefix = item.name[0]?.prefix[0];
    } else {
      prefix = '';
    }
    const birthDate = item?.birthDate;
    const id = item?.id;
    const gender = item?.gender;
    const given = item?.name[0]?.given[0] ? item?.name[0]?.given[0] : '';
    const family = item?.name[0]?.family;
    const fullName = `${prefix} ${given} ${family} `;
    const city =
      item?.address && item?.address[0] ? item?.address[0]?.city : '';
    const state =
      item?.address && item?.address[0] ? item?.address[0]?.state : '';
    const postalCode = (item?.address && item?.address[0]?.postalCode) || '';
    const tempObj = {
      id: id,
      birthDate: birthDate,
      gender: gender,
      city: city,
      state: state,
      postalCode: postalCode,
      fullName: fullName,
      family: family,
    };
    tempFormatedList.push(tempObj);
  });

  return tempFormatedList;
};

export const getModalTitleByPersonType = (
  personType: string | undefined
): string => {
  let modalTitle = '';

  switch (personType) {
    case PERSON_TYPES.CUSTOMER:
      modalTitle = 'convertToPatient';
      break;
    case PERSON_TYPES.LEAD:
      modalTitle = 'convertToLead';
      break;
  }

  return modalTitle;
};

export const getConfirmationMessageByAction = (actionType: string) => {
  let message = '';
  let modalTitle = '';
  switch (actionType) {
    case CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER:
      message = 'confirmTextForCustomer';
      modalTitle = 'convertToPatient';
      break;

    case CONTACT_ACTION_CODES.CONVERT_TO_LEAD:
      message = 'confirmTextForLead';
      modalTitle = 'convertToPatient';

      break;

    case CONTACT_ACTION_CODES.CREATE_PATIENT:
      message = 'confirmTextForCreatePatient';
      modalTitle = 'createPatient';
      break;
  }
  return {
    message,
    modalTitle,
  };
};

export const getContactTypeFilterForLead = (args: {
  leadMlovId?: string;
  supportingMember?: string;
  other?: string;
}) => {
  const { leadMlovId, other, supportingMember } = args;
  const _or: any[] = [];
  if (leadMlovId) {
    _or.push({
      typeId: {
        _eq: leadMlovId,
      },
    });
  }
  if (other) {
    _or.push({
      typeId: {
        _eq: other,
      },
    });
  }
  if (supportingMember) {
    _or.push({
      typeId: {
        _eq: supportingMember,
      },
    });
  }
  return _or;
};

const createCustomAttributesFilter = (queryObj: ICustomAttributeQueryObj) => {
  const {customAttributeId, customAttributeValue, fieldName, condition, range} = queryObj
  const customAttributesFilter = {
    customAttributeValues: {} as CustomAttributeValues<{ [key: string]: string | number | boolean }>
  };
  const isRequiredDataPresent = customAttributeId && fieldName && condition && (customAttributeValue || range?.to || range?.from);
  if (isRequiredDataPresent) {
    customAttributesFilter.customAttributeValues.customAttributeId = { _eq: customAttributeId };
    if (range?.from || range?.to) {
      customAttributesFilter.customAttributeValues[fieldName] = {
        ...(range?.from !== undefined && { _gte: range?.from.trim() }),
        ...(range?.to !== undefined && { _lte: range?.to.trim() }),
      };
    } else {
      if (condition === CUSTOM_ATTRIBUTE_FILTERS_CONDITION.LIKE || condition === CUSTOM_ATTRIBUTE_FILTERS_CONDITION.NOT_LIKE) {
        customAttributesFilter.customAttributeValues[fieldName] = { [condition]: `%${customAttributeValue.trim()}%` };
      } else {
        customAttributesFilter.customAttributeValues[fieldName] = { [condition]: customAttributeValue.trim() };
      }
    }
  }
  return customAttributesFilter;
}

const getCustomAttributeFilterObject = (customAttribute: ICustomAttributesFilters) => {
  const queryObj: ICustomAttributeQueryObj = {
    customAttributeId: customAttribute?.customAttributeId,
    customAttributeValue: customAttribute?.value,
    fieldName: customAttribute?.fieldName,
    condition: customAttribute?.condition,
    range: customAttribute?.range
  }
  const customAttributesFilter = createCustomAttributesFilter(queryObj);
  return customAttributesFilter;
}
const getCustomAttributeSearchContactFilterObject = (customAttribute: ICustomAttributesFilters) => {
  const queryObj: ICustomAttributeQueryObj = {
    customAttributeId: customAttribute?.customAttributeId,
    customAttributeValue: customAttribute?.value,
    fieldName: customAttribute?.fieldName,
    condition: replaceFilterCondition(customAttribute?.condition),
    range: customAttribute?.range
  }
  return queryObj;
}

export const trimSpace = (customAttributes: ICustomAttributesFilters[]) => {
  const filterData = customAttributes?.map((customAttribute) => {
    const {range} = customAttribute;
    if (range?.from || range?.to) {
      return {
        ...customAttribute,
        range: {
          ...range,
          from: customAttribute?.range?.from.trim(),
          to: customAttribute?.range?.to.trim(),
        }
      }
    } else {
      return {
        ...customAttribute,
        value: customAttribute?.value.trim(),
      }
    }
  })
  return filterData;
}

export const isAffiliatePatient = (
  additionalAttributes: Record<string, any>
) => {
  return additionalAttributes?.isAffiliatePatient || false;
};
export const getActionEventAndResourceCodeByOption = (actionName: string)=> {
 if ([PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT].includes(actionName)) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.CALENDAR,
    }
  } else if ([PERSON_ACTION_CODES.SEND_FORMS,MIDDLE_CONTAINER_TABS.FORM_RESPONSES].includes(actionName)) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.FORMS,
    }
  } else if (PERSON_ACTION_CODES.CREATE_TASK == actionName) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.TASKS,
    }
  } else if (PERSON_ACTION_CODES.CREATE_AUTOMATION == actionName) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.AUTOMATION,
    }
  } else if ([MAIN_MENU_CODES.CALL_LOGS].includes(actionName)) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.CALL_LOGS,
    }
  } else if ([MIDDLE_CONTAINER_TABS.JOURNEYS].includes(actionName)) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.CARE_JOURNEY,
    }
  }
}


export const formatCustomAttributeValueObject = (attributes: ICustomAttributeQueryObj)=> {
  let updatedValue = attributes.customAttributeValue as any
  let range = attributes.range
  switch (attributes.fieldName) {
    case CUSTOM_ATTRIBUTE_VALUE_COLUMN.BOOLEAN_VALUE:
      updatedValue = normalizeBoolean(attributes.customAttributeValue);
      break;
    case CUSTOM_ATTRIBUTE_VALUE_COLUMN.NUMBER_VALUE:
      updatedValue = normalizeNumber(updatedValue)
      break;
    case CUSTOM_ATTRIBUTE_VALUE_COLUMN.DATE_VALUE:
      const normalizeDateObj  = normalizeDate(updatedValue,attributes.range)
      updatedValue = normalizeDateObj.value
      range = normalizeDateObj.range as ICustomAttributesFilterRange
      break;
  }
  return {...attributes, customAttributeValue: updatedValue, range: range}
}
