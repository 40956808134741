import {Badge, Flex, HStack, Skeleton, Stack, Text, View, VStack} from 'native-base';
import {FlatList, Pressable} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../../styles';
import {getPatientLocationUuidFromPracticeLocations, hasValue, numericStringMask} from '../../../../../../../utils/commonUtils';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {Email} from '../../../../../../common/EmailAndPhone/Email';
import {PhoneNumber} from '../../../../../../common/EmailAndPhone/PhoneNumber';
import MemberInfoListItem from '../../../../../../common/MemberInfoListItem/MemberInfoListItem';
import {TagDataListView} from '../../../../../../PersonOmniView/LeftContainer/OtherDetails/TagDataListView';
import {capitalizeFirstLatter} from '../../../../../ContentManagement/CampaignManagement/Helper';
import {ActionsView} from '../ActionsView';
import {MemberAutomation} from '../MemberAutomation';
import {MemberCampaign} from '../MemberCampaign';
import {MemberCareJourney} from '../MemberCareJourney';
import {MemberGroups} from '../MemberGroups';
import Feather from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';
import {IPatientCareJourney} from '../../../interfaces';
import {
  ADDITIONAL_ATTRIBUTE_KEY,
  CONTACT_TABLE_COLUMN_CODE,
  MAIN_TAB_CODE,
  PROGRAM_FILTER_CODE,
  PROGRAM_STEPS_FILTER_CODE,
  PROGRAM_SUB_FILTER_CODE,
} from '../../../../../../common/MemebersView/constant';
import {AttributesView} from '../../../../../../PersonOmniView/LeftContainer/OtherDetails/AttributesView';
import {ICondition} from '../../../../../Forms/FHFormio/CustomComponents/Conditions/interfaces';
import {IContactCareProgramResponse, IDashboardColumnProps} from '../../../../../../../services/ContactCareProgram/interface';
import {CareProgramStepWiseView} from '../../../../../../common/MemebersView/CareProgramStepWiseView';
import { getAttributeByKey, getDayLapse, getProgramEnrollmentDateAndStatus } from '../../../../../../common/CareProgramPanel/CareProgramUtils';
import { CARE_TEAM_MEMBER_TYPE } from '../../../../../../../constants/MlovConst';
import { MemberHealthPlanView } from '../../../../../../common/MemebersView/MemberHealthPlanView';
import { MemberAWVStatusView } from '../../../../../../common/MemebersView/MemberAWVStatusView';
import { getDateStrFromFormat, getDiffInMonths, getMomentObj } from '../../../../../../../utils/DateUtils';
import { DATE_FORMATS, SCORE_TYPE, SORT_ORDER } from '../../../../../../../constants/StringConst';
import PatientListInternalTask from '../../../../../../common/PatientListInternalTask/PatientListInternalTask';
import SortIcon from '../../../../../../common/Svg/SortIcon';

import {isCallsAllowed} from '../../../../../UserAccess/UserAccessPermission';
import { ActionableTab } from '../../../../../../common/PatientListInternalTask/InternalTaskDrawer';
import { Popover } from 'antd';
import ContactPopoverContent from '../../../../../../common/MemebersView/ContactPopoverContent';
import { CareManagerAssignee } from '../../../../../../PersonOmniView/RightContainer/CareTeamView/CareManagerAssignee';
import CarePlanGoalProgressBar from '../../../../../../common/CarePlanGoalProgress/CarePlanGoalProgressBar';
import { getPatientCondition } from '../../../../../../../services/CommonService/AidBoxService';
import { RAFScoreElement } from '../../../../../../../RAFScoreElement';
import '../../../../../../common/CustomFilterView/OneViewFilter/popoverStyle.css'
import { CCM_COLUMN_CODES, MY_PATIENT_COLUMN_CODES, TCM_COLUMN_CODES } from './CareProgramConst';
import { IGridColumn } from '../../../../../../common/MemebersView/interface';
import CareProgramComplexity from '../../../../../../common/CareProgram/CareProgramComplexity';
import CareProgramStatus from '../../../../../../common/CareProgram/CareProgramStatus';
import DayLapseView from '../../../../../../common/CareProgram/DayLapseView';
import DiagnosisGap from '../../../../../../DiagnosisGap';
import { StyleSheet } from 'react-native';

export const MembershipColumn: any = {
  title: (
    <DisplayText
      size={'thMedium'}
      extraStyles={{color: Colors.Custom.GRAY350 || ''}}
      textLocalId="membership"
      />
  ),

  dataIndex: 'subscriptionStatus',
  key: 'subscriptionStatus',
  width: 200,
  render: (text: any): JSX.Element => {
    const bgColor =
      text.status == 'ACTIVE'
        ? Colors.success['100']
        : text.status == 'IN_ACTIVE'
        ? Colors.info['100']
        : Colors.danger['100'];
    const borderColor =
      text.status == 'ACTIVE'
        ? Colors.success['200']
        : text.status == 'IN_ACTIVE'
        ? Colors.info['200']
        : Colors.danger['200'];
    const textColor =
      text.status == 'ACTIVE'
        ? Colors.success['500']
        : text.status == 'IN_ACTIVE'
        ? Colors.info['400']
        : Colors.error['600'];
    return (
      <>
        {text?.loading ? (
          <Skeleton.Text lines={2}></Skeleton.Text>
        ) : (
          <View
            style={{
              width: 'max-content',
              paddingHorizontal: 8,
              paddingVertical: 3,
              borderRadius: 6,
              backgroundColor: bgColor,
              borderWidth: 1,
              borderColor: borderColor,
            }}>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text size={'smRegular'} color={textColor}>
                {text.label}
              </Text>
            )}
          </View>
        )}
      </>
    );
  },
};

export const hintMembershipColumn: any = {
  title: (
    <DisplayText
      size={'thMedium'}
      extraStyles={{color: Colors.Custom.GRAY350 || ''}}
      textLocalId="membershipStatus"
      />
  ),
  dataIndex: 'hintMembershipStatus',
  key: 'hintMembershipStatus',
  width: 240,
  render: (text: any): JSX.Element => {
    return <Text>{capitalizeFirstLatter(text) || '-'}</Text>;
  },
  responsive: ['md'],
};

export const getLeadTableViewColumns = (
  sortedInfo: any,
  onActionPerformed: (actionCode: string, rowData: any) => void,
  contactType?: string,
  canShowMembership?: boolean,
  isHideAction?: boolean,
  hideConvertToPatient?: boolean,
  showHintmembership?: boolean,
  showSelectedText?: boolean,
  selectedCount?: number,
  careJourneyData?: IPatientCareJourney,
  inboxData?: any,
): any[] => {
  const intl = useIntl();

  let headerTitleText =
    contactType == 'CUSTOMER'
      ? intl.formatMessage({id: 'patientName'})
      : 'Prospect name';

  if (showSelectedText && selectedCount) {
    headerTitleText = headerTitleText + ` (${selectedCount} selected)`;
  }

  const primaryColumns = [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || '', marginLeft: 8}}
            textLocalId={headerTitleText}
          />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      width: 310,
      fixed: 'left',
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'contactData' && sortedInfo.order,
      filterMultiple: false,
      filters: isHideAction
        ? false
        : [
            {
              text: 'Sort by',
            },
            {
              text: 'Name',
              value: 'name',
              children: [
                {
                  text: 'Ascending',
                  value: 'asc_name',
                },
                {
                  text: 'Descending',
                  value: 'desc_name',
                },
              ],
            },
            {
              text: 'Created date',
              value: 'created_at',
              children: [
                {
                  text: 'Ascending',
                  value: 'asc_created',
                },
                {
                  text: 'Descending',
                  value: 'desc_created',
                },
              ],
            },
            {
              text: 'Update date',
              value: 'updated_at',
              children: [
                {
                  text: 'Ascending',
                  value: 'asc_updated',
                },
                {
                  text: 'Descending',
                  value: 'desc_updated',
                },
              ],
            },
          ],
      ellipsis: true,
      render: (text: any, selectedRecord: any): JSX.Element => {
        return (
          <MemberInfoListItem
            onActionPerformed={(actionCode, rowData) => {
              onActionPerformed(actionCode, {
                ...selectedRecord,
              });
            }}
            contactData={text}
            showEmail={false}
            showDateOfBirth={true}
          />
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="contactInfo"
        />
      ),
      dataIndex: 'singleLeadData',
      key: 'mobileNumber',
      width: 250,
      // sorter: (nameOne: any, nameTow: any) => {
      //   return nameOne.name.localeCompare(nameTow.name);
      // },
      // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      // ellipsis: true,
      render: (singleLeadData: any, selectedRecord: any): JSX.Element => {
        const mask = `(###) ###-####`;
        const maskedPhoneNumber = numericStringMask(
          singleLeadData.phoneNumber,
          mask,
        );

        const email = singleLeadData?.email || '';
        return (
          <VStack>
            <View>
              {singleLeadData.phoneNumber === '' ||
              singleLeadData.phoneNumber === null ? (
                <Text color={'gray.400'}>--</Text>
              ) : (
                <Pressable>
                  <PhoneNumber
                    phoneNumber={maskedPhoneNumber}
                    contactData={singleLeadData}
                    onActionPerformed={actionCode => {
                      if (!selectedRecord.consentLoading) {
                        onActionPerformed(actionCode, {
                          ...selectedRecord,
                          showConsentError: true,
                        });
                      }
                    }}
                    inboxData={inboxData}
                  />
                </Pressable>
              )}
            </View>
            <View>
              {email === '' || email === null ? (
                <Text color={'gray.400'}>--</Text>
              ) : (
                <Pressable>
                  <Email
                    contactData={singleLeadData}
                    onActionPerformed={actionCode => {
                      if (!selectedRecord.consentLoading) {
                        onActionPerformed(actionCode, {
                          ...selectedRecord,
                          showConsentError: true,
                        });
                      }
                    }}
                  />
                </Pressable>
              )}
            </View>
          </VStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="tags"
        />
      ),
      dataIndex: 'tags',
      key: 'tags',
      width: 240,
      render: (text: any): JSX.Element => {
        return (
          <TagDataListView
            taggableType={'Contact'}
            style={{marginBottom: 10}}
            hideAddMoreButton={true}
            tagList={text}
          />
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="attributes"
          />
      ),
      dataIndex: 'customAttributes',
      key: 'customAttributes',
      width: 250,
      render: (attributes: any): JSX.Element => {
        return <AttributesView attributes={attributes} />;
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="groups"
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      width: 150,
      render: (contactData: any, selectedRecord: any): JSX.Element => {
        return selectedRecord?.consentLoading ? (
          <Skeleton.Text lines={1} />
        ) : (
          <MemberGroups contactData={contactData} />
        );
      },
      responsive: ['md'],
    },

    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="careJourney"
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      width: 150,
      render: (contactData: any): JSX.Element => {
        return (
          <MemberCareJourney contactData={contactData} data={careJourneyData} />
        );
      },
      responsive: ['md'],
      hidden: contactType == 'CUSTOMER' ? false : true,
    },

    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="campaigns"
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      width: 150,
      render: (contactData: any): JSX.Element => {
        return <MemberCampaign contactData={contactData} />;
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="automation"
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      width: 200,
      render: (contactData: any): JSX.Element => {
        return <MemberAutomation contactData={contactData} />;
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="location"
        />
      ),
      dataIndex: 'location',
      key: 'location',
      width: 150,
      render: (text: any): JSX.Element => {
        const result = text?.length > 0 ? text[0].practiceLocation?.name : '--';
        return (
          <View>
            <>
              <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
                {result}
              </Text>
            </>
          </View>
        );
      },
      responsive: ['md'],
      hidden: contactType == 'CUSTOMER' ? false : true,
    },
    {
      title: isHideAction ? (
        <></>
      ) : (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || '' }}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: 200,
      fixed: 'right',
      onCell: () => {
        return {
          onClick: (e: Event) => {
            e.stopPropagation();
          },
        };
      },
      render: (text: any, selectedRecord: any) => {
        return (
          <>
            {isHideAction ? (
              <></>
            ) : (
              <>
                {selectedRecord?.consentLoading ? (
                  <Skeleton.Text lines={1}></Skeleton.Text>
                ) : (
                  <ActionsView
                    hideConvertToPatient={hideConvertToPatient}
                    record={selectedRecord}
                    onActionClick={(actionCode: string, data: any): any => {
                      // added hardoded true for now :: ABHAY
                      if (!text.consentLoading || true) {
                        if (data === COMMON_ACTION_CODES.CONSENT_WARNING) {
                          onActionPerformed(actionCode, {
                            ...selectedRecord,
                            showConsentError: true,
                          });
                        } else {
                          onActionPerformed(actionCode, selectedRecord);
                        }
                      }
                    }}
                  />
                )}
              </>
            )}
          </>
        );
      },
      responsive: ['md'],
    },
  ].filter(item => !item.hidden);
  if (canShowMembership) {
    primaryColumns.splice(1, 0, MembershipColumn);
  }
  if (showHintmembership) {
    primaryColumns.splice(2, 0, hintMembershipColumn);
  }
  return primaryColumns;
};

const getAttributeByKeyByProgram = (attributeKey: string, contactCareProgram: IContactCareProgramResponse) => {
  let value = getAttributeByKey(attributeKey, contactCareProgram);
  if (value && (attributeKey == ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE || attributeKey == ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE)) {
    value = getDateStrFromFormat(value, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT);
  }
  return value || '-';
}

export const renderAdditionalAttributeWithDetail = (attributeKey: string, selectedRecord: any): JSX.Element => {
  if (selectedRecord?.additionalDataLoading?.careProgramLoading) {
    return (<Skeleton.Text lines={1}></Skeleton.Text>);
  }
  if (!selectedRecord?.additionalDataLoading?.careProgramLoading && !selectedRecord?.contactCarePrograms?.length) {
    return (<></>);
  }
  const contactCarePrograms: IContactCareProgramResponse[] = selectedRecord?.contactCarePrograms || [];
  return (
    <>
      <Stack direction={'column'}>
        {(contactCarePrograms || []).map((contactCareProgram, index) => {
          return (
            <Stack direction={'row'} key={`${contactCareProgram?.id}_${index}_${attributeKey}`}>
                <Text
                  wordBreak={'break-word'}
                  color={Colors.Custom.Gray500}
                  size={'smLight'}
                  >
                  {getAttributeByKeyByProgram(attributeKey, contactCareProgram)}
                </Text>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
}

export const renderContactTagsColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const headerTitleText = columnData?.displayName || 'tags';
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId={headerTitleText}
      />
    ),
    key: 'tags',
    width: 240,
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.tagLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      return (
        <TagDataListView
          taggableType={'Contact'}
          style={{marginBottom: 10}}
          hideAddMoreButton={true}
          tagList={selectedRecord?.tags}
        />
      );
    },
    responsive: ['md'],
  };
};

export const renderInternalTaskColumn = (columnParams: IDashboardColumnProps, alertDisplayCategoryId?: string) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="Pending Actions"
      />
    ),
    key: 'patientInternalTask',
    width: 250,
    render: (selectedRecord: any): JSX.Element => (
      <PatientListInternalTask
        contactId={selectedRecord?.contactData?.uuid}
        onActionableClick={(actionableTab: ActionableTab) => {
          if (
            columnParams?.onActionPerformed &&
            typeof columnParams.onActionPerformed === 'function'
          ) {
            const actionCode = actionableTab === ActionableTab.TASKS
              ? COMMON_ACTION_CODES.ACTIONABLE_TASK_VIEW
              : COMMON_ACTION_CODES.ACTIONABLE_ALERT_VIEW;
            columnParams?.onActionPerformed(
              actionCode,
              {
                ...selectedRecord,
              }
            );
          }
        }}
        alertDisplayCategoryId={alertDisplayCategoryId || ''}
      />
    ),
    responsive: ['md'],
  };
}

export const renderMemberNameColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const order = columnParams?.name === SORT_ORDER.ASC ? SORT_ORDER.DESC  : SORT_ORDER.ASC;
  let headerTitleText = columnData?.displayName || 'Member Name'
  if (!!columnParams?.selectedItemsCount) {
    headerTitleText =
      headerTitleText + ` (${columnParams?.selectedItemsCount} selected)`;
  }
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
          <Pressable onPress={() => columnParams?.sortName(order)}>
            <SortIcon sortMode={order} />
          </Pressable>
      </Stack>
    ),
    dataIndex: 'contactData',
    key: 'contactData',
    fixed: 'left',
    sorter: false,
    width: 310,
    filterMultiple: false,
    ellipsis: true,
    render: (text: any, selectedRecord: any): JSX.Element => {
      return (
        <Popover
          overlayClassName='contact-popover'
          placement="bottomLeft"
          content={<ContactPopoverContent selectedRecord={selectedRecord} />}
          showArrow={false}
          overlayInnerStyle={{
            borderRadius: 8,
            borderWidth: 0.5,
            borderColor: Colors.FoldPixel.GRAY150,
            marginTop: -24,
          }}
          trigger="hover"
          mouseEnterDelay={1}
          mouseLeaveDelay={0.3}
          align={{ offset: [60, -50] }}
        >
          <View>
            <MemberInfoListItem
              onActionPerformed={(actionCode, rowData) => {
                if (
                  columnParams?.onActionPerformed &&
                  typeof columnParams.onActionPerformed == 'function'
                ) {
                  columnParams.onActionPerformed(actionCode, {
                    ...selectedRecord,
                  });
                }
              }}
              isMemberNameColumn={true}
              contactData={text}
              showEmail={false}
              showDateOfBirth={true}
              hideFullNameTooltip={true}
            />
          </View>
        </Popover>
      );
    },
    responsive: ['md'],
  };
};

export const renderContactInfoColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="contactInfo"
      />
    ),
    dataIndex: 'singleLeadData',
    key: 'mobileNumber',
    width: 250,
    render: (singleLeadData: any, selectedRecord: any): JSX.Element => {
      const mask = `(###) ###-####`;
      const maskedPhoneNumber = numericStringMask(
        singleLeadData?.phoneNumber,
        mask,
      );
      const email = singleLeadData?.email || '';
      return (
        <VStack>
          <View>
            {singleLeadData.phoneNumber === '' ||
            singleLeadData.phoneNumber === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Pressable>
                <PhoneNumber
                  phoneNumber={maskedPhoneNumber}
                  contactData={singleLeadData}
                  onActionPerformed={actionCode => {
                    if(!isCallsAllowed()) {
                      return
                    }
                    if (
                      columnParams?.onActionPerformed &&
                      typeof columnParams?.onActionPerformed == 'function'
                    ) {
                      columnParams.onActionPerformed(actionCode, {
                        ...selectedRecord,
                        showConsentError: true,
                      });
                    }
                  }}
                />
              </Pressable>
            )}
          </View>
          <View>
            {email === '' || email === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Pressable>
                <Email
                  contactData={singleLeadData}
                  onActionPerformed={actionCode => {
                    if (
                      columnParams?.onActionPerformed &&
                      typeof columnParams?.onActionPerformed == 'function'
                    ) {
                      columnParams.onActionPerformed(actionCode, {
                        ...selectedRecord,
                        showConsentError: true,
                      });
                    }
                  }}
                />
              </Pressable>
            )}
          </View>
        </VStack>
      );
    },
    responsive: ['md'],
  };
};

const renderRAFScoreElement = (selectedRecord: any, scoreType: any ) : JSX.Element => {
  const hccScores = selectedRecord?.contactData?.contactScoreAudit?.filter(
    (item:any) => item?.scoreType ===  scoreType
  );
  let hccScoreDiff;
  if (hccScores?.length >= 2) {
    hccScoreDiff = (hccScores?.[0].scoreValue - hccScores?.[1].scoreValue);
    if (hccScoreDiff !== 0) {
      hccScoreDiff = +hccScoreDiff.toFixed(2);
    }
  }
  else {
    hccScoreDiff = 0;
  }
  return (
    <RAFScoreElement
      hccScore={selectedRecord?.contactData?.contactScore?.hccV28}
      hccScoreDiff={hccScoreDiff}
      selectedRecord={selectedRecord}
      patientId={selectedRecord?.contactData?.patient?.patientId}
      practiceLocationId={selectedRecord?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid}
    />
  );
}


export const renderRAFColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const order = columnParams?.sortOrderBy?.hccV28 === SORT_ORDER.ASC ? SORT_ORDER.DESC  : SORT_ORDER.ASC;
  const headerTitleText = columnData?.displayName || 'raf'
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
        <Pressable onPress={() => columnParams?.sortHccV28(order)}>
          <SortIcon sortMode={order}/>
        </Pressable>
      </Stack>
    ),
    key: 'raf',
    width: 100,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const hccV28ScoreElement = renderRAFScoreElement(selectedRecord, SCORE_TYPE.HCC_V_28);
      return hccV28ScoreElement;
    },
    responsive: ['md'],
  };
};
export const renderLocationColumn = (columnParams: IDashboardColumnProps,  columnData?: IGridColumn) => {
  // const order = columnParams?.sortOrderBy?.foldScore === SORT_ORDER.ASC ? SORT_ORDER.DESC  : SORT_ORDER.ASC;
  const headerTitleText = columnData?.displayName || 'location';
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
           {/* <Pressable onPress = {() => columnParams?.sortFoldScore(order)}>
          <SortIcon sortMode={order} />
        </Pressable> */}
      </Stack>
    ),
    dataIndex: 'location',
    key: 'location',
    width: 150,
    render: (text: any): JSX.Element => {
      const result = text?.length > 0 ? text[0].practiceLocation?.name : '--';
      return (
        <View>
          <>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
              {result}
            </Text>
          </>
        </View>
      );
    },
    responsive: ['md'],
  };
};

export const renderBillingPractitionerColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const headerTitleText = columnData?.displayName || 'primaryCareManager';
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
        {/* {columnData?.isSortingApplicable && (
          <Pressable>
            <SortIcon sortMode={SORT_ORDER.ASC} />
          </Pressable>
        )} */}
      </Stack>
    ),
    key: 'primaryCareManager',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.careTeamLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      const allCareTeamMembersExcludingPcpAndCareManager = (
        selectedRecord?.careTeam?.careTeamMemberType || []
      ).filter(
        (careTeamMember: any) =>
          careTeamMember?.memberType?.code !== CARE_TEAM_MEMBER_TYPE.PCP &&
          careTeamMember?.memberType?.code !==
            CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
      );



      const billingPractitioner = (selectedRecord?.careTeam?.careTeamMemberType || []).find((careTeamMember: any) => {
        return careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER;
      });
      const careManager = (selectedRecord?.careTeam?.careTeamMemberType || []).find((careTeamMember: any) => {
        return careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.CARE_MANAGER;
      });

      return (
        <Stack direction={'column'}>
          <CareManagerAssignee
            contactUuid={selectedRecord?.contactData?.uuid}
            accountLocationUuid={selectedRecord?.accountLocationUuid}
            careTeam={selectedRecord?.careTeam}
            careManager={careManager}
            showDialog={false}
          />

          {/* Commented for the future use
          {!!allCareTeamMembersExcludingPcpAndCareManager && (
            <Stack direction={'column'}>
              {allCareTeamMembersExcludingPcpAndCareManager?.map(
                (careTeamMember: any, index: number) => {
                  return (
                    <Text
                      key={careTeamMember?.userId}
                      wordBreak={'break-word'}
                      size={'smLight'}
                      color={Colors.Custom.Gray500}
                      fontSize={14}
                    >
                      {careTeamMember?.userName}
                      {(index < allCareTeamMembersExcludingPcpAndCareManager?.length -1) && ','}
                    </Text>
                  );
                }
              )}
            </Stack>
          )}
          */}
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const renderPatientLastVisitDate = (
  columnParams: IDashboardColumnProps
) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="patientLastVisitDate"
      />
    ),
    key: 'patientLastVisitDate',
    width: 150,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const lastVisitDate = selectedRecord?.lastVisitAppointmentData
        ?.startDateTime
        ? getDateStrFromFormat(
            selectedRecord?.lastVisitAppointmentData?.startDateTime,
            DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
          )
        : '-';
      return (
        <Stack direction={'column'}>
          <Text color={Colors.Custom.Gray500} size={'smRegular'}>
            {lastVisitDate}
          </Text>
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const renderPCPColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const headerTitleText = columnData?.displayName || 'pcp';
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
        {/* {columnData?.isSortingApplicable && (
          <Pressable>
            <SortIcon sortMode={SORT_ORDER.ASC} />
          </Pressable>
        )} */}
      </Stack>
    ),
    key: 'pcp',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const pcpPractitioner = (
        selectedRecord?.careTeam?.careTeamMemberType || []
      ).find((careTeamMember: any) => {
        return careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.PCP;
      });

      if (selectedRecord?.additionalDataLoading?.careTeamLoading) {
        return <Skeleton.Text lines={1}></Skeleton.Text>;
      }

      return (
        <Stack direction={'column'}>
          {pcpPractitioner?.userName ? (
            <Text
              wordBreak={'break-word'}
              size={'smRegular'}
              color={Colors.FoldPixel.GRAY250}
              fontSize={14}
            >
              {pcpPractitioner.userName}
            </Text>
          ) : (
            <Text color={Colors.Custom.Gray500} size={'smRegular'}>
              {'-'}
            </Text>
          )}
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const renderChronicConditionColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const headerTitleText = columnData?.displayName || 'chronicConditions';
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId={headerTitleText}
      />
    ),
    key: 'conditions',
    width: 240,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.chronicConditionLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      const chronicConditions: ICondition[] = selectedRecord?.chronicCondition || [];
      const popOverContentData = chronicConditions.map((item: any) => ({
        name: item.name,
        duration: item?.onSetDateTime
          ? Math.floor(getDiffInMonths(item?.onSetDateTime, getMomentObj(new Date())))
          : 0
      }));
      return (
        <>
          <Stack direction={'column'}>
            {chronicConditions.length > 0 ? (
              <Popover
                content={
                  <FlatList
                    data={popOverContentData}
                    renderItem={({ item }) => (
                      <Stack direction="row" style={styles.chronicConditionView}>
                        <Text style={styles.chronicCondition}>{item.name}</Text>
                        <Text 
                          style={styles.chronicConditionMonths}
                          paddingX={2}
                          paddingY={0.25}              
                        >
                          {`${item.duration}M`}
                        </Text>
                      </Stack>
                    )}
                    keyExtractor={(item, index) => `${item.name}-${index}`}
                    style={{ width: 200 }}
                  />
                }
                trigger="hover"
              >
                <Stack flexWrap="wrap" flexDirection="row">
                  {chronicConditions.slice(0, 2).map((condition: ICondition, index: number) => (
                    <Text key={index} wordBreak={'break-word'} color={Colors.FoldPixel.GRAY250} size={'smRegular'}>
                      {condition?.name}{index < 1 && chronicConditions.length > 1 ? ', ' : ''}
                    </Text>
                  ))}
                  {chronicConditions.length > 2 && (
                    <Text wordBreak={'break-word'} color={Colors.Custom.Gray500} size={'smRegular'}>
                      {` +${chronicConditions.length - 2}`}
                    </Text>
                  )}
                </Stack>
              </Popover>
            ) : (
              <Text color={Colors.Custom.Gray500}>
                {'-'}
              </Text>
            )}
          </Stack>
        </>
      );
    },
    responsive: ['md'],
  };
};

export const renderDischargeDateColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const headerTitleText = columnData?.displayName || 'dischargeDate';
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId={headerTitleText}
      />
    ),
    key: 'dischargeDate',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE, selectedRecord);
    },
    responsive: ['md'],
  };
};

export const renderAdmissionDateColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const headerTitleText = columnData?.displayName || 'admittedDate';
  return {
    title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
    ),
    key: 'admittedDate',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE, selectedRecord);
    },
    responsive: ['md'],
  };
};

export const renderComplexityColumn = (
  columnParams: IDashboardColumnProps,
  columnData?: IGridColumn
) => {
  const headerTitleText = columnData?.displayName || 'complexity';
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
      </Stack>
    ),
    key: 'complexity',
    width: 120,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.careProgramLoading) {
        return <Skeleton.Text lines={1}></Skeleton.Text>;
      }
      if (
        !selectedRecord?.additionalDataLoading?.careProgramLoading &&
        !selectedRecord?.contactCarePrograms?.length
      ) {
        return <></>;
      }
      const contactCarePrograms: IContactCareProgramResponse[] =
        selectedRecord?.contactCarePrograms || [];
      return (
        <CareProgramComplexity contactCarePrograms={contactCarePrograms}/>
      );
    },
    responsive: ['md'],
  };
};

export const renderCareProgramStatusColumn = (
  columnParams: IDashboardColumnProps,
  columnData?: IGridColumn
) => {
  const TCM_CARE_PROGRAM_LIST = [PROGRAM_FILTER_CODE.TCM, PROGRAM_SUB_FILTER_CODE.TCM_APPOINTMENT_PENDING, PROGRAM_SUB_FILTER_CODE.TCM_OUTREACH_PENDING]
  const headerTitleText = columnData?.displayName ? columnData?.displayName : TCM_CARE_PROGRAM_LIST.includes(columnParams?.filterCode || '') ? "TCM Status" : 'CCM Status';
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
      </Stack>
    ),
    key: 'careProgramStatus',
    width: 120,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (
        !selectedRecord?.additionalDataLoading?.careProgramLoading &&
        !selectedRecord?.contactCarePrograms?.length
      ) {
        return <Skeleton.Text lines={1}></Skeleton.Text>;
      }
      const contactCarePrograms: IContactCareProgramResponse[] =
        selectedRecord?.contactCarePrograms || [];
      return (
        <CareProgramStatus contactCarePrograms={contactCarePrograms}/>
      );
    },
    responsive: ['md'],
  };
};

export const renderPrimaryDiagnosisCodeColumn = (
  columnParams: IDashboardColumnProps,
  columnData?: IGridColumn
) => {
  const headerTitleText = columnData?.displayName || 'primaryDiagnosisCode';
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || '' }}
          textLocalId={headerTitleText}
        />
      </Stack>
    ),
    key: 'primaryDiagnosisCode',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.PRIMARY_DIAGNOSIS_CODE, selectedRecord)
    },
    responsive: ['md'],
  };
};

export const renderAttendingPhysicianColumn = (
  columnParams: IDashboardColumnProps,
  columnData?: IGridColumn
) => {
  const headerTitleText = columnData?.displayName || 'attendingPhysician';
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
      </Stack>
    ),
    key: 'attendingPhysician',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.ATTENDING_PHYSICIAN, selectedRecord)
    },
    responsive: ['md'],
  };
};

export const renderDaysElapsedColumn = (
  columnParams: IDashboardColumnProps,
  columnData?: IGridColumn
) => {
  const headerTitleText = columnData?.displayName || 'daysElapsed';
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
      </Stack>
    ),
    key: 'daysElapsed',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return (
        <DayLapseView selectedRecord= {selectedRecord}/>
      )
    },
    responsive: ['md'],
  };
};

export const renderTCMStatusColumn = (
  columnParams: IDashboardColumnProps,
  columnData?: IGridColumn
) => {
  const headerTitleText = columnData?.displayName || 'tcmStatus';
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
      </Stack>
    ),
    key: 'tcmStatus',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.careProgramLoading) {
        return <Skeleton.Text lines={1}></Skeleton.Text>;
      }
      if (
        !selectedRecord?.additionalDataLoading?.careProgramLoading &&
        !selectedRecord?.contactCarePrograms?.length
      ) {
        return <></>;
      }
      const contactCarePrograms: IContactCareProgramResponse[] =
        selectedRecord?.contactCarePrograms || [];
      return (
        <Stack direction={'column'}>
          {(contactCarePrograms || []).map((contactCareProgram, index) => {
            return (
              <Stack
                direction={'row'}
                key={`${contactCareProgram?.id}_${index}`}
              >
                <Text
                  wordBreak={'break-word'}
                  color={Colors.Custom.Gray500}
                  size={'smLight'}
                >
                  {'-'}
                </Text>
              </Stack>
            );
          })}
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const renderFoldScoreColumn = (
  columnParams: IDashboardColumnProps,
  columnData?: IGridColumn
) => {
  const headerTitleText = columnData?.displayName || 'P';
  const order = columnParams?.sortOrderBy?.foldScore === SORT_ORDER.ASC ? SORT_ORDER.DESC  : SORT_ORDER.ASC;
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId={headerTitleText}
        />
        <Pressable onPress = {() => columnParams?.sortFoldScore(order)}>
          <SortIcon sortMode={order} />
        </Pressable>
      </Stack>
    ),
    key: 'foldScore',
    width: 60,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const foldScore = selectedRecord?.contactData?.contactScore?.foldScore;
      return (
        <Stack direction={'column'}>
          {
            <Text
              wordBreak={'break-word'}
              size={'thMedium'}
              color={Colors.FoldPixel.GRAY400}
              fontSize={14}
            >
              {hasValue(foldScore) ? foldScore : '-'}
            </Text>
          }
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const renderFoldIdColumn = (
  columnParams: IDashboardColumnProps,
) => {
  // const order = columnParams?.sortOrderBy?.foldScore === SORT_ORDER.ASC ? SORT_ORDER.DESC  : SORT_ORDER.ASC;
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="foldId"
        />
        {/* <Pressable onPress = {() => columnParams?.sortFoldScore(order)}>
          <SortIcon sortMode={order} />
        </Pressable> */}
      </Stack>
    ),
    key: 'foldId',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const foldId = selectedRecord?.id;
      return (
        <Stack direction={'column'}>
          {
            <Text
              wordBreak={'break-word'}
              size={'smLight'}
              color={Colors.FoldPixel.GRAY250}
              fontSize={14}
            >
              {hasValue(foldId) ? foldId : '-'}
            </Text>
          }
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const renderTimeSpentColumn = (
  columnParams: IDashboardColumnProps,
) => {
  // const order = columnParams?.sortOrderBy?.foldScore === SORT_ORDER.ASC ? SORT_ORDER.DESC  : SORT_ORDER.ASC;
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="timeSpentOnPatient"
        />
        {/* <Pressable onPress = {() => columnParams?.sortFoldScore(order)}>
          <SortIcon sortMode={order} />
        </Pressable> */}
      </Stack>
    ),
    key: 'timeSpent',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const totalDurationInMinutes = selectedRecord?.billableTimeSpentResponse?.[0]?.totalDurationInMinutes;
      return (
        <Stack direction={'column'}>
          {
            <Text
              wordBreak={'break-word'}
              size={'smLight'}
              color={Colors.Custom.Gray500}
              fontSize={14}
            >
              {!!totalDurationInMinutes ? `${totalDurationInMinutes} mins` : '-'}
            </Text>
          }
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const renderCaseManagementFlagColumn = (
  columnParams: IDashboardColumnProps,
) => {
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="caseManagementFlag"
        />
      </Stack>
    ),
    key: 'caseManagementFlag',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const hasTCM = selectedRecord?.contactCarePrograms?.some((contactCareProgram: IContactCareProgramResponse) => 
        contactCareProgram?.payerCareProgram?.careProgramType?.code === 'TCM'
      );
      const caseManagement = hasTCM ? 'Yes' : 'No';
      const CaseManagemenDisplay = `Case Management: ${caseManagement}`;
      return (
        <Stack direction={'column'}>
          {
            <Text
              wordBreak={'break-word'}
              size={'smRegular'}
              color={Colors.FoldPixel.GRAY250}
              fontSize={14}
            >
              {CaseManagemenDisplay}
            </Text>
          }
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const renderCareManagementFlagColumn = (
  columnParams: IDashboardColumnProps,
) => {
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="careManagementFlag"
        />
      </Stack>
    ),
    key: 'careManagementFlag',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const hasCCM = selectedRecord?.contactCarePrograms?.some((contactCareProgram: IContactCareProgramResponse) => 
        contactCareProgram?.payerCareProgram?.careProgramType?.code === 'CCM'
      );
      const careManagement = hasCCM ? 'Yes' : 'No';
      const CareManagemenDisplay = `Care Management: ${careManagement}`;
      return (
        <Stack direction={'column'}>
          {
            <Text
              wordBreak={'break-word'}
              size={'smRegular'}
              color={Colors.FoldPixel.GRAY250}
              fontSize={14}
            >
              {CareManagemenDisplay}
            </Text>
          }
        </Stack>
      );
    },
    responsive: ['md'],
  };
};


export const renderNumberOfDiagnosisGapsColumn = (
  columnParams: IDashboardColumnProps,
) => {
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="numberOfDiagnosisGaps"
        />
      </Stack>
    ),
    key: 'numberOfDiagnosisGaps',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const numberOfDiagnosisGaps = Object.keys(selectedRecord?.contactData?.contactScore?.reference?.hccRiskScoreData?.hccV28RiskScoreData?.output?.hcc_map || {}).length;
      return (
       <DiagnosisGap 
        selectedRecord={selectedRecord}
        numberOfDiagnosisGaps={numberOfDiagnosisGaps}
       />
        
      );
    },
    responsive: ['md'],
  };
};

export const renderClientIdColumn = (
  columnParams: IDashboardColumnProps,
) => {
  return {
    title: (
      <Stack direction={'row'}>
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.Custom.GRAY350 || ''}}
          textLocalId="clientId"
        />
      </Stack>
    ),
    key: 'clientId',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const clientId = selectedRecord?.contactData?.patient?.patientId;
      return (
        <Stack direction={'column'}>
          {
            <Text
              wordBreak={'break-word'}
              size={'smLight'}
              color={Colors.FoldPixel.GRAY250}
              fontSize={14}
            >
              {!!clientId ? `${clientId} ` : '-'}
            </Text>
          }
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const renderCarePlanStatusColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="carePlanStatus"
      />
    ),
    key: 'carePlanStatus',
    width: 150,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.chronicConditionLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      return (<></>)
    },
    responsive: ['md'],
  };
};

export const renderHealthPlanAndCareStatusColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="healthPlan"
      />
    ),
    key: 'healthPlan',
    width: 220,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const patientId = selectedRecord?.contactData?.patient?.patientId || undefined;
      const patientLocationUuid = getPatientLocationUuidFromPracticeLocations(selectedRecord?.contactData?.contactPracticeLocations) || '';
      if (!patientId) {
        return (<></>)
      }
      return (<MemberHealthPlanView patientId={patientId} patientLocationUuid={patientLocationUuid}/>)
    },
    responsive: ['md'],
  };
};


export const renderEnrollmentDateAndStatusColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="enrollmentDate"
      />
    ),
    key: 'enrollmentDate',
    width: 200,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.careProgramLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      if (!selectedRecord?.additionalDataLoading?.careProgramLoading && !selectedRecord?.contactCarePrograms?.length) {
        return (<></>);
      }
      const contactCarePrograms: IContactCareProgramResponse[] = selectedRecord?.contactCarePrograms || [];
      return (
        <>
          <Stack direction={'column'} key={`${selectedRecord?.uuid}_enrollment`}>
            {(contactCarePrograms || []).map((contactCareProgram, index) => {
              return (
                <Stack direction={'row'} key={`${contactCareProgram?.id}_enrollment`}>
                  {contactCareProgram?.enrollmentLog?.enrollmentDateTime && (
                    <Text size={'thMedium'} color={Colors.Custom.Gray500} key={`${contactCareProgram?.id}_enrollmentDate`}>{getDateStrFromFormat(contactCareProgram.enrollmentLog.enrollmentDateTime, DATE_FORMATS.MESSAGE_DATE_FORMAT)}
                    </Text>
                  )}
                  {!contactCareProgram?.enrollmentLog?.enrollmentDateTime && (
                    <Text size={'thMedium'} color={Colors.Custom.Gray500} key={`${contactCareProgram?.id}_enrollmentDateStatus`}>{getProgramEnrollmentDateAndStatus(contactCareProgram, true)} </Text>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </>
      );
    },
    responsive: ['md'],
  };
};

export const renderFacilityColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const headerTitleText = columnData?.displayName || 'facility';
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId={headerTitleText}
      />
    ),
    key: 'facility',
    width: 180,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.FACILITY, selectedRecord);
    },
    responsive: ['md'],
  };
};

export const renderFacilityTypeColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const headerTitleText = columnData?.displayName || 'facilityType';
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId={headerTitleText}
      />
    ),
    key: 'facilityType',
    width: 150,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.FACILITY_TYPE, selectedRecord);
    },
    responsive: ['md'],
  };
};

export const renderAWVDateColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="awvDate"
      />
    ),
    key: 'awvDate',
    width: 270,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const patientId = selectedRecord?.contactData?.patient?.patientId || undefined;
      const patientLocationUuid = getPatientLocationUuidFromPracticeLocations(selectedRecord?.contactData?.contactPracticeLocations) || '';
      const appointmentData=selectedRecord?.appointmentData
      if (!patientId) {
        return (<></>)
      }
      return (<MemberAWVStatusView patientId={patientId} appointmentData={appointmentData} patientLocationUuid={patientLocationUuid}/>)

    },
    responsive: ['md'],
  };
};

export const renderDischargeToColumn = (columnParams: IDashboardColumnProps, columnData?: IGridColumn) => {
  const headerTitleText = columnData?.displayName || 'dischargeTo';
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId={headerTitleText}
      />
    ),
    key: 'dischargeTo',
    width: 150,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      return renderAdditionalAttributeWithDetail(ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_TO, selectedRecord);
    },
    responsive: ['md'],
  };
};

export const renderEligibleProgramColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="eligibleFor"
      />
    ),
    key: 'eligibleFor',
    width: 280,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      if (selectedRecord?.additionalDataLoading?.careProgramLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      if (!selectedRecord?.additionalDataLoading?.careProgramLoading && !selectedRecord?.contactCarePrograms?.length) {
        return (<></>);
      }
     const contactId= selectedRecord?.id;
      const contactCarePrograms: IContactCareProgramResponse[] = selectedRecord?.contactCarePrograms || [];
      return (
        <>
          <Stack direction={'column'}>
            {(contactCarePrograms || []).map((contactCareProgram, index) => {
              return (
                <>
                  <CareProgramStepWiseView
                    isCareProgramCard={false}
                    contactId={contactId}
                    filterCode={columnParams?.filterCode}
                    contactCareProgram={contactCareProgram}
                    onActionPerformed={(actionCode: string, extraData: any) => {
                      if (
                        actionCode === COMMON_ACTION_CODES.SEND_FORMS ||
                        actionCode === COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL ||
                        actionCode === COMMON_ACTION_CODES.ECM_OUTREACH_VIEW ||
                        actionCode === COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT ||
                        actionCode === COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_CONSENT ||
                        actionCode === COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_ASSESSMENT ||
                        actionCode === COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_AWV_VISIT
                      ) {
                        if (
                          columnParams?.onActionPerformed &&
                          typeof columnParams.onActionPerformed === 'function'
                        ) {
                          columnParams?.onActionPerformed(
                            actionCode,
                            {...selectedRecord, extraData},
                          );
                        }
                      }
                    }}
                  />
                </>
              );
            })}
          </Stack>
        </>
      );
    },
    responsive: ['md'],
  };
};

export const renderActionColumn = (columnParams: IDashboardColumnProps, hideCommounicationAction?: boolean) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="actions"
      />
    ),
    key: 'action',
    width: 200,
    fixed: 'right',
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (text: any, selectedRecord: any) => {
      if (selectedRecord?.additionalDataLoading?.consentLoading) {
        return (<Skeleton.Text lines={1}></Skeleton.Text>);
      }
      return (
        <>
          <ActionsView
            hideConvertToPatient={false}
            record={selectedRecord}
            onActionClick={(actionCode: string, data: any): any => {
              if (
                columnParams?.onActionPerformed &&
                typeof columnParams?.onActionPerformed == 'function'
              ) {
                if (data === COMMON_ACTION_CODES.CONSENT_WARNING) {
                  columnParams.onActionPerformed(actionCode, {
                    ...selectedRecord,
                    showConsentError: true,
                  });
                } else {
                  columnParams.onActionPerformed(actionCode, selectedRecord);
                }
              }
            }}
            hideCommounicationAction={false}
          />
        </>
      );
    },
    responsive: ['md'],
  };
};

export const renderGoalProgressColumn = (columnParams: IDashboardColumnProps) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="goalProgress"
          />
    ),
    key: 'goalProgress',
    width: 250,
    render: (selectedRecord: any): JSX.Element => (
      <CarePlanGoalProgressBar
        contactId={selectedRecord?.contactData?.uuid}
        onActionableClick={() => {
          columnParams?.onActionPerformed(
            COMMON_ACTION_CODES.CARE_PLAN_GOALS_VIEW,
            {
              ...selectedRecord,
            }
          );
        }}
      />
    ),
    responsive: ['md'],
  };
}

export const renderPatientNextPCPAppointmentDate = (
  columnParams: IDashboardColumnProps
) => {
  return {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.Custom.GRAY350 || ''}}
        textLocalId="pcpNextAppointment"
      />
    ),
    key: 'pcpNextAppointment',
    width: 180,
    onCell: () => {
      return {
        onClick: (e: Event) => {
          e.stopPropagation();
        },
      };
    },
    render: (selectedRecord: any): JSX.Element => {
      const lastVisitDate = selectedRecord?.pcpAppointmentData?.startDateTime
        ? getDateStrFromFormat(
          selectedRecord?.pcpAppointmentData?.startDateTime,
            DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
          )
        : '-';
      
    if (selectedRecord?.additionalDataLoading?.nextPCPAppointmentLoading) {
      return (<Skeleton.Text lines={1}></Skeleton.Text>);
    }
      return (
        <Stack direction={'column'}>
          <Text color={Colors.Custom.Gray500}>
            {lastVisitDate}
          </Text>
        </Stack>
      );
    },
    responsive: ['md'],
  };
};

export const getTableColumnByFilterCode = (inputFilterCode: string | undefined, inputTabCode?: string | undefined) => {
  const columnCodes: string[] = [];
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.RAF);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CONTACT_INFO);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.TAGS);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.PCP);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.NEXT_APPOINTMENT);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.INTERNAL_TASK);
  const filterCode = inputFilterCode || PROGRAM_STEPS_FILTER_CODE.ALL;
  if (filterCode === PROGRAM_STEPS_FILTER_CODE.ALL && inputTabCode === MAIN_TAB_CODE.MY_PATIENT) {
    return MY_PATIENT_COLUMN_CODES;
  }
  switch(filterCode) {
    case PROGRAM_STEPS_FILTER_CODE.ALL:
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.AWV_DUE);
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CONDITION);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_STATUS);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.ENROLLMENT_STATUS);
      break;
    case PROGRAM_FILTER_CODE.CCM:
    case PROGRAM_SUB_FILTER_CODE.CCM_VISIT_PENDING:
    case PROGRAM_SUB_FILTER_CODE.CCM_CONSENT_PENDING: 
    case PROGRAM_SUB_FILTER_CODE.CCM_ASSESSMENT_PENDING:
    case PROGRAM_SUB_FILTER_CODE.CCM_ENROLLMENT_PENDING:
    case PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_DUE:
    case PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_ENROLL:
      return CCM_COLUMN_CODES;
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_STATUS);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.ENROLLMENT_STATUS);
      break;
    case PROGRAM_FILTER_CODE.ACCP:
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CONDITION);
      break;
    case PROGRAM_FILTER_CODE.TCM:
    case PROGRAM_SUB_FILTER_CODE.TCM_APPOINTMENT_PENDING:
    case PROGRAM_SUB_FILTER_CODE.TCM_OUTREACH_PENDING:
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS);
      // columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_STATUS);
      return TCM_COLUMN_CODES
    case PROGRAM_FILTER_CODE.ECM:
      //columnCodes.push(CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS);
      break;
    case PROGRAM_FILTER_CODE.AWV_DUE:
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.AWV_DUE);
      break;
    case PROGRAM_FILTER_CODE.AWV_DUE:
    case PROGRAM_SUB_FILTER_CODE.AWV_SCHEDULED:
    case PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED:
    case PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT:
      columnCodes.push(CONTACT_TABLE_COLUMN_CODE.AWV_DUE);
      break;
  }
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.LAST_VIST);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.ENROLLMENT);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.GOAL_PROGRESS);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.LOCATION);
  columnCodes.push(CONTACT_TABLE_COLUMN_CODE.ACTION);
  return columnCodes;
}

export const getTableViewColumns = (tableColumnParams: IDashboardColumnProps, alertDisplayCategoryId?: string, hideCommounicationAction?: boolean) => {
  const columns: any[] = [];
  const columnsCodes: string[] = getTableColumnByFilterCode(tableColumnParams?.filterCode, tableColumnParams?.tabCode)
  for (const columnCode of columnsCodes) {
    switch (columnCode) {
      case CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME:
        columns.push(renderMemberNameColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.RAF:
        columns.push(renderRAFColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CONTACT_INFO:
        columns.push(renderContactInfoColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CONDITION:
        columns.push(renderChronicConditionColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.ADMITTED_DATE:
        columns.push(renderAdmissionDateColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.DISCHARGE_DATE:
        columns.push(renderDischargeDateColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.DISCHARGE_TO:
        columns.push(renderDischargeToColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.FACILITY:
        columns.push(renderFacilityColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.FACILITY_TYPE:
        columns.push(renderFacilityTypeColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.NEXT_APPOINTMENT:
        break;
      case CONTACT_TABLE_COLUMN_CODE.NEXT_PCP_APPOINTMENT:
        columns.push(renderPatientNextPCPAppointmentDate(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS:
        columns.push(renderCarePlanStatusColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CARE_STATUS:
        columns.push(renderHealthPlanAndCareStatusColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.AWV_DUE:
        columns.push(renderAWVDateColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER:
        columns.push(renderBillingPractitionerColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.PCP:
        columns.push(renderPCPColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT_STATUS:
        columns.push(renderEnrollmentDateAndStatusColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.TAGS:
        columns.push(renderContactTagsColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.INTERNAL_TASK:
        columns.push(renderInternalTaskColumn(tableColumnParams, alertDisplayCategoryId));
        break;
      case CONTACT_TABLE_COLUMN_CODE.LAST_VIST:
        columns.push(renderPatientLastVisitDate(tableColumnParams));
        break
      case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT:
        columns.push(renderEligibleProgramColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.GOAL_PROGRESS:
        columns.push(renderGoalProgressColumn(tableColumnParams));
      break;
      case CONTACT_TABLE_COLUMN_CODE.LOCATION:
        columns.push(renderLocationColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.ACTION:
        columns.push(renderActionColumn(tableColumnParams, hideCommounicationAction));
        break;
      case CONTACT_TABLE_COLUMN_CODE.COMPLEXITY:
        columns.push(renderComplexityColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CCM_STATUS:
        columns.push(renderCareProgramStatusColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.FOLD_SCORE:
        columns.push(renderFoldScoreColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.FOLD_ID:
        columns.push(renderFoldIdColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.TIME_SPENT:
        columns.push(renderTimeSpentColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CASE_MANAGEMENT_FLAGS:
        columns.push(renderCaseManagementFlagColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CARE_MANAGEMENT_FLAGS:
        columns.push(renderCareManagementFlagColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.NUMBER_OF_DIAGNOSIS_GAPS:
        columns.push(renderNumberOfDiagnosisGapsColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CLIENT_ID:
        columns.push(renderClientIdColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.PRIMARY_DIAGNOSIS_CODE:
        columns.push(renderPrimaryDiagnosisCodeColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.TCM_ATTENDING_PHYSICIAN:
        columns.push(renderAttendingPhysicianColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.TCM_STATUS:
        columns.push(renderCareProgramStatusColumn(tableColumnParams))
        break;
      case CONTACT_TABLE_COLUMN_CODE.DAYS_ELAPSED:
        columns.push(renderDaysElapsedColumn(tableColumnParams));
        break;
    }
  }
  return columns;
};

export const getTableViewColumnsV2 = (
  tableColumnParams: IDashboardColumnProps,
  columnData: IGridColumn[],
  alertDisplayCategoryId?: string,
  hideCommounicationAction?: boolean
) => {
  const columns: any[] = [];
  for (const singleColumnData of columnData) {
    switch (singleColumnData?.columnCode) {
      case CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME:
        columns.push(
          renderMemberNameColumn(tableColumnParams, singleColumnData)
        );
        break;     
      case CONTACT_TABLE_COLUMN_CODE.RAF:
        columns.push(renderRAFColumn(tableColumnParams, singleColumnData));
        break;
      case CONTACT_TABLE_COLUMN_CODE.ADMITTED_DATE:
        columns.push(
          renderAdmissionDateColumn(tableColumnParams, singleColumnData)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.DISCHARGE_DATE:
        columns.push(
          renderDischargeDateColumn(tableColumnParams, singleColumnData)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.DISCHARGE_TO:
        columns.push(
          renderDischargeToColumn(tableColumnParams, singleColumnData)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.FACILITY:
        columns.push(renderFacilityColumn(tableColumnParams, singleColumnData));
        break;
      case CONTACT_TABLE_COLUMN_CODE.FACILITY_TYPE:
        columns.push(
          renderFacilityTypeColumn(tableColumnParams, singleColumnData)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER:
        columns.push(
          renderBillingPractitionerColumn(tableColumnParams, singleColumnData)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.PCP:
        columns.push(renderPCPColumn(tableColumnParams, singleColumnData));
        break;
      case CONTACT_TABLE_COLUMN_CODE.LOCATION:
        columns.push(renderLocationColumn(tableColumnParams, singleColumnData));
        break;
      case CONTACT_TABLE_COLUMN_CODE.ACTION:
        columns.push(
          renderActionColumn(tableColumnParams, hideCommounicationAction)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.COMPLEXITY:
        columns.push(
          renderComplexityColumn(tableColumnParams, singleColumnData)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.CCM_STATUS:
        columns.push(renderCareProgramStatusColumn(tableColumnParams, singleColumnData));
        break;
      case CONTACT_TABLE_COLUMN_CODE.FOLD_SCORE:
        columns.push(
          renderFoldScoreColumn(tableColumnParams)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.FOLD_ID:
        columns.push(renderFoldIdColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.TIME_SPENT:
        columns.push(renderTimeSpentColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CASE_MANAGEMENT_FLAGS:
        columns.push(renderCaseManagementFlagColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CARE_MANAGEMENT_FLAGS:
        columns.push(renderCareManagementFlagColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.NUMBER_OF_DIAGNOSIS_GAPS:
        columns.push(renderNumberOfDiagnosisGapsColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CLIENT_ID:
        columns.push(renderClientIdColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT:
        columns.push(renderEligibleProgramColumn(tableColumnParams));
        break;
      case CONTACT_TABLE_COLUMN_CODE.PRIMARY_DIAGNOSIS_CODE:
        columns.push(
          renderPrimaryDiagnosisCodeColumn(tableColumnParams, singleColumnData)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.TCM_ATTENDING_PHYSICIAN:
        columns.push(
          renderAttendingPhysicianColumn(tableColumnParams, singleColumnData)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.TCM_STATUS:
        columns.push(renderCareProgramStatusColumn(tableColumnParams, singleColumnData));
        break;
      case CONTACT_TABLE_COLUMN_CODE.DAYS_ELAPSED:
        columns.push(
          renderDaysElapsedColumn(tableColumnParams, singleColumnData)
        );
        break;
      case CONTACT_TABLE_COLUMN_CODE.TAGS:
        columns.push(renderContactTagsColumn(tableColumnParams, singleColumnData));
        break;
      case CONTACT_TABLE_COLUMN_CODE.CONDITION:
        columns.push(renderChronicConditionColumn(tableColumnParams, singleColumnData));
        break;
      case CONTACT_TABLE_COLUMN_CODE.NEXT_PCP_APPOINTMENT:
        columns.push(renderPatientNextPCPAppointmentDate(tableColumnParams));
        break;
    }
  }
  return columns;
};

const styles = StyleSheet.create({
  chronicCondition: {
    color: Colors.Custom.Gray500,
    fontSize: 14,
    fontWeight: '500',
    alignSelf: 'flex-start',
    marginRight: 1
  },
  chronicConditionMonths: {
    borderWidth: 1,   
    borderColor: Colors.Custom.Gray200, 
    backgroundColor: Colors.Custom.Gray100,           
    borderRadius: 8, 
    fontWeight: '500',
    color: Colors.Custom.Gray500 ,
    alignSelf: 'flex-end'
  },
  chronicConditionView:{
    justifyContent: 'space-between', 
    marginBottom: 6, 
    display: 'flex'
  }
});

