import {View, Text} from 'native-base';
import {Colors} from '../../../../styles/Colors';
import JourneyGauge from './JourneyGauge';
import NodataViewTypeOne from '../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';
import {useContext} from 'react';
import {MLOV_CATEGORY} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';

const JourneyByEntityType = (props: {data?: any}) => {
  const mlovData = useContext(CommonDataContext);

  const taskEntityTypes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ENTITY_TYPE
    ) || [];
  return (
    <View>
      <Text
        style={{
          marginBottom: 8,
          color: Colors.FoldPixel.GRAY400
        }}
        size={'smBold'}
        fontSize={18}
      >
        Adherence by Task Type
      </Text>
      <View flexDirection={'row'} flexWrap={'wrap'}>
        {props?.data?.overallAdherenceByTaskEntityType?.length ? (
          props?.data?.overallAdherenceByTaskEntityType.map((item: any) => {
            const overallAdherence =
              item?.overallAdherencePercentage?.toFixed(2) || 0;
            const value =
              taskEntityTypes?.find(
                (subitem) => subitem.code == item.entityTypeCode
              )?.value || '';
            return (
              <View
                style={{
                  padding: 12,
                  marginHorizontal: 24,
                  marginVertical: 12,
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                maxW={200}
              >
                <Text
                  style={{
                    marginBottom: 8,
                    textAlign: 'center',
                  }}
                  size={'smBold'}
                  fontSize={18}
                >
                  {value}
                </Text>
                <JourneyGauge value={parseFloat(overallAdherence)} />
                <Text
                  size={'smNormal'}
                  style={{
                    color: Colors.Custom.Gray700,
                    marginTop: 14,
                    textAlign: 'center',
                    fontSize: 12,
                  }}
                >
                  {`Average % of All Patients' ${value} Adherence`}
                </Text>
              </View>
            );
          })
        ) : (
          <NodataViewTypeOne viewCode="TEXT_ICON" />
        )}
      </View>
    </View>
  );
};

export default JourneyByEntityType;
