import { GraphColors } from "../../../styles/Colors";
import { COMMUNICATION_TYPE } from './CommunicationLogChartMemberList';

export const GraphConfig = {
    appendPadding: 10,
    radius: 1,
    innerRadius: 0.6,
    color: GraphColors,
    // label: {
    //   autoRotate: false,
    //   type: 'inner',
    //   offset: '-50%',
    //   content: '{value}',
    //   style: {
    //     textAlign: 'center',
    //     fontSize: 10,
    //   },
    // },
    height: 250,
    width: 250,
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: undefined,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
}

export const isSMSCommunicationType = (communicationLog: any) => {
  return communicationLog?.communicationType === COMMUNICATION_TYPE.SMS;
};

export const getCommunicationErrorMessage = (communicationLog: any) => {
  const communicationStatus = communicationLog?.communicationStatus;
  for (const status of communicationStatus) {
    if (status.communicationErrorInfo?.code) {
      return status.communicationErrorInfo.message || '';
    }
    if (
      isSMSCommunicationType(communicationLog) &&
      status.twilioErrorInfo?.code
    ) {
      return status.twilioErrorInfo.message || '';
    }
  }
  return '';
};
