import {HStack, Icon, Pressable, Text, View, VStack} from 'native-base';
import {useState} from 'react';
import {Colors} from '../../../styles';
import {ICustomStepView, IStep} from './interfaces';
import {commonBodyContentContainer} from '../../../styles/CommonBodyContentStyle';
import {styles} from '../CustomTabsView/CustomTabsViewStyles';

const CustomStepView = (props: ICustomStepView) => {
  const {
    stepList,
    onStepClick,
    canSelectStep,
    stepListWrapperStyle,
    selectedStep,
    details,
    style
  } = props;

  const [customStepViewState, setCustomStepViewState] = useState<{
    selectedStep: IStep;
  }>({
    selectedStep: stepList[0],
  });

  const onStepPress = (step: IStep, index: number) => {
    let canSelect = true;

    if (canSelectStep) {
      canSelect = canSelectStep(step, index);
    }

    if (canSelect) {
      setCustomStepViewState((prev) => ({
        ...prev,
        selectedStep: step,
      }));

      onStepClick(step, index);
    }
  };

  const getColorCondition = () => {
    if (selectedStep) {
      return selectedStep;
    }

    return customStepViewState.selectedStep;
  };

  return (
    <View style={[commonBodyContentContainer.customStepViewStyle]}>
      <HStack justifyContent={'space-between'} alignItems={'center'}>
        <HStack style={[stepListWrapperStyle ? stepListWrapperStyle : {}, {paddingHorizontal: 15}]}>
          {stepList.map((step: IStep, index: number) => {
            return (
              <HStack
                key={step.key}
                style={{
                  alignItems: 'center',
                }}
              >
                <Pressable
                  // style={[tabListWrapperStyle ? tabListWrapperStyle : {},]}
                  key={index}
                  onPress={() => {
                    onStepPress(step, index);
                  }}
                  disabled={step.isDisabled}
                >
                  <VStack
                    style={styles.tabStyles}
                    color={
                      getColorCondition().key === step.key
                        ? Colors.primary[300]
                        : Colors.FoldPixel.GRAY300
                    }
                    fontWeight={
                      getColorCondition().key === step.key ? 600 : 500
                    }
                    borderBottomWidth={
                      getColorCondition().key === step.key ? 5 : 0
                    }
                    borderBottomColor={
                      getColorCondition().key === step.key
                        ? Colors.primary[300]
                        : '#fff'
                    }
                  >
                    {step.title}
                  </VStack>
                </Pressable>
              </HStack>
            );
          })}
        </HStack>
        {props.actionContainer && (
          <View style={{marginRight: 16}}>{props.actionContainer}</View>
        )}
      </HStack>
    </View>
  );
};

export default CustomStepView;
