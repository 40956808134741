import {DatePicker, DatePickerProps} from 'antd';
import {RangePickerProps} from 'antd/lib/date-picker';
import moment from 'moment';
import {Select} from 'native-base';
import React, {useEffect, useMemo} from 'react';
import {View, StyleSheet} from 'react-native';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {Colors} from '../../../../../styles';
import {getAccountConfigValue} from '../../../../../utils/configUtils';
import {getMomentObj, isDateStringIsValid, getMomentObjectWithDateStringAndFormat} from '../../../../../utils/DateUtils';
import {TEMP_DATE_RANGE_FOR_DASHBOARD, EMPLOYER_CONST} from '../EmployerUtils/EmployerConst';
import {getUiRangeMonthGap, getRangeTypeCode} from '../EmployerUtils/WidgetUtils';
import { InputStyle } from '../../../Contacts/Leads/LeadView/LeadViewStyle';

const DateRangeFilter = (props:any) => {
  const analyticsStartDateString = getAccountConfigValue(CONFIG_CODES.ANALYTICS_START_DATE);
  const analyticsStartDateMoment =  isDateStringIsValid(analyticsStartDateString)
    ? getMomentObjectWithDateStringAndFormat(analyticsStartDateString, 'YYYY-MM-DD')
    : getMomentObj(new Date());
  const isValidAnalyticsStartDateMoment =  isDateStringIsValid(analyticsStartDateString)

  const onChange = (
    value: any,
    dateString: string,
    selectDateType?: string
  ) => {
    if (selectDateType === 'FROM') {
      const uiSelectDate = moment(value).startOf('month');
      const uiToDate = moment(uiSelectDate)
        .add(getUiRangeMonthGap(props.state.dateRangeType), 'months')
        .endOf('month');
      const uiFromDate = moment(uiSelectDate)
        .subtract(getUiRangeMonthGap(props.state.dateRangeType, true), 'months')
        .startOf('month');
      const isCurrentMonth = moment() < uiToDate ? true : false;
      if (isCurrentMonth) {
        onChange(moment(), '', '');
        return;
      }
      props.setDateRange?.((prev:any) => {
        return {
          ...prev,
          to: uiToDate,
          from: uiFromDate,
          uiFromDate: uiSelectDate,
          uiToDate: uiToDate,
        };
      });
    } else if (selectDateType === 'TO') {
      let toDate: any = moment(value).endOf('month');
      const fromDate = moment(toDate)
        .subtract(getRangeTypeCode(props.state.dateRangeType), 'months')
        .startOf('month');
      const isCurrentMonth =
        moment().get('month') == toDate.get('month') ? true : false;
      if (isCurrentMonth) {
        toDate = toDate.set('day', moment().get('day'));
      }
      props.setDateRange?.((prev:any) => {
        return {
          ...prev,
          from: fromDate,
          to: toDate,
        };
      });
    } else {
      const uiSelectDate = moment(value).endOf('month');
      let uiFromDate = moment(uiSelectDate)
        .subtract(getUiRangeMonthGap(props.state.dateRangeType, false), 'months')
        .startOf('month');
        if (isValidAnalyticsStartDateMoment) {
          uiFromDate =  uiFromDate < analyticsStartDateMoment ? analyticsStartDateMoment : uiFromDate
        }
      const actualFromDate = moment(uiFromDate)
        .subtract(getUiRangeMonthGap(props.state.dateRangeType, true), 'months')
        .startOf('month');
      props.setDateRange?.((prev:any) => {
        return {
          ...prev,
          to: uiSelectDate,
          from: actualFromDate,
          uiFromDate: uiFromDate,
          uiToDate: uiSelectDate,
        };
      });
    }
  };

  useEffect(() => {
    onChange(moment(), '', '');
  }, [props.state.dateRangeType]);

  const dynamicStyles = useMemo(() => {
    return StyleSheet.create({
      outerView: {
        marginLeft: props?.isSmallScreen ? 18 : 12
      },
      innerView: {
        width: props?.isSmallScreen ? 170 : 160
      },
      datePickerInnerContainer : {
        width: props?.isSmallScreen ? 60 : 130
      }
    });
  }, [props?.isSmallScreen]);

  return (
    <>
      <View style={dynamicStyles.outerView}>
        {/* <Text
                  size={'lgSemibold'}
                  style={ {color: Colors.Custom.Gray900, marginBottom: 8}}
                >
                  Duration
        </Text> */}
        <View style={dynamicStyles.innerView}>
          <Select
            width={'100%'}
            fontSize={InputStyle.placeHolderFontsize}
            height={10}
            borderRadius={8}
            borderColor={Colors.Custom.Gray200}
            backgroundColor={'#fff'}
            onValueChange={(value) => {
              props.setState?.((prev:any) => {
                return {
                  ...prev,
                  dateRangeType: value,
                };
              });
             // onChange(moment(), '', value);
            }}
            selectedValue={props?.state?.dateRangeType || ''}
          >
            {TEMP_DATE_RANGE_FOR_DASHBOARD?.map((item) => {
              return (
                <Select.Item
                  fontSize={16}
                  label={item.name}
                  value={item.code}
                  key={item.id}
                />
              );
            })}
          </Select>
        </View>
      </View>
      <View style={styles.datePickerContainer}>
        <View style={dynamicStyles.datePickerInnerContainer}>
          <DatePicker
            disabledDate={(current) => {
              if (isValidAnalyticsStartDateMoment) {
                return current && current < analyticsStartDateMoment.startOf('day');
              }
              return !current.isBefore(getMomentObj(new Date()))
            }}
            style={antdStyles.datePicker}
            clearIcon={false}
            format={
              props?.state?.dateRangeType == EMPLOYER_CONST.MONTH
                ? 'YYYY-MMM-DD'
                : 'YYYY-MMM'
            }
            onChange={(
              value: DatePickerProps['value'] | RangePickerProps['value'],
              dateString: string
            ) => {
              onChange?.(value, dateString, 'FROM');
            }}
            picker="month"
            value={props?.dateRange?.uiFromDate}
          />
        </View>
        <View style={styles.toDatePickerContainer}>
          <DatePicker
            style={antdStyles.datePicker}
            clearIcon={false}
            format={
              props.state?.dateRangeType == EMPLOYER_CONST.MONTH
                ? 'YYYY-MMM-DD'
                : 'YYYY-MMM'
            }
            onChange={(
              value: DatePickerProps['value'] | RangePickerProps['value'],
              dateString: string
            ) => {
              onChange?.(value, dateString, 'TO');
            }}
            disabled={true}
            value={props.dateRange.uiToDate}
            picker="month"
          />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  datePickerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 12,
    borderWidth: 1,
    borderColor: '#e4e7ec',
    borderRadius: 8,
    backgroundColor: '#fff',
    overflow: 'hidden',
    height: 40
  },
  toDatePickerContainer: {
    width: 130
  }
});

const antdStyles: Record<string, React.CSSProperties> = {
  datePicker: {
    width: '100%',
    height: 40,
    borderWidth: 0,
    backgroundColor: 'none',
  },
};

export default React.memo(DateRangeFilter);
