import {Dimensions, StyleSheet} from 'react-native';
import { Colors } from '../../../styles';
export const styles = StyleSheet.create({
  titleWrapper: {
    // marginHorizontal: 22,
    // marginVertical: 12,
  },

  tabsWrapper: {
   // marginHorizontal: 8,
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    height: Dimensions.get('window').height - 60,
  },
  sidebar: {
    width: 200,
    // backgroundColor: '#ffffff',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D6D6D6',
    borderTopWidth: 0,
    overflow: 'scroll',
    borderBottomWidth: 0,
    height: Dimensions.get('window').height - 60,
  },
  content: {
    height: Dimensions.get('window').height - 90,
    flex: 1,
    paddingVertical: 2,
  },
  menuItem: {
    borderLeftWidth: 0,
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderTopColor: '#EEEEEE',
    borderBottomColor: '#EEEEEE',
    paddingLeft: 25,
    paddingVertical: 15,
  },
  selectedMenuItem: {
    borderLeftWidth: 5,
    borderLeftColor: '#7F56D9',
    backgroundColor: '#7F56D920',
  },
  borderTop1: {
    borderTopWidth: 1,
  },
  menuItemText: {
    color: Colors.FoldPixel.GRAY300,
  },
  selectedMenuItemText: {
    color: '#7F56D9',
  },
});
