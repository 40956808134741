import React, {useState} from 'react';
import {
  View,
  Text,
  VStack,
  Pressable,
  HStack,
  Icon
} from 'native-base';
import {ILastAppointment} from '../interface';
import {getFormattedDate} from '../../../../../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../../../../../constants/StringConst';
import {Colors} from '../../../../../../../styles/Colors';
import {IContact} from '../../../../../TeamInbox/Conversations/interfaces';
import AtClinicAppointmentIcon from '../../../../../../../assets/images/Avatar/AtClinicAppointmentIcon';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {DRILL_DOWN_RESOURCE_CODE} from '../../../EmployerUtils/EmployerConst';
import {getSingleContactDrillDownData} from '../../../../../../../services/AccountDashboard/AccountDashboardServices';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import DrillDownRowDataView from '../../DrillDownRowDataView';
const LastAppointmentDetails = (props: {
  columnData: ILastAppointment;
  columnConfig: any;
  contactData: IContact;
  contactDrillDownData: any;
  isOnFirstView: boolean;
  filterData?: any;
  onColumnActionPerformed?: (actionCode: string, actionData?: any) => void;
}) => {
  const {
    columnData,
    contactData,
    onColumnActionPerformed
  } = props;
  const [state, setState] = useState({
    isOpenDetailView: false,
    selectedDrillDownRowData: {} as any,
    columnList: [] as any[],
    selectedContact: contactData as IContact,
  });


  const getSingleContactAppointmentData = async () => {
    if (contactData?.uuid && onColumnActionPerformed) {
      onColumnActionPerformed(COMMON_ACTION_CODES.LOADING);
      const response = await getSingleContactDrillDownData({
        contactUuid: contactData?.uuid,
        resourceCode: DRILL_DOWN_RESOURCE_CODE.APPOINTMENT,
      }
      ).catch((err) => {
        onColumnActionPerformed(COMMON_ACTION_CODES.LOADING_COMPLETE);
      });
      onColumnActionPerformed(COMMON_ACTION_CODES.LOADING_COMPLETE);
      if (response?.data?.contactDrillDownData?.contactDrillDownDataList?.length
        && response?.data?.contactDrillDownData?.columnList?.length) {
        setState(prev => {
          return {
            ...prev,
            isOpenDetailView: true,
            selectedDrillDownRowData: response?.data?.contactDrillDownData?.contactDrillDownDataList[0],
            columnList: response?.data?.contactDrillDownData?.columnList || [],
            selectedContact: contactData as IContact
          }
        })
      }
    }
  }
  const locationTypeValue = columnData?.locationTypeValue?.toLowerCase() || 'at clinic';
  const renderLastAppointmentDetails = () => {
    if (columnData?.contactUuid && columnData?.startDateTime.value) {
      return (
        <VStack>
          <HStack>
            <Text size={'smRegular'} style={{color: Colors.FoldPixel.GRAY250 }}>
              {getFormattedDate(columnData?.startDateTime.value, DATE_FORMATS.MESSAGE_DATE_FORMAT)}
            </Text>
          </HStack>
          <HStack>
            {locationTypeValue !== 'at clinic' ? (
              <AtClinicAppointmentIcon color={Colors.Custom.black25} />
            ) : null}
            {locationTypeValue === 'VIRTUAL' && (
              <Icon
                as={FeatherIcon}
                name="video"
                size={4}
                mr={1}
                color={Colors.Custom.black25}
              />
            )}
            <Text noOfLines={1} size={'smRegular'} style={{color: Colors.FoldPixel.GRAY250 }}>{columnData?.primaryUserName}</Text>
          </HStack>
          <Pressable
            style={{paddingHorizontal: 3, paddingVertical: 4}}
            onPress={() => {
              getSingleContactAppointmentData();
            }} >
            <Text size='smRegular' color={Colors.FoldPixel.PRIMARY300}>
              All Appointments
            </Text>
          </Pressable>
          {state.isOpenDetailView ? (
            <DrillDownRowDataView
              onClose={() => {
                setState(prev => {
                  return {
                    ...prev,
                    isOpenDetailView: false,
                    selectedDrillDownRowData: null,
                    selectedContact: {} as IContact
                  }
                });
              }}
              columnList={state.columnList}
              selectedContact={state.selectedContact}
              selectedDrillDownRowData={state.selectedDrillDownRowData}
            />
          ) : (
            <></>
          )}
        </VStack>
      )
    } else {
      return (
        <View>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} >
            No Appointment Detail
          </Text>
        </View>
      );
    }
  };
  return (
    <View style={{
      maxWidth: 180
    }}>
      {renderLastAppointmentDetails()}
    </View>
  );
}
export default React.memo(LastAppointmentDetails);
