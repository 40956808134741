import React, {useState} from 'react';
import {Drawer, Skeleton} from 'antd';
import {
  useMediaQuery,
  View,
  VStack,
  HStack,
  Text,
  FlatList,
  Center,
  Badge,
  Pressable,
} from 'native-base';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../../../styles';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {ActivityIndicator} from 'react-native';
import useGroupDetailsAudit from './Hooks/useGroupDetailsAudit';
import DateRangePicker from '../../../Analytics/CommonComponents/DateRangePicker';
import {DateRange} from '../../../Analytics/CommonComponents/Interfaces';
import {TimeUnitValues} from '../../../Analytics/CommonComponents/constants';
import AuditTimelineItemView from './AuditTimelineItemView';
import {AUDIT_ACTION_CODES} from '../../../../common/Audit/AuditHelper';
import {parentResourceCodeFilters} from './GroupDetailsUtils';
import {
  getDateObjectFromStringAndFormat,
  getEndOfDay,
  getFormattedDate,
} from '../../../../../utils/DateUtils';
import {useIntl} from 'react-intl';
import ClockIconForAudit from '../../../../common/Svg/ClockIconForAudit';
import {ActionTypeNames} from './Hooks/interface';
import Stack from '../../../../common/LayoutComponents/Stack';
import Feather from 'react-native-vector-icons/Feather';
import GroupDetailMemberListDrawer from './GroupDetailMemberListDrawer';
import MemberAddedSvg from '../../../../common/Svg/PopulationGroupAudit/MemberAddedSvg';
import MemberDroppedSvg from '../../../../common/Svg/PopulationGroupAudit/MemberDroppedSvg';
import WriteInfoBackBanner from '../../../../PersonOmniView/MiddleContainer/CareTimeline/components/WriteInfoBackBanner';

const GroupDetailsDrawer = (props: {onCancel: () => void; ruleId: string}) => {
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const intl = useIntl();
  const [showMemberList, setShowMemberList] = useState<boolean>(false);

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '60%' : '30%';
  const onCancelMemberDrawer = () => {
    setShowMemberList(false);
  };

  const {
    accountUsersMappedById,
    allDataMappedById,
    monthMappedData,
    handleActions,
    showMoreData,
    selectedFilters,
    loaders,
    ruleMemberData,
  } = useGroupDetailsAudit(props.ruleId);

  const renderSingleActivityCard = ({item, index}: any) => {
    const listData = monthMappedData;
    const auditLogs = listData[item];

    return (
      <>
        <HStack
          key={index}
          style={{
            justifyContent: 'space-between',
            marginTop: 0,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              lineHeight: 21,
              fontWeight: '600',
              color: 'rgb(102, 112, 133)',
              padding: 4,
              marginLeft: 17,
              marginTop: 10,
              textTransform: 'uppercase',
              letterSpacing: 2,
              fontFamily: 'Manrope',
            }}
          >
            {item}
          </Text>
        </HStack>
        <VStack>
          {auditLogs.map((auditLogData, index: number) => {
            const resourceName =
              allDataMappedById?.[auditLogData.parentResourceId]?.name;
            const subTitleTextLoading =
              !resourceName &&
              auditLogData?.parentResourceTypeCode !==
                ActionTypeNames.RULE_CREATION &&
              auditLogData?.parentResourceTypeCode !==
                ActionTypeNames.RULE_UPDATED &&
              loaders.resourcesLoading;
            return (
              <AuditTimelineItemView
                auditLogData={auditLogData}
                actionPerformerName={
                  auditLogData.actionBy
                    ? accountUsersMappedById?.[auditLogData.actionBy]?.name
                    : ''
                }
                resourceName={resourceName}
                subTitleTextLoading={subTitleTextLoading}
              />
            );
          })}
        </VStack>
      </>
    );
  };

  return (
    <>
      {showMemberList && ruleMemberData && (
        <GroupDetailMemberListDrawer
          isDrawerVisible={showMemberList}
          onCancel={onCancelMemberDrawer}
          auditLogData={ruleMemberData}
        />
      )}
      <Drawer
        bodyStyle={{
          paddingTop: 0,
        }}
        // Uncomment when text is avaliable
        // footer={
        //   !loaders.intialDataLoading &&
        //   !loaders?.paginationLoading &&
        //   Object.keys(monthMappedData)?.length === 0 ? (
        //     <WriteInfoBackBanner
        //       message={intl.formatMessage({id: 'groupAuditMessage'})}
        //     />
        //   ) : (
        //     <></>
        //   )
        // }
        title={
          <ModalActionTitle
            title={'Activity Log'}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  props.onCancel();
                },
              },
            ]}
          />
        }
        open={true}
        width={'37%'}
        closable={false}
        onClose={() => {
          props.onCancel();
        }}
      >
        {loaders.intialDataLoading && <Skeleton active />}
        {!loaders.intialDataLoading && (
          <>
            <Stack
              direction="row"
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                borderColor: Colors.Custom.Gray200,
                borderWidth: 1,
                borderRadius: 12,
              }}
            >
              <HStack alignItems={'center'} space={2} paddingRight={2}>
                <Feather name="clock" size={17} color={Colors.Custom.Gray600} />
                <Text>{intl.formatMessage({id: 'last24hrs'})}</Text>
              </HStack>
              <View
                style={{
                  width: 2,
                  height: 50,
                  backgroundColor: Colors.Custom.Gray200,
                  alignSelf: 'center',
                  transform: [{rotate: '180deg'}],
                }}
              />
              <HStack alignItems={'center'} space={2} paddingRight={2}>
                <MemberAddedSvg />
                <Text color={Colors.FoldPixel.GRAY400} fontWeight={600}>
                  {intl.formatMessage({id: 'newlyAdded'})}
                </Text>
                <Badge
                  fontSize={12}
                  fontWeight={600}
                  backgroundColor={Colors.Custom.Gray100}
                  _text={{
                    color: '#344054',
                  }}
                  alignSelf="center"
                  rounded="full"
                  justifyContent={'center'}
                  paddingX={2}
                  paddingY={1}
                >
                  {ruleMemberData?.membersAddedCount || 0}
                </Badge>
              </HStack>
              <View
                style={{
                  width: 2,
                  height: 50,
                  backgroundColor: Colors.Custom.Gray200,
                  alignSelf: 'center',
                  transform: [{rotate: '180deg'}],
                }}
              />
              <HStack alignItems={'center'} space={2}>
                <MemberDroppedSvg />
                <Text color={Colors.FoldPixel.GRAY400} fontWeight={600}>
                  {intl.formatMessage({id: 'droppedOff'})}
                </Text>
                <Badge
                  fontSize={12}
                  fontWeight={600}
                  backgroundColor={Colors.Custom.Gray100}
                  _text={{
                    color: Colors.Custom.Gray700,
                  }}
                  alignSelf="center"
                  rounded="full"
                  justifyContent={'center'}
                  paddingX={2}
                  paddingY={1}
                >
                  {ruleMemberData?.membersDroppedCount || 0}
                </Badge>
              </HStack>
              <View
                style={{
                  width: 2,
                  height: 50,
                  backgroundColor: Colors.Custom.Gray200,
                  alignSelf: 'center',
                  transform: [{rotate: '180deg'}],
                }}
              />
              <HStack style={{paddingBottom: 4, paddingTop: 4}}>
                <Pressable
                  onPress={() => setShowMemberList(true)}
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    height={25}
                    fontSize={14}
                    lineHeight={21}
                    fontWeight={700}
                    fontFamily={'Manrope'}
                    color={Colors.Custom.PurpleColor}
                  >
                    View
                    <Feather
                      name="chevron-right"
                      style={{
                        marginLeft: 2,
                      }}
                      size={16}
                      color={Colors.Custom.PurpleColor}
                    />
                  </Text>
                </Pressable>
              </HStack>
            </Stack>
            <HStack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              overflow={'clip'}
            >
              <ModalActionAntSelect
                allowClear={false}
                leftMargin={'0'}
                showSearch={false}
                labelInValue={true}
                filterOption={false}
                value={selectedFilters?.parentResourceType}
                isRequired={false}
                style={{width: '45%'}}
                onChange={(value: any, data: {code: string; value: string}) => {
                  handleActions(
                    AUDIT_ACTION_CODES.CHANGE_RESOURCE_CODE_FILTER,
                    {
                      code: value?.value,
                      value: value?.label,
                    }
                  );
                }}
                data={parentResourceCodeFilters}
                optionProps={{
                  key: 'id',
                  value: 'code',
                  label: 'value',
                }}
                extraStyle={{flex: 1}}
              />
              <View marginBottom={'15px'}>
                <DateRangePicker
                  dateRange={{
                    from: getFormattedDate(
                      selectedFilters?.dateRange?.from,
                      DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                    ),
                    to: getFormattedDate(
                      selectedFilters?.dateRange?.to,
                      DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                    ),
                  }}
                  onDateRangeChange={(dateRange: DateRange) => {
                    const formattedDateRange = {
                      from: getDateObjectFromStringAndFormat(
                        dateRange?.from,
                        DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                      )?.toISOString(),
                      to: getEndOfDay(
                        getDateObjectFromStringAndFormat(
                          dateRange?.to,
                          DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                        )
                      ).toISOString(),
                    };
                    handleActions(
                      AUDIT_ACTION_CODES.CHANGE_DATE_FILTER,
                      formattedDateRange
                    );
                  }}
                  isDateRangeRestricted={true}
                  dateComponents={{
                    dateRange: 1,
                    timeUnit: TimeUnitValues.year,
                  }}
                ></DateRangePicker>
              </View>
            </HStack>
            <VStack maxHeight="85%" overflow={'scroll'}>
              <FlatList
                marginBottom={10}
                data={Object.keys(monthMappedData)}
                keyExtractor={(item, index) => {
                  return 'auditBox_' + item;
                }}
                renderItem={renderSingleActivityCard}
              />
              <HStack space={1}>
                {showMoreData &&
                  !(
                    loaders?.paginationLoading || loaders?.intialDataLoading
                  ) && (
                    <Text
                      color={Colors?.Custom?.mainPrimaryPurple}
                      fontSize={16}
                      fontWeight={'700'}
                      paddingTop={3}
                      onPress={() =>
                        handleActions(AUDIT_ACTION_CODES.SHOW_MORE)
                      }
                    >
                      {intl.formatMessage({id: 'loadMore'})}
                    </Text>
                  )}
              </HStack>
              {!loaders?.intialDataLoading && loaders?.paginationLoading && (
                <ActivityIndicator
                  size={'large'}
                  color={Colors.Custom.PrimaryColor}
                />
              )}
            </VStack>
          </>
        )}
        {!loaders.intialDataLoading &&
          !loaders?.paginationLoading &&
          Object.keys(monthMappedData)?.length === 0 && (
            <Center>
              <ClockIconForAudit />
              <Text
                color={Colors.Custom.Gray400}
                fontSize={16}
                fontWeight="500"
              >
                {intl.formatMessage({id: 'noHistoryCreated'})}
              </Text>
            </Center>
          )}
      </Drawer>
    </>
  );
};

export default GroupDetailsDrawer;
