import React, {useState} from 'react';
import {
  View,
  Text,
  Pressable
} from 'native-base';
import {useIntl} from 'react-intl';
import {IContact} from '../../../../../TeamInbox/Conversations/interfaces';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {DRILL_DOWN_RESOURCE_CODE} from '../../../EmployerUtils/EmployerConst';
import {getSingleContactDrillDownData} from '../../../../../../../services/AccountDashboard/AccountDashboardServices';
import DrillDownRowDataView from '../../DrillDownRowDataView';
import {Colors} from '../../../../../../../styles/Colors';
const ClaimCost = (props: {
  columnData: {
    contactUuid: string;
    totalClaimCost: number;
  };
  contactData: IContact;
  columnConfig: any;
  contactDrillDownData: any;
  isOnFirstView: boolean;
  filterData?: any;
  onColumnActionPerformed?: (actionCode: string, actionData?: any) => void;
}) => {
  const {
    columnData,
    contactData,
    onColumnActionPerformed
  } = props;
  const [state, setState] = useState({
    isOpenDetailView: false,
    selectedDrillDownRowData: {} as any,
    columnList: [] as any[],
    selectedContact: contactData as IContact,
  });


  const getSingleContactClaimsData = async () => {
    if (contactData?.uuid && onColumnActionPerformed) {
      onColumnActionPerformed(COMMON_ACTION_CODES.LOADING);
      const response = await getSingleContactDrillDownData({
        contactUuid: contactData?.uuid,
        resourceCode: DRILL_DOWN_RESOURCE_CODE.CLAIMS,
      }
      ).catch((err) => {
        onColumnActionPerformed(COMMON_ACTION_CODES.LOADING_COMPLETE);
      });
      onColumnActionPerformed(COMMON_ACTION_CODES.LOADING_COMPLETE);
      if (response?.data?.contactDrillDownData?.contactDrillDownDataList?.length
        && response?.data?.contactDrillDownData?.columnList?.length) {
        setState(prev => {
          return {
            ...prev,
            isOpenDetailView: true,
            selectedDrillDownRowData: response?.data?.contactDrillDownData?.contactDrillDownDataList[0],
            columnList: response?.data?.contactDrillDownData?.columnList || [],
            selectedContact: contactData as IContact
          }
        })
      }
    }
  }
  const intl = useIntl();

  const getClaimCost = () => {
    let claimCost = '';
    if (columnData?.totalClaimCost) {
      claimCost = intl.formatNumber(columnData?.totalClaimCost, {
        style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    }
    return claimCost;
  }

  return (
    <View
      style={{
        maxWidth: 180
      }}
    >
      <View>
        <Text>{getClaimCost()}</Text>
        {
          columnData?.totalClaimCost ?
            (
              <>
                <Pressable
                  style={{paddingHorizontal: 3, paddingVertical: 4}}
                  onPress={() => {
                    getSingleContactClaimsData();
                  }} >
                  <Text size='smBold' color={Colors.Custom.mainPrimaryPurple}>
                    View details
                  </Text>
                </Pressable>
                {state.isOpenDetailView ? (
                  <DrillDownRowDataView
                    onClose={() => {
                      setState(prev => {
                        return {
                          ...prev,
                          isOpenDetailView: false,
                          selectedDrillDownRowData: null,
                          selectedContact: {} as IContact
                        }
                      });
                    }}
                    columnList={state.columnList}
                    selectedContact={state.selectedContact}
                    selectedDrillDownRowData={state.selectedDrillDownRowData}
                  />
                ) : (
                  <></>
                )}
              </>
            )
            :
            (
              <View>
                <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} >
                  No Claim Cost
                </Text>
              </View>
            )
        }
      </View>
    </View>
  );
}
export default React.memo(ClaimCost);

