import { Popover, Tooltip } from 'antd';
import { Button, Divider, HStack, Pressable, VStack, Text } from 'native-base';
import { useState } from 'react';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../styles/Colors';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { IUser } from '../interfaces';
import { styles } from './ActionsViewStyles';
import { getTextConfigsByStatus } from './Helper';
import { default as AntIcon } from 'react-native-vector-icons/AntDesign';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { isBlockNonSSOFlow, isMasterAccount } from '../../../../../utils/commonUtils';

interface IActionsViewProps {
    record: IUser;
    actionFn: (actionCode: string, record: IUser) => {};
}
const TeamActionView = (props: IActionsViewProps) => {
    const { record, actionFn } = props;
    const isActive = record.accountUsers?.[0]?.isActive;
    const { icon, tooltipLabel } = getTextConfigsByStatus(isActive);
    const isInvited = record.isInvited;
    const [isOpen, setIsOpen] = useState(false);
    const isMasterAccountFlag = isMasterAccount()
    const hideResetPasswordButton = isBlockNonSSOFlow();

    const handleVisibleChange = (visible: any) => {
        setIsOpen(visible);
    };
    const content = (
        <VStack style={{ padding: 0, borderRadius: 16 }}>
             <Pressable
                onPress={() => {
                    setIsOpen(!isOpen);
                    actionFn('EDIT', record || ({} as IUser));
                }}
            >
                <HStack style={[styles.mainMenuContent, styles.firstMenu]}>
                    <Feather
                        name='edit-2'
                        size={16}
                        style={{
                            marginLeft: 2,
                            marginRight: 2,
                            fontSize: 14.29,
                            color: Colors.Custom.Gray600,
                            alignSelf: 'center',
                        }}
                    />
                    <Text style={styles.mainMenuTxt}>
                        <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY400}} textLocalId={'edit'} />
                    </Text>
                </HStack>
                <Divider my={1} style={styles.dividerStyle} />
            </Pressable>

            <Pressable
            style={{display: isMasterAccountFlag ? 'none' : 'flex'}}
                onPress={() => {
                    setIsOpen(!isOpen);
                    actionFn('CHAT', record || ({} as IUser));
                }}
            >
                <HStack style={[styles.mainMenuContent, styles.firstMenu]}>
                    <Feather
                        name='message-circle'
                        size={16}
                        style={{
                            marginLeft: 2,
                            marginRight: 2,
                            fontSize: 14.29,
                            color: Colors.Custom.Gray600,
                            alignSelf: 'center',
                        }}
                    />
                    <Text style={styles.mainMenuTxt}>
                        <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY400}} textLocalId={'instantChat'} />
                    </Text>
                </HStack>
                <Divider my={1} style={styles.dividerStyle} />
            </Pressable>

            <Pressable
            style={{display: isMasterAccountFlag ? 'none' : 'flex'}}
                onPress={() => {
                    setIsOpen(!isOpen);
                    actionFn('MEET', record || ({} as IUser));
                }}
            >
                <HStack style={[styles.mainMenuContent, styles.firstMenu, styles.lastMenu]}>
                    <Feather
                        name='video'
                        size={16}
                        style={{
                            marginLeft: 2,
                            marginRight: 2,
                            fontSize: 14.29,
                            color: Colors.Custom.Gray600,
                            alignSelf: 'center',
                        }}
                    />
                    <Text style={styles.mainMenuTxt}>
                        <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY400}} textLocalId={'instantMeet'} />
                    </Text>
                </HStack>
            </Pressable>
        </VStack>
    );

    if (isInvited) {
        return (
            <Button.Group>
                <Button
                    onPress={() => {
                        actionFn('RESEND_INVITE', record || ({} as IUser));
                    }}
                    leftIcon={
                        <Tooltip title="Resend Invite">
                            <Feather
                                name="mail"
                                size={17}
                                style={{
                                    marginLeft: 2,
                                    marginRight: 2,
                                    fontSize: 14.29,
                                    color: Colors.Custom.Gray600,
                                }}
                            />
                        </Tooltip>
                    }
                    style={{ backgroundColor: 'transparent', borderWidth: 0 }}
                />
                <Button
                    onPress={() => {
                        actionFn('CANCEL_INVITE', record || ({} as IUser));
                    }}
                    leftIcon={
                        <Tooltip title="Cancel Invite">
                            <MaterialCommunityIcon
                                name="account-cancel-outline"
                                size={17}
                                style={{
                                    marginLeft: 2,
                                    marginRight: 2,
                                    fontSize: 14.29,
                                    color: Colors.Custom.Gray600,
                                }}
                            />
                        </Tooltip>
                    }
                    style={{ backgroundColor: 'transparent', borderWidth: 0 }}
                />
            </Button.Group>
        );
    } else {
        return (
            <Button.Group>
              {hideResetPasswordButton ? (
                <></>
              ) : (
                <Button
                  onPress={() => {
                    actionFn('password', record || ({} as IUser));
                  }}
                  leftIcon={
                    <Tooltip title="Reset Password">
                      <Feather
                        name="user"
                        size={17}
                        style={{
                          marginLeft: 2,
                          marginRight: 2,
                          fontSize: 14.29,
                          color: Colors.Custom.Gray600,
                        }}
                      />
                    </Tooltip>
                  }
                  style={{backgroundColor: 'transparent', borderWidth: 0}}
                />
              )}
                <Button
                    onPress={() => {
                        actionFn('deactivate', record || ({} as IUser));
                    }}
                    leftIcon={
                        <Tooltip title={tooltipLabel}>
                            <AntIcon
                                name={icon}
                                size={17}
                                style={{
                                    marginLeft: 2,
                                    marginRight: 2,
                                    fontSize: 14.29,
                                    color: Colors.Custom.Gray600,
                                }}
                            />
                        </Tooltip>
                    }
                    style={{ backgroundColor: 'transparent', borderWidth: 0 }}
                />

             {isActive ? 
                <Popover
                    key={record?.id}
                    overlayInnerStyle={{ padding: 0, borderRadius: 16 }}
                    overlayStyle={{ padding: 0 }}
                    overlay={{ padding: 0 }}
                    style={{ padding: 0, borderRadius: 16 }}
                    placement={'left'}
                    trigger="click"
                    visible={isOpen}
                    onVisibleChange={handleVisibleChange}
                    content={content}
                >
                    <Button
                        onPress={() => {
                            setIsOpen(true);
                        }}
                        style={{
                            //border: 'none',
                            backgroundColor: 'transparent',
                        }}
                    >
                        <Feather
                            name="more-vertical"
                            style={{
                                marginLeft: 2,
                                marginRight: 2,
                                fontSize: 14.29,
                                color: Colors.Custom.Gray600,
                            }}
                        />
                    </Button>
                </Popover>
                : <></>
             }
    
            </Button.Group>
        );
    }
};

export default TeamActionView;