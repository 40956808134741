import {Table} from 'antd';
import {Spinner} from 'native-base';
import {useState} from 'react';
import {Dimensions,View} from 'react-native';
import {useNavigate, useParams} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import PageBodyContainer from '../../../../../../common/PageBodyContainer/PageBodyContainer';
import {TableWrapper} from '../../../../../../common/TableWrapper';
import {getQualifiedPatientList} from '../../../../HelperFiles/TablesColumnsView';
import {IQualityMeasuresPatientList} from '../../../../HelperFiles/interface';
import {MeasureCriteriaDetailDrawer} from '../MeasureCriteriaDetail/MeasureCriteriaDetailDrawer';
import {MIDDLE_CONTAINER_TABS} from '../../../../../../PersonOmniView/MiddleContainer/MiddleContainerConst';
import {ExecutionDate} from '../../../../ExecutionDateView';

const PatientList = (props: IQualityMeasuresPatientList) => {
  const {stateData, handlePageChange, data, patientType} = props;
  const [patientListState, setPatientListState] = useState({
    selectedItems: [] as any,
    selectedRowKey: [] as any,
    showMeasureCriteriaDetailDrawer: false,
    selectedMeasure: {} as any,
  })
  const {height} = Dimensions.get('window');
  const {contractId, qmId} = useParams();
  const finalHeight = height - 320;
  const navigate = useNavigate();

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch(actionCode) {
      case COMMON_ACTION_CODES.SHOW_REASON:
        setPatientListState((prev) => {
          return{
            ...prev,
            showMeasureCriteriaDetailDrawer: true,
            selectedMeasure: actionData,
          }
        })
        break;
      case COMMON_ACTION_CODES.CLOSE:
        setPatientListState((prev) => {
          return{
            ...prev,
            showMeasureCriteriaDetailDrawer: false,
            selectedMeasure: {}
          }
        })
        break;
      case COMMON_ACTION_CODES.OPEN:
        navigate(`/members/patient/${actionData?.contactData?.id}`,{
          state: {
            contactData: actionData?.contactData,
            middleContainerTab: MIDDLE_CONTAINER_TABS.ALERTS
          }
        })
        break;
      default:
        break;
    }
  }
  return (
    <>
      <PageBodyContainer>
        <ExecutionDate date={stateData?.executionDate} loading={stateData.executionDateLoading}/>
        <TableWrapper
          wrapperStyle={{alignItems: 'center'}}
          minHeight={230}
          pagination={{
            current: stateData?.currentPage,
            pageSize: stateData?.pageSize || 10,
            total: stateData?.total,
            onChange: (currentPage, currentPageSize) => {
              handlePageChange?.(currentPage, currentPageSize);
            },
          }}
          hideOnSinglePage={false}
        >
          <Table
            scroll={{x: 1000, y: finalHeight}}
            rowClassName={(record, index) => {
              let className = index % 2 == 0 ? 'table-row-light' : ''
              className = className + ' quality-measures-report'
              return className
            }
            }
            className="lead-list-table-view patient-list-view"
            columns={getQualifiedPatientList({memberType: patientType},onActionPerformed)}
            dataSource={data}
            pagination={false}
            loading={{
              spinning: stateData.loading,
              indicator: (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Spinner size={'lg'} />
                </View>
              ),
            }}
            rowSelection={undefined}
            rowKey={(record) => {
              return record.id;
            }}
          />
        </TableWrapper>
      </PageBodyContainer>
      {patientListState?.showMeasureCriteriaDetailDrawer && <MeasureCriteriaDetailDrawer
        selectedMeasureId={patientListState?.selectedMeasure?.id}
        isInverse={patientListState?.selectedMeasure?.inverse}
        onDrawerClose={onActionPerformed}
        criteriaType={patientType}
        qmMeasureEvidence={patientListState?.selectedMeasure?.qmMeasureEvidence}
      />}
    </>
  );
};

export default PatientList;
