import React from 'react';
import {ICommonSvgProps} from './interfaces';
import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';

const ClockCircleSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props.width || 16}
      height={props.height || 16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <G clip-path="url(#clip0_8177_281113)">
        <Path
          d="M8.00065 5.33325V7.99992L9.66732 9.66659M14.6673 7.99992C14.6673 11.6818 11.6825 14.6666 8.00065 14.6666C4.31875 14.6666 1.33398 11.6818 1.33398 7.99992C1.33398 4.31802 4.31875 1.33325 8.00065 1.33325C11.6825 1.33325 14.6673 4.31802 14.6673 7.99992Z"
          stroke={props?.customStrokeColor || '#6F7A90'}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_8177_281113">
          <Rect width="16" height="16" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default React.memo(ClockCircleSvg);
