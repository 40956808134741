import { CONTACT_TABLE_COLUMN_CODE } from "../../../../../../common/MemebersView/constant";

export const TCM_COLUMN_CODES = [
CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME,
CONTACT_TABLE_COLUMN_CODE.FOLD_SCORE,
CONTACT_TABLE_COLUMN_CODE.RAF,
CONTACT_TABLE_COLUMN_CODE.LOCATION,
CONTACT_TABLE_COLUMN_CODE.COMPLEXITY,
CONTACT_TABLE_COLUMN_CODE.PCP,
CONTACT_TABLE_COLUMN_CODE.TCM_STATUS,
CONTACT_TABLE_COLUMN_CODE.ADMITTED_DATE,
CONTACT_TABLE_COLUMN_CODE.DISCHARGE_DATE,
CONTACT_TABLE_COLUMN_CODE.DISCHARGE_TO,
CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER,
CONTACT_TABLE_COLUMN_CODE.FACILITY,
CONTACT_TABLE_COLUMN_CODE.FACILITY_TYPE,
CONTACT_TABLE_COLUMN_CODE.NEXT_PCP_APPOINTMENT,
CONTACT_TABLE_COLUMN_CODE.CLIENT_ID,
CONTACT_TABLE_COLUMN_CODE.PRIMARY_DIAGNOSIS_CODE,
CONTACT_TABLE_COLUMN_CODE.TCM_ATTENDING_PHYSICIAN,
CONTACT_TABLE_COLUMN_CODE.DAYS_ELAPSED,
CONTACT_TABLE_COLUMN_CODE.FOLD_ID,
CONTACT_TABLE_COLUMN_CODE.ACTION
]

export const CCM_COLUMN_CODES = [
    CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME,
    CONTACT_TABLE_COLUMN_CODE.FOLD_SCORE,
    CONTACT_TABLE_COLUMN_CODE.RAF,
    CONTACT_TABLE_COLUMN_CODE.RISK,
    CONTACT_TABLE_COLUMN_CODE.CCM_STATUS,
    CONTACT_TABLE_COLUMN_CODE.FOLD_ID,
    CONTACT_TABLE_COLUMN_CODE.CONDITION,
    CONTACT_TABLE_COLUMN_CODE.TAGS,
    CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER,
    CONTACT_TABLE_COLUMN_CODE.TIME_SPENT,
    CONTACT_TABLE_COLUMN_CODE.CLIENT_ID,
    CONTACT_TABLE_COLUMN_CODE.ENROLLMENT,
    CONTACT_TABLE_COLUMN_CODE.CASE_MANAGEMENT_FLAGS,
    CONTACT_TABLE_COLUMN_CODE.CARE_MANAGEMENT_FLAGS,
    CONTACT_TABLE_COLUMN_CODE.NUMBER_OF_DIAGNOSIS_GAPS,
    CONTACT_TABLE_COLUMN_CODE.LOCATION,
    CONTACT_TABLE_COLUMN_CODE.PCP,
    CONTACT_TABLE_COLUMN_CODE.ACTION,
]

export const MY_PATIENT_COLUMN_CODES = [
    CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME,
    CONTACT_TABLE_COLUMN_CODE.FOLD_SCORE,
    CONTACT_TABLE_COLUMN_CODE.RAF,
    CONTACT_TABLE_COLUMN_CODE.LOCATION,
    CONTACT_TABLE_COLUMN_CODE.PCP,
    CONTACT_TABLE_COLUMN_CODE.CONDITION,
    CONTACT_TABLE_COLUMN_CODE.TAGS,
    CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER,
    CONTACT_TABLE_COLUMN_CODE.FOLD_ID,
    CONTACT_TABLE_COLUMN_CODE.NUMBER_OF_DIAGNOSIS_GAPS,
    CONTACT_TABLE_COLUMN_CODE.CASE_MANAGEMENT_FLAGS,
    CONTACT_TABLE_COLUMN_CODE.CARE_MANAGEMENT_FLAGS,
    CONTACT_TABLE_COLUMN_CODE.CLIENT_ID,
    CONTACT_TABLE_COLUMN_CODE.ACTION,
]

export const CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE = {
    CONTACT_CARE_PROGRAM: 'CONTACT_CARE_PROGRAM',
    CONTACT: 'CONTACT',
};

export const CARE_PROGRAM_EVENT_CODES = {
  BILLING_SELECTION_CHANGED: 'BILLING_SELECTION_CHANGED',
  COMPLEXITY_CHANGED: 'COMPLEXITY_CHANGED',
  AWV_TYPE_CHANGED: 'AWV_TYPE_CHANGED',
  BILLING_DATA_CHANGED: 'BILLING_DATA_CHANGED',
  STOP_TIMER: 'STOP_TIMER',
  SAVE_RECORDED_TIME: 'SAVE_RECORDED_TIME',
  LOGGED_TIME: 'LOGGED_TIME',
  OPEN_TIME_LOGGING_DRAWER: 'OPEN_TIME_LOGGING_DRAWER',
  CARE_PROGRAM_ACTION_TAKEN: 'CARE_PROGRAM_ACTION_TAKEN',
  GENERATE_BILLING_DATA: 'GENERATE_BILLING_DATA',
  SEND_CLAIM: 'SEND_CLAIM',
} as const;

export const BILLING_STATUS = {
  BILLED: 'Billed',
  PENDING: 'Pending'
} as const;
