import {useLazyQuery} from '@apollo/client';
import {Button, Drawer, Popover, Upload} from 'antd';
import moment from 'moment';
import {Divider, Input, Spinner, TextArea} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {
  ANALYTICS_RESOURCE,
  BUTTON_TYPE,
  DISPLAY_TYPE,
  SECTION_LIST,
} from '../../../../../constants';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {EmployerDashboard} from '../../../../../services';
import EmployerQueries from '../../../../../services/Employer/EmployerQueries';
import {getSectionDataByResourceCode} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {Colors} from '../../../../../styles';
import {
  getAccountName,
  getAccountUUID,
  getUserEmployerId,
  getUserUUID,
  isEmployerRole,
} from '../../../../../utils/commonUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import AnalyticsSections from '../AnalyticsSections';
import {WIDGET_ACTION_CODE, VIEW_CODE, EMPLOYER_CONST} from '../EmployerUtils/EmployerConst';
import {getFormattedDashboardPage} from '../EmployerUtils/FormateDataUtils';
import {isWidgetDataEmpty} from '../EmployerUtils/WidgetUtils';
import {
  IPageResponse,
  IPageSection,
  IPageView,
  IPrintPreview,
  ISectionListItem,
} from '../interface';
import {PrintPageFunction} from '../EmployerUtils/printUtils';
import {useNavigate} from 'react-router-dom';
import {getTenantForDemo} from '../tempDataFile';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import Feather from 'react-native-vector-icons/Feather';
import ColorPlates from './ColorPlates';
import CoverPageView from './CoverPageView';
import { SM_CONFIG } from '../../../../../theme/FontConfig/TextFontSizeConfig';

const PrintPreview = (props: IPrintPreview) => {
  const accountName = getAccountName();
  const {height, width} = Dimensions.get('window');
  const intl = useIntl();
  const employerId = getUserEmployerId() || props.employerId;
  const accountUuid = getAccountUUID();
  const screenWidth = Dimensions.get('window').width;
  const [isDownload, setIsDownload] = useState(false);
  const navigate = useNavigate();

  const [stateData, setStateData] = useState({
    sectionList: [] as ISectionListItem[],
    loading: false,
    pageResponse: {} as IPageView,
    reusableData: {
      employeeCount: null,
    } as any,
    employerData: {} as any,
    rawPageSections: ['a', 'c'] as IPageSection[] | any[],
    isDashboardAssign: false,
    dashboardLoading: false,
    drillDownData: {} as any,
    selectedView: '',
    selectedResourceCode: '',
    widgetCodeList: [] as any,
    widgetLoadedList: [] as any,
    previewBtnLoading: false,
    employerLogoSrc: '',
    clinicLogoSrc: '',
    coverPageBgColor: '',
    coverPageBgImage: 'url("https://i.imgur.com/dpewEWi.jpg")',
    openBgEditView: false
  });

  const [EmployerById] = useLazyQuery(EmployerQueries.getEmployerById, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const [GetDashboard] = useLazyQuery(
    EmployerDashboard.GetDashboardByEmployerId
  );

  const [GetDefaultPage] = useLazyQuery(
    EmployerDashboard.GetDefaultPageForDashboard
  );

  useEffect(() => {
    getEmployerById();
    getDashboardByEmployerId();
    getEmployeeCount();
  }, [employerId]);

  const handleWidgetLoaded = (actionCode: string, data?: any) => {
    const widgetLoadedList = stateData.widgetLoadedList;
    widgetLoadedList.push(data.resourceCode);
    setStateData((prev) => {
      return {
        ...prev,
        widgetLoadedList: widgetLoadedList,
      };
    });
    if (widgetLoadedList?.length == stateData?.widgetCodeList?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          previewBtnLoading: false,
        };
      });
    }
  };

  const getEmployeeCount = async () => {
    const response = await getSectionDataByResourceCode(
      employerId,
      ANALYTICS_RESOURCE.EMPLOYEE_COUNT || ''
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          reusableData: {},
        };
      });
    });
    if (response?.data && isWidgetDataEmpty(response?.data)) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          reusableData: {
            ...prev.reusableData,
            employeeCount: response.data?.totalCount || null,
          },
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          reusableData: {},
        };
      });
    }
  };

  const getEmployerById = async () => {
    const employerDataRes = await EmployerById({
      variables: {
        employerId: employerId,
      },
    });
    if (employerDataRes.data?.employers?.length) {
      const employerData = employerDataRes.data?.employers[0];
      setStateData((prev) => {
        return {
          ...prev,
          employerData: {...employerData},
        };
      });
    }
  };

  const getDashboardByEmployerId = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
        previewBtnLoading: true,
      };
    });
    const dashboardRes = await GetDashboard({
      variables: {
        employerId: employerId,
        accountUuid: accountUuid,
      },
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: {} as IPageView,
        };
      });
    });
    if (
      dashboardRes &&
      dashboardRes?.data?.employerDashboards[0]?.employerDashboards?.length
    ) {
      const dashboardPage: IPageResponse =
        dashboardRes?.data?.employerDashboards[0]?.employerDashboards[0].page;
      const formattedData = getFormattedDashboardPage(dashboardPage);
      const newFormattedDataForSaving = formattedData.sectionData.map(
        (data) => {
          if (data.sectionCategory === SECTION_LIST.SAVINGS) {
            const groupWidgetsList: ISectionListItem[] = [];
            const filteredChildSectionWidget = data.childSectionWidget.filter(
              (item) => {
                if (item.resourceCode == ANALYTICS_RESOURCE.TOTAL_SAVINGS) {
                  return true;
                } else {
                  groupWidgetsList.push(item);
                  return false;
                }
              }
            );
            const singleWidgetsGroup: ISectionListItem = {
              ...groupWidgetsList[0],
              name: 'Group saving',
              childSectionWidget: groupWidgetsList,
              resourceCode: ANALYTICS_RESOURCE.GROUP_SAVING,
              currentDisplayType: DISPLAY_TYPE.SAVING_GROUP_CUSTOM,
            };
            filteredChildSectionWidget.push(singleWidgetsGroup);
            data['childSectionWidget'] = filteredChildSectionWidget;
          }
          return data;
        }
      );
      formattedData.sectionData = newFormattedDataForSaving;
      const rawPageSections = dashboardPage.pageSections;
      const widgetCodeList: any = [];
      formattedData.sectionData.forEach((section) => {
        if (section?.childSectionWidget?.length && section.isVisible) {
          section?.childSectionWidget.forEach((widget) => {
            if (widget.childSectionWidget?.length && widget.isVisible) {
              widget.childSectionWidget?.forEach((childWidget) => {
                if (childWidget.resourceCode) {
                  widgetCodeList.push(childWidget.resourceCode);
                }
              });
            } else if (widget.resourceCode && widget.isVisible) {
              widgetCodeList.push(widget.resourceCode);
            }
          });
        }
      });
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: formattedData,
          sectionList: formattedData.sectionData,
          rawPageSections: rawPageSections,
          isDashboardAssign: true,
          widgetCodeList: widgetCodeList,
        };
      });
    } else {
      await getDefaultPageForDashboard().catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
    }
  };

  const getDefaultPageForDashboard = async () => {
    const defaultPageRes = await GetDefaultPage({
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: {} as IPageView,
        };
      });
    });
    if (defaultPageRes?.data?.pages?.length) {
      const defaultPage: IPageResponse[] = defaultPageRes?.data?.pages;
      const formattedData = getFormattedDashboardPage(defaultPage[0]);
      const rawPageSections = defaultPage[0].pageSections;
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: formattedData,
          sectionList: formattedData.sectionData,
          rawPageSections: rawPageSections,
          isDashboardAssign: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: {} as IPageView,
          sectionList: [],
          widgetCodeList: [],
        };
      });
    }
  };

  const btnStyle = {
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    fontSize: 14,
    cursor: stateData.previewBtnLoading ? 'not-allowed' : 'pointer',
  };
  return (
    <>
      <Drawer
        destroyOnClose
        placement="right"
        onClose={() => {
          props.onCancel?.();
        }}
        visible={props?.isVisible}
        closable={true}
        width={screenWidth * 0.65}
        bodyStyle={{
          padding: 0,
        }}
        title={
          <ModalActionTitle
            title={'employerReport'}
            titleColor={''}
            buttonList={[]}
            rightButton={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {isDownload ? (
                  <>
                    <div
                      style={{
                        ...btnStyle,
                        borderColor: Colors.secondary['200'],
                        color: Colors.secondary['800'],
                        backgroundColor: Colors.secondary['100'],
                        marginRight: 10,
                        ...SM_CONFIG.smNormal
                      }}
                      className="hideOnPrint"
                      onClick={async () => {
                        const html = await PrintPageFunction(stateData);
                        navigate(`/download-report`, {
                          state: {
                            html,
                          },
                        });
                      }}
                    >
                      {intl.formatMessage({id: 'download'})}
                    </div>
                    <div
                      style={{
                        ...btnStyle,
                        borderColor: Colors.secondary['200'],
                        color: Colors.secondary['800'],
                        backgroundColor: Colors.secondary['100'],
                        ...SM_CONFIG.smNormal,
                        marginRight: 10,
                      }}
                      className="hideOnPrint"
                      onClick={() => {
                        setIsDownload(false);
                      }}
                    >
                      {intl.formatMessage({id: 'edit'})}
                    </div>
                    <div
                      className="hideOnPrint"
                      style={{
                        ...btnStyle,
                        borderColor: Colors.secondary['200'],
                        color: Colors.secondary['800'],
                        backgroundColor: Colors.secondary['100'],
                        ...SM_CONFIG.smNormal,
                      }}
                      onClick={() => {
                        props.onCancel?.();
                      }}
                    >
                      {intl.formatMessage({id: 'close'})}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        ...btnStyle,
                        borderColor: Colors.secondary['200'],
                        color: stateData.previewBtnLoading
                          ? Colors.secondary['600']
                          : Colors.secondary['800'],
                        backgroundColor: stateData.previewBtnLoading
                          ? Colors.secondary['50']
                          : Colors.secondary['100'],
                        marginRight: 10,
                        ...SM_CONFIG.smNormal,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        if (stateData.previewBtnLoading) {
                          return;
                        }
                        setIsDownload(true);
                      }}
                    >
                      {stateData.previewBtnLoading && (
                        <Spinner
                          marginRight={'5px'}
                          size="sm"
                          color={Colors.secondary['800']}
                        />
                      )}
                      <div style={{marginLeft: 5}}>
                        {intl.formatMessage({id: 'previewReport'})}
                      </div>
                    </div>
                    <div
                      style={{
                        ...btnStyle,
                        borderColor: Colors.danger['200'],
                        color: Colors.danger['800'],
                        backgroundColor: Colors.danger['100'],
                        ...SM_CONFIG.smNormal,
                      }}
                      onClick={() => {
                        props?.onCancel?.();
                      }}
                    >
                      {intl.formatMessage({id: 'cancel'})}
                    </div>
                  </>
                )}
              </div>
            }
          />
        }
      >
        <div
          style={{
            overflow: 'scroll',
          }}
          id="printParent"
        >
          <CoverPageView {...{...props, isDownload: isDownload}} />
          <div
            className="main-print-content"
            style={{paddingLeft: 24, paddingRight: 24, paddingTop: 24}}
          >
            <AnalyticsSections
              memberSatisfactionFormList={props.memberSatisfactionFormList}
              sectionList={stateData.sectionList}
              employerData={stateData.employerData}
              employerId={employerId}
              dateRangeType={props.dateRangeType}
              isEmployerRole={isEmployerRole()}
              reusableData={stateData.reusableData}
              dateRange={props.dateRange}
              isPrintDownload={isDownload}
              isPrintPreview
              onWidgetLoaded={handleWidgetLoaded}
              onActionPerformed={(type?: any, index?: any, data?: any) => {
                if (type == WIDGET_ACTION_CODE.HIDE_SECTION) {
                  const sectionListDummy = stateData.sectionList.slice();
                  if (
                    data?.data?.currentDisplayType ===
                    DISPLAY_TYPE.SAVING_WIDGET
                  ) {
                    const groupSavingWidget =
                      sectionListDummy?.[data.index]?.childSectionWidget?.[
                        index
                      ];
                    groupSavingWidget.childSectionWidget.forEach((widget) => {
                      if (widget.resourceCode === data?.data?.resourceCode) {
                        widget.isDisabled = !widget.isDisabled;
                      }
                    });
                    sectionListDummy[data.index].childSectionWidget[index] =
                      groupSavingWidget;
                  } else {
                    const currentValue =
                      sectionListDummy?.[data.index]?.childSectionWidget?.[
                        index
                      ]?.isDisabled;
                    sectionListDummy[data.index].childSectionWidget[
                      index
                    ].isDisabled = !currentValue;
                  }
                  setStateData((prev) => ({
                    ...prev,
                    sectionList: [...sectionListDummy],
                  }));
                }
              }}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default React.memo(PrintPreview);
