import React, {useState} from 'react';
import RuleScoreTable from './RulesScoreTable';
import useRuleScore from './hooks/useRuleScore';
import {IAddOrUpdateDayOptimizerRuleFactorsParams, IDayOptimizerRuleFactor} from './common/interface';
import AddOrUpdateRuleScore from './AddOrUpdateRuleScore';

const RuleScore = () => {

  const {ruleScoreData, loading, pagination, onPaginationChange, onStatusChange, updateRuleFactor} =
    useRuleScore();

  const [stateData, setStateData] = useState({
    selectedRuleData: {} as IDayOptimizerRuleFactor,
    showRuleScoreDrawer: false,
  });

  const onHandleTableRowClick = (ruleScore: IDayOptimizerRuleFactor) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedRuleData: ruleScore,
        showRuleScoreDrawer: true,
      };
    });
  };

  const onDrawerClose = () => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedRuleData: {} as IDayOptimizerRuleFactor,
        showRuleScoreDrawer: false,
      };
    });
  };

  const onSubmit = async (ruleScoreData: IAddOrUpdateDayOptimizerRuleFactorsParams) => {
    await updateRuleFactor(ruleScoreData);
    onDrawerClose()
  };

  return (
    <>
      <RuleScoreTable
        ruleScoreData={ruleScoreData}
        loading={loading}
        pagination={pagination}
        onHandleTableRowClick={onHandleTableRowClick}
        onPageChange={onPaginationChange}
        onStatusChange={onStatusChange}
      />
      {stateData.showRuleScoreDrawer && (
        <AddOrUpdateRuleScore
          ruleScoreData={stateData.selectedRuleData}
          isOpen={stateData.showRuleScoreDrawer}
          onClose={onDrawerClose}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default RuleScore;
