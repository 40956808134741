import { Divider, Popover } from 'antd';
import { Menu, Pressable, Spinner } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import {
  DayOptimizerMenuItemKeys,
  getMenuItem,
  getMenuItems,
} from '../../DayOptimizerHelper';
import { DayOptimizerViewAction } from '../../DayOptimizerConstants';
import { cssPropertiesAntD, styles } from '../../style';
import { ILoadingAction } from '../../interfaces';
import Stack from '../../../../LayoutComponents/Stack';
import { Colors } from '../../../../../../styles/Colors';
import MenuDotsSvg from '../../../../Svg/DayOptimization/MenuDotsSvg';

const ThreeDotsActionPopover = (props: {
  record: any;
  onViewAction: (
    action: DayOptimizerViewAction | DayOptimizerMenuItemKeys,
    record: any,
    popover: {open: boolean; closePopover: () => void}
  ) => void;
  currentDate: Date;
  filterFlagList: any;
  loadingActions: ILoadingAction[]
}) => {
  const [open, setOpen] = React.useState(false);
  const closePopover = () => {
    setOpen(false);
  };

  return (
    <>
      <Popover
        overlayClassName="threeDotsPopoverStyle"
        placement={'bottomRight'}
        trigger="click"
        onOpenChange={setOpen}
        open={open}
        showArrow={false}
        overlayInnerStyle={cssPropertiesAntD.borderRadius8}
        content={
          <View style={styles.p8}>
            {getMenuItems(
              props?.record,
              props?.filterFlagList,
              props?.currentDate
            ).map((item) => {
              const isBorderSection =
                item.key === DayOptimizerMenuItemKeys.MARK_AS_COMPLETE;
              
              const isActionLoading = props.loadingActions.find((loadingAction)=> {
                return loadingAction.actionCode === item.key && loadingAction.contactId === props.record?.patientId
              })  
                
              return (
                <>
                  {isBorderSection && (
                    <Divider
                      type="horizontal"
                      style={cssPropertiesAntD.divider}
                    />
                  )}
                  <Menu.Item
                    key={item.key}
                    onPress={() =>
                      props?.onViewAction(item.key, props?.record, {
                        open,
                        closePopover,
                      })
                    }
                    borderRadius={4}
                    padding={'8px'}
                    _hover={
                      {
                        // backgroundColor: Colors.FoldPixel.GRAY100,
                      }
                    }
                  >
                    <Stack direction='row' style={{flex: 1, justifyContent: 'space-between'}}>
                      {getMenuItem(item, props?.record, props?.onViewAction, {
                        open,
                        closePopover,
                      })}
                      {isActionLoading && <Spinner/>}
                    </Stack>                   
                  </Menu.Item>
                </>
              );
            })}
          </View>
        }
      >
        <Pressable
          onPress={() => {
            setOpen((prev)=> !prev)
          }}
          style={styles.threeDotsActionPopover}
          _hover={{
            backgroundColor: Colors.FoldPixel.GRAY100
          }}
        >
          <MenuDotsSvg />
        </Pressable>
      </Popover>
    </>
  );
};

export default ThreeDotsActionPopover;
