import {gql} from '@apollo/client';
import BaseService from '../CommonService/BaseService';

export const GetLeads = gql`
  query GetLeads($searchString: String!, $typeCode: String!) {
    leads(
      where: {
        _or: [
          {person: {firstName: {_ilike: $searchString}}}
          {person: {lastName: {_ilike: $searchString}}}
          {person: {address: {zipcode: {code: {_ilike: $searchString}}}}}
          {person: {contact: {value: {_ilike: $searchString}}}}
        ]
        customer_type: {_or: {code: {_ilike: $typeCode}}}
      }
    ) {
      note
      id
      person {
        birthDate
        contact {
          id
          value
          typeId
        }
        firstName
        genderId
        id
        lastName
        middleName
        ssn
        address {
          cityId
          id
          line1
          line2
          stateId
          typeId
          zipcodeId
          zipcodes {
            code
            id
          }
          states {
            id
            name
            abbr
          }
          cities {
            id
            name
          }
        }
      }
      contactConsents {
        contactUuid
        consentId
      }
    }
  }
`;

export const CreateLeadGql = gql`
  mutation createLead(
    $firstName: String!
    $lastName: String!
    $note: String!
    $birthDate: timestamptz!
    $genderId: uuid!
    $cityId: uuid!
    $stateId: uuid!
    $zipCodeId: uuid!
    $emailContactTypeId: uuid!
    $email: String!
    $phoneContactTypeId: uuid!
    $phone: String!
    $customerTypeId: uuid!
  ) {
    createLead(
      object: {
        note: $note
        type_id: $customerTypeId
        person: {
          data: {
            birthDate: $birthDate
            firstName: $firstName
            genderId: $genderId
            lastName: $lastName
            address: {
              data: {
                line1: ""
                cityId: $cityId
                stateId: $stateId
                zipCodeId: $zipCodeId
              }
            }
            contact: {
              data: [
                {typeId: $emailContactTypeId, value: $email}
                {typeId: $phoneContactTypeId, value: $phone}
              ]
            }
          }
        }
      }
    ) {
      id
      person {
        id
        contact {
          id
        }
        address {
          id
        }
      }
    }
  }
`;

export const UpdateLeadGql = gql`
  mutation UpdateLead(
    $leadId: uuid!
    $note: String!
    $personId: uuid!
    $firstName: String!
    $lastName: String!
    $birthDate: timestamptz!
    $genderId: uuid!
    $personAddressId: uuid!
    $line1: String!
    $cityId: uuid!
    $stateId: uuid!
    $zipCodeId: uuid!
  ) {
    updateLead(pk_columns: {id: $leadId}, _set: {note: $note}) {
      id
    }
    updatePerson(
      pk_columns: {id: $personId}
      _set: {
        firstName: $firstName
        lastName: $lastName
        birthDate: $birthDate
        genderId: $genderId
      }
    ) {
      id
    }
    updatePersonAddress(
      pk_columns: {id: $personAddressId}
      _set: {
        cityId: $cityId
        line1: $line1
        stateId: $stateId
        zipCodeId: $zipCodeId
      }
    ) {
      id
    }
  }
`;

export const UpdatePersonContactGql = gql`
  mutation UpdatePersonContact($id: uuid!, $typeId: uuid!, $value: String!) {
    updatePersonContact(
      pk_columns: {id: $id}
      _set: {typeId: $typeId, value: $value}
    ) {
      id
    }
  }
`;

///=====

export const CreatePersonContact = gql`
  mutation CreatePersonContact(
    $accountId: Int!
    $personContactId: Int!
    $typeId: uuid!
    $value: String!
    $rank: Int!
  ) {
    createPersonContact(
      object: {
        accountId: $accountId
        personContactId: $personContactId
        typeId: $typeId
        value: $value
        rank: $rank
      }
    ) {
      accountId
      id
      typeId
      isPreferredCommunicationChannel
      value
      personContactId
    }
  }
`;

export const UpdatePersonContact = gql`
  mutation UpdatePersonContact($value: String!, $id: uuid!, $typeId: uuid!) {
    updatePersonContacts(
      where: {id: {_eq: $id}}
      _set: {value: $value, typeId: $typeId}
    ) {
      returning {
        id
        typeId
        value
        rank
      }
    }
  }
`;

export const UpdateContactPracticeLocation = gql`
  mutation UpdateContactPracticeLocation(
    $contactUuid: uuid!
    $practiceLocationUuid: uuid!
  ) {
    updateContactPracticeLocations(
      where: {contactUuid: {_eq: $contactUuid}}
      _set: {practiceLocationUuid: $practiceLocationUuid}
    ) {
      returning {
        id
        uuid
        contactUuid
        practiceLocationUuid
      }
    }
  }
`;

export const CreateContactPracticeLocation = gql`
  mutation MyMutation(
    $contactUuid: uuid!
    $practiceLocationUuid: uuid!
    $accountUuid: uuid!
  ) {
    createContactPracticeLocation(
      object: {
        contactUuid: $contactUuid
        practiceLocationUuid: $practiceLocationUuid
        accountUuid: $accountUuid
      }
    ) {
      contactUuid
      practiceLocationUuid
    }
  }
`;

export const UpdateContact = gql`
  mutation UpdateLead(
    $updateContactObj: contacts_set_input!
    $leadId: bigint!
  ) {
    updateContact(pk_columns: {id: $leadId}, _set: $updateContactObj) {
      email
      name
      phoneNumber
      id
    }
  }
`;

export const CreateAddress = gql`
  mutation createAddress($personAddressObj: person_addresses_insert_input!) {
    createPersonAddress(object: $personAddressObj) {
      cityId
      stateId
      zipcodeId
      id
    }
  }
`;
export const UpdatePersonAddress = gql`
  mutation updatePersonAdress(
    $personAddressId: uuid!
    $updateAddessObj: person_addresses_set_input
  ) {
    updatePersonAddresses(
      where: {id: {_eq: $personAddressId}}
      _set: $updateAddessObj
    ) {
      returning {
        cityId
        id
        stateId
        zipcodeId
      }
    }
  }
`;
export const GetContact = gql`
  query GetContact($id: bigint!) {
    contact(id: $id) {
      additionalAttributes
      contactAdditionalInfos {
        stripeCustomerId
      }
      contactProfilePhotos {
        attachmentId
      }
      contactLanguages {
        id
        languageCode
        preferred
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        accountLocation {
          uuid
        }
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      contactScore {
        foldScore
        hccV24
        hccV28
        reference
      }
      contactScoreAudits {
        scoreDate
        scoreType
        scoreValue
      }
      contactDeactivationInfo {
        isActive
      }
      isActive
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      contactType {
        contactType {
          code
          value
          id
          isDefault
          can_convert_to_patient
        }
        id
      }
      customAttributeValues {
        id
        customAttribute {
          id
          label
        }
        value
        booleanValue
        dateValue
      }
      contactScore {
        foldScore
        hccV24
        hccV28
        reference
      }
      contactScoreAudit {
        scoreDate
        scoreType
        scoreValue
      }
      personAddress {
        cityId
        stateId
        zipcodeId
        line1
        line2
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
          abbr
        }
        id
      }
      accountId
      uuid
      phoneNumber
      pubsubToken
      updatedAt
      source
      notes {
        contactId
        content
        userId
        id
      }
      name
      email
      id
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        firstName
        birthDate
        marriageAnniversaryDate
        id
        bloodGroup {
          value
          code
          id
        }
        chosenName
        pronounId
        gender {
          value
          code
        }
        genderId
        bloodGroupId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      contactUser {
        foldUsername
      }
      createdAt
      personContact {
        isVerified
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      lastActivityAt
      identifier
      workflowMetaData
      contactConsents {
        contactUuid
        consentId
        id
      }
      employees {
        employerId
        contactId
        employer {
          name
        }
      }
    }
  }
`;

export const GetContactAndSourceId = gql`
  query GetContactAndSourceId($id: bigint!, $conversationId: bigint) {
    contact(id: $id) {
     contactInboxes(where : {
      conversations: {
        id: {_eq: $conversationId}
      }
      }) {
        sourceId
      }
      additionalAttributes
      contactAdditionalInfos {
        stripeCustomerId
      }
      contactProfilePhotos {
        attachmentId
      }
      contactLanguages {
        id
        languageCode
        preferred
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        accountLocation {
          uuid
        }
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      contactDeactivationInfo {
        isActive
      }
      isActive
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      contactType {
        contactType {
          code
          value
          id
          isDefault
          can_convert_to_patient
        }
        id
      }
      customAttributeValues {
        id
        customAttribute {
          id
          label
        }
        value
        booleanValue
        dateValue
      }
      personAddress {
        cityId
        stateId
        zipcodeId
        line1
        line2
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
          abbr
        }
        id
      }
      accountId
      uuid
      phoneNumber
      pubsubToken
      updatedAt
      source
      notes {
        contactId
        content
        userId
        id
      }
      name
      email
      id
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        firstName
        birthDate
        marriageAnniversaryDate
        id
        bloodGroup {
          value
          code
          id
        }
        chosenName
        pronounId
        gender {
          value
          code
        }
        genderId
        bloodGroupId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      contactUser {
        foldUsername
      }
      createdAt
      personContact {
        isVerified
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      lastActivityAt
      identifier
      workflowMetaData
      contactConsents {
        contactUuid
        consentId
        id
      }
      employees {
        employerId
        contactId
        employer {
          name
        }
      }
    }
  }
`;

export const GetContactForMessageHeader = gql`
  query GetContact($id: bigint!) {
    contact(id: $id) {
      additionalAttributes
      id
      uuid
      name
      phoneNumber
      patient{
        patientId
      }
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        practiceLocationUuid
      }
    }
  }
`;

export const GetContacts = gql`
  query GetContacts(
    $limit: Int
    $offset: Int
    $order_by: [contacts_order_by!]! = {
      name: asc
      email: null
      phoneNumber: null
    }
    $contact_filters: contacts_bool_exp
  ) {
    contacts(
      order_by: $order_by
      where: $contact_filters
      limit: $limit
      offset: $offset
    ) {
      contactConsents {
        consentId
        contactUuid
      }
      contactAdditionalInfos {
        stripeCustomerId
      }
      contactDeactivationInfo {
        isActive
      }
      isActive
      additionalAttributes
      contactLanguages {
        id
        languageCode
        preferred
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      isDeleted
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      accountId
      phoneNumber
      pubsubToken
      updatedAt
      name
      email
      id
      uuid
      createdAt
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      contactProfilePhotos {
        attachmentId
      }
      person {
        firstName
        birthDate
        id
        bloodGroupId
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
        chosenName
        pronounId
      }
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      lastActivityAt
      identifier
      workflowMetaData
      employees {
        employerId
        contactId
        employer {
          name
        }
      }
    }
    contactAggregate(where: $contact_filters) {
      aggregate {
        count
      }
    }
  }
`;

export const GetVisitorContacts = gql`
  query GetVisitorContacts(
    $limit: Int!
    $offset: Int!
    $order_by: [visitor_contact_view_order_by!]! = {
      name: asc
      email: null
      phoneNumber: null
    }
    $visitor_contact_filters: visitor_contact_view_bool_exp
  ) {
    visitorContactView(
      order_by: $order_by
      where: $visitor_contact_filters
      limit: $limit
      offset: $offset
    ) {
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      uuid
      personAddress {
        cityId
        stateId
        zipcodeId
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
          abbr
        }
        id
      }
      accountId
      phoneNumber
      pubsubToken
      updatedAt
      name
      email
      id
      notes {
        contactId
        content
        userId
        id
      }
      createdAt
      personContact {
        personContactId
        rank
        value
        id
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      person {
        firstName
        birthDate
        id
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      patient {
        patientId
        patientUuid
        id
      }
      lastActivityAt
      identifier
    }
    visitor_contact_view_aggregate(
      # where: {name: {_like: $searchString}}
      where: $visitor_contact_filters
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const CreateContact = gql`
  mutation createContact($contactObj: contacts_insert_input!) {
    createContact(object: $contactObj) {
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      personAddress {
        cityId
        stateId
        zipcodeId
      }
      notes {
        contactId
        content
        userId
      }
      accountId
      phoneNumber
      pubsubToken
      updatedAt
      name
      email
      id
      createdAt
      contactDeactivationInfo {
        isActive
      }
      isActive
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      personContact {
        personContactId
        rank
        value
        id
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
      }
    }
  }
`;

export const SearchContacts = gql`
  query SearchContacts(
    $searchString: String
    $limit: Int
    $contactId: bigint
    $where: contacts_bool_exp
  ) {
    contacts(
      order_by: {updatedAt: desc}
      where: $where
      limit: $limit
    ) {
      contactConsents {
      consentId
      id
    }
    isActive
    additionalAttributes
    email
    id
    name
    phoneNumber
    relationshipWith {
      isDeleted
      contactId
    }
    uuid
    contactType {
      contactType {
        value
        code
        id
      }
    }
    patient {
      patientUuid
      patientId
    }
    person {
      chosenName
      pronounId
      firstName
      lastName
      birthDate
      bloodGroup {
        value
        code
        id
      }
      gender {
        code
        id
      }
      birthSexId
      sexAtBirth {
        value
        code
        id
      }
    }
    contactDeactivationInfo {
      isActive
    }
    contactTagging {
      labels {
        color
        id
        title
        description
      }
    }
    }
  }
`;

export const SearchContacts2 = gql`
  query SearchContacts2($searchString: String, $limit: Int) {
    contacts(
      where: {
        name: {_ilike: $searchString}
        contactType: {typeId: {_eq: "76d88a40-2db7-4859-9f97-ae919fee51de"}}
      }
    ) {
      additionalAttributes
      email
      id
      name
      phoneNumber
    }
  }
`;

export const SearchContacts3 = gql`
  query SearchContacts($searchString: String, $limit: Int,$where: contacts_bool_exp) {
    contacts(
      where: $where
      limit: $limit
    ) {
      contactConsents {
        id
        consentId
      }
      additionalAttributes
      isActive
      email
      id
      name
      phoneNumber
      relationshipWith {
        isDeleted
        contactId
      }
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientUuid
        patientId
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      contactDeactivationInfo {
        isActive
      }
      contactTagging {
        labels {
          color
          id
          title
          description
        }
      }
    }
  }
`;

export const CreateContactPerson = gql`
  mutation CreateContactPerson(
    $contactPersonObj: contact_persons_insert_input!
  ) {
    createContactPerson(object: $contactPersonObj) {
      id
      genderId
      lastName
      middleName
      contactId
      birthDate
      accountId
    }
  }
`;

export const UpdateContactPerson = gql`
  mutation MyMutation(
    $updateObj: contact_persons_set_input!
    $whereCondition: contact_persons_bool_exp!
  ) {
    updateContactPersons(where: $whereCondition, _set: $updateObj) {
      returning {
        lastName
      }
    }
  }
`;

export const CreateContactType = gql`
  mutation CreateContactType($contactId: Int!, $typeId: uuid!) {
    createContactType(object: {contactId: $contactId, typeId: $typeId}) {
      typeId
      id
      contactType {
        value
      }
    }
  }
`;

export const DeactivateContact = gql`
  mutation createContactDeactivationReason(
    $accountUuid: uuid!
    $contactUuid: uuid!
    $isActive: Boolean!
    $reason: String
  ) {
    createContactDeactivationInfo(
      object: {
        accountUuid: $accountUuid
        contactUuid: $contactUuid
        isActive: $isActive
        reason: $reason
      }
    ) {
      id
    }
  }
`;

export const DeactivateContactData = gql`
  mutation MyMutation($contactUuid: uuid!, $isActive: Boolean!) {
    updateContacts(
      _set: {isActive: $isActive}
      where: {uuid: {_eq: $contactUuid}}
    ) {
      returning {
        id
      }
    }
  }
`;

export const DeleteContact = gql`
  mutation MyMutation($id: bigint!) {
    updateContact(pk_columns: {id: $id}, _set: {isDeleted: true}) {
      id
      name
      email
      isDeleted
    }
  }
`;

export const GetSupportingMemeber = gql`
  query GetSupportingMemeber(
    $limit: Int!
    $offset: Int!
    $whereObj: contact_relations_bool_exp
  ) {
    contactRelations(
      limit: $limit
      offset: $offset
      distinct_on: relationContactId
      where: $whereObj
    ) {
      createdAt
      contactId
      id
      relationContactId
      relationId
      realatedContact {
        uuid
        isDeleted
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        personAddress {
          cityId
          stateId
          zipcodeId
          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
            abbr
          }
          id
        }
        accountId
        notes {
          contactId
          content
          userId
          id
        }
        phoneNumber
        pubsubToken
        updatedAt
        name
        email
        id
        contactDeactivationInfo {
          isActive
        }
        isActive
        contactPreferredCommunicationChannel {
          preferredCommunicationModeId
          id
          preferredCommunicationModeMlov {
            code
            value
          }
        }
        createdAt
        personContact {
          personContactId
          rank
          value
          id
          personContactType {
            code
            accountId
            value
            id
            categoryId
          }
          typeId
        }
        patient {
          patientId
          patientUuid
          id
        }
        person {
          firstName
          birthDate
          id
          bloodGroup {
            value
            code
            id
          }
          chosenName
          pronounId
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        lastActivityAt
        identifier
      }
    }
    contactRelationsAggregate(
      distinct_on: relationContactId
      where: $whereObj
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getContactsbyUuid = gql`
  query getContactsbyUuid($whereObj: contacts_bool_exp) {
    contacts(where: $whereObj) {
      id
      name
      email
      uuid
    }
  }
`;

export const GetContactsForGroup = gql`
  query GetContactsForGroup(
    $typeId: uuid!
    $isDeleted: Boolean!
    $searchString: String
    $searchConditions: [contacts_bool_exp!]
  ) {
    contacts(
      where: {
        _or: $searchConditions
        contactType: {typeId: {_eq: $typeId}}
        isDeleted: {_eq: $isDeleted}
      }
    ) {
      additionalAttributes
      uuid
      phoneNumber
      name
      id
      email
      accountId
      contactType {
        contactType {
          code
          id
        }
      }
      contactConsents {
        contactUuid
        consentId
      }
      personContact {
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
    }
  }
`;

export const GetContactsForSMSInboxCreate = gql`
  query GetContactsForGroup(
    $typeId: uuid!
    $isDeleted: Boolean!
    $searchString: String
    $searchConditions: [contacts_bool_exp!]
  ) {
    contacts(
      where: {
        _or: $searchConditions
        contactType: {typeId: {_eq: $typeId}}
        isDeleted: {_eq: $isDeleted}
        phoneNumber: {_neq: ""}
      }
    ) {
      additionalAttributes
      email
      id
      name
      phoneNumber
      relationshipWith {
        isDeleted
        contactId
      }
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientUuid
        patientId
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      contactConsents {
        contactUuid
        consentId
      }
      personContact {
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
    }
  }
`;

export const GetContactsForEmailInboxCreate = gql`
  query GetContactsForGroup(
    $typeId: uuid!
    $isDeleted: Boolean!
    $searchString: String
  ) {
    contacts(
      where: {
        _or: [{name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}]
        contactType: {typeId: {_eq: $typeId}}
        isDeleted: {_eq: $isDeleted}
        email: {_neq: ""}
      }
    ) {
      additionalAttributes
      uuid
      phoneNumber
      name
      id
      email
      accountId
      contactType {
        contactType {
          code
          id
        }
      }
      contactConsents {
        contactUuid
        consentId
      }
      personContact {
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
    }
  }
`;

export const SingleCreateContactQuery = gql`
  mutation SingleCreateContactQuery($object: contact_persons_insert_input!) {
    createContactPerson(object: $object) {
      contact {
        id
        contactPracticeLocations(
          where: {practiceLocationUuid: {_is_null: false}}
        ) {
          uuid
          practiceLocationUuid
          id
          contactUuid
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        contactDeactivationInfo {
          isActive
        }
        isActive
        contactPreferredCommunicationChannel {
          preferredCommunicationModeId
          id
          preferredCommunicationModeMlov {
            code
            value
          }
        }
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        personAddress {
          cityId
          stateId
          zipcodeId

          cities {
            name
            id
          }
          zipcodes {
            code
            id
          }
          states {
            name
            id
            abbr
          }
          id
        }
        accountId
        uuid
        phoneNumber
        pubsubToken
        updatedAt
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          firstName
          birthDate
          chosenName
          pronounId
          id
          bloodGroup {
            value
            code
            id
          }
          gender {
            value
          }
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        createdAt
        personContact {
          personContactId
          value
          id
          rank
          isPreferredCommunicationChannel
          personContactType {
            code
            accountId
            value
            id
            categoryId
          }
          typeId
        }
        lastActivityAt
        identifier
      }
    }
  }
`;

export const GetContactByEmail = gql`
  query GetContactByEmail($where: contacts_bool_exp!) {
    contacts(where: $where) {
      id
      createdAt
      contactType {
        contactType {
          code
          id
        }
      }
      contactConsents {
        contactUuid
        consentId
      }
    }
  }
`;

export const UpdateWorkflowMetaData = gql`
  mutation updateMetadata($id: bigint, $workflowListObj: jsonb) {
    updateContacts(
      where: {id: {_eq: $id}}
      _set: {workflowMetaData: $workflowListObj}
    ) {
      returning {
        id
      }
    }
  }
`;

export const GetContactNameByPhoneNumber = gql`
  query GetContactNameByPhoneNumber($phoneNumber: String!) {
    contacts(
      where: {phoneNumber: {_ilike: $phoneNumber}}
      order_by: {updatedAt: desc}
    ) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      additionalAttributes
      contactLanguages {
        id
        languageCode
        preferred
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      personAddress {
        cityId
        stateId
        zipcodeId
        line1
        line2
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
          abbr
        }
        id
      }
      accountId
      uuid
      phoneNumber
      pubsubToken
      updatedAt
      source
      notes {
        contactId
        content
        userId
        id
      }
      name
      email
      id
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        firstName
        birthDate
        chosenName
        pronounId
        id
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      createdAt
      personContact {
        personContactId
        rank
        value
        id
        personContactType {
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      lastActivityAt
      identifier
      workflowMetaData
      contactConsents {
        contactUuid
        consentId
      }
      employees {
        employerId
        contactId
        employer {
          name
        }
      }
    }
  }
`;

export const UpdateContactLanguages = gql`
  mutation UpdateContactLanguages($code: String, $id: uuid, $contactId: Int) {
    updateContactLanguages(
      _set: {languageCode: $code}
      where: {id: {_eq: $id}, contactId: {_eq: $contactId}}
    ) {
      affected_rows
    }
  }
`;
export const dfaultQuery = gql`
  query GetContacts(
    $limit: Int
    $offset: Int
    $order_by: [contacts_order_by!]! = {
      name: asc
      email: null
      phoneNumber: null
    }
    $contact_filters: contacts_bool_exp
  ) {
    contacts(
      order_by: $order_by
      where: $contact_filters
      limit: $limit
      offset: $offset
    ) {
      contactAdditionalInfos {
        stripeCustomerId
      }
      contactDeactivationInfo {
        isActive
      }
      isActive
      additionalAttributes
      contactLanguages {
        id
        languageCode
        preferred
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      isDeleted
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      personAddress {
        cityId
        stateId
        zipcodeId
        line1
        line2
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
          abbr
        }
        id
      }
      accountId
      notes {
        contactId
        content
        userId
        id
      }
      phoneNumber
      pubsubToken
      updatedAt
      name
      email
      id
      uuid
      createdAt
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      personContact {
        personContactId
        rank
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        firstName
        birthDate
        chosenName
        pronounId
        id
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      lastActivityAt
      identifier
      workflowMetaData
      contactConsents {
        contactUuid
        consentId
      }
      employees {
        employerId
        contactId
        employer {
          name
        }
      }
    }
    contactAggregate(where: $contact_filters) {
      aggregate {
        count
      }
    }
  }
`;

export const CreateContactLanguages = gql`
  mutation CreateContactLanguages(
    $accountUuid: uuid
    $contactId: Int
    $code: String
  ) {
    createContactLanguages(
      objects: {
        languageCode: $code
        contactId: $contactId
        accountUuid: $accountUuid
      }
    ) {
      affected_rows
    }
  }
`;

export const searchContactsByRuleId = gql`
  query getContactsByRuleId($params: SearchContactsInput!) {
    searchContacts(params: $params) {
      contacts {
        contactDeactivationInfo {
          isActive
        }
        isActive
        additionalAttributes
        contactLanguages {
          id
          languageCode
          preferred
        }
        contactPracticeLocations{
          uuid
          practiceLocationUuid
          id
          contactUuid
          accountLocation {
            uuid
          }
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        isDeleted
        contactType {
          contactType {
            code
            value
            id
          }
          id
        }
        accountId
        phoneNumber
        pubsubToken
        updatedAt
        name
        email
        id
        uuid
        createdAt
        contactPreferredCommunicationChannel {
          preferredCommunicationModeId
          id
          preferredCommunicationModeMlov {
            code
            value
          }
        }
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          firstName
          birthDate
          id
          gender {
            value
            code
          }
          chosenName
          pronounId
          genderId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        lastActivityAt
        identifier
        workflowMetaData
        employees {
          employerId
          contactId
          employer {
            name
          }
        }
      }
      contactAggregate {
        aggregate {
        count
        }
      }
    }
  }
`;

export const GetContactAggregateCountByRuleId = gql`
  query GetContactAggregateCountByRuleId($params: SearchContactsInput!) {
    searchContacts(params: $params) {
      contactAggregate {
        aggregate {
        count
        }
      }
    }
  }
`;

  export const searchContacts = gql`
  query GET_SEARCH_CONTACTS($params: SearchContactsInput!) {
    searchContacts(params: $params) {
    contacts {
      contactConsents {
        contactUuid
        consentId
      }
      contactAdditionalInfos {
        stripeCustomerId
      }
      contactDeactivationInfo {
        isActive
      }
      isActive
      additionalAttributes
      contactLanguages {
        id
        languageCode
        preferred
      }
      contactPracticeLocations {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
        accountLocation {
          uuid
        }
      }
      isDeleted
      contactType {
        contactType {
          code
          value
          id
          isDefault
        }
        id
      }
      accountId
      phoneNumber
      pubsubToken
      updatedAt
      name
      email
      id
      uuid
      createdAt
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      contactProfilePhotos {
        attachmentId
      }
      person {
        firstName
        birthDate
        id
        bloodGroupId
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
        chosenName
        pronounId
      }
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      lastActivityAt
      identifier
      workflowMetaData
      employees {
        employerId
        contactId
        employer {
          name
        }
        type
      }
      customAttributeValues {
        id
        customAttribute {
          id
          label
        }
        value
        booleanValue
        dateValue
      }
      contactScore {
        foldScore
        hccV24
        hccV28
        reference
      }
      contactScoreAudit {
        scoreDate
        scoreType
        scoreValue
      }
      dayOptimizerUserSchedules {
        id
        userUuid
        date
        contactUuid
        statusId
        dayOptimizerRuleScoreLog{
          score
          scoreByRuleFactors
          }
        }
    }
    contactAggregate {
      aggregate {
        count
      }
    }
  }
}
`;

export const searchDayOptimizerPatients = gql`
  query GET_SEARCH_CONTACTS($params: SearchContactsInput!) {
    searchContacts(params: $params) {
      contacts {
        dayOptimizerUserSchedules {
        id
        userUuid
        date
        contactUuid
        statusId
        dayOptimizerRuleScoreLog{
          score
          scoreByRuleFactors
          }
        }
        contactDeactivationInfo {
          isActive
        }
        id
        additionalAttributes
        isActive
        name
        email
        uuid
        phoneNumber
        additionalAttributes
        contactTagging {
          labels {
            title
            color
            id
            description
          }
        }
        contactType {
          contactType {
            value
            code
            id
          }
        }
        contactScore {
          foldScore
          hccV24
          hccV28
          reference
        }
        contactScoreAudit {
          scoreValue
          scoreDate
          scoreType
        }
        patient {
          patientId
          patientUuid
        }
        person {
          chosenName
          pronounId
          firstName
          lastName
          birthDate
          bloodGroup {
            value
            code
            id
          }
          gender {
            code
            id
          }
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
        }
        contactPracticeLocations {
          uuid
          practiceLocationUuid
          id
          contactUuid
          practiceLocation {
            uuid
            name
            practiceCity {
              name
              id
            }
          }
          accountLocation {
            uuid
          }
        }
      }
      contactAggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const contactAggregateOnLocation = gql`
  query GET_SEARCH_CONTACTS($params: SearchContactsInput!) {
    searchContacts(params: $params) {
      contactAggregate {
        aggregate {
          count
        }
      }
    }
  }
`;


export const getContactDataFromAPI = async (
  limit: number,
  offset: number,
  contact_filters: any,
  filteredInfo?: any,
  orderBy?: any,
) => {
  const axios = BaseService.getSharedInstance().axios;
  let variables: any = {
    order_by: orderBy || {
      updatedAt: 'desc',
    },
  }
  // if (sorter?.columnKey == 'contactData') {
  //   variables = {
  //     ...variables,
  //     order_by: {
  //       ...variables.order_by,
  //       name:
  //         sorter?.order == 'ascend'
  //           ? 'asc'
  //           : sorter?.order == 'descend'
  //           ? 'desc'
  //           : sorter?.order,
  //     },
  //   };
  // }
  if (filteredInfo?.contactData?.length) {
    if (filteredInfo?.contactData[0]?.includes('name')) {
      variables = {
        order_by: {
          name: filteredInfo?.contactData[0]?.includes('asc')
            ? 'asc'
            : filteredInfo?.contactData[0]?.includes('desc')
              ? 'desc'
              : '',
        },
      };
    } else if (filteredInfo?.contactData[0]?.includes('created')) {
      variables = {
        order_by: {
          createdAt: filteredInfo?.contactData[0]?.includes('asc')
            ? 'asc'
            : filteredInfo?.contactData[0]?.includes('desc')
              ? 'desc'
              : '',
        },
      };
    } else if (filteredInfo?.contactData[0]?.includes('updated')) {
      variables = {
        order_by: {
          updatedAt: filteredInfo?.contactData[0]?.includes('asc')
            ? 'asc'
            : filteredInfo?.contactData[0]?.includes('desc')
              ? 'desc'
              : '',
        },
      };
    }
  }
  const body = {
    operationName: 'GetContacts',
    operationCode: 'GetContactsWithSubscriptionData',
    variables: {
      ...variables,
      limit: limit,
      offset: offset,
      contact_filters: contact_filters,
    },
    query: `
      query GetContacts(
        $limit: Int
        $offset: Int
        $order_by: [contacts_order_by!]! = {
          name: asc
          email: null
          phoneNumber: null
        }
        $contact_filters: contacts_bool_exp
      ) {
        contacts(
          order_by: $order_by
          where: $contact_filters
          limit: $limit
          offset: $offset
        ) {
          contactConsents {
            contactUuid
            consentId
          }
          contactScore{
            hccV24
            hccV28
            foldScore
          }
          contactAdditionalInfos {
            stripeCustomerId
          }
          contactDeactivationInfo {
            isActive
          }
          isActive
          additionalAttributes
          contactLanguages {
            id
            languageCode
            preferred
          }
          contactPracticeLocations(
            where: {practiceLocationUuid: {_is_null: false}}
          ) {
            uuid
            practiceLocationUuid
            id
            contactUuid
            practiceLocation {
              name
              practiceCity {
                name
                id
              }
            }
            accountLocation {
              uuid
            }
          }
          isDeleted
          contactType {
            contactType {
              code
              value
              id
              isDefault
            }
            id
          }
          accountId
          phoneNumber
          pubsubToken
          updatedAt
          name
          email
          id
          uuid
          createdAt
          patient {
            patientId
            patientUuid
            annualVisitDate
            isWeeklyCheckin
            id
          }
          contactProfilePhotos {
            attachmentId
          }
          person {
            firstName
            birthDate
            id
            bloodGroupId
            gender {
              value
              code
            }
            genderId
            birthSexId
            sexAtBirth {
              value
              code
              id
            }
            contactId
            middleName
            lastName
            chosenName
            pronounId
          }
          contactPreferredCommunicationChannel {
            preferredCommunicationModeId
            id
            preferredCommunicationModeMlov {
              code
              value
            }
          }
          lastActivityAt
          identifier
          workflowMetaData
          employees {
            employerId
            contactId
            employer {
              name
            }
            type
          }
          customAttributeValues {
            id
            customAttribute {
              id
              label
            }
            value
            booleanValue
            dateValue
          }
           contactScore {
            hccV24
            hccV28
            foldScore
            reference
          }
          contactScoreAudits {
            scoreDate
            scoreType
            scoreValue
          }
        }
        contactAggregate(where: $contact_filters) {
          aggregate {
            count
          }
        }
      }
    `,
  };
  return await axios.post(`crm-nest/account/contact`, {
    ...body,
  });
};

export const GetContactRelationCountByContactId = gql`
  query GetContactRelationCountByContactId($contactId: Int) {
    contactRelationsAggregate(where: {contactId: {_eq: $contactId}}) {
      aggregate {
        count
      }
    }
  }
`;
export const CreateContactForInBoundCall = gql`
  mutation CreateContactForInBoundCall($object: contact_persons_insert_input!) {
    createContactPerson(object: $object) {
      id
    }
  }
`;

export const CreatePreferredChannel = gql`
  mutation CreatePreferredChannel(
    $accountUuid: uuid!
    $contactUuid: uuid!
    $preferredCommunicationModeId: uuid!
  ) {
    createContactPreferredCommunicationChannel(
      object: {
        accountUuid: $accountUuid
        contactUuid: $contactUuid
        preferredCommunicationModeId: $preferredCommunicationModeId
      }
    ) {
      id
    }
  }
`;

export const UpdatePreferredChannel = gql`
  mutation UpdatePreferredChannel(
    $id: uuid!
    $preferredCommunicationModeId: uuid!
    $contactUuid: uuid!
  ) {
    updateContactPreferredCommunicationChannels(
      where: {id: {_eq: $id}, contactUuid: {_eq: $contactUuid}}
      _set: {
        preferredCommunicationModeId: $preferredCommunicationModeId
        contactUuid: $contactUuid
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const GetContactsByFromDateToDate = gql`
  query getContactByBirthDate(
    $boolExpression: contacts_bool_exp
    $offset: Int
    $limit: Int
  ) {
    contacts(
      distinct_on: [id]
      order_by: {id: asc}
      offset: $offset
      limit: $limit
      where: $boolExpression
    ) {
      id
      name
      email
      uuid
      isActive
      person {
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
        }
        chosenName
        pronounId
        contactType {
          contactType {
            code
          }
        }
      }
    }
  }
`;

export const GetContactsByGenderCode = gql`
  query getContactByGenderCode(
    $boolExp: contacts_bool_exp
    $offset: Int
    $limit: Int
  ) {
    contacts(
      distinct_on: [id]
      order_by: {id: asc}
      offset: $offset
      limit: $limit
      where: $boolExp
    ) {
      id
      name
      uuid
      email
      isActive
      person {
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
        }
        chosenName
        pronounId
        contactType {
          contactType {
            code
          }
        }
      }
    }
  }
`;

export const getContactsByUuid = gql`
  query getContactsByUuid(
    $contactUuidList: [uuid!]!
    $limit: Int
    $offset: Int
  ) {
    contacts(
      where: {uuid: {_in: $contactUuidList}}
      distinct_on: uuid
      limit: $limit
      offset: $offset
    ) {
      additionalAttributes
      uuid
      name
      email
      contactConsents {
        contactUuid
        consentId
      }
      isActive
      id
      contactPracticeLocations {
        accountLocation {
          uuid
        }
      }
      phoneNumber
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      contactMemberships(order_by: {status: asc}) {
        id
        startDate
        endDate
        isDeleted
        status
        enrollmentStatus
      }
      patient {
        patientId
        patientUuid
      }
      person {
        sexAtBirth {
          code
          value
        }
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          value
        }
      }
    }
  }
`;

export const getContactsByIds = gql`
  query getContactsByIds(
    $contactIdList: [bigint!]!
    $limit: Int
    $offset: Int
  ) {
    contacts(
      where: {id: {_in: $contactIdList}}
      distinct_on: uuid
      limit: $limit
      offset: $offset
    ) {
      additionalAttributes
      uuid
      name
      email
      contactConsents {
        contactUuid
        consentId
      }
      isActive
      id
      phoneNumber
      patient {
        patientId
        patientUuid
      }
      person {
        sexAtBirth {
          code
          value
        }
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          value
        }
      }
    }
  }
`;

export const getContactDetailsByUuid = gql`
  query getContactsByUuid(
    $contactUuidList: [uuid!]!
  ) {
    contacts(
      where: {uuid: {_in: $contactUuidList}}
      distinct_on: uuid
    ) {
      additionalAttributes
      uuid
      name
      isActive
      id
      patient {
        patientId
        patientUuid
      }
      person {
        sexAtBirth {
          code
          value
        }
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        gender {
          code
          value
        }
      }
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      contactProfilePhotos {
        attachmentId
      }
    }
  }
`;

export const Get_Contact_By_Uuid = gql`
  query Get_Contact_By_Uuid($_or: [contacts_bool_exp!]!) {
    contacts(where: {_or: $_or}) {
      contactDeactivationInfo {
        isActive
      }
      isActive
      additionalAttributes
      contactLanguages {
        id
        languageCode
        preferred
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      isDeleted
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      personAddress {
        cityId
        stateId
        zipcodeId
        line1
        line2
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
          abbr
        }
        id
      }
      accountId
      notes {
        contactId
        content
        userId
        id
      }
      phoneNumber
      pubsubToken
      source
      updatedAt
      name
      email
      id
      uuid
      createdAt
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      personContact {
        personContactId
        rank
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        firstName
        birthDate
        id
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
          code
        }
        chosenName
        pronounId
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      lastActivityAt
      identifier
      workflowMetaData
      employees {
        employerId
        contactId
        employer {
          name
        }
      }
    }
  }
`;

export const GetContactsByIds = gql`
  query GetContactsByIds($ids: [bigint!]) {
    contacts(where: {id: {_in: $ids}}) {
      additionalAttributes
      contactProfilePhotos {
        attachmentId
      }
      contactLanguages {
        id
        languageCode
        preferred
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        accountLocation {
          uuid
        }
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      contactDeactivationInfo {
        isActive
      }
      isActive
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      personAddress {
        cityId
        stateId
        zipcodeId
        line1
        line2
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
        }
        id
      }
      accountId
      uuid
      phoneNumber
      pubsubToken
      updatedAt
      source
      notes {
        contactId
        content
        userId
        id
      }
      name
      email
      id
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        firstName
        birthDate
        id
        bloodGroup {
          value
          code
          id
        }
        chosenName
        pronounId
        gender {
          value
          code
        }
        genderId
        bloodGroupId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      createdAt
      personContact {
        rank
        isVerified
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
      lastActivityAt
      identifier
      workflowMetaData
      contactConsents {
        contactUuid
        consentId
      }
      employees {
        employerId
        contactId
        employer {
          name
        }
      }
    }
  }
`;

export const GetContactsForMeetingCreate = gql`
  query GetContactsForGroup(
    $typeId: uuid!
    $isDeleted: Boolean!
    $searchString: String
    $searchConditions: [contacts_bool_exp!]
  ) {
    contacts(
      where: {
        _or: $searchConditions
        contactType: {typeId: {_eq: $typeId}}
        isDeleted: {_eq: $isDeleted}
        email: {_neq: ""}
      }
    ) {
      additionalAttributes
      email
      id
      name
      phoneNumber
      relationshipWith {
        isDeleted
        contactId
      }
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientUuid
        patientId
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      contactConsents {
        contactUuid
        consentId
      }
      personContact {
        rank
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
    }
  }
`;

export const GetConsentsByContactIds = gql`
  query GetConsentsByContactIds($contactIds: uuid_comparison_exp!) {
    contactConsents(where: {contactUuid: $contactIds}) {
      contactUuid
      accountUuid
      consentId
      id
    }
  }
`;

export const GetContactTags = gql`
  query GetContactTags($contactIds: [Int!]) {
    labelTaggings(where: {taggableId: {_in: $contactIds}}) {
      taggableId
      labels {
        title
        color
        id
        description
      }
    }
  }
`;
export const GetContactLoginTimeStamp = gql`
  query GetContact($id: bigint!) {
    contact(id: $id) {
      id
      additionalAttributes
    }
  }
`;

export const getOrderedContactsByIds = gql`
  query getContactsByIds(
    $contactIdList: [bigint!]!
    $accountId: Int
    $limit: Int
    $offset: Int
    $order_by: [contacts_order_by!]
  ) {
    contacts(
      where: {accountId: {_eq: $accountId}, id: {_in: $contactIdList}}
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      additionalAttributes
      uuid
      name
      email
      isActive
      id
      phoneNumber
      patient {
        patientId
        patientUuid
      }
      person {
        sexAtBirth {
          code
          value
        }
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          value
        }
      }
    }
  }
`;

export const getBulkContactByUUID = gql`
  query getContactsByIds(
    $contactIdList: [uuid!]!
    $accountId: Int
    $limit: Int
    $offset: Int
    $order_by: [contacts_order_by!]
    $name: String
  ) {
    contactAggregate(
      where: {
        accountId: {_eq: $accountId}
        uuid: {_in: $contactIdList}
        name: {_ilike: $name}
      }
    ) {
      aggregate {
        count
      }
    }
    contacts(
      where: {
        accountId: {_eq: $accountId}
        uuid: {_in: $contactIdList}
        name: {_ilike:$name},
      }
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      additionalAttributes
      uuid
      accountId
      name
      email
      isActive
      id
      phoneNumber
      patient {
        patientId
        patientUuid
      }
      person {
        sexAtBirth {
          code
          value
        }
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          value
        }
      }
    }
  }
`;
export const getContactDataById = gql`
  query GetContact($id: bigint!) {
    contact(id: $id) {
      uuid
    }
  }
`;

export const getUserContactPracticeLocations = gql`
  query GetUserContactPracticeLocations($contactId: bigint!) {
    contacts(limit: 1, where: {id: {_eq: $contactId}}, order_by: {updatedAt: desc}) {
      contactPracticeLocations(order_by: {updatedAt: desc}) {
        accountLocation {
          uuid
        }
      }
    }
  }
`;

export const GetContactAccountPracticeLocation = gql`
query GetContact($id: bigint!) {
  contact(id: $id) {
    contactPracticeLocations(where: {practiceLocationUuid: {_is_null: false}}) {
      uuid
      practiceLocationUuid
      id
      contactUuid
      accountLocation {
        uuid
      }
      practiceLocation {
        name
        practiceCity {
          name
          id
        }
      }
    }
  }
}
`

export const GetContactsForGroupByLocationUuids = gql`
  query GetContactsForGroupByLocationUuids(
    $typeId: uuid!
    $isDeleted: Boolean!
    $searchString: String
    $locationUuids: [uuid!]
    $searchConditions: [contacts_bool_exp!]
  ) {
    contacts(
      where: {
        _or: $searchConditions
        contactType: {typeId: {_eq: $typeId}}
        isDeleted: {_eq: $isDeleted}
        phoneNumber: {_neq: ""}
        contactPracticeLocations: {practiceLocationUuid: {_in: $locationUuids}}
      }
    ) {
      additionalAttributes
      email
      id
      name
      phoneNumber
      relationshipWith {
        isDeleted
        contactId
      }
      uuid
      contactType {
        contactType {
          value
          code
          id
        }
      }
      patient {
        patientUuid
        patientId
      }
      person {
        chosenName
        pronounId
        firstName
        lastName
        birthDate
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          id
        }
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      contactConsents {
        contactUuid
        consentId
      }
      personContact {
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          code
          accountId
          value
          id
          categoryId
        }
        typeId
      }
    }
  }
`;

export const GetContactByPhoneNumberAndInboxIds = gql`
  query GetContactByPhoneNumberAndInboxIds(
    $contactPhoneNumber: String
    $inboxIds: [bigint!] = []
  ) {
    contacts(
      where: {
        contactInboxes: {
          inboxId: {_in: $inboxIds}
          sourceId: {_eq: $contactPhoneNumber}
        }
      }
    ) {
      id
      uuid
      name
      email
      phoneNumber
      isActive
      additionalAttributes
      contactDeactivationInfo {
        isActive
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        firstName
        middleName
        lastName
        chosenName
        birthDate
        pronounId
        id
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
      }
      personContact {
        personContactId
        rank
        value
        id
        personContactType {
          accountId
          value
          id
          categoryId
        }
        typeId
      }
    }
  }
`;


export const GET_CONTACTS_BY_RULE_ID = gql`
  query getContactsByRuleId($params: SearchContactsInput!) {
    searchContacts(params: $params) {
      contacts {
        id
        uuid
        isActive
        contactPracticeLocations {
          practiceLocation {
            name
            practiceCity {
              name
            }
          }
        }
        phoneNumber
        name
        email
        person {
          firstName
          birthDate
          gender {
            value
            code
          }
          chosenName
          middleName
          lastName
        }
        patient {
          patientId
        }
        personAddress {
          line1
          line2
          cities {
            name
          }
          zipcodes {
            code
          }
          states {
            name
            abbr
          }
        }
      }
    }
  }`;

export const searchContactCount = gql`
  query GET_SEARCH_CONTACTS($params: SearchContactsInput!) {
    searchContacts(params: $params) {
      contactAggregate {
        aggregate {
          count
        }
      }
    }
  }
`;


export const addPatientToDayOptimizer = gql`
  mutation($params: AddOrUpdateDayOptimizerUserScheduleInput!) {
  addOrUpdateDayOptimizerUserSchedule(params: $params) {
    id
  }
}
`;

export const bulkUpdateDateForDayOptimizerSchedulesInput = gql`
  mutation ($params: BulkUpdateDateForDayOptimizerSchedulesInput!) {
    bulkUpdateDateForDayOptimizerSchedules(params: $params) {
      alreadyPresentUserScheduleIds
      success
      toDeleteUserScheduleIds
      toUpdateDateUserScheduleIds
    }
  }
`

export const SearchDayOptimizerRuleFactors = gql`
query SearchDayOptimizerRuleFactors($params: DayOptimizerRulesFactorInput) {
  searchDayOptimizerRuleFactors(params: $params) {
    ruleFactors {
    id
    name
    score
    isEnabled
    ruleCode
    ruleScoreId
    accountRoleId
  }
  }
}`;

export const UpdateDayOptimizerRuleFactorStatus = gql`
mutation MyMutation($params: AddOrUpdateDayOptimizerRuleFactorsInput!) {
  addOrUpdateDayOptimizerRuleFactors(params: $params) {
    success
  }
}`;

export default {
  getContactDataById,
  GetLeads,
  UpdateLeadGql,
  CreateLeadGql,
  UpdateContact,
  UpdatePersonContactGql,
  GetContacts,
  GetVisitorContacts,
  CreateContact,
  GetContact,
  GetContactAndSourceId,
  GetContactForMessageHeader,
  UpdatePersonContact,
  CreatePersonContact,
  UpdatePersonAddress,
  CreateAddress,
  SearchContacts,
  SearchContacts2,
  SearchContacts3,
  CreateContactPerson,
  UpdateContactPerson,
  CreateContactType,
  DeleteContact,
  GetSupportingMemeber,
  getContactsbyUuid,
  getContactsByIds,
  UpdateContactPracticeLocation,
  CreateContactPracticeLocation,
  GetContactsForGroup,
  GetContactsForSMSInboxCreate,
  SingleCreateContactQuery,
  GetContactByEmail,
  UpdateWorkflowMetaData,
  GetContactNameByPhoneNumber,
  UpdateContactLanguages,
  CreateContactLanguages,
  DeactivateContact,
  GetContactRelationCountByContactId,
  CreateContactForInBoundCall,
  UpdatePreferredChannel,
  CreatePreferredChannel,
  GetContactsByFromDateToDate,
  GetContactsByGenderCode,
  getContactsByUuid,
  Get_Contact_By_Uuid,
  DeactivateContactData,
  GetContactsForMeetingCreate,
  GetContactsByIds,
  GetConsentsByContactIds,
  GetContactTags,
  GetContactLoginTimeStamp,
  getContactDetailsByUuid,
  getOrderedContactsByIds,
  getUserContactPracticeLocations,
  GetContactAccountPracticeLocation,
  searchContacts,
  searchDayOptimizerPatients,
  searchContactsByRuleId,
  GetContactAggregateCountByRuleId,
  GetContactsForGroupByLocationUuids,
  GetContactByPhoneNumberAndInboxIds,
  GET_CONTACTS_BY_RULE_ID,
  contactAggregateOnLocation,
  searchContactCount,
  addPatientToDayOptimizer,
  bulkUpdateDateForDayOptimizerSchedulesInput,
  SearchDayOptimizerRuleFactors,
  UpdateDayOptimizerRuleFactorStatus
};

gql`
  query GetContacts(
    $limit: Int
    $offset: Int
    $order_by: [contacts_order_by!]! = {
      name: asc
      email: null
      phoneNumber: null
    }
    $contact_filters: contacts_bool_exp
  ) {
    contacts(
      order_by: $order_by
      where: $contact_filters
      limit: $limit
      offset: $offset
    ) {
      contactAdditionalInfos {
        stripeCustomerId
      }
      contactDeactivationInfo {
        isActive
      }
      isActive
      additionalAttributes
      contactLanguages {
        id
        languageCode
        preferred
      }
      contactPracticeLocations(
        where: {practiceLocationUuid: {_is_null: false}}
      ) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      isDeleted
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      accountId
      phoneNumber
      pubsubToken
      updatedAt
      name
      email
      id
      uuid
      createdAt
      personContact {
        isVerified
        isPreferredCommunicationChannel
        personContactId
        value
        id
        rank
        isPreferredCommunicationChannel
        personContactType {
          accountId
          value
          code
          id
          categoryId
        }
        typeId
      }
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      contactProfilePhotos {
        attachmentId
      }
      person {
        firstName
        birthDate
        id
        bloodGroupId
        gender {
          value
          code
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
        chosenName
        pronounId
      }
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      lastActivityAt
      identifier
      workflowMetaData
      employees {
        employerId
        contactId
        employer {
          name
        }
      }
    }
    contactAggregate(where: $contact_filters) {
      aggregate {
        count
      }
    }
  }
`;

