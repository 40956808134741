import {DownOutlined} from '@ant-design/icons';
import {useLazyQuery} from '@apollo/client';
import {Dropdown, Menu, Space, Typography} from 'antd';
import {
  HStack,
  Skeleton,
  Stack,
  useMediaQuery,
  useToast,
  View,
  VStack,
  Text,
} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useParams} from 'react-router-dom';
import {IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../../constants';
import {USER_ROLE_CODES} from '../../../../../constants/MlovConst';
import EmployerQueries from '../../../../../services/Employer/EmployerQueries';
import {Colors} from '../../../../../styles';
import {
  getAccountUUID,
  getCurrentUserRole,
  getUserEmployerId,
  isLoggedInUserGlobalAdmin,
  isLoggedInUserWorkFlow,
  isLoggedInUserWorkFlowOrCustomerSuccess,
  isLoginUserBusinessOwner,
} from '../../../../../utils/commonUtils';
import AgeGroupsChart from '../../../../common/AppDashboard/AgeGroupsChart';
import GenderGroupsChart from '../../../../common/AppDashboard/GenderGroupsChart';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import {ActiveLivesGraph} from '../../../Analytics/ActiveLivesGraph';
import {AppointmentTypeGraph} from '../../../Analytics/AppointmentTypeGraph';
import EmployerAnalytics from '../../../Analytics/EmployerAnalytics/EmployerAnalytics';
import {FormResponseGraph} from '../../../Analytics/FormResponseGraph';
import {LoginEmployeeGraph} from '../../../Analytics/LoginEmployeeGraph';
import { isFormAccessAllowed, isLeadAccessAllowed, isPatientAccessAllowed, isScheduleAccessAllowed} from '../../../UserAccess/UserAccessPermission';
import '../../index.css';
import {
  AnalyticsController,
  AnalyticsState,
  Employer,
} from './AnalyticsController';
import {IPowerBiWorkspaceReport} from '../../../../../services/PowerBI/interface';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';

const SelectFilterMenu = (props: {
  list: Employer[];
  isDisabled: boolean;
  onSelectAction: (key: string) => void;
  selectedKey: string;
}) => {
  const {isDisabled, list, selectedKey, onSelectAction} = props;

  return (
    <Menu
      onClick={(info) => {
        const key = info.key;
        onSelectAction(key);
      }}
      disabled={isDisabled}
      selectedKeys={[selectedKey]}
      style={{
        height: 300,
        overflow: 'scroll',
      }}
    >
      {list.map((item) => {
        return (
          <Menu.Item key={item.id} id={item.id}>
            {item.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default function NewAnalyticsDataComponentView(props: any) {
  const isEnablePowerBiAnalytics = isAccountConfigEnabled(CONFIG_CODES.ENABLE_POWER_BI_ANALYTICS);
  const isLoggedInUserWorkFlowUser = isLoggedInUserWorkFlow();
  const [state, setState] = useState<AnalyticsState>({
    list: [] as [],
    loading: false,
    selected: {} as Employer,
    selectedPowerBiAccountReport: {} as IPowerBiWorkspaceReport
  });
  const {id}: any = useParams();
  const tenantId = getAccountUUID();
  const [getEmployers] = useLazyQuery(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: tenantId,
      query: '%%',
    },
  });

  const userRoles = getCurrentUserRole();
  const userEmployerId = getUserEmployerId();

  const toast = useToast();

  const {showSkeleton, isEmployerRole, setSelected, onMount} =
    new AnalyticsController({
      state,
      tenantId,
      toast,
      userRoles,
      userEmployerId,
      setState,
      getEmployers,
      id,
      isFromEmployerListing: props.isFromEmployerListing,
      isEnablePowerBiAnalytics: isEnablePowerBiAnalytics,
      isLoggedInUserWorkFlowUser: isLoggedInUserWorkFlowUser
    });

  useEffect(() => {
    onMount();
  }, []);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const {height} = Dimensions.get('window');
  const finalHeight = height - 200;

  const showAgeAndGenderGraph = isPatientAccessAllowed() || isEmployerRole();
  const showFormsGraph = isFormAccessAllowed() || isEmployerRole();
  const showAppointmentTypesGraph = isScheduleAccessAllowed() || isEmployerRole();
  const showLoginCountGraph = isLeadAccessAllowed() || isEmployerRole();
  const currentUserRoles = getCurrentUserRole();
  const isBusinessOwner = isLoginUserBusinessOwner() || isLoggedInUserGlobalAdmin() || isLoggedInUserWorkFlowOrCustomerSuccess()

  const [isShowAppointmentTypesGraph, setIsShowAppointmentTypesGraph] =
    useState(showAppointmentTypesGraph);

  return (
    <View>
      <View
        style={{
          height: props.isFromEmployerListing ? height - 180 : height - 120,
          // height: height - 40, vivek change
          overflow: 'scroll',
          // backgroundColor: '#fff',
          paddingHorizontal: 16,
          paddingTop: isEmployerRole() && !props.hideTitleSubtitleView ? 16 : 0,
          padding: 16,
        }}
      >
        {isEmployerRole() || props.isFromEmployerListing ? null : (
          <VStack
          style={{
            marginBottom: 12,
          }}
          >
            <div
              style={{
                display: 'flex',
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '28px',
              }}
            >
              <Text style={{marginRight: '8px',  fontSize: 18}}>
                Employer Analytics: Showing data for
              </Text>
              <Dropdown
                overlay={
                  <>
                    {showSkeleton ? (
                      <Skeleton.Text lines={2} />
                    ) : (
                      <SelectFilterMenu
                        list={state.list}
                        selectedKey={state?.selected?.id || ''}
                        isDisabled={isEmployerRole()}
                        onSelectAction={(key) => {
                          setSelected(key);
                        }}
                      />
                    )}
                  </>
                }
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space style={{marginRight: '8px'}}>
                    {state?.selected?.name}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
              <Text> employer</Text>
            </div>
            <View></View>
          </VStack>
        )}
        <div
          style={{
            maxHeight: finalHeight,
            width: '100%',
            overflow: 'scroll',
            marginTop: 12,
          }}
        >
          {state.loading ? (
            <Skeleton.Text lines={5} />
          ) : (
            <>
              <Stack
                direction={isSmallScreen ? 'column' : 'row'}
                space="3"
                flexWrap={'wrap'}
                alignItems={'flex-start'}
                flex={1}
                marginTop={-5}
              >
                {/* <EmployerAnalytics employerId={state?.selected?.id} /> */}
                {showLoginCountGraph && (
                  <VStack
                    width={isSmallScreen ? '100%' : '31.5%'}
                    marginTop={3}
                  >
                    <LoginEmployeeGraph
                      key={state?.selected?.id}
                      employerId={state?.selected?.id}
                    />
                  </VStack>
                )}

                {showAgeAndGenderGraph && (
                  <VStack
                    width={isSmallScreen ? '100%' : '31.5%'}
                    marginTop={3}
                  >
                    <AgeGroupsChart
                      key={state?.selected?.id}
                      employerId={state?.selected?.id}
                    ></AgeGroupsChart>
                  </VStack>
                )}

                {showAgeAndGenderGraph && (
                  <VStack
                    width={isSmallScreen ? '100%' : '31.5%'}
                    marginTop={3}
                  >
                    <GenderGroupsChart
                      key={state?.selected?.id}
                      employerId={state?.selected?.id}
                    ></GenderGroupsChart>
                  </VStack>
                )}
                {isShowAppointmentTypesGraph && (
                  <VStack
                    width={isSmallScreen ? '100%' : '31.5%'}
                    key={state?.selected?.id}
                    marginTop={3}
                  >
                    <AppointmentTypeGraph
                      setIsShowAppointmentTypesGraph={
                        setIsShowAppointmentTypesGraph
                      }
                      key={state?.selected?.id}
                      employerId={state?.selected?.id}
                    />
                  </VStack>
                )}
                {showFormsGraph && (
                  <VStack
                    width={isSmallScreen ? '100%' : '31.5%'}
                    marginTop={3}
                  >
                    <FormResponseGraph employerId={state?.selected?.id} />
                  </VStack>
                )}
                {isBusinessOwner && (
                  <VStack
                    width={isSmallScreen ? '100%' : '31.5%'}
                    marginTop={3}
                  >
                    <ActiveLivesGraph
                      key={state?.selected?.id}
                      employerId={state?.selected?.id}
                    />
                  </VStack>
                )}
              </Stack>
            </>
          )}
        </div>
      </View>
    </View>
  );
}
