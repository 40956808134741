import {CHANNEL_TYPE_CODE} from "../../ConversationConst";
import { MESSAGE_TYPE } from "../../interfaces";

export const MESSAGE_CUSTOM_CARD_KEY = {
    CONTACT_CARD: 'CONTACT_CARD',
    PATIENT_CARD: 'PATIENT_CARD',
    TASK_CARD: 'TASK_CARD',
    APPOINTMENT_CARD: 'APPOINTMENT_CARD',
    CUSTOM_CARD: 'custom_card',
    INCOMING_MISSED_CALL_CARD: 'INCOMING_MISSED_CALL',
    INCOMING_COMPLETED_CALL_CARD: 'INCOMING_COMPLETED_CALL',
    OUTGOING_MISSED_CALL_CARD: 'OUTGOING_MISSED_CALL',
    OUTGOING_COMPLETED_CALL_CARD: 'OUTGOING_COMPLETED_CALL',
}
export declare type MESSAGE_CUSTOM_CARD_KEY_NAME =
  | 'CONTACT_CARD'
  | 'TASK_CARD'
  | 'APPOINTMENT_CARD';

export const MESSAGE_CUSTOM_CARD_TYPE = {
    CONTACT_CARD: 11,
    INCOMING_MISSED_CALL_CARD: 4,
    INCOMING_COMPLETED_CALL_CARD: 5,
    OUTGOING_MISSED_CALL_CARD: 6,
    OUTGOING_COMPLETED_CALL_CARD: 7,
}

export const ALL_MESSAGE_TYPE_LIST = Object.values(MESSAGE_TYPE);

export const CALL_MESSAGE_TYPE_NUM = [
  MESSAGE_TYPE.outgoing,
  MESSAGE_TYPE.incoming_missed_call,
  MESSAGE_TYPE.incoming_completed_call,
  MESSAGE_TYPE.outgoing_missed_call,
  MESSAGE_TYPE.outgoing_completed_call,
];

export const CALL_ONLY_MESSAGE_TYPE_NUM = [
  MESSAGE_TYPE.incoming_missed_call,
  MESSAGE_TYPE.incoming_completed_call,
  MESSAGE_TYPE.outgoing_missed_call,
  MESSAGE_TYPE.outgoing_completed_call,
];

export const MISS_CALL_MESSAGE_TYPE_NUM = [
  MESSAGE_TYPE.incoming_missed_call,
  MESSAGE_TYPE.outgoing_missed_call,
];

export const IO_MESSAGE_TYPE_NUM = [
  MESSAGE_TYPE.outgoing,
  MESSAGE_TYPE.incoming,
];


export const CALL_AND_IO_MESSAGE_TYPE_NUM = [
  MESSAGE_TYPE.outgoing,
  MESSAGE_TYPE.incoming,
  MESSAGE_TYPE.incoming_missed_call,
  MESSAGE_TYPE.incoming_completed_call,
  MESSAGE_TYPE.outgoing_missed_call,
  MESSAGE_TYPE.outgoing_completed_call,
];

export const IO_MESSAGE_AND_MISS_CALL_TYPE_NUM = [
  MESSAGE_TYPE.outgoing,
  MESSAGE_TYPE.incoming,
  MESSAGE_TYPE.incoming_missed_call,
];

export const CONVERSATION_DISPLAY_MESSAGE_TYPE = {
  [CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME]: [
    MESSAGE_TYPE.activity,
    ...CALL_AND_IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER]: [
    MESSAGE_TYPE.activity,
    ...CALL_AND_IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED]: [
    MESSAGE_TYPE.activity,
    ...CALL_AND_IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_MENTION]: [],
  [CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS]: [
    MESSAGE_TYPE.activity,
    MESSAGE_TYPE.incoming_missed_call
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED]: [
    MESSAGE_TYPE.activity,
    ...CALL_AND_IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_ALL]: [
    MESSAGE_TYPE.activity,
    ...CALL_AND_IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET]: [
    MESSAGE_TYPE.activity,
    ...IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS]: [
    MESSAGE_TYPE.activity,
    ...IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_EMAIL]: [
    MESSAGE_TYPE.activity,
    ...IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_E_FAX]: [
    MESSAGE_TYPE.activity,
    ...IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_INTERNAL]: [
    MESSAGE_TYPE.activity,
    ...IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.ALL_CALLS]: [
    ...CALL_ONLY_MESSAGE_TYPE_NUM
  ],
}

export const CONVERSATION_UNREAD_COUNT_TYPE = {
  [CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME]: [
   ...IO_MESSAGE_AND_MISS_CALL_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER]: [
   ...IO_MESSAGE_AND_MISS_CALL_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED]: [
   ...IO_MESSAGE_AND_MISS_CALL_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_MENTION]: [],
  [CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS]: [
    MESSAGE_TYPE.incoming_missed_call
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED]: [
   ...IO_MESSAGE_AND_MISS_CALL_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_ALL]: [
   ...IO_MESSAGE_AND_MISS_CALL_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET]: [
    ...IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS]: [
    ...IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_EMAIL]: [
    ...IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_E_FAX]: [
    ...IO_MESSAGE_TYPE_NUM
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_INTERNAL]: [
    ...IO_MESSAGE_TYPE_NUM
  ],
};

export const NEW_GROUP_CONVERSATION_DISPLAY_REALTIME: string[] = [
  CHANNEL_TYPE_CODE.CHANNEL_ALL,
  CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
  CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED,
  CHANNEL_TYPE_CODE.CHANNEL_INTERNAL,
];

export const NEW_PATIENT_GROUP_CONVERSATION_DISPLAY_REALTIME: string[] = [
  CHANNEL_TYPE_CODE.CHANNEL_ALL,
  CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
  CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED,
];

export const GROUP_MEMBER_ADD_REMOVE_CONVERSATION_DISPLAY_REALTIME: string[] = [
  CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME,
  CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER,
  CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED,
  CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED,
  CHANNEL_TYPE_CODE.CHANNEL_ALL,
  CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
  CHANNEL_TYPE_CODE.CHANNEL_INTERNAL,
];


