import {DownloadOutlined} from '@ant-design/icons';
import {useLazyQuery, useMutation} from '@apollo/client';
import {Drawer, Dropdown, Menu, Table} from 'antd';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import {Center, Divider, HStack, Icon, Pressable, Skeleton, Spinner, Text, VStack, View, useMediaQuery, useToast} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useParams} from 'react-router-dom';
import {BUTTON_TYPE, DATE_FORMATS, DEFAULT_EMPLOYER_REPORTING_CODE, IPAD_MINI_WIDTH, IPAD_WIDTH, POWER_BI_REPORT_CODE, SMALL_WINDOW_1700, scrollViewEmployer} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {EmployerDashboard, FormsQueries} from '../../../../../services';
import BaseService from '../../../../../services/CommonService/BaseService';
import EmployerQueries from '../../../../../services/Employer/EmployerQueries';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import {IAccountPracticeLocationResp} from '../../../../../services/Location/interfaces';
import {updateMarketCostForSelectEmployer} from '../../../../../services/MarketCost/MarketCostService';
import PayersQueries from '../../../../../services/MarketCost/PayersQueries';
import {IPayer, IPayerRespData} from '../../../../../services/MarketCost/interface';
import {exportPowerBiReport, getExportedPowerBiReport, getPowerBiExportedReportDetails, getPowerBITokenInfoForReport} from '../../../../../services/PowerBI/PowerBIService';
import {IPowerBiAccountReports, IPowerBiExportedReports} from '../../../../../services/PowerBI/interface';
import {Colors} from '../../../../../styles';
import {ConvertUnixTimestamp, getFormattedDate} from '../../../../../utils/DateUtils';
import {
  getAccountUUID,
  getCurrentUserRole,
  getFeatureFlag,
  getMemberSatisfactionFormsOnly,
  getUserEmployerId,
  isLoggedInUserWorkFlow
} from '../../../../../utils/commonUtils';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import CustomTabsView from '../../../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../../../common/CustomTabsView/interfaces';
import FormSearch from '../../../../common/FormSearch/FormSearch';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import PrintSvg from '../../../../common/Svg/SideMenu/PrintSvg';
import SettingSvg from '../../../../common/Svg/SideMenu/SettingSvg';
import AddWidgetDrawer from '../../../Analytics/EmployerAnalytics/CommonView/AddWidgetDrawer';
import DateRangeFilter from '../../../Analytics/EmployerAnalytics/CommonView/DateRangeFilter';
import {IDashboardSection} from '../../../Analytics/EmployerAnalytics/CommonView/interface';
import DrillDownMemberList from '../../../Analytics/EmployerAnalytics/DrillDownMemberList/DrillDownMemberList';
import EmployerAnalytics from '../../../Analytics/EmployerAnalytics/EmployerAnalytics';
import {DashboardDataContext} from '../../../Analytics/EmployerAnalytics/EmployerContext/DashboardContext';
import {
  EMPLOYER_CONST, EMPLOYER_DASHBOARD_TABS,
  VIEW_CODE,
  showTabsStructure
} from '../../../Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';
import AddNewPowerBiReport from '../../../Analytics/EmployerAnalytics/PowerBI/AddNewPowerBiReport';
import PowerBiView from '../../../Analytics/EmployerAnalytics/PowerBI/PowerBiView';
import PrintPreview from '../../../Analytics/EmployerAnalytics/PrintPreview/PrintPreview';
import {IMemberSatisfactionForm, IPageSection, IPageView, ISectionListItem} from '../../../Analytics/EmployerAnalytics/interface';
import {IAccountLocation} from '../../../Contacts/TeamMembers/interfaces';
import '../../index.css';
import {
  AnalyticsController,
  AnalyticsState,
  Employer,
  PowerBiReportMode
} from './AnalyticsController';
import './EmpDashboard.css';
import PayerConfirmModal from './PayerConfirmModal/PayerConfirmModal';
import {POWER_BI_ENTITY_TYPE_CODE} from '../../../Analytics/EmployerAnalytics/PowerBI/powerBiConst';
import moment from 'moment';
import {WorkspaceList} from './WorkspaceList';

export default function EmpDashboard(props: any) {
  const [state, setState] = useState<AnalyticsState>({
    list: [] as [],
    loading: true,
    selected: {} as Employer,
    selectedPowerBiAccountReport: {
      code: DEFAULT_EMPLOYER_REPORTING_CODE
    } as any,
    dateRangeType: EMPLOYER_CONST.QUARTER,
    dashboardAction: false,
    allPayerList: [],
    powerBiAccountReports: [],
    powerBiReportMode: PowerBiReportMode.VIEW
  });
  const accountUuid = getAccountUUID();
  const isGetMarketCostFromJsonEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_GET_MARKET_COST_FROM_JSON);
  const isNewCostSavingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_NEW_COST_SAVING_API);
  const isEnablePowerBiAnalytics = isAccountConfigEnabled(CONFIG_CODES.ENABLE_POWER_BI_ANALYTICS);
  const isLoggedInUserWorkFlowUser = isLoggedInUserWorkFlow();
  const [lastSync, setLastSync] = useState({
    lastSyncDateTime: "",
    fetchingLastSync: false,
  });
  const [preview, setPreview] = useState<boolean>(false);
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700;
  const [selectedTab, setSelectedTab] = useState(EMPLOYER_DASHBOARD_TABS[0] as ITabsList)
  const [showDrawer, setShowDrawer] = useState(false);
  const [empDashboardState, setEmpDashboardState] = useState({
    showPayerSelectModal: false,
    selectedPayerId: '',
    selectedPayerIdAfterUpdate: '',
    isLoading: false,
    selectedView: '',
    dashboardData: [] as IDashboardSection[],
    powerBiSelectedData: {
      contactUuids: []
    },
    selectedEmployer: {
      name: '',
      id: ''
    }
  });
  const showAddWidgetBtn = selectedTab.key === 'OVERVIEW' || selectedTab.key === 'FINANCIAL' || !showTabsStructure;
  const showPrintConfigure = selectedTab.key === 'OVERVIEW' || !showTabsStructure;
  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const commonData = useContext(CommonDataContext);
  const isVbcTenant = getFeatureFlag(CONFIG_CODES.IS_VBC_TENANT, commonData);

  const [isOpen, setIsOpen] = useState(false);

  const intl = useIntl();
  const [dateRange, setDateRange] = useState({
    from: '' as any,
    to: '' as any,
    uiFromDate: '' as any,
    uiToDate: '' as any,
  });
  const [formData, setFormValue] = useState<any>(undefined);
  const [stateData, setStateData] = useState({
    sectionList: [] as ISectionListItem[],
    loading: false,
    pageResponse: {} as IPageView,
    reusableData: {
      employeeCount: null,
    } as any,
    employerData: {} as any,
    rawPageSections: ['a', 'c'] as IPageSection[] | any[],
    isDashboardAssign: false,
    dashboardLoading: false,
    drillDownData: {} as any,
    selectedView: '',
    selectedResourceData: null,
    masterData: [] as any[],
    tabList: [] as any,
    memberSatisfactionFormList: [] as IMemberSatisfactionForm[],
    powerBiWorkspaces: [] as IPowerBiAccountReports[],
    powerBiReportMode: PowerBiReportMode.VIEW
  });

  const [powerbiExportedReports, setPowerbiExportedReports] = useState<IPowerBiExportedReports[]>([] as IPowerBiExportedReports[]);
  const { id }: any = useParams();
  const tenantId = getAccountUUID();
  const [locationFiltersState, setLocationFiltersState] = useState({
    accountPracticeLocations: [] as IAccountLocation[],
  });
  const [accountPracticeLocations] = useLazyQuery<IAccountPracticeLocationResp>(
    UserPracticeLocationQueries.GetPracticeLocationsByTenantId,
    {
      variables: {tenantId: tenantId},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [getEmployers] = useLazyQuery(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: tenantId,
      query: '%%',
    },
  });

  const [getAllPayersFromApi] = useLazyQuery<IPayerRespData>(PayersQueries.getAllPayers, {
    fetchPolicy: 'no-cache',
  });
  const [createEmployerPriceSource] = useMutation(EmployerQueries.createEmployerPriceSource);
  const [updateEmployerPriceSource] = useMutation(EmployerQueries.updateEmployerPriceSource);
  const [GetDashboard] = useLazyQuery(
    EmployerDashboard.GetDashboardByEmployerId
  );
  const [GetMembersSatisfaction] = useLazyQuery(
    FormsQueries.GET_MEMBERS_SATISFACTION,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const userRoles = getCurrentUserRole();
  const userEmployerId = getUserEmployerId();

  const toast = useToast();

  const {showSkeleton, isEmployerRole, setSelected, onMount} =
    new AnalyticsController({
      state,
      tenantId,
      toast,
      userRoles,
      userEmployerId,
      setState,
      getEmployers,
      getAllPayersFromApi,
      id,
      isFromEmployerListing: props.isFromEmployerListing,
      isEnablePowerBiAnalytics: isEnablePowerBiAnalytics,
      isLoggedInUserWorkFlowUser: isLoggedInUserWorkFlowUser
    });

  useEffect(() => {
    onMount();
    setPlaceholderForPayer();
    // getLastSyncTime();
    updateMembersastisfactionList();
  }, []);

  const updateMembersastisfactionList = async () => {
    let memberSatisfactionFormList = [] as any[];
    const memberSatisfactionFormResp = await GetMembersSatisfaction();
    if (memberSatisfactionFormResp?.data?.forms?.length) {
      memberSatisfactionFormList = getMemberSatisfactionFormsOnly(memberSatisfactionFormResp?.data?.forms) as any[];;
    }
    setLastSync((prev) => {
      return {
        ...prev,
        memberSatisfactionFormList: [...memberSatisfactionFormList]
      };
    });
  };
  // useEffect(() => {
  //   onChange(moment(), '', '');
  // }, [state.dateRangeType]);
  useEffect(() => {
    if (props.isFromEmployerListing) {
      setState((prev) => {
        return {
          ...prev,
          ...props.state,
        };
      });
      setDateRange((prev) => {
        return {
          ...props.dateRange,
        };
      });
    }
    setPlaceholderForPayer();
  }, [props.dateRangeType || props.dateRange]);

  useEffect(() => {
    if (props.dashboardAction) {
      setState((prev) => {
        return {
          ...prev,
          dashboardAction: true,
        };
      });
    }
    setPlaceholderForPayer();
  }, [props.dashboardAction]);


  useEffect(() => {
    setPlaceholderForPayer();
  }, [state.selected]);

  const {height} = Dimensions.get('window');
  const updateEmployerMarketPriceSource = async () => {
    const selectedPayerData = getPayerDataFromId(empDashboardState.selectedPayerId);
    if (state?.selected?.employerPriceSourceData?.id && empDashboardState.selectedPayerId) {
      const updatedData = await updateEmployerPriceSource({
        variables: {
          employerPriceSource: {
            payerId: empDashboardState.selectedPayerId,
            carrierId: selectedPayerData.carrierData.id,
          },
          id: state.selected.employerPriceSourceData.id,
          employerId: state.selected.id,
        },
      });
      if (state.selected.id && empDashboardState.selectedPayerId) {
        updateMarketCostDataForSelectedEmployer({
          employerId: state.selected.id,
          payerId: empDashboardState.selectedPayerId,
        });
      }
    } else {
      const createdData = await createEmployerPriceSource({
        variables: {
          employerPriceSource: {
            payerId: empDashboardState.selectedPayerId,
            carrierId: selectedPayerData.carrierData.id,
            accountId: accountUuid,
            employerId: state.selected.id,
          },
        },
      });
      if (createdData?.data && state.selected.id && empDashboardState.selectedPayerId) {
        updateMarketCostDataForSelectedEmployer({
          employerId: state.selected.id,
          payerId: empDashboardState.selectedPayerId,
        });
      }
    }
    setPlaceholderForPayer();
    setEmpDashboardState((prev) => {
      return {
        ...prev,
        showPayerSelectModal: false
      }
    });
  };
  const updateMarketCostDataForSelectedEmployer = async (paramsData: {
    payerId: string;
    employerId: string;
  }) => {
    const bodyParams = {
      ...paramsData
    }
    const respData = await updateMarketCostForSelectEmployer({
      bodyParams: bodyParams
    }).catch(() => {
      showToast(
        toast,
        intl.formatMessage({id: 'marketCostSuccess'}),
        ToastType.success,
        3000
      );
      setEmpDashboardState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    });
    if (respData?.data && Object.keys(respData?.data)?.length) {
      showToast(
        toast,
        intl.formatMessage({id: 'marketCostSuccess'}),
        ToastType.success,
        3000
      );
      setEmpDashboardState((prev) => {
        return {
          ...prev,
          isLoading: false,
          selectedPayerIdAfterUpdate: paramsData.payerId
        };
      });
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'marketCostSuccess'}),
        ToastType.success,
        3000
      );
      setEmpDashboardState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }
  }

  const setPlaceholderForPayer = () => {
    if (state?.selected?.employerPriceSourceData?.payerId || empDashboardState.selectedPayerId) {
      const selectPayerElem: any = document.querySelectorAll('.select-payer .ant-select-selection-search-input');
      if (selectPayerElem && selectPayerElem?.[0] && typeof selectPayerElem?.[0]?.setAttribute === 'function') {
        selectPayerElem?.[0]?.setAttribute('placeholder', '');
      }
    } else {
      const selectPayerElem: any = document.querySelectorAll('.select-payer .ant-select-selection-search-input');
      if (selectPayerElem && selectPayerElem?.[0] && typeof selectPayerElem?.[0]?.setAttribute === 'function') {
        selectPayerElem?.[0]?.setAttribute('placeholder', 'Select Payer');
      }
    }
  }
  const getPayerDataFromId = (payerId: string): IPayer => {
    return state?.allPayerList?.find((singlePayerData) => {
      return singlePayerData.id === payerId
    }) as any;
  }

  const getLastSyncTime = async () => {
    setLastSync((prev) => {
      return {
        ...prev,
        fetchingLastSync: true,
      };
    });
    const path = `/crm-nest/account/api/employer/sync-time`;
    const axios = BaseService.getSharedInstance().axios;
    const syncDateResponse = await axios.get(path).catch((error) => {})
    if (syncDateResponse) {
      setLastSync((prev) => {
        return {
          ...prev,
          lastSyncDateTime: ConvertUnixTimestamp(syncDateResponse?.data?.lastSyncDateTime),
        };
      });
    }
    setLastSync((prev) => {
      return {
        ...prev,
        fetchingLastSync: false,
      };
    });
  };

  const onPayerConfirmModalActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setPlaceholderForPayer();
        setEmpDashboardState((prev) => {
          return {
            ...prev,
            selectedPayerId: '',
            showPayerSelectModal: false
          }
        });
        break;
      case COMMON_ACTION_CODES.UPDATE:
        setEmpDashboardState((prev) => {
          return {
            ...prev,
            showPayerSelectModal: false,
            isLoading: true,
          };
        });
        updateEmployerMarketPriceSource();
        break;
    }
  }
  const getAccountPracticeLocations = async () => {
    const accountLocationRes = await accountPracticeLocations().catch((err) => {
    })

    if (accountLocationRes?.data?.accountLocations?.length) {
      const allLocationObj: any =  {
        uuid: 'ALL',
        practiceLocation: {
          name: 'All location',
          uuid: 'ALL',
        }
      }
      accountLocationRes?.data?.accountLocations.unshift(allLocationObj)
      setLocationFiltersState(prev => {
        return {
          ...prev,
          accountPracticeLocations: accountLocationRes?.data?.accountLocations || [] as IAccountLocation[]
        }
      })
    }

  }
  const onAddNewPowerBiReportActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE:
        setEmpDashboardState(prev => {
          return {
            ...prev,
            selectedView: '',
            powerBiSelectedData: {
              contactUuids: []
            }
          }
        })
        break;
      case COMMON_ACTION_CODES.NEW_POWER_BI_REPORT_CREATED:
        setEmpDashboardState(prev => {
          return {
            ...prev,
            selectedView: '',
            powerBiSelectedData: {
              contactUuids: []
            }
          }
        });
        if (actionData?.accountReportData?.id) {
            setState((prev)=> {
            const powerBiAccountReports = prev?.powerBiAccountReports;
            powerBiAccountReports?.push(actionData?.accountReportData?.powerBiWorkspacesReports);
            return {
              ...prev,
              powerBiAccountReports: powerBiAccountReports,
              selectedPowerBiAccountReport: actionData?.accountReportData?.powerBiWorkspacesReports || {} as any,
              powerBiReportMode: PowerBiReportMode.EDIT
            }
          });
        }

        break;
      default:
        break;
    }
  };

  const getExportedFiles = async () => {
    console.log("Clicked getExportedFiles");
    console.log(state);
    const exportedFiles = await getPowerBiExportedReportDetails(state.selectedPowerBiAccountReport.powerBiReportId);
    if (exportedFiles.data.length) {
      setPowerbiExportedReports(exportedFiles.data);
    }
    setShowDrawer(true);
  }

  const menuItem: ItemType[] = [
    {
      key: "PDF",
      label: "PDF",
    },
    {
      key: "PPTX",
      label: "PPTX",
    },
    {
      key: "PNG",
      label: "PNG",
    },
  ];

  const onExportReport = async (exportFormat: 'PDF' | 'PPTX' | 'PNG') => {
    showToast(
      toast,
      'Report will download once it download is completed we will notify you',
      ToastType.info,
      3000
    );
    await exportPowerBiReport(
      state.selectedPowerBiAccountReport.powerBiReportId,
      state.selectedPowerBiAccountReport.powerBiWorkspaceId,
      exportFormat
    );
  }

  const powerBiExportFormats = () => {
    return (
      <Menu
        items={menuItem}
        onClick={(data: any) => {
          onExportReport(data.key);
        }
        }
      />
    );
  };

  const columns = [
    {
      title: 'Format',
      dataIndex: 'exportFormat',
      key: 'exportFormat',
    },
    {
      title: 'Status',
      dataIndex: 'powerBiExportStatus',
      key: 'powerBiExportStatus',
    },
    {
      title: 'Generated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Download',
      dataIndex: 'download',
      key: 'updatedAt',
      render: (text: any, record: IPowerBiExportedReports) => {
        return record.powerBiExportStatus === 'Succeeded' ? (
          <FoldButton
            nativeProps={{
              leftIcon: (<DownloadOutlined />),
              variant: BUTTON_TYPE.PRIMARY,
              style: { margin: 0, padding: 0 },
              onPress: async () => {
                const file = await getExportedPowerBiReport(record.powerBiReportId, record.powerBiWorkspaceId, record.powerBiExportId);
                const blob = new Blob([new Uint8Array(file.data.data)]);
                const url = window.URL.createObjectURL(
                  new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                  'download',
                  `report.${record.exportFormat.toLowerCase()}`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                //   // Clean up and remove the link
                link.parentNode!.removeChild(link);
              },
            }}
            customProps={{
              btnText: 'Download',
              withRightBorder: false,
            }} />
        ) : null
      }
    },

  ];

  useEffect(() => {
    getAccountPracticeLocations()
  },[]);

  const getNewReportBtnText = () => {
    if (state?.selectedPowerBiAccountReport?.isDefault && state?.selectedPowerBiAccountReport?.code !== POWER_BI_REPORT_CODE.EMPTY_REPORT) {
      return 'Clone report';
    }
    return 'Create new report';
  }

  const setDefaultEmployer = () => {
    if (state.list?.length) {
      const defaultEmployer = state.list[0];
      setSelected(defaultEmployer?.id || '');
      setEmpDashboardState((prev) => {
        return {
          ...prev,
          selectedEmployer: {
            name: defaultEmployer?.name,
            id: defaultEmployer?.id,
          }
        }
      })
    }
  }


  const getDrillThrowReportBtnText = () => {
    const uniqueSelectedMemberCount = empDashboardState?.powerBiSelectedData?.contactUuids?.length;
    return `Selected Member Count ${uniqueSelectedMemberCount}`;
  }
  const getEmployerListByReportCode = () => {
    if (state?.selectedPowerBiAccountReport?.entityType === POWER_BI_ENTITY_TYPE_CODE.FORMS) {
      return state.list;
    }
    return  state.list?.filter((singleEmployer) => {
      return singleEmployer?.id !== 'SELECT_ALL'
    })
  }

  const handleOnWorkspaceSelect = (value: string) => {
    const selectedPowerBiWorkspace: any = state?.powerBiAccountReports?.find(
      (singleData) => {
        return singleData?.id === value;
      }
    );
    setState((prev) => {
      return {
        ...prev,
        selectedPowerBiAccountReport: selectedPowerBiWorkspace || ({} as any),
      };
    });
    const isTabSelected = EMPLOYER_DASHBOARD_TABS.find((tab) => tab.key === value);
    if (isTabSelected?.title) {
      if (!state?.selected?.id) {
        const defaultEmployer = state.list[1];
        setSelected(defaultEmployer?.id || '');
        setEmpDashboardState((prev) => {
          return {
            ...prev,
            selectedEmployer: {
            name: defaultEmployer?.name,
            id: defaultEmployer?.id,
          },
        };
        });
      }
      setSelectedTab(isTabSelected)
      return
    }
    if (
      selectedPowerBiWorkspace?.entityType === POWER_BI_ENTITY_TYPE_CODE.FORMS
    ) {
      setDefaultEmployer();
    } else if (
      selectedPowerBiWorkspace?.code === DEFAULT_EMPLOYER_REPORTING_CODE
    ) {
      setSelectedTab(EMPLOYER_DASHBOARD_TABS[0])
      const defaultEmployer = state.list[1];
      setSelected(defaultEmployer?.id || '');
      setEmpDashboardState((prev) => {
        return {
          ...prev,
          selectedEmployer: {
            name: defaultEmployer?.name,
            id: defaultEmployer?.id,
          },
        };
      });
    } else if (
      selectedPowerBiWorkspace?.code !== DEFAULT_EMPLOYER_REPORTING_CODE
    ) {
      setSelected('');
      setEmpDashboardState((prev) => {
        return {
          ...prev,
          selectedEmployer: {
            name: '',
            id: '',
          },
        };
      });
    }
  };
  return state.loading ? (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: height - 150,
      }}
    >
      <Spinner size="lg" />
    </View>
  ) : (
    <HStack nativeID="empMainSection">
      <VStack
        style={{
          flex: 0.12,
          padding: 8,
          borderRightWidth: 0.5,
          borderRightColor: '#D0D6E1'
        }}
      >
        <WorkspaceList
          onSelectWorkspace={handleOnWorkspaceSelect}
          powerBiAccountReports={state.powerBiAccountReports || []}
          selectedPowerBiAccountReportId={
            state.selectedPowerBiAccountReport?.id || ''
          }
        />
      </VStack>
      <View flex={0.88}>

        <View style={{
          flexDirection: 'column', borderBottomWidth: 1,
          borderColor: Colors.Custom.Gray200,
        }}>
          {!props.isFromEmployerListing ? (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: isSmallScreen ? 'space-evenly' : 'space-between',
                marginHorizontal: isSmallScreen ? '' : 22,
              }}
            >
              <View
                style={{
                  paddingHorizontal: isSmallScreen ? 10 : 0,
                  paddingVertical: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {state?.selectedPowerBiAccountReport?.entityType === POWER_BI_ENTITY_TYPE_CODE.FORMS  ?  (
                  <View style={{
                    marginRight: 12,
                  }}>
                    <View style={{width: isSmallScreen ? 170 : 300}}>
                      <FormSearch
                        isShowError={false}
                        isReturnCategory={true}
                        onChange={(form) => {
                          setFormValue(form);
                        }}
                        onInitSetDefaultForm={(defaultFormData: any) => {
                          setFormValue(defaultFormData);
                          setDefaultEmployer();
                        }}
                        setDefaultValueOnInit={true}
                        value={formData}
                        displayAllForms={true}
                        allowClear={false}
                      />
                    </View>
                  </View>
                ) : null}
                 {isEmployerRole() || (state?.selectedPowerBiAccountReport?.code && state?.selectedPowerBiAccountReport?.code !== DEFAULT_EMPLOYER_REPORTING_CODE && state?.selectedPowerBiAccountReport?.entityType !== POWER_BI_ENTITY_TYPE_CODE.FORMS) ? null : (
                  <View>
                    <View style={{width: isSmallScreen ? 170 : 300}}>
                      <ModalActionAntSelect
                        size="large"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select Employer"
                        className={'select-employer'}
                        label={''}
                        value={state?.selected?.id as any || ''}
                        filterOption={(input: string, option: any) => {
                          return (option?.children || '').toLowerCase().includes(input.toLowerCase());
                        }}
                        onChange={(value: any[], data: any) => {
                          const selectPayerElem: any = document.querySelectorAll('.select-payer .ant-select-selection-search-input');
                          if (selectPayerElem && selectPayerElem?.[0] && typeof selectPayerElem?.[0]?.setAttribute === 'function') {
                            selectPayerElem?.[0]?.setAttribute('placeholder', '');
                          }
                          setEmpDashboardState((prev) => {
                            return {
                              ...prev,
                              selectedPayerId: '',
                              selectedEmployer: {
                                name: data?.children,
                                id: data?.value
                              },
                              showPayerSelectModal: false
                            }
                          });
                          setSelected(data?.value || '');
                        }}
                        style={{height: 40}}
                        customStyle={{height: 40}}
                        data={getEmployerListByReportCode()}
                        optionProps={{
                          key: 'id',
                          label: 'name',
                          value: 'id',
                        }}
                      />
                    </View>
                  </View>
                )}
                {
                   state?.selectedPowerBiAccountReport?.entityType === POWER_BI_ENTITY_TYPE_CODE.TASKS ?
                  (
                    <></>
                  )
                  :
                  (
                    <DateRangeFilter
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      setState={setState}
                      state={state}
                      isSmallScreen={isSmallScreen}
                    />
                  )
                }

                {empDashboardState?.powerBiSelectedData?.contactUuids?.length > 0 && state?.selectedPowerBiAccountReport?.code !== DEFAULT_EMPLOYER_REPORTING_CODE &&
                  <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.PRIMARY,
                      style: {
                        margin: 3,
                        marginLeft: 12
                      },
                      onPress: () => {
                        setEmpDashboardState((prev) => {
                          return {
                            ...prev,
                            selectedView: VIEW_CODE.GROUP_MEMBER_VIEW,
                          };
                        });
                      },
                    }}
                    customProps={{
                      btnText: getDrillThrowReportBtnText(),
                      withRightBorder: false,
                    }}/>
                }
                {
                  showDrawer && <>
                    <Drawer
                      placement="right"
                      open={showDrawer}
                      closable
                      width={
                        isIPadScreen || isIPadMiniScreen
                          ? '70%'
                          : smallWindow
                            ? '60%'
                            : '50%'
                      }
                      title={
                        <ModalActionTitle
                          title={'Exported Reports'}
                          titleColor={''}
                          buttonList={[
                            {
                              show: true,
                              id: 1,
                              btnText: 'cancel',
                              textColor: Colors.Custom.mainSecondaryBrown,
                              variant: BUTTON_TYPE.SECONDARY,
                              isTransBtn: false,
                              onClick: () => {
                                setShowDrawer(false);
                              },
                            },
                          ]}
                        />
                      }
                    >
                      <Table dataSource={powerbiExportedReports} columns={columns}
                      pagination={{
                        position: ['bottomCenter'],
                        pageSize: props.pageSize,
                        pageSizeOptions: [5, 7, 10, 15, 20, 50],
                        current: props.page,
                        total: props.total,
                        onChange(page, pageSize) {
                          if (props.onPaginationChange) {
                            props.onPaginationChange(page, pageSize, {
                              isDropDownCodeChange: false,
                            });
                          }
                        },
                      }} />
                    </Drawer>
                  </>
                }
                {false && state?.selectedPowerBiAccountReport?.code && state?.selectedPowerBiAccountReport?.code !== DEFAULT_EMPLOYER_REPORTING_CODE &&
                  <View
                    margin={1}
                    style={{
                      height: 36,
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          console.log('button click');
                        },
                        rightIcon: (
                          <Dropdown
                            overlay={powerBiExportFormats}
                            placement="bottomRight"
                            overlayStyle={{ width: 180 }}
                          >
                            <Icon color={'#825AC7'} as={AntIcon} name={'down'} size="4" />
                          </Dropdown>
                        ),
                        style: { minWidth: 'auto' }
                      }}
                      customProps={{
                        btnText: 'Export report',
                        withRightBorder: true,
                        isDisableOnPress: true
                      }}
                    />
                  </View>
                }
                {false && state?.selectedPowerBiAccountReport?.code && state?.selectedPowerBiAccountReport?.code !== DEFAULT_EMPLOYER_REPORTING_CODE &&
                  <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.PRIMARY,
                      style: { margin: 3 },
                      onPress: () => {
                        getExportedFiles()
                      },
                    }}
                    customProps={{
                      btnText: 'Previous exports',
                      withRightBorder: false,
                    }} />
                }
                {!isEmployerRole() && isGetMarketCostFromJsonEnabled && !isNewCostSavingEnabled && state?.selectedPowerBiAccountReport?.code === DEFAULT_EMPLOYER_REPORTING_CODE && (
                  <View
                    style={{
                      paddingHorizontal: isSmallScreen ? 10 : 0,
                      marginLeft: 10,
                    }}>
                    <View style={{width: isSmallScreen ? 170 : 300}}>
                      <ModalActionAntSelect
                        size="large"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select Payer"
                        className={'select-payer'}
                        label={''}
                        value={empDashboardState.selectedPayerId || state?.selected?.employerPriceSourceData?.payerId || ''}
                        filterOption={(input: string, option: any) => {
                          return (option?.children || '').toLowerCase().includes(input.toLowerCase());
                        }}
                        onChange={(value: any[], data: any) => {
                          if (data?.value) {
                            const selectPayerElem: any = document.querySelectorAll('.select-payer .ant-select-selection-search-input');
                            if (selectPayerElem && selectPayerElem?.[0] && typeof selectPayerElem?.[0]?.setAttribute === 'function') {
                              selectPayerElem?.[0]?.setAttribute('placeholder', '');
                            }
                            setEmpDashboardState((prev) => {
                              return {
                                ...prev,
                                showPayerSelectModal: true,
                                selectedPayerId: data?.value,
                              }
                            });
                          }
                        }}
                        style={{height: 40}}
                        customStyle={{height: 40}}
                        data={state.allPayerList || []}
                        optionProps={{
                          key: 'id',
                          label: 'displayName',
                          value: 'id',
                        }}
                      />
                    </View>
                  </View>
                )}
              </View>
              {isEmployerRole() ? (
                <></>
              ) : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >

                  {showAddWidgetBtn && state?.selectedPowerBiAccountReport?.code === DEFAULT_EMPLOYER_REPORTING_CODE ? <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.PRIMARY,
                      style: {borderRadius: 6, minWidth: 'auto', marginHorizontal: 5},
                      onPress: () => {
                        setEmpDashboardState((prev) => {
                          return {
                            ...prev,
                            selectedView: VIEW_CODE.ADD_WIDGET,
                            powerBiSelectedData: {
                              contactUuids: []
                            }
                          };
                        });
                      },
                      leftIcon: (
                        <AntIcon name={'plus'} size={16} />
                      ),
                    }}
                    customProps={{
                      btnText: intl.formatMessage({
                        id: 'addWidget',
                      }),
                      withRightBorder: false,
                    }}
                  /> : <></>}
                  {state?.selectedPowerBiAccountReport?.code && state?.selectedPowerBiAccountReport?.code !== DEFAULT_EMPLOYER_REPORTING_CODE &&
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        style: {
                          margin: 3,
                          marginLeft: 12
                        },
                        onPress: () => {
                          setEmpDashboardState((prev) => {
                            return {
                              ...prev,
                              selectedView: VIEW_CODE.CREATE_POWER_BI_REPORT,
                              powerBiSelectedData: {
                                contactUuids: []
                              }
                            };
                          });
                        },
                      }}
                      customProps={{
                        btnText: getNewReportBtnText(),
                        withRightBorder: false,
                    }}/>
                  }
                  {state?.selectedPowerBiAccountReport?.code && state?.selectedPowerBiAccountReport?.code !== DEFAULT_EMPLOYER_REPORTING_CODE &&
                    state?.powerBiReportMode === PowerBiReportMode.VIEW &&
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        style: {
                          margin: 3,
                          marginLeft: 16
                        },
                        onPress: () => {
                          setState((prev) => {
                            return {
                              ...prev,
                              powerBiReportMode: PowerBiReportMode.EDIT
                            };
                          });
                        },
                      }}
                      customProps={{
                        btnText: 'Edit current report',
                        withRightBorder: false,
                        }}/>
                  }
                  {state?.selectedPowerBiAccountReport?.code && state?.selectedPowerBiAccountReport?.code !== DEFAULT_EMPLOYER_REPORTING_CODE &&
                    state?.powerBiReportMode === PowerBiReportMode.EDIT &&
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        style: {
                          margin: 3,
                          marginLeft: 16
                        },
                        onPress: () => {
                          setState((prev) => {
                            return {
                              ...prev,
                              powerBiReportMode: PowerBiReportMode.VIEW
                            };
                          });
                        },
                      }}
                      customProps={{
                        btnText: 'Exit edit mode',
                        withRightBorder: false,
                      }} />
                  }
                  {showPrintConfigure && state?.selectedPowerBiAccountReport?.code === DEFAULT_EMPLOYER_REPORTING_CODE ?
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Divider
                        style={{width: 1, height: 24, marginLeft: 12, marginRight: 6}}
                        bg={Colors.Custom.Gray200}
                      />
                      <Pressable style={{width: 24, height: 24, marginHorizontal: 6}} onPress={() => {
                        setPreview(true);
                      }}>
                        <PrintSvg customStrokeColor={Colors.Custom.Gray500} isSelected={true} />
                      </Pressable>
                      <Divider
                        style={{width: 1, height: 24, marginHorizontal: 6}}
                        bg={Colors.Custom.Gray200}
                      />
                      <Pressable style={{width: 24, height: 24, marginHorizontal: 6}} onPress={() => {
                        setState((prev) => {
                          return {
                            ...prev,
                            dashboardAction: true,
                          };
                        });
                      }}>
                        <SettingSvg customStrokeColor={Colors.Custom.Gray500} isSelected={true} />
                      </Pressable>
                    </View>
                    :
                    <></>}
                </View>
              )}
            </View>
          ) : (
            <></>
          )}
          <>
            {
              state?.selectedPowerBiAccountReport?.code === DEFAULT_EMPLOYER_REPORTING_CODE ? (
                <Text
                  style={{
                    marginLeft: isSmallScreen ? 10 : 18,
                    color: Colors.FoldPixel.GRAY250,
                    padding: 4,
                    paddingTop: 0,
                    fontSize: 12,
                  }}
                >
                  {isVbcTenant
                    ? `• All the data points are based on the membership start & end date of members and their dependents. ${intl.formatMessage({id: 'analyticsSyncMessage'})} ${
                        lastSync.fetchingLastSync
                          ? `(fetching date/time...)`
                          : lastSync.lastSyncDateTime &&
                            `, last synced date/time ${getFormattedDate(
                              lastSync.lastSyncDateTime,
                              DATE_FORMATS.REPORT_DATE_FORMAT
                            )}`
                      }.`
                    : `• All the data points are based on the Hint membership start & end date of employees and their dependents. ${intl.formatMessage({id: 'analyticsSyncMessage'})} ${
                        lastSync.fetchingLastSync
                      ? `(fetching date/time...)`
                      : lastSync.lastSyncDateTime &&
                      `, last synced date/time ${getFormattedDate(
                        lastSync.lastSyncDateTime,
                        DATE_FORMATS.REPORT_DATE_FORMAT
                      )}`
                    }.`}
                </Text>
              )
                :
                (
                  <></>
                )
            }
          </>

        </View>

        {!stateData.loading ?
          <View style={{width: '100%', maxWidth: '100%', display: showTabsStructure  && false ? 'flex' : 'none'}}>
            {
              state?.selectedPowerBiAccountReport?.code === DEFAULT_EMPLOYER_REPORTING_CODE ? (
                <CustomTabsView
                  contentTopMargin={0}
                  showHeading={true}
                  heading={selectedTab?.key}
                  canNavigate={false}
                  basePath={''}
                  tabsList={EMPLOYER_DASHBOARD_TABS}
                  extraBigRightView
                  settingsPath={''}
                  tabListWrapperStyle={{
                    paddingHorizontal: 16,
                    paddingVertical: 16,
                    backgroundColor: '#fff',
                  }}
                  selectedTab={selectedTab}
                  onTabClick={(tabItem) => {
                    if (scrollViewEmployer) {
                      const ref: any = document.getElementById(tabItem.key);
                        ref?.scrollIntoView({behavior: 'smooth'});
                    } else {
                      setSelectedTab(tabItem)
                    }
                  }}
                    titleStyle={{fontSize: 14}}
                />
              )
                :
                (
                  <></>
                )
            }

          </View> : <></>}
        <>
          {
            state?.selectedPowerBiAccountReport?.code === DEFAULT_EMPLOYER_REPORTING_CODE ? (
              <View
                style={{
                  height: isEmployerRole()
                    ? height - 140 - 60
                    : props.isFromEmployerListing
                      ? height - 140 - 60
                      : height - 150 - 60,
                  backgroundColor: '#fff',
                  paddingTop: isEmployerRole() && !props.hideTitleSubtitleView ? 16 : 0,
                  marginTop: isEmployerRole() ? 0 : 0,
                  borderTopWidth: 0,
                  borderBottomWidth: 1,
                  borderColor: Colors.Custom.Gray200,
                }}
              >
                {preview ? (
                  <div
                    className="main-container"
                    style={{
                      marginTop: props.isFromEmployerListing ? 20 : 0,
                      // height: props.isFromEmployerListing ? height - 100 : height - 100,
                      overflow: 'scroll',
                      paddingTop: 12,
                    }}
                  >
                    <DashboardDataContext.Provider
                      value={{
                        toDate: dateRange.to,
                        fromDate: dateRange.from,
                        dateRangeType: state.dateRangeType || '',
                        uiToDate: dateRange.uiToDate,
                        uiFromDate: dateRange.uiFromDate,
                        employerName: state?.selected?.name || '',
                        accountPracticeLocations: locationFiltersState.accountPracticeLocations
                      }}
                    >
                      {preview ? (
                        <PrintPreview
                          memberSatisfactionFormList={props.memberSatisfactionFormList || []}
                          isVisible={preview}
                          onCancel={() => {
                            setPreview(false);
                          }}
                          dateRangeType={state.dateRangeType || ''}
                          dateRange={dateRange}
                          employerId={state?.selected?.id || id}
                        />
                      ) : (
                        <></>
                      )}
                    </DashboardDataContext.Provider>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="main-container"
                  style={{
                    marginTop: props.isFromEmployerListing ? 20 : 0,
                    // height: props.isFromEmployerListing ? height - 100 : height - 100,
                    overflow: 'scroll',
                    // paddingTop: 12,
                    backgroundColor: '#F2F4F7',
                    height: '100%'
                  }}
                >
                  <DashboardDataContext.Provider
                    value={{
                      toDate: dateRange?.to,
                      fromDate: dateRange?.from,
                      dateRangeType: state?.dateRangeType || '',
                      uiToDate: dateRange?.uiToDate,
                      uiFromDate: dateRange?.uiFromDate,
                      employerName: state?.selected?.name || '',
                      accountPracticeLocations: locationFiltersState.accountPracticeLocations
                    }}
                  >
                    {stateData.loading ? (
                      <Skeleton.Text lines={5} />
                    ) : (
                      <EmployerAnalytics
                        dateRangeType={state.dateRangeType || ''}
                        dateRange={dateRange}
                        sendDashboardCallBack={(data: any) => {
                          setEmpDashboardState(prev => {
                            return {
                              ...prev,
                              dashboardData: data
                            }
                          })
                        }}
                        dashboardCallback={() => {
                          props.dashboardCallback?.();
                          setState((prev) => {
                            return {
                              ...prev,
                              dashboardAction: false,
                            };
                          });
                        }}
                        isEmployerRole={isEmployerRole()}
                        employerName={state?.selected?.name}
                        employerId={state?.selected?.id || id}
                        dashboardAction={state.dashboardAction}
                        selectedPayerId={empDashboardState.selectedPayerIdAfterUpdate}
                        selectedTab={selectedTab}
                      />
                    )}
                  </DashboardDataContext.Provider>
                </div>
              </View>
            )
              :
              (<View
                style={{
              height:height - 140,
                  backgroundColor: '#fff',
                  paddingTop: isEmployerRole() && !props.hideTitleSubtitleView ? 16 : 0,
                  marginTop: isEmployerRole() ? 0 : 0,
                  borderTopWidth: 0,
                  borderBottomWidth: 1,
                  borderColor: Colors.Custom.Gray200,
                }}
              >
                {
                  state?.selectedPowerBiAccountReport?.code && state?.selectedPowerBiAccountReport?.code !== DEFAULT_EMPLOYER_REPORTING_CODE ? (
                    (
                      <PowerBiView
                        selectedPowerBiWorkspaceReport={state?.selectedPowerBiAccountReport}
                        filters={{
                          dateRange: {
                            fromDate: dateRange?.uiFromDate,
                            toDate: dateRange?.uiToDate,
                            fromDateStr: moment(dateRange?.uiFromDate).format(`${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`),
                            toDateStr: moment(dateRange?.uiToDate).format(`${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`),
                          },
                          selectedEmployerData: empDashboardState?.selectedEmployer,
                          selectedFormData: formData
                        }}
                        powerBiReportMode={state?.powerBiReportMode || PowerBiReportMode.EDIT}
                        onDrillThrowSelection={(selectedData: {
                          contactUuids: string[]
                        })=> {
                          if (selectedData?.contactUuids?.length) {
                            setEmpDashboardState((prev: any) => {
                              return {
                                ...prev,
                                powerBiSelectedData: {
                                  contactUuids: selectedData?.contactUuids || []
                                }
                              }
                            });
                          } else {
                            setEmpDashboardState((prev) => {
                              return {
                                ...prev,
                                powerBiSelectedData: {
                                  contactUuids: []
                                }
                              }
                            });
                          }

                        }}
                      />
                    )
                  )
                    :
                    (
                      <></>
                    )
                }
              </View>
              )
          }
        </>
        {
          empDashboardState.showPayerSelectModal && empDashboardState.selectedPayerId &&
          (
            <PayerConfirmModal onPayerConfirmModalActionPerformed={onPayerConfirmModalActionPerformed} />
          )
        }
      </View>
      {empDashboardState.selectedView === VIEW_CODE.ADD_WIDGET ? (
        <AddWidgetDrawer
          dashboardData={empDashboardState.dashboardData}
          onComplete={() => {
            props.dashboardCallback?.();
            setState((prev) => {
              return {
                ...prev,
                dashboardAction: false,
              };
            });
            setEmpDashboardState(prev => {
              return {
                ...prev,
                selectedView: '',
                powerBiSelectedData: {
                  contactUuids: []
                }
              }
            })
          }}
          onClose={() => {
            setEmpDashboardState(prev => {
              return {
                ...prev,
                selectedView: '',
                powerBiSelectedData: {
                  contactUuids: []
                }
              }
            })
          }} />
      ) : <></>}
      {
        empDashboardState.selectedView === VIEW_CODE.CREATE_POWER_BI_REPORT ? (
          <AddNewPowerBiReport
            onAddNewPowerBiReportActionPerformed={onAddNewPowerBiReportActionPerformed}
            selectedPowerBiAccountReport={state?.selectedPowerBiAccountReport}
          />
        ) : <></>
      }
      {empDashboardState.selectedView === VIEW_CODE.GROUP_MEMBER_VIEW ? (
            <DrillDownMemberList
              appliedFilterData={{} as any}
              resourceCode={'POWER_BI_REPORT'}
              isNonMemberDrillDown={false}
              responseData={{
                contactUuids: empDashboardState?.powerBiSelectedData?.contactUuids || [],
                count: empDashboardState?.powerBiSelectedData?.contactUuids?.length || 0
              }}
              page={1}
              pageSize={empDashboardState?.powerBiSelectedData?.contactUuids?.length}
              total={empDashboardState?.powerBiSelectedData?.contactUuids?.length || 10}
              data={{
                contactUuids: empDashboardState?.powerBiSelectedData?.contactUuids || [],
                count: empDashboardState?.powerBiSelectedData?.contactUuids?.length || 0
              }}
              onPaginationChange={()=> {}}
              title={'Member Details'}
              isFromNewEmployer={true}
              contactUuidList={empDashboardState?.powerBiSelectedData?.contactUuids || []}
              contactDrillDownData={{} as any}
              onCompleteAction={() => {
                setEmpDashboardState((prev) => {
                  return {
                    ...prev,
                    selectedView: '',
                  };
                });
              }}
              onClose={() => {
                setEmpDashboardState((prev) => {
                  return {
                    ...prev,
                    selectedView: '',
                  };
                });
              }}
            />
          ) : (
            <></>
          )}

    </HStack>
  );
}
