import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  tabStyles: {
    paddingHorizontal: 8,
    paddingVertical: 6,
    justifyContent: 'center',
    marginRight: 8,
    alignItems: 'center',
    fontSize: 16,
  },
  searchBarContainerStyles: {
    paddingHorizontal: 16,
    paddingTop: 12,
    paddingBottom: 8,
  },
  conversationTabContainerStyles: {
    backgroundColor: Colors.FoldPixel.GRAY50,
  },
  inputCloseButtonStyles: {
    height: '30px',
    width: '40px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabWrapperStyles: {
    width: '100%',
    backgroundColor: Colors.FoldPixel.GRAY50,
    paddingLeft: 16,
    paddingRight: 4,
    height: 40,
    marginTop: 8,
    marginBottom: 8,
  },
  tabWrapperViewStyles: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabWrapperHstackStyles: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabItemStyles: {
    alignItems: 'center',
  },
  tabItemFilterBadgeViewStyles: {
    position: 'absolute',
    top: -6.5,
    right: -5.5,
    width: 15,
    height: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.danger[500],
    borderRadius: 15,
  },
  tabItemFilterBadgeTextStyles: {
    color: '#ffffff',
    fontWeight: '600',
    fontSize: 10,
  },
  dividerViewStyles: {
    width: 1,
    height: 20,
    backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 12,
  },
  searchButtonStyles: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    marginHorizontal: 2,
  }
});
