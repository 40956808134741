import {ColumnsType} from 'antd/lib/table';
import {Text, Tooltip} from 'native-base';
import {getUSLocaleDateString} from '../../../common/Notifications/NotificationsUtils';
import {ITemplateCategory} from '../EmailTemplates/interfaces';
import {ISMSTemplateTableColumnArgs, SingleSMSTemplate} from './interfaces';
import {SMSTemplatesTableActions} from './SMSTemplatesTableActions';
import {onFilter, sorter} from './SMSTemplatesUtils';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import { Colors } from '../../../../styles';

export const getSMSTemplatesTableColumns = (
  args: ISMSTemplateTableColumnArgs
) => {
  const {actionFn, categories, defaultFilteredValues, defaultSortOrder} = args;
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="Name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      fixed: 'left',
      render(name: string) {
        return (
          <Tooltip label={name} placement="top">
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>{name}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="Category"
        />
      ),
      dataIndex: 'category',
      key: 'category',
      filters: categories,
      filterSearch: true,
      defaultFilteredValue: defaultFilteredValues,
      width: '20%',
      onFilter: onFilter,
      render(category: ITemplateCategory) {
        return <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{category?.name}</Text>;
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="Default"
        />
      ),
      key: 'isDefault',
      dataIndex: 'isDefault',
      width: '10%',
      render(isDefault: boolean) {
        return <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{isDefault ? 'Yes' : 'No'}</Text>;
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="Last Updated"
        />
      ),
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      width: '20%',
      sorter: sorter,
      defaultSortOrder: defaultSortOrder,
      render(lastUpdated: string) {
        const date = new Date(lastUpdated).toLocaleDateString('en-US', {
          year: 'numeric',
          day: 'numeric',
          month: 'long',
        });
        return <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{date}</Text>;
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="Actions"
        />
      ),
      key: 'actions',
      width: '20%',
      fixed: 'right',
      render(record: SingleSMSTemplate) {
        return (
          <SMSTemplatesTableActions
            record={record}
            onActionPerformed={actionFn}
          />
        );
      },
    },
  ];

  return columns;
};
