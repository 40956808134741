import React, {useState} from 'react';
import {
  View,
  Text,
  VStack,
  Pressable
} from 'native-base';
import {MESSAGE_CUSTOM_CARD_TYPE} from '../../../../../../TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import LastMessage from './LastMessage';
import {ILastMessageDrillDownData} from '../../interface';
import {INewConversationResponse} from '../../../../../../TeamInbox/Conversations/interfaces';
import {useLazyQuery} from '@apollo/client';
import ConversationsQueries from '../../../../../../../../services/Conversations/ConversationsQueries';
import {getUserId, getUserUUID} from '../../../../../../../../utils/commonUtils';
import {ILastCommunicationProps, ILastCommunicationState} from './interface';
import {Colors} from '../../../../../../../../styles/Colors';
import {COMMON_ACTION_CODES} from '../../../../../../../../constants/ActionConst';
import InstantChatView from '../../../../../../../common/ChatDrawer/InstantChatView';
import {IContact} from '../../../../../../TeamInbox/Conversations/interfaces';
const LastCommunicationDetails = (props: ILastCommunicationProps) => {
  const {
    onColumnActionPerformed,
    contactData,
  } = props;
  const [componentState, setComponentState] = useState<ILastCommunicationState>({
    selectedConversationData: {} as any,
    isConversationDrawerOpen: false,
  });
  const userUuid = getUserUUID();
  const userId = getUserId();
  const [GetConversationByConversationId] = useLazyQuery<INewConversationResponse>(ConversationsQueries.GetConversationByConversationId,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const getConversationData = async () => {
    if (onColumnActionPerformed) {
      onColumnActionPerformed(COMMON_ACTION_CODES.LOADING);
      const conversationResponse = await GetConversationByConversationId({
        variables: {
          conversationId: parseInt(columnData?.conversationId as any),
          loginUserIntId: userId,
          loginUserUuid: userUuid,
        },
      });
      onColumnActionPerformed(COMMON_ACTION_CODES.LOADING_COMPLETE);
      if (conversationResponse?.data?.conversations?.length) {
        setComponentState((prev) => {
          return {
            ...prev,
            selectedConversationData: conversationResponse?.data?.conversations[0] as any
          };
        });
      }
    }

  }

  const {columnData} = props;
  const isDisplayCallMsgHeader =
    (columnData?.messageType === MESSAGE_CUSTOM_CARD_TYPE.INCOMING_COMPLETED_CALL_CARD) ||
    (columnData?.messageType === MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_COMPLETED_CALL_CARD) ||
    (columnData?.messageType === MESSAGE_CUSTOM_CARD_TYPE.INCOMING_MISSED_CALL_CARD) ||
    (columnData?.messageType === MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_MISSED_CALL_CARD)
  const getMessageElem = () => {
    if (columnData?.messageType === 0 || columnData?.messageType === 1 || isDisplayCallMsgHeader) {
      return <LastMessage columnData={columnData} />;
    } else {
      return (
        <View>
          <Text size={'xsMedium'} color={Colors.Custom.Gray400} >
            No Communication Details
          </Text>
        </View>
      );
    }
  }
  const isDisplayViewDetails = () => {
    return (
      columnData?.content?.length > 1
      && (columnData?.messageType === 0 || columnData?.messageType === 1 || isDisplayCallMsgHeader)
    );
  }
  if (columnData) {
    return (
      <VStack style={{
        maxWidth: 180
      }}>
        <View>
          {getMessageElem()}
        </View>
        {
          isDisplayViewDetails() &&
          (
            <>

              <View>
                <Pressable
                  style={{paddingHorizontal: 3, paddingVertical: 4}}
                  onPress={() => {
                    getConversationData();
                  }} >
                  <Text size='smRegular' color={Colors.FoldPixel.PRIMARY300}>
                    Communication Details
                  </Text>
                </Pressable>
              </View>
              <View>
                {
                  componentState?.selectedConversationData?.id && (
                    <InstantChatView
                      selectedConversation={componentState?.selectedConversationData}
                      contactData={contactData}
                      isLoading={false}
                      isDrawerVisible={true}
                      isInstantChatView={true}
                      instantChatFromNotifications={true}
                      onActionPerformed={(actionCode: any, actionData?: any) => {
                        if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                          setComponentState((prev) => {
                            return {
                              ...prev,
                              selectedConversationData: {} as any
                            }
                          })
                        } else if (actionCode === COMMON_ACTION_CODES.ITEM_CLICKED) {
                          setComponentState((prev) => {
                            return {
                              ...prev,
                              selectedConversationData: actionData,
                            };
                          });
                        }
                      }}
                    />
                  )
                }

              </View>
            </>
          )
        }


      </VStack>
    )
  } else {
    return (
      <View>
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} >
          No Communication Details
        </Text>
      </View>
    );
  }

}
export default React.memo(LastCommunicationDetails);
