import {Colors} from "../../../../styles";
import {ICommonSvgProps} from "../interfaces";


const ProgressArrowSvg = (props: {
  met: boolean
}) => {
  const bgColor = props?.met ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY300;

  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.25 8L2.99516e-06 0.5L0 15.5L11.25 8Z" fill={bgColor}/>
      <line x1="11.75" y1="0.5" x2="11.75" y2="15.5" stroke={bgColor} stroke-linecap="round"/>
    </svg>

  );
};

export default ProgressArrowSvg;
