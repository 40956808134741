import {useApolloClient,useLazyQuery} from "@apollo/client";
import {Table} from "antd";
import {Spinner,VStack} from "native-base";
import {useState} from "react";
import {useIntl} from "react-intl";
import {Dimensions} from "react-native";
import {useNavigate,useParams} from "react-router-dom";
import {COMMON_ACTION_CODES} from "../../../../../constants/ActionConst";
import {CARESTUDIO_APOLLO_CONTEXT} from "../../../../../constants/Configs";
import QualityMeasuresService from "../../../../../services/Contracts/QualityMeasures.service";
import {Colors} from "../../../../../styles";
import LocalStorage from "../../../../../utils/LocalStorage";
import {getAccountUUID} from "../../../../../utils/commonUtils";
import {ToastType} from "../../../../../utils/commonViewUtils";
import {useToast} from "../../../../Toast/ToastProvider";
import PageBodyContainer from "../../../../common/PageBodyContainer/PageBodyContainer";
import {TableWrapper} from "../../../../common/TableWrapper";
import {CUSTOM_FIELD_TOAST_ERROR_DURATION,CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG} from "../../../Contacts/CustomField/CustomFieldConst";
import {ICustomToast} from "../../../Contacts/CustomField/interface";
import {getQualityMeasuresListColumns,getQualityMeasuresListExpendColumns} from '../../HelperFiles/TablesColumnsView';
import {QUALITY_MEASURE_STORAGE_KEY} from "../../HelperFiles/const";
import {IExpandedRowData,IQmMeasureGroup,IQmReportList,IQualityMeasuresList,IQualityMeasuresListView} from "../../HelperFiles/interface";
import {ExecutionDate} from "../../ExecutionDateView";

const QualityMeasuresList = (props: IQualityMeasuresListView) => {
  const {stateData, handlePageChange, data, handleSortChange} = props;
  const {height} = Dimensions.get('window');
  const {contractId} = useParams();
  const accountUuid = getAccountUUID();
  const toast = useToast();
  const intl = useIntl();
  const contractName = data[0]?.contractName;
  const navigate = useNavigate()
  const finalHeight = height - 254;
  const client = useApolloClient();

  const [qmStateData, setStateData] = useState({
    expandLoading: false
  })
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const onActionPerformed = (code: string, data?: any, measureGroup?: any) => {
    const goal = data?.goal;
    const performanceScore = measureGroup?.performanceScore || data?.performanceScore;
    const isInverse = data?.inverse;
    const measureName = data?.name;
    const isGroup = (measureGroup?.groupId || measureGroup?.stratificationId) ? true : false;
    const isStratification = data?.hasSinglePerformance;
    const measureDetails = {
      goal,
      performanceScore,
      isInverse,
      measureName,
      contractName,
      isGroup,
      measureGroup,
      isStratification
    }
    switch (code) {
      case COMMON_ACTION_CODES.MEASURE_NOT_MET:
        LocalStorage.setItem(QUALITY_MEASURE_STORAGE_KEY.MEASURE_DETAILS, JSON.stringify(measureDetails));
        navigate(`/members/contracts/${contractId}/qualityMeasures/${data.measureId}/patients-unqualified`);
        break;
      case COMMON_ACTION_CODES.DETAIL_VIEW:
        LocalStorage.setItem(QUALITY_MEASURE_STORAGE_KEY.MEASURE_DETAILS, JSON.stringify(measureDetails));
        navigate(`/members/contracts/${contractId}/qualityMeasures/${data.measureId}/overview`);
        break;
      case COMMON_ACTION_CODES.SORT_CHANGED:
        handleSortChange(data?.order);
        break;
      default:
        break;
    }
  }
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [expandedRowData, setExpandedRowData] = useState<IExpandedRowData[]>([]);

  const [qualityMeasureReportByMeasureId] = useLazyQuery(
    QualityMeasuresService.qualityMeasureReportById,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const fetchQualityMeasureReports = async (measureId: string, groupId: string, isStratification: boolean) => {
    const filterObject = {
      isLatest: { _eq: true },
      contractId: { _eq: contractId},
      measureId: { _eq: measureId},
      ...(isStratification) ? {stratification: {_eq: groupId}} : {groupId: {_eq: groupId}},
      tenantId: { _eq: accountUuid },
      denominatorFlag: {_eq: true},
    };

    const response = await client.query({
      query: QualityMeasuresService.qualityMeasureReportById,
      variables: {
        filterObjectTotal: filterObject,
        filterObjectMeasureNotMeet: {
          ...filterObject,
          numeratorFlag: { _eq: false },
        },
      },
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    });
    return {
      groupId,
      data: response.data,
    };
  };

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const fetchQualityMeasureReportsByIds = async (measureId: string, groupIds: string[], isStratification: boolean) => {
    const promiseList = groupIds?.map((groupId) => fetchQualityMeasureReports(measureId, groupId, isStratification));
    const results = await Promise.all(promiseList);

    const finalResult = results?.map((report) => {
      return {
        id: report?.groupId,
        reports: report?.data?.qmMeasureReports,
        totalMember: report?.data?.aggregateQmMeasureReportTotal?.aggregate?.count,
        measureNotMeet: report?.data?.aggregateQmMeasureReportMeasureNotMeet?.aggregate?.count,
      }
    })
    return finalResult;
  };

  const processQualityMeasures = (record: any, qmReportList: IQmReportList[]) => {
    const isStratification = record?.hasSinglePerformance;
    const qualityMeasuresList = isStratification ? record?.qmMeasureStratification : record?.qmMeasureGroup;
    qualityMeasuresList.forEach((measure: IQmMeasureGroup) => {
      const id = isStratification ? measure?.stratificationId : measure?.groupId;
      const qmReportsByMeasure = qmReportList.find(qmReport => qmReport.id === id);
      if (qmReportsByMeasure?.id) {
        const qmReportsByNumeratorTrue = qmReportsByMeasure?.reports?.filter(qmReport => qmReport.numeratorFlag);
        const performanceRate = (qmReportsByNumeratorTrue?.length / record?.totalPatients) * 100;
        measure.performanceScore = `${performanceRate.toFixed(2)}%`;
        measure.totalPatients = qmReportsByMeasure?.totalMember;
        measure.careGap = qmReportsByMeasure?.measureNotMeet;
      }
    });
    return qualityMeasuresList;
  };

  const fetchGroupData = async (record: IQualityMeasuresList) => {
    const isStratification = record?.hasSinglePerformance;
    let groupIds;
    if (isStratification) {
      groupIds = record?.qmMeasureStratification?.map(((group: IQmMeasureGroup) => group?.stratificationId));
    } else {
      groupIds = record?.qmMeasureGroup?.map(((group: IQmMeasureGroup) => group?.groupId));
    }
    try {
      const qmReportList = await fetchQualityMeasureReportsByIds(record?.measureId, groupIds || [], isStratification);
      const updatedQualityMeasuresList = processQualityMeasures(record, qmReportList);
      setExpandedRowData((prev) => {
        const updatedData = prev?.map((rowData: IExpandedRowData) => {
          if (rowData?.id === record?.id) {
            return {
              id: rowData.id,
              loading: false,
              groupData: updatedQualityMeasuresList
            }
          } return rowData;
        })
        return updatedData
      })
      setStateData((prev) => {
        return {
          ...prev,
          expandLoading: false
        }
      })
    } catch (err: any) {
      setExpandedRowData((prev) => {
        const updatedData = prev?.map((rowData) => {
          if (rowData?.id === record?.id) {
            return {
              id: rowData.id,
              loading: false,
              groupData: [],
            }
          } return rowData;
        })
        return updatedData
      })
      setStateData((prev) => {
        return {
          ...prev,
          expandLoading: false
        }
      })
      showToast({
        toastType: ToastType.error,
        message: err?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const handleExpand = (expanded: boolean, record: IQualityMeasuresList) => {
    const isStratification = record?.hasSinglePerformance;
    const qualityMeasuresList = isStratification ? record?.qmMeasureStratification : record?.qmMeasureGroup;
    const id = `quality_measures_list_${record.id}`
    setExpandedRowKeys((prevKeys) =>
      expanded ? [...prevKeys, id] : prevKeys.filter((key) => key !== id)
    );
    if (expanded) {
      setExpandedRowData((prev: any) => {
        const data = [...prev, {
          id: record.id,
          loading: true,
          groupData: qualityMeasuresList
        }]
        return data
      })
      setStateData((prev) => {
        return {
          ...prev,
          expandLoading: true
        }
      })
      fetchGroupData(record)
    } else {
      setExpandedRowData((prev: any) => {
        const data = prev.filter((group: any) => group?.id !== record.id)
        return data
      })
      setStateData((prev) => {
        return {
          ...prev,
          expandLoading: false
        }
      })
    }
  };
  return (
    <PageBodyContainer>
      <ExecutionDate date={stateData?.executionDate} loading={stateData?.executionDateLoading}/>
      <TableWrapper
        wrapperStyle={{alignItems: 'center'}}
        minHeight={230}
        pagination={{
          current: stateData?.currentPage,
          pageSize: stateData?.pageSize || 10,
          total: stateData?.total,
          onChange: (currentPage, currentPageSize) => {
            setExpandedRowKeys([]);
            setExpandedRowData([]);
            handlePageChange?.(currentPage, currentPageSize);
          },
          disabled: (stateData?.loading || stateData?.countLoading)
        }}
        hideOnSinglePage={false}
      >
        <Table
          scroll={{x: 1000, y: finalHeight}}
          rowClassName={(record, index) => {
            const isExpanded = expandedRowKeys.includes(`quality_measures_list_${record.id}`);
            return isExpanded ? 'quality-measures-expanded' : 'quality-measures';
          }}
          className={'quality-measures-table'}
          columns={getQualityMeasuresListColumns(onActionPerformed, stateData?.countLoading, expandedRowKeys, handleExpand, qmStateData.expandLoading, stateData?.nameSortOrder)}
          dataSource={data}
          pagination={false}
          loading={{
            spinning: stateData.loading,
            indicator: (
              <VStack justifyContent="center" height={'100%'}>
                <Spinner size={'lg'} />
              </VStack>
            ),
          }}
          rowKey={(row: any) => `quality_measures_list_${row.id}`}
          onRow={(record, rowIndex) => {
            const isDisabled = stateData?.loading || stateData?.countLoading;
            const isExpanded = expandedRowKeys.includes(`quality_measures_list_${record.id}`);
            const totalPatients = record?.totalPatients;
            return {
              onClick: () => {
                if (stateData?.loading || stateData?.countLoading) {
                  return;
                }
                const showExpand = record?.showExpand;
                if (showExpand) {
                  if (stateData?.countLoading) {
                    return;
                  }
                  const key = `quality_measures_list_${record.id}`
                  handleExpand(!expandedRowKeys.includes(key), record);
                  return;
                }
                if (!totalPatients) {
                  showToast({
                    toastType: ToastType.info,
                    message: intl.formatMessage({id: 'noMemberFoundForMeasure'}),
                    duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
                    closeAllPrevToast: true,
                  })
                  return;
                }
                onActionPerformed(COMMON_ACTION_CODES.DETAIL_VIEW, record)
              },
              onMouseEnter: (event) => {
                if (!isExpanded) {
                  event.currentTarget.style.backgroundColor = Colors.FoldPixel.GRAY50;
                }
              },
              onMouseLeave: (event) => {
                if (!isExpanded) {
                  event.currentTarget.style.backgroundColor = "";
                }
              },
              style: {
                cursor: (isDisabled || (totalPatients === 0 && !record?.showExpand)) ? "not-allowed" : "pointer",
                backgroundColor: isExpanded ? Colors.FoldPixel.GRAY50 : "",
              },
            };
          }}
          expandable={{
            expandedRowRender: (record) => {
              return getQualityMeasuresListExpendColumns(record, expandedRowData, onActionPerformed, hoveredIndex, setHoveredIndex)
            },
            expandIconColumnIndex: -1, // Hide the default expand icon column
            onExpand: (expanded, record) => {
              handleExpand(expanded, record)
            },
          }}
          expandedRowKeys={expandedRowKeys}
        />
      </TableWrapper>
    </PageBodyContainer>
  )
}
export default QualityMeasuresList;
