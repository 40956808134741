import {ColumnsType} from 'antd/lib/table';
import {Text, Button, View} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles/Colors';
import { getOnFocusButtonStyles } from '../../../../utils/commonUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {TeamMemberProfile} from '../../Contacts/TeamMembers/TeamMembersTable/TeamMemberProfile';
import {styles} from './TaskPoolSettingStyle';

const getScheduleUserName = (user: any) => {
  const userNames = user?.scheduleUsers
    ?.filter((item: any) => {
      return !!item.userId && user.userId !== item.userId;
    })
    .map((item: any) => {
      return item.userName;
    });
  const userList = userNames || [];
  const last = userList.pop();
  const userNameString = last
      ? ` themself${userList.length ? ',' : ''} `.concat(userList.join(', ') + ' and ' + last)
      : ' themself';
  return userNameString;
};

export const getColumns = (
  actionFn: (actionCode: string, record: any) => {}
): ColumnsType<any> => {
  return [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'userName',
      key: 'userName',
      fixed: 'left',
      width: '20%',
      render: (userName: string) => {
        return <TeamMemberProfile fullName={userName || ''} />;
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '20%',
      render: () => (
        <View style={styles.userNameView}>
          <Text size={'smRegular'} style={[{color: Colors.FoldPixel.GRAY250}]}>
            can manage tasks for
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="users"
        />
      ),
      key: 'scheduleUsers',
      width: '20%',
      render: (record: any) => {
        return (
          <View style={styles.userNameView}>
            <Text size={'smRegular'} style={[{color: Colors.FoldPixel.GRAY250}]}>
              {getScheduleUserName(record)}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="action"
        />
      ),
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: (record: any) => {
        return (
          <Button.Group justifyContent={'flex-start'}>
            <Button
              onPress={() => {
                actionFn('EDIT', record || ({} as any));
              }}
              _focus={getOnFocusButtonStyles()}
              leftIcon={
                <Feather
                  name="edit-2"
                  size={17}
                  style={styles.featherIcon}
                />
              }
              style={styles.transparentButton}
            />
          </Button.Group>
        );
      },
    },
  ];
};
