import { Drawer, Radio, Tooltip } from 'antd';
import { Button, FormControl, HStack, Icon, Input, Spinner, Text, TextArea, useToast, View } from 'native-base';
import { useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE } from '../../../../../constants';
import { Colors } from '../../../../../styles';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { AddCustomContactTypeStatus, IAddCustomContactTypeModalProps, IAddCustomContactTypeState } from '../interfaces';
import { createOrUpdateContactType } from '../CustomContactTypeApi';
import InfoSvg from '../../../../common/Svg/InfoSvg';
import './AddCustomContactTypeModal.css'

const { Label } = FormControl;

const AddCustomContactTypeModal = (props: IAddCustomContactTypeModalProps) => {
  const { isModalOpen, onModalClose, contactTypeData, contactTypeList } = props;
  const toast = useToast();
  const intl = useIntl()
  const [addCustomContactTypeState, setAddCustomContactTypeState] = useState<IAddCustomContactTypeState>({
    newCustomContactTypeStatus: contactTypeData?.status || 'Active',
    newCustomContactTypeName: contactTypeData?.name || '',
    newCustomContactTypeDescription: contactTypeData?.description || '',
    isDefault: contactTypeData ? contactTypeData?.isDefault : false,
    id: contactTypeData?.id,
    loading: false,
    convertToPatientStatus: contactTypeData?.canConvertToPatient || false,
  })
  const finalWidth = 650;

  const [errors, setErrors] = useState<any>({
    newCustomContactTypeNameError: '',
    newCustomContactTypeStatusError: ''
  });

  const validate = () => {
    setErrors({});
    let validate = true
    if (addCustomContactTypeState.newCustomContactTypeStatus.trim() === '') {
      setErrors((prev: any) => ({ ...prev, newCustomContactTypeStatusError: 'Please select status' }));
      validate = false;
    }
    if (addCustomContactTypeState.newCustomContactTypeName.trim() === '') {
      setErrors((prev: any) => ({ ...prev, newCustomContactTypeNameError: 'Please enter a name' }));
      validate = false;
    }

    if (checkIfDuplicateContactType()) {
      setErrors((prev: any) => ({ ...prev, newCustomContactTypeNameError: 'Contact type already exist' }));
      validate = false;
    }
    return validate
  };

  const checkIfDuplicateContactType = () => {
    let isDuplicate = false
    contactTypeList?.forEach((contactType) => {
      if (contactType.name?.toLowerCase() === addCustomContactTypeState.newCustomContactTypeName?.trim().toLowerCase() && contactTypeData?.id !== contactType.id) {
        isDuplicate = true
      }
    });
    return isDuplicate;
  }

  const handleAddOrUpdateCustomContactType = async () => {
    if (validate()) {
      const contactType = {
        value: addCustomContactTypeState.newCustomContactTypeName,
        description: addCustomContactTypeState.newCustomContactTypeDescription,
        isDefault: addCustomContactTypeState.isDefault,
        isActive: addCustomContactTypeState.newCustomContactTypeStatus === AddCustomContactTypeStatus.Active,
        code: addCustomContactTypeState?.code ? addCustomContactTypeState.code: null,
        id: addCustomContactTypeState?.id,
        canConvertToPatient: addCustomContactTypeState?.convertToPatientStatus
    }
      setAddCustomContactTypeState((prev: any) => ({ ...prev, loading: true }))
      try {
        const response = await createOrUpdateContactType(contactType);
        setAddCustomContactTypeState((prev) => ({
          ...prev,
          newCustomContactTypeName: '',
          newCustomContactTypeDescription: '',
          newCustomContactTypeStatus: '',
        }));
        if (response?.data) {
          showToast(toast, props?.isEdit ? 'Contact type updated' : 'Contact type created', ToastType.success)
          props?.onModalClose()
          props?.onSave()
        }
        setAddCustomContactTypeState((prev: any) => ({ ...prev, loading: false }))
        setErrors({});
      } catch (error: any) {
        const message = error?.response?.data?.message || error?.networkError?.result?.message;
        setAddCustomContactTypeState((prev) => ({ ...prev, loading: false }))
        showToast(toast, message || 'Something went wrong', ToastType.error)
      }
    };
  }
  return (
    <Drawer
      width={finalWidth}
      visible={isModalOpen}
      onClose={() => {
        setAddCustomContactTypeState((prev: any) => ({
          ...prev,
          contactTypeStatus: '',
          contactTypeName: {},
          contactTypeDescription: '',
        }));
        setErrors({});
        onModalClose();
      }}
      title={
        <>
          <ModalActionTitle
            title={
              props?.isEdit
                ? 'Update Contact Type'
                : 'Add Contact Type'
            }
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  setAddCustomContactTypeState((prev) => ({
                    ...prev,
                    newCustomContactTypeName: '',
                    newCustomContactTypeDescription: '',
                  }));
                  setErrors({});
                  onModalClose();
                },
              },
              {
                show: true,
                id: 2,
                btnText: props.isEdit ? 'update' : 'create',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                rightIcon: addCustomContactTypeState?.loading && <Spinner />,
                onClick: () => {
                  handleAddOrUpdateCustomContactType();
                },
              },
            ]}
          />
        </>
      }
    >
      <View mx={0}>
        <>
          <FormControl isRequired={true} marginTop={3}>
            <HStack>
              <Label>
                <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={'Status'} />
              </Label>
              <Tooltip
                  title={intl.formatMessage({ id: 'contactTypeStatusTooltip' })}
                  placement={'top'} destroyTooltipOnHide={true} >
                  <View>
                    <Button
                      marginTop={0.5}
                      marginLeft={-2}
                      marginRight={2}
                      height={5}
                      width={5}
                      size={'smMedium'}
                      variant={'unstyled'}
                      _text={{
                        color: Colors.Custom.Gray500,
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                      _hover={{
                        _text: {
                          color: Colors.Custom.PurpleColor,
                        }
                      }}
                      endIcon={<Icon as={AntIcon} name="infocirlceo" size="smMedium" />}
                    />
                  </View>
                </Tooltip>
            </HStack>
            <View>
              <Radio.Group
                onChange={(selectedStatus) => {
                  setAddCustomContactTypeState((prev: any) => ({
                    ...prev,
                    newCustomContactTypeStatus: selectedStatus.target.value
                  }))
                  setErrors((prev: any) => ({
                    ...prev,
                    newCustomContactTypeStatusError: '',
                  }));
                }}
                value={addCustomContactTypeState.newCustomContactTypeStatus}
              >
                <Radio value={AddCustomContactTypeStatus.Active}>
                  {AddCustomContactTypeStatus.Active}
                </Radio>
                <Radio value={AddCustomContactTypeStatus.Inactive}>
                  {AddCustomContactTypeStatus.Inactive}
                </Radio>
              </Radio.Group>
            </View>
            {errors?.newCustomContactTypeStatusError && (
              <Text
                size={'smLight'}
                color="error.500"
                marginTop={2}
                fontSize={12}
                fontWeight={500}
              >
                {errors?.newCustomContactTypeStatusError}
              </Text>
            )}
          </FormControl>
          <FormControl isRequired={true} marginTop={3}>
            <Label>
              <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={'name'} />
            </Label>
            <Input
              _focus={{
                borderColor: Colors.Custom.Gray200,
              }}
              isDisabled={addCustomContactTypeState.isDefault}
              placeholder="Enter Name"
              size={'mdNormal'}
              value={addCustomContactTypeState.newCustomContactTypeName}
              onChangeText={(text: string) => {
                setAddCustomContactTypeState((prev) => ({
                  ...prev,
                  newCustomContactTypeName: text,
                }));
                setErrors((prev: any) => ({
                  ...prev,
                  newCustomContactTypeNameError: '',
                }));
              }}
            />
            {errors?.newCustomContactTypeNameError && (
              <Text
                size={'smLight'}
                color="error.500"
                marginTop={2}
                fontSize={12}
                fontWeight={500}
              >
                {errors?.newCustomContactTypeNameError}
              </Text>
            )}
          </FormControl>
          <FormControl isRequired={false} marginTop={3}>
            <HStack alignItems={'center'}>
              <Label>
                <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={'description'} />
              </Label>
            </HStack>
            <TextArea
              placeholder="Enter Description"
              onChangeText={(text: any) => {
                setAddCustomContactTypeState((prev) => ({
                  ...prev,
                  newCustomContactTypeDescription: text,
                }));
              }}
            >
              {addCustomContactTypeState.newCustomContactTypeDescription}
            </TextArea>
          </FormControl>
          <View style={{
            marginTop: 16,
            flexDirection: 'row',
            alignItems: 'center'
          }}>
            <View style={{
              width: 34,
              height: 19,
              borderRadius: 4,
              alignItems: 'center',
              justifyContent: 'center',
              opacity: !addCustomContactTypeState?.convertToPatientStatus ? 0.3 : 1,
            }}>
              <div className={`convert-patient-custom-switch ${addCustomContactTypeState?.convertToPatientStatus ? 'checked' : ''}`} onClick={() => {
                setAddCustomContactTypeState((prev) => {
                  return {
                    ...prev,
                    convertToPatientStatus: !prev?.convertToPatientStatus
                  }
                })
              }}>
                <div className={`convert-patient-custom-switch-handle ${addCustomContactTypeState?.convertToPatientStatus ? 'checked' : ''}`} ></div>
              </div>
            </View>
            <Text size={'smRegular'} style={{
              marginLeft: 10,
              color: Colors.FoldPixel.GRAY300,
              lineHeight: 14.4,
            }}>
              {intl.formatMessage({id: 'convertibleToPatient'})}
            </Text>
            <Tooltip
              title={intl.formatMessage({id: 'convertibleToPatientTooltipMsg'})}
              placement={'top'} destroyTooltipOnHide={true}
            >
              <View style={{
                marginLeft: 6,
                marginTop: 1,
              }}>
                <InfoSvg customDimension={'16'} customColor={Colors.Custom.Gray500}/>
              </View>
            </Tooltip>
          </View>
        </>
      </View>
    </Drawer>
  );
};

export default AddCustomContactTypeModal;
