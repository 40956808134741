import {HStack, Icon, Text} from "native-base";
import Feather from "react-native-vector-icons/Feather";
import {COMMON_ACTION_CODES} from "../../../../../../constants/ActionConst";
import {Colors} from "../../../../../../styles";
import {ACTIONS_CODE_CT} from "./CostTemplatesConst";
import AntIcon from 'react-native-vector-icons/AntDesign';
import {DisplayText} from "../../../../../common/DisplayText/DisplayText";

export const getIconByCode = (code: string) => {
  let icon = <></>;
  const iconColor = Colors.Custom.Gray500
  switch (code) {
    case COMMON_ACTION_CODES.ADD:
      icon = <Feather
        name="plus"
        size={15}
        color={iconColor}
      />
      break;
    case COMMON_ACTION_CODES.DOWNLOAD:
      icon = <Feather
        name="download"
        size={15}
        color={iconColor}
      />
      break;
    case COMMON_ACTION_CODES.UPLOAD:
      icon = <Feather
        name="upload"
        size={15}
        color={iconColor}
      />
      break;
    case ACTIONS_CODE_CT.SET_AS_DEFAULT:
      icon = <Feather
        name="check-square"
        size={15}
        color={iconColor}
      />
      break;
    case ACTIONS_CODE_CT.BULK_ASSIGN:
      icon = <Feather
        name="users"
        size={15}
        color={iconColor}
      />
      break;
    case ACTIONS_CODE_CT.EDIT:
      icon = <Feather
        name="edit-2"
        size={15}
        color={iconColor}
      />
      break;
    case ACTIONS_CODE_CT.DUPLICATE:
      icon = <Feather
        name="copy"
        size={15}
        color={iconColor}
      />
      break;
    case ACTIONS_CODE_CT.DELETE:
      icon = <Icon
        as={AntIcon}
        name="delete"
        size="4"
        color={'#D92D20'}
        style={{marginBottom: 2}}
        alignSelf={'flex-end'}
      />
      break;
    default:
      break;
  }
  return icon
}


export const addedCptTableColumns = (data: {columnListArray: any, width: number, showModifier: boolean}) => {
  const {width, columnListArray, showModifier} = data;
  const columnList: any = [];
  columnListArray?.forEach((item: string) => {
    if (item === 'MODIFIER') {
      return
    }
    columnList.push({
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500,}}
          textLocalId={item}
        />
      ),
      dataIndex: {item},
      key: {item},
      width:
        item === 'CPT'
          ? 100
          : item === 'NAME'
          ? 300
          : item === 'DEFAULT_COST' || item === 'MODIFIER'
          ? width * 0.3 * 0.25
          : width * 0.3 * 0.15,
      ...((item === 'CPT') && {
        fixed: 'left',
      }),
      render: (text: string, data: any): JSX.Element => {
        const prefix =
          data[item] && (item === 'DEFAULT_COST' || item === 'COST') ? '$' : '';
        const modifier = item === 'CPT' && showModifier && data['MODIFIER'] !== '[]' ? data['MODIFIER'] : ''
        return (
          <HStack
            style={{
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Text
              size={'smRegular'}
              style={{
                lineHeight: 24,
                color:
                  item === 'CPT'
                    ? Colors.FoldPixel.GRAY400
                    : Colors.FoldPixel.GRAY250,
              }}
            >
              {`${prefix}${data[item]} ${modifier ? modifier : ''}` ||
                (item === 'DEFAULT_COST' || item === 'COST' ? 0 : '--') }
            </Text>
          </HStack>
        );
      },
    })
  });
  return columnList
}
