import React from 'react';
import AddOrUpdateTask from '../../../../AddTask/AddOrUpdateTask';
import {getFormDataFromLeadData} from '../../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {PatientData} from '../../interfaces';
import {ParticipantType} from '../../../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {DayOptimizerMenuItemKeys} from '../../DayOptimizerHelper';

const AddTaskWrapper = (props: {
  selectedPatients: PatientData | null;
  activeDrawer: DayOptimizerMenuItemKeys;
  onMenuItemActionPerformed: (
    action: DayOptimizerMenuItemKeys,
    record: any
  ) => void;
}) => {
  const {selectedPatients, activeDrawer, onMenuItemActionPerformed} = props;
  const commonContextData = React.useContext(CommonDataContext);
  const formattedContactData = React.useCallback(
    getFormDataFromLeadData(
      {
        ...selectedPatients,
        name: selectedPatients?.name,
        id: selectedPatients?.patientId,
      },
      commonContextData
    ),
    [selectedPatients]
  );

  const getNewTaskMember = () => {
    return {
      value: selectedPatients?.uuid,
      label: selectedPatients?.name,
      key: selectedPatients?.uuid,
      type: ParticipantType.patient,
      contactData: formattedContactData,
      details: formattedContactData as any,
    }
  };

  return (
    <>
      <AddOrUpdateTask
        saveBtnText={'Send'}
        successMessage={'taskAddedMsg'}
        assignee={{
          value: selectedPatients?.uuid,
          label: selectedPatients?.name,
          key: selectedPatients?.uuid,
          contactId: selectedPatients?.patientId,
          details: formattedContactData,
          contactData: formattedContactData,   
          type:
            selectedPatients?.patient?.patientUuid ||
            selectedPatients?.patient?.patientId
              ? ParticipantType.patient
              : ParticipantType.leads,
        }}
        member={getNewTaskMember()}
        restrictPatientOrLeadSelectionTo={getNewTaskMember()}
        isVisible={activeDrawer === DayOptimizerMenuItemKeys.ADD_TASK}
        onComplete={() => {
          onMenuItemActionPerformed(DayOptimizerMenuItemKeys.ADD_TASK, null);
        }}
        onCancel={() => {
          onMenuItemActionPerformed(DayOptimizerMenuItemKeys.ADD_TASK, null);
        }}
      />
    </>
  );
};

export default AddTaskWrapper;
