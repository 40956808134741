import { Drawer, Timeline, Card } from 'antd';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { Colors } from '../../../../../styles/Colors';
import { BUTTON_TYPE, CAMPAIGN_STATUS, DISPLAY_SLASH_DATE_FORMAT } from '../../../../../constants';
import { Spinner, Text, useToast, View, Box } from 'native-base';
import { SmileOutlined } from '@ant-design/icons';
import SuccessTimelineIcon from '../../../../common/Svg/TimelineIcon/SuccessTimelineIcon';
import Stack from '../../../../common/LayoutComponents/Stack';
import { ReactNode, useEffect, useState } from 'react';
import { getFormattedDate, getFormattedTime } from '../../../../../utils/DateUtils';
import { Pressable } from 'react-native';
import { getCampaignHistory } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { GET_USER_BY_UUID_LIST } from '../../../../../services/User/UserQueries';
import { useLazyQuery } from '@apollo/client';
import { IUser } from '../../../../../Interfaces';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { IUserActionLog } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/IWorkflow';
import { Month_Array } from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/useReccuringEvent/interface';
import PauseTimelineIcon from '../../../../common/Svg/TimelineIcon/PauseTimelineIcon';
import AbortTimelineIcon from '../../../../common/Svg/TimelineIcon/AbortTimelineIcon';
import PlayTimelineIcon from '../../../../common/Svg/TimelineIcon/PlayTimelineIcon';
import { useIntl } from 'react-intl';
import { campaignHistoryStyles } from './styles';

interface ICampaignHistoryRow {
  headlineText?: string;
  reasonText?: string;
  modifiedBy?: string;
  updatedOn?: string;
  showAggregateInfo?: boolean;
  aggregateInfo?: ICampaignAggregateInfo[];
  icon?: ReactNode;
}

interface ICampaignAggregateInfo {
  count: number;
  status: string;
  textColor: string;
  bgColor: string;
}
interface ICampaignHistory {
  open: boolean;
  resourceId: string;
  onClose: () => void;
  currentCampaignStatus?: string;
  currentUpdatedAt?: string;
}

interface ILogBucket {
  bucket: string;
  logs: IUserActionLog[]
}

type ICampaignActions = 'ABORT' | 'RESUME' | 'PAUSE' | 'START' | 'END'

const ACTION_TO_LOG_TEXT_MAP = {
  START : {
    icon: <PlayTimelineIcon/>,
    headlineText: 'campaignStarted'
  },
  ABORT: {
    icon: <AbortTimelineIcon/>,
    headlineText: 'campaignAborted'
  },
  RESUME: {
    icon: <PlayTimelineIcon/>,
    headlineText: 'campaignResumed'
  },
  PAUSE: {
    icon: <PauseTimelineIcon/>,
    headlineText: 'campaignPaused'
  },
  END: {
    icon: <SuccessTimelineIcon/>,
    headlineText: 'campaignEnded'
  }
}

const CampaignHistoryRow = (props: ICampaignHistoryRow) => {
  const dateObj = new Date(props?.updatedOn as string);
  return (
    <Timeline.Item
      dot={props?.icon}
      children={
        <Stack
          direction='column'
          space={4}
          style={campaignHistoryStyles.leftMargin}
        >
          {
            props?.updatedOn &&
            <Text size={'xsNormal'} color={Colors.FoldPixel.GRAY300}>{getFormattedDate(dateObj, DISPLAY_SLASH_DATE_FORMAT)} {'\u2219'} {getFormattedTime(dateObj)}</Text>
          }
          {
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400} lineHeight={16.8} >{props.headlineText}</Text>
          }
          {
            props?.showAggregateInfo &&
            <Stack direction='row' space={4} style={campaignHistoryStyles.stackStyles}>
              {props?.aggregateInfo?.map((obj: ICampaignAggregateInfo) => {
                  return (
                    <View backgroundColor={props?.aggregateInfo?.[0].bgColor} borderRadius={4} alignContent={'center'} justifyContent={'center'}
                     style={{
                      height: '22px',
                      padding: 6,
                    }}>
                      <Text color={props?.aggregateInfo?.[0].textColor}>{props?.aggregateInfo?.[0].count} {props?.aggregateInfo?.[0].status}</Text>
                    </View>
                  );
                })
              }

              {/* <Text>{props?.aggregateInfo?.[0].count} {props?.aggregateInfo?.[0].status}</Text> */}
            </Stack>
          }
          {
            props?.reasonText &&
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300}>Reason: {props.reasonText}</Text>
          }
          { 
            props?.modifiedBy &&
            <Text size={'xsNormal'} color={Colors.FoldPixel.GRAY300}>Modified By {props.modifiedBy}</Text>
          }
        </Stack>
      }
    />
  );
}

const MonthIcon = (props: {text: string}) => {
  return (
    <Box style={campaignHistoryStyles.monthIconStyles} backgroundColor={Colors.FoldPixel.GRAY50}>
      <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300}>{props.text}</Text>
    </Box>
  );
}


const CampaignHistory = (props: ICampaignHistory ) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [logsByMonth, setLogsByMonth] = useState<ILogBucket[]>([]);
  const intl = useIntl();
  const [userNameMap, setUserNameMap] = useState<{[key: string]: string}>({});
  const [getUsersByUuidList] = useLazyQuery(GET_USER_BY_UUID_LIST, {
    fetchPolicy: 'no-cache',
  });

  const toast = useToast();
  const userIdToNamesMap: {[key: string]: string } = {}
  useEffect(()=>{
    const getHistoryLogsAndUserNames = async () => {
      try {
        const historyLogs = await getCampaignHistory(props.resourceId);
        const userUuids = new Set();
        historyLogs.forEach((log) => {
          userUuids.add(log.updatedBy);
        });
  
        const userUuidArray = Array.from(userUuids);
  
        const response = await getUsersByUuidList({
          variables: {
            uuidList: userUuidArray
          },
        });
        const users = response?.data?.users as IUser[]
        users?.forEach((element: IUser) => {
          userIdToNamesMap[element.uuid] = element.name;
        });
        setUserNameMap(userIdToNamesMap);
        // bucketing the logs year and month wise
        const tempLogsByBucketArray: ILogBucket[] = [];
        let currentString = ''
        historyLogs.forEach((historyLog) => {
          const createdOn = historyLog.createdOn as string;
          const dateObj = new Date(createdOn);
          const month = dateObj.getMonth();
          const monthString = Month_Array[month];
          const yearString = dateObj.getFullYear().toString();
          const bucketString = `${monthString} ${yearString}`;

          if (bucketString!=currentString) {
            currentString = bucketString;
            tempLogsByBucketArray.push({
              bucket: bucketString,
              logs: [historyLog]
            })
          } else {
            tempLogsByBucketArray.at(-1)?.logs.push(historyLog);
          }
        });

        setLogsByMonth(tempLogsByBucketArray);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToast(toast, 'Could Not Load Campaign History', ToastType.error);
      }

    };

    getHistoryLogsAndUserNames();
  },[]);

  return (
    isLoading? <Spinner />
    :
    <Drawer
      width={'40%'}
      open={props?.open}
      onClose={() => {
        props?.onClose();
      }}
      title={
        <ModalActionTitle
          title={'History Log'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'Close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props?.onClose();
              },
            }
          ]}
        />
      }
    >
      <Timeline
      >

        <View marginLeft={'32px'}>
          {logsByMonth.map((obj: ILogBucket, index: number) => {
            return (
            <>
              <Timeline.Item
              dot={<MonthIcon text={obj.bucket}/>}
              children={<View marginBottom={'24px'}></View>}
              />
              {
                index===0 && props?.currentCampaignStatus===CAMPAIGN_STATUS.ENDED &&
                <CampaignHistoryRow
                  updatedOn={props?.currentUpdatedAt}
                  icon={ACTION_TO_LOG_TEXT_MAP.END.icon}
                  headlineText={intl.formatMessage({id: ACTION_TO_LOG_TEXT_MAP.END.headlineText})}
                />
              }
              {
                obj.logs.map((logObj) => {
                  const action = logObj.action as ICampaignActions;

                  return (
                    <CampaignHistoryRow
                      icon={ACTION_TO_LOG_TEXT_MAP[action].icon}
                      headlineText={intl.formatMessage({id: ACTION_TO_LOG_TEXT_MAP[action].headlineText})}
                      modifiedBy={userNameMap[logObj.updatedBy]}
                      reasonText={logObj.comment}
                      updatedOn={logObj.createdOn as string}
                    />
                  );
                })
              }
            </>
            );
          })}
          {/* <Timeline.Item
              dot={<MonthIcon month='Jul' year={2024}/>}
              children={<View marginBottom={'20px'}></View>}
          />
          <CampaignHistoryRow
            icon={<SuccessTimelineIcon/>}
            modifiedBy='Cameron Wilson'
            headlineText='Campaign Executed Successfully'
            reasonText='Process no longer needed due to updated workflows and system integration; manual oversight preferred to improved accuracy in critical tasks'
            showAggregateInfo={true}
            aggregateInfo={[
              {
                status: 'Successful',
                count: 98,
                textColor: Colors.Custom.SuccessColor,
                bgColor: Colors.FoldPixel.STATUS_LIGHT_SUCCESS
              }
            ]}
          />
          <Timeline.Item
            dot={<SuccessTimelineIcon/>}
          /> */}
        </View>
      </Timeline>
    </Drawer>
  );
};


export default CampaignHistory;
