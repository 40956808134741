import React from 'react';
import {Skeleton, Text} from 'native-base';
import {View} from 'react-native';
import {Colors} from '../../../../../../styles/Colors';
import {IUser} from '../../../../../../Interfaces/CommonInterfaces';
import { styles } from '../../style';

const DisplayPCPUser = (props: {
  width: number;
  pcpUser: IUser;
  isLoading: boolean;
}) => {
  return (
    <>
      <View
        style={{width: props?.width, display: 'flex', flexDirection: 'column'}}
      >
        {props?.isLoading ? (
          <Skeleton.Text
            style={styles.w100}
            lines={1}
            _line={{borderRadius: 4}}
          />
        ) : props?.pcpUser?.name ? (
          <Text
            wordBreak={'break-word'}
            size={'smRegular'}
            color={Colors.FoldPixel.GRAY250}
            fontSize={14}
          >
            {props?.pcpUser?.name}
          </Text>
        ) : (
          <Text alignSelf={'center'} color={Colors.Custom.Gray500}>
            {'-'}
          </Text>
        )}
      </View>
    </>
  );
};

export default DisplayPCPUser;
