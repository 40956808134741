import {HStack, View, VStack, Text, TextArea} from 'native-base'
import {createRef} from 'react'
import { Colors } from '../../../../styles/Colors';

const GroupWelcomeMessageSetting = (props: {
  groupWebComeTextData: any;
  onGroupWelcomeMsgSave: (groupWebComeText: string) => void;
}) => {
  const {groupWebComeTextData, onGroupWelcomeMsgSave} = props;
  const messageRef: any = createRef();
  const onGroupWelComeMessageChange = () => {
    const welcomeMessageText = messageRef?.current?.value || ''
    onGroupWelcomeMsgSave(welcomeMessageText);
  };

  return (
    <View paddingTop={8}>
      <VStack>
        <VStack
        // flex={0.3}
        mt={3}>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Welcome message for default messaging group</Text>
        </VStack>
        <VStack
        // flex={0.7}
        mt={3}
        >
          <TextArea
            placeholder="Enter text here..."
            placeholderTextColor={Colors.Custom.Gray300}
            defaultValue={groupWebComeTextData.messageText}
            width={600}
            height={100}
            ref={messageRef}
            onBlur={() => {
              onGroupWelComeMessageChange();
            }}
          />
        </VStack>
      </VStack>
    </View>
  )
}

export default GroupWelcomeMessageSetting;
