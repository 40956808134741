import React, {useContext} from 'react';
import Stack from '../../../../../LayoutComponents/Stack';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  getMlovListFromCategory,
  getMlovObjectFromCode,
} from '../../../../../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../../../../../constants';
import {MLOV_CODES} from '../../../../../../../constants/MlovConst';
import {IDoFilter} from '../../../interfaces';
import {Pressable, Text} from 'native-base';
import UnCheckedIconSvg from '../../../../../../../assets/Icons/UnCheckedIconSvg';
import {IMlov} from '../../../../../../../Interfaces';
import CheckedIconSvg from '../../../../../../../assets/Icons/CheckedIconSvg';
import {Colors} from '../../../../../../../styles';
import {doFilterStyles} from './DoFilterStyles';

interface IDoFilterPopoverProps {
  filter: IDoFilter;
  onFilterChange: (filter: IDoFilter) => void;
}

export const DoFilterPopover: React.FC<IDoFilterPopoverProps> = ({
  filter,
  onFilterChange,
}) => {
  const commonData = useContext(CommonDataContext);

  const mlovs =
    getMlovListFromCategory(
      commonData.MLOV,
      MLOV_CATEGORY.USER_DAY_OPTIMIZER_SCHEDULE_STATUS
    ) || [];
  const completedStatusMlov = getMlovObjectFromCode(
    MLOV_CODES.DAY_OPTIMIZER_DONE,
    mlovs
  );
  const todoStatusMlov = getMlovObjectFromCode(
    MLOV_CODES.DAY_OPTIMIZER_TODO,
    mlovs
  );

  const statusMlovs = [todoStatusMlov, completedStatusMlov];

  const handleChange = (statusId?: string) => {
    const newValue = filter?.statusId === statusId ? undefined : statusId;
    onFilterChange({
      statusId: newValue,
    });
  };

  const getStatusText = (statusMlov?: IMlov) => {
    switch (statusMlov?.code) {
      case MLOV_CODES.DAY_OPTIMIZER_DONE:
        return 'Completed';
      case MLOV_CODES.DAY_OPTIMIZER_TODO:
        return 'Open';
      default:
        return '';
    }
  };

  return (
    <Stack direction="column" style={doFilterStyles.container}>
      <Stack direction="column">
        <Text style={doFilterStyles.filterByText}>{"Filter By"}</Text>
        {statusMlovs?.map((statusMlov) => {
          return (
            <Pressable
              key={statusMlov?.id}
              onPress={() => {
                handleChange(statusMlov?.id);
              }}
            >
              {({isHovered}) => {
                const isChecked = filter?.statusId === statusMlov?.id;
                return (
                  <Stack
                    direction="row"
                    style={{
                      alignItems: 'center',
                      paddingVertical: 6,
                      backgroundColor: isHovered
                        ? Colors.FoldPixel.GRAY100
                        : Colors.Custom.White,
                      borderRadius: 4,
                      paddingHorizontal: 8,
                      marginBottom: 2
                    }}
                    space={6}
                  >
                    {isChecked ? <CheckedIconSvg /> : <UnCheckedIconSvg />}
                    <Text
                      size={'smRegular'}
                      style={{
                        fontSize: 14,
                        color: Colors.FoldPixel.GRAY400,
                      }}
                    >
                      {getStatusText(statusMlov)}
                    </Text>
                  </Stack>
                );
              }}
            </Pressable>
          );
        })}
      </Stack>
    </Stack>
  );
};
