// Views
import {ColumnsType} from 'antd/lib/table';
import {Text, View} from 'native-base';
import React from 'react';
import {IntlShape} from 'react-intl';
import {Colors} from '../../../../styles';
import TaskPoolActions from './TaskTablActions';
import {StyleSheet} from 'react-native';

export const getColumns = (
  intl: IntlShape,
  onBottomViewAction: (task: any, action: any) => void
): ColumnsType<any> => {
  return [
    {
      title: (
        <Text size={'thMedium'} style={styles.columnTitle}>
          {intl.formatMessage({id: 'Name'})}
        </Text>
      ),
      key: 'Name',
      dataIndex: 'name',
      width: '20%',
      fixed: 'left',
      render: (name: string) => {
        return (
          <View flexDirection="row">
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
              {name}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <Text size={'thMedium'} style={styles.columnTitle}>
          {intl.formatMessage({id: 'Users'})}
        </Text>
      ),
      key: 'users',
      width: '60',
      dataIndex: 'userPoolUsers',
      render: (userPoolUsers: any[]) => {
        let usersDisplayString = '';
        userPoolUsers.map((userItem, index) => {
          if (index === userPoolUsers.length - 1) {
            usersDisplayString = usersDisplayString + userItem?.user?.name;
          } else {
            usersDisplayString =
              usersDisplayString + userItem?.user?.name + ', ';
          }
        });
        return (
          <View flexDirection="row">
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
              {usersDisplayString}
            </Text>
          </View>
        );
      },
    },
    {
      title: (
        <Text
          size={'thMedium'}
          style={styles.actionsTitle}
        >
          {intl.formatMessage({id: 'actions'})}
        </Text>
      ),
      key: 'action',
      width: '20%',
      fixed: 'right',
      render: (task: any) => {
        return (
          <View flex={0.25}>
            <TaskPoolActions
              onBottomViewAction={onBottomViewAction}
              record={task}
            />
          </View>
        );
      },
    },
  ];
};

const styles = StyleSheet.create({
  columnTitle: {
    color: Colors.FoldPixel.GRAY300,
  },
  actionsTitle: {
    color: Colors.Custom.Gray500,
    marginLeft: 8,
  },
});
