import {Bar, Column, ColumnConfig, Line, LineConfig} from '@ant-design/charts';
import {Skeleton} from 'antd';
import {Pressable, Tooltip} from 'native-base';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {ANALYTICS_RESOURCE, DATE_FORMATS, DISPLAY_TYPE, EMPLOYER_REPORT_FILTER_CODES, MOMENT_DATE_CONST} from '../../../../../../constants';
import {getSectionDataByResourceCode} from '../../../../../../services/Employer/EmployerServices/EmployerServices';
import {getEmployerReportFilterList} from '../../../../../../utils/commonUtils';
import {getFormattedDateStringInTimeZone} from '../../../../../../utils/DateUtils';
import RestSvgIcons from '../../../../../common/Svg/RestSvgIcons';
import {capitalizeText} from '../../../../ContentManagement/CampaignManagement/Helper';
import {COST_TYPE_WORDS, WIDGET_ACTION_CODE} from '../../EmployerUtils/EmployerConst';
import {getFormattedBarChartsAppointmentWidgetData, getFormattedUtilizationWidgetData, getTopDataViewForAppointmentChartView} from '../../EmployerUtils/FormateDataUtils';
import {aggregateGroupEntry, getDateRangeFilterFromAppliedFilter, getXAxisTitleByResourceCode, getYAxisTitleByResourceCode, isWidgetDataEmpty} from '../../EmployerUtils/WidgetUtils';
import {graphColorList, graphMetaDataColor} from '../../graphColors';
import {IAppliedFilter, IContactMembershipsData, IResource, IWidgetCardView} from '../../interface';
import LocationFilterDropdown from '../../LocationFilterDropDown';
import {APPOINTMENT_AGGREGATE_DATA, APPOINTMENT_TYPE_DATA, ENGAGEMENT_ON_APPLIED_RULES_DATA_DIABETIC, ENGAGEMENT_ON_APPLIED_RULES_DATA_HTN, getTenantForDemo, TEMP_APPOINTMENT_BY_DAY, TEMP_APPOINTMENT_BY_TIME, USER_MESSAGING_DATA} from '../../tempDataFile';
import WidgetHeader from '../../WidgetHeader/WidgetHeader';
import CustomTooltip from '../MixMultipleCharts/CustomTooltip';
import NodataViewTypeOne from '../NoDataView/NodataViewTypeOne';
import {getFormattedMemberType, getYAxisMaxValueWidgetObject, xAxisLabelFormatter} from '../utils';
import { XL_CONFIG } from '../../../../../../theme/FontConfig/TextFontSizeConfig';

const AppointMentWidgets = (props: IWidgetCardView) => {
  const {
    sectionData,
    dateRange,
    employerId,
    isAccountDashboard,
    memberType,
    hintMembershipDisabled,
  } = props;
  const refs: any = useRef();
  const containerRef: any = useRef()
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    donutChartData: [] as any[],
    resourceData: [] as IResource[] | any,
    loading: false,
    appliedFilter: props.appliedFilter,
    filterValueName: [] as string[],
    filterValueString: '',
    selectedAppliedFilter: [] as any[],
    accountPracticeLocations: props.accountPracticeLocations,
    selectedLocation: props.accountPracticeLocations[0]?.uuid || ''
  });

  const onActionClick = (code: string, data?: any) => {
    switch (code) {
      case WIDGET_ACTION_CODE.RESET_LOCATION_FILTER:
        const filteredWidgetFilter = stateData.selectedAppliedFilter.filter(item => {
          return item.filterCode != 'LOCATION'
        })
        setStateData(prev => {
          return {
            ...prev,
            selectedAppliedFilter: filteredWidgetFilter,
            filterValueString: '',
            filterValueName: []
          }
        })
        getSectionByResourceCode();
        break;
      default:
        props.onActionClick?.(code, data)
        break;
    }
  };

  const getTotalCountForLegend = (dataList1: any[], dataList2?: any[]) => {
    const res: any = {};
    dataList1?.forEach(function (el: any) {
      const type = el?.type || el?.type1 || el?.type2;
      const value = el?.count || el?.value || el?.value1 || el?.value2;
      res[type] = (res[type])
        ? res[type] += +value
        : +value;
    });
    dataList2?.forEach(function (el: any) {
      const type = el?.type || el?.type1 || el?.type2;
      const value = el?.count || el?.value || el?.value1 || el?.value2;
      res[type] = (res[type])
        ? res[type] += +value
        : +value;
    });
    return Object.keys(res).map(function (el) {
      return {type: el, count: res[el]};
    });
  };

  const formattedResourceData = getFormattedBarChartsAppointmentWidgetData(stateData.resourceData?.result, sectionData?.resourceCode || '', sectionData.currentDisplayType || '');
  const graphYAxisTitle = getYAxisTitleByResourceCode(sectionData.resourceCode || '')
  const graphXAxisTitle = getXAxisTitleByResourceCode(sectionData.resourceCode || '')
  let containerWidth = 400;
  if (containerRef?.current?.offsetWidth) {
    containerWidth = parseInt((containerRef?.current?.offsetWidth) as any) - 64;
  }
  const getBarChartViewByType = () => {
    let ele = <></>;
    let configGroup: any;
    switch (sectionData.currentDisplayType) {
      case DISPLAY_TYPE.APPOINTMENT_WITH_GROUP_BAR_VIEW:
        const configGroups: ColumnConfig = {
          data: formattedResourceData,
          isGroup: true,
          xField: 'groupBy',
          color: graphColorList,
          yField: 'value',
          seriesField: 'type',
          autoFit: true,
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style: any) {
                return {
                  ...style,
                  r: 4
                }
              },
            }
          },
          xAxis: {
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60,
            },
            tickLine: {
              style: {
                fill: '#fff',
                stroke: '#fff',
              },
            },
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              formatter(text: string) {
                  return xAxisLabelFormatter(sectionData.resourceCode || '', text)
              },
              rotate: -1
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
          },
          yAxis: {
            title: {
              text: graphYAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          label: {
            formatter: () => {
              return ''
            }
          },
          height: 240,
          columnStyle(datum: any) {
            return {
              radius: [8, 8, 0, 0],
              // opacity: 0.3,
            };
          },
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data?.type) {
                onReady(args);
              }
            });
          },
        };
        ele = <Column {...configGroups} />;
        break;
      case DISPLAY_TYPE.APPOINTMENT_WITH_STACK_BAR_VIEW:
        const yAxisMaxValue = getYAxisMaxValueWidgetObject(formattedResourceData, sectionData.resourceCode);
        const configStack: ColumnConfig = {
          data: formattedResourceData,
          xField: 'groupBy',
          color: graphColorList,
          yField: 'value',
          seriesField: 'type',
          autoFit: true,
          isStack: true,
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style: any) {
                return {
                  ...style,
                  r: 4
                }
              },
            }
          },
          xAxis: {
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60,
            },
            tickLine: {
              style: {
                fill: '#fff',
                stroke: '#fff',
              },
            },
            label: {
              offset: 20,
              style: {
                fontSize: 11,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              formatter(text: string) {
                  return xAxisLabelFormatter(sectionData.resourceCode || '', text)
              },
              rotate: -1.1
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
          },
          yAxis: {
            title: {
              text: graphYAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            },
            ...(yAxisMaxValue)
          },
          label: {
            formatter: () => {
              return ''
            }
          },
          tooltip: {
            shared: true,
            customContent : (title: string, data: any[]) => {
              const allMembersColumns: any[] = [];
              data.forEach((singleItem) => {
                allMembersColumns.push(singleItem?.data?.type?.toLowerCase())
              })
              const totalMetaData: any[] = [
                {
                  title: 'Total',
                  columnList: allMembersColumns,
                }
              ];
              if (title && data?.length) {
                return <CustomTooltip title={title} data={data} totalMetaData={totalMetaData} />
              }
            }
          },
          height: 240,
          annotations: aggregateGroupEntry(sectionData.resourceCode || '', formattedResourceData),
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data?.type) {
                onReady(args);
              }
            });
          },
        };
        ele = <Column {...configStack} />;
        break;
      case DISPLAY_TYPE.APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW:
        configGroup = {
          data: formattedResourceData,
          yField: 'groupBy',
          xField: 'value',
          seriesField: 'type',
          isStack: true,
          color: graphColorList,
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style: any) {
                return {
                  ...style,
                  r: 4
                }
              },
            }
          },
          label: {
            position: 'middle',
            style: {
              fill: null
            },
            formatter: () => {
              return ''
            }
          },
          xAxis: {
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            title: {
              text: graphXAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            },
          },
          yAxis: {
            tickLine: {
              style: {
                fill: '#fff',
                stroke: '#fff',
              },
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              formatter: (text: string) => {
                if (sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE) {
                  return text.replace(/^[a-z]|[A-Z]/g, (c, i) => (i ? " " : "") + c.toUpperCase());
                }
                // return wordWrapFromChart(text, 50);
                return `${text.substring(0, 30)} ${text?.length < 30 ? '' : '...'}`;
              },

            },
          },
          tooltip: {
            shared: true,
            customContent : (title: string, data: any[]) => {
              const allMembersColumns: any[] = [];
              data.forEach((singleItem) => {
                allMembersColumns.push(singleItem?.data?.type?.toLowerCase())
              })
              const totalMetaData: any[] = [
                {
                  title: 'Total',
                  columnList: allMembersColumns,
                }
              ];
              if (title && data?.length) {
                if (sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE) {
                  title = title?.replace(/\b\w/g, (match) => match.toUpperCase())
                    .replace(/([A-Z])/g, ' $1');
                }
                return <CustomTooltip title={title} data={data} totalMetaData={totalMetaData} />
              }
            }
          },
          height: 240,
          width: 470,
          // barBackground: {
          //   style: {
          //     fill: 'transparent',
          //     points: [{}],
          //     lineDash: [0]
          //   },
          // },
          // barStyle(datum:any) {
          //   return {
          //     radius: [2, 2, 0, 0],
          //     fill: '#B692F6',
          //     lineDash: [0],
          //     points: [{}]
          //   };
          // },
          interactions: [
            {
              type: 'element-selected',
            },
            {
              type: 'element-active',
            },
          ],
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data) {
                onReady(args);
              }
            });
          },
        };
        ele = <Bar {...configGroup} />;
        break;
      case DISPLAY_TYPE.APPOINTMENT_WITH_HORIZONTAL_GROUP_VIEW:
        configGroup = {
          data: formattedResourceData,
          yField: 'groupBy',
          xField: 'value',
          seriesField: 'type',
          isGroup: true,
          color: graphColorList,
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style: any) {
                return {
                  ...style,
                  r: 4
                }
              },
            }
          },
          label: {
            position: 'middle',
            style: {
              fill: null
            },
            formatter: () => {
              return ''
            }
          },
          xAxis: {
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            title: {
              text: graphXAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            },
          },
          yAxis: {
            tickLine: {
              style: {
                fill: '#fff',
                stroke: '#fff',
              },
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              formatter: (text: string) => {
                if (sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE) {
                  return text.replace(/^[a-z]|[A-Z]/g, (c, i) => (i ? " " : "") + c.toUpperCase());
                }
                // return wordWrapFromChart(text, 50);
                return `${text.substring(0, 40)} ${text?.length < 40 ? '' : '...'}`;
              },

            },
          },
          height: 240,
          width: 470,
          tooltip: {
            title(title: string) {
              if (sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE) {
                return title?.replace(/\b\w/g, (match) => match.toUpperCase())
                  .replace(/([A-Z])/g, ' $1');
              }
              return title
            },
          },
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data) {
                onReady(args);
              }
            });
          },
        };
        ele = <Bar {...configGroup} />;
        break;
      case DISPLAY_TYPE.HORIZONTAL_GROUP_BAR_WITH_LINE_VIEW:
        let formattedDataForLineChart: any[] = [];
        containerWidth = 470;
        if (formattedResourceData?.length) {
          if (refs?.current?.offsetWidth) {
            containerWidth = parseInt(((containerRef?.current?.offsetWidth * 49) / 100) as any);
          }
        }
        configGroup = {
          data: formattedResourceData,
          yField: 'groupBy',
          xField: 'value',
          seriesField: 'type',
          isGroup: true,
          color: graphColorList,
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style: any) {
                return {
                  ...style,
                  r: 4
                }
              },
            }
          },
          label: {
            position: 'middle',
            style: {
              fill: null
            },
            formatter: () => {
              return ''
            }
          },
          yAxis: {
            tickLine: {
              style: {
                fill: '#fff',
                stroke: '#fff',
              },
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              formatter: (text: string) => {
                if (sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE) {
                  return text.replace(/^[a-z]|[A-Z]/g, (c, i) => (i ? " " : "") + c.toUpperCase());
                }
                // return wordWrapFromChart(text, 50);
                return `${text.substring(0, 40)} ${text?.length < 40 ? '' : '...'}`;
              },
              itemSpacing: 8,
              position: 'bottom',
              layout: 'horizontal',
            },
          },
          xAxis: {
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          height: 240,
          width: containerWidth - 40,
          barBackground: {
            style: {
              fill: 'transparent',
              points: [{}],
              lineDash: [0]
            },
          },
          // barStyle(datum:any) {
          //   return {
          //     radius: [2, 2, 0, 0],
          //     fill: '#B692F6',
          //     lineDash: [0],
          //     points: [{}]
          //   };
          // },
          interactions: [
            {
              type: 'active-region',
              enable: false,
            },
          ],
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data) {
                onReady(args);
              }
            });
          },
        };
        const dateFilter = getDateRangeFilterFromAppliedFilter(props.appliedFilter)
        const barChartElem = <Bar {...configGroup} />;
        if (stateData?.resourceData?.additionalData?.finalBPAndBMIList?.length) {
          formattedDataForLineChart = getFormattedUtilizationWidgetData(
            stateData?.resourceData?.additionalData?.finalBPAndBMIList,
            dateFilter,
            sectionData?.resourceCode || '',
            {} as IContactMembershipsData,
            isAccountDashboard
          );
        }
        const multipleLineConfigGroup: LineConfig = {
          data: formattedDataForLineChart,
          xField: 'month',
          yField: 'value',
          seriesField: 'type',
          smooth: true,
          height: 240,
          width: containerWidth - 5,
          tooltip: {
            formatter: (data: any) => {
              const isCost = COST_TYPE_WORDS.find((typeValue) => {
                const typeName = data.type.toLowerCase();
                return typeName.includes(typeValue)
              })
              const value = data.value;
              return {
                name: data.type?.replace(/^[a-z]|[A-Z]/g, (c: any, i: any) => (i ? "" : "") + c.toUpperCase()).replace('Amount', ''),
                value: value
              }
            },
          },
          renderer: 'svg',
          xAxis: {
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60
            },
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              rotate: -1
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          yAxis: {
            title: {
              text: graphYAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              formatter: (text: any) => {
                const value: any = text
                return value
              },
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          legend: {
            position: 'top-right',
            layout: 'horizontal',
            itemSpacing: 3,
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style: any) {
                return {
                  ...style,
                  fill: style.stroke,
                  stroke: null,
                  r: 4
                }
              },
            }
          },
          color: graphColorList,
          point: {
            shape: ({type}: any) => {
              return 'circle';
            },
          },
        };
        const lineChartElem = <Line {...multipleLineConfigGroup} />;
        ele = (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                padding: '15px 5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '48%'
              }}
            >
              {stateData.loading ? (
                <Skeleton active={true} />
              ) : formattedResourceData.length ? (
                <div className='canvas-wrapper' style={{width: '100%'}}>
                  {barChartElem}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 250,
                    height: 250,
                  }}
                >
                  <NodataViewTypeOne viewCode="TEXT_ICON" />{' '}
                </div>
              )}
            </div>
            <div style={{alignItems: 'center', width: '48%'}}>
              <div
                style={{
                  height: '89%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <main
                  id={sectionData.resourceCode}
                  style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: 'inherit',
                    padding: '15px 5px',
                  }}
                >
                  {stateData.loading ? (
                    <Skeleton active={true} />
                  ) : formattedDataForLineChart.length ? (
                    <div className='canvas-wrapper'>
                      {lineChartElem}
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 980,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <NodataViewTypeOne viewCode="TEXT_ICON" />
                    </div>
                  )}
                </main>
              </div>
            </div>
          </div>
        );
        break;
      case DISPLAY_TYPE.APPOINTMENT_WITH_HORIZONTAL_VIEW:
        configGroup = {
          data: formattedResourceData,
          xField: 'count',
          yField: 'name',
          xAxis: {
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60
            },
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          color: graphColorList,
          yAxis: {
            tickLine: {
              style: {
                fill: '#fff',
                stroke: '#fff',
              },
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
              formatter: (text: string) => {
                // return wordWrapFromChart(text, 50);
                return `${text.substring(0, 40)} ${text?.length < 40 ? '' : '...'}`;
              },
            },
            title: {
              text: graphYAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          height: 240,
          width: 470,
          legend: {
            itemSpacing: 2,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text: any) {
                return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style: any) {
                return {
                  ...style
                }
              },
            }
          },
          label: {
            position: 'middle',
            formatter: () => {
              return ''
            }
          },
          barBackground: {
            style: {
              points: [{}],
              lineDash: [0]
            },
          },
          barStyle(datum: any) {
            return {
              radius: [8, 8, 8, 8],
              lineDash: [0],
              points: [{}]
            };
          },
          interactions: [
            {
              type: 'active-region',
              enable: false,
            },
          ],
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data) {
                onReady(args);
              }
            });
          },
        };
        ele = <Bar {...configGroup} />;
        break;
    }
    return ele;
  };
  const onReady = (data: any) => {
    const dateRangeFilterObj = props.appliedFilter?.find(item => {
      return item.filterCode === "DATE_RANGE"
    })
    let filterData = {} as any;
    const fromDateString =
      sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY
        || sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME
        || sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_TYPE 
        || sectionData.resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_DAY
        || sectionData.resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_TIME
        ? dateRangeFilterObj?.filterValue?.fromDate || dateRangeFilterObj?.filterValue?.currentDateRange?.fromDate
        : data?.data?.data?.date;
    const toDateString =
      sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY
        || sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME
        || sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_TYPE
        || sectionData.resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_DAY
        || sectionData.resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_TIME
        ? dateRangeFilterObj?.filterValue?.toDate || dateRangeFilterObj?.filterValue?.currentDateRange?.toDate
        : data?.data?.data?.date;

    const fromDate = getFormattedDateStringInTimeZone(
      fromDateString,
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.START_OF
    );
    const toDate = getFormattedDateStringInTimeZone(
      toDateString,
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.END_OF
    );
    let type: string = data?.data?.data?.type;
    let dataType = capitalizeText(type);
    const dateOrMonth = data?.data?.data?.date || data?.data?.data?.month
    switch (sectionData.resourceCode) {
      // case ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME:
      case ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY:
      case ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME:
      case ANALYTICS_RESOURCE.APPOINTMENT_TYPE:
      case ANALYTICS_RESOURCE.TELEPHONE_BY_DAY:
      case ANALYTICS_RESOURCE.TELEPHONE_BY_TIME:
        let typeList: string[] = [];
        const memberType = getFormattedMemberType(type?.toLocaleLowerCase());
        if (memberType === 'employee') {
          typeList = ['employee'];
        } else if (memberType === 'dependent') {
          typeList = ['father', 'spouse', 'child'];
        } else if (memberType === 'selfsponsored') {
          typeList = ['self-sponsored'];
        } else if (memberType === 'oneoff') {
          typeList = ['one-off'];
        } else {
          typeList = [type]
        }
        const groupBy = data?.data?.data?.groupBy;
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value,
          },
          {
            filterCode: 'DASHBOARD_DATE_RANGE',
            filterValue: dateRange
          },
          {
            filterCode: ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME == sectionData.resourceCode 
            || ANALYTICS_RESOURCE.TELEPHONE_BY_TIME == sectionData.resourceCode
              ? 'TIME_GROUP'
              : ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY == sectionData.resourceCode ||
              ANALYTICS_RESOURCE.TELEPHONE_BY_DAY == sectionData.resourceCode
                ? 'DAY_NAME'
                : ANALYTICS_RESOURCE.APPOINTMENT_TYPE == sectionData.resourceCode
                  ? 'APPOINTMENT_TYPE'
                  : '',
            filterValue: [groupBy]
          }
        ];
        if (stateData.selectedLocation && stateData.selectedLocation !== 'ALL') {
          filterData.push({
            filterCode: "LOCATION",
            filterValue: [stateData.selectedLocation]
          })
        }
        if (
          sectionData.resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_DAY ||
          sectionData.resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_TIME
        ) {
          filterData.push( {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.CALL_TYPE,
            filterValue: {
              value: data?.data?.data?.filterCode,
            },
            count: data?.data?.data?.value
          })
        } else {
          filterData.push( {
            filterCode: 'EMPLOYEE_TYPE',
            filterValue: typeList,
            count: data?.data?.data?.value
          },)
        }
        const day = ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY || ANALYTICS_RESOURCE.TELEPHONE_BY_DAY == sectionData.resourceCode ? groupBy : '';
        filterData = [...filterData, ...stateData.selectedAppliedFilter]
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth, day});
        break;
      case ANALYTICS_RESOURCE.ENGAGEMENT_ON_APPLIED_RULES:
        const ruleIds = data?.data?.data?.code;
        type = data?.data?.data?.groupBy || data?.data?.data?.type;
        dataType = capitalizeText(type);
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.RULE_ID,
            filterValue: {
              value: [ruleIds]
            },
          },
        ];
        filterData = [...filterData, ...stateData.selectedAppliedFilter]
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      default:
        break;
    }
  };
  const topViewData = getTopDataViewForAppointmentChartView(
    formattedResourceData,
    sectionData.resourceCode || ''
  );
  const getTopViewWithData = (
    <div
      style={{
        borderWidth: 1,
        borderBottomColor: '#EDEDED',
        borderBottomStyle: 'solid',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '20px 26px',
      }}
    >
      <div style={{marginRight: 20}}>
        <div
          style={{
            color: '#000',
            ...XL_CONFIG.xlBold,
            marginBottom: 2,
          }}
        >
          {topViewData.type1?.name}
        </div>
        <div
          style={{
            color: '#000',
            ...XL_CONFIG.xlMedium
          }}
        >
          {topViewData.type1?.count}
        </div>
      </div>
      <div>
        <div
          style={{
            color: '#000',
            ...XL_CONFIG.xlBold,
            marginBottom: 2,
          }}
        >
          {topViewData.type2?.name}
        </div>
        <div
          style={{
            color: '#000',
            ...XL_CONFIG.xlMedium
          }}
        >
          {topViewData.type2?.count}
        </div>
      </div>
    </div>
  );
  const barChartView = useMemo(() => {
    return getBarChartViewByType()
  }, [stateData.loading])
  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let formId = '';
    if (sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION && sectionData?.additionalAttributes?.formId) {
      formId = sectionData?.additionalAttributes?.formId;
    }
    let filterList: any = appliedFilter?.length ? appliedFilter : stateData.appliedFilter
    let appliedFilterList = [];
    if (sectionData?.appliedFilter) {
      appliedFilterList = getEmployerReportFilterList(sectionData?.appliedFilter);
      if (appliedFilterList?.length && filterList?.length) {
        filterList = [...filterList, ...appliedFilterList];
      } else if (appliedFilterList?.length) {
        filterList = [...appliedFilterList];
      }
    }
    if (hintMembershipDisabled) {
      filterList.push({
        filterCode: EMPLOYER_REPORT_FILTER_CODES.MEMBERSHIP, 
        filterValue: hintMembershipDisabled
      })
    }
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      formId,
      isAccountDashboard,
      memberType,
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && isWidgetDataEmpty(response?.data)) {
      const resourceData = response.data;
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
        };
      });
    } else {
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          loading: true
        }
      })
      let resourceData: any =
        sectionData.resourceCode == ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY
          ? TEMP_APPOINTMENT_BY_DAY
          : sectionData.resourceCode == ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME
            ? TEMP_APPOINTMENT_BY_TIME
            : sectionData.resourceCode == ANALYTICS_RESOURCE.APPOINTMENT_TYPE
              ? APPOINTMENT_TYPE_DATA
              : sectionData.resourceCode == ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE
                ? APPOINTMENT_AGGREGATE_DATA
                : sectionData.resourceCode == ANALYTICS_RESOURCE.USER_MESSAGING
                  ? USER_MESSAGING_DATA
                  : [];
      if (sectionData?.additionalAttributes && Object.keys(sectionData?.additionalAttributes || {})?.length) {
        const sectionAdditionalAttributes = sectionData?.additionalAttributes;
        if (sectionAdditionalAttributes?.code === 'HTN'
          || sectionData?.name?.includes('HTN')
          || sectionData?.name?.includes('Hypertension')
        ) {
          resourceData = ENGAGEMENT_ON_APPLIED_RULES_DATA_HTN;
        } if (sectionAdditionalAttributes?.code === 'DIABETIC'
          || sectionData?.name?.includes('Diabetic')
        ) {
          resourceData = ENGAGEMENT_ON_APPLIED_RULES_DATA_DIABETIC;
        }
      }
      setTimeout(() => {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: resourceData,
            loading: false
          }
        })
      }, 3000)
      return;
    }

    if (stateData.selectedLocation && stateData.selectedLocation !== 'ALL') {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedLocationFilter: any = {
        filterCode: 'LOCATION' ,
        filterValue: stateData.selectedLocation ?  [stateData.selectedLocation] : []
      }
      appliedFilter.push(selectedLocationFilter);
      getSectionByResourceCode(appliedFilter);
    } else {
      getSectionByResourceCode();
    }
    return () => controller?.abort();
  }, [sectionData.id, stateData.selectedLocation]);

  const getRefreshButtonOnLocalEnv = () => {
    if (window.location.host.includes('localhost')) {
      return (
        <Pressable
          style={{marginHorizontal: 3}}
          onPress={() => {
            setStateData(prev => {
              return {
                ...prev,
                filterValueName: [],
                filterValueString: ''
              }
            })
            getSectionByResourceCode();
          }}
        >
          <Tooltip label="Refresh">
            <RestSvgIcons />
          </Tooltip>
        </Pressable>
      )
    }
    return <></>;
  }
  return (
    <div ref={refs} className="widgetBoxShadow">
      <WidgetHeader isPrintPreview={props.isPrintPreview} isPrintDownload={props.isPrintDownload} refreshButtonOnLocalEnv={getRefreshButtonOnLocalEnv()} sectionData={sectionData} onActionClick={onActionClick} />
      {stateData.loading ? (
        <Skeleton style={{padding: 40}} active={true} />
      ) : (
        <>
          {stateData.resourceData && stateData.resourceData?.result?.length ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: '75%',
                position: 'relative'
              }}
            >
              <LocationFilterDropdown
                accountPracticeLocations={props.accountPracticeLocations}
                selectedLocation={stateData.selectedLocation}
                resourceCode={sectionData.resourceCode}
                onSelect={(value: any) => {
                  setStateData(prev => {
                    return {
                      ...prev,
                      selectedLocation: value
                    }
                  })
                }}
              />
              <main style={{
                alignSelf: 'center',
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                height: '100%'
              }}>
                <main
                  style={{padding: '10px 0px', width: sectionData.currentDisplayType === DISPLAY_TYPE.HORIZONTAL_GROUP_BAR_WITH_LINE_VIEW ? '96%' : '88%'}}
                  className={
                    sectionData.currentDisplayType === DISPLAY_TYPE.HORIZONTAL_GROUP_BAR_WITH_LINE_VIEW ? '' : stateData.resourceData.result?.length &&
                      props.isPrintPreview
                      ? 'canvas-wrapper'
                      : ''
                  }
                >
                  {barChartView}
                </main>
              </main>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '60%',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <LocationFilterDropdown
                accountPracticeLocations={props.accountPracticeLocations}
                selectedLocation={stateData.selectedLocation}
                resourceCode={sectionData.resourceCode}
                top={-10}
                onSelect={(value: any) => {
                  setStateData(prev => {
                    return {
                      ...prev,
                      selectedLocation: value
                    }
                  })
                }}
              />
              <NodataViewTypeOne viewCode={'TEXT_ICON'} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AppointMentWidgets;
