import React from 'react';

const RefreshSvg = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.45288 6.55556H1.95288V6.55556L2.45288 6.55556ZM2.45288 7.66667L2.10072 8.02161C2.29566 8.21502 2.6101 8.21502 2.80504 8.02161L2.45288 7.66667ZM3.92492 6.9105C4.12095 6.716 4.12219 6.39942 3.9277 6.20339C3.7332 6.00737 3.41662 6.00612 3.22059 6.20062L3.92492 6.9105ZM1.68517 6.20062C1.48914 6.00612 1.17256 6.00737 0.978068 6.20339C0.783575 6.39942 0.784818 6.716 0.980846 6.9105L1.68517 6.20062ZM12.41 3.92853C12.5547 4.16377 12.8626 4.23723 13.0979 4.09261C13.3331 3.94799 13.4066 3.64005 13.2619 3.40481L12.41 3.92853ZM13.5423 6.33333L13.8938 5.97772C13.699 5.7852 13.3856 5.7852 13.1908 5.97772L13.5423 6.33333ZM12.0666 7.08883C11.8702 7.28295 11.8684 7.59953 12.0625 7.79593C12.2566 7.99233 12.5732 7.99417 12.7696 7.80006L12.0666 7.08883ZM14.3149 7.80006C14.5113 7.99417 14.8279 7.99233 15.022 7.79593C15.2162 7.59953 15.2143 7.28295 15.0179 7.08883L14.3149 7.80006ZM3.54496 10.0707C3.39994 9.83575 3.09188 9.76281 2.85688 9.90784C2.62188 10.0529 2.54895 10.3609 2.69397 10.5959L3.54496 10.0707ZM8.05225 0.5C4.68737 0.5 1.95288 3.20747 1.95288 6.55556H2.95288C2.95288 3.76714 5.23224 1.5 8.05225 1.5V0.5ZM1.95288 6.55556L1.95288 7.66667L2.95288 7.66667L2.95288 6.55556L1.95288 6.55556ZM2.80504 8.02161L3.92492 6.9105L3.22059 6.20062L2.10072 7.31173L2.80504 8.02161ZM2.80504 7.31173L1.68517 6.20062L0.980846 6.9105L2.10072 8.02161L2.80504 7.31173ZM13.2619 3.40481C12.191 1.66285 10.2579 0.5 8.05225 0.5V1.5C9.89861 1.5 11.5148 2.4723 12.41 3.92853L13.2619 3.40481ZM7.9215 13.5C11.2964 13.5 14.0423 10.7943 14.0423 7.44444H13.0423C13.0423 10.2311 10.7551 12.5 7.9215 12.5V13.5ZM14.0423 7.44444V6.33333H13.0423V7.44444H14.0423ZM13.1908 5.97772L12.0666 7.08883L12.7696 7.80006L13.8938 6.68894L13.1908 5.97772ZM13.1908 6.68894L14.3149 7.80006L15.0179 7.08883L13.8938 5.97772L13.1908 6.68894ZM2.69397 10.5959C3.76904 12.338 5.70903 13.5 7.9215 13.5V12.5C6.0665 12.5 4.44357 11.5269 3.54496 10.0707L2.69397 10.5959Z" fill="#8A94A8"/>
    </svg>
  );
};

export default RefreshSvg;