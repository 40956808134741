import {StyleSheet} from 'react-native';
import { Colors } from '../../../../styles';

export const styles = StyleSheet.create({
  inputViewStyle: {
    marginVertical: 5,
  },
  inputStyle: {
    height: 44,
    borderRadius: 8,
  },
  phoneNumberWrapper: {
    flex: 1,
  },
  iconWrapper: {
    flex: 0.1,
    marginRight: 10,
  },
  phoneNumberView: {
    flex: 0.9,
  },
  mt30: {
    marginTop: 30,
  },
  fw600: {
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY400
  }
});
