import {Colors} from "../../../../styles";
import {ICommonSvgProps} from "../interfaces";


const ProgressArrowInverseSvg = (props: {
  met : boolean
}) => {
  const bgColor = props?.met ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY300;

  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_579_18064)">
      <path d="M1 8.00025L12.25 15.5002L12.25 0.500244L1 8.00025Z" fill={bgColor}/>
      </g>
      <line x1="0.5" y1="0.500244" x2="0.499999" y2="15.5002" stroke={bgColor} stroke-linecap="round"/>
      <defs>
      <filter id="filter0_d_579_18064" x="1" y="0.500244" width="15.25" height="19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="2" dy="2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_579_18064"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_579_18064" result="shape"/>
      </filter>
      </defs>
    </svg>


  );
};

export default ProgressArrowInverseSvg;
