import {Dimensions, StyleSheet} from 'react-native';
import {Colors} from './../../../../../styles/Colors';
const {height} = Dimensions.get('window');
export const styles = StyleSheet.create({
  titleWrapper: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  conversationDrawerContainer: {
    justifyContent: 'center',
  },
  sidebarListContainer: {
    height: '100%',
    width: '13%',
    backgroundColor: 'white',
    borderColor: Colors.Custom.Gray200,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    maxHeight: height - 100,
  },
  sidebarListContainerItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomColor: Colors.Custom.Gray200,
    borderBottomWidth: 1,
    marginBottom: 2,
  },
  sidebarListContainerItemText: {
    color: Colors.FoldPixel.GRAY250,
    paddingLeft: 16,
    paddingVertical: 12,
  },
  automationSettingBtn: {
    right: 10,
  },
});

export const getConditionalStyles = ({
  isSidecarContext,
}: {
  isSidecarContext: boolean | undefined;
}) => {
  return StyleSheet.create({
    sideBarContainer: {
      display: 'flex',
      flexDirection: isSidecarContext ? 'column' : 'row',
      alignItems: isSidecarContext ? 'flex-start' : undefined,
      flex: 1,
    },
    conversationContainer: {
      height: '100%',
      ...(isSidecarContext && {width: '100%'}),
    },
  });
};
