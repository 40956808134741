import {Drawer} from 'antd';
import {Input} from 'native-base';
import React, {useRef, useEffect} from 'react';
import {Text, View} from 'react-native';
import {BUTTON_TYPE} from '../../../constants';
import {Colors} from '../../../styles';
import {ModalActionTitle} from '../../common/ModalActionTitle/ModalActionTitle';
import {IAddOrUpdateDayOptimizerRuleFactorsParams, IDayOptimizerRuleFactor} from './common/interface';

const AddOrUpdateRuleScore = (props: {
  ruleScoreData: IDayOptimizerRuleFactor;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (ruleScoreData: IAddOrUpdateDayOptimizerRuleFactorsParams) => void;
}) => {

  const {isOpen, onClose, onSubmit, ruleScoreData} = props;
  const scoreInputRef = useRef<any>(null); 
  const [state, setState] = React.useState({
    submitting: false,
    searching: false,
    code: '',
    description: '',
    titleError: '',
    score: ruleScoreData.score?.toString(),
  });

  useEffect(() => {
    if (isOpen && scoreInputRef.current) {
      scoreInputRef.current?.focus();
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    const updateRuleData: IAddOrUpdateDayOptimizerRuleFactorsParams = {
      ruleScoreId: ruleScoreData.ruleScoreId,
      score: Number(state.score),
    };
    setState({...state, submitting: true});
    onSubmit(updateRuleData);
  };

  return (
    <Drawer
      onClose={onClose}
      width={'33vw'}
      open={isOpen}
      title={
        <ModalActionTitle
          title={
            ruleScoreData?.name ? 'Update Rule Score' : 'Add Rule and Score'
          }
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onClose();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'save',
              textColor: Colors.Custom.mainPrimaryPurple,
              isLoading: state.submitting,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                handleSubmit();
              },
            },
          ]}
        />
      }
    >
      <View>
        <View style={{marginBottom: 18}}>
          <Text
            style={{
              fontSize: 16,
              color: Colors.FoldPixel.GRAY400,
              fontWeight: '500',
              marginBottom: 18,
            }}
          >
            Rule Name
          </Text>
          <Input
            isDisabled={ruleScoreData?.name ? true : false}
            value={ruleScoreData?.name}
          />
        </View>
        
        <View style={{marginBottom: 18}}>
          <Text
            style={{
              fontSize: 16,
              color: Colors.FoldPixel.GRAY400,
              fontWeight: '500',
              marginBottom: 18,
            }}
          >
            Role Name
          </Text>
          <Input
            value={ruleScoreData?.roleName}
            isDisabled={ruleScoreData?.name ? true : false}
          />
        </View>
        <View style={{marginBottom: 18}}>
          <Text
            style={{
              fontSize: 16,
              color: Colors.FoldPixel.GRAY400,
              fontWeight: '500',
              marginBottom: 18,
            }}
          >
            Score
          </Text>
          <Input
            ref={scoreInputRef}
            value={state.score}
            onChangeText={(value) => setState({...state, score: value.replace(/[^0-9]/g, '')})}
            keyboardType="numeric"
          />
        </View>
      </View>
    </Drawer>
  );
};

export default AddOrUpdateRuleScore;
