import React, {useMemo} from 'react';
import {Path, Svg} from 'react-native-svg';
import {ICommonSvgProps} from '../../components/common/Svg/interfaces';

const UnCheckedIconSvg = (props: ICommonSvgProps) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8Z"
        fill="white"
      />
      <Path
        d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8Z"
        stroke={props.customStrokeColor || '#8A94A8'}
      />
    </Svg>
  );
};

export default React.memo(UnCheckedIconSvg);
