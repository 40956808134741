import {Checkbox, Dropdown, Popover} from 'antd';
import {useState} from 'react';
import {View, Text, Pressable} from 'react-native';
import Stack from '../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../styles';
import './MembersMultiSelect.css';
import {IContact} from '../../../common/CareDashboard/CareDashboardInterfaces';
import Close from '../../../../assets/svg/Close';
import {useIntl} from 'react-intl';
import {ITableTopBarButtonProps} from '../../TableTopBar';
import {BUTTON_TYPE} from '../../../../constants';
import FoldButtonV2, {
  ButtonType,
} from '../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';

interface IMultiSelectView {
  isVisible: boolean;
  selectedMembers: IContact[];
  onClose: () => void;
  buttonList?: ITableTopBarButtonProps[];
  hideDividerBetweenButtons?: boolean;
  customStyles?: {
    customButtonSpace?: number;
  };
  customPopoverWidth?: number;
}

const MembersMultiSelectView = (props: IMultiSelectView) => {
  const {isVisible, selectedMembers, onClose, hideDividerBetweenButtons, customPopoverWidth} = props;
  const intl = useIntl();

  const [isPopoverOpen, setIsPopoverOpen] = useState({
    selectedId: 0,
    isOpen: false,
    zIndex: 1,
  });

  const closeBulkAction = () => {
    onClose();
  };

  const handleVisibleChange = (e: any) => {
    setIsPopoverOpen(e);
  };

  return (
    <View>
      {isVisible && (
        <div className={'modal-container'} style={{ width: props.buttonList?.length === 1 ? 350 : customPopoverWidth || 550, left: props.buttonList?.length === 1 ? '44%' : '37%' }}>
          <Stack
            direction="row"
            style={{alignItems: 'center', justifyContent: 'center'}}
          >
            <Checkbox checked={selectedMembers.length > 0} />
            <Text
              style={{
                fontSize: 14,
                color:
                  selectedMembers.length > 0
                    ? Colors.Custom.Gray500
                    : Colors.Custom.Gray300,
                fontWeight: '400',
                marginLeft: 8,
              }}
            >
              {selectedMembers.length} selected
            </Text>
            <View
              style={{
                width: 1,
                height: 16,
                backgroundColor: Colors.Custom.Gray300,
                marginHorizontal: 8,
              }}
            />
            <Stack
              direction="row"
              space={props?.customStyles?.customButtonSpace ?? 8}
              style={{alignItems: 'center', justifyContent: 'center'}}
            >
              {props?.buttonList?.map(
                (singleButton: ITableTopBarButtonProps, index: number) => {
                  if (!singleButton) {
                    return null;
                  }
                  return (
                    <>
                      {singleButton.content ? (
                        singleButton.dropDownType === 'MENU_DROPDOWN' ? (
                          <Dropdown
                            overlay={singleButton.content}
                            placement="bottomRight"
                          >
                            <View
                              style={{
                                width: 100,
                                height: 20,
                              }}
                            >
                              <FoldButtonV2
                                key={index}
                                rightIcon={singleButton?.rightIcon}
                                label={intl.formatMessage({
                                  id: singleButton.btnText,
                                })}
                                buttonType={ButtonType.secondary}
                                isDisabled={selectedMembers.length === 0}
                                onPress={singleButton.btnClick}
                                leftIcon={singleButton.leftIcon}
                              />
                            </View>
                          </Dropdown>
                        ) : singleButton.dropDownType === 'CUSTOM_VIEW' ? (
                          <>
                            {singleButton.content}
                          </>
                        ) : (
                          <Popover
                            key={index}
                            overlayInnerStyle={{
                              bottom: 3,
                              borderRadius: 16,
                              padding: 0,
                            }}
                            content={singleButton.content || <></>}
                            title=""
                            trigger="click"
                            visible={
                              isPopoverOpen.selectedId === singleButton.id
                                ? isPopoverOpen.isOpen
                                : false
                            }
                            onVisibleChange={handleVisibleChange}
                            placement={
                              singleButton?.popoverPosition || 'bottom'
                            }
                          >
                            <FoldButtonV2
                              key={index}
                              rightIcon={singleButton?.rightIcon}
                              label={
                                singleButton.btnText
                                  ? intl.formatMessage({
                                      id: singleButton.btnText || '',
                                    })
                                  : ''
                              }
                              hideBorder={singleButton.variant === BUTTON_TYPE.NO_BORDER ? true : false}
                              buttonType={ButtonType.secondary}
                              height={25}
                              isDisabled={selectedMembers.length === 0}
                              onPress={()=> {
                                setIsPopoverOpen({
                                  ...isPopoverOpen,
                                  selectedId:
                                    singleButton.id || 0,
                                  isOpen: !isPopoverOpen.isOpen,
                                });
                              }}
                              leftIcon={singleButton.leftIcon}
                            />
                          </Popover>
                        )
                      ) : (
                        <FoldButtonV2
                          key={index}
                          rightIcon={singleButton?.rightIcon}
                          label={intl.formatMessage({id: singleButton.btnText})}
                          buttonType={ButtonType.secondary}
                          isDisabled={selectedMembers.length === 0}
                          onPress={singleButton.btnClick}
                          leftIcon={singleButton.leftIcon}
                        />
                      )}

                      {!hideDividerBetweenButtons && (props.buttonList?.length || 1) - 1 != index ? (
                        <View
                          style={{
                            width: 1,
                            height: 16,
                            backgroundColor: Colors.Custom.Gray300,
                            marginLeft: 8,
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  );
                }
              )}
            </Stack>
            <View
              style={{
                width: 1,
                height: 16,
                backgroundColor: Colors.Custom.Gray300,
                marginHorizontal: 8,
              }}
            />
            <Pressable onPress={closeBulkAction}>
              <Close fill={Colors.Custom.Gray500} size={20} />
            </Pressable>
          </Stack>
        </div>
      )}
    </View>
  );
};

export default MembersMultiSelectView;
