import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Popover, Badge} from 'antd';
import ScheduleSuggestionsIcon from '../../../Svg/ScheduleSuggestionsIcon';
import {
  Box,
  Flex,
  HStack,
  Image,
  Pressable,
  Spacer,
  Text,
  VStack,
} from 'native-base';
import {Colors} from '../../../../../styles';
import {useIntl} from 'react-intl';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {LeadQueries, UserQueries} from '../../../../../services';
import {getAccountId, getBooleanFeatureFlag, getUserUUID, navigateToNewSettingTab} from '../../../../../utils/commonUtils';
import {DisplayCardAvatar} from '../../../DisplayCard/DisplayCardAvatar';
import {GROUP_MEMBER_TYPE, MLOV_CATEGORY} from '../../../../../constants';
import ScheduleRuleMemberList from './components/ScheduleRuleMemberList/ScheduleRuleMemberList';
import {useNavigate} from 'react-router-dom';
import {ContactListItem, FeatureKey, ScheduleSuggestionResponse} from './interface';
import {formattedContactDataList} from './ScheduleSuggestorUtils';
import {getScheduleSuggestions} from '../../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import Feather from 'react-native-vector-icons/Feather';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { USER_PREFERENCE_CODE } from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { getMenuConfig } from '../../../../SideMenuBar/SideBarHelper';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import FeatureFlags from '../../../../../constants/FeatureFlags.enums';
import { testID, TestIdentifiers } from '../../../../../testUtils';

const ScheduleSuggestor = (props: {refetchAppointments: () => void}) => {
  const maxCountForAvatars = 8;
  const intl = useIntl();
  const navigate = useNavigate();
  const accountId = getAccountId();
  const userId = getUserUUID();
  const [componentState, setComponentState] = useState<{
    ruleList: string[];
    contactIdsList: string[];
    contactList: any[];
    isPopoverVisible: boolean;
    showRuleDrawer: boolean;
    selectedContactIds: string[];
    loading: boolean;
  }>({
    ruleList: [],
    contactIdsList: [],
    contactList: [],
    isPopoverVisible: false,
    showRuleDrawer: false,
    selectedContactIds: [],
    loading: false,
  });
  const commonData = useContext(CommonDataContext);
  const userPreferenceList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.FEATURES_VIEWED
  );
  const noRulesConfigured = componentState.ruleList.length === 0;
  const userUUID = getUserUUID();
  const menuConfig = getMenuConfig(userUUID);
  const isSettingTabAccess = menuConfig.sideBarMenus.some(
    (menu) => menu.menuCode === MAIN_MENU_CODES.SETTING
  );
  const isNewSettingTabEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_NEW_SETTING_TAB_ENABLED);

  const [getContactsByIds] = useLazyQuery(LeadQueries.getContactsByIds);
  const [addOrUpdatePreferences] = useMutation(
    UserQueries.ADD_OR_UPDATE_USER_PREFERENCE,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );
  useQuery(UserQueries.GET_USER_PREFERENCE, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: userId,
      userPreferencesTypeId: userPreferenceId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted:(data) => {
      if(data?.userPreferences?.length > 0 && data?.userPreferences?.[0]?.preferencesJson){
       const preferenceData = data?.userPreferences?.[0];
       const pref = preferenceData?.preferencesJson
         ? JSON.parse(preferenceData?.preferencesJson)
         : {};
       if (!pref?.featureViewed?.[FeatureKey.ScheduleSuggestion] && noRulesConfigured) {
         setTimeout(() => {
           handlePopoverChange(true);
           updateViewedFeatureList(preferenceData);
         }, 800);
       }
      } else {
        if(!noRulesConfigured){
          return;
        }
        const preferenceData = data?.userPreferences?.[0];
        setTimeout(() => {
          handlePopoverChange(true);
          updateViewedFeatureList(preferenceData);
        }, 800);
      }
    }
  });

  const handlePopoverChange = (visible: boolean) => {
    setComponentState((prev) => ({
      ...prev,
      isPopoverVisible: visible,
    }));
  };

  const handleDrawerVisibleChange = (visible: boolean) => {
    setComponentState((prev) => ({
      ...prev,
      showRuleDrawer: visible,
    }));
  };

  const fetchSuggestions = async () => {
    setComponentState((prev) => ({...prev, loading: true}));
    const response: {
      data: ScheduleSuggestionResponse;
    } = await getScheduleSuggestions();

    if (!!Object.keys(response?.data).length) {
      await getContactsByIds({
        variables: {
          contactIdList: response.data.contactIds.splice(0, maxCountForAvatars),
          accountId: accountId,
        },
        onCompleted: (data) => {
          setComponentState((prev) => ({
            ...prev,
            contactList: formattedContactDataList(data.contacts),
            contactIdsList: response.data.contactIds,
            loading: false,
            ruleList: response.data.rules.map((rule) => rule.id),
          }));
        },
        onError: (err) => {
          setComponentState((prev) => ({
            ...prev,
            contactList: [],
            ruleList: [],
            contactIdsList: [],
            loading: false,
          }));
        },
      });
    } else {
      setComponentState((prev) => ({
        ...prev,
        contactList: [],
        ruleList: [],
        contactIdsList: [],
        loading: false,
      }));
    }
  };

  const updateViewedFeatureList = (preferenceData: any) => {
    const prevJSON = preferenceData?.preferencesJson
      ? JSON.parse(preferenceData?.preferencesJson)
      : {};
    const newJSON = {
      ...prevJSON,
      featureViewed: {
        [FeatureKey.ScheduleSuggestion]: true,
      },
    };
    const data = {
      object: {
        userId: userId,
        userPreferencesTypeId: userPreferenceId,
        preferencesJson: JSON.stringify(newJSON),
        id: preferenceData?.id || undefined,
      },
    };
    addOrUpdatePreferences({variables: data});
  }


  useEffect(() => {
    fetchSuggestions();
  }, []);

  const renderContactList = (): JSX.Element => {
    if (
      componentState.contactIdsList.length > 1 &&
      componentState.contactIdsList.length < maxCountForAvatars + 1
    ) {
      return (
        <>
          <Avatar.Group size={'default'}>
            {componentState.contactList.map((contact: any, index: number) => (
              <Box marginLeft={-2}>
                <DisplayCardAvatar
                  key={index + contact?.uuid}
                  avatarStyle={{
                    avatarSize: 8,
                    width: 26,
                    height: 26,
                    textStyle: {
                      fontSize: 10,
                    },
                  }}
                  userData={{
                    userId: contact.id,
                    userType: GROUP_MEMBER_TYPE.CONTACT,
                    userName: contact?.name,
                  }}
                  isLetterAvatarShow
                />
              </Box>
            ))}
          </Avatar.Group>
        </>
      );
    }
    if (componentState.contactIdsList.length === 1) {
      const contact = componentState.contactList[0];
      return (
        <>
          <DisplayCardAvatar
            key={contact?.uuid}
            avatarStyle={{
              avatarSize: 8,
              width: 26,
              height: 26,
              textStyle: {
                fontSize: 10,
              },
            }}
            userData={{
              userId: contact.id,
              userType: GROUP_MEMBER_TYPE.CONTACT,
              userName: contact?.name,
            }}
            isLetterAvatarShow
          />
          <Text
            color={Colors.Custom.mainPrimaryPurple}
            fontSize={'sm'}
            ml={2}
          >{`${contact?.name}`}</Text>
        </>
      );
    }
    if (componentState.contactIdsList.length > 4) {
      return (
        <>
          <Avatar.Group size={'default'}>
            {componentState.contactList
              .slice(0, 4)
              .map((contact: any, index: number) => (
                <Box marginLeft={-2}>
                  <DisplayCardAvatar
                    key={index + contact?.uuid}
                    avatarStyle={{
                      avatarSize: 8,
                      width: 26,
                      height: 26,
                      textStyle: {
                        fontSize: 10,
                      },
                    }}
                    userData={{
                      userId: contact.id,
                      userType: GROUP_MEMBER_TYPE.CONTACT,
                      userName: contact?.name,
                    }}
                    isLetterAvatarShow
                  />
                </Box>
              ))}
          </Avatar.Group>
          {componentState.contactIdsList.length - 4 > 0 && (
            <Text
              color={Colors.Custom.mainPrimaryPurple}
              fontSize={'sm'}
              ml={2}
            >{`+ ${
              componentState.contactIdsList.length - 4
            } ${intl.formatMessage({id: 'more'})}`}</Text>
          )}
        </>
      );
    }
    return <></>;
  };

  const renderContent = (): JSX.Element => {
    if (noRulesConfigured) {
      return (
        <VStack
          w={300}
          bg={Colors.Custom.BackgroundColor}
          borderRadius={16}
          alignItems={'center'}
        >
          <Text fontWeight={600} textAlign={'left'} w="100%" pb={2}>
            Calendar rules are here
          </Text>
          <Image
            source={{
              uri: require('../../../../../assets/images/calendar-rules.png'),
            }}
            height={150}
            w={200}
          />
          <Text color={Colors.Custom.Gray600} pt={2}>
            {isSettingTabAccess
              ? 'Now you can set custom calendar rules in settings to suggest scheduling for members meeting specific criteria.'
              : 'View personalized scheduling suggestions for members meeting specific criteria here.'}
          </Text>
          {isSettingTabAccess && (
            <HStack justifyContent={'center'} py={1}>
              <Pressable
                onPress={onSettingPress}
              >
                <Text color={Colors.Custom.mainPrimaryPurple}>
                  Explore Now!
                </Text>
              </Pressable>
            </HStack>
          )}
        </VStack>
      );
    }
    return (
      <VStack w={200} bg={Colors.Custom.BackgroundColor} borderRadius={16}>
        <VStack borderColor={Colors.Custom.Gray200} borderBottomWidth={1}>
          <HStack>
            <Text fontWeight={600}>
              {intl.formatMessage({id: 'bookAppointment'})}
            </Text>
          </HStack>
          {componentState.contactList.length > 0 ? (
            <Pressable
              onPress={() => handleDrawerVisibleChange(true)}
              flexDir={'row'}
              m={2}
              alignItems={'center'}
            >
              {renderContactList()}
              <Spacer />
              <Feather
                name="chevron-right"
                size={15}
                color={Colors.Custom.mainPrimaryPurple}
              />
            </Pressable>
          ) : (
            <Text color={Colors.Custom.Gray400} my={1}>
              {intl.formatMessage({id: 'noSuggestionsForNow'})}
            </Text>
          )}
        </VStack>
        <HStack justifyContent={'flex-end'} alignItems={'center'} mt={1}>
          <Pressable onPress={() => handlePopoverChange(false)}>
            <Text color={Colors.Custom.mainPrimaryPurple}>
              {intl.formatMessage({id: 'dismiss'})}
            </Text>
          </Pressable>
          {isSettingTabAccess && (
            <>
              <Box
                style={{
                  width: 1,
                  height: 18,
                  backgroundColor: Colors.Custom.Gray200,
                  marginLeft: 10,
                  marginRight: 10,
                }}
              />
              <Pressable
                onPress={onSettingPress}
              >
                <Text color={Colors.Custom.mainPrimaryPurple}>
                  {intl.formatMessage({id: 'viewRules'})}
                </Text>
              </Pressable>
            </>
          )}
        </HStack>
      </VStack>
    );
  };

  const onSettingPress = () => {
    const settingsPath = '/admin/schedule/suggestionRules';
    if (isNewSettingTabEnabled) {
      navigateToNewSettingTab(settingsPath);
    } else {
      navigate(settingsPath);
    }
  }

  return (
    <>
      <Popover
        align={{offset: [0, 10]}}
        open={componentState.isPopoverVisible}
        trigger={'click'}
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        style={{padding: 0, borderRadius: 16, cursor: 'pointer'}}
        onOpenChange={handlePopoverChange}
        content={renderContent()}
        placement="bottom"
      >
        <Pressable
          disabled={componentState.loading}
          onPress={() => handlePopoverChange(true)}
          {...testID(TestIdentifiers.scheduleSuggestor)}
        >
          {componentState.contactIdsList.length > 0 ? (
            <Badge dot={true}>
              <ScheduleSuggestionsIcon />
            </Badge>
          ) : (
            <ScheduleSuggestionsIcon />
          )}
        </Pressable>
      </Popover>
      {componentState.showRuleDrawer && (
        <ScheduleRuleMemberList
          rulesIds={componentState.ruleList}
          refetchAppointments={() => props.refetchAppointments()}
          isOpen={componentState.showRuleDrawer}
          onClose={() => handleDrawerVisibleChange(false)}
          contactIdList={[...new Set(componentState.contactIdsList)]}
        />
      )}
    </>
  );
};

export default ScheduleSuggestor;
