import {View, Text, Skeleton, Center} from 'native-base';
import {Colors} from '../../../../styles/Colors';
import {useLazyQuery} from '@apollo/client';
import {
  GET_CARE_JOURNEY_GOAL_NAME_DESCRIPTION,
  GET_CARE_JOURNEY_TITLE,
} from '../../../../services/CareJourney/CareJourneyQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {getAccountUUID} from '../../../../utils/commonUtils';
import {useEffect, useState} from 'react';
import CommonService from '../../../../services/CommonService/CommonService';
import NodataViewTypeOne from '../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';
import { NodeType } from '../JourneyContactList/interfaces';

const JourneyOverview = (props: {carejourneyId: string}) => {
  const [titleSubtitle, setTitleSubtitle] = useState<any>();
  const [goals, setGoals] = useState<any[]>([]);
  const [titleLoading, setTitleLoading] = useState(true);
  const [goalLoading, setGoalLoading] = useState(true);

  const tenantId = getAccountUUID();

  const [GetJourneyTitle] = useLazyQuery(GET_CARE_JOURNEY_TITLE);

  const [GetJourneyGoalName] = useLazyQuery(
    GET_CARE_JOURNEY_GOAL_NAME_DESCRIPTION
  );

  const fetchData = async () => {
    setTitleLoading(true);
    GetJourneyTitle({
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        id: props.carejourneyId,
      },
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }).then((resp) => {
      setTitleLoading(false);
      if (resp.data.careJourney) {
        setTitleSubtitle(resp.data.careJourney);
      }
    });

    const commonService = CommonService.getCommonServiceInstance();
    const workflowService = commonService.workflowService;
    setGoalLoading(true);
    const response = await workflowService
      .post(
        '/graphql',
        JSON.stringify({
          query:
            '\n  query getWorkflowByMasterId($workflowMasterId: uuid!, $tenantId: uuid!) {\n    workflows(\n      where: {\n        workflowMasterId: {_eq: $workflowMasterId}\n        tenantId: {_eq: $tenantId}\n        isDeleted: {_eq: false}\n      }\n    ) {\n      id\n      \n      graph {\n        nodes {\n          data\n          type\n        }\n      }\n    }\n  }\n',
          variables: {
            workflowMasterId: props.carejourneyId,
            tenantId: tenantId,
          },
        })
      )
      .then((resp) => {
        setGoalLoading(false);

        if (resp.data?.data?.workflows?.[0]?.graph?.nodes?.length) {
          const nodes = resp.data?.data?.workflows?.[0]?.graph?.nodes;
          const goals: any[] = [];
          const goalNodes = nodes.filter((node: any) => node.data?.nodeType === NodeType.Care_Journey_Goal);
          goalNodes.forEach((item: any) => {
              if (!!item?.data?.metaData?.userInputFieldList) {
                const goal: any = {};
                item?.data?.metaData?.userInputFieldList?.forEach?.(
                  (subitem: any) => {
                    if (subitem?.key === 'title' && !!subitem?.value) {
                      goal.title = subitem?.value;
                    }
                    if (subitem?.key === 'description' && !!subitem?.value) {
                      goal.description = subitem?.value;
                    }
                  }
                );
                goals.push(goal);
              }
          });
          setGoals(goals);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <Text
        style={{
          marginVertical: 8,
        }}
        size={'lgMedium'}
        color={Colors.FoldPixel.GRAY400}
      >
        Journey Title
      </Text>
      {titleLoading ? (
        <Skeleton.Text lines={5} />
      ) : (
        <Text style={{}} size={'xslMedium'}>
          {titleSubtitle?.title}
        </Text>
      )}
      <View
        style={{
          width: '100%',
          height: 1,
          marginVertical: 18,
          backgroundColor: Colors.Custom.Gray200,
        }}
      />
      <Text
        style={{
          marginVertical: 8,
        }}
        size={'lgMedium'}
        color={Colors.FoldPixel.GRAY400}
      >
        Journey Description
      </Text>
      {titleLoading ? (
        <Skeleton.Text lines={5} />
      ) : (
        <Text
          style={{
            color: Colors.Custom.Gray500,
          }}
          fontSize={16}
        >
          {titleSubtitle?.description}
        </Text>
      )}

      <>
        <View
          style={{
            width: '100%',
            height: 1,
            marginVertical: 18,
            backgroundColor: Colors.Custom.Gray200,
          }}
        />
        <Text
          style={{
            marginVertical: 8,
          }}
          size={'lgMedium'}
          color={Colors.FoldPixel.GRAY400}
        >
          Goals
        </Text>
        {goalLoading ? (
          <Center margin={4} flex={1}>
            <Skeleton.Text lines={5} />
          </Center>
        ) : goals?.length ? (
          goals.map((item, index) => {
            return (
              <>
                {!!item.title && (
                  <Text
                    style={{
                      color: Colors.Custom.Gray700,
                    }}
                    fontSize={16}
                  >
                    {`${index + 1}. ${item.title}:`}
                  </Text>
                )}
                <Text
                  style={{
                    color: Colors.Custom.Gray500,
                  }}
                  fontSize={16}
                >
                  {`${!item.title ? index + 1 + '. ' : ''}${item.description}`}
                </Text>
              </>
            );
          })
        ) : (
          <View style={{paddingTop: 10}}>
            <NodataViewTypeOne viewCode="TEXT_ICON" />{' '}
          </View>
        )}
      </>
    </View>
  );
};

export default JourneyOverview;
