import { Drawer, Space, Table } from 'antd';
import 'antd/dist/antd.css';
import { Button, Icon, IconButton, Skeleton, Text, VStack, View, useToast } from 'native-base';
import { useEffect, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { BUTTON_TYPE } from '../../../constants';
import { Colors } from '../../../styles';
import { ModalActionTitle } from '../../common/ModalActionTitle/ModalActionTitle';
import { TimeZoneDropdown } from './TimeZoneDropdown';
import { TimerDropdown } from './TimerDropdown';
import { TypeDropdown } from './TypeDropdown';

import { getAccountUUID } from '../../../utils/commonUtils';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import FHAlertDialog from '../../common/FHAlertDialog/FHAlertDialog.native';
import { addUpdateMergeDelayTrigger, getMergeDelayTriggers } from './MergeDelayApi';
import { DisplayText } from '../../common/DisplayText/DisplayText';



export const MergedOrDelayed = (props: any) => {
 const [delayedTime, setDelayedTime] = useState('11:30PM');
 const [dataToUpdate, setDataToUpdate] = useState({});
 const [isOpen, setOpen] = useState(false);
 const [ dataArr, setDataSource]= useState([]);

 const limit = 10;

//  const [updateDelayTrigger] = useMutation(ADD_UPDATE_MERGE_DELAY_TRIGGER);
{/* <Skeleton.Text lines={5} /> */}
//  const [ updateDelayTrigger]
 const [ isLoading, setIsLoading] = useState(false);
 const accountId = getAccountUUID();
 const toast = useToast();
 const handleOnClose=()=> {
  setOpen(false)

 }

 const fetchTriggersData = (limit: number, offset:number)=> {
  setIsLoading(true);
  getMergeDelayTriggers(limit, offset)
  .then((data)=>{

    setDataSource(data);
    setIsLoading(false);
  }).catch((err)=>{

    setIsLoading(false);

  })
 }

 useEffect(()=>{

  fetchTriggersData(10, 0);
 }, [])

 const defaultData  = { tenantId: accountId, hour: 12, minute:30, type: 'EMAIL'};
 const [modalData, setModalData] = useState<any>(defaultData);
 const [deleteConfirmationState, setDeleteConfirmationState] = useState({
  isDelete: false,
  record: {
    id: ''
  }
})
const handleUpdateTrigger = async(data:any)=> {
  setIsLoading(true);

  addUpdateMergeDelayTrigger(data)
  .then(()=>{
    setIsLoading(false);
    fetchTriggersData(10, 0);
    if(data.isDeleted){
      showToast(
        toast,
        'Deleted Successfully!',
        ToastType.success,
        1500,
      );
    }
    setDeleteConfirmationState(() => {
      return {
        record: {id: ''}, isDelete: false
      }
    })
    handleOnClose();

  })
  .catch(()=>{
    setIsLoading(false);
    if(data.isDeleted){
    showToast(
      toast,
      'Error, Try again',
      ToastType.error,
      1500,
    );
    }
  })
}
const getDrawer = ()=> {
  return(
  <Drawer
  open={isOpen}
  onClose={()=>setOpen(false)}
  width={'33vw'}
  title={
    <ModalActionTitle
      title={'Trigger Data'}
      titleColor={''}
      buttonList={[
        {
          show: true,
          id: 1,
          btnText: 'cancel',
          textColor: Colors.Custom.mainSecondaryBrown,
          variant: BUTTON_TYPE.SECONDARY,
          isTransBtn: false,
          onClick: () => {
            handleOnClose();
          },
        },
        {
          show: true,
          id: 2,
          btnText: 'Submit',
          textColor: Colors.Custom.mainPrimaryPurple,
          variant: BUTTON_TYPE.PRIMARY,
          isLoading,
          isTransBtn: false,
          onClick: () => {
            handleUpdateTrigger(modalData);
          },
        },
      ]}
    />
  }
>
  <VStack  space="5">
    {/* <VStack style={{}} space="2">
      <Text>Members not in following Campaign</Text>
      <View>
        ddddd
      </View>
    </VStack> */}
    <VStack style={{}} space="2">
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Hour</Text>
          <TimerDropdown
                defaultValue={modalData.hour}
                optionType='hour'
                onChange={(hour: string)=> {
                  setModalData({...modalData, hour});
                }}
                />
    </VStack>
    <VStack style={{}} space="2">
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Minute</Text>
        <TimerDropdown
            defaultValue={modalData.minute}
            optionType='minute'
            onChange={(minute: string)=> {
              setModalData({...modalData, minute});
            }}
          />
    </VStack>
    <VStack style={{}} space="2">
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Timezone</Text>
        <TimeZoneDropdown selectedTimezone={modalData.timezone}
          onChange={(timezone: string)=> {
          setModalData({ ...modalData, timezone});
        }}
        />
    </VStack>
    <VStack style={{}} space="2">
        <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Type</Text>
        <TypeDropdown
              defaultValue={modalData.type}
              onChange={(type: string)=> {
                setModalData({...modalData, type});
            }}
          />
    </VStack>

  </VStack>
</Drawer>
  )
}



const TableColumn = ({ value, align}: any)=> {
  return (
        <View >
            <Text style={{
              color: '#101828',
              fontSize: 16,
              fontFamily: 'Manrope',
              fontWeight: '400',
              lineHeight: 24,
              textAlign: align || 'left'

            }}>{value}</Text>
        </View>
    )
}

const columns = [
  {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.FoldPixel.GRAY300}}
        textLocalId='Hour'
      />
    ),
    dataIndex: 'hour',
    key: 'hour',
    render: (hour: string, obj:any) => {


      return (
        <View>
          <TableColumn value={hour}/>
        </View>
      )
    },
    width: '10%',
  },
  {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.FoldPixel.GRAY300}}
        textLocalId='Minute'
      />
    ),
    dataIndex: 'minute',
    key: 'minute',
    width: '10%',
    render: (minute: any, obj:any) => {


      return <TableColumn value={minute}/>
    },
  },
  {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.FoldPixel.GRAY300}}
        textLocalId='TimeZone'
      />
    ),
    dataIndex: 'timezone',
    key: 'timezone',
    width: '10%',
    render: (timezone: any, obj: any) => {
      return (<TableColumn value={timezone}/>
      )
    },
  },
  {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.FoldPixel.GRAY300}}
        textLocalId='Type'
      />
    ),
    dataIndex: 'type',
    key: 'type',
    width: '10%',
    render: (type: any, obj: any) => {
      return <TableColumn value={type}/>
        },
  },
  {
    title: (
      <DisplayText
        size={'thMedium'}
        extraStyles={{color: Colors.FoldPixel.GRAY300}}
        textLocalId='Action'
      />
    ),
    dataIndex: '',
    key: '',
    width: '5%',
    render: (type: any, row: any) => {

      return <View>
         <Space size="middle">

<>
  <IconButton
    colorScheme="gray"
    key={row.createdOn + 'edit'}
    variant="ghost"
    onPress={() => {
      setModalData(row);
      setOpen(true);
    }}
    icon={<Icon as={AntIcon} name="edit" size="4" color="gray.400" />}
  />

  <IconButton
    colorScheme="gray"
    tintColor="gray.500"
    key={row.createdOn + 'delete'}
    onPress={() => {
      setDeleteConfirmationState((state: any) => {
        return { isDelete: true, record: row }
      });


    }}
    variant="ghost"
    icon={
      <Icon as={AntIcon} name="delete" size="4" color="gray.400" />
    }
  />

</>

</Space>
        {/* <Button
          onPress={()=> {
          setModalData(row);
          setOpen(true);
          }}>Update</Button> */}
      </View>
    },
  },
];
if(isLoading){
  return <Skeleton.Text lines={5} />
}

  return (
    <View flex={1} flexDirection={'column'} padding={4} gap={2}>
        {isOpen ? getDrawer() : <></>}
        {/* <Input type='text' value={delayedTime}/>
        <Button>Update</Button> */}
        <View alignItems={'flex-end'}>
          <Button variant={BUTTON_TYPE.PRIMARY}
            onPress={()=> {
              setOpen(true);
              setModalData(defaultData);
            }}>Add
          </Button>
        </View>
        <Table
            dataSource={dataArr}
            columns={columns}
            scroll={{x:'100%', y:500}}
            pagination={{

            pageSizeOptions:['5', '10', '15'],
            onChange: (page, pageSize) => {

              fetchTriggersData(limit, (page-1)*pageSize)
            // onPageChange(page, pageSize)
          },
        }}/>
          <FHAlertDialog
              isOpen={deleteConfirmationState.isDelete}
              header={'Delete'}
              message={`Are you sure you want to delete the record`}
              buttonActions={[
                {
                  textLocalId: 'No',
                  buttonProps: {
                    variant: BUTTON_TYPE.SECONDARY,
                    isDisabled: isLoading,
                  },
                  onPress: () => {
                    setDeleteConfirmationState(() => {
                      return {
                        record: {id: ''}, isDelete: false
                      }
                    })
                  },
                },
                {
                  textLocalId: 'Yes',

                  buttonProps: {
                    variant: BUTTON_TYPE.PRIMARY,
                    isLoading: isLoading,
                    isDisabled: isLoading,
                    _loading: {

                    },
                  },
                  onPress: () => {

                    handleUpdateTrigger({...deleteConfirmationState.record, isDeleted: true});
                    // handleRemoveRecord(deleteConfirmationState.record?.id)
                  },
                },
              ]}
      />
    </View>
  );
};
