import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  buttonTitle: {
    color: Colors.FoldPixel.GRAY250,
    marginTop: 1,
  },
  dialPadNumberTitle: {
    paddingX: 16,
    marginTop: 32,
    width: '100%',
    textAlign: 'center',
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY400,
    marginBottom: -16,
    minHeight: 40,
  },
});
