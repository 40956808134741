import { Buffer } from 'buffer';
import { find } from 'lodash';
import moment from 'moment';
import { useMediaQuery } from 'native-base';
import { useContext } from 'react';
import { IAppointmentType } from '../components/RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import {
  IAccountLocation,
  IUser,
  IUserRoleMap
} from '../components/RightSideContainer/Contacts/TeamMembers/interfaces';
import { CapabilityResource } from '../components/RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { CHANNEL_TYPE,CHANNEL_TYPE_CODE } from '../components/RightSideContainer/TeamInbox/Conversations/ConversationConst';
import { MAIN_MENU_CODES } from '../components/SideMenuBar/SideBarConst';
import { IMembershipData } from '../components/common/MemberShip/interface';
import {
  CONTACT_SOURCE_DISPLAY_STR,
  GROUP_MEMBER_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  ONLINE_STATUS_COLORS,
  THRESHOLD,
  TOLL_FREE_NUMBERS_PREFIX
} from '../constants';
import {
  evnName,
  getCurrentSubdomainNative,
  getEnvVariable
} from '../constants/BaseUrlConst';
import {
  CARE_TEAM_MEMBER_TYPE,
  GLOBAL_ROLE_CODES,
  GROUP_TYPE_CODES,
  PERSON_CONTACT_TYPE_CODES,
  USER_ROLE_CODES as USER_ROLE,
  USER_ROLE_CODES
} from '../constants/MlovConst';
import { IContactSource } from '../constants/interface';
import { CommonDataContext,ICommonData } from '../context/CommonDataContext';
import { OnlineUserPresenceDataContext } from '../context/OnlineUserPresenceDataContext';
import * as Sentry from '@sentry/react';
import { v4 as uuidV4 } from 'uuid';

import {
  EHRWiseLocations,
  IAllowedUserPracticeLocationUuidsAndName,
  IAssociatedAccounts,
  IConfiguration,
  IEhrCapability,
  IInfoOnMessageWindowHeader,
  ILoginUserData,
  IMlov,
  IPersonContact,
  IUserPermission,
  IUserRoleCategory,
  IUserRoleCode,
  UserActionMasterData
} from '../Interfaces';
import { IWSSender } from '../Interfaces/WebSocketEventInterfaces';
import { IMemberSatisfactionForm } from '../components/RightSideContainer/Analytics/EmployerAnalytics/interface';
import { CALL_MESSAGE_TYPE_NUM } from '../components/RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import { isCommunicateWithInactivePatient } from '../components/RightSideContainer/TeamInbox/Conversations/MessagingWindow/MsgConst';
import { PRONOUN_CODE } from '../components/common/DisplayCard/DisplayAvatarConst';
import { CONFIG_CODES,ON_OFF_CONFIG_VALUE } from '../constants/AccountConfigConst';
import { FHIR_RESOURCE } from '../constants/FhirConstant';
import { IOnlineUserData, IUserPracticeLocations } from '../context/interfaces';
import BaseService from '../services/CommonService/BaseService';
import { IUserPracticeLocation } from '../services/Location/interfaces';
import { IUserSettingsByCode } from '../services/UserSettings/interfaces';
import { Colors } from '../styles';
import { IContact, IContactPracticeLocations, IConversationData } from './../components/RightSideContainer/TeamInbox/Conversations/interfaces';
import { BROWSER_TYPE,browserInfo } from './BrowserInfo';
import { convertUnixDate,isDateBetweenRange } from './DateUtils';
import LocalStorage from './LocalStorage';
import { WIDGET_TYPES } from '../components/RightSideContainer/Forms/FormsConstants';
import {IUserAccountLocationList} from '../components/common/ProfileView/interface';
import { ICopayDetail, IInsuranceMandatoryFieldAccordingToEHR, IInsuranceState } from '../components/InsuranceInterfaces';
import { ITemplateCategory } from '../components/RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import { CATEGORY_CODE } from './commonViewUtils';
import { getResourceAbilities,isBirthSexPrimary } from './capabilityUtils';
import { getAccountConfigValue } from './configUtils';
import { IUserType } from './interfaces';
import { getMlovIdFromCode } from './mlovUtils';
import { isWeb } from './platformCheckUtils';
import { isInsuranceStateEmpty } from '../components/InsuranceUtils';
import jwtDecode from 'jwt-decode';
import CommonService from '../services/CommonService/CommonService';
import { ILocation } from '../components/RightSideContainer/ContentManagement/CampaignManagement/interfaces';
import {EHRName} from '../components/PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {IPermitCaslAbility} from '../screens/MainScreen/interface';
import { usePermissions } from '../components/CustomHooks/usePermissions';
import FeatureFlags from '../constants/FeatureFlags.enums';
import { ICarePlanProps } from '../components/PersonOmniView/MiddleContainer/CarePlan/interfaces';
import {BASE_URL} from '../constants/Configs';
import { IContactScoreAudit, IHccScoreData, IScoreType } from '../components/common/MemebersView/interface';
import { IContactScoreReference } from '../components/RafScore/interface';
import { IAvailableEhr } from '../components/PersonOmniView/MiddleContainer/interfaces';
import { TaskViewContext } from '../context/TaskViewContext';
import { ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP } from '../components/PersonOmniView/RightContainer/CareTeamView/CareTeamUtils';

export const getCommerceSubdomain = () => {
  let commerceSubdomain = '';
  const envStr = getEnvVariable();
  const commonData = useContext(CommonDataContext);
  const subdomainMap: any = commonData.accountSubdomain || {};
  const subdomainName: string = subdomainMap?.subDomain;
  if (subdomainName && envStr.length) {
    commerceSubdomain = subdomainName.replace(envStr, 'website.' + envStr);
  } else if (subdomainName && !envStr.length) {
    const tenantName = subdomainName.replace('.foldhealth.io', '');
    commerceSubdomain = `${tenantName}.website.foldhealth.io`;
  }
  return commerceSubdomain;
};

export const getSubdomainBaseUrl = () => {
  let commerceSubdomain = '';
  const envStr = getEnvVariable();
  const commonData = useContext(CommonDataContext);
  const subdomainMap: any = commonData.accountSubdomain || {};
  const subdomainName: string = subdomainMap?.subDomain;
  if (subdomainName && envStr.length) {
    commerceSubdomain = subdomainName.replace(envStr, envStr);
  } else if (subdomainName && !envStr.length) {
    const tenantName = subdomainName.replace('.foldhealth.io', '');
    commerceSubdomain = `${tenantName}.foldhealth.io`;
  }
  const url = `https://${commerceSubdomain}`;

  return url;
};

export const getCarePlanProps = (
  formattedContactData: IContact | undefined,
  contactData: IContact | undefined,
  setIsNavigatingToCarePlan: (isAdded: boolean) => void,
  isCompactView?: boolean
): ICarePlanProps => {
  return {
    accountLocationUuid: formattedContactData?.accountLocationUuid || '',
    contactUuid: contactData?.uuid || '',
    contactName: formattedContactData?.name || '',
    contactId: formattedContactData?.id || 0,
    personData: formattedContactData,
    onClose: (isAdded: boolean) => {
      setIsNavigatingToCarePlan(isAdded);
    },
    isCompactView: isCompactView
  };
};

export const getEmailContactObjFromList = (
  contactList: IPersonContact[],
  personContactMlovList: IMlov[],
  rank?: number
): IPersonContact => {
  const mlovId = getMlovIdFromCode(
    personContactMlovList,
    PERSON_CONTACT_TYPE_CODES.EMAIL
  );
  const emailContactObj = find(contactList, {
    typeId: mlovId,
    rank: rank,
  }) as never;
  return emailContactObj;
};

export const getPhoneContactObjFromList = (
  contactList: IPersonContact[],
  personContactMlovList: IMlov[],
  rank?: number
): IPersonContact => {
  const mlovId = getMlovIdFromCode(
    personContactMlovList,
    PERSON_CONTACT_TYPE_CODES.PHONE
  );
  const phoneContactObj = find(contactList, {
    typeId: mlovId,
    rank: rank,
  }) as never;
  return phoneContactObj;
};

// export const getAvatarColorInHsl = (
//   nameStr: string,
//   saturation = 60,
//   lightness = 80
// ): string => {
//   let nameStrHash = 0;
//   for (let nameStrIndex = 0; nameStrIndex < nameStr.length; nameStrIndex++) {
//     nameStrHash =
//       nameStr.charCodeAt(nameStrIndex) + ((nameStrHash << 5) - nameStrHash);
//   }
//   const nameHashColor = nameStrHash % 360;

//   const hlsColor =
//     'hsl(' + nameHashColor + ', ' + saturation + '%, ' + lightness + '%)';
//   return '#F9FAFB';
// };

export const getAvatarColorInHsl = (
  nameStr: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  saturation = 60,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lightness = 80
): string => {
  let nameStrHash = 0;
  for (let nameStrIndex = 0; nameStrIndex < nameStr.length; nameStrIndex++) {
    nameStrHash =
      nameStr.charCodeAt(nameStrIndex) + ((nameStrHash << 5) - nameStrHash);
  }
  const nameHashColor = nameStrHash % 360;

  const hlsColor =
    'hsla(' + nameHashColor + ', ' + saturation + '%, ' + lightness + '%, .3)';
  return hlsColor;
};

export const getInitialsFromFullName = (fullName: string): string => {
  let nameList = fullName.split(' ');
  let isDashPresent = false;
  if (nameList.length === 1) {
    nameList = fullName.split('-');
    isDashPresent = true;
  }
  let nameInitials = '';
  if (nameList.length > 2) {
    if (isDashPresent) {
      nameInitials =
        nameList[0].charAt(0).toUpperCase() +
        '' +
        nameList[1].charAt(0).toUpperCase();
    } else {
      nameInitials =
        nameList[0].charAt(0).toUpperCase() +
        '' +
        nameList[nameList.length - 1].charAt(0).toUpperCase();
    }
  } else if (nameList.length > 1) {
    nameInitials =
      nameList[0].charAt(0).toUpperCase() +
      '' +
      nameList[1].charAt(0).toUpperCase();
  } else {
    nameInitials = nameList[0].charAt(0).toUpperCase();
  }
  return nameInitials;
};

export const getInitials = (firstName?: string, lastName?: string) => {
  let initials = '';
  if (firstName) {
    initials = firstName.trim().substring(0, 1).toUpperCase();
  }
  const words = firstName?.split(' ') || [];
  lastName = lastName ? lastName : words?.length > 1 ? words[1] : undefined;
  if (lastName) {
    initials += lastName.trim().substring(0, 1).toUpperCase();
  }
  return initials;
};

export const getPersonFullName = (
  personData: Record<string, string>,
  separator = ' '
): string => {
  const firstName = personData?.firstName || '';
  const lastName = personData?.lastName || '';
  const middleName = personData?.middleName || '';
  return firstName + separator + middleName + separator + lastName;
};

export const getColorFromOnlineStatus = (onlineStatus: string): string => {
  const colorCode =
    ONLINE_STATUS_COLORS[onlineStatus] || Colors.Custom.OfflineColor;
  return colorCode;
};

//fetch type api process response to get the status and json seperate
export const processResponse = (response: any) => {
  const statusCode = response.status;
  const data = response.json();
  const resHeaders = response.headers;
  return Promise.all([statusCode, data, resHeaders]).then((res) => ({
    statusCode: res[0],
    data: res[1],
    responseHeaders: res[2],
  }));
};

export const findStringDifference = (previous: string, current: string): number => {
  const minLength = Math.min(previous?.length, current?.length);

  for (let i = 0; i < minLength; i++) {
    if (previous[i] !== current[i]) {
      return i;
    }
  }
  return previous.length === current.length ? -1 : minLength;
};

export const isEmptyString = (input: string) => {
  if (
    input === undefined ||
    input === '' ||
    input == null ||
    (typeof input == 'string' && !input.trim())
  ) {
    return true;
  }
  return false;
};

export const parseHtmlToPlainText = (htmlString: string): string => {
  const scriptPattern = /<script[^>]*>(?:.|\s)*?<\/script>/gi; // Script tags
  const srcPattern = /\bsrc\s*=\s*(?:'([^']*)'|"([^"]*)"|([^>]*))/gi; // src attributes
  const evalPattern = /eval\(\s*([^)]*)\)/gi; // eval expressions
  const expressionPattern = /expression\(\s*([^)]*)\)/gi; // expression expressions
  const javascriptPattern = /javascript:/gi; // javascript: URIs
  const vbscriptPattern = /vbscript:/gi; // vbscript: URIs
  const onloadPattern = /onload\s*=\s*([^=]*)/gi; // onload attributes
  const brPattern = /<br\s*\/?>/gi; // <br> pattern

  const patterns = [
    scriptPattern,
    srcPattern,
    evalPattern,
    expressionPattern,
    javascriptPattern,
    vbscriptPattern,
    onloadPattern,
    brPattern
  ];

  // Sanitize the input string
  patterns.forEach((pattern) => {
    htmlString = htmlString?.replace(pattern, '');
  });
  htmlString = htmlString?.replace(/<(?![ ])/g, "< ")
  return htmlString;
};

export const isValidObject = (input: any) => {
  return !(input == undefined || input == null);
};

export const getAccountId = () => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  return userData.account_id || '';
};

export const getEmployerId = () => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  return userData.employerId || '';
}

export const getAccountName = () => {
  const commonData = useContext(CommonDataContext);
  const subdomainMap: any = commonData.accountSubdomain || {};
  const tenantName: string =
    subdomainMap?.accountToSubdomain?.name || 'tenant name';
  return tenantName || '';
};

export const navigateToOtherScreen = (navigate: any, url: string, navigateToNewTab?: boolean) => {
  const targetWindowName =
    url.indexOf('admin') != -1
      ? MAIN_MENU_CODES.DESIGNER
      : MAIN_MENU_CODES.HOME;
  const currentWindowName =
    (window?.location?.href || '').indexOf('#/admin') != -1
      ? MAIN_MENU_CODES.DESIGNER
      : MAIN_MENU_CODES.HOME;
  if (window) {
    window.name =
      (window?.location?.href || '').indexOf('#/admin') != -1
        ? MAIN_MENU_CODES.DESIGNER
        : MAIN_MENU_CODES.HOME;
  }
  if (navigateToNewTab && window) {
    if (url.indexOf('#') == -1) {
      if (url.indexOf('/') == 0) {
        url = '/#' + url;
      } else {
        url = '/#/' + url;
      }
    }
    url = window.location.origin + url;
    return window.open(url, targetWindowName, 'noopener,noreferrer');
  }

  if (true || (navigate && targetWindowName == currentWindowName) || !window) {
    url = url.replace('/#', '');
    url = url.replace('#', '');
    return navigate(url);
  }
};

export const getAccountUUID = () => {
  const commonData = useContext(CommonDataContext);
  return commonData?.userData?.accounts && commonData.userData.accounts.length
    ? commonData.userData.accounts[0].uuid
    : '';
};
export const getAssociatedAccounts = () => {
  const commonData = useContext(CommonDataContext);
  return commonData?.associatedAccounts && commonData?.associatedAccounts?.length
    ? commonData.associatedAccounts
    : []  as IAssociatedAccounts[];
};
export const getUsersAccounts = () => {
  const commonData = useContext(CommonDataContext);
  return commonData?.userAccounts && commonData?.userAccounts?.length
    ? commonData.userAccounts
    : []  as IUserAccountLocationList[];
};
export const getConfigDataFromCode = (configCode: string): IConfiguration => {
  const commonData: any = useContext(CommonDataContext);
  return commonData?.accountConfigData?.[configCode] || ({} as any);
};

export const getFoldAccessToken = () => {
  const commonData = useContext(CommonDataContext);
  return commonData?.userData?.fold_access_token || '';
};

export const isMasterAccount = () => {
  const commonData = useContext(CommonDataContext);
  if (commonData?.userMainDataObj?.hasOwnProperty('isMasterAccount') && commonData?.hasOwnProperty('associatedAccounts')) {
    const isHaveAssociated = commonData?.associatedAccounts && commonData?.associatedAccounts?.length > 1 ? true : false
    const isMasterAccount = commonData?.userMainDataObj?.isMasterAccount && isHaveAssociated
    return isMasterAccount
   }
   return false
};
export const isChildAccount = () => {
  const commonData = useContext(CommonDataContext);
  if (commonData?.userMainDataObj?.hasOwnProperty('isMasterAccount')) {
   return !commonData.userMainDataObj.isMasterAccount
  }
  return false
};

export const getContactUuid = () => {
  const commonData = useContext(CommonDataContext);
  return commonData?.userData?.uuid;
};

export const isSideCardEnabled = () => {
  const mlovData = useContext(CommonDataContext);
  return mlovData.sidecarContext?.isSidecar;
}

export const getUserId = () => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  return userData.id || '';
};
export const getUserName = () => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  return userData.name || '';
};

export const getUserEmail = () => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  return userData.email || '';
};

export const getUserUUID = (): string => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  return userData.uuid || '';
};
export const getUserData = (): ILoginUserData => {
  const commonData = useContext(CommonDataContext);

  return commonData?.userData || ({} as any);
};

export const isCRMRole = (): boolean => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  return userData.role === 'CRM';
};

export const filterWorkflowUser = (accountUsers: any[], loggedInUserId: string, customRoleFilter?: (userRole: any) => boolean) => {
  return accountUsers?.filter((item) => {
    if (!item?.userRoles?.length) {
      return true;
    }

    if (item.uuid && item.uuid === loggedInUserId) {
      return true;
    }

    for (const userRole of item.userRoles) {
      if (!!customRoleFilter) {
        return customRoleFilter(userRole)
      } else if (userRole?.userRole?.userRole?.code === USER_ROLE_CODES.WORKFLOW || userRole?.userRole?.userRole?.code === USER_ROLE_CODES.CUSTOMER_SUCCESS) {
        return false;
      }
    }
    return true;
  });
}

export const getCurrentUserRole = (commonDataObject?: ICommonData): IUserRoleCode[] => {
  let commonData: any = {};
  try {
    commonData = commonDataObject ?? useContext(CommonDataContext);
  } catch (e) { }
  const currentUserRole =
    commonData?.currentUserRoles || ([] as IUserRoleCode[]);
  return currentUserRole;
};

export const getUserPermissions = (): IUserPermission[] => {
  let commonData: any = {};
  try {
    commonData = useContext(CommonDataContext);
  } catch (e) { }
  const userPermissions =
    commonData?.userPermissions || ([] as IUserPermission[]);
  return userPermissions;
};

export const getUserFullName = () => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  return userData.name || '';
};

export const getCaslAbility = () => {
  const commonData = useContext(CommonDataContext);
  const caslAbility = commonData?.ability || ({} as IPermitCaslAbility);
  return caslAbility;
}

export const getTypingUsersText = (users: IWSSender[] = []) => {
  if (users && users.length) {
    const count = users.length;
    if (count === 1) {
      const [user] = users;
      return `${user.name} is typing`;
    }

    if (count === 2) {
      const [first, second] = users;
      return `${first.name} and ${second.name} are typing`;
    }

    const [user] = users;
    const rest = users.length - 1;
    return `${user.name} and ${rest} others are typing`;
  }
  return '';
};

export const getTypingUsersToolTipText = (users: IWSSender[] = []): string => {
  let userNameListStr = '';
  if (users && users.length) {
    users.forEach((senderUser, index) => {
      if (index === users.length - 1) {
        userNameListStr += senderUser.name;
      } else {
        userNameListStr += senderUser.name + '\n';
      }
    });
  }

  return userNameListStr;
};

export const getPatientName = (patient: any): string => {
  let patientName = '';
  if (patient.name && patient.name.length > 0) {
    const officialName = patient.name[0];
    const lastName = officialName.family;
    const firstName =
      officialName.given && officialName.given.length > 0
        ? officialName.given[0]
        : '';
    patientName = firstName + ' ' + lastName;
  }
  return patientName;
};

export const getPatientAgeGender = (patientContact: any) => {
  let text = '';
  const age = moment().diff(patientContact?.birthDate, 'years');
  const gender = getPrimaryGenderValue({person: patientContact});
  if (age && gender) {
    text = `${age}Y  |  ${gender}`;
  } else if (age) {
    text = age + 'Y';
  } else {
    text = gender;
  }
  return text;
};

export const isStringArrayElementDiff = (
  list1: Array<string>,
  list2: Array<string>
) => {
  let isNotMatchedAnyValue = list1.some((value1) => {
    return list2.indexOf(value1) == -1;
  });
  if (!isNotMatchedAnyValue) {
    isNotMatchedAnyValue = list2.some((value2) => {
      return list1.indexOf(value2) == -1;
    });
  }
  return isNotMatchedAnyValue;
};

export const getMsgEchoId = () => {
  return 'xxxxxxxx4xxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const formatToLink = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text?.replace(urlRegex, function (url) {
    return `[${url}](${url})`;
  })
}

export const addQueryParamsInApiPath = (
  apiUrl: string,
  queryParams: any
): string => {
  const queryParamsKeys = Object.keys(queryParams);
  for (let index = 0; index < queryParamsKeys.length; index++) {
    const currentKey = queryParamsKeys[index];
    const value = queryParams[currentKey];
    if (index === queryParamsKeys.length - 1) {
      apiUrl += `${currentKey}=${value}`;
    } else {
      apiUrl += `${currentKey}=${value}&`;
    }
  }
  return apiUrl;
};

export const getQueryObjectFromURL = (urlParams: URLSearchParams): any => {
  const queryObject: any = {};
  urlParams.forEach((value, key) => {
    queryObject[key] = value;
  });
  return queryObject;
};

export const getQueryParams = (query?: string) =>
  (query || window.location.search.replace('?', ''))
    .split('&')
    .map((e) => e.split('=').map(decodeURIComponent))
    .reduce((r: any, [k, v]) => ((r[k] = v), r), {});

export const getValueFromList = (list: any[], key: string): any[] => {
  const values: any[] = [];
  list.forEach((listItem) => {
    if (listItem[key]) {
      values.push(listItem[key]);
    }
  });
  return values;
};

export const getTpaFormURL = (
  token: string
): string => {
  if (isWeb()) {
    return (
      window.location.origin +
      '/widget.html#/public/forms?' +
      generateQueryString({
        isWidget: true,
        token: encodeURIComponent(token),
        widgetType: WIDGET_TYPES.TPA_CARE_ADVOCATE
      })
    );
  } else {
    return (
      window.location.origin +
      '/widget.html#/public/forms?' +
      generateQueryString({
        isWidget: true,
        token: encodeURIComponent(token),
        widgetType: WIDGET_TYPES.TPA_CARE_ADVOCATE
      })
    );
  }
};

export const getFormURL = (
  formId: string,
  contactId: string,
  accountId: string,
  subjectId?: string,
  sourceId?: string,
  subdomainBaseUrl?: string,
  eventId?: string,
): string => {
  if (isWeb()) {
    return (
      `${subdomainBaseUrl ? subdomainBaseUrl : window.location.origin}` +
      '/widget.html#/public/forms?' +
      generateQueryString({
        isWidget: true,
        formId,
        contactId,
        accountId,
        subjectId,
        sourceId,
        eventId,
      })
    );
  } else {
    return (
      subdomainBaseUrl +
      '/widget.html#/public/forms?' +
      generateQueryString({
        isWidget: true,
        formId,
        contactId,
        accountId,
        subjectId,
        sourceId,
        eventId,
      })
    );
  }
};

export const getFormURLNative = (
  subDomainUrl: string,
  formId: string,
  contactId: string,
  accountId: string,
  subjectId?: string,
  sourceId?: string,
  eventId?: string
): string => {
  const baseUrl = subDomainUrl || `https://${getCurrentSubdomainNative()}`;
  return (
    baseUrl +
    '/widget.html#/public/forms?' +
    generateQueryString({
      isWidget: true,
      client: isWeb() ? 'web' : 'app',
      formId,
      contactId,
      accountId,
      subjectId,
      sourceId,
      eventId,
    })
  );
};

export const getAppointmentBookingLink = (
  accountUUID: string,
  contactUUID?: string,
  subdomainBaseUrl?: string,
  appointmentType?: IAppointmentType,
  userIds?: string[],
): string => {
  if (isWeb()) {

    let appointmentLink = `${subdomainBaseUrl ? subdomainBaseUrl : window.location.origin}` +
      '/widget.html#/public/appointment/booking?' +
      generateQueryString({
        accountId: accountUUID,
        isWidget: true,
      });
    if (contactUUID) {
      appointmentLink += `&contactId=${contactUUID}`;
    }
    if (appointmentType?.id) {
      appointmentLink += `&appointmentTypeId=${appointmentType?.id}`;
    }
    if (userIds?.length) {
      appointmentLink += `&appointmentUsers=${userIds}`;
    }
    return appointmentLink;
  } else {
    let appointmentLink =
      subdomainBaseUrl +
      '/widget.html#/public/appointment/booking?' +
      generateQueryString({
        accountId: accountUUID,
        isWidget: true,
      });
    if (contactUUID) {
      appointmentLink += `&contactId=${contactUUID}`;
    }
    if (appointmentType?.id) {
      appointmentLink += `&appointmentTypeId=${appointmentType?.id}`;
    }
    if (userIds?.length) {
      appointmentLink += `&appointmentUsers=${userIds}`;
    }
    return appointmentLink;
  }
};

export const generateQueryString = (data: any): string => {
  const join = '&';
  let queryString = '';
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      if (queryString.length > 0) {
        queryString += join;
      }
      queryString += `${key}=${data[key]}`;
    }
  });
  return queryString;
};

export const isAdminUser = () => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData;
  return userData?.role?.toLowerCase() === 'administrator';
};

export const isAdminView = () => {
  return window.location.href.includes('/#/admin/');
};

export const isWebChannelInboxType = (selectInboxTypeCode: string) => {
  return (
    selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET ||
    selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL ||
    selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ALL
  );
};

export const isInternalChannelInboxType = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL;
};

export const isWebOnlyChannelInboxType = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET;
};

export const isAllInboxType = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ALL;
};

export const isEmailChannelInboxType = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL;
};

export const isAllCallsChannelInboxType = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE_CODE.ALL_CALLS;
};

export const isEFaxChannelInbox = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_E_FAX;
};

export const isArchivedChannel = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED;
};

export const isSMSChannelInboxType = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS;
};

export const isChannelTwillioSms = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE.CHANNEL_TWILIO_SMS;
};

export const isChannelTypeWebWidget = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE.CHANNEL_WEB_WIDGET;
};

export const isChannelEmail = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE.CHANNEL_EMAIL;
};

export const isChannelEmailOrSms = (selectInboxTypeCode: string) => {
  return (
    isChannelEmail(selectInboxTypeCode) ||
    isChannelTwillioSms(selectInboxTypeCode)
  );
};

export const isMissedCallChannelInboxType = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS;
};

export const isSMSEmailMissedCallChannelInboxType = (selectInboxTypeCode: string) => {
  return (
    isEmailChannelInboxType(selectInboxTypeCode) ||
    isSMSChannelInboxType(selectInboxTypeCode) ||
    isMissedCallChannelInboxType(selectInboxTypeCode)
  );
};

export const isSMSMissedAllCallsChannelInboxType = (selectInboxTypeCode: string) => {
  return (
    selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS ||
    selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS ||
    selectInboxTypeCode === CHANNEL_TYPE_CODE.ALL_CALLS
  );
};

export const showExtraEditorToorbarIcon = (channelType: string) => {
  if (channelType) {
    if (channelType === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export const isCurrentSelectedGroupInternal = (
  selectConversationData: IConversationData
) => {
  if (
    selectConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.USER_BROADCAST ||
    selectConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.INTERNAL
  ) {
    return true;
  }
  return false;
};


export const isString = (value: any): boolean => {
  return typeof value === 'string' || value instanceof String;
};

export const toBase64 = (content: any) => {
  return Buffer.from(content).toString('base64');
};

export const fromBase64 = (content: any) => {
  return Buffer.from(content, 'base64').toString('utf-8');
};

export const isNonPractitionerUser = (): boolean => {
  const commonContextData = useContext(CommonDataContext);
  commonContextData.currentUserRoles.some((userRole: any) => {
    return (
      userRole.code === USER_ROLE.NURSE_PRACTITIONER ||
      userRole.code === USER_ROLE.BUSINESS_OWNER ||
      userRole.code === USER_ROLE.PHYASST
    );
  });
  return true;
};

export const isLoginUserBusinessOwner = (commonData?: ICommonData): boolean => {
  const currentUserRoles = getCurrentUserRole(commonData);
  const isBusinessOwner = currentUserRoles.some((role) => {
    return (
      USER_ROLE_CODES.BUSINESS_OWNER === role.code ||
      GLOBAL_ROLE_CODES.GLOBAL_ADMIN === role.code ||
      isWorkflowOrCustomerSuccessUser(commonData)
    );
  });
  return isBusinessOwner;
};
export const isLoginUserOnlyBusinessAnalyticRole = (
  commonData?: ICommonData
): boolean => {
  const currentUserRoles = getCurrentUserRole(commonData);
  if (currentUserRoles.length !== 1) {
    return false;
  }
  return currentUserRoles.some(
    (role) => GLOBAL_ROLE_CODES.SUPER_ANALYTIC === role.code
  );
};

export const isWorkflowOrCustomerSuccessUser = (commonData?: ICommonData): boolean => {
  const userRoles = getCurrentUserRole(commonData);
  const isWorkflowUser = ((userRoles || []).some((role) => {
    return role?.code === USER_ROLE_CODES.WORKFLOW || role?.code === USER_ROLE_CODES.CUSTOMER_SUCCESS
  }));
  return isWorkflowUser
}

export const isUserBusinessOwner = (selectedUserRoles: any): boolean => {
  const isBusinessOwner = selectedUserRoles?.some((role: any) => {
    return (
      USER_ROLE_CODES.BUSINESS_OWNER === role?.userRole?.customRoleCode ||
      GLOBAL_ROLE_CODES.GLOBAL_ADMIN === role?.userRole?.customRoleCode
    );
  });
  return isBusinessOwner;
};

export const isLoginUserStrictlyBusinessOwner = (commonData?: ICommonData): boolean => {
  const currentUserRoles = getCurrentUserRole(commonData);
  const isBusinessOwner = currentUserRoles.some((role) => {
    return (
      USER_ROLE_CODES.BUSINESS_OWNER === role.code ||
      GLOBAL_ROLE_CODES.GLOBAL_ADMIN === role.code
    );
  });
  return isBusinessOwner;
};

export const isLoggedInUserGlobalAdmin = (): boolean => {
  const currentUserRoles = getCurrentUserRole();
  const isGlobalAdmin = currentUserRoles.some((role) => {
    return GLOBAL_ROLE_CODES.GLOBAL_ADMIN === role.code;
  });
  return isGlobalAdmin ;
};

export const checkLoggedInUserRolesAccess = (roles: string[]): boolean => {
  const currentUserRoles = getCurrentUserRole();
  const access = currentUserRoles.some((role) => {
    const haveAccess = roles.includes(role.code);
    return haveAccess;
  });
  return access;
  // return isLoginUserBusinessOwner() || access;
};

export const isLoggedInUserBusinessAnalytics = (): boolean => {
  const currentUserRoles = getCurrentUserRole();
  const isBusinessAnalytics = currentUserRoles.some((role) => {
    return GLOBAL_ROLE_CODES.SUPER_ANALYTIC === role.code;
  });
  return isBusinessAnalytics ;
};

export const isAllowedAllLocationToUser = (
  currentUserRolesData: IUserRoleCode[]
): boolean => {
  const isAllowedAllLocation = currentUserRolesData.some((role) => {
    return (
      USER_ROLE_CODES.BUSINESS_OWNER === role.code ||
      GLOBAL_ROLE_CODES.GLOBAL_ADMIN === role.code ||
      USER_ROLE_CODES.WORKFLOW === role.code ||
      USER_ROLE_CODES.CUSTOMER_SUCCESS === role.code
    );
  });
  return isAllowedAllLocation;
};

export const isLoggedInUserWorkFlowOrCustomerSuccess = (commonData?: ICommonData): boolean => {
  const currentUserRoles = getCurrentUserRole(commonData);
  const isGlobalAdmin = currentUserRoles.some((role) => {
    return (
      USER_ROLE_CODES.WORKFLOW === role.code ||
      USER_ROLE_CODES.CUSTOMER_SUCCESS === role.code
    );
  });
  return isGlobalAdmin;
};
export const isLoggedInUserCustomerSuccess = (): boolean => {
  const currentUserRoles = getCurrentUserRole();
  const isCustometSuccess = currentUserRoles.some((role) => {
    return (USER_ROLE_CODES.CUSTOMER_SUCCESS === role.code);
  });
  return isCustometSuccess;
};

export const isLoggedInUserWorkFlow = (): boolean => {
  const currentUserRoles = getCurrentUserRole();
  const isWorkflowUser = currentUserRoles.some((role) => {
    return (USER_ROLE_CODES.WORKFLOW === role.code );
  });
  return isWorkflowUser;
};

export const isLoggedInUserHealthCoach = ():boolean => {
  const currentUserRoles = getCurrentUserRole();
  const isHealthCoach = currentUserRoles.some((role) => {
    return USER_ROLE_CODES.HEALTH_COACH === role.code;
  });
  return isHealthCoach;
}

export const getCurrentUserRoleCodes = (): string[] => {
  const commonData = useContext(CommonDataContext);
  const currentUserRoles = commonData.currentUserRoles;
  return currentUserRoles.map((userRole: any) => {
    return userRole.code;
  });
};

export const getUserOnlineStatus = (
  userOrContactId: string | number,
  userType: IUserType
): boolean => {
  let isUserOnline = false;
  const commonData = useContext(CommonDataContext);
  const onlinePresenceData = useContext(OnlineUserPresenceDataContext);
  const {onlineUserPresenceData} = onlinePresenceData;
  const userData = commonData?.userData || ({} as ILoginUserData);
  if (userType === GROUP_MEMBER_TYPE.USER && userOrContactId == userData.id) {
    isUserOnline = false;
  } else {
    if (
      userType === GROUP_MEMBER_TYPE.CONTACT &&
      onlineUserPresenceData?.contacts &&
      Object.keys(onlineUserPresenceData?.contacts).length &&
      onlineUserPresenceData.contacts[userOrContactId]
    ) {
      isUserOnline = true;
    } else if (
      userType === GROUP_MEMBER_TYPE.USER &&
      onlineUserPresenceData?.users &&
      Object.keys(onlineUserPresenceData?.users).length &&
      onlineUserPresenceData.users[userOrContactId]
    ) {
      isUserOnline = true;
    }
  }
  return isUserOnline;
};


export const isUserOrContactOnline = (
  userOrContactId: string | number,
  userType: IUserType,
  onlineUserPresenceData: IOnlineUserData,
  currentLoginUser: ILoginUserData
): boolean => {
  let isUserOnline = false;
  if (userType === GROUP_MEMBER_TYPE.USER && userOrContactId == currentLoginUser.id) {
    isUserOnline = false;
  } else {
    if (
      (userType === GROUP_MEMBER_TYPE.CONTACT || userType === GROUP_MEMBER_TYPE.PATIENT) &&
      onlineUserPresenceData?.contacts?.[userOrContactId]
    ) {
      isUserOnline = true;
    } else if (
      userType === GROUP_MEMBER_TYPE.USER &&
      onlineUserPresenceData?.users?.[userOrContactId]
    ) {
      isUserOnline = true;
    }
  }
  return isUserOnline;
};

export const injectedJavascript = `(function() {
  const meta = document.createElement('meta');
  meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
  meta.setAttribute('name', 'viewport');
  document.getElementsByTagName('head')[0].appendChild(meta);
  window.postMessage = function(data) {
    window.ReactNativeWebView.postMessage(data);
  };
})()`;
export const range = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(start)

    .map((_, idx) => start + idx);
};

export const isValidEmail = (email: string) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return email.match(emailRegex);
};

export const isValidPhoneNumber = (number: string) => {
  const phoneRegex = /^(?!0{10})\d{10}$/;
  return number.match(phoneRegex);
};

export const isValidURL = (url: string) => {
  const urlRegex = /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/;
  return url.match(urlRegex);
};


export const numericStringMask = (str: string, mask: string) => {
  if (!mask) return str;

  if (str && str.length === 10) {
    const numeric = str.replace(/[^\d]/g, '');
    let idx = 0;
    const formatted = mask.split('').map((el) => {
      if (el === '#') {
        el = numeric[idx];
        idx++;
      }
      return el;
    });
    return formatted.join('');
  } else if (str && str.length > 10) {
    const unmaskNumber = unmaskPhoneNumber(str);
    const firstTenNumber = unmaskNumber.slice(0, 10);
    const numeric = firstTenNumber.replace(/[^\d]/g, '');
    let idx = 0;
    const formatted = mask.split('').map((el) => {
      if (el === '#') {
        el = numeric[idx];
        idx++;
      }
      return el;
    });
    return formatted.join('');
  }
  return str;
};
export const removeScriptsAndStyles = (htmlStr: string) => {
  const div = document.createElement('div');
  div.innerHTML = htmlStr;
  const scripts: any = div.getElementsByTagName('script');
  let scriptsLength = scripts.length;
  while (scriptsLength--) {
    scripts[scriptsLength]?.parentNode?.removeChild(scripts[scriptsLength]);
  }

  const styles: any = div.getElementsByTagName('style');
  let stylesLength = styles.length;
  while (stylesLength--) {
    styles[stylesLength]?.parentNode?.removeChild(styles[stylesLength]);
  }
  return div.innerHTML;
};

export const getCurrentSubdomainWeb = () => {
  if (window.location.host.includes('localhost')) {
    return `multiehr.${evnName()}foldhealth.io`;
  }

  return window.location.host;
};

export const getIsExportSupported = () => {
  const currentURL = getCurrentSubdomainWeb();
  const exportSupportedURL = ['fold.sandbox.foldhealth.io', 'demo.sandbox.foldhealth.io', 'seed.sandbox.foldhealth.io'];
  const isExportSupported = exportSupportedURL.indexOf(currentURL) != -1;
  return isExportSupported;
}

export const showEnvironmentInCampaign = () => {
  const currentURL = getCurrentSubdomainWeb();
  const exportSupportedURL = ['fold.sandbox.foldhealth.io'];
  const isExportSupported = exportSupportedURL.indexOf(currentURL) != -1;
  return isExportSupported;
}

export const getCurrentSubdomainForPreview = () => {
  if (window.location?.host?.includes('localhost')) {
    return 'https://' + `btmpreview.${evnName()}foldhealth.io`;
  }
  return window?.location?.origin;
};



export const unmaskPhoneNumber = (str: string) => {
  return str.replace(/[^\d]/g, '');
};

export const isEmptyObject = (object: any) => {
  return Object.keys(object || {}).length === 0;
};

export const getPhoneNumberAndCountryCode = (phoneNumber: string) => {
  let formattedPhoneNumber = phoneNumber;
  let phoneNumberCountryCode = '+1';
  if (phoneNumber && phoneNumber?.length > 10) {
    const phoneDigitList: any = phoneNumber.match(/[\d]/gi);
    if (phoneDigitList && phoneDigitList?.length > 10) {
      const listOfDigits = phoneDigitList.splice(-10);
      formattedPhoneNumber = listOfDigits.join('');
      phoneNumberCountryCode = phoneDigitList.join('');
      if (!phoneNumberCountryCode) {
        phoneNumberCountryCode = '+1';
      }
    } else if (phoneDigitList?.length === 10) {
      formattedPhoneNumber = phoneDigitList.join('');
    } else {
    }
  }
  return {
    formattedPhoneNumber,
    phoneNumberCountryCode,
  };
};

export const getSourceValue = (source: IContactSource): string => {
  return (
    CONTACT_SOURCE_DISPLAY_STR[source] ||
    source ||
    CONTACT_SOURCE_DISPLAY_STR.UNKNOWN
  );
};

export const isEmptyArray = (array: any[]) => {
  const result = !(array?.length > 0);
  return result;
};
export const getUserRoles = (singleUserData: IUser) => {
  const userRoleCodes: string[] = [];
  singleUserData?.userRoles?.forEach((userRoleObj) => {
    if (userRoleObj?.userRole?.userRole?.code) {
      userRoleCodes.push(userRoleObj?.userRole?.userRole?.code);
    }
  });
  return userRoleCodes;
};

export const findIsAllowToRunAutomation = ():boolean => {
  const userRoles = getCurrentUserRole();
  const isWorkflowUser =  ((userRoles|| []).some((role)=>{
    return role?.code === 'WORKFLOW' || role?.code === 'CUSTOMER_SUCCESS'
  }));
  const currentURL = getCurrentSubdomainWeb();
  return (!isWorkflowUser || (!!getEnvVariable() || currentURL.indexOf('preview') != -1))

}

export const transformStingToCamelcase = (text: string) => {
  return text.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (camelCaseMatch, i) {
    if (+camelCaseMatch === 0) return '';
    return i === 0
      ? camelCaseMatch.toLowerCase()
      : camelCaseMatch.toUpperCase();
  });
};

export const IS_FEATURE_FLAG_ENABLED = (flagName: string) => {
  const commonData = useContext(CommonDataContext);

  const subdomainMap: any = commonData.accountSubdomain || {};

  const attributes = subdomainMap?.additionalAttributes;

  if (attributes && attributes[flagName] !== undefined) {
    return attributes[flagName];
  }

  return false;
};

export const getFormPrintURL = (form: any, uuid: string): string => {
  return (
    window.location.origin +
    '/widget.html#/public/forms?formId=' +
    form.id +
    '&accountId=' +
    uuid +
    '&isPrint=' +
    true +
    '&readOnly=' +
    true
  );
};

export const getScreenChecks = (): {
  isIPadScreen: boolean;
  isIPadMiniScreen: boolean;
} => {
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  return {
    isIPadMiniScreen,
    isIPadScreen,
  };
};

export const getDrawerWidthByScreens = (args: {
  screenWidth: number;
  divider: number;
}) => {
  const {screenWidth, divider} = args;
  const {isIPadMiniScreen, isIPadScreen} = getScreenChecks();
  if (isIPadMiniScreen || isIPadScreen) {
    return screenWidth / 2;
  }
  return divider ? screenWidth / divider : screenWidth / 3;
};

export const isMemberShipWithinDateRange = (memberShip: IMembershipData) => {
  const startDate = convertUnixDate(memberShip.current_period_start);
  const emdDate = convertUnixDate(memberShip.current_period_end);
  return isDateBetweenRange(new Date(), startDate, emdDate);
};

export function getDisplayTimeSaved(timeSavedInSeconds: number) {
  const timeSavedInUnits = (
    Math.floor(timeSavedInSeconds / 86400) +
    ':' +
    new Date(timeSavedInSeconds * 1000).toISOString().substr(11, 8)
  ).split(':');
  const timeSaved = {
    day: {
      value: parseInt(timeSavedInUnits[0]),
      unit: 'Days',
    },
    hour: {
      value: parseInt(timeSavedInUnits[1]),
      unit: 'Hours',
    },
    minute: {
      value: parseInt(timeSavedInUnits[2]),
      unit: 'Minutes',
    },
    second: {
      value: parseInt(timeSavedInUnits[3]),
      unit: 'Seconds',
    },
  };
  let timeSavedInSecondsStr = '';
  if (timeSaved.day.value) {
    timeSavedInSecondsStr = `${timeSaved.day.value} ${timeSaved.day.unit}`;
  } else if (timeSaved.hour.value) {
    timeSavedInSecondsStr = `${timeSaved.hour.value} ${timeSaved.hour.unit}`;
  } else if (timeSaved.minute.value) {
    timeSavedInSecondsStr = `${timeSaved.minute.value} ${timeSaved.minute.unit}`;
  } else if (timeSaved.second.value) {
    timeSavedInSecondsStr = `${timeSaved.second.value} ${timeSaved.second.unit}`;
  }
  return timeSavedInSecondsStr;
}
export const getPrimaryGenderValue = (contactData: any) => {
  let gender =
    contactData?.person?.gender?.value ||
    contactData?.person?.sexAtBirth?.value ||
    '';
  if (
    isBirthSexPrimary(
      contactData?.contactPracticeLocations?.[0]?.accountLocation.uuid
    )
  ) {
    gender =
      contactData?.person?.sexAtBirth?.value ||
      contactData?.person?.gender?.value ||
      '';
  }
  return gender;
};

export const getPrimaryGenderCode = (contactData: any) => {
  let genderCode =
    contactData?.person?.sexAtBirth?.code ||
    contactData?.person?.gender?.code ||
    '';
  if (isBirthSexPrimary('')) {
    genderCode =
      contactData?.person?.sexAtBirth?.code ||
      contactData?.person?.gender?.code ||
      '';
  }
  return genderCode;
};

export const getBirthSexPrimaryGenderCode = (contactData: any, isBirthSexPrimaryValue: any) => {
  let genderCode =
    contactData?.person?.gender?.code ||
    contactData?.person?.sexAtBirth?.code ||
    '';
  if (isBirthSexPrimaryValue) {
    genderCode =
      contactData?.person?.sexAtBirth?.code ||
      contactData?.person?.gender?.code ||
      '';
  }
  return genderCode;
};

export const isActiveContact = (conversationContact: any) => {
  // intentionally added this change.
  // will be changed in the future.
  return true;
  return conversationContact?.isActive;
};

export const isContactVerified = (personContact: any, actionCode: string) => {
  if (personContact.length) {
    const contactData = personContact.filter((contact: any) => {
      return contact?.personContactType?.code === actionCode;
    });
    if (contactData.length) {
      const isVerified = contactData.filter((contData: any) => {
        return contData?.isVerified === true;
      });
      if (isVerified.length) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
};

export const isSecondaryContactVerified = (
  personContact: any,
  actionCode: string
) => {
  if (personContact.length) {
    const contactData = personContact.filter((contact: any) => {
      return contact?.personContactType?.code === actionCode;
    });
    if (contactData.length) {
      const isVerified = contactData.filter((contData: any) => {
        return contData?.rank === 2 && contData?.isVerified === true;
      });
      if (isVerified.length) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
};

export const getUserEmployerId = () => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  return userData.employerId || '';
};

export function extractAccountLocationsFromUserPracticeLocations(
  userPracticeLocations: IUserPracticeLocation[]
): IAccountLocation[] {
  return (userPracticeLocations || []).reduce(
    (accountLocations, userPracticeLocation) => {
      if (userPracticeLocation?.accountLocation?.uuid) {
        accountLocations.push(userPracticeLocation?.accountLocation);
      }
      return accountLocations;
    },
    [] as IAccountLocation[]
  );
}

export const isEmployerRole = (): boolean => {
  const commonData = useContext(CommonDataContext);
  const currentUserRoles = commonData.currentUserRoles;
  const roles = currentUserRoles.map((userRole: any) => {
    return userRole.code;
  });
  return roles.includes('EMPLOYER') || false;
};

export const getGenderTextByGenderCode = (contactData: any) => {
  let genderValue =
    contactData?.person?.sexAtBirth?.value ||
    contactData?.person?.gender?.value ||
    '';
  if (isBirthSexPrimary('')) {
    genderValue =
      contactData?.person?.sexAtBirth?.value ||
      contactData?.person?.gender?.value ||
      '';
  }
  return getGenderFullForm(genderValue)
};

export const getGenderFullForm = (genderValue: string) => {
  if (genderValue) {
    if (genderValue.toLowerCase().includes('female')) {
      return 'Female';
    } else if (genderValue.toLowerCase().includes('male')) {
      return 'Male';
    } else if (genderValue.toLowerCase().includes('other')) {
      return 'Other';
    } else {
      return genderValue;
    }
  } else {
    return '';
  }
}

const onFocus = () => {

};

const onBlur = () => {

};

export const WindowFocusHandler = () => {
  window.addEventListener('focus', onFocus);
  window.addEventListener('blur', onBlur);
  // Calls onFocus when the window first loads
  onFocus();
  // Specify how to clean up after this effect:
  return () => {
    window.removeEventListener('focus', onFocus);
    window.removeEventListener('blur', onBlur);
  };
};
export const getRandomNumberBetweenOneAndMax = (max: number) => {
  const rndInt = Math.floor(Math.random() * max) + 1;
  return rndInt;
};

export const getUserActionObjectFromActionCode = (
  code: string
): UserActionMasterData => {
  const commonData = useContext(CommonDataContext);
  const userActions =
    commonData.userActionMasterData || ([] as UserActionMasterData[]);
  const result = userActions.find((item) => item.actionCode == code);
  return result || ({} as UserActionMasterData);
};

export const getUserActionByLevel = (level: string): UserActionMasterData[] => {
  const commonData = useContext(CommonDataContext);
  const userActions = commonData.userActionMasterData || [];
  const result = userActions.filter((item) => item.level == level);
  return result;
};

export const isCurrentMenuLast = (index: number, array: any[]): boolean => {
  return index < array.length - 1;
};

export const getSecondaryValue = (personContact: any, actionCode: string) => {
  let value = '';
  if (personContact?.length) {
    personContact.map((personSecData: any) => {
      if (
        personSecData?.rank === 2 &&
        personSecData?.personContactType?.code === actionCode
      ) {
        value = personSecData?.value || '';
      }
    });
  }
  return value;
};

export const isValidJsonString = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return false;
  }
};

export const getFormattedToNumber = (number: string) => {
  if (number && number.includes('+')) {
    return number;
  }
  return `+1${number}`;
};

export const getInboxTypeCode = (typeCode: string) => {
  if (typeCode === 'Channel::TwilioSms') {
    return CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS;
  } else if (typeCode === 'Channel::WebWidget') {
    return CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET;
  } else if (typeCode === 'Channel::Email') {
    return CHANNEL_TYPE_CODE.CHANNEL_EMAIL;
  } else if (typeCode === 'Channel::Whatsapp') {
    return CHANNEL_TYPE_CODE.CHANNEL_WHATSAPP;
  } else if (typeCode === 'Channel::EFax') {
    return CHANNEL_TYPE_CODE.CHANNEL_E_FAX;
  } else return CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET;
};


export const getSectionUpdateError = (heading: string, updateType: string, toastType: string, hideOrShow: boolean) => {
  if (updateType === 'SEQUENCE') {
    if (toastType === 'SUCCESS') {
      return `${heading} position changed.`
    } else if (toastType === 'ERROR') {
      return `Error In changing position of ${heading}.`
    }
  } else if (updateType === 'VISIBLE') {
    if (toastType === 'SUCCESS') {
      return `${hideOrShow ? 'Show' : 'Hide'} ${heading} Section.`
    } else if (toastType === 'ERROR') {
      return `Error to ${hideOrShow ? 'Show' : 'Hide'}  ${heading} Section.`
    }
  }
}

export const isSettingScreen = (): boolean => {
  const result = window?.location?.hash?.includes('admin');
  return result;
};

export const getTenantForDemo = () => {
  // if (window.location.host.includes('localhost')) {
  if (window.location.host.split('.')?.[0]?.includes('demo') && window.location.host.split('.')?.[1] === 'sandbox') {
    return true;
  }
  return false;
}

export const isWidthBelowThreshold = (width: number) => {
  return width < THRESHOLD;
};
export const getOnFocusButtonStyles = (args?: {
  backgroundColor?: string;
  borderColor?: string;
}) => {
  return {
    backgroundColor: args?.backgroundColor || 'white',
    borderColor: args?.borderColor || 'black',
  };
};

export const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value);
};

export const isContactConsentGiven = (contactData: any) => {
  // done this change intentionally on prod for palmetto consent warning issue
  // time: 1st March 2023
  return (
    contactData?.contactConsents?.length &&
    contactData?.contactConsents?.length > 0
  );
}

export const isCallConsentGiven = (contactConsent: {consentId: string}[], callConsentId: string) => {
  return contactConsent.some((consent) => consent?.consentId === callConsentId);
}

export const isPhysicianRole = (): boolean => {
  const commonData = useContext(CommonDataContext);
  const currentUserRoles = commonData.currentUserRoles;
  const roles = currentUserRoles.map((userRole: any) => {
    return userRole.code;
  });
  return roles.includes('PHYSICIAN') || false;
};

export const getFeatureFlag = (code: string, commonData?: ICommonData, accountConfigData?: any) => {
  let configObj;
  if (commonData?.accountConfigData) {
    configObj = commonData?.accountConfigData?.[code];
  } else {
    accountConfigData?.forEach((singleConfig: any) => {
      if (singleConfig.code === code) {
        configObj = singleConfig;
      }
    });
  }
  if (configObj?.accountConfigurations?.length) {
    if (configObj?.accountConfigurations?.[0].value == 'ON') {
      return true;
    } else {
      return false;
    }
  } else {
    if (configObj?.defaultValue == 'ON') {
      return true;
    } else {
      return false;
    }
  }
};

export const isBrowserIncompatibleForPrint = () => {
  const currentBrowser = browserInfo.browserName;

  if (currentBrowser == BROWSER_TYPE.SAFARI) {
    if (browserInfo.compareBrowserVersion('<', '16.0')) {
      return true;
    }
  }

  return false;
}

export const getEHRCapability = (ehrCapabilities: IEhrCapability[], capabilityList: string[]): IEhrCapability | undefined => {
  if (ehrCapabilities?.length) {
    const capabilities: IEhrCapability[] = ehrCapabilities;
    const matchingCapability = capabilities.find((item) => item.resourceName && capabilityList.includes(item.resourceName));
    if (matchingCapability) {
      return matchingCapability;
    }
  }
};

export const isVirtualLocationDisabled = (userSettings: IUserSettingsByCode, ehrCapabilities: IEhrCapability[]) => {
  if (userSettings?.['disallow_virtual_location']?.value === 'True') {
    return true;
  }
  return false;
}

export const isAppointmentTypesGroupEnable = (userSettings: IUserSettingsByCode) => {
  if (userSettings?.['group_session_appointment_enabled']?.value === 'True') {
    return true;
  }
  return false;
}

export const isInsuranceEnable = (userSettings: IUserSettingsByCode) => {
  if (userSettings?.['patient_insurance_enabled']?.value === 'True') {
    return true;
  }
  return false;
}

export const isFormAnalyticsEnabled = (userSettings: IUserSettingsByCode) => {
  return userSettings?.['form_analytics_enabled']?.value === 'True' || false;
}

export const isContractsEnabled = (userSettings: IUserSettingsByCode) => {
  return userSettings?.['contract_enabled']?.value === 'True' || false ;
}

export const isCloneJourneyEnabled = (userSettings: IUserSettingsByCode) => {
  return userSettings?.['clone_journey_action_enabled']?.value === 'True' || false;
}

export const getDefaultFormIdForClinicalNote = (userSettings: IUserSettingsByCode) => {
  return userSettings?.['default_message_note_code']?.value || 'phone';
};

export const canChangeAppointmentDuration = (ehrCapabilities: IEhrCapability[]) => {
  return getEHRCapability(ehrCapabilities, [CapabilityResource.appointment])?.abilities?.canDurationChange !== false;
};

export const isPopGroupActivityLogEnable = (userSettings: IUserSettingsByCode) => {
  if (userSettings?.['enabled_pop_group_activity_log']?.value === 'True') {
    return true;
  }
  return false;
}

export const isLicensedStatePatientMatchingEnabled = (userSettings: IUserSettingsByCode) => {
  return userSettings?.['licensed_state_patient_matching_enabled']?.value === 'True' || false;
};

export const getLicensedStateUserRoles = (userSettings: IUserSettingsByCode): string[] => {
  try {
    const config = userSettings?.['licensed_state_user_roles']?.value;
    if (config && typeof config === 'string') {
      const userRoles = JSON.parse(config);
      if (Array.isArray(userRoles) && userRoles.length) {
        return userRoles;
      }
    }
    return [USER_ROLE_CODES.PHYSICIAN, USER_ROLE_CODES.NURSE_PRACTITIONER, USER_ROLE_CODES.PHYASST];
  } catch (error) {

    return [USER_ROLE_CODES.PHYSICIAN, USER_ROLE_CODES.NURSE_PRACTITIONER, USER_ROLE_CODES.PHYASST];
  }
};

export const getContactAddressDetails = (contactData: any) => {
  let displayAddress: string[] = [];
  const line1 =
    contactData?.personAddress?.length > 0
      ? contactData?.personAddress[0]?.line1
      : '';
  if (line1) displayAddress.push(line1);

  const line2 =
    contactData?.personAddress?.length > 0
      ? contactData?.personAddress[0]?.line2
      : '';
  if (line2) displayAddress.push(line2);

  const city =
    contactData?.personAddress?.length > 0
      ? contactData?.personAddress[0]?.cities?.name
      : '';
  if (city) displayAddress.push(city);

  if (displayAddress.length) {
    displayAddress = [displayAddress.join(', ')];
  }

  const state =
    contactData?.personAddress?.length > 0
      ? contactData?.personAddress[0]?.states?.abbr
      : '';
  if (state) displayAddress.push(state);

  const zipCode =
    contactData?.personAddress?.length > 0
      ? contactData?.personAddress[0]?.zipcodes?.code
      : '';
  if (zipCode) displayAddress.push(zipCode);

  return displayAddress.join(', ');
};

export const generateUUID = () => {
  return uuidV4();
}

export const isCallMessageType = (args: {type: number}) => {
  const {type} = args;
  return CALL_MESSAGE_TYPE_NUM.includes(type);
};

export const isContactConsentRequired = () => {
  const configs = getConfigDataFromCode(CONFIG_CODES.CONSENT_REQUIRED);
  const result = configs.accountConfigurations?.find(
    (item) => item.value == ON_OFF_CONFIG_VALUE.ON
  );
  if (configs?.accountConfigurations && configs?.accountConfigurations?.length) {
    return !!result
  } else {
    return configs?.defaultValue == ON_OFF_CONFIG_VALUE.ON;
  }
};

export const isEnableMarriageAnniversaryDate = () => {
  const configs = getConfigDataFromCode(CONFIG_CODES.ENABLE_MARRIAGE_ANNIVERSARY_DATE);
  const result = configs.accountConfigurations?.filter(
    (item) => item.value == ON_OFF_CONFIG_VALUE.ON
  );
  if (configs?.accountConfigurations && configs?.accountConfigurations?.length) {
    return !!result
  } else {
    return configs?.defaultValue == ON_OFF_CONFIG_VALUE.ON;
  }
};

export const isMentionedMessage = (text: string) => {
  return text.includes('mention://');
}
export const shouldCreateInCanvasForPatient = () => {
  const isCreateInCanvas =
    getResourceAbilities(FHIR_RESOURCE.PATIENT, '', '')
      ?.createPatientUsingIntegration || false;
  return isCreateInCanvas;
}

export const getUserRoleExistByRoleCode = (
  userRoles: IMlov[],
  roleCode: string
): boolean => {
  const roles = userRoles?.map((userRole: any) => {
    return userRole?.code || '';
  });
  return roles?.includes(roleCode) || false;
};

export const userLogout = () => {
  const axios = BaseService.getSharedInstance().axios;
  axios.post('crm-nest/logout').then((res) => {
    LocalStorage.removeItem('consent_Obj');
    LocalStorage.removeItem('isWorkflowLogin');
    LocalStorage.removeItem('loginDone');
    LocalStorage.removeItem('user').then(() => {
      const redirectString = '/#/home';
      window.location.href = window.location.origin + redirectString;
      window.location.reload();
    });
  });
}
export const getChannelType = (inboxTypeFromConversation: string) => {
  switch (inboxTypeFromConversation) {
    case CHANNEL_TYPE.CHANNEL_TWILIO_SMS:
      return 'SMS'
    case CHANNEL_TYPE.CHANNEL_EMAIL:
      return 'EMAIL'
    default:
      return 'CHAT'
  }
}

export const shouldUpdateApp = (args: {
  currentVersion: string;
  requiredVersion: string;
}) => {
  const {currentVersion, requiredVersion} = args;
  if (!currentVersion || !requiredVersion) {
    return false;
  }
  const v1Segments = currentVersion.split('.').map(Number);
  const v2Segments = requiredVersion.split('.').map(Number);
  for (let i = 0; i < Math.max(v1Segments.length, v2Segments.length); i++) {
    const v1Seg = v1Segments[i] || 0;
    const v2Seg = v2Segments[i] || 0;
    if (v1Seg < v2Seg) {
      return true;
    } else if (v1Seg > v2Seg) {
      return false;
    }
  }
  return false;
};
export const isConsentRequiredAndGiven = (contactData: any) => {
  const isGiven = isContactConsentGiven(contactData);
  const isRequired = isContactConsentRequired();
  if (!isRequired) {
    return true;
  }
  return isGiven && isRequired;
}

export const canCommunicateWithInactivePatient = (contactData: any) => {
  const isActive = isActiveContact(contactData);
  if (isCommunicateWithInactivePatient) {
    return true;
  } else {
    if (isActive) {
      return true;
    } else {
      return false;
    }
  }
};

export const isVirtualLocationEnabledInAvailability = (userSettings: IUserSettingsByCode | undefined) => {
  if (userSettings?.['is_allow_virtual_location_for_schedule_availability']?.value === 'True') {
    return true;
  }
  return false;
}
export const splitArrayIntoChunks = (array: any[], chunkSize: number) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

export const isLocalDevEnvironment = () => {
  return window.location.hostname === 'localhost';
}

export const onWindowConfirmExit = (e: BeforeUnloadEvent) => {
  if (isWeb() && !isLocalDevEnvironment()) {
    e.preventDefault();
    e.returnValue = 'Are you sure to close the tab?';
    return 'Are you sure to close the tab?';
  }
}

export const getMinAndMaxFromList = (dataList: any[]) => {
  if (dataList && dataList?.length) {
    const newDataList = [...dataList];
    const result = newDataList.reduce((firstObject: any, secondObj: any) => {
      const value = secondObj?.totalClaimCost;
      firstObject.min = Math.min(firstObject.min, value);
      firstObject.max = Math.max(firstObject.max, value);
      return firstObject;
    }, {min: Infinity, max: -Infinity});
    return result;
  }
  return {};
}

export const isLeadGroupsPage = () => {
  return window.location.href && window?.location?.href.includes('lead_groups');
}
export const removeSpacesFromText = (text: string) => {
  return (text || '').replace(/\s/g, '');
}

export const getEmployerReportFilterList = (sectionDataAppliedFilter: any[]): any[] => {
  const filterData: any[] = [];
  Object.entries(sectionDataAppliedFilter)?.forEach(([key, value]: any) => {
    const temp = {
      filterCode: key,
      filterValue: {
        value: value
      }
    }
    filterData.push(temp)
  });
  return filterData;
}

export const isHTMLString = (str: string) => {
  if (!str) {
    return false;
  }
  const isHTMLRegex = /<\/?[a-z][\s\S]*>/i;
  return isHTMLRegex.test(str);
}

export const objectToQueryString = (obj: any) =>
  Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');

export const getMemberSatisfactionFormsOnly = (formList: IMemberSatisfactionForm[]) => {
  const removeFormCodes = ['APPOINTMENT_FEEDBACK', 'NET_PROMOTER_SCORE'];
  let finalFormList = [] as IMemberSatisfactionForm[];
  if (formList?.length) {
    finalFormList = formList.filter((singleFormData) => {
      return !removeFormCodes?.includes(singleFormData?.code);
    });
  }
  return finalFormList;
}

export const showInfoOnMessageHeader = () => {
  const commonData = useContext(CommonDataContext);
  const infoConfig = commonData.accountConfigData?.ADDITIONAL_INFO_ON_MESSAGE?.accountConfigurations;
  if (infoConfig?.length) {
    const infoValue = infoConfig[0].value;
    const infoValueData = JSON.parse(infoValue);
    const infoOnMessage: IInfoOnMessageWindowHeader = {
      SHOW_PRONOUN_NAME: infoValueData.SHOW_PRONOUN_NAME,
      SHOW_CHOSEN_NAME: infoValueData.SHOW_CHOSEN_NAME,
      SHOW_PCP: infoValueData.SHOW_PCP,
      ADD_STICKY_NOTE: infoValueData.ADD_STICKY_NOTE,
    }
    return infoOnMessage;
  }
  return {} as IInfoOnMessageWindowHeader;
};

export const isSearchTextNumeric = (searchText: string) => {
  const cleanedText = searchText.replace(/[\(\)\-\s]/g, '');
  const regex = /^[0-9]+$/;
  if (regex.test(cleanedText)) {
    return true;
  }
  return false;
}

export const getUniqueObjectFromKey = (list: any[], compareKey: string) => {
  const uniqueKeys = new Set();
  return list.filter((singleListItem) => {
    if (!uniqueKeys.has(singleListItem[compareKey])) {
      uniqueKeys.add(singleListItem[compareKey]);
      return true;
    }
    return false;
  });
};

export const getFilterObjByCode = (list: any[], givenFilterCode: string) => {
  return list.find((singleListItem) => {
    return singleListItem?.filterCode === givenFilterCode;
  });
};


export const updateFilterValue = (list: any[], givenFilterCode: string, filterValue: any): any[] => {
  for (let index = 0; index < list.length; index++) {
    const singleFilterObj = list[index];
    if (singleFilterObj?.filterCode === givenFilterCode) {
      singleFilterObj.filterValue = {
        value: filterValue
      }
    }
  }
  return list;
};

export const removeFilterObjFromList = (filterList: any[], givenFilterCode: string): any[] => {
  return filterList.filter((singleListItem) => {
    return (singleListItem?.filterCode !== givenFilterCode);
  });
};

export const getAgentsList = () => {
  const commonData = useContext(CommonDataContext);
  return commonData?.agents || [];
};

export const isBlockNonSSOFlow = () => {
  const configs = getConfigDataFromCode(CONFIG_CODES.BLOCK_NON_SSO_FLOW);
  const result = configs.accountConfigurations?.find(
    (item) => item.value == ON_OFF_CONFIG_VALUE.ON
  );
  if (
    configs?.accountConfigurations &&
    configs?.accountConfigurations?.length
  ) {
    return !!result;
  } else {
    return configs?.defaultValue === ON_OFF_CONFIG_VALUE.ON;
  }
}

export const getRoles = (user: IUser) => {
  const roleNames = [
    ...new Set(
      user.userRoles?.map(
        (role) => role?.userRole?.roleName || role?.userRole?.userRole?.value
      ) || []
    ),
  ];
  return roleNames.filter(Boolean).join(' • ');
};

export const getUserRolesNameList = (userRoles: IUserRoleMap[]) => {
  return [
    ...new Set(
      userRoles?.filter(
        (role) =>
          role?.userRole?.roleName
      )?.map(
        (role: any) =>
          role?.userRole?.roleName
      )
    ),
  ];
};

export const isZoomEnabled = () => {
  const configs = getConfigDataFromCode(CONFIG_CODES.ENABLE_ZOOM);
  const result = configs.accountConfigurations?.find(
    (item) => item.value == ON_OFF_CONFIG_VALUE.ON
  );
  if (configs.accountConfigurations?.length) {
    return !!result;
  } else {
    return configs.defaultValue == ON_OFF_CONFIG_VALUE.ON;
  }
};

export const isJourneyPackagesEnabled = () => {
  const configs = getConfigDataFromCode(CONFIG_CODES.ENABLE_JOURNEY_PACKAGE);
  const result = configs.accountConfigurations?.find(
    (item) => item.value == ON_OFF_CONFIG_VALUE.ON
  );
  if (configs.accountConfigurations?.length) {
    return !!result;
  } else {
    return configs.defaultValue == ON_OFF_CONFIG_VALUE.ON;
  }
};

export const isOutlookEnabled = () => {
  const configs = getConfigDataFromCode(CONFIG_CODES.ENABLE_OUTLOOK);
  const result = configs.accountConfigurations?.find(
    (item) => item.value == ON_OFF_CONFIG_VALUE.ON
  );
  if (configs.accountConfigurations?.length) {
    return !!result;
  } else {
    return configs.defaultValue == ON_OFF_CONFIG_VALUE.ON;
  }
};

export const isDisplayOutlookTokenExpiryAlert = () => {
  const configs = getConfigDataFromCode(CONFIG_CODES.DISPLAY_TOKEN_EXPIRY_ALERT);
  const result = configs.accountConfigurations?.find(
    (item) => item.value == ON_OFF_CONFIG_VALUE.ON
  );
  if (configs.accountConfigurations?.length) {
    return !!result;
  } else {
    return configs.defaultValue == ON_OFF_CONFIG_VALUE.ON;
  }
};

export const stripEmojis = (str: string) => {
  if (!str) {
    return '';
  }
  return str.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
};

export const replaceWordInSentence = (
  text: string,
  fromWord: string,
  toWord: string
) => {
  return text.replace(fromWord, toWord);
};

export const notListedPronounId = (pronounList: IMlov[]) => {
  return pronounList?.find((pronoun)=> {
    return pronoun?.code === PRONOUN_CODE.not_listed
  })
}

export const replaceHashValueToString = (
  keyValue: Record<string, string>,
  blockMessage: string
) => {
  const keyValueObjectKeys = Object.keys(keyValue);
  let updatedMessage = blockMessage;
  keyValueObjectKeys.forEach((item) => {
    const pattern = new RegExp(`#${item}#`, 'g');
    updatedMessage = updatedMessage?.replace(pattern, keyValue[item]);
  });
  return updatedMessage;
};

export const markdownLinkToPlainText = (markdownText: string) => {
  if (markdownText?.length) {
    // Regex pattern to match Markdown-formatted links
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const plainText = markdownText.replace(regex, '$2');
    return plainText;
  }
  return markdownText;
};

export const getFileNameWithoutSpace = (fileName: string) => {
  return fileName.replace(/%20/g, " ")
}

export const isAvergentClient = (): boolean => {
  try {
    if (isWeb()) {
      const url = new URL(window?.location?.href);
      if (url.hostname?.includes('avergent')) {
        return true;
      }
    }
    return false;
  } catch(error) {
    return false;
  }
}

export const isPatientNoteMandatory = (userSettings: IUserSettingsByCode): boolean => {
  if (isAvergentClient()) {
    return true;
  }
  if (userSettings?.['patient_note_title_mandatory']?.value === 'True') {
    return true;
  }
  return false;
}

export const allowToCreateTaskForNote = (userSettings: IUserSettingsByCode): boolean => {
  if (isAvergentClient()) {
    return true;
  }
  if (userSettings?.['allow_to_create_task_of_note']?.value === 'True') {
    return true;
  }
  return false;
}

export const allowToShareForNoteWithPatient = (userSettings: IUserSettingsByCode): boolean => {
  if (isAvergentClient()) {
    return true;
  }
  if (userSettings?.['allow_to_share_patient_note']?.value === 'True') {
    return true;
  }
  return false;
}

export const isMultiTenancyEnabled = (userSettings: IUserSettingsByCode): boolean => {
  if (userSettings?.['is_multi_tenancy_enabled']?.value === 'True') {
    return true;
  }
  return false;
}

export const isEnableCareProgram = (userSettings: IUserSettingsByCode): boolean => {
  if (userSettings?.['enable_care_program']?.value === 'True') {
    return true;
  }
  return false;
}

export const isEnableRAFScore = (userSettings: IUserSettingsByCode): boolean => {
  if (userSettings?.['enabled_raf_score']?.value === 'True') {
    return true;
  }
  return false;
}

export const isSideCarEnabledForPatientDashboard = (userSettings: IUserSettingsByCode): boolean => {
  if (userSettings?.['enable_sidecar_patient_dashboard']?.value === 'True') {
    return true;
  }
  return false;
}

export const isAllowToShowExternalPatientId = (userSettings: IUserSettingsByCode): boolean => {
  if (userSettings?.['allow_to_show_external_patient_id']?.value === 'True') {
    return true;
  }
  return false;
}

export const getNoteConfigurationKey = (
  userSettings: IUserSettingsByCode
): string => {
  const configString = userSettings?.['note_configuration']?.value;
  if (configString && typeof configString === 'string') {
    const config = JSON.parse(configString);
    if (config?.setTitleFromKey) {
      return config?.setTitleFromKey;
    }
  }
  return '';
};

export const isNewCareTimeLineViewEnabled = (userSettings: IUserSettingsByCode): boolean => {
  if (userSettings?.['enable_new_care_timeline_view']?.value === 'True') {
    return true;
  }
  return false;
}

export const isWearableRuleEnabled = (userSettings: IUserSettingsByCode): boolean => {
  return userSettings?.['wearable_rule_enabled']?.value === 'True';
}

export const skipSecondaryUsersForCareTeamTypeAppointmentType = (userSettings: IUserSettingsByCode): boolean => {
  return userSettings?.['skip_secondary_users_for_care_team_type_appointment_type']?.value === 'True';
};

export const isWearableIntegrationEnabled = (userSettings: IUserSettingsByCode, contactId: string): boolean => {
  try {
    let wearableEnabledForContact = false;
    const config = userSettings?.['wearable_configuration']?.value;
    if (config && typeof config === 'string') {
      const wearableConfig = JSON.parse(config);
      if (wearableConfig?.enabledContactIds && Array.isArray(wearableConfig?.enabledContactIds)) {
        wearableEnabledForContact = wearableConfig.enabledContactIds.includes(contactId);
      }
    }
    return userSettings?.['wearable_integration_enabled']?.value === 'True' || wearableEnabledForContact;
  } catch (error) {

    return false;
  }
}

export const isSummaryComponentVisible = (accountUUID: string, userId: number | string) => {
  const LLM_CONFIG = getAccountConfigValue(CONFIG_CODES.LLM_CONFIG)
  if(LLM_CONFIG){
    const llmConfig = JSON.parse(LLM_CONFIG)
    return llmConfig?.allowedAccountUUIDList?.includes(accountUUID)  && (llmConfig?.summaryWidgetAllowedUserIds?.includes(userId) || llmConfig?.allWidgetsAllowedUserIds?.includes(userId))
  }
  return false;
};

export const isCarePlanRecommendationComponentVisible = (accountUUID: string, userId: number | string) => {
  const LLM_CONFIG = getAccountConfigValue(CONFIG_CODES.LLM_CONFIG)
  if(LLM_CONFIG){
    const llmConfig = JSON.parse(LLM_CONFIG)
    return llmConfig?.allowedAccountUUIDList?.includes(accountUUID)  && (llmConfig?.claimsPredictionWidgetAllowedUserIds?.includes(userId) || llmConfig?.allWidgetsAllowedUserIds?.includes(userId))
  }
  return false;
};

export const isHealthMapComponentVisible = (accountUUID: string, userId: number | string) => {
  const LLM_CONFIG = getAccountConfigValue(CONFIG_CODES.LLM_CONFIG)
  if(LLM_CONFIG){
    const llmConfig = JSON.parse(LLM_CONFIG)
    return llmConfig?.allowedAccountUUIDList?.includes(accountUUID)  && (llmConfig?.healthMapWidgetallowedUserIds?.includes(userId) || llmConfig?.allWidgetsAllowedUserIds?.includes(userId))
  }
  return false;
};

export const isLanguageModelComponentVisible = (accountUUID: string, userId: number | string) => {
  const LLM_CONFIG = getAccountConfigValue(CONFIG_CODES.LLM_CONFIG)
  if(LLM_CONFIG){
    const llmConfig = JSON.parse(LLM_CONFIG)
    return llmConfig?.allowedAccountUUIDList?.includes(accountUUID)  && (llmConfig?.healthMapWidgetallowedUserIds?.includes(userId) || llmConfig?.allWidgetsAllowedUserIds?.includes(userId) || llmConfig?.summaryWidgetAllowedUserIds?.includes(userId)) && llmConfig?.isLanguageModelComponentVisible == "true"
  }
  return false;
};

export const isDayOptimizerEnabled = () => {
  const config = getAccountConfigValue(CONFIG_CODES.DAY_OPTIMIZER_ENABLED);

  if (config == ON_OFF_CONFIG_VALUE.ON) {
    if(checkLoggedInUserRolesAccess(ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER])) {
      return true;
    }
  }
  return false;
}

export const healthMapName = () => {
  const LLM_CONFIG = getAccountConfigValue(CONFIG_CODES.LLM_CONFIG);
  if (LLM_CONFIG) {
    const llmConfig = JSON.parse(LLM_CONFIG);
    return llmConfig?.widgetNames?.healthMapName
      ? llmConfig?.widgetNames?.healthMapName
      : '';
  }
  return '';
};

export const isAlertComponentVisible = (accountUUID: string, userId: number | string) => {
  const LLM_CONFIG = getAccountConfigValue(CONFIG_CODES.LLM_CONFIG)
  if(LLM_CONFIG){
    const llmConfig = JSON.parse(LLM_CONFIG)
    return llmConfig?.allowedAccountUUIDList?.includes(accountUUID)  && (llmConfig?.alertWidgetallowedUserIds?.includes(userId) || llmConfig?.allWidgetsAllowedUserIds?.includes(userId))
  }
  return false;
};

export const isDiagnosesComponentVisible = (accountUUID: string, userId: number | string) => {
  const LLM_CONFIG = getAccountConfigValue(CONFIG_CODES.LLM_CONFIG)
  if(LLM_CONFIG){
    const llmConfig = JSON.parse(LLM_CONFIG)
    return llmConfig?.allowedAccountUUIDList?.includes(accountUUID)  && (llmConfig?.diagnosysWidgetallowedUserIds?.includes(userId) || llmConfig?.allWidgetsAllowedUserIds?.includes(userId))
  }
  return false
};

export const getBulkTaskOperationConfigs = (userSettings: IUserSettingsByCode): {labelOperation: string[]; disallowLabels: string[];} => {
  const defaultValue = {
    labelOperation: ["FIND_REPLACE", "FIND_DELETE","ADD_EXISTING","DELETE_ALL","REPLACE_ALL"],
    disallowLabels: []
  }
  try {
    const bulkTaskOperation = userSettings?.['allow_bulk_task_operation']?.value;
    if (bulkTaskOperation && typeof bulkTaskOperation === 'string') {
      return JSON.parse(bulkTaskOperation);
    }
    return defaultValue;
  } catch {
    return defaultValue;
  }
}

export function getCodeFromUrl() {
  try {
    const url = new URL(window.location.href);
    const code = url.searchParams.get('code');
    return code;
  } catch (error) {
    return '';
  }
}

export const postMessageToParentWindow = (message: string, url: string) => {
  if (window.opener) {
    window.opener.postMessage({message, sender: 'FOLD'}, url);
  }
};

export const isEFaxChannelInboxType = (selectInboxTypeCode: string) => {
  return selectInboxTypeCode === CHANNEL_TYPE.CHANNEL_EFAX;
};

export const isValidEFaxContactInboxSourceIdFromString = (sourceId: string) => {
  return sourceId && !isNaN(sourceId as any);
};

export const codeToDisplayMapAthena: { [key: string]: string } = {
  'M': 'Male',
  'F': 'Female',
};

export const codeToDisplayMapElation: { [key: string]: string } = {
  'M': 'Male',
  'F': 'Female',
  'N': 'Neutral',
};

export const displayToCodeMapAthena: { [key: string]: string } = {
  'Male': 'M',
  'Female': 'F',
};

export const displayToCodeMapElation: { [key: string]: string } = {
  'Male': 'M',
  'Female': 'F',
  'Neutral': 'N',
};

export const codeToDisplayMapAthenaOptions: { code: string; display: string; }[] = [
  { code: 'M', display: 'Male' },
  { code: 'F', display: 'Female' },
];

export const codeToDisplayMapElationOptions: { code: string; display: string; }[] = [
  { code: 'M', display: 'Male' },
  { code: 'F', display: 'Female' },
  { code: 'N', display: 'Neutral' },
];

export const renderCopayFields = (copayDetails: ICopayDetail[]) => {
  return copayDetails.map((copayDetail, index) => ({
    valueMoney: {
      value: copayDetail.amount,
    },
    type: {
      text: copayDetail.type,
    },
  }));
};

export const validateInsuranceFields = (insuranceData: IInsuranceState | undefined, isAthena: boolean, secondaryInsuranceData:IInsuranceState | undefined, tertiaryInsuranceData: IInsuranceState | undefined ,isPrimaryInsuranceDeleted?: boolean, isSecondaryInsuranceDeleted?: boolean, isTertiaryInsuranceDeleted?: boolean, canPlanNameCreate?: boolean) => {
  const errors: IInsuranceMandatoryFieldAccordingToEHR = {};
  const secondaryErrors: IInsuranceMandatoryFieldAccordingToEHR = {};
  const tertiaryErrors:  IInsuranceMandatoryFieldAccordingToEHR = {};
  let error = ''
  if (!isPrimaryInsuranceDeleted || !isInsuranceStateEmpty(insuranceData)) {
    if (canPlanNameCreate && isAthena && insuranceData?.insuranceCarrierName !== insuranceData?.planName) {
      error = "Please fill 'Insurance Carrier Name' and 'Plan Name' with same value";
      return { error, errors }
    }
    if (isAthena && !insuranceData?.patientRealtionWithPolicyHolder) {
      errors.patientRealtionWithPolicyHolder = "Patient's Relationship to policyholder is required";
    }
    if (isAthena && !insuranceData?.sexAtBirth) {
      errors.sexAtBirth = 'Sex at birth is required'
    }
    if(!isAthena && !insuranceData?.groupID){
      errors.groupId = 'Group ID is required'
    }
    errors.memberId = !insuranceData?.memberID ? 'Member ID is required' : '';
    errors.firstName = !insuranceData?.firstName ? 'First name is required' : '';
    errors.lastName = !insuranceData?.lastName ? 'Last name is required' : '';
    errors.insuranceCarrierName = !insuranceData?.insuranceCarrierName ? 'Insurance Carrier Name is required' : '';
    errors.planName = !insuranceData?.planName ? 'Plan Name is required' : '';
    if (!insuranceData?.memberID ||
      !insuranceData.firstName ||
      !insuranceData.lastName ||
      (!isAthena && !insuranceData?.groupID) ||
      (isAthena && (!insuranceData.patientRealtionWithPolicyHolder || !insuranceData?.sexAtBirth))) {
      error = 'Please fill the mandatory details'
    }
  }
  if(insuranceData?.addSecondaryInsuranceOpen && (!isSecondaryInsuranceDeleted || !isInsuranceStateEmpty(secondaryInsuranceData))){
    if (canPlanNameCreate && isAthena && secondaryInsuranceData?.insuranceCarrierName !== secondaryInsuranceData?.planName) {
      error = "Please fill 'Insurance Carrier Name' and 'Plan Name' with same value for secondary insurance";
      return { error, secondaryErrors }
    }
    if (isAthena && !secondaryInsuranceData?.patientRealtionWithPolicyHolder) {
      secondaryErrors.patientRealtionWithPolicyHolder = "Patient's Relationship to policyholder is required for secondary insurance";
    }
    if (isAthena && !secondaryInsuranceData?.sexAtBirth) {
      secondaryErrors.sexAtBirth = 'Sex at birth is required for secondary insurance'
    }
    if(!isAthena && !secondaryInsuranceData?.groupID){
      secondaryErrors.groupId = 'Group ID is required for secondary insurance'
    }
    secondaryErrors.memberId = !secondaryInsuranceData?.memberID ? 'Member ID is required for secondary insurance' : '';
    secondaryErrors.firstName = !secondaryInsuranceData?.firstName ? 'First name is required for secondary insurance' : '';
    secondaryErrors.lastName = !secondaryInsuranceData?.lastName ? 'Last name is required for secondary insurance' : '';
    secondaryErrors.insuranceCarrierName = !secondaryInsuranceData?.insuranceCarrierName ? 'Insurance Carrier Name is required for secondary insurance' : '';
    secondaryErrors.planName = !secondaryInsuranceData?.planName ? 'Plan Name is required for secondary insurance' : '';
    if (!secondaryInsuranceData?.memberID ||
      !secondaryInsuranceData.firstName ||
      !secondaryInsuranceData.lastName ||
      (!isAthena && !secondaryInsuranceData?.groupID) ||
      (isAthena && (!secondaryInsuranceData.patientRealtionWithPolicyHolder || !secondaryInsuranceData?.sexAtBirth))) {
      error = 'Please fill the mandatory details'
    }
  }
  if(insuranceData?.addTertiaryInsuranceOpen && (!isTertiaryInsuranceDeleted || !isInsuranceStateEmpty(tertiaryInsuranceData))){
    if (canPlanNameCreate && isAthena && tertiaryInsuranceData?.insuranceCarrierName !== tertiaryInsuranceData?.planName) {
      error = "Please fill 'Insurance Carrier Name' and 'Plan Name' with same value for tertiary insurance";
      return { error, errors }
    }
    if (isAthena && !tertiaryInsuranceData?.patientRealtionWithPolicyHolder) {
      tertiaryErrors.patientRealtionWithPolicyHolder = "Patient's Relationship to policyholder is required for tertiary insurance";
    }
    if (isAthena && !tertiaryInsuranceData?.sexAtBirth) {
      tertiaryErrors.sexAtBirth = 'Sex at birth is required for tertiary insurance'
    }
    if(!isAthena && !tertiaryInsuranceData?.groupID){
      tertiaryErrors.groupId = 'Group ID is required for tertiary insurance'
    }
    tertiaryErrors.memberId = !tertiaryInsuranceData?.memberID ? 'Member ID is required for tertiary insurance' : '';
    tertiaryErrors.firstName = !tertiaryInsuranceData?.firstName ? 'First name is required for tertiary insurance' : '';
    tertiaryErrors.lastName = !tertiaryInsuranceData?.lastName ? 'Last name is required for tertiary insurance' : '';
    tertiaryErrors.insuranceCarrierName = !tertiaryInsuranceData?.insuranceCarrierName ? 'Insurance Carrier Name is required for tertiary insurance' : '';
    tertiaryErrors.planName = !tertiaryInsuranceData?.planName ? 'Plan Name is required for tertiary insurance' : '';
    if (!tertiaryInsuranceData?.memberID ||
      !tertiaryInsuranceData.firstName ||
      !tertiaryInsuranceData.lastName ||
      (!isAthena && !tertiaryInsuranceData?.groupID) ||
      (isAthena && (!tertiaryInsuranceData.patientRealtionWithPolicyHolder || !tertiaryInsuranceData?.sexAtBirth))) {
      error = 'Please fill the mandatory details'
    }
  }
  return { error, errors, secondaryErrors, tertiaryErrors };
}


export const isRejected = (
  input: PromiseSettledResult<unknown>
): input is PromiseRejectedResult => input.status === 'rejected';

export const isFulfilled = <T>(
  input: PromiseSettledResult<T>
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled';

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const getUserUUIDFromStorage = async () => {
  const userDataString = await LocalStorage.getItem('user');
  const userData = JSON.parse(userDataString);
  if (userData?.data?.uuid) {
    return userData?.data?.uuid;
  }
  return '';
};
export const hideGenderFieldsForPrintPreview = (userSettings: IUserSettingsByCode): boolean => {
  if (userSettings?.['hide_gender_fields_for_print_preview']?.value === 'True') {
    return true;
  }
  return false;
}
export const isConfigEnable = (configObj: any) => {
  if (configObj?.accountConfigurations?.length) {
    return (
      configObj?.accountConfigurations[0]?.value === ON_OFF_CONFIG_VALUE.ON
    );
  }
  return configObj?.defaultValue == ON_OFF_CONFIG_VALUE.ON;
}
export const isTollFreeNumber = (phoneNumber: string) => {
  if (!(phoneNumber && typeof phoneNumber === "string")) {
    return;
  }
  return TOLL_FREE_NUMBERS_PREFIX.some((item) =>
    phoneNumber?.startsWith(item.toString())
  );
};

export const canCreateSMSInboxWithPhoneNumber = (phoneNumber: string) => {
  return !isTollFreeNumber(phoneNumber);
};

export const filteredFormsCategoryList = (list: ITemplateCategory[]): ITemplateCategory[] => {
  return list.filter((item: ITemplateCategory) => {
    return item.code !== CATEGORY_CODE.PATIENT_FORM;
  });
};


export const VALIDATE_EMAIL_REGEX = /^[\w+.-]+(\.[\w+.-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;


export const reArrangeOptionNodeNode = (nodes: any, edges: any) => {

  let optionNodeList = nodes
    .filter((node: any) => {
      return node?.data?.nodeType == 'AllCondition';
    })
    .filter((node: any) => {
      return Object.keys(node?.data?.metaData?.optionMap || {})?.length;
    });

  const optionNodeIdList: any = [];

  const processedIds: any = [];
  const optionNodeIdMapPosition = {}
  while (optionNodeList?.length) {
    const optionFinalList = [];
    let optionEdges: any = [];
    const currentProcess: any = optionNodeList[0];
    processedIds.push(currentProcess.id);
    [currentProcess].forEach((optionNode: any) => {
      let allNodesConnectedToCurrentOption: any = [];


      let sortIndex=nodes?.length -1;
      nodes.some((node:any, index:number)=>{
        if(node.id == optionNode.id){
          sortIndex = index;
          return true;
        }
      })
      Object.keys(optionNode?.data?.metaData?.optionMap || {}).forEach(
        (key) => {
          const option = optionNode?.data?.metaData?.optionMap[key];
          optionEdges = [];
          allNodesConnectedToCurrentOption = [];
          edges = edges.filter((edge: any) => {
            if (
              edge?.source == optionNode?.id &&
              option?.value == edge.sourceHandle
            ) {
              nodes = nodes.filter((nodesWithoutOptionNode: any) => {
                if (nodesWithoutOptionNode?.id == edge.target) {
                  allNodesConnectedToCurrentOption.push(nodesWithoutOptionNode);
                  return false;
                }
                return true;
              });
              optionEdges.push(edge);
              return false;
            }
            return true;
          });

          while (optionEdges?.length > 0) {

            const newOptionEdges: any = [];
            optionEdges.forEach((edge1: any) => {
              edges = edges.filter((edge2: any) => {
                if (edge1?.target == edge2.source) {
                  newOptionEdges.push(edge2);
                  nodes = nodes.filter((node: any) => {
                    if (node?.id == edge2?.target) {
                      allNodesConnectedToCurrentOption.push(node);
                      return false;
                    }
                    return true;
                  });
                  return false;
                }
                return true;
              });
              edges.push(edge1);
              optionEdges = newOptionEdges;
            });
          }

          const newNodeList:any = [];
          const newNodeListIds: any = [];
          let isAdded = false;
          nodes.forEach((node:any, index:number)=>{
            if(!newNodeListIds.includes(node.id)){
              newNodeList.push(node)
              newNodeListIds.push(node.id)
            }

            if(sortIndex == index){
              isAdded = true;
              allNodesConnectedToCurrentOption.forEach((node2: any) => {
                node2.position = {x: 0, y: 0};
                node2.positionAbsolute = {x: 0, y: 0};
                if(!newNodeListIds.includes(node2.id)){
                  newNodeList.push(node2)
                  newNodeListIds.push(node2.id)
                  sortIndex = sortIndex + 1;
                }

              });
            }
          });
          if(!isAdded){
            allNodesConnectedToCurrentOption.forEach((node2: any) => {
              node2.position = {x: 0, y: 0};
              node2.positionAbsolute = {x: 0, y: 0};
              if(!newNodeListIds.includes(node2.id)){
                newNodeList.push(node2)
                newNodeListIds.push(node2.id)
                sortIndex = sortIndex + 1;
              }

            });
          }
          nodes = newNodeList;


        }
      );
    });
    const edgeMap: any = {};
    edges = edges.filter((edge: any) => {
      if (!edgeMap[edge.id]) {
        edgeMap[edge.id] = edge.id;
        return true;
      }
      return false;
    });
    optionNodeList = nodes.filter((node: any) => {
        return (
          node?.data?.nodeType == 'AllCondition' && Object.keys(node?.data?.metaData?.optionMap || {})?.length && node.id &&
          !processedIds.includes(node.id)
        );
      });

  }
  return {nodes: nodes, edges: edges};
};

export const isFoldAccessTokenExpired = (token: string) => {
  const expiry = getFoldAccessTokenExpiry(token);
  const currentTime = new Date().getTime() / 1000;
  if (!expiry || currentTime >= expiry) {
    return true;
  }
  return false;
};

export const getFoldAccessTokenExpiry = (token: string) => {
  if (!token) {
    return undefined;
  }
  const decoded = jwtDecode(token);
  const decodedObj = JSON.parse(JSON.stringify(decoded));

  if (decodedObj.exp) {
    return decodedObj.exp;
  }
  return undefined;
};


export const showHomeMonitoringInSideCar = () => {
  const context = useContext(CommonDataContext);
  const userSettings = context?.userSettings;
  const hideHomeMonitoringInSidecar = userSettings?.['hide_home_monitoring_in_sidecar']?.value === 'True';
  return !hideHomeMonitoringInSidecar;
}

export const showAlertsInSideCar = () => {
  const context = useContext(CommonDataContext);
  const userSettings = context?.userSettings;
  const showAlertsInSidecar = userSettings?.['show_alerts_in_sidecar']?.value === 'True';
  return showAlertsInSidecar;
}

export const showSummaryInSideCar = () => {
  const context = useContext(CommonDataContext);
  const userSettings = context?.userSettings;
  const hideSummaryInSidecar = userSettings?.['hide_summary_in_sidecar']?.value === 'True';
  return !hideSummaryInSidecar;
}

export const showAssessmentInSideCar = () => {
  const context = useContext(CommonDataContext);
  const userSettings = context?.userSettings;
  const hideAssessmentInSidecar = userSettings?.['hide_assessment_in_sidecar']?.value === 'True';
  return !hideAssessmentInSidecar;
}

export const showCareJourneyInSideCar = () => {
  const context = useContext(CommonDataContext);
  const userSettings = context?.userSettings;
  const hideCareJourneyInSidecar = userSettings?.['hide_care_journey_in_sidecar']?.value === 'True';
  return !hideCareJourneyInSidecar;
}

export const isAllowedToRefreshOnMemoryExceed = (userSettings: IUserSettingsByCode) => {
  return userSettings?.['memory_exceed_refresh_enabled']?.value === 'True';
}

export const getDocumentReferenceSortKey = (): boolean => {
  const commonContextData = useContext(CommonDataContext);
  const userSettings = commonContextData.userSettings;
  return userSettings['document_reference_sort_key']?.value === 'True' || false;
};

/**
 * Sanitizes a given test string to prevent malicious.
 *
 * @param {string | null} inputString - The inputString string to be sanitized.
 * @returns {string} - The sanitized inputString or '' if invalid.
 */
export const getSanitizeString = (inputString: string): string => {
  if (!inputString) {
    return '';
  }
  // Remove potentially dangerous characters and trim the string
  return inputString.replace(/[\u0000-\u0009\u000B-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim, "").trim();
}


export const getEHRDisplayNameByCode = (code: string) => {
  switch (code) {
    case EHRName.ELATION:
      return 'Elation';
    case EHRName.FOLD:
      return 'Fold';
    case EHRName.ATHENA:
      return 'Athena';
    case EHRName.CANVAS:
      return 'Canvas';
    case EHRName.CERNER:
      return 'Cerner';
    default:
      return '';
  }
}
export const createCodeByName = (name: string) => {
  let code = '';
  if (name) {
    code = name.toUpperCase().replace(/\s+/g, '_');
    return code;
  }
  return code;
};

export const getPatientLocationUuidFromPracticeLocations = (
  contactPracticeLocations: IContactPracticeLocations[]
) => {
  return contactPracticeLocations?.[0]?.accountLocation?.uuid;
};

export const getUserPracticeLocationUuids = (): string[] => {
  const commonData = useContext(CommonDataContext);
  const locationData = commonData?.userPracticeLocations || [];
  const locationUuids =
    locationData?.map((item: any) => {
      if (item?.accountLocations[0]?.practiceLocation?.uuid) {
        return item?.accountLocations[0]?.practiceLocation?.uuid;
      }
    }) || [];
  return locationUuids;
};

export const getAllowedUserPracticeLocationUuids = (
  userAccessPermissionCode: string,
  mainMenuCode: string,
  commonDataObject?: ICommonData,
): string[] => {
  const commonData = commonDataObject ?? useContext(CommonDataContext);
  const isAllowedAllLocation =
    isLoginUserBusinessOwner(commonData) ||
    isLoggedInUserWorkFlowOrCustomerSuccess(commonData);
  if (isAllowedAllLocation) {
    const accountPracticeLocationUuids =
      commonData.accountLocationListWithEHR?.map(
        (location) => location.practiceLocation.uuid
      ) || [];
    return accountPracticeLocationUuids;
  } else {
    const {check} = usePermissions(commonData);
    const permissionConfig = check(userAccessPermissionCode, mainMenuCode);
    const currentUserAllowedLocations =
      permissionConfig?.allowedLocationIds || [];
    const locationData = commonData?.userPracticeLocations;
    const allowedUserPracticeLocationUuids: string[] = [];
    locationData?.forEach((location) => {
      if (
        currentUserAllowedLocations?.includes(
          location?.accountLocations?.[0]?.uuid
        )
      ) {
        allowedUserPracticeLocationUuids.push(
          location?.accountLocations[0]?.practiceLocation?.uuid
        );
      }
    });
    return allowedUserPracticeLocationUuids;
  }
};

export const getUserAccountLocationUuids = () => {
  const commonData = useContext(CommonDataContext);
  const locationData = commonData?.userPracticeLocations || [];
  const locationUuids =
    locationData?.map((item: any) => {
      return item?.accountLocations?.[0]?.uuid;
    }) || [];
  return locationUuids;
};

export const getAllowedUserAccountLocationUuids = (
  userAccessPermissionCode: string,
  mainMenuCode: string
): string[] => {
  const {check} = usePermissions();
  const permissionConfig = check(userAccessPermissionCode, mainMenuCode);
  const currentUserAllowedLocations =
    permissionConfig?.allowedLocationIds || [];
  return currentUserAllowedLocations;
};

export const getAllowedUserPracticeLocationUuidsAndName = (
  userAccessPermissionCode: string,
  mainMenuCode: string
): IAllowedUserPracticeLocationUuidsAndName[] => {
  const commonData = useContext(CommonDataContext);
  const allowedUserPracticeLocationUuidsAndName: IAllowedUserPracticeLocationUuidsAndName[] =
    [];
  const isAllowedAllLocation =
    isLoginUserBusinessOwner() ||
    isLoggedInUserWorkFlowOrCustomerSuccess();
  if (isAllowedAllLocation) {
      commonData?.accountLocationListWithEHR?.forEach((location) =>
        allowedUserPracticeLocationUuidsAndName.push({
          practiceLocationId: location.practiceLocation.uuid,
          practiceLocationName: location.practiceLocation.name,
          uuid: location?.uuid,
        })
      ) || [];
    return allowedUserPracticeLocationUuidsAndName;
  } else {
    const {check} = usePermissions();
    const permissionConfig = check(userAccessPermissionCode, mainMenuCode);
    const currentUserAllowedLocations =
      permissionConfig?.allowedLocationIds || [];
    const locationData = commonData?.userPracticeLocations;
    locationData?.forEach((location) => {
      if (
        currentUserAllowedLocations?.includes(
          location?.accountLocations?.[0]?.uuid
        )
      ) {
        allowedUserPracticeLocationUuidsAndName.push({
          practiceLocationId:
            location?.accountLocations[0]?.practiceLocation?.uuid,
          practiceLocationName:
            location?.accountLocations[0]?.practiceLocation?.name,
          uuid: location?.accountLocations[0]?.uuid,
        });
      }
    });
  }
  return allowedUserPracticeLocationUuidsAndName;
};

export const getUserPracticeLocations = async (): Promise<string[]> => {
  let userLocationsIds: string[] = [];
  try {
    const result = await LocalStorage.getItem('userPracticeLocations');
    if (result) {
      const userPracticeLocations = JSON.parse(result);
      userLocationsIds = userPracticeLocations?.map((value: any) => {
        return value?.accountLocations?.[0]?.uuid;
      }
      );
    }
  } catch (error) {
    userLocationsIds = [];
  } finally {
    return userLocationsIds;
  }
};

export const getLocationNames = (foldLocations: ILocation[] = [], locationIds?: string[]) => {
  return foldLocations?.filter(loc => locationIds?.includes(loc?.uuid));
};

export const getFoldLocations = async () => {
  let foldLocations = []
  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;

  const url = 'api/FoldLocations';
  try {
    const resp = await integrationService.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (resp?.data) {
      foldLocations = resp?.data?.accountLocations?.map((location: { uuid: string; practiceLocation: { name: string; }; }) => ({
        uuid: location?.uuid,
        name: location?.practiceLocation?.name
      }));
    }
  } catch (e) {
    console.log(e);
    return [];
  }
  return foldLocations
};

export const EHR_CODE_TO_NAME_MAP = {
  FHIR_STORE: 'FHIR store',
  ELATION_CALIBER: 'Elation',
  ATHENA_CALIBER: 'Athena',
  CERNER: 'Cerner',
  CANVAS: 'Canvas'
};

type EhrCode = keyof typeof EHR_CODE_TO_NAME_MAP;

export const getEhrDisplayNameByCode = (ehrCode: EhrCode): string => {
  return EHR_CODE_TO_NAME_MAP[ehrCode] || 'Unknown EHR code';
};

export const getEhrDisplayNameByCodes = (ehrCode?: string) => {
  switch(ehrCode){
    case 'FHIR_STORE':
      return 'FHIR Store'
    case 'ATHENA_CALIBER':
      return 'Athena'
    case 'ELATION_CALIBER':
      return 'Elation'
    case 'CERNER':
      return 'Cerner'
    case 'CANVAS':
      return 'Canvas'
    default:
      'Unknown EHR'
  }
}

const mapOldToModernTimeZone = (timeZone: string): string => {
  const timeZoneMap: { [key: string]: string } = {
    "Asia/Calcutta": "Asia/Kolkata",
    // Add more mappings if needed
  };
  return timeZoneMap[timeZone] || timeZone;
}

export const getMachineTimeZone = () : string => {
  const timeZone = moment.tz.guess();
  return mapOldToModernTimeZone(timeZone);
}

export const getEhrCapabilitiesWithLocationGroupId = (
  capabilityMap: Map<string, IEhrCapability[]>,
  locationGroupId?: string,
): IEhrCapability[] => {
  return capabilityMap.get(locationGroupId || '') || [];
};

export const accountLocationUuidListByUserPracticeLocationList = (
  userPracticeLocationList: IUserPracticeLocations[] | undefined
) => {
  const accountLocationList: string[] = []
  if (userPracticeLocationList) {
    userPracticeLocationList?.forEach((locationObject: IUserPracticeLocations) => {
      if (locationObject?.accountLocations &&
        locationObject?.accountLocations?.length &&
        locationObject?.accountLocations[0]?.uuid
      ) {
        accountLocationList.push(locationObject?.accountLocations[0]?.uuid)
      }
    })
  }
  return accountLocationList
}

export const getLocationGroupIdFromLocationId = (
  accountLocationListWithEHR: EHRWiseLocations[] | undefined,
  locationId: string | undefined
) => {
  let locationGroupId: string | undefined = undefined;
  if (
    locationId &&
    accountLocationListWithEHR &&
    accountLocationListWithEHR?.length > 0
  ) {
    locationGroupId = accountLocationListWithEHR?.find(
      (accountLocation: EHRWiseLocations) => {
        return accountLocation?.uuid === locationId;
      }
    )?.locationGroupId;
  }
  return locationGroupId;
};

export const getLocationListFromWorkflowLocations = (workflowLocations: { locationId: string }[]) => {
  const uuidList: string[] = []
  workflowLocations.forEach((location: any) => {
    uuidList.push(location?.locationId)
  })
  return uuidList
}

export const getPracticeLocationUuidList = (accountLocations: any) => {
  const uuidList: string[] = []
  accountLocations.forEach((location: any) => {
    if (location?.practiceLocation && location?.practiceLocation?.uuid) {
      uuidList.push(location?.practiceLocation?.uuid)
    }
  })
  return uuidList
}
/**
 * Show timeline source other than 'FOLD' based on `swap_timeline_source_fold_with_hie` flag
 *
 * @param userSettings
 * @returns {boolean} Whether to show source other than 'FOLD' or not. True/False
 */
export const swapTimelineSourceFoldWithHie = (userSettings: IUserSettingsByCode): boolean => {
  if (userSettings?.['swap_timeline_source_fold_with_hie']?.value === 'True') {
    return true;
  }
  return false;
}

export const getBooleanFeatureFlag = (userSettings: IUserSettingsByCode, featureFlag: FeatureFlags): boolean => {
  if (userSettings?.[featureFlag]?.value === 'True') {
    return true;
  }
  return false;
}

export const hasValue = (value: any): boolean => {
  return value !== undefined && value !== null && value !== '';
};

const sendMemoryToSentry = () => {
  const memoryUsed = getMemoryUsed();

  if (memoryUsed !== null) {
    Sentry.setMeasurement('memoryUsed', memoryUsed, 'byte');
  }
};

export const sendMemoryToSentryPeriodically = () => {
  sendMemoryToSentry();
  setInterval(sendMemoryToSentry, 900000); // 15 minutes in milliseconds
}

export const getEhrConfigObjectByLocations = () => {
  const CommonContext = useContext(CommonDataContext)
  const isBusinessOwner = isLoginUserBusinessOwner()
  const accountLocationListWithEHR = CommonContext?.accountLocationListWithEHR || [];
  const userAccountLocationUuids = CommonContext?.userAccountLocationUuids || [];
  const ehrFlagsObject: IAvailableEhr = { isAthena: false, isFold: false, isElation: false, isCanvas: false };
  let filterList: any[] = [];

  if (!isBusinessOwner) {
    filterList = accountLocationListWithEHR.filter(item =>
      userAccountLocationUuids.includes(item.uuid)
    );
  } else {
    filterList = accountLocationListWithEHR;
  }
  filterList.forEach(item => {
    const ehrCode = item.ehrInfo.ehrCode;
    if (ehrCode === EHRName.ATHENA) {
      ehrFlagsObject.isAthena = true;
    }
    if (ehrCode === EHRName.FOLD) {
      ehrFlagsObject.isFold = true;
    }
    if (ehrCode === EHRName.CANVAS) {
      ehrFlagsObject.isCanvas = true;
    }
    if (ehrCode === EHRName.ELATION) {
      ehrFlagsObject.isElation = true;
    }
  });
  return ehrFlagsObject
}

export const getEmailsFromCommaSeperatedString = (toText?: string) : string[] => {
  let emailsList: string[] = [];
  if(!toText){
    return emailsList
  }
  try {
    const list = toText?.split(",");
    const mappedList = list?.map((item) => {
      const emailRegx = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
      const match = item?.match?.(emailRegx);
      const toEmail = match ? match[0] : "";
      return toEmail || "";
    })
    emailsList = mappedList?.filter(item => item.length > 0);
  } catch {
  }
  return emailsList;
}

export const filterUsersDataUsingLocationGroupId = (
  accountUserList: any,
  locationGroupId: string | undefined
) => {
  // The accountUserList is typed as any because the Users API call interface may differ.
  // The accountUserList must contain:
  // - An externalUserMap object with external user IDs and location group IDs.
  // - An accountUsers array that includes external user IDs and externalUserId from the CRM User table.
  return accountUserList.map((user: any) => {
    const externalUserMap = user.externalUserMap || [];
    const externalUserMapId = locationGroupId
      ? externalUserMap.find(
          (item: any) => item.locationGroupId === locationGroupId
        )?.externalUserId
      : externalUserMap?.[0]?.externalUserId;

    // Use fallbacks to get externalUserId
    const externalUserId =
      externalUserMapId ||
      user?.accountUsers?.[0]?.externalUserId ||
      user?.externalUserId;
    return {
      ...user,
      externalUserId: externalUserId !== 'null' ? externalUserId : null,
    };
  });
};

export const navigateToNewSettingTab = (urlPath: string, callback?: () => void) => {
  const currentHash = window?.location?.hash;
  const origin = window?.location?.origin;
  if (currentHash && currentHash?.includes('/admin')) {
    callback?.();
  } else {
    window.open(`${origin}/#${urlPath}`, '_blank', 'noopener,noreferrer');
  }
}

export const redirectToSelf = () => {
  const urlToRedirect = window.location.href;
  const encodedUrl = encodeURIComponent(urlToRedirect);
  const url = `${BASE_URL}/crm-communication/transactions/redirect?url=${encodedUrl}`;
  window.location.href = url;
};

const getMemoryUsed = () => {
  if ('memory' in performance) {
    return ((performance.memory as any).usedJSHeapSize as number) || null; // In bytes
  }
  return null;
};

export const genderCodeToDisplay: { [key: string]: string } = {
  'M': 'Male',
  'F': 'Female',
};

export const getRoundedValue = (value: number) => {
  return parseFloat(value.toFixed(2));
}

export const getHccScoreData = (
  contactScore: {
    hccV28: number;
    reference: IContactScoreReference;
  } | undefined,
  contactScoreAudits: {
    scoreDate : string;
    scoreType : string;
    scoreValue : number;
  }[] | undefined,
  SCORE_TYPE: IScoreType,
  hasValue: (value: any) => boolean
): IHccScoreData => {
  const hccScore = contactScore?.hccV28 || 0;
  const hccScoreValue = hasValue(hccScore) ? hccScore : '-';

  const sortedContactScoreAudits = contactScoreAudits?.sort((a: IContactScoreAudit, b: IContactScoreAudit) => {
    return new Date(b.scoreDate).getTime() - new Date(a.scoreDate).getTime();
  });

  const hccScores = sortedContactScoreAudits?.filter(
    (item: IContactScoreAudit) => item?.scoreType === SCORE_TYPE.HCC_V_28
  );

  let hccScoreDiff = 0;
  let formattedHccScoreDiff = '0';

  if (hccScores && hccScores?.length >= 2) {
    const scoreDifference =
      hccScores?.[0].scoreValue - hccScores?.[1].scoreValue;

    if (scoreDifference !== 0) {
      hccScoreDiff = +scoreDifference.toFixed(2);
      formattedHccScoreDiff = hccScoreDiff > 0 ? `+${hccScoreDiff}` : `${hccScoreDiff}`;
    }
  }

  return {
    hccScore,
    hccScoreDiff,
    hccScoreValue,
    formattedHccScoreDiff
  };
};


export const normalizeBoolean = (value: string | boolean) => {
  if (typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
  }
  if (typeof value === 'boolean') {
    return value;
  }
  return false;
};

export const normalizeNumber = (value: string | number) => {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return parsedValue;
    }
  }
  return null;
}
export const normalizeSingleDate = (dateValue: string | number | Date) => {
  if (dateValue instanceof Date) {
    return !isNaN(dateValue.getTime()) ? dateValue : null;
  }
  if (typeof dateValue === 'string') {
    const parsedDate = new Date(dateValue);
    return !isNaN(parsedDate.getTime()) ? parsedDate : null;
  }
  return null;
};
export const normalizeDate = (
  value: string | Date,
  range: {
    from?: string | Date;
    to?: string | Date;
  }
) => {
  const normalizedValue = value ? normalizeSingleDate(value) : '';
  const fromDate = range?.from ? normalizeSingleDate(range.from) : '';
  const toDate = range?.to ? normalizeSingleDate(range.to) : '';
  return {
    value: normalizedValue,
    range: range
      ? {
          from: fromDate,
          to: toDate,
        }
      : null,
  };
};

export const isRenderTasksCompactView = () => {
  const patientTaskContextData = useContext(TaskViewContext);
  return patientTaskContextData?.isRenderCompactView || false;
}

export const isRenderTasksGrayBackground = () => {
  const patientTaskContextData = useContext(TaskViewContext);
  return patientTaskContextData?.isShowGrayBackground || false;
}


export const getApolloError = (error: any) => {
  let errorMessage = 'Something went wrong while processing claim';
  if (error?.networkError?.result) {
    const result = error.networkError.result;
    errorMessage = result?.message?.extensions?.error || result?.extensions?.error?.message || errorMessage;
  }
  return errorMessage;
}

export const getAxiosError = (error: any) => {
  return error?.response?.data?.message || error?.response?.data?.error;
}
export const isPhoneNumber = (searchString = ''): boolean => {
  const cleanedNumber = searchString
    .trim()
    .replace(/^\+1/, '')
    .replace(/[^0-9]/g, '');
  return cleanedNumber.length === 10;
};
