import {defaultThemeColors} from '../theme/ColorConfig';
import {isWeb} from '../utils/platformCheckUtils';
import {IColors, IThemeColors} from './interface';

export let Colors: IColors = {
  Custom: {
    silverGray: '#BFBFBF',
    primaryLight: '#C2A6F3',
    gray150: '#EEEEEE',
    primary300: '#7F56D9',
    LightPurpleColor :'#EBE8F2',
    DeceasedTagColor: '#F79009',
    DeceasedTagBorderColor: '#FBD7A8',
    DeceasedTagBackgroundColor: '#FCF1E2',
    PrimaryColor: '#6941C6',
    PrimaryBtnColor: '#a855f7',
    PrimaryColor100: '#F5F0FF',
    PrimaryColor200: '#D7C0FF',
    SuccessColor: '#12B76A',
    OfflineColor: '#dddddd',
    ErrorColor: '#F04438',
    BackgroundColor: '#fff',
    AppBackgroundColor: '#FAFAFF',
    TitleColor: '#000000d9',
    BorderColor: '#EAECF0',
    ConversationBgColor: '#FFFFFF',
    HoverBgColor: '#F0F0F0',
    IconColor: 'gray',
    IconColorWhite: '#FFFFFF',
    ContainerBGColor: '#FFFFFF',
    FontColorWhite: '#FFFFFF',
    BtnHoverColor: 'gray',
    DataKeyColor: '#888888',
    DisplayCardBGColor: '#fafafa',
    OfflineTagColor: '#aaaeb3',
    TabBorderColor: '#dee4e7',
    TextColor: '#101828',
    HeadingColor: '#000000',
    ShadowColor: '#000000',
    IconColorGray: '#9ca3af',
    ConversationBg: '#f1f5f8',
    UnReadCount: '#38cb3e',
    UnReadTost: '#68b6ff',
    IATextColor: '#667085',
    Gray500: '#667085',
    Gray900: '#101828',
    Gray800: '#16181D',
    Gray700: '#344054',
    Gray400: '#98A2B3',
    Gray600: '#475467',
    Gray300: '#D0D5DD',
    GRAY350 : '#6F7A90',
    Gray200: '#E4E7EC',
    Gray100: '#F2F4F7',
    GRAY150 : '#D0D6E1',
    Gray50: '#F9FAFB',
    Gray25: '#FCFDFD',
    ScreenBGColor: '#F2F4F7',
    CardBorderColor: '#F5F1FE',
    NeutralColor: '#F2F4F7',
    SubTitleColor: '#62627B',
    TitleColor2: '#071731',
    orange900: '#C4320A',
    SectionBorderColor: '#F0F1F4',
    Blue50: '#EFF6FF',
    Blue700: '#1D4ED8',
    Primary50: '#F9F5FF',
    Primary200: '#D7C0FF',
    Danger200: '#F8AEA3',
    Danger100: '#FBDAD0',
    Danger700: '#BE123C',
    Danger800: '#6F0835',
    ModalActionBtnBackgroung: '#FEEDD5',
    ModalActionBtnBorder: '#FDD7AD',
    AntCalendarDayColor: '#1890ff',
    Indigo50: '#EEF2FF',
    Indigo700: '#4338CA',
    PurpleColor: '#825AC7',
    GrayDisable: '#D0D5DD',
    GraphSubTitleColor: '#98a2b3',
    Info50: '#F0F9FF',
    Danger50: '#FFF1F2',
    Success50: '#ECFDF3',
    Base50: '#FDFAFF',
    MessageFooterIconColor: '#333333',
    Transparent: 'transparent',
    SlotBackground: '#1a0647',
    BookedAppointmentHomeBtn: '#4F2D90',
    DoneTextColor: '#10B981',
    SelectBorderColor: '#000000',
    ActionBtnBorder: '#EFE0FC',
    ProfileViewTextColor: '#101828',
    ButtonDefaultColor: '#1a0647',
    ProgressColor: '#9EA5D1',
    FilterBorderColor:'#8C210F',
    PlaceholderColor:'#D0D5DD',
    Base:'#FAF5FE',
    black:'#000000',
    Base100:'#DCC8F5',
    black25:'#00000080',
    mainPrimaryPurple: '#825AC7',
    mainSecondaryBrown: '#8C210F',
    brandAccentColor: '#F37232',
    brandAccentBackgroundColor: '#FFF5F0',
    foldIconColor: '#667085',
    buttonWhiteBg: '#ffffff00',
    MissedCallBg: '#FFEDEC',
    MissedCallIconColor: '#D92D20',
    OutgoingCompletedBgColor: '#D3FDE9',
    overdueTaskIconColor: '#D92D20',
    iconBgColorOrange: '#FFF9F5',
    insuranceBgColor: '#FCFAFF',
    textBlackColor: '#0f0f0f',
    orangeMessageTopBar: '#FFA500',
    stripeColorForPendingAppointment: '#E1E6ED',
    LabReportAbnormalColor: '#D92D20',
    LabReportAxisColor: '#F2F4F7',
    MonochromeBlack: "#000000",
    MonochromeWhite: "#FFFFFF",
    pausedCareJourneyColor:'#EEA47B',
    activeJourneyColor:'#80A4D5',
    stickyNoteEditColor: '#FFF7D9',
    selectedTabBorderColor: '#4F2D90',
    GrayTextForReadOnly: '#B7BFCB',
    Red100: '#FCEAE9',
    AppointmentStatusPending: '#F79009',
    AppointmentStatusRed: '#D92D20',
    Primary300: '#8C5AE2',
    crossIconColor: '#D92D20',
    patientHieDetailsBgColor: "#FCEAE9",
    infoColor: "#0ea5e9",
    White: '#ffffff',
    PendingTaskBackgroundColor: '#FEF4E6',
    PendingTaskFontColor: '#F79009',
    CompletedTaskBackgroundColor: '#E8FDF3',
    CompletedTaskFontColor: '#12B76A',
    MissedTaskBackgroundColor: '#FCEAE9',
    MissedTaskFontColor: '#D92D20',
    infoBannerBgColor: '#E7F0FE',
    purpleBackgroundContainer: '#FCFAFF',
    purple :'#9385E2',
    shadeGray: '#CED4DD',
    graphLineColor: '#80A4D5',
    AlertsDescriptionColor: '#8A94A8',
    graphLabelStroke: '#EEF1EA',
    higherGoalColor: '#004b23',
    lowerGoalColor: '#38b000',
    singleGoalColor: '#008000',
    goalAreaColor: 'rgba(252, 250, 255, 0.8)',
    sysGoalAreaColor: 'rgba(177, 210, 255, 0.16)',
    dysGoalAreaColor: 'rgba(169, 255, 240, 0.16)',
    goalLineColor: 'rgba(140, 90, 226, 0.5)',
    goalAreaBorderColor: 'rgb(227, 213, 247)',
    sysGoalAreaBorderColor: 'rgba(190, 218, 255, 1)',
    dysGoalAreaBorderColor: 'rgba(172, 244, 232, 1)',
    separatorPipeBackgroundColor: '#9B9B9B',
    formInputFocusBorderColor: '#D0D5DD',
    alertsDescriptionColor: '#8A94A8',
    badgeColorOrange: '#F47A3E',
    messageTimeColor: '#8A94A8',
    customInputBorderColor: '#e5e5e5',
    customInputFocusedBorderColor: '#22D3F8',
    BarColorV2: '#80A4D5',
    AWAKE_SLEEP: '#BD9ECF',
    REM_SLEEP: '#8DE3D4',
    DEEP_SLEEP: '#80A4D5',
    LIGHT_SLEEP: '#BFD7F6',
    InterventionDurationInfoColor: '#A7AFBE',
    onHoverPrimaryButtonColor: '#5020A0',
    LightGreenBackground: '#E6FBC3',
    OliveGreen: '#BACA33',
    DarkGreen: '#799B3E',
  },

  FoldPixel : {
    PRIMARY100 : '#F5F0FF',
    PRIMARY300 : '#8C5AE2',
    PRIMARY_FOCUS : '#AD7DFF',
    PRIMARY_HOVER : '#6B45AD',
    PRIMARY_20: '#8C5AE233',
    GRAY50 : '#F6F7F8',
    GRAY100 : '#E9ECF1',
    GRAY150 : '#D0D6E1',
    GRAY200 : '#A7AFBE',
    GRAY250 : '#8A94A8',
    GRAY300 : '#6F7A90',
    GRAY400 : '#3A485F',
    GRAY700: '#344054',
    GRAY800: '#16181D',
    GRAY_PLACEHOLDER : '#D0D5DD',
    ACCENT_LIGHT_PINK : '#FDE8EF',
    ACCENT_DARK_PINK : '#E81E63',
    ACCENT_DARK_PINK_10 : '#E81E631A',
    ACCENT_DARK_PINK_20 : '#E81E6333',
    ACCENT_LIGHT_BLUE: '#E9F4FE',
    ACCENT_DARK_BLUE : '#2196F3',
    STATUS_DARK_SUCCESS : '#12B76A',
    STATUS_LIGHT_SUCCESS : '#E8FDF3',
    TRANSPARENT : '#00000000',
    STATUS_ERROR : '#D92D20',
    STATUS_IN_PROGRESS: '#F79009',
    INFO_COLOR: '#0e66e9',
    ALERT_HOVER_COLOR: "#FCEAE9",
    WARNING: '#F47A3E',
    CONVERSATIONBGCOLOR: '#FFFFFF',
    PLACEHOLDERCOLOR: '#8A94A8'
  },

  tertiary: {},
  secondary: {},
  muted: {},
  error: {},
  success: {},
  primary: {},
  warning: {},
  danger: {},
  light: {},
  info: {},
};

export const GraphColors = [
  "#FC8EB7",
  "#FBD8AB",
  "#C2D6B8",
  "#FEDD64",
  "#F09431",
  "#D54C4C",
  "#FA9884",
  "#B08BBB",
  "#90AACB",
  "#FCD8D4",
  "#CAD315",
  "#D4B5B0"
];

export const updateThemeColors = (themeColorConfigs: IThemeColors): void => {
  if (isWeb()) {
    Colors = {...Colors, ...themeColorConfigs};
  } else {
    Colors = {...Colors, ...defaultThemeColors.colorConfig};
  }
};

