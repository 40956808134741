import {useLazyQuery, useQuery} from '@apollo/client';
import 'antd/dist/antd.variable.css';
import {Spinner, View} from 'native-base';

import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BodyContainer} from '../../components/BodyContainer';
import CommonDataProvider from '../../components/CommonDataProvider/CommonDataProvider';
import {setUserRoleSideBarCodesPermissionWise} from '../../components/RightSideContainer/UserAccess/UserAccessPermission';
import {getUserActionMasterData} from '../../components/RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {getDefaultPath} from '../../components/SideMenuBar/SideBarHelper';
import {
  CARESTUDIO_APOLLO_CONTEXT,
  CLOUD_TELEPHONY_APOLLO_CONTEXT,
} from '../../constants/Configs';
import {CommonDataContext, ICommonData} from '../../context/CommonDataContext';
import {
  IAgent,
  ILocationGroupList,
  IUserRoleCategoryResp,
  UserActionMasterData,
} from '../../Interfaces';
import {getEhrCapabilities} from '../../services/CommonService/AidBoxService';
import {MlovQueries, UserQueries} from '../../services/index';
import {IUserSettingsByCode} from '../../services/UserSettings/interfaces';
import * as UserSettingQueries from '../../services/UserSettings/UserSettingQueries';
import '../../styles/CSS/globalStyles.css';
import {ICTMlovRespData} from '../../utils/interfaces';
import {
  getCloudTelePhonyMlovByCategory,
  getMlovByCategory,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../utils/mlovUtils';
import {CommonWebSocket, initializeWebSocketConnection} from '../../utils/WebSocketUtils';
import {styles} from './Styles';
import {FilterDataContextProvider} from '../../context/FilterDataContext';
import {getAllAgentsList} from '../../services/ProfileImage/ProfileImage';
import {EventBus} from '../../utils/EventBus';
import {CUSTOM_MESSAGE_EVENT_CODES} from '../../constants/WebSocketConst';
import {MLOV_CATEGORY, ONLINE_STATUS} from '../../constants';
import {getAccountId, getCurrentUserRole, getUserUUID, isAllowedAllLocationToUser, isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner} from '../../utils/commonUtils';
import {USER_PREFERENCE_CODE} from '../../constants/MlovConst';
import useGetCapabilities from './useGetCapabilities';
import {INTEGRATION_BASE_URL} from '../../constants/BaseUrlConst';
import UserPracticeLocationQueries from '../../services/Location/UserPracticeLocationQueries';
import LocalStorage from '../../utils/LocalStorage';
import useAxiosQuery from '../../lib/useAxiosQuery';
import { IUserPracticeLocations } from '../../context/interfaces';
import {getAccountLocationUuidFromRolesResp} from '../MainScreen/utils';
import ConversationsQueries from '../../services/Conversations/ConversationsQueries';
import {getSipPhoneCredentialList} from '../../services/CloudTelephony/CloudTelephonyQueries';
import { IndexedDBService } from '../../services/IndexedDBService';
import {useMessageDBService} from '../../components/CustomHooks/useMessageDBService';
import {isIndexedDbEnabledForAccount} from '../../utils/configUtils';
import { EncryptionService } from '../../services/EncryptionService';

const BusinessStudio = () => {
  // const [capabilities, setCapabilities] = useState<{
  //   loading: boolean;
  //   data: any[];
  // }>({loading: true, data: []});
  const [userActionData, setUserActionData] = useState<UserActionMasterData[]>(
    []
  );
  const [state, setState] = React.useState({
    agents: [] as IAgent[],
    loading: false,
    locationGroupIds: [] as string[],
    accountLocationIds: [] as string[],
    locationGroupList: [] as ILocationGroupList[],
    userLocationLoading: false,
    userPracticeLocations: [] as IUserPracticeLocations[],
    sipNumberList: [],
  });

  const userUuid = getUserUUID();
  const accountId = getAccountId();
  const commonData = useContext(CommonDataContext);
  const isIndexedDbEnabled = isIndexedDbEnabledForAccount();
  const navigateToBusiness = useNavigate();
  const indexedDBService = IndexedDBService.getInstance();
  const encryptionService = EncryptionService.getInstance();
  const [isUserPermissionApply, setIsUserPermissionApply] = useState(false);
  const {syncLatestConversations} = useMessageDBService();
  const crmMlovData = useQuery(MlovQueries.GetAllMlovsWithCategory, {
    fetchPolicy: 'no-cache',
  });
  const careStudioMlovData = useQuery(MlovQueries.GetAllCareMlovsWithCategory, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const defaultContactTypes = useQuery(MlovQueries.GetAllDefaultContactTypes, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: commonData.userData?.accounts?.[0]?.uuid,
    },
  });
  const userSettingsApi = useQuery(UserSettingQueries.GetUserSettings, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      tenantId: commonData.userData?.accounts?.[0]?.uuid,
      userId: commonData.userData?.uuid,
    },
  });

  const [GetUserPracticeLocationsByUserUUID] = useLazyQuery(
    UserPracticeLocationQueries.GetUserPracticeLocationsByUserUUID, {
      fetchPolicy: 'no-cache',
    }
  );

  const accountUserList = useQuery(ConversationsQueries.AccountUserList,{
    fetchPolicy: 'no-cache',
    variables: {
      id: accountId,
    },
  });

  const cloudTelePhonyMlovs = useQuery<ICTMlovRespData>(
    MlovQueries.GetAllCloudTelePhonyMlovsWithCategory,
    {
      fetchPolicy: 'no-cache',
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
    }
  );

  const userRoleCategory = useQuery<IUserRoleCategoryResp>(
    UserQueries.GET_USER_ROLE_CATEGORIES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        category: [MLOV_CATEGORY.USER_ROLES, MLOV_CATEGORY.GLOBAL_ROLES]
      }
    }
  );
  const currentUserRoleApi = useQuery<AnimationPlaybackEvent>(
    UserQueries.CURRENT_USER_ROLE,
    {
      variables: {
        userId: commonData.userData?.id,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const userPermissionsApi = useQuery<any>(UserQueries.USER_PERMISSIONS, {
    variables: {
      accountUUID: commonData.userData?.accounts[0].uuid,
    },
    fetchPolicy: 'no-cache',
  });

  // Location details
  const userLocationDetails = useQuery(
    UserQueries.GET_LOGGED_IN_USER_LOCATION_DETAILS,
    {
      skip: !commonData.userData?.uuid,
      fetchPolicy: 'no-cache',
      variables: {
        userId: commonData.userData?.uuid,
      },
    },
  );

  const {loading: loadingEhrCapabilities, locationGroupMap, fetchCapabilities, accountConfigCapabilities, popGroupCapabilities} = useGetCapabilities();


  const formatUserRole = (currentUserRoleData: any) => {
    const currentUserRoles: any = [];
    if (currentUserRoleData) {
      currentUserRoleData.userRoles.forEach((data: any) => {
        currentUserRoles.push({
          code: data?.userRole?.customRoleCode,
          value: data?.userRole?.roleName,
        });
      });
    }
    return currentUserRoles;
  };

  const formattedMlovData = getMlovByCategory(crmMlovData.data);
  const formattedCareStudioMlovData = getMlovByCategory(
    careStudioMlovData.data,
    commonData.userData?.accounts?.[0]?.uuid,
  );

  const formatedCtMlovData = getCloudTelePhonyMlovByCategory(
    cloudTelePhonyMlovs?.data || ({} as any)
  );
  const formattedCurrentUserRole = formatUserRole(currentUserRoleApi.data);
  const userPreferenceList = getMlovListFromCategory(
    formattedCareStudioMlovData,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.USER_CONVERSATION_PREFERENCE
  );

  const isAllowedAllLocation = isAllowedAllLocationToUser(formattedCurrentUserRole);

  const getUserActionData = async () => {
    try {
      const response = await getUserActionMasterData();
      setUserActionData(response?.userActionMasters || []);
    } catch (error) {
      setUserActionData([]);
    }
  };
  const locationQuery = useAxiosQuery(
    `${INTEGRATION_BASE_URL}/api/FoldLocations`,
  );

  useEffect(()=> {
    const allLocationData = locationQuery?.data?.accountLocations || [];

    const locationGroupList = !isAllowedAllLocation
      ? userLocationDetails?.data?.users?.[0]?.userPracticeLocations?.map(
          (location: any) => {
            return {
              locationGroupId: location?.accountLocation?.locationGroupId,
              accountLocationUuid: location?.accountLocation?.uuid,
            };
          }
        ) || []
      : allLocationData?.map((location: any) => {
          return {
            locationGroupId: location?.locationGroupId,
            accountLocationUuid: location?.uuid,
          };
        });


    let locationGroupIds = !isAllowedAllLocation
      ? userLocationDetails?.data?.users?.[0]?.userPracticeLocations
          ?.map((location: any) => location?.accountLocation?.locationGroupId)
          ?.filter((item: string) => !!item) || []
      : allLocationData?.map((location: any) => location?.locationGroupId);
    locationGroupIds = [...new Set(locationGroupIds)];

    let accountLocationIds = !isAllowedAllLocation
      ? userLocationDetails?.data?.users?.[0]?.userPracticeLocations
          ?.map((location: any) => location?.accountLocation?.uuid)
          ?.filter((item: string) => !!item) || []
      : allLocationData?.map((location: any) => location?.uuid);
    accountLocationIds = [...new Set(accountLocationIds)];

    if (
      userLocationDetails?.data &&
      !locationQuery.loading &&
      !!allLocationData?.length
    ) {
      fetchCapabilities(Array.from(locationGroupIds));
    }

    setState((prev) => {
      return {
        ...prev,
        accountLocationIds: accountLocationIds,
        locationGroupIds: locationGroupIds,
        locationGroupList: locationGroupList
      };
    });

  }, [
    userLocationDetails,
    isAllowedAllLocation,
    locationQuery.loading,
    locationQuery?.data?.accountLocations?.length > 0,
  ]);

  const userSettings: IUserSettingsByCode = {};

  (userSettingsApi?.data?.defaultUserSettings || []).forEach(
    (defaultUserSetting: any) => {
      if (!defaultUserSetting) return;

      const id: string = defaultUserSetting.id;
      const code: string = defaultUserSetting.code;
      let value: string = defaultUserSetting.value;

      if (defaultUserSetting.userSettings?.[0]) {
        value = defaultUserSetting.userSettings[0].value;
      }

      userSettings[code] = {id, code, value};
    }
  );

  const getUserPreference = useQuery(UserQueries.GET_USER_PREFERENCE, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: userUuid,
      userPreferencesTypeId: userPreferenceId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });


  const accountEHRListQuery = useAxiosQuery(
    `${INTEGRATION_BASE_URL}/api/ehr`
  );
  const allCommonData: ICommonData = {
    ...commonData,
    userSettings,
    userPermissions: userPermissionsApi?.data?.userPermissions,
    MLOV: formattedMlovData,
    currentUserRoles: formattedCurrentUserRole,
    CARE_STUDIO_MLOV: formattedCareStudioMlovData,
    CLOUD_TELEPHONY_MLOV: formatedCtMlovData,
    userRoleCategories: userRoleCategory.data?.accountRoles || [],
    ehrCapabilities: [],
    userActionMasterData: userActionData,
    defaultContactTypes: defaultContactTypes.data,
    agents: state.agents || [],
    userPreferenceSetting: getUserPreference?.data,
    ehrCapabilityLocationMap: locationGroupMap,
    loggedInUserLocationDetails: {
      accountLocationIds: state.accountLocationIds,
      locationGroupIds: state.locationGroupIds,
      locationGroupList: state.locationGroupList,
    },
    accountConfigCapabilities: accountConfigCapabilities,
    accountLocationListWithEHR: locationQuery?.data?.accountLocations || [],
    accountEhrList: accountEHRListQuery?.data?.ehr?.map((ehr: {ehrName: string}) => ehr.ehrName) || [],
    userPracticeLocations: state.userPracticeLocations,
    accountUserList: accountUserList?.data?.users,
    sipNumberList: state?.sipNumberList,
    popGroupCapabilities: popGroupCapabilities,
  };
  if (
    !userPermissionsApi.loading &&
    !currentUserRoleApi.loading &&
    !isUserPermissionApply
  ) {
    setUserRoleSideBarCodesPermissionWise(
      formattedCurrentUserRole,
      userPermissionsApi?.data?.userPermissions
    );
    setIsUserPermissionApply(true);
    navigateToBusiness(getDefaultPath(commonData.userData?.uuid || ''), {replace: true});
  }

  const getAgentList = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const accountId = commonData.userData?.accounts?.[0]?.id;
    try {
      const response = await getAllAgentsList(accountId);
      const agents = response.data || [];
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          agents: agents,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          agents: [],
        };
      });
    }
  };


  const getUserPracticeLocations = async (userUuid: string) => {
    let userPracticeLocations: IUserPracticeLocations[] = [];
    let userAccountLocationUuids: string[] = []
    try {
      setState((prev) => {
        return {
          ...prev,
          userLocationLoading: true,
        };
      });
      const userPracticeLocationsResp =
        await GetUserPracticeLocationsByUserUUID({
          variables: {
            userUUID: userUuid,
            accountUuid: commonData.userData?.accounts?.[0]?.uuid,
          },
        });

      if (userPracticeLocationsResp?.data?.userPracticeLocations) {
        userPracticeLocations =
          userPracticeLocationsResp?.data?.userPracticeLocations;
          userAccountLocationUuids = getAccountLocationUuidFromRolesResp([],userPracticeLocations)
      }
      const userPracticeLocationsString = JSON.stringify(userPracticeLocations);
      const userAccountLocationUuidsString = JSON.stringify(userAccountLocationUuids);
      await LocalStorage.setItem(
        'userPracticeLocations',
        userPracticeLocationsString || '[]'
      );
      await LocalStorage.setItem(
        'userAccountLocationUuids',
        userAccountLocationUuidsString || '[]'
      );
      setState((prev) => {
        return {
          ...prev,
          userLocationLoading: false,
          userPracticeLocations: userPracticeLocations,
          userAccountLocationUuids: userAccountLocationUuids
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          userLocationLoading: false,
          userPracticeLocations: [],
        };
      });
    }
  };

  const getSipNumberList = async () => {
    try {
      setState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const spiPhoneCredResponse = await getSipPhoneCredentialList();
      setState((prev) => {
        return {
          ...prev,
          sipNumberList: spiPhoneCredResponse?.data ? spiPhoneCredResponse?.data : [],
          loading: false,
        };
      });
    } catch (err) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  }

  const connectToDBAndSyncConversations = async () => {
    if (!isIndexedDbEnabled) {
      return;
    }
    try {
      await encryptionService.setEncryptionKeyConfig();
      await indexedDBService.connectToDB();
      syncLatestConversations();
    } catch (error) {}
  };

  useEffect(() => {
    initializeWebSocketConnection(allCommonData.userData);
    getUserActionData();
    getAgentList();
    getUserPracticeLocations(userUuid);
    getSipNumberList();
    connectToDBAndSyncConversations();
  }, []);

  return (
    <View>
      {crmMlovData.loading ||
        careStudioMlovData.loading ||
        userRoleCategory.loading ||
        userPermissionsApi.loading ||
        currentUserRoleApi.loading ||
        loadingEhrCapabilities ||
        accountUserList.loading ||
        state.loading ||
        userSettingsApi.loading ||
        userLocationDetails.loading ||
        locationQuery.loading ||
        state.userLocationLoading ||
        !isUserPermissionApply ? (
        <View style={styles.loaderContainer}>
          <Spinner size={'lg'} />
        </View>
      ) : (
        <View>
          <View style={styles.bodyContainer}>
            <CommonDataProvider allCommonData={allCommonData}>
              <FilterDataContextProvider>
                <BodyContainer />
              </FilterDataContextProvider>
            </CommonDataProvider>
          </View>
        </View>
      )}
    </View>
  );
};

export default BusinessStudio;
