import {useLazyQuery} from '@apollo/client';
import React, {useContext, useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {CARE_PROGRAM_STEP_STATUS_CODES, APPOINTMENT_STATUS_CODES, CARE_PROGRAM_TYPES, CARE_TEAM, CARE_TEAM_MEMBER_TYPE, MLOV_CATEGORY, USER_ROLE_CODES, CARE_PROGRAM_TYPE_CODES, CARE_PLAN_STATUS_CODES, APPOINTMENT_PARTICIPANT_TYPE_CODES, BILLABLE_ACTIVITY_STATUS_STATUS_CODES} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {IMlov, IUser} from '../../../../Interfaces/CommonInterfaces';
import {GET_CARE_TEAM_MEMBER_TYPE, GET_CONTACT_ID_BY_MATCH_CARE_MEMBER_TYPE_USER, GET_UNDER_MY_CARE_CONTACT_IDS, GET_UNDER_MY_CARE_CONTACT_WITH_MATCH_CARE_MEMBER_TYPE_USER} from '../../../../services/CareTeam/CareTeamQueries';
import { getPatientChronicConditions } from '../../../../services/CommonService/AidBoxService';
import { IAppointmentResponse, IContactCareProgramResponse } from '../../../../services/ContactCareProgram/interface';
import {GET_CONTACT_CARE_PROGRAM_WITH_STEPS_LOG, GET_CONTACT_CARE_PROGRAM_WITH_STEPS_LOG_BY_CARE_PROGRAM_TYPE_ID, GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID, GET_CONTACT_IDS_BY_PAYER_CARE_PROGRAM_ID_FOR_TCM, GET_CONTACT_IDS_BY_PROGRAM_IDS, GET_CONTACT_IDS_BY_PROGRAM_IDS_FOR_TCM, GET_CONTACT_IDS_BY_PROGRAM_STEPS_ID, GET_CONTACT_IDS_BY_PROGRAM_STEPS_ID_CONTACT_IDS} from '../../../../services/ContactCareProgram/ContactCareProgram';
import LeadQueries, {
  getContactDataFromAPI,
  GetContactTags,
  GetConsentsByContactIds,
} from '../../../../services/Lead/LeadQueries';
import { GetTimezoneOfAccountAndLocations } from '../../../../services/Location/UserPracticeLocationQueries';

import {
  getAccountId,
  getAccountUUID,
  getUserUUID,
} from '../../../../utils/commonUtils';
import {getMlovIdFromCode, getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {getProgramContactDataFromPopGroup} from '../../../RightSideContainer/Contacts/CodeGroups/CodeGroupApi';
import {LabelTagging} from '../../../RightSideContainer/Contacts/Leads/interfaces';
import { getTableColumnByFilterCode } from '../../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/Helper/LeadListViewColumns';
import {formateLeadListData} from '../../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import { getRecordListFormatted } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/AddOrUpdateConditions/AddOrUpdateConditionsHelper';
import {
  ADDITIONAL_ATTRIBUTE_KEY,
  AFTER_PROCESS_CONTACT_API_CODE,
  AWV_POP_GROUP_CODE,
  AWV_SCHEDULED_SUB_FILTER_CODE,
  CARE_PROGRAM_STEP_TYPE,
  CONTACT_TABLE_COLUMN_CODE,
  DASHBOARD_TAB_CODE,
  DEFAULT_AFTER_PROCESS_CONTACT_API_CODE,
  FUTURE_AWV_API_CODE,
  PROGRAM_FILTER_CODE,
  PROGRAM_STEPS_FILTER_CODE,
  PROGRAM_SUB_FILTER_CODE,
  STEP_STATUS,
  TCM_FILTER_CODES,
} from '../constant';
import {
  CONTACT_SUB_DATA_FILTER,
  ICareTeams,
  IContactsFilterParams,
  IFilterCategoryApiResponseMap,
  IGetContactFilterParams,
  IGetContactIdWiseResponseParams,
  IPatientDashboardManager,
  IPayerProgramWiseProgramResponse,
  IPopGroupResponse,
} from '../interface';
import { getAwvFilteredResponse, getContactFilter} from './utils';
import { GET_APPOINTMENTS_BY_CONTACT_IDS, GET_FUTURE_APPOINTMENT_DATA, GET_FUTURE_BOOKED_APPOINTMENTS_BY_CONTACT_IDS } from '../../../../services/Appointment/AppointmentQueries';
import { getCurrentDateTimeMoment } from '../../../../utils/DateUtils';
import { EventBus } from '../../../../utils/EventBus';
import { EVENT_NAMES, ORDER, SORT_ORDER } from '../../../../constants';
import { GET_CONTACTIDS_WHICH_HAVE_ATLEAST_ONE_ALERT } from '../../../../services/Task/TaskQueries';
import { getCompletedTaskStatusId } from '../../Tasks/TasksUtils/TasksUtils';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../constants/WebSocketConst';
import { getAttributeByKey } from '../../CareProgramPanel/CareProgramUtils';
import { GET_BILLING_MINS_BY_MONTH } from '../../../RightSideContainer/CareManagementBilling/BillingQueries';

export const usePatientManager = (args: IPatientDashboardManager) => {
  const {categoryCode, tabCode, pagination, config} = args;
  const [loading, setLoading] = useState<boolean>(false);
  const [awvProgramResponseContactIds, setAwvProgramContactIdsResponse] = useState<string[]>([])
  const [contactData, setContactData] = useState<{
    contactList: any[];
    totalCount: number;
    accountUsers: IUser[],
  }>({
    contactList: [],
    totalCount: 0,
    accountUsers: [],
  });

  const commonContextData = useContext(CommonDataContext);
  const currentUserId = getUserUUID();
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const billableActivityStatusMlov = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS) || [];

  const careTeamList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.PRIMARY_CARE_TEAM) || [];
  const careProgramStepList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_STEP_TYPE) || [];
  const careProgramStepStatusList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_STEP_STATUS) || [];
  const carePlanStatusList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PLAN_STATUS) || [];
  const careProgramTypeList = getMlovListFromCategory(
    commonContextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_TYPES
  ) || [];
  const mlovData = useContext(CommonDataContext);
  const appointmentStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];
  const taskStatusMlov = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];

  const ccmCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.CCM);
  const ecmCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.ECM);
  const tcmCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.TCM);
  const accpCareProgramTypeId = getMlovIdFromCode(careProgramTypeList, CARE_PROGRAM_TYPES.ACCP);

  const [getUnderMyCareContactIds] = useLazyQuery(
    GET_UNDER_MY_CARE_CONTACT_IDS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  const getFilterCategoryMlov = (categoryCode: string): IMlov | undefined => {
    return careProgramStepList.find(careProgramStep => {
      return careProgramStep?.code === categoryCode;
    });
  };
  const assessmentCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.ASSESSMENT);
  const consentCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.CONSENT);
  const planCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN);
  const scheduleF2FCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F);
  const ccmEnrolledCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.CCM_ENROLLED);
  const tcmOutreachCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.TCM_OUTREACH_2DAY);
  const tcmAppointmentCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.TCM_OUTREACH_14DAY);
  const ecmOutreachCategory = getFilterCategoryMlov(PROGRAM_STEPS_FILTER_CODE.ECM_OUTREACH);

  const [searchContacts] = useLazyQuery(LeadQueries.searchContacts,{
    fetchPolicy: 'no-cache',
  })

  const [getUnderMyCareContactIdsWithCareTeamMemberTypeUserId] = useLazyQuery(
    GET_UNDER_MY_CARE_CONTACT_WITH_MATCH_CARE_MEMBER_TYPE_USER,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  const [getContactByMatchCareTeamMemberType] = useLazyQuery(
    GET_CONTACT_ID_BY_MATCH_CARE_MEMBER_TYPE_USER,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  const [getContactTags] = useLazyQuery(GetContactTags, {
    fetchPolicy: 'no-cache',
  });

  const [getContactConsentByIds] = useLazyQuery(GetConsentsByContactIds, {
    fetchPolicy: 'no-cache',
  });


  const [getAccountUsers] = useLazyQuery(GetTimezoneOfAccountAndLocations, {
    fetchPolicy: 'no-cache',
  });

  const [getFutureAppointmentData] = useLazyQuery(GET_FUTURE_APPOINTMENT_DATA, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });


  const getProgramIdsByCategory = (categoryCodes: string[]) => {
    const programIds: string[] = [];
    for (const categoryCode of categoryCodes) {
      switch (categoryCode) {
        case PROGRAM_FILTER_CODE.CCM:
        case PROGRAM_SUB_FILTER_CODE.CCM_VISIT_PENDING:
        case PROGRAM_SUB_FILTER_CODE.CCM_ASSESSMENT_PENDING:
        case PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_DUE:
        case PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_ENROLL:
        case PROGRAM_SUB_FILTER_CODE.CCM_ENROLLMENT_PENDING:
        case PROGRAM_SUB_FILTER_CODE.CCM_CONSENT_PENDING:
          programIds.push(ccmCareProgramTypeId);
          break;
        case PROGRAM_FILTER_CODE.TCM:
        case PROGRAM_SUB_FILTER_CODE.TCM_OUTREACH_PENDING:
        case PROGRAM_SUB_FILTER_CODE.TCM_APPOINTMENT_PENDING:
          programIds.push(tcmCareProgramTypeId);
          break;
        case PROGRAM_FILTER_CODE.ECM:
        case PROGRAM_SUB_FILTER_CODE.ECM_OUTREACH_PENDING:
        case PROGRAM_SUB_FILTER_CODE.ECM_CONSENT_PENDING:
        case PROGRAM_SUB_FILTER_CODE.ECM_ASSESSMENT_PENDING:
        case PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_ENROLL:
        case PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_DUE:
          programIds.push(ecmCareProgramTypeId);
          break;
        case PROGRAM_FILTER_CODE.ACCP:
          programIds.push(accpCareProgramTypeId);
          break;
      }
    }
    return programIds;
  }

  const getBillableActivityStatusIds = () => {
    return [billableActivityStatusMlov.find((mlov) => mlov.code === BILLABLE_ACTIVITY_STATUS_STATUS_CODES.PENDING)?.id || '']
  }

  const updateConsentDataInContactResponse = (
    consentResponse: any[],
    contactData: any[],
  ) => {
    const result = contactData.map((contact) => {
      const additionalLoader = contact?.additionalDataLoading || {};
      const isConsentIncludes = (consentResponse || []).some(consent => {
        return consent?.contactUuid === contact?.uuid;
      });
      return {
        ...contact,
        additionalDataLoading: {
          ...additionalLoader,
          consentLoading: false,
        },
        contactData: {
          ...contact.contactData,
          contactConsents: isConsentIncludes
            ? [{contactUuid: contact?.uuid}]
            : [],
        },
        singleLeadData: {
          ...contact.singleLeadData,
          contactConsents: isConsentIncludes
            ? [{contactUuid: contact?.uuid}]
            : [],
        }
      };
    });
    return result;
  };

  const updateTaggingDataInContactResponse = (
    labelTaggings: LabelTagging[],
    contactData: any[],
  ) => {
    if (!labelTaggings?.length) {
      const result = contactData.map((item) => {
        const additionalLoader = item?.additionalDataLoading || {};
        return {
          ...item,
          additionalDataLoading: {
            ...additionalLoader,
            tagLoading: false,
          }
        };
      });
      return result;
    }
    const result = contactData.map((item) => {
      const additionalLoader = item?.additionalDataLoading || {};
      return {
        ...item,
        tags: labelTaggings.filter((tag) => tag.taggableId === item.id) || [],
        additionalDataLoading: {
          ...additionalLoader,
          tagLoading: false,
        }
      };
    });
    return result;
  };

  const [getCategoryWiseContactIds] = useLazyQuery(
    GET_CONTACT_IDS_BY_PROGRAM_STEPS_ID,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  const [getCategoryAndContactIdWiseContactIds] = useLazyQuery(
    GET_CONTACT_IDS_BY_PROGRAM_STEPS_ID_CONTACT_IDS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );


  const [getContactWiseCareProgram] = useLazyQuery(
    GET_CONTACT_CARE_PROGRAM_WITH_STEPS_LOG,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  const [getContactWiseCareProgramByCareProgramTypeId] = useLazyQuery(
    GET_CONTACT_CARE_PROGRAM_WITH_STEPS_LOG_BY_CARE_PROGRAM_TYPE_ID,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  const [getCareTeamMemberByMemberType] = useLazyQuery(
    GET_CARE_TEAM_MEMBER_TYPE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  const filterUnderMyCareContactIds = (
    underMyCareContactIds: string[],
    categoryWiseContactIds: string[],
  ) => {
    return (categoryWiseContactIds || []).filter(categoryWiseContactId => {
      return (underMyCareContactIds || []).some(underMyCareContactId => {
        return underMyCareContactId === categoryWiseContactId;
      });
    });
  };

  const getProgramIdByCategory = (categoryCode: string): string => {
    let programId = '';
    switch(categoryCode) {
      case PROGRAM_FILTER_CODE.CCM:
        programId = ccmCareProgramTypeId;
        break;
      case PROGRAM_FILTER_CODE.TCM:
        programId = tcmCareProgramTypeId;
        break;
      case PROGRAM_FILTER_CODE.ECM:
        programId = ecmCareProgramTypeId;
        break;
      case PROGRAM_FILTER_CODE.ACCP:
        programId = accpCareProgramTypeId;
        break;
    }
    return programId;
  }

  const getPayerProgramIdWiseContactIds = (payerProgramId: string, programWiseResponse: IPayerProgramWiseProgramResponse[], contactIds: string[]): string[] => {
    let contactUuids: string[] = [];
    (programWiseResponse || []).forEach(programResponse => {
      if (programResponse?.contactCareProgram?.payerCareProgram?.careProgramTypeId === payerProgramId) {
        contactUuids.push(programResponse?.contactCareProgram?.contactId);
      }
    });
    if (tabCode === DASHBOARD_TAB_CODE.MY_PATIENT && !contactIds?.length) {
      return [];
    }
    if (contactIds?.length) {
      contactUuids = (contactUuids || []).filter(contactUuid => {
        return contactIds.includes(contactUuid);
      });
    }
    return contactUuids;
  }

  const getCandidatesOfProgramByRuleCode = (ruleCode: string, programWiseResponse: IPopGroupResponse, contactIds: string[]): string[] => {
    let contactUuids: string[] = [];
    (programWiseResponse?.result || []).some(programResponse => {
      if (programResponse?.ruleCode === ruleCode) {
        contactUuids = programResponse?.ruleResult?.contactUuids || [];
        return true;
      }
    });
    if (tabCode === DASHBOARD_TAB_CODE.MY_PATIENT && !contactIds?.length) {
      return [];
    }
    if (contactIds?.length) {
      contactUuids = (contactUuids || []).filter(contactUuid => {
        return contactIds.includes(contactUuid);
      });
    }
    return contactUuids;
  }

  const getFormattedResponsePopGroupFilter = (
    response: IPopGroupResponse,
    categoryCode: string,
    filterContactIds: string[],
  ) => {
    const contactUuids = getCandidatesOfProgramByRuleCode(categoryCode, response, filterContactIds);
    return {
      categoryCode: categoryCode,
      contactIds: contactUuids,
      count: contactUuids?.length,
    } as IFilterCategoryApiResponseMap;
  };

  const getCategoryWiseData = async (
    categoryCode: string,
    contactIds: string[],
    tabCode: string,
  ) => {
    const filterStatusIds: string[] = [];
    const DoneStatusId: string[] = [];
    (careProgramStepStatusList || []).map(careProgramStepStatus => {
      if (
        careProgramStepStatus.code == STEP_STATUS.FAIL ||
        careProgramStepStatus.code == STEP_STATUS.TODO
      ) {
        filterStatusIds.push(careProgramStepStatus?.id);
      }
    });
    (careProgramStepStatusList || []).map(careProgramStepStatus => {
      if (careProgramStepStatus.code == STEP_STATUS.DONE) {
        DoneStatusId.push(careProgramStepStatus?.id);
      }
    });
    switch (categoryCode) {
      case PROGRAM_STEPS_FILTER_CODE.ALL:
          const response = await getContactIdWiseResponse({contactIds: contactIds});
          await processContactResponseData(response);
        break;
      case PROGRAM_STEPS_FILTER_CODE.ASSESSMENT:
      case PROGRAM_STEPS_FILTER_CODE.CONSENT:
      case PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN:
      case PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F:
          let uniqueCareProgramStepTypeIds: (string | undefined)[] = [];
          switch (categoryCode) {
            case PROGRAM_STEPS_FILTER_CODE.ASSESSMENT:
              uniqueCareProgramStepTypeIds = [assessmentCategory?.id];
              break;
            case PROGRAM_STEPS_FILTER_CODE.CONSENT:
              uniqueCareProgramStepTypeIds = [consentCategory?.id];
              break;
            case PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN:
              uniqueCareProgramStepTypeIds = [planCategory?.id];
              break;
            case PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F:
              uniqueCareProgramStepTypeIds = [scheduleF2FCategory?.id];
              break;
          }
            const res = await getContactIdWiseResponse({careProgramStepTypeIds: uniqueCareProgramStepTypeIds || [], careProgramStepStatusIds: filterStatusIds});
            await processContactResponseData(res);

        break;
      case PROGRAM_FILTER_CODE.CCM_ENROLLED:
        const ccmEnrollRes = await getContactIdWiseResponse({careProgramStepTypeIds: [ccmEnrolledCategory?.id] || [], careProgramStepStatusIds: DoneStatusId, careProgramTypeIds: [ccmCareProgramTypeId]});
        await processContactResponseData(ccmEnrollRes);
        break;

      case PROGRAM_FILTER_CODE.CCM:
      case PROGRAM_FILTER_CODE.TCM:
      case PROGRAM_FILTER_CODE.ECM:
      case PROGRAM_FILTER_CODE.ACCP:
        const programId = getProgramIdsByCategory([categoryCode]);
        const resp = await getContactIdWiseResponse({careProgramTypeIds: programId});
        await processContactResponseData(resp);
        break;
      case PROGRAM_FILTER_CODE.AWV_DUE:
        if (!contactIds?.length && tabCode === DASHBOARD_TAB_CODE.MY_PATIENT) {
          setContactData(prev => {
            return {
              ...prev,
              contactList: [],
              totalCount: 0,
            };
          });
        } else {
            const response = await getContactIdWiseResponse({ruleCodes: [AWV_POP_GROUP_CODE]});
            await processContactResponseData(response);
        }
        break;
      case PROGRAM_SUB_FILTER_CODE.AWV_SCHEDULED:
            const respo = await getContactIdWiseResponse({ ruleCodes: [FUTURE_AWV_API_CODE]});
            await processContactResponseData(respo);
        break;
      case PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED:
            const respon = await getContactIdWiseResponse({customFilterCode: AWV_SCHEDULED_SUB_FILTER_CODE.NOT_SCHEDULED});
            await processContactResponseData(respon);
        break;
      case PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT:
            const respons = await getContactIdWiseResponse({customFilterCode: AWV_SCHEDULED_SUB_FILTER_CODE.OTHER_APPOINTMENT});
            await processContactResponseData(respons);
        break;
      case PROGRAM_FILTER_CODE.ALERTS:
          const ressponse = await getContactIdWiseResponse({ showContactsWithPendingAlerts: true});
          await processContactResponseData(ressponse);
        break;
      case PROGRAM_SUB_FILTER_CODE.CCM_VISIT_PENDING:
      case PROGRAM_SUB_FILTER_CODE.CCM_ASSESSMENT_PENDING:
      case PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_DUE:
      case PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_ENROLL:
      case PROGRAM_SUB_FILTER_CODE.CCM_ENROLLMENT_PENDING:
      case PROGRAM_SUB_FILTER_CODE.CCM_CONSENT_PENDING:
      case PROGRAM_SUB_FILTER_CODE.TCM_OUTREACH_PENDING:
      case PROGRAM_SUB_FILTER_CODE.TCM_APPOINTMENT_PENDING:
      case PROGRAM_SUB_FILTER_CODE.ECM_OUTREACH_PENDING:
      case PROGRAM_SUB_FILTER_CODE.ECM_CONSENT_PENDING:
      case PROGRAM_SUB_FILTER_CODE.ECM_ASSESSMENT_PENDING:
      case PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_ENROLL:
      case PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_DUE:
        const contactProgramId = getProgramIdsByCategory([categoryCode]);
        const programStepFilter = getProgramStepWiseTypeId(categoryCode);
        const contactProgramResponse = await getContactIdWiseResponse({careProgramTypeIds: contactProgramId, ...programStepFilter});
        await processContactResponseData(contactProgramResponse);
      break;
    }
  };

  const getProgramStepWiseTypeId = (subFilterCode: string) => {
    let filterStatusIds: string[] = [];
    (careProgramStepStatusList || []).map(careProgramStepStatus => {
      if (careProgramStepStatus.code == STEP_STATUS.FAIL || careProgramStepStatus.code == STEP_STATUS.TODO) {
        filterStatusIds.push(careProgramStepStatus.id);
      }
    });
    const stepTypeIds = [];
    const filterCarePlanStatusIds: string[] = [];
    let isCarePlanNotAssigned: boolean | undefined = undefined;
    switch (subFilterCode) {
      case PROGRAM_SUB_FILTER_CODE.CCM_VISIT_PENDING:
        stepTypeIds.push(scheduleF2FCategory?.id);
        break;
      case PROGRAM_SUB_FILTER_CODE.CCM_CONSENT_PENDING:
      case PROGRAM_SUB_FILTER_CODE.ECM_CONSENT_PENDING:
        stepTypeIds.push(consentCategory?.id);
        break
      case PROGRAM_SUB_FILTER_CODE.CCM_ASSESSMENT_PENDING:
      case PROGRAM_SUB_FILTER_CODE.ECM_ASSESSMENT_PENDING:
        stepTypeIds.push(assessmentCategory?.id);
        break;
      case PROGRAM_SUB_FILTER_CODE.CCM_ENROLLMENT_PENDING:
        stepTypeIds.push(ccmEnrolledCategory?.id);
        break;
      case PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_DUE:
      case PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_DUE:
        filterStatusIds = [];
        isCarePlanNotAssigned = true;
        break;
      case PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_ENROLL:
      case PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_ENROLL:
        filterStatusIds = [];
        isCarePlanNotAssigned = false;
        const allowCarePlanStatusCodes = [CARE_PLAN_STATUS_CODES.ACTIVE, CARE_PLAN_STATUS_CODES.DRAFT, CARE_PLAN_STATUS_CODES.IN_REVIEW, CARE_PLAN_STATUS_CODES.COMPLETED];
        (carePlanStatusList || []).map(carePlanStatus => {
          if (allowCarePlanStatusCodes.includes(carePlanStatus.code)) {
            filterCarePlanStatusIds.push(carePlanStatus.id);
          }
        });
        break;
      case PROGRAM_SUB_FILTER_CODE.TCM_OUTREACH_PENDING:
        stepTypeIds.push(tcmOutreachCategory?.id);
        break;
      case PROGRAM_SUB_FILTER_CODE.TCM_APPOINTMENT_PENDING:
        stepTypeIds.push(tcmAppointmentCategory?.id);
        break;
      case PROGRAM_SUB_FILTER_CODE.ECM_OUTREACH_PENDING:
        stepTypeIds.push(ecmOutreachCategory?.id);
        break;
    }
    return { careProgramStepTypeIds: stepTypeIds, careProgramStepStatusIds: filterStatusIds, carePlanStatusIds: filterCarePlanStatusIds, isCarePlanNotAssigned: isCarePlanNotAssigned };
  }


  const getUniqueContactIds =(meetings:any) =>{
    const uniqueContactIds = new Set();
    meetings.forEach((meeting:any) => {
        meeting.participants.forEach((participant:any) => {
            uniqueContactIds.add(participant.contactId);
        });
    });
    return Array.from(uniqueContactIds);
}

  const getAfterFormattedContactApiCode = (): CONTACT_SUB_DATA_FILTER[] => {
    const categoryWiseEnum: CONTACT_SUB_DATA_FILTER[] = [];
    const columnCodes: string [] = getTableColumnByFilterCode(args.categoryCode);
    for (const columnCode of columnCodes) {
      switch(columnCode) {
        case CONTACT_TABLE_COLUMN_CODE.ACTION:
          if (!categoryWiseEnum.includes(CONTACT_SUB_DATA_FILTER.CONSENT)) {
            categoryWiseEnum.push(CONTACT_SUB_DATA_FILTER.CONSENT);
          }
          break;
        case CONTACT_TABLE_COLUMN_CODE.TAGS:
          if (!categoryWiseEnum.includes(CONTACT_SUB_DATA_FILTER.TAGS)) {
            categoryWiseEnum.push(CONTACT_SUB_DATA_FILTER.TAGS);
          }
          break;
        case CONTACT_TABLE_COLUMN_CODE.CONDITION:
          if (!categoryWiseEnum.includes(CONTACT_SUB_DATA_FILTER.CHRONIC_CONDITION)) {
            categoryWiseEnum.push(CONTACT_SUB_DATA_FILTER.CHRONIC_CONDITION);
          }
          break;
        case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT:
        case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT_DATE:
        case CONTACT_TABLE_COLUMN_CODE.ADMITTED_DATE:
        case CONTACT_TABLE_COLUMN_CODE.DISCHARGE_DATE:
        case CONTACT_TABLE_COLUMN_CODE.DISCHARGE_TO:
        case CONTACT_TABLE_COLUMN_CODE.FACILITY:
        case CONTACT_TABLE_COLUMN_CODE.FACILITY_TYPE:
        case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT_STATUS:
          if (!categoryWiseEnum.includes(CONTACT_SUB_DATA_FILTER.CARE_PROGRAM)) {
            categoryWiseEnum.push(CONTACT_SUB_DATA_FILTER.CARE_PROGRAM);
          }
          break;
        case CONTACT_TABLE_COLUMN_CODE.LAST_VIST:
          if (!categoryWiseEnum.includes(CONTACT_SUB_DATA_FILTER.LAST_VIST)) {
              categoryWiseEnum.push(CONTACT_SUB_DATA_FILTER.LAST_VIST);
          }
          break;
        case CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER:
          if (!categoryWiseEnum.includes(CONTACT_SUB_DATA_FILTER.CARE_TEAM)) {
            categoryWiseEnum.push(CONTACT_SUB_DATA_FILTER.CARE_TEAM);
          }
          break;
        case CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS:
        case CONTACT_TABLE_COLUMN_CODE.CARE_STATUS:
          if (!categoryWiseEnum.includes(CONTACT_SUB_DATA_FILTER.CARE_PLAN)) {
            categoryWiseEnum.push(CONTACT_SUB_DATA_FILTER.CARE_PLAN);
          }
          break;
        case CONTACT_TABLE_COLUMN_CODE.NEXT_APPOINTMENT:
          if (!categoryWiseEnum.includes(CONTACT_SUB_DATA_FILTER.APPOINTMENT)) {
            categoryWiseEnum.push(CONTACT_SUB_DATA_FILTER.APPOINTMENT);
          }
          break;
        case CONTACT_TABLE_COLUMN_CODE.TIME_SPENT:
          if (!categoryWiseEnum.includes(CONTACT_SUB_DATA_FILTER.TIME_SPENT)) {
            categoryWiseEnum.push(CONTACT_SUB_DATA_FILTER.TIME_SPENT);
          }
          break;
        case CONTACT_TABLE_COLUMN_CODE.AWV_DUE:
        case CONTACT_TABLE_COLUMN_CODE.HEALTH_PLAN:
          if (!categoryWiseEnum.includes(CONTACT_SUB_DATA_FILTER.CLAIM)) {
            categoryWiseEnum.push(CONTACT_SUB_DATA_FILTER.CLAIM);
          }
          break;
      }
    }
    return categoryWiseEnum;
  }

  const buildChronicConditionParams = (formattedContactData: any[]) => {
    const patientAndLocationMap: { location?: string, patientId: string} [] = [];
    (formattedContactData || []).forEach((contact: any) => {
      const accountLocationUuid = contact.singleLeadData.contactPracticeLocations[0]?.accountLocation.uuid;
      patientAndLocationMap.push({
        patientId: contact?.contactData?.patient?.patientId,
        ...(!!accountLocationUuid && { location: accountLocationUuid })
      });
    });
    return {
      patients: patientAndLocationMap,
      isChronic: true,
    };
  }

  const [getFutureBookedAppointments, {refetch: refetchAppointments}] = useLazyQuery(
    GET_FUTURE_BOOKED_APPOINTMENTS_BY_CONTACT_IDS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  const [getBillableActivitiesByMonth] = useLazyQuery(GET_BILLING_MINS_BY_MONTH, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getAppointments] = useLazyQuery(GET_APPOINTMENTS_BY_CONTACT_IDS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getContactIdsWithAtLeastOneAlert] = useLazyQuery(
    GET_CONTACTIDS_WHICH_HAVE_ATLEAST_ONE_ALERT,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  )

  const getCategoryResponse = (
    responseList: any[],
    promiseIndexWithCodes: { index: number, categoryIndex: number}[],
    enumCode: CONTACT_SUB_DATA_FILTER
  ) => {
    let categoryResponse: any = {};
    const findPromiseIndex = (promiseIndexWithCodes || []).find(promiseIndexWithCode => {
      return promiseIndexWithCode.categoryIndex === enumCode;
    });
    if (findPromiseIndex && findPromiseIndex?.categoryIndex >= 0) {
      categoryResponse = responseList[findPromiseIndex.index];
    }
    return categoryResponse;
  }

  const isCategoryIndexAlreadyAdded = (categoryIndex: number, promiseIndexWithCodes: { index: number, categoryIndex: number}[]): boolean => {
    return (promiseIndexWithCodes || []).some(promiseIndexWithCode => {
      return promiseIndexWithCode?.categoryIndex === categoryIndex;
    })
  }

  const getResponseByRequestObject = async (
    formattedContactData: any[],
    contactUuids: string[],
    contactIds: string[]
  ): Promise<{ responseList: any[]; promiseIndexWithCodes: { index: number; categoryIndex: number; }[]; }> => {
    try {
      const promiseList: any[] = [];
      const promiseIndexWithCode: { index: number, categoryIndex: number}[] = [];
      const columnCodes: string [] = getTableColumnByFilterCode(args.categoryCode);
      let promiseIndex = 0;
      for (const columnCode of columnCodes) {
        switch(columnCode) {
          case CONTACT_TABLE_COLUMN_CODE.ACTION:
            if (!isCategoryIndexAlreadyAdded(CONTACT_SUB_DATA_FILTER.CONSENT, promiseIndexWithCode)) {
              promiseIndexWithCode.push({
                index: promiseIndex,
                categoryIndex: CONTACT_SUB_DATA_FILTER.CONSENT
              });
              promiseIndex += 1;
              promiseList.push(getContactConsentByIds({
                variables: {
                  accountUuid: accountUuid,
                  contactIds: {
                    _in: [...contactUuids],
                  },
                },
              }));
            }
            break;
          case CONTACT_TABLE_COLUMN_CODE.TAGS:
            if (!isCategoryIndexAlreadyAdded(CONTACT_SUB_DATA_FILTER.TAGS, promiseIndexWithCode)) {
              promiseIndexWithCode.push({
                index: promiseIndex,
                categoryIndex: CONTACT_SUB_DATA_FILTER.TAGS
              });
              promiseIndex += 1;
              promiseList.push(getContactTags({variables: {contactIds: contactIds}}));
            }
            break;
          case CONTACT_TABLE_COLUMN_CODE.CONDITION:
            if (!isCategoryIndexAlreadyAdded(CONTACT_SUB_DATA_FILTER.CHRONIC_CONDITION, promiseIndexWithCode)) {
              promiseIndexWithCode.push({
                index: promiseIndex,
                categoryIndex: CONTACT_SUB_DATA_FILTER.CHRONIC_CONDITION
              });
              promiseIndex += 1;
              const params = buildChronicConditionParams(formattedContactData);
              promiseList.push(getPatientChronicConditions(params?.patients, params.isChronic));
            }
            break;
          case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT:
          case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT_DATE:
          case CONTACT_TABLE_COLUMN_CODE.ADMITTED_DATE:
          case CONTACT_TABLE_COLUMN_CODE.DISCHARGE_DATE:
          case CONTACT_TABLE_COLUMN_CODE.DISCHARGE_TO:
          case CONTACT_TABLE_COLUMN_CODE.FACILITY:
          case CONTACT_TABLE_COLUMN_CODE.FACILITY_TYPE:
          case CONTACT_TABLE_COLUMN_CODE.ENROLLMENT_STATUS:
            if (!isCategoryIndexAlreadyAdded(CONTACT_SUB_DATA_FILTER.CARE_PROGRAM, promiseIndexWithCode)) {
              promiseIndexWithCode.push({
                index: promiseIndex,
                categoryIndex: CONTACT_SUB_DATA_FILTER.CARE_PROGRAM
              });
              promiseIndex += 1;
              const contactCareProgramQuery =
                categoryCode && TCM_FILTER_CODES?.includes(categoryCode)
                  ? getContactWiseCareProgramByCareProgramTypeId
                  : getContactWiseCareProgram;
                  
              promiseList.push(
                contactCareProgramQuery({
                  variables: {
                    contactIds: contactUuids,
                    ...(categoryCode &&
                      TCM_FILTER_CODES?.includes(categoryCode) && {
                        careProgramTypeId: tcmCareProgramTypeId,
                      }),
                  },
                })
              );
            }
            break;
          case CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER:
            if (!isCategoryIndexAlreadyAdded(CONTACT_SUB_DATA_FILTER.CARE_TEAM, promiseIndexWithCode)) {
              promiseIndexWithCode.push({
                index: promiseIndex,
                categoryIndex: CONTACT_SUB_DATA_FILTER.CARE_TEAM
              });
              promiseIndex += 1;
              const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);
              promiseList.push(getCareTeamMemberByMemberType({
                variables: {
                  contactIds: contactUuids,
                  careTeamTypeId: careTeamTypeId,
                }
              }));
            }
            break;
          case CONTACT_TABLE_COLUMN_CODE.CARE_PLAN_STATUS:
          case CONTACT_TABLE_COLUMN_CODE.CARE_STATUS:
            if (!isCategoryIndexAlreadyAdded(CONTACT_SUB_DATA_FILTER.CARE_PLAN, promiseIndexWithCode)) {
              promiseIndexWithCode.push({
                index: promiseIndex,
                categoryIndex: CONTACT_SUB_DATA_FILTER.CARE_PLAN
              });
              promiseIndex += 1;
              promiseList.push(new Promise((resolve, reject) => {
                resolve({data: []})
              }));
            }
            break;
          case CONTACT_TABLE_COLUMN_CODE.NEXT_APPOINTMENT:
            if (!isCategoryIndexAlreadyAdded(CONTACT_SUB_DATA_FILTER.APPOINTMENT, promiseIndexWithCode)) {
              const scheduledStatusId = appointmentStatusList?.find((status) => status?.code === APPOINTMENT_STATUS_CODES.BOOKED)?.id
              promiseIndexWithCode.push({
                index: promiseIndex,
                categoryIndex: CONTACT_SUB_DATA_FILTER.APPOINTMENT
              });
              promiseIndex += 1;
              promiseList.push(getFutureBookedAppointments({
                variables: {
                  contactIds: contactUuids,
                  startDateTime: getCurrentDateTimeMoment().toISOString(),
                  statusId: scheduledStatusId
                }
              }));
            }
            break;
          case CONTACT_TABLE_COLUMN_CODE.TIME_SPENT:
            if (!isCategoryIndexAlreadyAdded(CONTACT_SUB_DATA_FILTER.TIME_SPENT, promiseIndexWithCode)) {
              promiseIndexWithCode.push({
                index: promiseIndex,
                categoryIndex: CONTACT_SUB_DATA_FILTER.TIME_SPENT
              });
              promiseIndex += 1;
            }
              promiseList.push(getBillableActivitiesByMonth({
                variables: {
                  params: {
                    contactIds: contactUuids,
                     serviceStartDateTime: getCurrentDateTimeMoment().startOf('month').toISOString(),
                     serviceEndDateTime: getCurrentDateTimeMoment().endOf('month').toISOString(),
                    limit:10,
                    offset:0,
                    billableActivityStatusIds: getBillableActivityStatusIds()
                  }
                }
              }));
            break;
            case CONTACT_TABLE_COLUMN_CODE.LAST_VIST:
              if (
                !isCategoryIndexAlreadyAdded(
                  CONTACT_SUB_DATA_FILTER.LAST_VIST,
                  promiseIndexWithCode
                )
              ) {
                const scheduledStatusId = appointmentStatusList?.find(
                  (status) => status?.code === APPOINTMENT_STATUS_CODES.BOOKED
                )?.id;
                const pendingStatusId = appointmentStatusList?.find(
                  (status) => status?.code === APPOINTMENT_STATUS_CODES.PENDING
                )?.id;
                const cancelledStatusId = appointmentStatusList?.find(
                  (status) => status?.code === APPOINTMENT_STATUS_CODES.CANCELLED
                )?.id;
                promiseIndexWithCode.push({
                  index: promiseIndex,
                  categoryIndex: CONTACT_SUB_DATA_FILTER.LAST_VIST,
                });
                promiseIndex += 1;
                promiseList.push(
                  getAppointments({
                    variables: {
                      contactIds: contactUuids,
                      statusId: [
                        scheduledStatusId,
                        pendingStatusId,
                        cancelledStatusId,
                      ],
                    },
                  })
                );
              }
              break;
            case CONTACT_TABLE_COLUMN_CODE.AWV_DUE:
          case CONTACT_TABLE_COLUMN_CODE.HEALTH_PLAN:
            if (!isCategoryIndexAlreadyAdded(CONTACT_SUB_DATA_FILTER.CLAIM, promiseIndexWithCode)) {
              promiseIndexWithCode.push({
                index: promiseIndex,
                categoryIndex: CONTACT_SUB_DATA_FILTER.CLAIM
              });
              promiseIndex += 1;
              promiseList.push(new Promise((resolve, reject) => {
                resolve({data: []})
              }));
            }
            break;
        }
      }
      const promiseResponseList = await Promise.all(promiseList);
      return { responseList: promiseResponseList || [],  promiseIndexWithCodes: promiseIndexWithCode || [] };
    } catch(error) {

      return { responseList: [], promiseIndexWithCodes: []};
    }
  }

  const updateChronicCondition = (formattedContactData: any[], resourceList: any[]) => {
    for (const contactData of formattedContactData) {
      const additionalLoader = contactData?.additionalDataLoading || {};
      const patientId = contactData?.singleLeadData?.patient?.patientId || contactData?.patient?.patientId;
      const contactConditionResource = (resourceList || []).find((resource: any) => {
        return resource?.patientId === patientId;
      });
      if (contactConditionResource?.conditions?.total) {
        contactData.chronicCondition = getRecordListFormatted(contactConditionResource.conditions?.entry || [])
      } else {
        contactData.chronicCondition = [];
      }
      contactData.additionalDataLoading = {
        ...additionalLoader,
        chronicConditionLoading: false,
      }
    }
    return formattedContactData;
  }

  const updateCareProgramData = (formattedContactData: any[], careProgramResponse: any[]) => {
    const result = formattedContactData.map((contactData) => {
      const additionalLoader = contactData?.additionalDataLoading || {};
      const contactId = contactData?.uuid;
      const contactCarePrograms = (careProgramResponse || []).filter((careProgram: IContactCareProgramResponse) => {
        const isContactMatch = careProgram?.contactId === contactId;
        const careProgramCode = careProgram.payerCareProgram?.careProgramType?.code;
        const isTcmCareProgram = careProgramCode && CARE_PROGRAM_TYPES.TCM === careProgramCode;
        if (isContactMatch && isTcmCareProgram) {
          return (careProgram?.stepsLog || []).some(tcmProgramStep => {
            const tcmProgramStepCode = tcmProgramStep.careProgramStep?.careProgramStepType?.code;
            if (tcmProgramStepCode == CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY) {
              const expiryOn = tcmProgramStep?.expiresOn;
              const stepStatusCode = tcmProgramStep?.careProgramStepStatus?.code;
              const isStepExpired = stepStatusCode === CARE_PROGRAM_STEP_STATUS_CODES.EXPIRE;
              if (isStepExpired || !expiryOn) {
                return false;
              }
              return true;
            } else {
              return true;
            }
          })
        } else if (isContactMatch && !isTcmCareProgram) {
          return true;
        } else {
          return false;
        }
      });
      let contactCareProgramStepsFilter = [];
      if (contactCarePrograms?.length) {
        (contactCarePrograms || []).forEach((contactCareProgram: IContactCareProgramResponse) => {
          contactCareProgramStepsFilter = contactCareProgram.contactCareProgramSteps.filter(step => {
            return step?.displaySequenceNumber != -1;
          });
          contactCareProgram.contactCareProgramSteps = contactCareProgramStepsFilter;
        });
      }
      return {
        ...contactData,
        contactCarePrograms: contactCarePrograms,
        additionalDataLoading: {
          ...additionalLoader,
          careProgramLoading: false
        }
      }
    });
    return result;
  }

  const updateAppointmentData = (formattedContactData: any[], appointmentResponse: IAppointmentResponse[]) => {
    const result = formattedContactData.map((contactData) => {
      let latestAppointment;
      const contactId = contactData?.uuid;
      const contactIdApointmentData = (appointmentResponse || []).filter((appointmentResponseItem: any) => {
        return appointmentResponseItem?.participants[0]?.contactId === contactId;
      });

      if (contactIdApointmentData?.length) {
        latestAppointment = contactIdApointmentData?.[0];
      }

      return {
        ...contactData,
        appointmentData: latestAppointment,
      }
    });
    return result;
  }

  const updateLastVisitData = (
    formattedContactData: any[],
    appointmentResponse: IAppointmentResponse[]
  ) => {
    const result = formattedContactData.map((contactData) => {
      let latestAppointment;
      const contactId = contactData?.uuid;
      const contactIdApointmentData = (appointmentResponse || []).filter(
        (appointmentResponseItem: any) => {
          return (
            appointmentResponseItem?.participants[0]?.contactId === contactId
          );
        }
      );
      if (contactIdApointmentData?.length) {
        latestAppointment = contactIdApointmentData?.[0];
      }
      return {
        ...contactData,
        lastVisitAppointmentData: latestAppointment,
      };
    });
    return result;
  };

  const updateTimeSpentData = (
    formattedContactData: any[],
    timeSpentResponse: any[]
  ) => {
    const result = formattedContactData.map((contactData) => {
      const contactId = contactData?.uuid;
      const contactTimeSpentResponse = Array.isArray(timeSpentResponse)
        ? timeSpentResponse.filter(
            (timeSpentResponseItem: any) => timeSpentResponseItem?.contactId === contactId
          )
        : [];
      return {
        ...contactData,
        billableTimeSpentResponse: contactTimeSpentResponse,
      };
    });
    return result;
  }
  ;
  const updateCareTeamData = (formattedContactData: any[], careProgramResponse: ICareTeams [], accountUsers: IUser[]) => {
    for (const contactData of formattedContactData) {
      const additionalLoader = contactData?.additionalDataLoading || {};
      const contactId = contactData?.uuid;
      const careTeamMemberType: ICareTeams | undefined = (careProgramResponse || []).find((careTeam: ICareTeams) => {
        return careTeam?.contactId === contactId;
      });
      if (careTeamMemberType?.contactId) {
        careTeamMemberType.careTeamMemberType.forEach(careMember => {
          const findUser: IUser | undefined = (accountUsers || []).find((user: IUser) => {
            return user.uuid === careMember.userId;
          });
          if (findUser?.uuid) {
            careMember.userName = findUser?.name;
          }
        })
        contactData.careTeam = careTeamMemberType;
        contactData.additionalDataLoading = {
          ...additionalLoader,
          careTeamLoading: false
        }
      } else {
        contactData.additionalDataLoading = {
          ...additionalLoader,
          careTeamLoading: false
        }
        if(!contactData.careTeam) {
          contactData.careTeam = {};
        }
      }
    }
    return formattedContactData;
  }

  const configureCategoryWiseCountData = async (
    requestObjects: any[],
    categoryWiseResponse: { responseList: any[], promiseIndexWithCodes: { index: number, categoryIndex: number}[] },
    formattedContactData: any[],
    accountUsers: IUser[]
  ) => {
    let contactData = formattedContactData;
    (requestObjects || []).forEach(async(requestObject, index) => {
      const allCategoryResponse = getCategoryResponse(categoryWiseResponse.responseList, categoryWiseResponse.promiseIndexWithCodes, requestObject);
      switch (requestObject) {
        case CONTACT_SUB_DATA_FILTER.TAGS:
          contactData = updateTaggingDataInContactResponse(allCategoryResponse?.data?.labelTaggings || [], contactData);
          break;
        case CONTACT_SUB_DATA_FILTER.CONSENT:
          contactData = updateConsentDataInContactResponse(allCategoryResponse?.data?.contactConsents || [], contactData);
          break;
        case CONTACT_SUB_DATA_FILTER.CARE_TEAM:
          const careTeams: ICareTeams [] = allCategoryResponse?.data?.careTeams || [];
          contactData = updateCareTeamData(contactData, careTeams, accountUsers);
          break;
        case CONTACT_SUB_DATA_FILTER.CHRONIC_CONDITION:
          contactData = updateChronicCondition(contactData, allCategoryResponse?.data?.resources || []);
          break;
        case CONTACT_SUB_DATA_FILTER.CARE_PROGRAM:
          contactData = updateCareProgramData(contactData, allCategoryResponse?.data?.contactCarePrograms ||[]);
          break;
        case CONTACT_SUB_DATA_FILTER.APPOINTMENT:
          contactData = updateAppointmentData(contactData, allCategoryResponse?.data?.appointments ||[]);
          break;
        case CONTACT_SUB_DATA_FILTER.LAST_VIST:
          contactData = updateLastVisitData(contactData, allCategoryResponse?.data?.appointments ||[]);
          break;
        case CONTACT_SUB_DATA_FILTER.TIME_SPENT:
          contactData = updateTimeSpentData(contactData, allCategoryResponse?.data?.getBillableActivities?.records ||[]);
          break;
      }
    });
    return contactData;
  }

  const processContactResponseData = async (response: any) => {
    const responseData = response?.data?.data;
    const contactList = responseData?.contacts || [];
    const contactAggregateCount = responseData?.contactAggregate?.aggregate?.count;
    if (contactList?.length) {
      let contactData = formateLeadListData(responseData, commonContextData);
      setContactData(prev => {
        return {
          ...prev,
          contactList: contactData,
          totalCount: contactAggregateCount,
        };
      });
      setLoading(false);
      const contactIds = contactData.map((item: any) => item?.id);
      const contactUuids = contactData.map((item: any) => item?.uuid);
      const categoryWiseEnum: CONTACT_SUB_DATA_FILTER[] = getAfterFormattedContactApiCode();
      const accountData = await getAccountUsers({
        variables: {
          tenantId: accountUuid, roleCode: USER_ROLE_CODES.EMPLOYER
        }
      });
      const accountUsers: IUser[] = accountData?.data?.users || [];
      const categoryWiseResponse: { responseList: any[], promiseIndexWithCodes: { index: number, categoryIndex: number}[] } = await getResponseByRequestObject(contactData, contactUuids, contactIds);
      contactData = await configureCategoryWiseCountData(categoryWiseEnum, categoryWiseResponse, contactData, accountUsers)
      setContactData(prev => {
        return {
          ...prev,
          contactList: contactData,
          totalCount: contactAggregateCount,
          accountUsers: accountUsers
        };
      });
      if (categoryCode && TCM_FILTER_CODES?.includes(categoryCode)) {
        getUpcomingAppointmentData(contactData)
      }
    } else {
      setContactData(prev => {
        return {
          ...prev,
          contactList: [],
          totalCount: 0,
        };
      });
    }
  };

  const getContactIdWiseResponse = async (params: IGetContactIdWiseResponseParams) => {
    const filterParams = {
      ...(params?.careProgramStepStatusIds?.length && params?.careProgramStepTypeIds?.length && {
        careProgramStepTypeIds: params.careProgramStepTypeIds,
        careProgramStepStatusIds: params.careProgramStepStatusIds,
      }),
      ...(params?.careProgramTypeIds?.length && {
        careProgramTypeIds: params.careProgramTypeIds,
      }),
      ...(params?.ruleCodes?.length && {
        ruleCodes: params.ruleCodes,
      }),
      ...(params?.customFilterCode && {
        customFilterCode: params.customFilterCode,
      }),
      ...(params?.showContactsWithPendingAlerts && {
        showContactsWithPendingAlerts: params.showContactsWithPendingAlerts,
      }),
      ...(params?.carePlanStatusIds?.length && {
        carePlanStatusIds: params.carePlanStatusIds,
      }),
      ...((params?.isCarePlanNotAssigned != undefined) && {
        isCarePlanNotAssigned: params.isCarePlanNotAssigned,
      }),
    };
    const filter = getContactFilter(getContactFilterParams(filterParams));
    const orderById = {id: "desc"}
    if(args?.orderBy?.foldScore) {
      filter.orderBy = {contactScore: {foldScore: {sort:args?.orderBy?.foldScore,nulls:ORDER.LAST}}};
    }
    else if(args?.orderBy?.hccV24) {
      filter.orderBy = {contactScore: {hccV24: {sort:args?.orderBy?.hccV24,nulls:ORDER.LAST}}};
    }
    else if(args?.orderBy?.hccV28) {
      filter.orderBy = {contactScore: {hccV28: {sort:args?.orderBy?.hccV28,nulls:ORDER.LAST}}};
    }
    else if(args?.name) {
      filter.orderBy = {name: args?.name};
    }
    else{
      filter.orderBy = {contactScore: {foldScore: {sort: SORT_ORDER.DESC,nulls:ORDER.LAST}}};
    }
    filter.limit = pagination.pageSize;
    filter.offset = pagination.pageNumber || 0;
    const response = await searchContacts({
      variables: {
        params: {...filter, orderBy: [filter.orderBy, orderById]},
      },
    });
    const res = {
      data: {
        data: {
          contacts: response?.data?.searchContacts?.contacts || [],
          contactAggregate:
            response?.data?.searchContacts?.contactAggregate || {},
        },
      },
    };
    return res;
  };

  // const getUnderMyCareContacts = async () => {
  //   try {
  //     let contactIds: string[] = [];
  //     let underMyCareContactResponse: any = undefined;
  //     if (args?.filter?.formData?.careManagerId) {
  //       underMyCareContactResponse = await getUnderMyCareContactIdsWithCareTeamMemberTypeUserId({
  //         variables: {
  //           userId: currentUserId,
  //           memberTypeCode: CARE_TEAM_MEMBER_TYPE.CARE_MANAGER,
  //           memberTypeUserId: args?.filter?.formData?.careManagerId
  //         }
  //       });
  //     } else {
  //       underMyCareContactResponse = await getUnderMyCareContactIds({
  //         variables: {
  //           userId: currentUserId,
  //         },
  //       });
  //     }
  //     if (underMyCareContactResponse.data?.careTeams?.length) {
  //       contactIds = underMyCareContactResponse.data.careTeams.map(
  //         (result: {contactId: string}) => {
  //           return result.contactId;
  //         },
  //       );
  //     }
  //     if (!contactIds?.length) {
  //       setContactData(prev => {
  //         return {
  //           ...prev,
  //           contactList: [],
  //           totalCount: 0
  //         }
  //       });
  //       return;
  //     }
  //     if (categoryCode) {
  //       await getCategoryWiseData(
  //         categoryCode,
  //         contactIds,
  //         DASHBOARD_TAB_CODE.MY_PATIENT,
  //       );
  //     } else {
  //       const response = await getContactIdWiseResponse({contactIds: contactIds});
  //       await processContactResponseData(response);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  const getContactFilterParams = ({
    contactIds,
    careProgramStepTypeIds,
    careProgramTypeIds,
    careProgramStepStatusIds,
    ruleCodes,
    showContactsWithPendingAlerts,
    customFilterCode,
    carePlanStatusIds,
    isCarePlanNotAssigned
  }: IContactsFilterParams): IGetContactFilterParams => {
    return {
      filter: args?.filter,
      tabCode: args?.tabCode,
      accountId: accountId,
      commonContextData: commonContextData,
      patientList: contactIds,
      careProgramStepTypeIds: careProgramStepTypeIds || [],
      careProgramStepStatusIds: careProgramStepStatusIds || [],
      careProgramTypeIds: careProgramTypeIds || [],
      ruleCodes: ruleCodes || [],
      showContactsWithPendingAlerts: showContactsWithPendingAlerts || false,
      customFilterCode: customFilterCode || '',
      ...((carePlanStatusIds || [])?.length && {
        carePlanStatusIds: carePlanStatusIds,
      }),
      ...((isCarePlanNotAssigned != undefined) && {
        isCarePlanNotAssigned: isCarePlanNotAssigned,
      }),
    };
  };

  const getContacts = async () => {
    try {
      let contactIds: string[] = [];
      if (args?.filter?.formData?.careManagerId) {
        const underMyCareContactResponse = await getContactByMatchCareTeamMemberType({
          variables: {
            memberTypeCode: CARE_TEAM_MEMBER_TYPE.CARE_MANAGER,
            memberTypeUserId: args?.filter?.formData?.careManagerId
          }
        });
        if (underMyCareContactResponse.data?.careTeams?.length) {
          contactIds = underMyCareContactResponse.data.careTeams.map(
            (result: {contactId: string}) => {
              return result.contactId;
            },
          );
        }
        if (!contactIds?.length) {
          setContactData(prev => {
            return {
              ...prev,
              contactList: [],
              totalCount: 0
            }
          });
          return;
        }
      }
      if (categoryCode) {
        await getCategoryWiseData(
          categoryCode,
          contactIds,
          DASHBOARD_TAB_CODE.PATIENT,
        );
      } else {
        const response = await getContactIdWiseResponse({contactIds: contactIds});
        await processContactResponseData(response);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchContactsByFilter = async (selectedTabCode?: string) => {
    setLoading(true);
    setContactData(prev => {
      return {
        ...prev,
        contactList: [],
      }
    });
    getContacts()
    switch (tabCode || selectedTabCode) {
      case DASHBOARD_TAB_CODE.MY_PATIENT:
        await getContacts();
        setLoading(false);
        break;
      case DASHBOARD_TAB_CODE.PATIENT:
        await getContacts();
        setLoading(false);
        break;
    }
  };

  const getUpcomingAppointmentData = async (contactData: any) => {
    const contactDataList = [] as any[];
    contactData?.forEach((contact: any) => {
      if (contact?.contactCarePrograms) {
        const pcpData = contact?.careTeam?.careTeamMemberType?.find(
          (careTeamMemberType: any) =>
            careTeamMemberType?.memberType?.code === CARE_TEAM_MEMBER_TYPE.PCP
        );
        const contactCarePrograms = contact?.contactCarePrograms?.filter(
          (careProgram: any) =>
            careProgram?.payerCareProgram?.careProgramType?.code ===
            CARE_PROGRAM_TYPE_CODES.TCM
        );
        const dischargeDate = getAttributeByKey(
          ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE,
          contactCarePrograms?.[0]
        );
        if (pcpData?.userId && dischargeDate) {
          contactDataList.push({
            contactId: contact?.uuid,
            userId: pcpData?.userId,
            dischargeDate: dischargeDate,
          });
        }
      }
    });

    if (contactDataList?.length) {
      try {
        const upcomingAppointmentWhereCondition =
          getWhereConditionForUpcomingAppointmentData(contactDataList);

        const appointmentResponse = await getFutureAppointmentData({
          variables: {
            whereCondition: upcomingAppointmentWhereCondition,
          },
        });

        setContactData((prev) => {
          const updatedContactData = prev?.contactList?.map((contact: any) => {
            const additionalLoader = contactData?.additionalDataLoading || {};
            const contactIndex =
              appointmentResponse?.data?.appointments?.findIndex(
                (appointment: any) => {
                  return appointment?.participants?.some(
                    (participant: {contactId: string}) =>
                      participant?.contactId === contact?.uuid
                  );
                }
              );

            if (contactIndex !== -1 && !contact.pcpAppointmentData) {
              contact.pcpAppointmentData =
                appointmentResponse?.data?.appointments?.[contactIndex];
            }
            contact.additionalDataLoading = {
              ...additionalLoader,
              nextPCPAppointmentLoading: false
            }
            return contact;
          });
          return {
            ...prev,
            contactList: updatedContactData,
          };
        });
      } catch (e) {
        setContactData((prev) => {
          const updatedContactData = prev?.contactList?.map((contact: any) => {
            const additionalLoader = contactData?.additionalDataLoading || {};
            contact.additionalDataLoading = {
              ...additionalLoader,
              nextPCPAppointmentLoading: false
            }
            return contact;
          });
          return {
            ...prev,
            contactList: updatedContactData,
          };
        });
      }
    } else {
      setContactData((prev) => {
        const updatedContactData = prev?.contactList?.map((contact: any) => {
          const additionalLoader = contactData?.additionalDataLoading || {};
          contact.additionalDataLoading = {
            ...additionalLoader,
            nextPCPAppointmentLoading: false
          }
          return contact;
        });
        return {
          ...prev,
          contactList: updatedContactData,
        };
      });
    }
  };

  const getWhereConditionForUpcomingAppointmentData = (contactDataList: any[]) => {
    const appointmentStatusCodes = [APPOINTMENT_STATUS_CODES.CANCELLED, APPOINTMENT_STATUS_CODES.DECLINED]
    const appointmentStatusIds = [] as string[]
     appointmentStatusList?.forEach((status: any) => {
      if (!appointmentStatusCodes?.includes(status?.code)) {
        appointmentStatusIds.push(status?.id)
      }
    });
    const upcomingAppointmentWhereCondition: any = {
      statusId: {
        _in: appointmentStatusIds
      },
      _or: []
    };
    contactDataList.forEach((contact: any) => {
      const endDate = new Date(contact.dischargeDate);
      endDate.setDate(endDate.getDate() + 30);
      upcomingAppointmentWhereCondition._or.push({
        startDateTime: {
          _gt: contact.dischargeDate
        },
        endDateTime: {
          _lt: endDate,
        },
          _and: [
            {
              participants: {
              contactId: {
                _eq: contact?.contactId
              },
              participantType: {
                code: {
                  _eq: APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT
                }
              }
            }
            },
            {
              participants: {
              userId: {
                _eq: contact?.userId
              },
              participantType: {
                code: {
                  _eq: APPOINTMENT_PARTICIPANT_TYPE_CODES.PRIMARY_USER
                }
              }
            }
            }
          ]
      })
    });
    return upcomingAppointmentWhereCondition;
  }

  useEffect(()=> {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(EVENT_NAMES.UPDATE_PATIENT_LIST_APPOINTMENT_DATA, updateContactListAppointmentData);
    eventBus.addEventListener(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CARE_MANAGER_CHANGE, updateContactListCareTeamData);
    eventBus.addEventListener(CONVERSATION_LOCAL_EVENT_CODES.BULK_CONTACTS_CARE_MANAGER_CHANGE, updateBulkContactListCareTeamData);

    return () => {
      eventBus.removeEventListener(updateContactListAppointmentData);
      eventBus.removeEventListener(updateContactListCareTeamData);
      eventBus.removeEventListener(updateBulkContactListCareTeamData);
    };
  }, [])

  const updateContactListCareTeamData = (data: any) => {
    setContactData((prev)=> {
      const updatedContactData = prev?.contactList?.map((contact)=> {
        if (contact?.uuid === data?.contactUuid) {
          const careMember = data?.careTeam?.updatedCareTeamMemberType;
          const careTeamMemberTypes = contact.careTeam?.careTeamMemberType?.filter((careMemberType: any) => careMemberType?.careTeamMemberTypeId !== careMember?.careTeamMemberTypeId) || [];
          careTeamMemberTypes.push(careMember)
          contact.careTeam.id = data?.careTeam?.id;
          contact.careTeam.userPoolId = data?.careTeam?.userPoolId;
          contact.careTeam.contactId = data?.contactUuid;
          contact.careTeam.careTeamMemberType = careTeamMemberTypes;
        }
        return contact;
      });
      return {
        ...prev,
        contactList: updatedContactData
      }});
  }

  const updateBulkContactListCareTeamData = (data: any) => {
    setContactData((prev) => {
      const updatedContactData = updateCareTeamData(
        prev?.contactList,
        data?.careTeams,
        prev.accountUsers
      );
      return {
        ...prev,
        contactList: updatedContactData,
      };
    });
  }

  const updateContactListAppointmentData = (appointmentData: any) => {
    setContactData((prev)=> {
      const updatedContactData = prev?.contactList?.map((contact)=> {
        if (contact?.appointmentData?.id === appointmentData?.id) {
          contact.appointmentData = appointmentData
        }
        return contact;
      });
      return {
        ...prev,
        contactList: updatedContactData
      }});
  }

  useEffect(() => {
    fetchContactsByFilter();
  }, [
    args?.filter?.contactUpdated,
    args?.filter?.formData,
    args?.filter?.inboxItem,
    args?.filter?.loggedInStatus,
    args?.filter?.personType,
    args?.filter?.personTypeUuid,
    args?.filter?.searchText,
    args?.filter?.selectedUserPracticeLocation,
    args?.filter?.tab,
    args?.filter?.tagItem,
    args?.filter?.status,
    args?.pagination?.pageNumber,
    args?.pagination?.pageSize,
    args?.orderBy,
    categoryCode
  ]);

  return {
    loading,
    contactList: contactData?.contactList || [],
    count: contactData?.totalCount || 0,
    fetchContactsByFilter
  };
};
