import React from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import BillingFilterRow from './BillingFilterRow';
import {Colors} from '../../../styles/Colors';
import {useIntl} from 'react-intl';
import InfoSvg from '../../common/Svg/InfoSvg';
import Stack from '../../common/LayoutComponents/Stack';
import {Skeleton, Tooltip} from 'antd';
import CareProgramBillingTable from './CareProgramBillingTable';
import UseBillingTab from './BillingHooks/UseBillingTab';
import {BILLING_FILTER_ACTION_CODES} from './BillingHooks/BillingHooksConstants';
import SendClaimSvg from '../../common/Svg/SendClaimSvg';
import {Spinner} from 'native-base';

interface ICareProgramBilling {
  isBilledView?: boolean;
  serviceStartDateTime?: string;
  serviceEndDateTime?: string;
}

const CareProgramBilling = (props: ICareProgramBilling) => {
  const intl = useIntl();
  const {
    selectedFilters,
    handleActions,
    handleTableActions,
    paginationState,
    hookState,
    accountUserList,
    activitiesDataLoading,
    attributeAndCodesLoading,
    loadingProcessBilling,
    onChangeCustomer,
    contactsLoading
  } = UseBillingTab({
    isBilledView: props?.isBilledView,
    serviceStartDateTime: props?.serviceStartDateTime,
    serviceEndDateTime: props?.serviceEndDateTime,
  });

  const renderUnbilledAmount = (
    <View style={styles.unbilledAmountContainer}>
      <Stack direction={'row'} style={styles.unbilledAmountHeader}>
        <Text style={styles.unbilledAmountTitle}>
          {intl.formatMessage({id: 'totalUnbilledAmount'})}
        </Text>
        <Tooltip
          title={intl.formatMessage({id: 'unbilledAmountInfo'})}
          destroyTooltipOnHide={true}
        >
          <View style={styles.infoIconContainer}>
            <InfoSvg
              customDimension={'15'}
              customColor={Colors.FoldPixel.GRAY200}
            />
          </View>
        </Tooltip>
      </Stack>
      <Text style={styles.unbilledAmountValue}>
        $ {hookState.totalAmountSum}
      </Text>
    </View>
  );

  const renderSendClaimTOEHrButton = () => {
    return (
      <Stack
        style={styles.sendClaimContainer}
        direction={'row'}
        space={30}
      >
        <View>
          <Stack direction={'row'} style={styles.selectedBillsHeader}>
            <Text style={styles.selectedBillsTitle}>
              {hookState?.selectedBills?.length +
                ' ' +
                intl.formatMessage({id: 'selectedBillableAmount'}) +
                ' '}
            </Text>
          </Stack>
          <Text style={styles.selectedBillsAmount}>
            {hookState?.selectedBills?.reduce(
              (sum, currentValue) => sum + currentValue.totalAmount,
              0
            )}
          </Text>
        </View>
        <Pressable
          disabled={loadingProcessBilling}
          onPress={() =>
            handleActions(
              BILLING_FILTER_ACTION_CODES.SEND_CLAIM_TO_EHR,
              undefined
            )
          }
          style={styles.sendClaimButton}
        >
          <Stack
            direction={'row'}
            style={styles.sendClaimButtonContent}
            space={2}
          >
            {loadingProcessBilling ? (
              <Spinner />
            ) : (
              <>
                <SendClaimSvg />
                <Text style={styles.sendClaimButtonText}>
                  {intl.formatMessage({id: 'sendClaimTOEhr'})}
                </Text>
              </>
            )}
          </Stack>
        </Pressable>
      </Stack>
    );
  };

  return (
    <>
      {!props?.isBilledView && (
        <BillingFilterRow
          hookState={hookState}
          selectedFilters={selectedFilters}
          handleActions={handleActions}
          accountUserList={accountUserList}
          onChangeCustomer={onChangeCustomer}
          contactsLoading={contactsLoading}
        />
      )}
      {activitiesDataLoading && <Skeleton active />}
      {!activitiesDataLoading && (
        <>
          <Stack direction={'row'} style={styles.contentContainer}>
            {!props?.isBilledView && renderUnbilledAmount}
            {!!hookState?.selectedBills?.length &&
              hookState?.selectedBills?.length > 0 &&
              renderSendClaimTOEHrButton()}
          </Stack>
          <CareProgramBillingTable
            onPagination={(page: number, pageSize: number) =>
              handleActions(BILLING_FILTER_ACTION_CODES.PAGINATION, {
                page: page,
                pageSize: pageSize,
              })
            }
            pageNo={paginationState.pageNo}
            pageSize={paginationState.limit}
            total={paginationState.total}
            handleTableActions={handleTableActions}
            hookState={hookState}
            accountUserList={accountUserList}
            attributeAndCodesLoading={attributeAndCodesLoading}
            isBilledView={props?.isBilledView}
            handleActions={handleActions}
          />
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  unbilledAmountContainer: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.Custom.Gray200,
    backgroundColor: Colors.Custom.ContainerBGColor,
    padding: 12,
    width: '30%',
    margin: 12,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  unbilledAmountHeader: {
    alignItems: 'center',
  },
  unbilledAmountTitle: {
    marginBottom: 4,
    color: Colors.FoldPixel.GRAY250,
    fontWeight: '500',
    fontSize: 16,
    marginRight: 4,
  },
  infoIconContainer: {
    alignItems: 'center',
  },
  unbilledAmountValue: {
    marginBottom: 4,
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '500',
    fontSize: 22,
  },
  sendClaimContainer: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.Custom.PrimaryColor200,
    backgroundColor: Colors.Custom.purpleBackgroundContainer,
    padding: 12,
    width: '30%',
    margin: 12,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  selectedBillsHeader: {
    alignItems: 'center',
  },
  selectedBillsTitle: {
    marginBottom: 4,
    color: Colors.FoldPixel.GRAY200,
    fontWeight: '500',
    fontSize: 16,
    marginRight: 4,
  },
  selectedBillsAmount: {
    marginBottom: 4,
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '500',
    fontSize: 22,
  },
  sendClaimButton: {
    alignSelf: 'center',
  },
  sendClaimButtonContent: {
    backgroundColor: Colors.Custom.Primary300,
    alignSelf: 'center',
    alignItems: 'center',
    paddingVertical: 7.5,
    paddingHorizontal: 8,
    borderRadius: 4,
  },
  sendClaimButtonText: {
    fontWeight: '500',
    color: Colors.Custom.FontColorWhite,
    fontSize: 14,
  },
  contentContainer: {
    justifyContent: 'space-between',
  },
});

export default CareProgramBilling;
