import { View } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TagView } from '..';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../../constants';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { FlowType } from '../../../../context/WorkflowContext';
import { Colors } from '../../../../styles';
import { commonBodyContentContainer } from '../../../../styles/CommonBodyContentStyle';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import CustomTabsView from '../../../common/CustomTabsView/CustomTabsView';
import { ITabsList } from '../../../common/CustomTabsView/interfaces';
import PlusIcon from '../../../common/Svg/PlusSvg';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES
} from '../../../common/TableTopBar';
import CodeGroupsView from '../CodeGroups/CodeGroupsView';
import AutomationTopBar from './AutomationTopBar';
import { styles } from './Styles';
import CustomContactTypes from '../CustomContactTypes/CustomContactTypesView';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { TAB_KEYS } from '../../constants';
import {CustomFieldView} from '../CustomField/CustomFieldView';
import ImportContactLogView from '../ImportLog/ImportContactLogView';
import RuleScore from '../../RulesScore/RuleScore';
import {isDayOptimizerEnabled} from '../../../../utils/commonUtils';
const PatientsAdmin = () => {
  const location = useLocation();
  const commonData = useContext(CommonDataContext);
  const isDayOptimizerEnable = isDayOptimizerEnabled();
  const [stateData, setStateData] = useState({
    filtersApplied: [],
    selectedActionCode: '',
  });

  const labels = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );

  const filteredResult = labels?.filter((item) => {
    return item.code === 'CONTACT';
  });
  const mlov = filteredResult[0];
  const basePath = '/admin/patients';

  const [searchString, setSearchString] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleSearchChange = (actionData: any) => {
    setSearchString(actionData.searchString);
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: actionCode
          }
        })
        break;
      case TABLE_TOP_BAR_ACTION_CODES.CLOSE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: actionCode
          }
        })
        break;
      case COMMON_ACTION_CODES.SAVE:
        setStateData((prev) => {
          return {
            ...prev,
            filtersApplied: actionData
          }
        })
        break;
    }
  };

  const tagsTabList = (): ITabsList[] => {
    const orderDetailTabView: ITabsList[] = [
      // {
      //   key: 'automation',
      //   path: 'automation',
      //   title: 'Automations',
      //   tabContainerElem: () => {
      //     return (
      //       <View
      //         bgColor={'white'}
      //         borderWidth={1}
      //         borderColor={Colors.Custom.Gray200}
      //         borderRadius={8}
      //         style={{marginHorizontal:16}}
      //       >
      //         <WorkflowView
      //           showTableTopBar={false}
      //           flowType={FlowType.patients}
      //           searchText={searchString}
      //         />
      //       </View>
      //     );
      //   },
      // },
      {
        key: 'tags',
        path: 'tags',
        title: 'Tags',
        tabContainerElem: () => {
          return (
            <TagView
              searchString={searchString}
              openModal={openModal}
              category={mlov}
              showTableTopBar={false}
              onViewChangeCallback={() => {
                setOpenModal(false);
              }}
            />
          );
        },
      },
      {
        key: 'customContactTypes',
        path: 'customContactTypes',
        title: 'Custom Contact Types',
        tabContainerElem: () => {
          return (
            <CustomContactTypes
              searchString={searchString}
              openModal={openModal}
              showTableTopBar={false}
              onViewChangeCallback={() => {
                setOpenModal(false);
              }}
            />
          );
        },
      },
      {
        key: 'customField',
        path: 'customField',
        title: 'Custom Contact Fields',
        tabContainerElem: () => {
          return (
            <CustomFieldView
              searchString={searchString}
              openModal={openModal}
              onViewChangeCallback={() => {
                setOpenModal(false);
              }}
            />
          );
        },
      },
      {
        key: 'codeGroups',
        path: 'codeGroups',
        title: 'Code Groups',
        tabContainerElem: () => {
          return (
            <CodeGroupsView
              searchString={searchString}
              selectedActionCode={stateData.selectedActionCode}
              onActionPerformed={(actionCode, actionData) => {
                onTableTopBarActionPerformed(actionCode, actionData)
              }}
              openModal={openModal}
              category={mlov}
              showTableTopBar={false}
              onViewChangeCallback={() => {
                setOpenModal(false);
              }}
            />
          );
        },
      },
    ];
    if (isDayOptimizerEnable) {
      orderDetailTabView.push({
        key: 'rulesScore',
        path: 'rulesScore',
        title: 'Rules Score',
        tabContainerElem: () => {
          return <RuleScore />;
        },
      });
    }
    return orderDetailTabView;
  };
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);

  const TagBar = (selectedTabKey?: string): JSX.Element => {
    const buttonText = selectedTabKey === 'customField' ? 'addCustomField' : 'new'
    return (
      <View>
        <TableTopBar
          title={``}
          onActionPerformed={onTableTopBarActionPerformed}
          alignSearchBarFlexEnd={true}
          isDividerNotVisible={true}
          searchText={searchString}
          showFilterIcon={selectedTab.key === TAB_KEYS.CODE_GROUPS}
          appliedFilters={stateData?.filtersApplied || []}
          buttonList={[
            {
              btnText: buttonText,
              size: 'smBold',
              textColor: Colors.Custom.mainPrimaryPurple,
              borderColor: Colors.Custom.mainPrimaryPurple,
              backgroundColor: '#ffffff00',
              variant: BUTTON_TYPE.PRIMARY,
              leftIcon: <PlusIcon/>,
              btnClick: () => setOpenModal(true),
            },
          ]}
        />
    </View>
    )
  }

  const workflowUrl = `create?currentTag=TRIGGER&flowType=${
    FlowType.patients || ''
  }`;


  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
    setOpenModal(false);
    setSearchString('');
  };

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele = <></>;
    if (selectedTab.key === 'tags') {
      ele = TagBar();
    } else if (selectedTab.key === 'automation') {
      ele = <AutomationTopBar workflowUrl={workflowUrl} onTableTopBarActionPerformed={onTableTopBarActionPerformed}></AutomationTopBar>;
    }
    else if (selectedTab.key === 'codeGroups'){
      ele = TagBar()
    } else if (selectedTab.key === 'customContactTypes') {
      ele = TagBar()
    } else if (selectedTab.key === 'customField') {
      ele = TagBar(selectedTab.key)
    }
    return ele;
  };

  useEffect(() => {
    const tagsList = tagsTabList();
    tagsList.some((tab: any) => {
      if (location?.pathname?.indexOf(tab.path) != -1) {
        setSelectedTab(tab);
      }
    });
  }, []);




  return (
    <View>
      <View
        style={[
          styles.tabsWrapper,
          commonBodyContentContainer.pageContentContainer,
        ]}
      >
        <CustomTabsView
          showHeading
          heading='consumerSetting'
          contentTopMargin={0}
          extraBigRightView
          canNavigate={true}
          basePath={basePath}
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          rightView={() => {
            return getSelectedTopBar(selectedTab);
          }}
          bottomOffset={141}
          onTabClick={(tabItem) => {
            if(tabItem.key === TAB_KEYS.CODE_GROUPS){
              setStateData((prev) => {
                return {
                  ...prev,
                  filtersApplied: [],
                };
              });
            }
            onTabClick(tabItem);
          }}
        />
      </View>
    </View>
  );
};

export default PatientsAdmin;
