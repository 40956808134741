import { StyleSheet } from 'react-native';
import { Colors } from '../../../../styles';

export const styles = StyleSheet.create({
  commonFlexProp: {
    justifyItems: 'space-between',
    alignItems: 'center'
  },
  p4: {
    padding: 4,
  },
  p8: {
    padding: '8px',
  },
  pressable: {
    width: '100%',
    borderRadius: 4,
    borderWidth: 0.5,
    padding: '8px',
    borderStyle: 'solid',
    borderColor: Colors.FoldPixel.GRAY250,
  },
  pressable1: {
    borderRadius: 4,
    borderColor: Colors.FoldPixel.GRAY250,
    borderWidth: 0.5,
    margin: 0,
    paddingHorizontal: '19px',
    paddingVertical: '5px',
  },
  iconBtnOpen: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 0,
  },
  iconBtnClose: {
    borderRadius: 4,
    padding: 4,
    marginRight: 4,
  },
  dFlexFdRowJcCenterAiCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  w100: {
    width: '100%',
  },
  p0_m0: {
    padding: 0,
    margin: 0,
  },
  p0_mr4: {
    padding: 0,
    marginRight: 4,
  },
  rafModalText: {
    fontSize: 14,
    minWidth: 52,
    width: '100%',
    textAlign: 'center',
    fontWeight: '400',
    paddingLeft: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  threeDotsActionPopover: {
    padding: 4,
    margin: 0,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
  },
  doFilterBadge: { 
    position: 'absolute',
    top: -5,
    right: -4,
    zIndex: 1000,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    width: 14,
    height: 14,
    backgroundColor: Colors.Custom.badgeColorOrange
  },
  doFilterBadgeText: {
    color: Colors.Custom.White,
    fontWeight: '600',                              
    fontSize: 10,
    textAlign: 'center',
  }
});


export const cssPropertiesAntD: Record<string, React.CSSProperties> = {
  patientListingDivider: {
    margin: 0,
    borderWidth: '0.5px',
    borderColor: Colors.FoldPixel.GRAY150,
  },
  borderRadius8: {
    borderRadius: 8,
  },
  divider: {
    margin: '8px 0',
    padding: 0,
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY150,
  },
  DownOutlinedIcon: {
    fontSize: 12
  },
  fullHeight: {
    height: '100%',
  },
  p8_12_0_12: {
    padding: '8px 12px 0px 12px',
  },
  p0: {
    padding: 0,
  },
  w350px: {
    width: '350px',
  },
  colorGray300Fs12Fw500: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 12,
    fontWeight: '500',
  },
  br16Bw0p5SolidGray100: {
    borderRadius: 16,
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: Colors.FoldPixel.GRAY100,
  },
  text: {
    marginLeft: '4px',
    fontSize: '14px',
    fontWeight: 500,
    color: Colors.FoldPixel.GRAY300,
  },
  dateLabelListingHeader: {
    margin: '0 8px',
    fontWeight: 500,
    fontSize: 14,
    color: Colors.FoldPixel.GRAY400,
    minWidth: '180px',
    textAlign: 'right'
  },
  scrollableDiv: {
    width: '100%',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  } as React.CSSProperties,
  flexGrow1OverflowHidden: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  divider1: {
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY150,
    margin: '8px 0',
  }
};
