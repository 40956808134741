import { StyleSheet } from "react-native";
import { Colors } from "../../../../../../../styles";

export const doFilterStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingVertical: 12
  },
  filterByText: {
    fontSize: 14,
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '600',
    marginLeft: 8,
    marginBottom: 6
  }
});
