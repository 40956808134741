import {Drawer, Select, Select as MultiSelect, Tag, Spin} from 'antd';
import {debounce} from 'lodash';
import {
  Button,
  FormControl,
  HStack,
  Input,
  Text,
  useMediaQuery,
  View,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {Colors} from '../../../../styles';
import { getBooleanFeatureFlag, getUserUUID } from '../../../../utils/commonUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {ModalActionAntSelect} from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import UserWithRoleItem from '../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/UserWithRoleItem';
import { ALL_USERS_CODE } from '../../UserScheduleSetting/UserScheduleHelper';
import {
  IAddPoolModalProps,
  ITaskPoolTableData,
  IUser,
} from '../TaskInterfaces/TaskInterface';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { ModuleCodes } from '../../../CustomHooks/useModuleSpecificUserLocations';
import LocalStorage from '../../../../utils/LocalStorage';
import { useLazyQuery } from '@apollo/client';
import { GET_USERS_BY_RESOURCE_CODES } from '../../../../services/User/UserQueries';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import { TestIdentifiers } from '../../../../testUtils/TestIdentifiers';
import { testID } from '../../../../testUtils/Utils';
import { usePermissions } from '../../../CustomHooks/usePermissions';
import { USER_ACCESS_PERMISSION } from '../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { StyleSheet } from 'react-native';
import { USER_ROLE_CODES } from '../../../../constants/MlovConst';
const {Label} = FormControl;
const { Option } = Select;

interface IComponentState {
  locations: string[];
  userUuids: string[];
  usersDataList: IUser[];
  loading: boolean;
  usersDataByAccountLocation: IUser[];
  usersListOfLoggedInUser: IUser[];
  userIdsOfOtherThanLoggedInUser: string[];
}

const AddPoolModal = (props: IAddPoolModalProps) => {
  const {isModalOpen, onModalClose, onSave, title, isUserDataLoading} = props;
  const commonData = useContext(CommonDataContext);
  const {accountLocationListWithEHR} = commonData;
  const accountLocationIds = accountLocationListWithEHR?.map(location => location.uuid);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const currentUserUUID = getUserUUID();
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.TASKS);
  const allowedAccountLocations = accountLocationListWithEHR?.filter((location) => {
    return permissionConfig?.allowedLocationIds?.includes(location?.uuid)
  })?.map((location) => {
    return {
      ...location,
      uuid: location?.uuid,
      name: location?.practiceLocation?.name,
    }
  }) || [];

  const isLocationDisabled = () => {
    if (props?.isEdit && props?.selectedTaskData?.userPoolLocations?.length > 0) {
      const filteredLocations = props?.selectedTaskData?.userPoolLocations
        .map((location: any) => location?.locationId)
        .filter((locationId: any) => {
          return allowedAccountLocations.some(
            (allowedLocation: any) => allowedLocation.uuid === locationId
          );
        });     
      return filteredLocations?.length !== props?.selectedTaskData?.userPoolLocations?.length;
    }
    return false;
  };

  const getUserNameByUuid = (uuid: string) => {
    const userInList = componentState?.usersDataList?.find(user => user?.uuid === uuid);
    if (userInList) {
      return userInList?.name;
    } 
    const userInAccountLocation = componentState?.usersDataByAccountLocation?.find(user => user?.uuid === uuid);
    if (userInAccountLocation) {
      return userInAccountLocation?.name;
    } 
    return null;
  };

  const getLocationNameByUuid = (uuid: string) => {
    const allowedLocation = allowedAccountLocations.find(loc => loc.uuid === uuid);
    if (allowedLocation) {
      return allowedLocation.name;
    }
    const ehrLocation = accountLocationListWithEHR?.find(loc => loc?.uuid === uuid);
    if (ehrLocation) {
      return ehrLocation?.practiceLocation?.name;
    }
    return null;
  };
  const locationsForEditCase =  props?.selectedTaskData?.userPoolLocations?.map((location: any) => {return location?.locationId})
  const filteredLocations= isMultiTenancyEnabled ? locationsForEditCase?.filter((uuid: string) => getLocationNameByUuid(uuid) !== null) : locationsForEditCase;

  const [componentState,setComponentState] = useState<IComponentState>({
    locations:
      props?.isEdit && props?.selectedTaskData?.userPoolLocations.length > 0
        ? filteredLocations
        : allowedAccountLocations?.length === 1
        ? [allowedAccountLocations[0]?.uuid]
        : [],
    userUuids: [],
    usersDataList: [],
    loading: false,
    usersDataByAccountLocation : [],
    usersListOfLoggedInUser : [],
    userIdsOfOtherThanLoggedInUser: []
  });
  const [newPoolName, setNewPoolName] = useState(
    props?.selectedTaskData?.name ? props.selectedTaskData.name : ''
  );
  const intl = useIntl();
  const isCurrentUserInTheAllowedList = props?.usersData?.find((user: any) => user.uuid === currentUserUUID)
  const userData =
    props?.selectedTaskData?.userPoolUsers &&
    props?.selectedTaskData?.userPoolUsers.length > 0
      ? props?.selectedTaskData?.userPoolUsers
          .map((data: any) => data.userId)
          ?.filter((item: any) => !!item)
      : [];
  const defaultAssignee = props.selectedTaskData?.userPoolUsers.find((data: any) => data.isDefault)?.userId || 'unassigned';
  const [newPoolUsers, setNewPoolUsers] = useState(userData);
  const [getUserUuidFromPracticeLocationUuid] = useLazyQuery(
    GET_USERS_BY_RESOURCE_CODES,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [newPoolAssignee, setNewPoolAssignee] = useState(defaultAssignee);
  const [newPoolUsersArray, setNewPoolUsersArray] = useState<any>([
    {
      name: 'Unassigned',
      uuid: 'unassigned',
    },
  ]);
  const [tempNewPoolUsersArray, setTempNewPoolUsersArray] = useState<any>([]);
  const [customerLoading, setCustomerLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({
    newPoolNameError: '',
    newPoolSelectUserError: '',
    newPoolSelectAssigneeError: '',
    location: ''
  });

  const validate = () => {
    setErrors({});
    if(isMultiTenancyEnabled && componentState?.locations?.length === 0){
      setErrors({location: 'Please fill the location'});
      return false;
    }
    if (newPoolName.trim().length === 0) {
      setErrors({newPoolNameError: 'Please fill this field'});
      return false;
    }

    if (!newPoolUsers.length) {
      setErrors({newPoolSelectUserError: 'Please fill this field'});
      return false;
    }

    if (!newPoolAssignee) {
      setErrors({newPoolSelectAssigneeError: 'Please fill this field'});
      return false;
    }

    return true;
  };

  const handleAddNewPool = () => {
    if (validate()) {
      const updatedLocations = componentState.locations.map(locationId => ({
        locationId,
      }));
      const selectedLocationIds = new Set(updatedLocations.map(location => location.locationId));
      const deletedLocations = props?.selectedTaskData?.userPoolLocations?.filter((location: any) => !selectedLocationIds.has(location?.locationId));
      const finalUpdatedLocations: ITaskPoolTableData['poolLocations'] = updatedLocations;
      if (deletedLocations?.length) {
        deletedLocations?.forEach((location: { id: string; locationId?: string }) => {
          finalUpdatedLocations.push({
            locationId: location?.locationId,
            isDeleted: true
          })
        })
      }
      const newPool: ITaskPoolTableData = {
        newPoolName,
        newPoolUsers,
        poolId: props.selectedTaskData?.id,
        poolUsers: props.selectedTaskData?.userPoolUsers,
        isDefault: newPoolAssignee,
        poolLocations: finalUpdatedLocations
      };

      onSave(newPool);
      setNewPoolName('');
      setNewPoolAssignee('unassigned');
      setNewPoolUsersArray([]);
      setErrors({});
      onModalClose();
    }
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const {width} = Dimensions.get('window');
  const finalWidth = isIPadMiniScreen || isIPadScreen || isSidecarContext ? width * 0.6 : width / 3;

  const fetchUsersUUIdByAccountLocationUuid = async () => {
    setComponentState((prevState) => {
      return {
        ...prevState,
        loading: true
      };
    });
    const response = await getUserUuidFromPracticeLocationUuid({
      variables: {
        params: {
          isActive: true,
          accountLocationId: accountLocationIds
        },
      },
    });
    setComponentState((prevState) => {
      return {
        ...prevState,
        usersDataByAccountLocation: response?.data?.searchUsers?.users,
        loading: false
      };
    });
  }

  const fetchUsersUUIdByPracticeLocationUuid = async () => {
    setComponentState((prevState) => {
      return {
        ...prevState,
        loading: true
      };
    });
    const response = await getUserUuidFromPracticeLocationUuid({
      variables: {
        params: {
          userRoleCodesNotIn: [USER_ROLE_CODES.WORFLOW_USER, USER_ROLE_CODES.CUSTOMER_SUCCESS, USER_ROLE_CODES.EMPLOYER, USER_ROLE_CODES.WORKFLOW],
          isActive: true,
          ...(isMultiTenancyEnabled && {
            permittedFor: {
              anyOf: componentState?.locations
                ?.filter(locationId => allowedAccountLocations.some(location => location.uuid === locationId))
                .map(locationId => ({
                  resourceCode: `${USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}/${MAIN_MENU_CODES.TASKS}`,
                  accountLocationId: locationId
                }))
            }
          })       
        },
      },
    });
    const filteredUsers = props.usersData.filter((userItem: any) =>
      response?.data?.searchUsers?.users.some(
        (locationUser: any) => locationUser.uuid === userItem.uuid
      )
    );
    const filteredUserIds = filteredUsers.map((userItem: any) => userItem.uuid).filter((id: string) => !!id);
    const newFilteredPoolUsers = newPoolUsers.filter((userId: string) => filteredUserIds.includes(userId));
    const usersListOfOtherThanLoggedInUser = newPoolUsers.filter((userId: string) => !filteredUserIds.includes(userId));
    const filteredUsersDataListBasedOnLocation = response?.data?.searchUsers?.users.filter((user:any) => {
      return !usersListOfOtherThanLoggedInUser.includes(user.uuid);
    });
    setComponentState((prevState) => {
      return {
        ...prevState,
        usersDataList: isMultiTenancyEnabled ? filteredUsersDataListBasedOnLocation : props.usersData,
        loading: false,
        usersListOfLoggedInUser: response?.data?.searchUsers?.users,
      };
    });
    const defaultAssigneeUser = newPoolAssignee && newFilteredPoolUsers.find((userItem: any) =>
      newPoolAssignee === userItem
    );
    if (isMultiTenancyEnabled && !defaultAssigneeUser) {
      setNewPoolAssignee('unassigned');
    }
    const updatedUserPoolUsersArray = filteredUsers.filter((user: any) => newFilteredPoolUsers.includes(user.uuid));
    isMultiTenancyEnabled && setNewPoolUsersArray(updatedUserPoolUsersArray);
  };

  useEffect(()=>{
    if(isMultiTenancyEnabled) {
    fetchUsersUUIdByAccountLocationUuid();
    }
  },[]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      if (!isMultiTenancyEnabled || componentState?.locations?.length > 0) {
        await fetchUsersUUIdByPracticeLocationUuid();
        
      } else {
        setComponentState((prevState) => {
          return {
          ...prevState,
          usersDataList: [],
        }});
        setNewPoolUsers([]);
      }
    }; 
    fetchDataAsync();
  }, [componentState?.locations]);

  useEffect(() => {
    let selectedUserIds =
      props.selectedTaskData?.userPoolUsers &&
      props.selectedTaskData?.userPoolUsers.length > 0
      ? props.selectedTaskData?.userPoolUsers.map((data: any) => data.userId): [];
    selectedUserIds = selectedUserIds?.filter((item: string) => !!item);
    setNewPoolUsers(selectedUserIds);
    // const updatedUserPoolUsersArray = newPoolUsersArray;
    const selectedUserData = props.usersData.filter((userItem: any) =>
      selectedUserIds.includes(userItem.uuid)
    );
    // selectedUserData.map((selectedUserItem: any) => {
    //   const isExistData = newPoolUsersArray.filter(
    //     (dataItem: any) => dataItem.uuid === selectedUserItem.uuid
    //   );
    //   if (!(isExistData && isExistData.length > 0)) {
    //     updatedUserPoolUsersArray.push(selectedUserItem);
    //   }
    // });

    setNewPoolUsersArray(selectedUserData);
  }, [props.selectedTaskData]);



  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const label =  isMultiTenancyEnabled ? getLocationNameByUuid(tagProps.value) : tagProps.label;
    if (isMultiTenancyEnabled && !label) {
      return <></>;
    }
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={tagProps?.closable}
        onClose={tagProps.onClose}
        style={{marginRight: 3}}
        className={'custom-tag'}
      >
        {label}
      </Tag>
    );
  };

  const tagRenderUser = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    let label = isMultiTenancyEnabled ? getUserNameByUuid(tagProps.value) : tagProps.label;
    if (!label) {
      const userDataMatch = props.usersData.find((user: any) => user.uuid === tagProps.value);
      if (userDataMatch) {
        label = userDataMatch.name;
      }
    }
    const isUserFromLoggedInUser = componentState.usersListOfLoggedInUser.some(user => user.uuid === tagProps.value);
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={isUserFromLoggedInUser}
        onClose={tagProps.onClose}
        style={{marginRight: 3}}
        className={'custom-tag'}
      >
        {label}
      </Tag>
    );
  };

  const onChangeCustomer = (searchText: string) => {
    setCustomerLoading(true);

    if (searchText) {
      if (!tempNewPoolUsersArray.length)
        setTempNewPoolUsersArray(newPoolUsersArray);
      const updatedUserPoolUsersArray: any =
        tempNewPoolUsersArray.length > 0
          ? [
              ...tempNewPoolUsersArray.filter((userItem: any) => {
                return String(userItem.name)
                  .toLocaleLowerCase()
                  .includes(String(searchText).toLocaleLowerCase());
              }),
            ]
          : [
              ...newPoolUsersArray.filter((userItem: any) => {
                return String(userItem.name)
                  .toLocaleLowerCase()
                  .includes(String(searchText).toLocaleLowerCase());
              }),
            ];
      setNewPoolUsersArray(updatedUserPoolUsersArray);
    } else {
      const updatedUserPoolUsersArray = newPoolUsersArray;
      const selectedUserData = props.usersData.filter((userItem: any) =>
        newPoolUsers.includes(userItem.uuid)
      );
      selectedUserData.map((selectedUserItem: any) => {
        const isExistData = newPoolUsersArray.filter(
          (dataItem: any) => dataItem.uuid === selectedUserItem.uuid
        );
        if (!(isExistData && isExistData.length > 0)) {
          updatedUserPoolUsersArray.push(selectedUserItem);
        }
      });
      const filterUnAssigned = updatedUserPoolUsersArray.filter(
        (dataItem: any) => dataItem.uuid === 'unassigned'
      );
      if (!filterUnAssigned.length) {
        updatedUserPoolUsersArray.unshift({
          name: 'Unassigned',
          uuid: 'unassigned',
        });
      }

      setTempNewPoolUsersArray([]);
      setNewPoolUsersArray(updatedUserPoolUsersArray);
    }

    setCustomerLoading(false);
  };

  const handleUsersChange = (values: string[], selectedData: any[]) => {
    setCustomerLoading(true);
    const userIds = selectedData.map(item => item.userId).filter(id => !!id);
   setNewPoolUsers(userIds);

    const selectedUserData = props.usersData.filter((userItem: any) => userIds.includes(userItem.uuid));
    const defaultAssigneeUser = newPoolAssignee && selectedUserData.find((userItem: any) => newPoolAssignee === userItem.uuid);
    if (!defaultAssigneeUser) {
      setNewPoolAssignee('unassigned');
    }

    setNewPoolUsersArray(selectedUserData);
    setCustomerLoading(false);
}

  const handleLocationChange = (newLocations: string[]) => {
    setComponentState(prevState => ({ ...prevState, locations: newLocations }));
  };

  return (
    <Drawer
      width={isSidecarContext ? '100%' : finalWidth}
      mask={isSidecarContext ? false : true}
      visible={isModalOpen}
      onClose={() => {
        setComponentState((prevState) => {
          return {
            ...prevState,
            locations: [],
            usersDataList: [],
          };
        });
        setNewPoolName('');
        setNewPoolUsers([]);
        setNewPoolAssignee('unassigned');
        setNewPoolUsersArray([]);
        setErrors({});
        onModalClose();
      }}
      title={
        <>
          <HStack style={styles.hStackContainer}>
            <ModalActionTitle isHeadNotSticky={true} title={title} titleColor={''}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: isSidecarContext ? 'back' : 'cancel',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    setNewPoolName('');
                    setNewPoolUsers([]);
                    setErrors({});
                    onModalClose();
                    setComponentState((prevState) => {
                      return {
                      ...prevState,
                      locations: [],
                      usersDataList: []
                    }});
                  },
                },
                {
                  show: true,
                  id: 2,
                  btnText: 'save',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: () => {
                    handleAddNewPool();
                  },
                },
              ]}
            />
          </HStack>
        </>
      }
    >
      <>
      {isUserDataLoading ? <Spin size="small" /> : (
      <View>
        { isMultiTenancyEnabled && (
          <FormControl marginBottom={2}>
            <FormControl.Label isRequired isInvalid={errors.location} >
              <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={'location'} />
            </FormControl.Label>

            <MultiSelect
              mode="multiple"
              size="large"
              allowClear={isMultiTenancyEnabled ? false : true}
              disabled={isMultiTenancyEnabled && isLocationDisabled()}
              value={componentState.locations}    
              optionFilterProp="label"
              optionLabelProp="label"
              tagRender={tagRender}
              placeholder="Select location"
              onChange={handleLocationChange}
              maxTagCount={'responsive'}
              {...testID(TestIdentifiers.addTaskPool.locationSelect)}
            >
              {allowedAccountLocations.map((record) => {
                return (
                  <Option
                    key={record?.uuid}
                    userId={record?.uuid}
                    value={record?.uuid}
                    label={record?.name}
                  >
                    {record?.name}
                  </Option>
                );
              })}
            </MultiSelect>
            {errors?.location && (
              <Text size={'smLight'} color="error.500">
                {errors?.location}
              </Text> 
          )}
          </FormControl>
        )}
        <FormControl>
          <Label>
            <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={'taskPoolName'} />
            <Text style={styles.requiredAsterisk}>*</Text>
          </Label>
          <Input
            _focus={{
              borderColor: Colors.Custom.Gray200,
            }}
            size={'mdNormal'}
            value={newPoolName}
            onChangeText={(text: string) => setNewPoolName(text)}
          />
          <Text size={'smLight'} color="error.500">
            {errors?.newPoolNameError}
          </Text>
        </FormControl>
        <FormControl marginTop={2}>
          <Label>
            <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={'taskPoolUsers'} />
            <Text style={styles.requiredAsterisk}>*</Text>
          </Label>
          <MultiSelect
            mode="multiple"
            size="large"
            allowClear={isMultiTenancyEnabled ? false : true}
            //defaultValue={getUsersName([...defaultValue], dataIs)}
            notFoundContent={componentState.loading ? <Spin size="small" /> : null}
            value={ componentState.loading ? [] : newPoolUsers}
            optionFilterProp="label"
            optionLabelProp="label"
            tagRender={tagRenderUser}
            placeholder={componentState.loading ? "Loading..." : "Select Users"}
            onChange={handleUsersChange}
            maxTagCount={'responsive'}
          >
            {componentState?.usersDataList?.map((agentRecord: any) => {
              return (
                <Option
                  key={agentRecord?.uuid}
                  userId={agentRecord?.uuid}
                  value={agentRecord?.uuid}
                  label={agentRecord?.name}
                >
                  <UserWithRoleItem agentRecord={agentRecord} />
                </Option>
              );
            })}
          </MultiSelect>

          <Text size={'smLight'} color="error.500">
            {errors?.newPoolSelectUserError}
          </Text>
        </FormControl>
        <FormControl marginTop={2}>
          <ModalActionAntSelect
            allowClear={false}
            showSearch={true}
            leftMargin={'0'}
            onSearch={debounce(onChangeCustomer, 500)}
            labelInValue={true}
            filterOption={false}
            defaultValue={newPoolAssignee}
            value={newPoolAssignee}
            isRequired={false}
            label={intl.formatMessage({
              id: 'taskPoolDefaultAssignee',
            })}
            notFoundContent={intl.formatMessage({
              id: 'NoDataSelect',
            })}
            placeholder={intl.formatMessage({
              id: 'taskPoolDefaultAssignee',
            })}
            onChange={(value: any) => {
              setNewPoolAssignee(value?.key);
            }}
            data={newPoolUsersArray}
            loading={customerLoading}
            optionProps={{key: 'uuid', value: 'uuid', label: 'name'}}
            extraStyle={{flex: 1}}
          />
        </FormControl>

        {/* {!props.isEdit && (
          <FormControl>
            <Label>
              <DisplayText
                size={'mdNormal'}
                textLocalId={'taskPoolDefaultAssignee'}
              />
            </Label>

            <Select
              defaultValue={newPoolAssignee}
              value={newPoolAssignee}
              onChange={(selectedUser: any) => {
                setNewPoolAssignee(selectedUser);
              }}
            >
              <Select.Option value={'unassigned'}>Unassigned</Select.Option>
              {newPoolUsersArray.map((userItem: any) => {
                return (
                  <Select.Option value={userItem.uuid}>
                    {userItem.name}
                  </Select.Option>
                );
              })}
            </Select>
            <Text size={'smLight'} color="error.500">
              {errors?.newPoolSelectAssigneeError}
            </Text>
          </FormControl>
        )} */}
      </View>
      )}
      </>
    </Drawer>
  );
};

const styles = StyleSheet.create({
  hStackContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  requiredAsterisk: {
    color: 'red',
  },
});

export default AddPoolModal;
