import {StyleSheet} from "react-native";
import {Colors} from "../../../../styles";

export const ruleScoreStyles = StyleSheet.create({
  ruleName: {
    color: Colors.Custom.Gray500
  },
  rowColumnEntry: {
    color: Colors.Custom.Gray400
  },
  columnDataCommon: {
    fontSize: 14,
    fontFamily: 'Manrope-SemiBold',
    fontWeight: '500',
  }
})