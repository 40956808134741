import { Drawer, Radio, Select as AntSelect, Skeleton } from "antd";
import { LLMTemplateViewCodes, LLM_VIEW_ROLE } from "../llmTemplatesConsts";
import { HStack, Pressable, VStack, Text, Input, FormControl, useToast, ScrollView, Icon } from "native-base";
import TitleSubtitleView from "../../../../common/TitleSubtitleView/TitleSubtitleView";
import Feather from "react-native-vector-icons/Feather";
import { Colors } from "../../../../../styles/Colors";
import React, { useEffect, useState } from "react";
import { COMMON_ACTION_CODES } from "../../../../../constants/ActionConst";
import { GetCloseAndContinue } from "../Helper";
import { styles } from '../llmTemplateStyles'
import { CONTEXT_PROMPT_TYPE, SingleLLMTemplate } from "../interfaces";
import { createNewLLMTemplate, updateLLMTemplate } from "../llmTemplateUtils";
import DraggableList from "./DraggableList";
import DragHandleIcon from "../../../../../assets/Icons/DragHandleIcon";
import TrashBin2Icon from "../../../../../assets/Icons/TrashBin2Icon";
import { ToastType, showToast } from "../../../../../utils/commonViewUtils";
import { useIntl } from "react-intl";
import { CaretDownOutlined, UpOutlined } from '@ant-design/icons';
import { getAccountUUID, getUserId, getUserUUID, isLanguageModelComponentVisible, isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner } from "../../../../../utils/commonUtils";
import { getContextPrompt } from "../../../../common/GPTPrompt/GPTPromptAPI";
import ReactMarkdown from 'react-markdown';
const { Option } = AntSelect;
const LLM_LANGUAGE_MODELS = [
  {
    label: 'Claude 3.5 Sonnet',
    value: 'claude-3-5-sonnet-latest'
  },
  {
    label: 'GPT-4o',
    value: 'gpt-4o'
  }
]
const GPT_4O = 'gpt-4o';

export const AddOrUpdateLLMTemplates = (props: {
  viewCode: string;
  onActionPerformed: any;
  template?: SingleLLMTemplate;
  id?: string;
  adminAccess: boolean;
  userList: string[];
  roleList: string[];
}) => {
  type TemplateSectionIndex = number;
  const isBusinessOwner = isLoginUserBusinessOwner() || isLoggedInUserGlobalAdmin() || isLoggedInUserWorkFlowOrCustomerSuccess()
  const [isActive, setIsActive] = useState<boolean>(true);
  const [templateName, setTemplateName] = useState<string>('');
  const [accessRole, setAccessRole] = useState(props?.template?.accessRole || LLM_VIEW_ROLE.PRIVATE);
  const [languageModel ,  setLanguageModel] = useState<string>(props.template?.languageModel || GPT_4O)
  const [templateMapList, setTemplateMapList] = useState<any>([{ title: '', description: '' }]);
  const [selectedUsers, setSelectedUsers] = useState(props.template?.userIds || [])
  const [selectedRoles, setSelectedRoles] = useState<string[]>(props.template?.userRoleIds || [])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isSubmitPresssed, setIsSubmitPressed] = useState(false)
  const [isValidName, setIsValidName] = useState(true)
  const [isValidRole, setIsValidRole] = useState(true)
  const [isValidUser, setIsValidUser] = useState(true)
  const [systemContextPrompt, setSystemContextPrompt] = useState<string>('');
  const [userContextPrompt, setUserContextPrompt] = useState<string>('');
  const isDefault = props?.template?.isDefault || false
  const userUUID = getUserUUID()
  const createdByLoginUser = ((userUUID === props?.template?.createdBy) || (!props?.template?.id))
  const [isLoading, setIsLoading] = useState(true);
  const [expandedUserContext, setExpandedUserContext] = useState(false);
  const [expandedSystemContext, setExpandedSystemContext] = useState(false);
  const accountUUID = getAccountUUID();
  const userId: number | string = getUserId();
  const toast = useToast();
  const intl = useIntl();
  useEffect(() => {
    setIsDrawerOpen((props.viewCode == LLMTemplateViewCodes.ADD) || (props.viewCode == LLMTemplateViewCodes.EDIT))
  }, [props.viewCode])

  useEffect(() => {
    if (isBusinessOwner) {
      fetchContextPrompts();
    }
  }, [isBusinessOwner]);

  const fetchContextPrompts = async () => {
    setIsLoading(true);
    try {
      const [systemPrompt, userPrompt] = await Promise.all([
        getContextPrompt(CONTEXT_PROMPT_TYPE.SYSTEM),
        getContextPrompt(CONTEXT_PROMPT_TYPE.USER)
      ]);
      setSystemContextPrompt(systemPrompt);
      setUserContextPrompt(userPrompt);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleActionPerformed = async (actionCode: string, viewCode: any) => {
    setIsSubmitPressed(true)

    if (actionCode === COMMON_ACTION_CODES.SAVE) {
      let isValid = true
      if (isActive === null) {
        isValid = false
      }
      if (accessRole === '') {
        isValid = false
      }
      if (templateName.trim() === '') {
        isValid = false
        setIsValidName(false)
      }
      if (!templateMapList.every((section: { title: string; description: string; }) => section.title.trim() !== '' && section.description.trim() !== '')) {
        isValid = false
      }
      if (accessRole == LLM_VIEW_ROLE.SELECTED_ROLES && selectedRoles.length === 0) {
        isValid = false
        setIsValidRole(false)
      }
      if (accessRole == LLM_VIEW_ROLE.SELECTED_USERS && selectedUsers.length === 0) {
        isValid = false
        setIsValidUser(false)
      }

      if (isValid) {
        const templateBody = {
          accessRole,
          isActive,
          templateName,
          templateMapList,
          templateType: 'regular',
          userRoleIds: selectedRoles,
          userIds: selectedUsers,
          isDefault: isDefault,
          languageModel: languageModel
        };

        if (props?.template?.id) {
          await updateLLMTemplate(templateBody, props?.template?.id)
          showToast(
            toast,
            intl.formatMessage({
              id: 'templateUpdatedMsg',
            }),
            ToastType.success,
            3000
          );
        } else {
          await createNewLLMTemplate(templateBody)
          showToast(
            toast,
            intl.formatMessage({
              id: 'templateCreatedMsg',
            }),
            ToastType.success,
            3000
          );
        }
        props.onActionPerformed(actionCode, null, viewCode);
      }
      else {
        setIsSubmitPressed(false)
      }
    } else {
      setIsSubmitPressed(false)
      props.onActionPerformed(actionCode, null, viewCode);
    }
  };

  const onChange = () => {
    setIsActive(!isActive);
  }

  const onChangeText = (text: any) => {
    setTemplateName(text)
  }

  const onChangeLanguageModel = (model: string) =>{
    setLanguageModel(model)
  }

  const selectedLanguageModel = LLM_LANGUAGE_MODELS.find(model => model.value === (languageModel || GPT_4O))?.label 

  const onChangeAceessRole = (role: any) => {
    setAccessRole(role)
  }

  const addNewSection = () => {
    setTemplateMapList([...templateMapList, { title: '', description: '' }]);
  };

  const updateTemplateSection = (index: TemplateSectionIndex, field: 'title' | 'description', value: string) => {
    const updatedSections = [...templateMapList];
    updatedSections[index][field] = value;
    setTemplateMapList(updatedSections);
  };

  const deleteTemplateSection = (index: TemplateSectionIndex) => {
    const updatedSections = [...templateMapList];
    updatedSections.splice(index, 1);
    setTemplateMapList(updatedSections);
  };

  const handleSelectedUsersChange = (selectedUserUuids: any) => {
    setSelectedUsers(selectedUserUuids);
    if (selectedUserUuids.length > 0) {
      setIsValidUser(true)
    }
  };

  const handleSelectedRolesChange = (selectedRoles: any) => {
    setSelectedRoles(selectedRoles);
    if (selectedRoles.length > 0) {
      setIsValidRole(true)
    }
  }

  const templateSectionsLength = templateMapList.length;

  useEffect(() => {
    if (props?.viewCode === LLMTemplateViewCodes.EDIT && props?.template) {
      setTemplateName(props?.template?.templateName || '');
      setIsActive(props?.template?.isActive || false)
      setTemplateMapList(props?.template?.templateMapList || [{ title: '', description: '' }])
      setSelectedRoles(props?.template?.userRoleIds || [])
      setSelectedUsers(props?.template?.userIds || [])
      setLanguageModel(props?.template?.languageModel || GPT_4O)
    }
  }, [props.template?.id])

  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = templateMapList.map((section: { title: string, description: string }, index: number) => {
      return {
        'id': index + '',
        title: section.title,
        description: section.description,
        content: (
          <HStack flex={1} key={index} space={2} style={styles.templateSectionCard} marginBottom={2}>
            <VStack flex={20} space={2} gap={2}>
              <Input
                isDisabled={!props?.adminAccess && props?.template?.isDefault}
                _focus={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS, }}
                _hover={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS }}
                backgroundColor={'white'}
                placeholder='Add Title'
                borderColor={Colors.FoldPixel.GRAY200}
                borderWidth={0.5}
                padding={2}
                value={section.title}
                onChangeText={(text) => updateTemplateSection(index, 'title', text)}
              />
              <Input
                isDisabled={!props?.adminAccess && props?.template?.isDefault}
                _focus={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS }}
                _hover={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS }}
                backgroundColor={'white'}
                multiline
                lineHeight={8}
                placeholder='Add Description'
                numberOfLines={5}
                borderColor={Colors.FoldPixel.GRAY200}
                borderWidth={0.5}
                padding={2}
                value={section.description}
                onChangeText={(text) => updateTemplateSection(index, 'description', text)}
              />

            </VStack>
            {templateSectionsLength > 1 && !(!props?.adminAccess && props?.template?.isDefault) ?
              <VStack width={'100%'} flex={1} alignItems={'center'} justifyContent={'space-between'}>
                <DragHandleIcon />

                <Pressable
                  isDisabled={!props?.adminAccess && props?.template?.isDefault}
                  onPress={() => deleteTemplateSection(index)}
                >
                  <TrashBin2Icon color={Colors.error[600]} />
                </Pressable>
              </VStack> : <></>}
          </HStack>
        )
      }

    });
    setItems(items)
  }, [templateMapList])

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const formatJSONView = (
    title: string, 
    json: string, 
    isExpanded: boolean, 
    setExpanded: (isExpanded: boolean) => void,
    isLoading: boolean
  ) => {
    const formattedContent = json
      .replace(/\\n/g, '\n')
      .replace(/\n\s*/g, '\n')
      .replace(/^\s*(\d+)\.\s+/gm, '$1. ')
      .replace(/\*/g, '-')
      .replace(/\s\./g, '.')
      .replace(/([^\n])\n\s*\n/g, '$1\n\n')
      .replace(/(?<=\S)\n{2,}/g, '\n')
      .replace(/\n{2,}/g, '\n')
      .trim();

    return (
      <VStack space={4} key={title}>
        <FormControl.Label
          padding={0} 
          margin={0}
        >
          <Pressable 
            flexDirection={'row'} 
            alignItems={'center'} 
            onPress={() => setExpanded(!isExpanded)}
            testID={`expand-${title.replace(/\s+/g, '-').toLowerCase()}`}
            key={`expand-${title.replace(/\s+/g, '-').toLowerCase()}`}
          >
            <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>
              {title}
            </Text>
            <Icon 
              as={Feather}
              name={isExpanded ? 'chevron-up' : 'chevron-down'} 
              size="xs"
              color={Colors.FoldPixel.GRAY150}
              marginLeft={1}
            />
          </Pressable>
        </FormControl.Label>
        {isLoading ? (
          <Skeleton.Input active size="small" block />
        ) : (
          <ScrollView style={[
            styles.scrollView,
            { maxHeight: isExpanded ? 200 : 30 }
          ]}>
            <Text fontSize={12} fontWeight={'400'} lineHeight={16.8} color={Colors.FoldPixel.GRAY300}>
              <ReactMarkdown>
                {formattedContent}
              </ReactMarkdown>
            </Text>
          </ScrollView>
        )}
      </VStack>
    );
  };

  return (
    <Drawer
      style={{
        paddingBottom: 20
      }}
      destroyOnClose={true}
      closable
      open={isDrawerOpen}
      width={700}
      closeIcon
      height={'100%'}
      title={
        <HStack height={'40px'} width={'100%'} maxWidth={'990px'} color={'000000'} justifyContent={'space-between'} alignItems={'center'}>
          <HStack alignItems={'start'} >
            <TitleSubtitleView
              isTitleSubtitleOldView={true}
              containerStyle={{ marginHorizontal: 0, marginTop: 0 }}
              titleLabelId={props.viewCode == LLMTemplateViewCodes.EDIT ? 'Update Synopsis Template' : 'Create Synopsis Template'}
              subtitleLabelId=""
              isHideCommonButton={true}
              titleStyle={{
                fontSize: 24,
              }}
            />
          </HStack>
          <HStack>
            <GetCloseAndContinue
              isPressed={isSubmitPresssed}
              onActionPerformed={handleActionPerformed}
              viewCode={props.viewCode}
              adminAccess={props.adminAccess}
              isDefaultTemplate={props?.template?.isDefault}
            />
          </HStack>
        </HStack>
      }
    >
      <VStack height={'100%'} space={6}>
        <FormControl>
          <VStack space={1}>
            <FormControl.Label isRequired padding={0} margin={0} alignItems={'center'}>
              <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>{'Status'}</Text>
            </FormControl.Label>
            <Radio.Group onChange={onChange} defaultValue={true} value={isActive} size='large' disabled={(!props?.adminAccess && !createdByLoginUser) || props?.template?.isDefault}>
              <Radio value={true} >{'Active'}</Radio>
              <Radio value={false}>{'Inactive'}</Radio>
            </Radio.Group>
          </VStack>
        </FormControl>


        <FormControl isRequired>
          <VStack space={1}>
            <FormControl.Label padding={0} margin={0} alignItems={'center'}>
              <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>
                {'Template Name'}
              </Text>
            </FormControl.Label>
            <Input
              isDisabled={!props?.adminAccess && props?.template?.isDefault}
              _focus={{ borderColor: isValidName ? Colors.FoldPixel.PRIMARY_FOCUS : 'red.600' }}
              _hover={{ borderColor: isValidName ? Colors.FoldPixel.PRIMARY_FOCUS : 'red.600' }}
              backgroundColor={'white'}
              placeholder='Add Title'
              borderColor={isValidName ? Colors.FoldPixel.GRAY200 : 'red.600'}
              borderWidth={isValidName ? 0.5 : 1}
              padding={2}
              onChangeText={onChangeText}
              value={templateName}
              onChange={() => setIsValidName(true)}
            >
            </Input>
            {
              !isValidName ? <Text color={'error.600'}>Enter Valid Name</Text> : <></>
            }
          </VStack>
        </FormControl>

        <VStack space={1}>
          <FormControl.Label isRequired padding={0} margin={0} alignItems={'center'}>
            <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>
              {'Accessed By'}
            </Text>
          </FormControl.Label>
          <Radio.Group
            onChange={(value) => { onChangeAceessRole(value.target.value) }}
            defaultValue={'PRIVATE'}
            value={accessRole}
            style={{ borderWidth: 20 }}
            disabled={(!props?.adminAccess && !createdByLoginUser) || props?.template?.isDefault}
          >
            <Radio value={'SELECTED_ROLES'}>Roles</Radio>
            <Radio value={'SELECTED_USERS'}>Users</Radio>
            <Radio value={'ALL_USERS'}>Everyone</Radio>
            <Radio value={'PRIVATE'}>Only Me</Radio>
          </Radio.Group>

          {
            (accessRole == 'SELECTED_ROLES') && (
              <VStack>
                <AntSelect
                  allowClear
                  disabled={!props?.adminAccess && props?.template?.isDefault}
                  mode="multiple"
                  placeholder="Select Roles"
                  loading={!props?.roleList?.length}
                  value={props?.roleList?.length ? selectedRoles : []}
                  onChange={handleSelectedRolesChange}
                  showSearch
                  filterOption={(inputValue: string, option: any) =>
                    option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {
                    props?.roleList?.map((role: any) => {
                      return (
                        <Option key={role.roleId} value={role.roleId}>
                          {role?.userRole?.value}
                        </Option>
                      )
                    })
                  }
                </AntSelect>
                {
                  !isValidRole ? <Text color={'error.600'}>Select Valid Role</Text> : <></>
                }
              </VStack>
            )
          }
          {
            (accessRole == 'SELECTED_USERS') && (
              <VStack>
                <AntSelect
                  allowClear
                  style={{ borderColor: 'green', borderWidth: 2 }}
                  disabled={!props?.adminAccess && props?.template?.isDefault}
                  mode="multiple"
                  placeholder="Select Users"
                  loading={!props?.userList?.length}
                  value={props?.userList?.length ? selectedUsers : []}
                  onChange={handleSelectedUsersChange}
                  showSearch
                  filterOption={(inputValue: string, option: any) =>
                    option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                  }
                  suffixIcon={
                    <CaretDownOutlined
                      style={{
                        color : Colors.FoldPixel.GRAY400
                      }}
                  />
                  }
                >
                  {
                    props?.userList?.map((user: any) => {
                      return (
                        <Option key={user.uuid} value={user.uuid}>
                          {user.name}
                        </Option>
                      )
                    })
                  }
                </AntSelect>
                {
                  !isValidUser ? <Text color={'error.600'}>Select Valid User</Text> : <></>
                }
              </VStack>
            )
          }
        </VStack>
        {isLanguageModelComponentVisible(accountUUID, userId) && (
          <FormControl isRequired>
            <VStack space={1}>
              <FormControl.Label padding={0} margin={0} alignItems={'center'}>
                <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>
                  {'Language Model'}
                </Text>
              </FormControl.Label>
              <AntSelect
                style={{ width: '100%' }}
                placeholder='Select Language Model'
                value={selectedLanguageModel}
                onChange={onChangeLanguageModel}
                >
                {LLM_LANGUAGE_MODELS.map((param) => (
                  <Option key={param.value} value={param.value}>{param.label}</Option>
                ))}
              </AntSelect>
            </VStack>
          </FormControl>
        )} 
        {isBusinessOwner && formatJSONView(
            'System Context Prompt',
            systemContextPrompt,
            expandedSystemContext,
            setExpandedSystemContext,
            isLoading
        )}
        <VStack space={3}>
          <FormControl.Label isRequired padding={0} margin={0} alignItems={'center'}>
          <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>
              Input Prompt (Template Sections)
            </Text>
          </FormControl.Label>
          
          <DraggableList initialItems={items} setItems={(items: any) => {
            setTemplateMapList(items.map((item: any) => {
              return {
                title: item?.title,
                description: item?.description
              }
            }))
          }}></DraggableList>

          <Pressable
            onPress={addNewSection}
            disabled={!props?.adminAccess && props?.template?.isDefault}
          >
            <Text color={Colors.Custom.Primary300} >
              <Feather
                name='plus'
                size={18}
                color={Colors.Custom.Primary300}
              />
              {'Add New Section'}
            </Text>
          </Pressable>
        </VStack>
        {isBusinessOwner && formatJSONView(
          'Output Prompt',
          userContextPrompt,
          expandedUserContext,
          setExpandedUserContext,
          isLoading
        )}
      </VStack>
    </Drawer>
  );
};

