import {FormControl, HStack, Radio, Text, View} from 'native-base';
import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';
import {capitalizeText} from '../ContactRelationView/ContactRelationUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import { testID } from '../../../testUtils';

interface IModalActionRadioSelect {
  label?: string;
  isRequired?: boolean;
  data: any;
  flex?: string | number;
  onChange: (value: any) => void;
  selectedValue: string | undefined;
  radioContainerMarginLeft?: string | number;
}

export const ModalActionRadioSelect = (props: IModalActionRadioSelect) => {
  const {
    label,
    isRequired,
    data,
    flex,
    onChange,
    selectedValue,
    radioContainerMarginLeft,
  } = props;

  const getValue = (value: string) => {
    if (value == 'unknown') return 'N/A';
    else return capitalizeText(value);
  };

  return (
    <View flex={flex ? flex : ''}>
      <FormControl isRequired={isRequired}>
        <FormControl.Label marginLeft={3}>
          {label && (
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 400,
                fontSize: 14,
              }}
              textLocalId={label}
            />
          )}
        </FormControl.Label>
        <Radio.Group
          marginLeft={radioContainerMarginLeft ? radioContainerMarginLeft : 3}
          defaultValue={selectedValue}
          name={label || ''}
          value={selectedValue}
          onChange={(nextValue: any) => {
            onChange(nextValue);
          }}
        >
          <HStack space={6}>
            {data &&
              data.map((contactType: any, index: number) => {
                return (
                  <Radio
                    colorScheme=""
                    isHovered={false}
                    isFocusVisible={false}
                    isFocused={true}
                    key={index}
                    value={contactType.code}
                    style={{marginVertical: 10}}
                  >
                    <Text
                      fontSize={14}
                      size={'smRegular'}
                      color={Colors.FoldPixel.GRAY300}
                      style={{marginLeft: 8}}
                      {...testID(getValue(contactType.label))}
                    >
                      {getValue(contactType.label)}
                    </Text>
                  </Radio>
                );
              })}
          </HStack>
        </Radio.Group>
      </FormControl>
    </View>
  );
};

const Styles = StyleSheet.create({});
