import { Colors } from '../../../../../../styles/Colors';
import {
  BUTTON_TYPE,
  RIGHT_SIDE_CONTAINER_CODE,
} from '../../../../../../constants';
import ThreeDotIconSvg from '../../../../Svg/InterventionSvg/ThreeDotIconSvg';
import { DayOptimizerBulkActions } from '../../DayOptimizerHelper';
import FoldButtonV2, {
  ButtonType,
} from '../../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import { useIntl } from 'react-intl';
import AutomationSvg from '../../../../Svg/GlobalActionSvg/AutomationSvg';
import { CustomMenuItem } from '../../../../CustomMenuItem/CustomMenuItem';
import CalendarNextDaySvg from '../../../../Svg/CalendarNextDaySvg';
import UserCheckSvg from '../../../../../../assets/svg/UserCheckSvg';
import MembersMultiSelectView from '../../../../MemebersView/MembersMultiSelectView/MembersMultiSelectView';
import { DayOptimizerActionStatus } from '../../DayOptimizerConstants';
import { PatientData } from '../../interfaces';

const BulkActionWrapper = (props: {
  onActionPerformed: (action: string, data: any) => void;
  selectedBulkActionMembers: Map<string, PatientData>;
  selectedDate: Date;
}) => {
  const intl = useIntl();
  const buttonList = [];
  const isMoveToNextDayEnabled = props?.selectedDate.toLocaleDateString() === new Date().toLocaleDateString();

  buttonList.push({
    id: 120,
    dropDownType: 'CUSTOM_VIEW',
    btnText: 'changeCareManager',
    size: 'smBold',
    textColor: Colors.Custom.mainPrimaryPurple,
    variant: BUTTON_TYPE.NO_BORDER,
    content: (
      <>
        <FoldButtonV2
          key={2}
          label={intl.formatMessage({
            id: 'changeCareManager',
          })}
          buttonType={ButtonType.secondary}
          onPress={() => {
            props.onActionPerformed(
              RIGHT_SIDE_CONTAINER_CODE.ASSIGN_CARE_MANAGER_VIEW,
              DayOptimizerActionStatus.OPEN
            );
          }}
          leftIcon={<UserCheckSvg />}
        />
      </>
    ),
    btnClick: () => {},
  });

  if (isMoveToNextDayEnabled) {
    buttonList.push({
      id: 121,
      btnText: 'moveToNextDay',
      size: 'smBold',
      textColor: Colors.Custom.mainPrimaryPurple,
      borderColor: Colors.Custom.mainPrimaryPurple,
      backgroundColor: Colors.Custom.buttonWhiteBg,
      variant: '',
      isRightDividerNotVisible: false,
      leftIcon: <CalendarNextDaySvg />,
      btnClick: () => {
        props?.onActionPerformed(
          DayOptimizerBulkActions.MOVE_TO_NEXT_DAY,
          null
        );
      },
    });
  }

  buttonList.push({
    id: 122,
    dropDownType: 'Popover',
    btnText: '',
    size: 'smBold',
    textColor: Colors.Custom.mainPrimaryPurple,
    borderColor: Colors.Custom.mainPrimaryPurple,
    backgroundColor: Colors.Custom.buttonWhiteBg,
    variant: BUTTON_TYPE.NO_BORDER,
    rightIcon: <ThreeDotIconSvg style={{padding: 0, margin: 0}} />,
    withRightBorder: true,
    isRightDividerNotVisible: false,
    content: (
      <>
        <CustomMenuItem
          label={intl.formatMessage({id: 'runAutomation'})}
          onClick={() => {
            props?.onActionPerformed(
              DayOptimizerBulkActions.RUN_AUTOMATION,
              null
            );
          }}
          hideBottomBorder
          icon={<AutomationSvg customStrokeColor={Colors.Custom.Gray500} />}
        ></CustomMenuItem>
      </>
    ),
    btnClick: () => {
      // btnClick
    },
  });

  return (
    <>
      <MembersMultiSelectView
        hideDividerBetweenButtons
        customPopoverWidth={isMoveToNextDayEnabled ? 590 : 420}
        customStyles={{
          customButtonSpace: 0,
        }}
        onClose={() => {
          props?.onActionPerformed(
            DayOptimizerBulkActions.DESELECT_ALL_MEMBERS,
            null
          );
        }}
        isVisible={props?.selectedBulkActionMembers.size > 0}
        selectedMembers={
          Array.from(props?.selectedBulkActionMembers.values()) as any[]
        }
        buttonList={buttonList}
      />
    </>
  );
};

export default BulkActionWrapper;
