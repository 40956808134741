import {Image} from 'antd';
import {HStack, Text, View, VStack} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {evnName} from '../../../../../constants/BaseUrlConst';
import {Colors} from '../../../../../styles';
import {
  getCommerceSubdomain,
  getCurrentSubdomainWeb,
} from '../../../../../utils/commonUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {IProductTableDisplayData} from '../../ProductsAndServices/Products/ProductTableView/ProductTable/interfaces';
import ActionsView from '../ActionsView';

const isFoldAccount = () => {
  const subdomain = getCurrentSubdomainWeb();
  if (subdomain === `fold.${evnName()}foldhealth.io`) {
    return true;
  }
  return false;
};

export const getMembershipsTableColumns = (
  onActionPerformed: (actionCode: string, rowData: any, checked?: any) => void
): any[] => {
  return [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      fixed: 'left',
      sorter: (nameOne: any, nameTow: any) => {
        return nameOne.name.localeCompare(nameTow.name);
      },
      ellipsis: true,
      render: (
        text: string,
        selectedRecord: IProductTableDisplayData
      ): JSX.Element => {
        const imagesList = selectedRecord.imageData;
        let imageUrl = '';
        if (
          imagesList &&
          imagesList.length &&
          imagesList[0] &&
          imagesList[0].attributes &&
          imagesList[0].attributes.original_url
        ) {
          imageUrl = `https://${getCommerceSubdomain()}${
            imagesList[0].attributes.original_url
          }`;
        }
        return (
          <HStack alignItems={'center'} space={2}>
            {imageUrl ? (
              <VStack>
                <Image
                  src={imageUrl}
                  preview={{
                    src: imageUrl,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  height={48}
                  width={48}
                />
              </VStack>
            ) : (
              <VStack>
                <AntIcon
                  name="picture"
                  size={48}
                  color={Colors.Custom.IconColorGray}
                />
              </VStack>
            )}
            <VStack marginRight={4}>
              {text ? (
                <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                  {text}
                </Text>
              ) : (
                <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>-</Text>
              )}
              {selectedRecord.active ? (
                <></>
              ) : (
                <View
                  style={{
                    backgroundColor: Colors.danger['100'],
                    width: 'max-content',
                    paddingHorizontal: 8,
                    paddingVertical: 2,
                    borderRadius: 8,
                    marginTop: 3,
                    borderWidth: 1,
                    borderColor: Colors.danger['200'],
                  }}
                >
                  <Text
                    size={'xsSemibold'}
                    style={{
                      color: Colors.danger['400'],
                    }}
                  >
                    Archived
                  </Text>
                </View>
              )}
            </VStack>
          </HStack>
        );
      },
      responsive: ['md'],
    },

    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="oneTimePrice"
        />
      ),
      width: '10%',
      render: (data: any): JSX.Element => {
        const price = data?.prices?.oneTime.price;
        const currency = data?.mrpData?.currency;
        return (
          <View>
            {price && price.length && price != 0 ? (
              <Text textAlign={'center'} size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
                {currency.toUpperCase()} {price / 100}
              </Text>
            ) : (
              <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="monthlyPrice"
        />
      ),
      width: '10%',
      ellipsis: true,
      render: (data: any): JSX.Element => {
        const price = data?.prices?.monthly.price;
        const currency = data?.mrpData?.currency;
        const trialDays = data?.trialInfo?.monthly?.trial_period_days
        return (
          <View>
            {price && price.length && price != 0 ? (
              <>
              <Text textAlign={'center'} size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
                {currency.toUpperCase()} {price / 100}
              </Text>
              {trialDays && <Text textAlign={'center'} size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
                {`(Trial ${trialDays} days)`}
              </Text>}
              </>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="quarterlyPrice"
        />
      ),
      width: '10%',
      ellipsis: true,
      render: (data: any): JSX.Element => {
        const price = data?.prices?.quarterly.price;
        const currency = data?.mrpData?.currency;
        const trialDays = data?.trialInfo?.quarterly?.trial_period_days
        return (
          <View>
            {price && price.length && price !== 0 ? (
              <>
              <Text textAlign={'center'} size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
                {currency.toUpperCase()} {price / 100}
              </Text>
              {trialDays && <Text textAlign={'center'} size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
                {`(Trial ${trialDays} days)`}
              </Text>}
              </>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="annuallyPrice"
        />
      ),
      width: '10%',
      ellipsis: true,
      render: (data: any): JSX.Element => {
        const price = data.prices?.yearly.price;
        const currency = data?.mrpData?.currency;
        const trialDays = data?.trialInfo?.yearly?.trial_period_days
        return (
          <View>
            {price && price.length && price != 0 ? (
              <>
              <Text textAlign={'center'} size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
                {currency.toUpperCase()} {price / 100}
              </Text>
              {trialDays && <Text textAlign={'center'} size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
                {`(Trial ${trialDays} days)`}
              </Text>}
              </>
            ) : (
              <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: '20%',
      fixed: 'right',
      render: (text: any, selectedRecord: any) => {
        return (
          <>
            <ActionsView
              selectedRecord={selectedRecord}
              onActionClick={(
                actionCode: string,
                actionData: any,
                type?: any
              ): any => {
                onActionPerformed(actionCode, selectedRecord, type);
              }}
            />
          </>
        );
      },
      responsive: ['md'],
    },
  ];
};
