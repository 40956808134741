import {useLazyQuery, useMutation} from '@apollo/client';
import {Select, Spin} from 'antd';
import {Pressable, Skeleton, Text, useToast, VStack} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {apiError, MLOV_CATEGORY, removedDefault, removeDefaultMessageForLocation, setDefaultMessageForLocation} from '../../../constants';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../constants/Configs';
import {
  USER_ROLE_CODES,
  VIRTUAL_ASSIGNEE_TYPE_CODES,
} from '../../../constants/MlovConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {CloudTelephonyQueries} from '../../../services';
import {addOrUpdateDefaultNumberForLocation, deleteDefaultNumberForLocation, getDefaultPhoneNumberByLocationByLocationId, resetDefaultNumberCachedResponse} from '../../../services/CloudTelephony/CloudTelephonyQueries';
import {Colors} from '../../../styles';
import {getAccountUUID, getCurrentUserRole} from '../../../utils/commonUtils';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {getMlovId} from '../../../utils/mlovUtils';
import {PERSON_ACTION_CODES} from '../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {DisplayText} from '../DisplayText/DisplayText';
import {IDeleteConfirmationDrawer} from './interface';
const {Option} = Select;

const DefaultLocationNumber = (props: IDeleteConfirmationDrawer) => {
  const {accountLocationId} = props;
  const accountUuid = getAccountUUID();
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const CommunicationType = mlovData?.CommunicationType;
  const toast = useToast();
  const currentUserRoles = getCurrentUserRole();

  const clinicUuid = getMlovId(
    mlovData,
    MLOV_CATEGORY.VIRTUAL_NUMBER_ASSIGNEE,
    VIRTUAL_ASSIGNEE_TYPE_CODES.CLINIC
  );
  const [stateData, setStateData] = useState({
    phoneNumberList: [] as any[],
    defaultNumberId: '',
    loading: false,
    defaultNumberLoading: false,
    type: '',
    virtualNumberObj: {} as any,
    defaultNumber: '',
    defaultNumberLocationIds: [] as string[],
  });
  const [GET_PHONE_NUMBERS_BY_LOCATION] = useLazyQuery(
    CloudTelephonyQueries.GET_PHONE_NUMBERS_BY_LOCATION,
    {
      fetchPolicy: 'no-cache'//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const getPhoneNumberByLocation = async () => {
    setStateData(prev=> {
      return {
        ...prev,
        loading: true
      }
    })
    const phoneNumberLocationResp = await GET_PHONE_NUMBERS_BY_LOCATION({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid,
        assigneeId: accountLocationId,
        assigneeTypeId: clinicUuid,
      },
    });
    if (phoneNumberLocationResp?.data?.virtualPhoneNumberAssignees?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          phoneNumberList:
            phoneNumberLocationResp?.data?.virtualPhoneNumberAssignees,
            loading: false
        };
      });
      await checkDefaultNumberByTypeCode()
    } else {
      setStateData(prev=> {
        return {
          ...prev,
          loading: false
        }
      })
    }
  };

  const checkDefaultNumberByTypeCode = async () => {
    setStateData(prev=> {
      return {
        ...prev,
        defaultNumberLoading: true
      }
    })
    const defaultNumberForLocation: any = await getDefaultPhoneNumberByLocationByLocationId({
      accountUuid: accountUuid,
      locationId: accountLocationId,
    }).catch((err) => {
      showToast(toast, apiError, ToastType.error, 4000);
      setStateData(prev=> {
        return {
          ...prev,
          defaultNumberLoading: false
        }
      })
    });
    if (defaultNumberForLocation?.data?.length) {
      const defaultNumberLocationIds: string[] = [];
      defaultNumberForLocation?.data?.forEach((numbersObj:any)=> {
        if (numbersObj?.id) {
        defaultNumberLocationIds.push(numbersObj?.id)
      }
      })
      const defaultNumberObj = defaultNumberForLocation?.data?.[0];
      const number = defaultNumberObj?.VirtualPhoneNumberAssignees?.virtualPhoneNumber;
      const numberId = defaultNumberObj?.VirtualPhoneNumberAssignees?.id;
      setStateData(prev => {
        return {
          ...prev,
          defaultNumberId: numberId,
          defaultNumber: number,
          defaultNumberLocationIds: defaultNumberLocationIds,
          defaultNumberLoading:  false
        }
      })
    } else {
      setStateData(prev=> {
        return {
          ...prev,
          defaultNumberLoading: false
        }
      })
    }
  };

  const getTypeIdByCode = (code: string) => {
    const typeObj = CommunicationType.filter((communication) => {
      return communication?.code === code;
    });
    return typeObj[0]?.id;
  };

  const resetSMSDefaultNumberCache = async () => {
    const communicationTypeId = getTypeIdByCode('SMS')
    await resetDefaultNumberCachedResponse(
      {
        accountUuid: accountUuid,
        communicationTypeId: communicationTypeId,
        locationId: accountLocationId
      }
    ).catch((err) => {
      return
    })
  }

  const handleAddDefaultForLocation = async (virtualPhoneNumber: string, virtualNumberId: string) => {
    if (!virtualNumberId) {
      return
    }
    const body = [
      {
        accountUuid: accountUuid,
        locationId: accountLocationId,
        virtualNumberId: virtualNumberId,
        communicationTypeCode: PERSON_ACTION_CODES.CALL
      },
      {
        accountUuid: accountUuid,
        locationId: accountLocationId,
        virtualNumberId: virtualNumberId,
        communicationTypeCode: PERSON_ACTION_CODES.SMS
      }
    ]
    const addOrUpdateDefaultForLocationObj:any =
      await addOrUpdateDefaultNumberForLocation(body).catch((err) => {
        showToast(toast, apiError, ToastType.error, 3000);
        return
      });
      if(addOrUpdateDefaultForLocationObj?.data?.length) {
        const defaultNumberLocationIds: string[] = [];
        addOrUpdateDefaultForLocationObj?.data?.forEach((numbersObj:any)=> {
        defaultNumberLocationIds.push(numbersObj?.id)
      })
        await resetSMSDefaultNumberCache()
        setStateData((prev) => {
          return {
            ...prev,
            defaultNumberId: virtualNumberId,
            defaultNumber: virtualPhoneNumber,
            defaultNumberLocationIds:  defaultNumberLocationIds
          };
        });
        showToast(toast, setDefaultMessageForLocation, ToastType.success, 3000);
      }
  };

  const handleDeleteDefaultNumberByLocation = async () => {
    const removeDefaultNumberResObj: any = await deleteDefaultNumberForLocation(
      {ids: stateData.defaultNumberLocationIds}
    ).catch((err) => {
      showToast(toast, apiError, ToastType.error, 3000);
      return;
    });
    if (removeDefaultNumberResObj?.data?.length) {
      await resetSMSDefaultNumberCache()
      showToast(toast, removeDefaultMessageForLocation, ToastType.success, 3000);
      setStateData(prev => {
        return {
          ...prev,
          defaultNumberId: '',
          defaultNumber: '',
          defaultNumberLocationIds: []
        }
      })
    } else {
      showToast(toast, apiError, ToastType.error, 3000);
    }
  };
  useEffect(() => {
    getPhoneNumberByLocation();
  }, [accountLocationId]);
  return (
    <>
      <Select
        value={stateData?.defaultNumberId}
        onChange={(data) => {
          const selectedVirtualNumber = stateData?.phoneNumberList?.find((item)=> {
            return item?.id == data
          })
          const virtualNumberId = selectedVirtualNumber?.id;
          const virtualPhoneNumber = selectedVirtualNumber?.virtualPhoneNumber
          handleAddDefaultForLocation(virtualPhoneNumber, virtualNumberId)
        }}
        onClear={()=> {
          handleDeleteDefaultNumberByLocation()
        }}
        allowClear={stateData?.defaultNumberId ? true : false}
        loading={stateData.defaultNumberLoading}
        notFoundContent={stateData.loading ? <Spin size="small" /> : null}
      >
        {stateData?.phoneNumberList?.map((numberObj) => {
          return (
            <Option key={numberObj?.id} >{numberObj.virtualPhoneNumber}</Option>
          );
        })}
      </Select>
      {!stateData?.phoneNumberList.length && !stateData.loading
        && <Text size="xsMedium"
          style={{
            color: Colors.Custom.Gray400,
            marginTop: 6,
            marginLeft: 4,
          }}>There is no phone numbers associated with the {props.locationName}, please set the phone number to {props.locationName} from, Settings {'>'} Calls {'>'} Phone numbers {'>'} Edit phone number.</Text>
      }
      {stateData.defaultNumberLoading ? <Spin size="small" /> : null}
    </>
  );
};

export default React.memo(DefaultLocationNumber);
