import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {ICommonSvgProps} from './interfaces';

const ChevronNewSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props.width || '6'}
      height={props.height || '6'}
      viewBox="0 0 6 6"
      fill="none"
    >
      <Path
        d="M4 3L2 1L2 5L4 3Z"
        fill={props?.customStrokeColor || '#667085'}
        stroke={props?.customStrokeColor || '#667085'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default React.memo(ChevronNewSvg);
