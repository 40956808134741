import { ColumnsType } from 'antd/lib/table';
import { Text, View, Badge, HStack} from 'native-base';
import { AddCustomContactTypeStatus, ICustomContactTypesTableData } from '../interfaces';
import { Colors } from '../../../../../styles';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { Pressable } from 'react-native';
import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import CustomContactTypesActions from './CustomContactTypesActions/CustomContactTypesActions';
import {CAN_CONVERT_TO_PATIENT} from './Const';

export const getContactTypesColumns = (
  actionFn: (actionCode: string, record: any) => {}
) => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'title',
      fixed: 'left',
      width: '20%',
      render: (
        contactTypeName: string,
        record: ICustomContactTypesTableData
      ) => (
        <Pressable onPress={() => {
          if (!record.isDefault) {
            actionFn(COMMON_ACTION_CODES.EDIT, record)
          }
        }}>
          <HStack alignItems={'center'} space={0.5}>
            <Text color={Colors.FoldPixel.GRAY400}>{contactTypeName}</Text>
            {record.isDefault && (
              <Text color={Colors.FoldPixel.GRAY300} fontSize={'12'}>{'(Default)'}</Text>
            )}
          </HStack>
        </Pressable>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="numberOfContacts"
        />
      ),
      dataIndex: 'contactCount',
      key: 'contacts',
      width: '14%',
      render: (text: number) => (
        <View justifyContent={'flex-start'}>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
            {text}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="description"
        />
      ),
      dataIndex: 'description',
      key: 'description',
      width: '35%',
      render: (text: string) => (
        <View justifyContent={'flex-start'}>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
            {text || '-'}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="convertibleToPatient"
        />
      ),
      dataIndex: 'canConvertToPatient',
      key: 'canConvertToPatient',
      width: '18%',
      render: (canConvertToPatient: boolean) => (
        <View justifyContent={'flex-start'}>
          <Badge
            style={{marginVertical: 4, marginHorizontal: 4}}
            key={canConvertToPatient ? CAN_CONVERT_TO_PATIENT.YES : CAN_CONVERT_TO_PATIENT.NO}
            colorScheme={canConvertToPatient ? 'green' : 'red'}
            background={canConvertToPatient ? Colors.Custom.CompletedTaskBackgroundColor : Colors.Custom.Red100}
            alignSelf="flex-start"
            variant="outline"
            borderRadius={4}
            borderWidth={0}
            fontSize={14}
            justifyContent={'flex-start'}
          >
            {canConvertToPatient ? CAN_CONVERT_TO_PATIENT.YES : CAN_CONVERT_TO_PATIENT.NO}
          </Badge>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="status"
        />
      ),
      dataIndex: 'status',
      key: 'status',
      fixed: 'left',
      width: '10%',
      render: (
        contactTypeStatus: string,
        record: ICustomContactTypesTableData
      ) => (
        <>
          {contactTypeStatus && contactTypeStatus.length > 0 ? (
            getStatusBadge(contactTypeStatus)
          ) : (
            <View justifyContent={'flex-start'} flexDirection={'row'}>
              <Text>-</Text>
            </View>
          )}
        </>
      ),
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'actions',
      fixed: 'right',
      width: '10%',
      render: (record: ICustomContactTypesTableData) => {
        return (
          <View>
            <CustomContactTypesActions
              onActionPerformed={actionFn}
              record={record}
            />
          </View>
        );
      },
    },
  ];
  return columns;
};

export const getStatusBadge = (status: string) => {
  const colorList = [
    {
      key: AddCustomContactTypeStatus.Active,
      color: 'green',
      background: 'green.100',
    },
    {
      key: AddCustomContactTypeStatus.Inactive,
      color: 'red',
      background: 'red.100',
    },
  ];

  const item = colorList.find((obj: any) => {
    return obj.key === status;
  }) || {
    color: 'gray',
    background: 'gray.100',
  };

  return (
    <HStack style={{flexWrap: 'wrap'}}>
          <Badge
          style={{marginVertical: 4, marginHorizontal: 4}}
            key={status}
            colorScheme={item.color}
            background={item.background}
            alignSelf="center"
            variant="outline"
            borderRadius={10}
          >
            {status}
          </Badge>
    </HStack>
  );
};
