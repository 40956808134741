import React, { useContext } from 'react';
import { Text } from 'native-base';
import { IContactCareProgramResponse } from '../../../services/ContactCareProgram/interface';
import Stack from '../LayoutComponents/Stack';
import { CONTACT_CARE_PROGRAM_STATUS_CODES, MLOV_CATEGORY } from '../../../constants/MlovConst';
import { Colors } from '../../../styles/Colors';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { PROGRAM_FILTER_CODE } from '../MemebersView/constant';

const CareProgramStatus = ({
  contactCarePrograms,
}: {
  contactCarePrograms: IContactCareProgramResponse[];
}) => {
  const commonContextData = useContext(CommonDataContext);
  const careProgramStatusList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_CARE_PROGRAM_STATUS
    ) || [];

  const getTextColor = (statusCode?: string) => {
    switch (statusCode) {
      case CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED:
        return Colors.Custom.SuccessColor;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS:
        return Colors.Custom.alertsDescriptionColor;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.NEW:
        return Colors.Custom.Primary300;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED:
        return Colors.Custom.Danger800;
      default:
        return Colors.Custom.Gray400;
    }
  }

  return (
    <Stack direction={'column'}>
      {(contactCarePrograms || []).map((contactCareProgram) => {
        const careProgramStatus =
          careProgramStatusList.find(status => status.id === contactCareProgram?.statusId);
        return (
          <Stack direction={'row'} key={contactCareProgram?.id}>
            <Text
        wordBreak={'break-word'}
        color={getTextColor(careProgramStatus?.code)}
        size={'smRegular'}
      >
        {careProgramStatus?.value}
      </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default CareProgramStatus;
