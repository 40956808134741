import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { ICommonSvgProps } from '../interfaces';

const MenuDotsSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props?.width || '16'}
      height={props?.height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <Path
        d="M4.66667 8.49935C4.66667 9.23573 4.06971 9.83268 3.33333 9.83268C2.59695 9.83268 2 9.23573 2 8.49935C2 7.76297 2.59695 7.16602 3.33333 7.16602C4.06971 7.16602 4.66667 7.76297 4.66667 8.49935Z"
        stroke={props?.customStrokeColor || "#6F7A90"}
        stroke-linecap="round"
      />
      <Path
        d="M9.33333 8.49935C9.33333 9.23573 8.73638 9.83268 8 9.83268C7.26362 9.83268 6.66667 9.23573 6.66667 8.49935C6.66667 7.76297 7.26362 7.16602 8 7.16602C8.73638 7.16602 9.33333 7.76297 9.33333 8.49935Z"
        stroke={props?.customStrokeColor || "#6F7A90"}
        stroke-linecap="round"
      />
      <Path
        d="M14 8.49935C14 9.23573 13.403 9.83268 12.6667 9.83268C11.9303 9.83268 11.3333 9.23573 11.3333 8.49935C11.3333 7.76297 11.9303 7.16602 12.6667 7.16602C13.403 7.16602 14 7.76297 14 8.49935Z"
        stroke={props?.customStrokeColor || "#6F7A90"}
        stroke-linecap="round"
      />
    </Svg>
  );
};

export default React.memo(MenuDotsSvg);