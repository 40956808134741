import React, {useContext} from 'react';
import {useLazyQuery} from '@apollo/client';
import {GET_CARE_TEAM_MEMBER_TYPE} from '../../../../../../services/CareTeam/CareTeamQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {CareManagerAssignee} from '../../../../../PersonOmniView/RightContainer/CareTeamView/CareManagerAssignee';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {MLOV_CATEGORY} from '../../../../../../constants';
import {
  CARE_TEAM,
  CARE_TEAM_MEMBER_TYPE,
} from '../../../../../../constants/MlovConst';

const AssignCareManagerPopoverWrapper = (props: {
  record: any;
  customPopoverBody?: React.ReactNode;
  parentPopover: {open: boolean; closePopover: () => void};
}) => {
  const commonContextData = useContext(CommonDataContext);
  const careTeamList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PRIMARY_CARE_TEAM
    ) || [];
  const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);

  const [getCareTeamMemberByMemberType] = useLazyQuery(
    GET_CARE_TEAM_MEMBER_TYPE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const [careTeam, setCareTeam] = React.useState<any>({});

  const careManager = (careTeam?.careTeamMemberType || []).find(
    (careTeamMember: any) => {
      return (
        careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
      );
    }
  );

  const fetchData = () => {
    getCareTeamMemberByMemberType({
      variables: {
        contactIds: [props?.record?.uuid],
        careTeamTypeId: careTeamTypeId,
      },
    }).then((res) => {
      setCareTeam(res?.data?.careTeams?.[0] || {});
    });
  };

  React.useEffect(() => {
    if (props?.parentPopover?.open) {
      fetchData();
    }
  }, [props?.parentPopover?.open]);

  return (
    <>
      {props?.parentPopover?.open ? (
        <CareManagerAssignee
          contactUuid={props?.record?.uuid}
          accountLocationUuid={props?.record?.practiceLocationAccountId}
          careTeam={careTeam}
          careManager={careManager}
          showDialog={false}
          customPopoverBody={props?.customPopoverBody}
          customPopoverPlacement={'left'}
          showPopoverArrow={false}
          onActionComplete={() => {
            props?.parentPopover?.closePopover();
          }}
        />
      ) : null}
    </>
  );
};

export default AssignCareManagerPopoverWrapper;
