import {HStack, Text, Tooltip, View} from 'native-base';
import {useState} from 'react';
import {Pressable} from 'react-native';
import { PERSON_TYPES, getInActiveContactError } from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles';
import {canCommunicateWithInactivePatient, isActiveContact, isConsentRequiredAndGiven, isContactConsentGiven, isContactVerified, isMasterAccount} from '../../../utils/commonUtils';
import {EventBus} from '../../../utils/EventBus';
import CreateSmsConversationDrawer from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {ContactVerified} from '../ContactVerfied/ContactVerified';
import OutboundCallSmsView from '../OutboundCallSmsView/OutboundCallSmsView';
import {inboxData} from '../OutboundCallSmsView/interface';
import { isCallsAllowed } from '../../RightSideContainer/UserAccess/UserAccessPermission';

export const PhoneNumber = (props: {
  phoneNumber: string;
  contactData: any;
  onActionPerformed: (actionCode: string) => void;
  inboxData?: inboxData;
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [stateData, setStateData] = useState({
    visible: false,
    selectedRawData: {} as any,
  });
  const contactActiveStatus = canCommunicateWithInactivePatient(props?.contactData)
  const consentCheck = isConsentRequiredAndGiven(props.contactData)
  const isMasterAccountFlag = isMasterAccount()
  const isCallAllowed = isCallsAllowed();

  const handleActions = (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.CALL:
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent('CLOUD_CALL', {
          contactData: props.contactData,
          fromNumber: rowData.fromNumber,
        });
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.SMS:
        setIsDrawerVisible(true);
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
            selectedRawData: rowData,
          };
        });
    }
  };

  return (
    <>
      {!isMasterAccountFlag ? (
        <OutboundCallSmsView
          visible={stateData.visible}
          content={
            <View>
              <HStack alignItems={'center'} space={1}>
                <Tooltip placement='top' label={
                  !contactActiveStatus ? getInActiveContactError(props?.contactData)
                  :
                  "Call"
                  }>
                  <Pressable
                    onPress={() => {
                      if (!isCallAllowed) {
                        return;
                      }
                      if (consentCheck) {
                       if(contactActiveStatus){
                        setStateData((prev) => {
                          return {
                            ...prev,
                            visible: true,
                          };
                        });
                      }
                      } else {
                        props?.onActionPerformed(COMMON_ACTION_CODES.EDIT);
                      }
                    }}
                  >
                    <Text size={'smRegular'} color={Colors.Custom.AlertsDescriptionColor}>
                      {props?.phoneNumber}
                    </Text>
                  </Pressable>
                </Tooltip>
                {isContactVerified(
                  props?.contactData?.personContact || [],
                  'phone'
                ) && (
                  <>
                    <ContactVerified label="Verified Number" />
                  </>
                )}
              </HStack>
            </View>
          }
          handleAction={(actionCode: string, rawData?: any) => {
            handleActions(actionCode, rawData);
          }}
          selectedContactData={props.contactData || {}}
          inboxData={props?.inboxData}
        />
      ) : (
        <Text size={'smMedium'} color={Colors.secondary['500']}>
            {props?.phoneNumber}
        </Text>
      )}

      <CreateSmsConversationDrawer
        isDrawerVisible={isDrawerVisible}
        selectedInbox={{} as any}
        onCreateSmsConversationActionPerformed={(
          actionCode: string,
          actionData: any
        ) => {
          setIsDrawerVisible(false);
          setStateData((prev) => {
            return {
              ...prev,
              selectedRawData: {} as any,
            };
          });
        }}
        selectedInboxId={stateData?.selectedRawData?.smsInboxId}
        selectedPatient={props?.contactData}
      />
    </>
  );
};
