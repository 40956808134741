import { Divider, HStack, Text, View, VStack } from 'native-base';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { MLOV_CATEGORY } from '../../../../../constants';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import {Colors} from '../../../../../styles';
import {
  getMlovListFromCategory,
  getMlovValueFromId
} from '../../../../../utils/mlovUtils';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import { IProductDetailForm } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import { ICcareTeamRoleSuggestions } from '../CareJourneyTeam/CareJourneyTeam';
import CareJourneyActivities from './CareJourneyActivities';
import { getCareTeam, getJourneyStart } from './CareJourneyPreviewHelper';

export interface ICareJourneyPreviewProps {
  journeyDetails: IProductDetailForm;
  nodes: any[];
  accountUserRoles: {value: string; label: string, mlovRoleId:string}[]
  edges: any[];
  nodeMasterDataMap: any;
  nodeMetaData: any;
  isAssignWorkflow: boolean;
  careTeamRoleSuggestions?: ICcareTeamRoleSuggestions;
  resetCareTeamRoleSuggestions?: () => void;
  onUpdateCareTeam?: (careTeam: any[]) => void;
}

const CareJourneyPreview = (props: ICareJourneyPreviewProps) => {
  const { journeyDetails, nodes, edges } = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const journeyDuration =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_JOURNEY_DURATION
    ) || [];
  const userRoles =
    getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.USER_ROLES) || [];

  const [ hasCareTeamRoleSuggestionsState, setHasCareTeamRoleSuggestionsState ] = useState(false);

  const initialVisibleDescriptionLength = 400;
  const [textShown, setTextShown] = useState(journeyDetails.description.length <= initialVisibleDescriptionLength);

  const toggleNumberOfLines = () => {
    setTextShown(!textShown);
  }

  if (!props.isAssignWorkflow) {
    const selectedCareTeamRoles = journeyDetails?.careTeam || [];

    const filteredCareTeamRoleSuggestions = (Object.values(props.careTeamRoleSuggestions?.roles || {})).filter((role: any) => {
      if (!role || !role.roleId) return false;
      if (selectedCareTeamRoles.includes(role.roleId)) return false;
      return true;
    });

    const hasCareTeamRoleSuggestions = !!(filteredCareTeamRoleSuggestions && filteredCareTeamRoleSuggestions.length);

    if (hasCareTeamRoleSuggestions && !hasCareTeamRoleSuggestionsState) {
      journeyDetails.careTeam = selectedCareTeamRoles.concat(filteredCareTeamRoleSuggestions.map((role) => role.roleId));
      if (props.onUpdateCareTeam) props.onUpdateCareTeam(journeyDetails.careTeam);
      setHasCareTeamRoleSuggestionsState(true);
    }

    if (props.resetCareTeamRoleSuggestions && Object.values(props.careTeamRoleSuggestions?.roles || {})?.length) props.resetCareTeamRoleSuggestions();
  }

  const displayDetails: any[] = [
    {
      title: intl.formatMessage({ id: 'journeyDuration' }),
      description:
        (journeyDetails.duration?.value || '') +
        ' ' +
        (journeyDetails.duration?.unit
          ? getMlovValueFromId(journeyDuration, journeyDetails.duration?.unit)
          : ''),
    },
    // {
    //   title: intl.formatMessage({ id: 'journeyWillStartOn' }),
    //   description: getJourneyStart(journeyDetails, props.isAssignWorkflow),
    // },
    {
      title: intl.formatMessage({ id: 'careTeam' }),
      description: getCareTeam(
        journeyDetails,
        props.isAssignWorkflow,
        props.accountUserRoles,
        hasCareTeamRoleSuggestionsState,
      ),
    },
  ];
  // if (journeyDetails.memberOutcome) {
  //   displayDetails.push({
  //     title: intl.formatMessage({ id: 'memberOutcome' }),
  //     description: journeyDetails.memberOutcome || '-',
  //   });
  // }
  // if (journeyDetails.businessOutcome) {
  //   displayDetails.push({
  //     title: intl.formatMessage({ id: 'businessOutcome' }),
  //     description: journeyDetails.businessOutcome || '-',
  //   });
  // }

  return (
    <View
      style={{
        backgroundColor: '#fff',
      }}
    >
      <PageBodyContainer>
        <VStack space={4} padding={4}>
          <VStack space={2}>
            <Text fontWeight={600} size={'lgMedium'} color={Colors.FoldPixel.GRAY400} numberOfLines={1}>
              {journeyDetails.name}
            </Text>
            {journeyDetails.description && (
              <>
                <Text fontWeight={300} size={'smRegular'} color={Colors.FoldPixel.GRAY250} marginTop={-2}>
                  {textShown
                    ? journeyDetails.description
                    : journeyDetails.description.substring(
                        0,
                        initialVisibleDescriptionLength
                      ) + '...'}
                  {journeyDetails.description.length <=
                  initialVisibleDescriptionLength ? null : (
                    <Text
                      onPress={toggleNumberOfLines}
                      fontWeight={300}
                      marginTop={-2}
                      color={'blue.400'}
                      underline={true}
                    >
                      {textShown ? 'Read less' : 'Read more'}
                    </Text>
                  )}
                </Text>
              </>
            )}

            {displayDetails.map((detail, index) => {
              return (
                <HStack key={index}>
                  <Text flex={1} size={'smMedium'} color={Colors.FoldPixel.GRAY250}>
                    {detail.title}
                  </Text>
                  <Text flex={6} size={'smRegular'} color={Colors.FoldPixel.GRAY400} numberOfLines={1}>
                    {detail.description || '-'}
                  </Text>
                </HStack>
              );
            })}
          </VStack>

          <Divider />
          <CareJourneyActivities
            journeyDetails={props.journeyDetails}
            nodes={nodes}
            edges={edges}
            displayConfig={{
              canEdit: false,
              showAssignee: false,
              height: '42vh',
            }}
            nodeMasterDataMap={props.nodeMasterDataMap}
            nodeMetaData={props.nodeMetaData}
          />
        </VStack>
      </PageBodyContainer>
    </View>
  );
};

export default CareJourneyPreview;
