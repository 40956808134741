import React from 'react';
import {Tooltip} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {
  View,
  Text,
  HStack
} from 'native-base';
import {Colors} from '../../../../../../../../styles/Colors';
import {ILastMessageDrillDownData} from '../../interface';
import {MESSAGE_CUSTOM_CARD_KEY, MESSAGE_CUSTOM_CARD_TYPE} from '../../../../../../TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import {isJsonString} from '../../../../../../TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {getCallIcons, getCallIconsColors, getCallStatusForEmployerReporting} from '../../../../../../TeamInbox/CallLogs/CallLogsUtils';
import MissedCallSvg from '../../../../../../../common/Svg/ConversationAllSvg/MissedCallSvg';
const LastMessage = (props: {
  columnData: ILastMessageDrillDownData;
}) => {
  const {columnData} = props;
  const isDisplayCallMsgHeader =
    (columnData?.messageType === MESSAGE_CUSTOM_CARD_TYPE.INCOMING_COMPLETED_CALL_CARD) ||
    (columnData?.messageType === MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_COMPLETED_CALL_CARD) ||
    (columnData?.messageType === MESSAGE_CUSTOM_CARD_TYPE.INCOMING_MISSED_CALL_CARD) ||
    (columnData?.messageType === MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_MISSED_CALL_CARD)
  const messageIcon = (
    <Feather
      size={20}
      name="message-square"
      color={columnData?.messageType === 0 ? Colors.Custom.SuccessColor : Colors.Custom.Gray500}
    />
  );

  const getDisplayMessageString = () => {
    let displayStr = '';
    if (columnData?.messageType === 0) {
      displayStr = 'Incoming Message';
    } else if (columnData?.messageType === 1) {
      displayStr = 'Outgoing Message';
    } else if (isDisplayCallMsgHeader && columnData?.content) {
      const contentJson = isJsonString(columnData?.content || '') ? JSON.parse(columnData?.content || '') : {}
      displayStr = getCallStatusForEmployerReporting(contentJson?.resourceData);
    }
    return displayStr;
  };
  const getMessageIncomingOutGoingElem = () => {
    const displayString = getDisplayMessageString();
    if (displayString) {
      if (columnData?.messageType === 0 || columnData?.messageType === 1) {
        return (
          <HStack>
            <View>{messageIcon}</View>
            <View paddingLeft={2}>
              <Text
                size={'smRegular'}
                color={Colors.FoldPixel.GRAY250}
                noOfLines={1}
              >
                {displayString}
              </Text>
            </View>
          </HStack>
        )
      } else if (isDisplayCallMsgHeader) {
        const contentJson = isJsonString(columnData?.content || '') ? JSON.parse(columnData?.content || '') : {}
        if (contentJson?.resourceCode === MESSAGE_CUSTOM_CARD_KEY.INCOMING_MISSED_CALL_CARD) {
          return (
            <HStack>
              <View height={'16px'} width={'16px'}>
                <MissedCallSvg
                  customStrokeColor={Colors.Custom.MissedCallIconColor}
                />
              </View>
              <View paddingLeft={2}>
                <Text
                  color={Colors.Custom.Gray700}
                  noOfLines={1}
                >
                  {displayString}
                </Text>
              </View>
            </HStack>
          );
        } else {
          return (
            <HStack>
              <View>
                <Feather
                  size={16}
                  name={getCallIcons(contentJson?.resourceData)}
                  color={getCallIconsColors(contentJson?.resourceData)}
                />
              </View>
              <View paddingLeft={2}>
                <Text
                  color={Colors.Custom.Gray700}
                  noOfLines={1}
                >
                  {displayString}
                </Text>
              </View>
            </HStack>
          );
        }
      }
    }

  }
  if (columnData?.content) {
    return (
      <HStack>
        {getMessageIncomingOutGoingElem()}
      </HStack>
    );
  } else {
    return <></>;
  }

}
export default React.memo(LastMessage);




